import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Actions from '../../../redux/actions/appUsers/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { AppBar, Button, Card, CardContent, Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PasswordResetModal from './PasswordResetModal';
import CannedRegex from '../../../../support/CannedRegex';
import FormField from '../../../../components/form/FormField';
import FormBooleanSelect from '../../../../components/form/FormBooleanSelect';
import AppUserAddressList from './appUserAddress/AppUserAddressList';
import AppUserNotificationList from './appUserNotification/AppUserNotificationList';
import AppUserReservationCardList from './appUserReservationCard/AppUserReservationCardList';
import AppUserCardTokenList from './AppUserCardToken/AppUserCardTokenList';
import AppUserPushTokenList from './appUserPushTokenList/AppUserPushTokenList';
import AppUserSetting from './appUserSetting/appUserSetting';
import TabPanel from '../../control/TabPanel';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';
import ResetPasswordType from '../../../../constants/ResetPasswordType';

export default function AppUserDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);

  const lastActionType = useSelector((state) => state.appUsersReducer.lastActionType);
  const processing = useSelector((state) => state.appUsersReducer.processing);
  const error = useSelector((state) => state.appUsersReducer.error);

  const [id] = React.useState(selectedItem?.id || '');
  const [email, setEmail] = React.useState(selectedItem?.email || '');
  const [username, setUsername] = React.useState(selectedItem?.username || '');
  const [firstName, setFirstName] = React.useState(selectedItem?.firstName || '');
  const [lastName, setLastName] = React.useState(selectedItem?.lastName || '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [over18, setOver18] = React.useState(selectedItem?.over18 || true);
  const [userResetPasswordModalOpen, setUserResetPasswordModalOpen] = React.useState(false);

  const location = useLocation();
  const selectedTab = new URLSearchParams(location.search).get('tab');
  const [value, setValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
  const editTabsList = [
    { label: 'Card Token' },
    { label: 'Notifications' },
    { label: 'Reservation Card' },
    { label: 'Push Tokens' },
    { label: 'User Settings' },
  ];

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.APPUSER_CREATE_SUCCESS ||
      lastActionType === Actions.APPUSER_DELETE_SUCCESS ||
      lastActionType === Actions.APPUSER_RESET_PASSWORD_SUCCESS
    ) {
      history.goBack();
    }
  }, [history, lastActionType]);

  function handleAddOrUpdate() {
    if (selectedItem) {
      dispatch(
        Actions.editAppUser({
          ...selectedItem,
          id: id,
          username: username,
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          locale: 'en',
          phoneNumber: phone.sanitizedPhoneNumber,
          over18: over18,
          appId: 1,
        })
      );
    } else {
      dispatch(
        Actions.createAppUser({
          username: username,
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          locale: 'en',
          phoneNumber: phone.sanitizedPhoneNumber,
          over18: over18,
          appId: 1,
        })
      );
    }
  }

  const moveSelectedTab = useCallback(
    (event, newValue) => {
      setValue(newValue);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tab', newValue);

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location]
  );

  const handleClose = () => {
    setUserResetPasswordModalOpen(false);
  };

  const handleResetPassword = (resetPasswordType, password) => {
    setUserResetPasswordModalOpen(false);

    if (resetPasswordType === ResetPasswordType.UPDATE_TEMP_PASSWORD.value) {
      dispatch(Actions.resetAppUserPassword(username, password));
    } else {
      console.warn('Unable to find correct reset password type', resetPasswordType);
    }
  };

  const isEmailValid = CannedRegex.isEmailValid(email);
  const isFormReady = checkFormAndRoles(processing, username, email, phone, firstName, lastName, over18);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Edit App User' : 'Create App User'}
        subheader={selectedItem ? selectedItem.emailAddress : 'Please complete the form to create a new app user'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        buttonLoading={processing}
        onClick={handleAddOrUpdate}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem}
        backLink={'/account/app-users'}
        onDelete={() => dispatch(Actions.deleteAppUser(selectedItem.id))}
      />

      <PasswordResetModal handleClose={handleClose} isOpen={userResetPasswordModalOpen} handleResetPassword={handleResetPassword} />

      <Divider variant={'middle'} />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to create or edit user please try again later. Error message (${error.message})`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Username'} value={username} setValue={setUsername} placeholder="Enter Username" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Email'}
              value={email}
              setValue={setEmail}
              error={!isEmailValid}
              helperText={CannedRegex.getEmailMessage(!isEmailValid)}
              placeholder="Enter Email Address"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'First Name'} value={firstName} setValue={setFirstName} placeholder="Enter First Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Last Name'} value={lastName} setValue={setLastName} placeholder="Enter Last Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Over 18'} value={over18} setValue={setOver18} />
          </Grid>
          {selectedItem && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button variant={'contained'} onClick={() => setUserResetPasswordModalOpen(true)}>
                Reset Password
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>

      {selectedItem?.id && (
        <Paper>
          <AppBar position="static">
            <Tabs value={value} onChange={moveSelectedTab}>
              <Tab label="Address" />
              {selectedItem && editTabsList.map((item) => <Tab label={item.label} />)}
            </Tabs>
          </AppBar>
          <div className="content-scrollable-no-padding">
            <TabPanel value={value} index={0}>
              <AppUserAddressList />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AppUserCardTokenList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AppUserNotificationList />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AppUserReservationCardList />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AppUserPushTokenList />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AppUserSetting />
            </TabPanel>
          </div>
        </Paper>
      )}
    </Card>
  );
}

function checkFormAndRoles(processing, username, email, phone, firstName, lastName, over18) {
  if (processing) {
    return false;
  }

  if (CannedRegex.isEmailValid(email) && phone.isValid && firstName && lastName && username && over18 !== null) {
    console.log('checkFormAndRoles', true);
    return true;
  }

  return false;
}
