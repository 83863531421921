const SpecialRequestRedirectType = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

const SpecialRequestRedirectTypeOptions = [
  { value: SpecialRequestRedirectType.EMAIL, label: 'Email' },
  { value: SpecialRequestRedirectType.PHONE, label: 'Phone' },
];

export default SpecialRequestRedirectType;
export { SpecialRequestRedirectTypeOptions };
