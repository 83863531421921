import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { Card, CardContent, Collapse, Grid, IconButton, Paper, TextField } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from 'components/form/FormField';
import * as Actions from '../../../../../redux/actions/compendium/category/actions';
import * as ArticleActions from '../../../../../redux/actions/compendium/article/actions';
import moment from 'moment/moment';
import AppConfig from '../../../../../../AppConfig';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import AutoTable from '../../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../../constants/DataTypes';
import { DeleteOutlined } from '@material-ui/icons';
import toBase64 from '../../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../../components/file/FileImageSelector';
import FormSelect from '../../../../../../components/form/FormSelect';
import CompendiumTypes from '../../../../../../support/CompendiumTypes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CompendiumContentEditor from '../shared/CompendiumContentEditor';
import CompendiumContentSwitcher from '../shared/CompendiumContentSwitcher';

const columns = [
  {
    id: 'disable',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(ArticleActions.deleteItem(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'title',
    label: 'Title',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function CategoryEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.compendiumCategoryReducer.lastActionType);
  const error = useSelector((state) => state.compendiumCategoryReducer.error);
  const selectedItem = useSelector((state) => state.compendiumCategoryReducer.selectedItem);
  const articles = useSelector((state) => state.compendiumArticleReducer.data);
  const processing = useSelector((state) => state.compendiumArticleReducer.processing);
  const articleLastActionType = useSelector((state) => state.compendiumArticleReducer.lastActionType);
  const page = useSelector((state) => state.compendiumArticleReducer.page);
  const articleError = useSelector((state) => state.compendiumArticleReducer.error);
  const foodVendors = useSelector((state) => state.foodVendorReducer.entityList);

  const [orderNo, setOrderNo] = React.useState(selectedItem?.orderNo || '');
  const [categoryName, setCategoryName] = React.useState(selectedItem?.categoryName || '');
  const [description, setDescription] = React.useState(selectedItem?.description || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);
  const [fileSelected, setFileSelected] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(selectedItem?.imageUrl || null);
  const [compendiumType, setCompendiumType] = React.useState(selectedItem?.compendiumType || null);
  const [vendorId, setVendorId] = React.useState(selectedItem?.vendorId || null);
  const [visualEditor, setVisualEditor] = React.useState(true);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.CATEGORY_ADD_SUCCESS ||
      lastActionType === Actions.CATEGORY_UPDATE_SUCCESS ||
      lastActionType === Actions.CATEGORY_DELETE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const categoryImage = await toBase64(fileSelected);
    const imageName = fileSelected?.name;
    const payload = {
      ...selectedItem,
      orderNo,
      categoryName,
      description,
      enabled,
      siteId: selectedSite?.id,
      imageUrl: categoryImage ? categoryImage : imageUrl,
      imageName: imageName ? imageName : imageUrl ? imageUrl.substring(imageUrl.lastIndexOf('/') + 1) : null,
      compendiumType,
      vendorId,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.create(payload));
    }
  };

  useEffect(() => {
    if (selectedItem && selectedSite) {
      dispatch(ArticleActions.fetch(0, 20, selectedItem.id));
    }
  }, [dispatch, selectedItem, selectedSite]);

  const handleRefreshReport = (selectedSite, selectedItem) => {
    if (selectedSite && selectedItem) {
      dispatch(ArticleActions.fetch(0, 20, selectedItem.id));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(ArticleActions.fetch(newPage, pageSize, selectedItem.id));
  };

  const vendorOptions = useMemo(() => {
    return foodVendors.filter((f) => f.enabled).map((f) => ({ value: f.id, label: f.name }));
  }, [foodVendors]);

  const isFormReady = orderNo && categoryName && (imageUrl || fileSelected);
  return (
    <Paper>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={'Compendium Category'}
            subheader={''}
            buttonName={selectedItem ? 'Update' : 'Add'}
            onClick={handleSubmit}
            disabled={!isFormReady}
          />
          <CardContent>
            {error && <Alert severity="error">{`Unable to update Category - ${error.message}`}</Alert>}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Name'} value={categoryName} setValue={setCategoryName} placeholder="Category Name" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Order'} value={orderNo} setValue={setOrderNo} placeholder="Order" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} alignContent={'center'}>
                <CompendiumContentSwitcher visual={visualEditor} setVisual={setVisualEditor} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CompendiumContentEditor value={description} setValue={setDescription} visual={visualEditor} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Compendium Type'}
                  value={compendiumType}
                  setValue={(value) => setCompendiumType(value)}
                  options={CompendiumTypes}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  fullWidth
                  options={vendorOptions}
                  filterSelectedOptions
                  getOptionSelected={(option, selected) => option.value === selected.value}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => setVendorId(newValue?.value)}
                  value={vendorOptions.find((f) => f.value === vendorId) || null}
                  renderInput={(params) => <TextField {...params} label="Food Vendor" variant="outlined" margin={'dense'} />}
                />
              </Grid>
              {selectedItem && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Created'}
                      value={moment.unix(selectedItem?.created).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Updated'}
                      value={moment.unix(selectedItem?.updated).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                      disabled
                    />
                  </Grid>
                </>
              )}

              <FileImageSelector
                sourceItem={selectedItem}
                sourceItemProperty={'imageUrl'}
                displayName={'Category Image'}
                onSelect={(file) => {
                  setFileSelected(file);
                }}
                imageCleared={(value) => {
                  if (value) {
                    setImageUrl(null);
                  }
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={selectedItem?.id}>
          <AutoTable
            title="Articles"
            subheader={''}
            handleRefreshReport={() => handleRefreshReport(selectedSite, selectedItem)}
            handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
            processing={processing}
            page={page}
            selectItem={(item) => dispatch(ArticleActions.selectItem(item))}
            error={articleError}
            clearError={() => dispatch(ArticleActions.clearError())}
            columns={columns}
            prefix="ARTICLE"
            buttonName="New Article"
            createNewURL="/site/compendium/article/new"
            detailURL="/site/compendium/article/view/{id}"
            idColumn={'id'}
            data={articles}
            lastActionType={articleLastActionType}
          />
        </Collapse>
      </Grid>
    </Paper>
  );
}
