import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import * as AccessActions from '../access/actions';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

const BASE_URL = '/api/private/portal/site';

export const SITE_PREFIX = 'SITE';
export const SITE_DUPLICATE_IN_PROGRESS = 'SITE_DUPLICATE_IN_PROGRESS';
export const SITE_DUPLICATE_SUCCESS = 'SITE_DUPLICATE_SUCCESS';
export const SITE_DUPLICATE_FAILURE = 'SITE_DUPLICATE_FAILURE';

const SiteActions = {
  ...actionCreator.createActions('Site', SITE_PREFIX, BASE_URL),
  updateChatCollectionId: (siteId, chatCollectionId) => async (dispatch) => {
    let requestUrl = `${BASE_URL}/chat/${siteId}?chatCollectionId=${chatCollectionId}`;

    API.post('PrivateAPI', requestUrl, {})
      .then((response) => {
        dispatch(AccessActions.updateChatCollectionId(response));
      })
      .catch((error) => {
        console.warn('error occurred while trying to save a chat collection id', error);
      });
  },
  duplicate: (site) => async (dispatch) => {
    Objects.notNull(site, 'item cannot be null');

    dispatch({ type: `SITE_DUPLICATE_IN_PROGRESS` });
    try {
      const response = await API.post('PrivateAPI', `${BASE_URL}/duplicate/${site.id}`, {});
      dispatch({ type: `SITE_DUPLICATE_SUCCESS`, payload: response });
      await Swal.fire('Site Duplicated ', 'Site has been duplicated successfully', 'success');
    } catch (error) {
      dispatch({ type: `SITE_DUPLICATE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  checkSiteUuidExists: async (siteUuid) => {
    Objects.notNull(siteUuid, 'site uuid cannot be null');
    return await API.get('PrivateAPI', `${BASE_URL}/uuid/check?uuid=${siteUuid}`, {});
  },
};

export default SiteActions;
