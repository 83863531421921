import React from 'react';
import * as AuthenticationActions from '../../redux/actions/authentication/actions';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, FormControl, TextField } from '@material-ui/core';
import ContentContainer from '../../../components/container/ContentContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ExitToAppOutlined } from '@material-ui/icons';
import CannedRegex from '../../../support/CannedRegex';
import Alert from '@material-ui/lab/Alert';

export default function NewPasswordRequired() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authenticationReducer.authData);
  const error = useSelector((state) => state.authenticationReducer.error);

  const onChangePasswordSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(AuthenticationActions.completeNewPassword(authData.user, newPassword, confirmNewPassword, firstName, lastName));
    },
    [dispatch, authData, newPassword, confirmNewPassword, firstName, lastName]
  );

  const navigateBack = () => {
    dispatch(AuthenticationActions.updateAuthenticationState('signIn'));
    history.push('/login');
  };

  const isPasswordInvalid = !CannedRegex.isPasswordValid(newPassword);
  const isPasswordMismatch = newPassword && newPassword !== confirmNewPassword;
  const isFormReady = firstName && lastName && newPassword && confirmNewPassword && !isPasswordInvalid && !isPasswordMismatch;
  return (
    <ContentContainer variant={'centered'}>
      <Card>
        <CardHeader title={'Confirm Your Details'} subheader={'Please confirm account your information'} />
        <CardContent>
          <form onSubmit={onChangePasswordSubmit}>
            {error && (
              <Alert severity="error" variant={'filled'} className={'mb-2'}>
                {`Unable to confirm password: ${error.message}`}
              </Alert>
            )}

            <FormControl fullWidth={true}>
              <TextField
                id="firstName"
                margin="dense"
                variant="outlined"
                label={'First Name'}
                type="text"
                autoFocus={true}
                error={!firstName}
                required
                value={firstName}
                autoComplete={'off'}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="lastName"
                margin="dense"
                variant="outlined"
                label={'Last Name'}
                type="text"
                required
                autoComplete={'off'}
                error={!lastName}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="newPassword"
                margin="dense"
                variant="outlined"
                label={'New Password'}
                type="password"
                required
                autoComplete={'off'}
                value={newPassword}
                error={isPasswordInvalid}
                helperText={CannedRegex.getPasswordMessage(isPasswordInvalid)}
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="confirmNewPassword"
                margin="dense"
                variant="outlined"
                label={'Confirm Password'}
                type="password"
                required
                autoComplete={'off'}
                error={isPasswordMismatch}
                helperText={isPasswordMismatch ? '** Password mismatch double check passwords' : ''}
                value={confirmNewPassword}
                onChange={(event) => setConfirmNewPassword(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth margin={'dense'}>
              <Button variant="contained" color="primary" type={'submit'} disabled={!isFormReady} startIcon={<ExitToAppOutlined />}>
                Update Password
              </Button>
            </FormControl>

            <FormControl fullWidth margin={'dense'}>
              <Button component={Link} to="/login" onClick={navigateBack}>
                <span>Back</span>
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </ContentContainer>
  );
}
