import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Actions from '../../redux/actions/app/theme/actions';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import { FlightOutlined } from '@material-ui/icons';
import PhoneNumberInput from '../../../components/phoneNumberInput/PhoneNumberInput';

const useStyles = makeStyles(() => ({
  stdPadding: {
    padding: '1.0rem',
    textAlign: 'center',
  },
  titleText: {
    fontWeight: 'bold',
  },
  centerContent: {
    maxWidth: '600px',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  logoContainer: {
    margin: 'auto',
    minWidth: '100px',
    textAlign: 'center',
  },
  imageSize: {
    maxWidth: '33vh',
    maxHeight: '33vh',
  },
  outerFlexContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  paperFlex: {
    flex: '1 1 auto',
  },
  recaptchaCenter: {
    width: '50%',
    padding: '1.0rem 0rem',
    margin: '0 auto',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function AppDynamicLink() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);

  const [phone, setPhone] = useState({
    phoneNumber: '',
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [formValid, setFormValid] = React.useState(false);

  const error = useSelector((state) => state.appThemeReducer.error);
  const selectedAppTheme = useSelector((state) => state.appThemeReducer.selectedItem);

  useEffect(() => {
    dispatch(Actions.fetchThemeByName(id));
  }, [dispatch, id]);

  const handleSubmit = () => {
    Actions.sendInvite(id, phone.sanitizedPhoneNumber)
      .then(() => {
        Swal.fire('Message Sent', 'You have been sent a text message containing the link to download our app', 'success');
        setPhone('');
      })
      .catch(() => {
        Swal.fire('Something Went Wrong', 'The text message could not be sent. Please try again later.', 'error');
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setFormValid(false);
      });
  };

  if (error) {
    return (
      <div className={classes.outerFlexContainer}>
        <Paper className={classes.paperFlex}>
          <div className={classes.centerContent}>
            <Typography variant="h3">{'Something went wrong'}</Typography>
            <Typography variant="h5">
              Unfortunately we were unable to complete your request. Please try again later or contact{' '}
              <a href={'mailto:support@liverton.com'}>help@liverton.com</a> for assistance
            </Typography>
          </div>
        </Paper>
      </div>
    );
  } else {
    const disabled = !phone.isValid || !formValid;
    return (
      <div className={classes.outerFlexContainer}>
        <Paper className={classes.paperFlex}>
          <div className={classes.centerContent}>
            <div className={classes.logoContainer}>
              {selectedAppTheme?.backgroundLogoUrl ? (
                <img src={selectedAppTheme.backgroundLogoUrl} className={classes.imageSize} alt="logo" />
              ) : null}
            </div>
            <Typography variant="h3" className={classes.titleText}>
              {selectedAppTheme?.appName || 'SmartCheck Mobile'}
            </Typography>
            <Typography variant="h5">Manage your booking and access your room using your Mobile. Download our App today</Typography>
            <div className={classes.stdPadding}>
              <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={false} />
            </div>

            <Typography variant="body1">You will receive a one-time SMS to download our app</Typography>

            <ReCAPTCHA
              sitekey="6LeIu8oZAAAAALgCGWJBaufwV6Y8MhIeveW4U1kS"
              onChange={() => setFormValid(true)}
              ref={recaptchaRef}
              className={classes.recaptchaCenter}
            />

            <div className={classes.stdPadding}>
              <Button variant="contained" color="primary" size="large" disabled={disabled} onClick={handleSubmit}>
                <FlightOutlined /> Send The App
              </Button>
            </div>
            <div className={classes.stdPadding}>
              <Typography variant="body1">
                App link will be sent to you via TXT after submission on this form. Standard TXT messaging rates apply
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
