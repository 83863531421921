import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/appUserCardToken/actions';
import AutoTable from '../../../../../components/table/AutoTable';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.delete(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'cardHolder',
    label: 'Card Holder',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardNumber',
    label: 'Card Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardType',
    label: 'Card Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardToken',
    label: 'Card Token',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardExpiry',
    label: 'Card Expiry',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function AppUserCardTokenList() {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const page = useSelector((state) => state.appUserCardTokenReducer.page);
  const error = useSelector((state) => state.appUserCardTokenReducer.error);
  const processing = useSelector((state) => state.appUserCardTokenReducer.processing);
  const lastActionType = useSelector((state) => state.appUserCardTokenReducer.lastActionType);
  const requests = useSelector((state) => state.appUserCardTokenReducer.entityList);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 10, { userId: selectedItem.id }));
    }
  }, [dispatch, selectedItem]);

  const handleRefreshReport = () => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 10, { userId: selectedItem.id }));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedItem) {
      dispatch(Actions.fetch(newPage, pageSize, { userId: selectedItem.id }));
    }
  };

  return (
    <AutoTable
      title="Save Cards "
      subheader={'Tokenised cards assigned to this account'}
      handleRefreshReport={() => handleRefreshReport()}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="APP_USER_CARD_TOKEN"
      buttonDisabled={true}
      data={requests}
      lastActionType={lastActionType}
    />
  );
}
