import React from 'react';
import ReservationStatus from '../../../../../support/ReservationStatus';
import HotelReservationList from './HotelReservationList';

export default function HotelReservationDeparted() {
  return (
    <HotelReservationList
      title={'Departed/Past Hotel Reservations'}
      subheader={'Hotel reservations with check-out status'}
      status={ReservationStatus.CHECKED_OUT}
    />
  );
}
