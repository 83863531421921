import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../redux/actions/wallet/actions';
import AutoTable from '../../../../components/table/AutoTable';
import * as DataTypes from '../../../../constants/DataTypes';

const conditionColumns = [
  {
    id: 'name',
    label: 'Wallet Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'platform',
    label: 'Wallet Platform',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];
export default function WalletList() {
  const dispatch = useDispatch();

  const walletList = useSelector((state) => state.walletReducer.entityList);
  const lastActionType = useSelector((state) => state.walletReducer.lastActionType);
  const page = useSelector((state) => state.walletReducer.page);

  return (
    <AutoTable
      title="Wallet List"
      subheader={'Wallet available for Android and IOS'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 10))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize))}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      columns={conditionColumns}
      prefix="WALLET"
      buttonName="New Wallet"
      createNewURL="/wallet/view"
      detailURL="/wallet/view/{id}"
      idColumn={'id'}
      data={walletList || []}
      lastActionType={lastActionType}
    />
  );
}
