import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodCorpFilterActions from '../../../../redux/actions/inRoom/ordering/foodCorpFilter/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Chip, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import { FoodCorpFilterTypes } from '../../../../../support/FoodCorpFilterType';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FoodCorpFilterEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const corp = useSelector((state) => state.accessReducer.selectedCorporation);
  const corpId = corp?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodCorpFilterReducer);
  const [filterName, setFilterName] = useState(selectedItem?.filterName || '');
  const [filterType, setFilterType] = useState(selectedItem?.filterType || '');
  const [filter, setFilter] = useState(selectedItem?.filter || []);

  useEffect(() => {
    dispatch(FoodCorpFilterActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_CORP_FILTER_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_CORP_FILTER_ADD_SUCCESS' ||
      lastActionType === 'FOOD_CORP_FILTER_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, corpId]);

  const handleFilterUpdate = (event) => {
    const value = event.target.value;

    if (!value || (filter && filter.length > 0 && filter.filter((f) => f === value).length > 0)) {
      return;
    }

    if (filter.length > 0) {
      setFilter([...filter, value]);
    } else {
      setFilter([value]);
    }
  };

  const handleFilterDelete = (value) => {
    setFilter(filter.filter((item) => item !== value));
  };

  const handleSubmit = async () => {
    const payload = {
      filterName: filterName,
      filterType: filterType,
      filter: filter,
    };

    if (selectedItem) {
      dispatch(FoodCorpFilterActions.update(payload, `/api/private/portal/food/corp/filter/${selectedItem.id}`, false));
    } else {
      dispatch(FoodCorpFilterActions.add(payload, { corpId: corpId }));
    }
  };

  const isFormReady = () => {
    return filterName && filterType && filter;
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Corporation Level Food Filter' : 'Create Corporation Level Food Filter'}
        subheader={'A Food Filter contains corporation level filtering of food items'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady() || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodCorpFilterActions.delete(selectedItem, `/api/private/portal/food/corp/filter/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food charge - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={filterName} setValue={setFilterName} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Filter Type'} value={filterType} setValue={setFilterType} options={FoodCorpFilterTypes} required />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              multiple
              id="corp-filter"
              options={[]}
              defaultValue={[]}
              freeSolo
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label={'Filter'} placeholder="Filter" variant={'outlined'} margin={'dense'} />
              )}
              value={filter}
              renderTags={(values) =>
                values.map((option) => <Chip key={option} label={option} onDelete={() => handleFilterDelete(option)} />)
              }
              onChange={handleFilterUpdate}
              onClose={handleFilterUpdate}
              clearOnBlur
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
