const CompendiumType = {
  STANDARD: 'STANDARD',
  VENDOR: 'VENDOR',
};

export const CompendiumTypes = [
  { label: 'STANDARD', value: CompendiumType.STANDARD },
  { label: 'VENDOR', value: CompendiumType.VENDOR },
];

export default CompendiumTypes;
