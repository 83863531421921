import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../redux/actions/siteFeatures/actions';
import { Box, CardContent, CardHeader, Divider, FormControlLabel, Grid, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import useFetch from '../../../../support/hook/useFetch';

const isSiteFeatureEnabled = (code, siteFeatureList) => {
  const listLength = siteFeatureList.filter((siteFeature) => siteFeature.feature.code === code).length;
  return Boolean(listLength);
};

export default function SiteFeatures() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteFeaturesReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const siteFeatureList = useSelector((state) => state.siteFeaturesReducer.data);
  const [availableFeatureList] = useFetch('/api/private/portal/features/available');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.getSiteFeatures(selectedSiteId));
    }
  }, [selectedSiteId, dispatch]);

  const handleChange = (event, feature) => {
    if (selectedSite) {
      if (event.target.checked) {
        dispatch(Actions.toggleFeature(true, selectedSiteId, feature));
      } else {
        dispatch(Actions.toggleFeature(false, selectedSiteId, feature));
      }
    }
  };

  return (
    <Card>
      <CardHeader title={'Site Features'} subheader={`Enabled features ${selectedSite?.name} for the mobile and web check-in system`} />

      <Divider variant={'middle'} />

      <CardContent>
        <Box className="content-scrollable">
          {error && <Alert severity="error">{'Unable to update site features - ' + error.message}</Alert>}

          <Grid container spacing={2}>
            {availableFeatureList.map((item) => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={item.name}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isSiteFeatureEnabled(item.code, siteFeatureList)}
                      onChange={(event) => {
                        handleChange(event, item);
                      }}
                      name={item.name}
                      color="primary"
                    />
                  }
                  label={item.name}
                  labelPlacement="end"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
