import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../redux/actions/system/actions';
import SystemCategory, { SystemCategorySelectOptions } from '../../../../../constants/SystemCategory';
import FormSelect from '../../../../../components/form/FormSelect';

function getSystemTypeSelectOptions(systemCategory, availableSystemMap = {}) {
  const available = availableSystemMap[systemCategory] || [];
  return available.map((s) => ({ label: s.name, value: s }));
}

export default function NewSystemModal({ handleClose, newSystemModalOpen }) {
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const [systemCategory, setSystemCategory] = useState(SystemCategory.PMS);
  const [systemType, setSystemType] = useState('');
  const availableSystemMap = useSelector((state) => state.siteResourcesReducer.availableSystemMap);
  const systemTypeSelectOptions = getSystemTypeSelectOptions(systemCategory, availableSystemMap);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(Actions.getAvailableSystemList());
  }, [dispatch]);

  const handleSelectSystem = () => {
    dispatch(
      Actions.selectItem(
        {
          id: null,
          name: systemType.name,
          systemCategory: systemCategory,
          systemType: systemType.systemClass,
          systemConfiguration: null,
          enabled: true,
          siteId: selectedSite?.id,
        },
        history
      )
    );
  };

  const areRequiredFieldsEmpty = systemCategory === '' || systemType === '';
  return (
    <Dialog open={newSystemModalOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">New System</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please select the system category and type you want to add to this site.
        </DialogContentText>

        <FormSelect label={'System Category'} value={systemCategory} setValue={setSystemCategory} options={SystemCategorySelectOptions} />
        <FormSelect label={'System Type'} value={systemType} setValue={setSystemType} options={systemTypeSelectOptions} />
      </DialogContent>
      <DialogActions className={'mb-1'}>
        <Button onClick={handleClose} autoFocus>
          CLOSE
        </Button>
        <Button color="primary" variant={'contained'} className={'mr-2'} onClick={handleSelectSystem} disabled={areRequiredFieldsEmpty}>
          ADD SYSTEM
        </Button>
      </DialogActions>
    </Dialog>
  );
}
