import reducerCreator from '../../support/reducerCreator';
import {
  SPECIAL_REQUEST_NOTIFICATION_FAILURE,
  SPECIAL_REQUEST_NOTIFICATION_IN_PROGRESS,
  SPECIAL_REQUEST_NOTIFICATION_SUCCESS,
  SPECIAL_REQUEST_UNSELECT,
} from '../../actions/specialRequest/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('SpecialRequest', 'SPECIAL_REQUEST');

function specialRequestReducer(state = initial, action) {
  switch (action.type) {
    case SPECIAL_REQUEST_NOTIFICATION_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case SPECIAL_REQUEST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList].filter((e) => e.id !== action.payload.id),
      };
    case SPECIAL_REQUEST_NOTIFICATION_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case SPECIAL_REQUEST_UNSELECT:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [],
      };
    default:
      return base(state, action);
  }
}

export default specialRequestReducer;
