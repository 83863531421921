import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../../components/form/FormSelect';

export default function EditPgr() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);
  const [url, setUrl] = React.useState(configuration?.url || '');
  const [merchantId, setMerchantId] = React.useState(configuration?.merchantId || '');
  const [apiToken, setApiToken] = React.useState(configuration?.apiToken || '');
  const [businessPartnerExternalId, setBusinessPartnerExternalId] = React.useState(configuration?.businessPartnerExternalId || '');
  const [membershipBrand, setMembershipBrand] = React.useState(configuration?.membershipBrand || '0');
  const [debug, setDebug] = React.useState(configuration?.debug || false);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        url,
        merchantId,
        apiToken,
        businessPartnerExternalId,
        membershipBrand,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  };

  const isFormReady = name && url && merchantId && apiToken && membershipBrand;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update PGR System' : 'New PGR System'}
        subheader={'Integration to PGR membership platform used by EVT Properties'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={name} setValue={setName} error={!name} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Endpoint URL'}
              placeholder={'https://test.api.svs.com.au/pgr2.0/1.0.0/memberRegistration'}
              value={url}
              setValue={setUrl}
              error={!url}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Merchant ID'} value={merchantId} setValue={setMerchantId} error={!merchantId} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'API Token'} value={apiToken} setValue={setApiToken} error={!apiToken} required type={'password'} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Hotel Code (Optional)'} value={businessPartnerExternalId} setValue={setBusinessPartnerExternalId} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Membership Brand'} value={membershipBrand} setValue={setMembershipBrand} options={MembershipBrandOptions} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant={'caption'} display={'block'}>
              UAT = https://test.api.svs.com.au/pgr2.0/1.0.0/memberRegistration
            </Typography>
            <Typography variant={'caption'} display={'block'}>
              Production = https://pgr.api.svs.com.au/pgr2.0/1.0.0/memberRegistration
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const MembershipBrandOptions = [
  { label: 'Rydges', value: '0' },
  { label: 'Atura', value: '1' },
  { label: 'QT', value: '2' },
  { label: 'Other', value: '3' },
];
