import React, { useEffect } from 'react';
import * as Actions from '../../../../redux/actions/rule/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import AutoTable from '../../../../../components/table/AutoTable';
import { IconButton, Switch } from '@material-ui/core';
import { DeleteForeverOutlined, FileCopyOutlined } from '@material-ui/icons';

// Table column rendering information.
const tableColumns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.duplicateRule(value))}>
            <FileCopyOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.remove(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Rule Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'conditions',
    label: 'Conditions',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_LIST,
    format: (item) => item.length,
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_LIST,
    format: (item) => item.length,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return <Switch checked={value.enabled} onChange={() => dispatch(Actions.toggleEnabled(value))} name={'enabled'} color={'primary'} />;
    },
  },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function RuleList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const ruleList = useSelector((state) => state.siteRuleReducer.entityList);
  const lastActionType = useSelector((state) => state.siteRuleReducer.lastActionType);
  const processing = useSelector((state) => state.siteRuleReducer.processing);
  const page = useSelector((state) => state.siteRuleReducer.page);
  const error = useSelector((state) => state.siteRuleReducer.error);

  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetch(0, 10, { siteId: selectedSiteId, sort: 'orderNo,asc' }));
    }
  }, [selectedSiteId, dispatch]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSiteId, sort: 'orderNo,asc' }));
  };

  return (
    <AutoTable
      title="Site Rule List"
      subheader={'Rules for managing each reservation'}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={tableColumns}
      prefix="SITE_RULE"
      newButtonName="New Rule"
      createNewURL="/site/rules/new"
      detailURL="/site/rules/view/{id}"
      idColumn={'id'}
      data={ruleList}
      lastActionType={lastActionType}
    />
  );
}
