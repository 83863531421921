import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../redux/actions/site/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import ValidationUtil from '../../../../support/ValidationUtil';
import { Card, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SiteUtil from '../../../../support/SiteUtil';
import TimeZones from '../../../../support/TimeZones';
import CardContent from '@material-ui/core/CardContent';
import FormBooleanSelect from '../../../../components/form/FormBooleanSelect';
import FormField from '../../../../components/form/FormField';
import FormSelectTheme from '../../../../components/form/FormSelectTheme';
import FormSelectPlatform from '../../../../components/form/FormSelectPlatform';
import { SiteNotificationTemplateType } from './template/NotificationTemplateType';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';
import PaymentProviderSelect from '../../../../components/form/PaymentProviderSelect';
import NotificationSchemeSelect from '../../../../components/form/NotificationSchemeSelect';
import ReceiptGroupingMode, { ReceiptGroupingModeOptions } from '../../../../support/ReceiptGroupingMode';
import FormSelect from '../../../../components/form/FormSelect';
import CheckInSecurityMode, { CheckInSecurityModeOptions } from '../../../../support/CheckInSecurityMode';
import MfaMode, { MfaModeOptions } from '../../../../support/MfaMode';
import FormCountryAndDivisionSelect from '../../../../components/form/country/FormCountryAndDivisionSelect';
import useDebounce from '../../../../support/hook/useDebounce';
import AppConfig from '../../../../AppConfig';

const getSelectedTime = (time) => {
  if (time) {
    return moment(time, 'HH:mm:ss');
  } else {
    return moment().hour('12').minute('00').second('00');
  }
};

const generateRandom = () => {
  return Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2).toUpperCase();
};

const UUID_REGEX = new RegExp('[^0-9a-zA-Z-_]');

export default function SiteEdit() {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const lastActionType = useSelector((state) => state.siteReducer.lastActionType);
  const error = useSelector((state) => state.siteReducer.error);
  const selectedItem = useSelector((state) => state.siteReducer.selectedItem);
  const processing = useSelector((state) => state.siteReducer.processing);

  const [name, setName] = useState(selectedItem?.name || '');
  const [enabled, setEnabled] = useState(selectedItem ? selectedItem.enabled : true);
  const [addressLine1, setAddressLine1] = useState(selectedItem?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(selectedItem?.addressLine2 || '');
  const [city, setCity] = useState(selectedItem?.city || '');
  const [divisionId, setDivisionId] = useState(selectedItem?.divisionId || '');
  const [countryId, setCountryId] = useState(selectedItem?.countryId || 2);
  const [longitude, setLongitude] = useState(selectedItem?.addressLongitude || '');
  const [latitude, setLatitude] = useState(selectedItem?.addressLatitude || '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phoneNumber,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [conciergePhone, setConciergePhone] = useState({
    phoneNumber: selectedItem?.conciergePhone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [email, setEmail] = useState(selectedItem?.email || '');
  const [website, setWebsite] = useState(selectedItem?.website || '');
  const [facebookUrl, setFacebookUrl] = useState(selectedItem?.facebookUrl || '');
  const [twitterUrl, setTwitterUrl] = useState(selectedItem?.twitterUrl || '');
  const [instagramUrl, setInstagramUrl] = useState(selectedItem?.instagramUrl || '');
  const [themeId, setThemeId] = useState(selectedItem?.themeId || '');
  const [platformId, setPlatformId] = useState(selectedItem?.platformId || '');
  const [paymentProviderId, setPaymentProviderId] = useState(selectedItem?.paymentProviderId || '');
  const [siteTimeZone, setSiteTimeZone] = useState(SiteUtil.getSiteZone(selectedItem));
  const [checkInTime, setCheckInTime] = useState(getSelectedTime(selectedItem?.checkInTime));
  const [checkOutTime, setCheckOutTime] = useState(getSelectedTime(selectedItem?.checkOutTime));
  const [webPlatformUrl, setWebPlatformUrl] = useState(selectedItem?.webPlatformUrl || '');
  const [webCheckInEnabled, setWebCheckInEnabled] = useState(selectedItem ? selectedItem.webCheckInEnabled : false);
  const [webCheckOutEnabled, setWebCheckOutEnabled] = useState(selectedItem ? selectedItem.webCheckOutEnabled : false);
  const [reservationSync, setReservationSync] = useState(selectedItem ? selectedItem.reservationSync : true);
  const [reservationSyncCiDays, setReservationSyncCiDays] = useState(selectedItem?.reservationSyncCiDays || '1');
  const [reservationSyncCoDays, setReservationSyncCoDays] = useState(selectedItem?.reservationSyncCoDays || '1');
  const [reservationSyncSchedule, setReservationSyncSchedule] = useState(selectedItem?.reservationSyncSchedule || 3600);
  const [cardOnFile, setCardOnFile] = useState(selectedItem ? selectedItem.cardOnFile : false);
  const [securityMode, setSecurityMode] = useState(selectedItem ? selectedItem.securityMode : CheckInSecurityMode.SWAP);

  const [notifications, setNotifications] = useState(
    selectedItem?.notifications?.map((notif) => {
      return { value: notif, label: notif };
    }) || []
  );
  const [notificationReminder, setNotificationReminder] = useState(selectedItem ? selectedItem.notificationReminder : false);
  const [kmsEnabled, setKmsEnabled] = useState(selectedItem?.kmsEnabled || false);
  const [apiKey, setApiKey] = useState(selectedItem?.apiKey || null);
  const [sqsMessageUrlRealTime, setSqsMessageUrlRealTime] = useState(selectedItem?.sqsMessageUrlRealTime || null);
  const [surcharge, setSurcharge] = useState(selectedItem ? selectedItem.surcharge : false);
  const [surchargePercent, setSurchargePercent] = useState(selectedItem ? selectedItem.surchargePercent : 0.0);
  const [surchargeTransactionCode, setSurchargeTransactionCode] = useState(selectedItem?.surchargeTransactionCode || '');
  const [showApiKey, setShowApiKey] = useState(false);
  const [taxInfo, setTaxInfo] = useState(selectedItem?.taxInfo || '');
  const [autoGuestNotifications, setAutoGuestNotifications] = useState(selectedItem ? selectedItem.autoGuestNotifications : false);
  const [notificationSchemeId, setNotificationSchemeId] = useState(selectedItem ? selectedItem.notificationSchemeId : 1);
  const [receiptGroupMode, setReceiptGroupMode] = useState(selectedItem ? selectedItem.groupingMode : ReceiptGroupingMode.DEFAULT);
  const [showCommsQr, setShowCommsQr] = useState(!!selectedItem?.showCommsQr);
  const [uuid, setUuid] = useState(selectedItem ? selectedItem.uuid : '');
  const [amenitiesUrl, setAmenitiesUrl] = useState(selectedItem ? selectedItem.amenitiesUrl : '');
  const [mfaMode, setMfaMode] = useState(selectedItem?.mfaMode || MfaMode.NONE);
  const [uuidExists, setUuidExists] = useState(false);
  const [uuidValid, setUuidValid] = useState(true);
  const debouncedUuid = useDebounce(uuid, 300);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    const handleUuidChange = () => {
      if (!debouncedUuid) {
        setUuidValid(true);
        setUuidExists(false);
      } else {
        setUuidValid(!UUID_REGEX.test(debouncedUuid));
        if (selectedItem?.uuid === debouncedUuid) {
          setUuidExists(false);
        } else {
          Actions.checkSiteUuidExists(debouncedUuid)
            .then((response) => {
              setUuidExists(response);
            })
            .catch((error) => {
              console.warn('Unable to check UUID on server: ', error);
            });
        }
      }
    };

    handleUuidChange();
  }, [debouncedUuid, dispatch, selectedItem?.uuid]);

  useEffect(() => {
    if (lastActionType === 'SITE_DELETE_SUCCESS' || lastActionType === 'SITE_ADD_SUCCESS') {
      history.goBack();
    }
  }, [history, lastActionType]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name,
      enabled,
      checkInTime: moment(checkInTime).format('HH:mm') + ':00',
      checkOutTime: moment(checkOutTime).format('HH:mm') + ':00',
      notifications: notifications.map((v) => v.value) || [],
      webCheckInEnabled,
      webCheckOutEnabled,
      webPlatformUrl,
      email,
      phoneNumber: phone.sanitizedPhoneNumber,
      conciergePhone: conciergePhone.sanitizedPhoneNumber,
      addressLine1,
      addressLine2,
      city,
      divisionId: divisionId !== -1 ? divisionId : null, // -1 is nothing
      countryId,
      addressLongitude: longitude,
      addressLatitude: latitude,
      website,
      taxInfo,
      instagramUrl,
      twitterUrl,
      facebookUrl,
      kmsEnabled,
      surcharge,
      surchargePercent,
      surchargeTransactionCode,
      autoGuestNotifications,
      zoneId: siteTimeZone.value,
      apiKey: apiKey === '' ? null : apiKey,
      themeId: themeId,
      platformId: platformId,
      paymentProviderId: paymentProviderId,
      notificationSchemeId: notificationSchemeId,
      corporationId: selectedItem ? selectedItem.corporationId : selectedCorporation.id,
      notificationReminder,
      groupingMode: receiptGroupMode,
      reservationSync,
      reservationSyncCiDays,
      reservationSyncCoDays,
      reservationSyncSchedule,
      cardOnFile,
      showCommsQr,
      securityMode,
      uuid,
      amenitiesUrl,
      sqsMessageUrlRealTime,
      mfaMode,
    };
    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const handleGenerateRandom = () => {
    setShowApiKey(true);
    setApiKey(generateRandom());
  };

  const isFormReadyForSubmit =
    name &&
    siteTimeZone &&
    addressLine1 &&
    city &&
    countryId &&
    longitude &&
    latitude &&
    themeId &&
    platformId &&
    notificationSchemeId &&
    uuidValid &&
    !uuidExists;
  const isEmailInvalid = email && !ValidationUtil.validateEmail(email);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? `${selectedCorporation?.name} - Update Site` : `${selectedCorporation?.name} - Create Site`}
        subheader={'A site is a unique property property with a PMS or LOCK system'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        buttonLoading={processing}
        disabled={!isFormReadyForSubmit}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
        deleteDisabled={!selectedItem?.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {error.message}
          </Alert>
        )}

        <Card>
          <CardContent>
            <Typography gutterBottom variant={'h6'}>
              Site Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Site Name'} value={name} setValue={setName} error={!name} required />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Site Enabled'} value={enabled} setValue={setEnabled} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  fullWidth
                  name="Site Timezone"
                  placeholder="Select timezone for site"
                  options={TimeZones}
                  getOptionSelected={(option, selected) => option.value === selected.value}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    setSiteTimeZone(newValue);
                  }}
                  value={siteTimeZone}
                  error={!siteTimeZone}
                  renderInput={(params) => <TextField {...params} label="Site Timezone" margin={'dense'} variant="outlined" />}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Site Email'}
                  value={email}
                  type={'email'}
                  setValue={setEmail}
                  error={isEmailInvalid}
                  helperText={isEmailInvalid ? 'Please enter a valid email address' : ''}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Address Line 1'} value={addressLine1} setValue={setAddressLine1} error={!addressLine1} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Address Line 2'} value={addressLine2} setValue={setAddressLine2} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'City'} value={city} setValue={setCity} error={!city} />
              </Grid>

              <FormCountryAndDivisionSelect
                countryId={countryId}
                setCountryId={setCountryId}
                divisionId={divisionId}
                setDivisionId={setDivisionId}
              />

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Longitude'} value={longitude} setValue={setLongitude} error={!longitude} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Latitude'} value={latitude} setValue={setLatitude} error={!latitude} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Website'} value={website} setValue={setWebsite} />
              </Grid>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TimePicker
                    fullWidth
                    label="Check In Start Time"
                    value={checkInTime}
                    margin={'dense'}
                    inputVariant={'outlined'}
                    onChange={(date) => setCheckInTime(date)}
                    placeholder="02:00 PM"
                    mask="__:__ _M"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TimePicker
                    fullWidth
                    label="Check Out End Time"
                    value={checkOutTime}
                    margin={'dense'}
                    inputVariant={'outlined'}
                    onChange={(date) => setCheckOutTime(date)}
                    placeholder="10:00 AM"
                    mask="__:__ _M"
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelectTheme label={'Site Theme'} value={themeId} setValue={setThemeId} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Amenities Base Url'} value={amenitiesUrl} setValue={setAmenitiesUrl} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Site UUID'}
                  value={uuid}
                  setValue={setUuid}
                  error={!uuidValid || uuidExists}
                  helperText={!uuidValid ? 'UUID has invalid characters' : uuidExists ? 'UUID Already exists' : ''}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom variant={'h6'}>
              Site Controls
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Sync Reservations'} value={reservationSync} setValue={setReservationSync} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Sync Schedule (Minutes)'}
                  type={'number'}
                  inputProps={{ step: '1' }}
                  value={reservationSyncSchedule}
                  setValue={setReservationSyncSchedule}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  type={'number'}
                  reactInputProps={{ inputProps: { min: 0 } }}
                  label={'Reservation Sync CI Days'}
                  value={reservationSyncCiDays}
                  setValue={setReservationSyncCiDays}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  type={'number'}
                  reactInputProps={{ inputProps: { min: 0 } }}
                  label={'Reservation Sync CO Days'}
                  value={reservationSyncCoDays}
                  setValue={setReservationSyncCoDays}
                />
              </Grid>

              {selectedItem?.reservationLastSync && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormField
                    label={'Reservation Last Sync'}
                    value={moment.unix(selectedItem?.reservationLastSync).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Surcharge Enabled'} value={surcharge} setValue={setSurcharge} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Auto Guest Notifications'} value={autoGuestNotifications} setValue={setAutoGuestNotifications} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Notification Reminders'} value={notificationReminder} setValue={setNotificationReminder} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  fullWidth
                  options={Object.values(SiteNotificationTemplateType).map((p) => {
                    return { value: p, label: p };
                  })}
                  getOptionSelected={(option, selected) => option.value === selected.value}
                  getOptionLabel={(option) => option.label}
                  multiple
                  defaultValue={[]}
                  onChange={(_, val) => setNotifications(val)}
                  value={notifications}
                  size={'small'}
                  renderInput={(params) => <TextField {...params} label="Notifications" margin={'dense'} variant="outlined" />}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Web Check-in'} value={webCheckInEnabled} setValue={setWebCheckInEnabled} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Web Check-Out'} value={webCheckOutEnabled} setValue={setWebCheckOutEnabled} />
              </Grid>

              {(webCheckInEnabled || webCheckOutEnabled) && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormField
                    label={'Web Platform URL'}
                    value={webPlatformUrl}
                    setValue={setWebPlatformUrl}
                    placeholder={'i.e. https://web.smartcheckmobile.com/liverton'}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Card On File'} value={cardOnFile} setValue={setCardOnFile} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NotificationSchemeSelect
                  value={notificationSchemeId}
                  setValue={setNotificationSchemeId}
                  corporationId={selectedItem ? selectedItem.corporationId : selectedCorporation?.id}
                />
              </Grid>

              {surcharge && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Surcharge Percent'}
                      type={'number'}
                      inputProps={{ step: '0.01' }}
                      value={surchargePercent}
                      setValue={setSurchargePercent}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Surcharge Transaction Code'}
                      value={surchargeTransactionCode}
                      setValue={setSurchargeTransactionCode}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  error={!receiptGroupMode}
                  value={receiptGroupMode}
                  label={'Email Receipt Mode'}
                  setValue={setReceiptGroupMode}
                  options={ReceiptGroupingModeOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  error={!securityMode}
                  value={securityMode}
                  label={'Check-In Security Mode'}
                  setValue={setSecurityMode}
                  options={CheckInSecurityModeOptions}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography gutterBottom variant={'h6'}>
              Site Extras
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PhoneNumberInput phone={conciergePhone} setPhone={setConciergePhone} isRequired={false} label={'Concierge Phone Number'} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Facebook URL'} value={facebookUrl} setValue={setFacebookUrl} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Twitter URL'} value={twitterUrl} setValue={setTwitterUrl} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Instagram URL'} value={instagramUrl} setValue={setInstagramUrl} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelectPlatform label={'Site Platform'} value={platformId} setValue={setPlatformId} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Tax Info'} value={taxInfo} setValue={setTaxInfo} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PaymentProviderSelect
                  value={paymentProviderId}
                  setValue={setPaymentProviderId}
                  corporationId={selectedItem ? selectedItem.corporationId : selectedCorporation?.id}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Show Comms QR'} value={showCommsQr} setValue={setShowCommsQr} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect error={!mfaMode} value={mfaMode} label={'MFA Mode'} setValue={setMfaMode} options={MfaModeOptions} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Enable KMS Connection'} value={kmsEnabled} setValue={setKmsEnabled} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField disabled label={'Api Endpoint'} value={selectedItem?.topicName} autoComplete="off" autoCorrect="off" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'SQS Message Queue Name (FIFO)'}
                  placeholder={'MyQueue.fifo'}
                  autoComplete="off"
                  autoCorrect="off"
                  value={sqsMessageUrlRealTime}
                  setValue={setSqsMessageUrlRealTime}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Api Key'}
                  value={apiKey}
                  setValue={setApiKey}
                  type={showApiKey ? 'text' : 'password'}
                  autoComplete="new-password"
                  autoCorrect="off"
                  reactInputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AutorenewIcon onClick={handleGenerateRandom} cursor={'pointer'} />
                        {apiKey && (
                          <IconButton onClick={() => setShowApiKey((val) => !val)} edge="end">
                            {showApiKey ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
