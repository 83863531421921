import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from 'components/form/FormSelect';
import FormField from 'components/form/FormField';
import CheckInState, { CheckInStateOptions } from '../../../../../../support/CheckInState';
import CheckOutState, { CheckOutStateOptions } from '../../../../../../support/CheckOutState';
import ReservationTrackingActions from '../../../../../redux/actions/reservationTracking/actions';
import moment from 'moment';

export default function ReservationTrackingEdit() {
  const dispatch = useDispatch();

  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.reservationTrackingReducer.lastActionType);
  const error = useSelector((state) => state.reservationTrackingReducer.error);
  const selectedItem = useSelector((state) => state.reservationTrackingReducer.selectedItem);

  const { confirmationNumber, reservationNumber } = selectedItem;
  const [checkOutState, setCheckOutState] = React.useState(selectedItem.checkOutState || CheckOutState.COMPLETED);
  const [checkInState, setCheckInState] = React.useState(selectedItem.checkInState || CheckInState.SUMMARY);

  useEffect(() => {
    dispatch(ReservationTrackingActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'SPECIAL_REQUEST_NOTIFICATION_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      checkInState,
      checkOutState,
      reservationNumber,
      siteId: selectedSite?.id,
    };

    if (selectedItem) {
      dispatch(ReservationTrackingActions.update(payload));
    }
  };

  const isFormReady = checkInState && checkOutState;

  return (
    <Card>
      <CardHeaderWithControls
        header={'Reservation Tracking'}
        subheader={''}
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to update reservation tracking - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Reservation Number'} value={reservationNumber} disabled={true} placeholder="Reservation Number" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Confirmation Number'} value={confirmationNumber} disabled={true} placeholder="Confirmation Number" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Check In State'} value={checkInState} setValue={setCheckInState} options={CheckInStateOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Check Out State'} value={checkOutState} setValue={setCheckOutState} options={CheckOutStateOptions} />
          </Grid>

          {selectedItem?.challengeCode && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField
                label={`Challenge Code (${selectedItem?.challengeAttempts || 0}) attempts`}
                value={selectedItem?.challengeCode}
                disabled
              />
            </Grid>
          )}

          {selectedItem?.challengeCodeExpiry && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Challenge Code Expiry'} value={moment.unix(selectedItem?.challengeCodeExpiry).format('LLL')} disabled />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Last Update'} value={moment.unix(selectedItem?.updated).format('LLL')} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Created'} value={moment.unix(selectedItem?.created).format('LLL')} disabled />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
