import * as Actions from '../../actions/appUsers/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  processing: false,
  error: null,
  selectedItem: null,
};

export default function appUsersReducer(state = initial, action) {
  switch (action.type) {
    case Actions.APPUSER_ACTION_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case Actions.APPUSER_GET_APPUSERS_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
        processing: false,
      };
    case Actions.APPUSER_GET_APPUSERS_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        data: [],
        error: action.payload,
        processing: false,
      };
    case Actions.APPUSER_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
      };
    case Actions.APPUSER_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.APPUSER_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((appUser) => appUser.id !== action.payload),
        processing: false,
      };
    case Actions.APPUSER_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.APPUSER_EDIT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: false,
      };
    case Actions.APPUSER_EDIT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.APPUSER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: false,
      };
    case Actions.APPUSER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.APPUSER_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.APPUSER_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    default:
      return state;
  }
}
