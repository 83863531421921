import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';

export default function ChatBubble({ classes, selectedChat, message, idx }) {
  const isAdmin = message.isSenderAdmin;
  return (
    <React.Fragment key={message._id}>
      {(idx !== 0 ? moment(message.createdAt).isAfter(selectedChat.messages[idx - 1].createdAt, 'day') : true) && (
        <div className={classes.messageDateFlexItem}>
          <Typography paragraph className={classes.messageDateText}>
            {moment(message.createdAt).format('LL')}
          </Typography>
        </div>
      )}
      <div className={isAdmin ? classes.portalUserMessageFlexItem : classes.appUserMessageFlexItem}>
        <div className={isAdmin ? classes.portalUserMessageBorder : classes.appUserMessageBorder}>
          <Typography>{message.text}</Typography>
          <Typography variant={'caption'} className={classes.guestName}>
            {`${message?.user?.name} - ${moment(message.createdAt).format('LT')}`}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}
