import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

const RoomSelectionActions = {
  ...actionCreator.createActions('RoomSelection', 'ROOM_SELECTION', '/api/private/portal/roomSelection'),
  selectById: (item) => async (dispatch) => {
    try {
      const response = await API.get('PrivateAPI', `/api/private/portal/roomSelection/${item}`, {});
      await dispatch({ type: 'ROOM_SELECTION_SELECT', payload: response });
    } catch (error) {
      dispatch({ type: 'ROOM_SELECTION_FETCH_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default RoomSelectionActions;
