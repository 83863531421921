import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodDiscountActions from '../../../../redux/actions/inRoom/ordering/foodDiscount/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import { PortionType, PortionTypes } from '../../../../../support/PortionTypes';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import { ComputationInclusion, ComputationInclusions } from '../../../../../support/ComputationInclusion';
import { DiscountRuleOptions } from '../../../../../support/DiscountRules';

export default function FoodDiscountEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodDiscountReducer);
  const [discountName, setDiscountName] = useState(selectedItem?.discountName || '');
  const [discountCode, setDiscountCode] = useState(selectedItem?.discountCode || '');
  const [discountAmount, setDiscountAmount] = useState(selectedItem?.discountAmount || 0);
  const [discountType, setDiscountType] = useState(selectedItem?.discountType || PortionType.FIXED);
  const [discountInclusion, setDiscountInclusion] = useState(selectedItem?.discountInclusion || ComputationInclusion.CHECK);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [sequence, setSequence] = useState(selectedItem?.sequence || 1);
  const [discountRule, setDiscountRule] = useState(selectedItem?.discountRule || null);

  useEffect(() => {
    dispatch(FoodDiscountActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_DISCOUNT_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_DISCOUNT_ADD_SUCCESS' ||
      lastActionType === 'FOOD_DISCOUNT_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      discountName,
      discountCode,
      discountAmount,
      discountType,
      discountInclusion,
      enabled,
      sequence,
      discountRule,
    };

    if (selectedItem) {
      dispatch(FoodDiscountActions.update(payload, `/api/private/portal/food/discount/${selectedItem.id}`, false));
    } else {
      dispatch(FoodDiscountActions.add(payload, { vendorId: vendorId }));
    }
  };

  const isFormReady = discountName && discountCode && (discountAmount || discountAmount === 0) && discountType && discountInclusion;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Discount' : 'Create Food Discount'}
        subheader={'A Food Discount contains fixed or percentage amount to be included in a check'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodDiscountActions.delete(selectedItem, `/api/private/portal/food/discount/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food discount - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={discountName} setValue={setDiscountName} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'External ID'} value={selectedItem?.externalId || ''} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Code'} value={discountCode} setValue={setDiscountCode} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Type'} value={discountType} setValue={setDiscountType} options={PortionTypes} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={discountAmount === 0 ? 'Open Amount' : 'Amount'}
              value={discountAmount}
              setValue={setDiscountAmount}
              type={'number'}
              inputProps={{ step: '1' }}
              reactInputProps={{
                endAdornment: discountType === PortionType.RATE && <InputAdornment position="end">%</InputAdornment>,
                startAdornment: discountType === PortionType.FIXED && <InputAdornment position="start">$</InputAdornment>,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Inclusion'}
              value={discountInclusion}
              setValue={setDiscountInclusion}
              options={ComputationInclusions}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Discount Rule'} value={discountRule} setValue={setDiscountRule} options={DiscountRuleOptions} hasClear />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sequence'} value={sequence} setValue={setSequence} type={'number'} inputProps={{ step: '1' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
