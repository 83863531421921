export const SystemCalendarEvent = {
  GLOBAL_OUTAGE: 'GLOBAL_OUTAGE',
  CI_CO_OUTAGE: 'CI_CO_OUTAGE',
  FNB_OUTAGE: 'FNB_OUTAGE',
};

export const SystemCalendarEventOptions = [
  { label: 'Global Outage', value: SystemCalendarEvent.GLOBAL_OUTAGE },
  { label: 'Check In/Out Outage', value: SystemCalendarEvent.CI_CO_OUTAGE },
  { label: 'F&B Outage', value: SystemCalendarEvent.FNB_OUTAGE },
];

export const SystemCalendarType = {
  GLOBAL: 'GLOBAL',
  ORGANISATION: 'ORGANISATION',
  SITE: 'SITE',
};
