module.exports = {
  RATE_CODE_MATCHES: {
    code: 1,
    displayName: 'Rate Code Matches',
  },
  ROOM_TYPE_MATCHES: {
    code: 2,
    displayName: 'Room Type Matches',
  },
  RES_TYPE_MATCHES: {
    code: 3,
    displayName: 'Res Type Matches',
  },
  ORIGIN_MATCHES: {
    code: 4,
    displayName: 'Origin Matches',
  },
  MARKET_CODE_MATCHES: {
    code: 5,
    displayName: 'Market Code Matches',
  },
  COMPANY_MATCHES: {
    code: 6,
    displayName: 'Company Matches',
  },
  TRAVEL_AGENT_MATCHES: {
    code: 7,
    displayName: 'Travel Agent Matches',
  },
  UDF_EXIST: {
    code: 8,
    displayName: 'UDF Exists',
  },
  SOURCE_CODE_MATCHES: {
    code: 9,
    displayName: 'Source Matches',
  },
  RESERVATION_SOURCE_MATCHES: {
    code: 10,
    displayName: 'Reservation Source Matches',
  },
  GROUP_MATCHES: {
    code: 11,
    displayName: 'Group Matches',
  },
  RESERVATION_BILL_BALANCE: {
    code: 12,
    displayName: 'Reservation Has Bill Balance',
  },
  HAS_OTA_EMAIL: {
    code: 13,
    displayName: 'Reservation Has OTA Email Address',
  },
  OTHER_PAYMENT_TYPE: {
    code: 14,
    displayName: 'Other Payment Type',
  },
  HAS_PACKAGE: {
    code: 15,
    displayName: 'Reservation Has Package',
  },
  PAYMENT_WINDOW_2_MATCH: {
    code: 16,
    displayName: 'Payment Window 2 Check',
  },
  HAS_SPECIAL: {
    code: 17,
    displayName: 'Reservation Has Special',
  },
  HAS_MEMBERSHIP_TYPE: {
    code: 18,
    displayName: 'Has Membership Type',
  },
  HAS_MEMBERSHIP_LEVEL: {
    code: 19,
    displayName: 'Has Membership Level',
  },
  ROOM_COUNT_MATCH: {
    code: 20,
    displayName: 'Number Of Rooms',
  },
  HAS_PAYMENT_MODE: {
    code: 21,
    displayName: 'Has Payment Mode',
  },
  UDF_MATCH: {
    code: 22,
    displayName: 'User Defined Field Match',
  },
  HAS_ADULT: {
    code: 23,
    displayName: 'Has Adult',
  },
  HAS_CHILD: {
    code: 24,
    displayName: 'Has Child',
  },
  MULTI_BILL_WINDOWS: {
    code: 25,
    displayName: 'Has Multiple Bill Windows',
  },
  HAS_ROUTING_RULE: {
    code: 26,
    displayName: 'Has Routing Rule',
  },
  HAS_RESERVATION_STATUS: {
    code: 27,
    displayName: 'Has Reservation Status',
  },
  ROOM_NUMBER_MATCHES: {
    code: 28,
    displayName: 'Room Number Matches',
  },
  NUMBER_OF_NIGHTS_MATCHES: {
    code: 29,
    displayName: 'Number of Nights Matches',
  },
  CARD_HOLDER_MATCHES: {
    code: 30,
    displayName: 'Card Holder Matches',
  },
  HAS_ROUTING_RULE_OWNER: {
    code: 31,
    displayName: 'Has Routing Rule Owner',
  },
  CREATED_RESERVATION_HOURS_MATCH: {
    code: 32,
    displayName: 'Reservation Created Time',
  },
  HAS_EMAIL_MATCH: {
    code: 33,
    displayName: 'Reservation Has Email Address',
  },
};
