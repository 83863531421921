import React from 'react';
import { CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Charts from '../Charts';
import { useTheme } from '@material-ui/core';
import ChartTable from './ChartTable';

const LivertonLineChart = ({ data = [], tableView, columnOverrides }) => {
  const currentTheme = useTheme();

  return (
    <>
      <ResponsiveContainer
        minWidth={Charts.getDefaultChartDimension()}
        minHeight={Charts.getDefaultChartDimension()}
        width="100%"
        height="100%"
      >
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis stroke={currentTheme.palette.text.secondary} fontSize="80%" />
          <XAxis dataKey="x" stroke={currentTheme.palette.text.secondary} fontSize="80%" />
          <Legend />
          <Tooltip />
          isAnimationActive={true}
          {Charts.getCategoryForChartWithType(data, 'x', 'line')}
        </LineChart>
      </ResponsiveContainer>
      <ChartTable visible={tableView} chartData={data} columnOverrides={columnOverrides} />
    </>
  );
};

export default LivertonLineChart;
