import reducerCreator from '../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Event Hook', 'EVENT_HOOK');

function eventHookReducer(state = initial, action) {
  switch (action.type) {
    case `EVENT_HOOK_SELECT_ALL_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    }
    case `EVENT_HOOK_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload,
      };
    }
    case `EVENT_HOOK_SELECT_ALL_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default eventHookReducer;
