import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Chip, Link } from '@material-ui/core';
import API from '@aws-amplify/api';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';
import { useHistory } from 'react-router-dom';
import FoodMenuMappingActions from '../../../../redux/actions/inRoom/ordering/menuMapping/actions';
import FoodItemActions from '../../../../redux/actions/inRoom/ordering/foodItem/actions';
import { Check } from '@material-ui/icons';

const handleClick = (value, menuId) => (dispatch) => async (event) => {
  let response;
  try {
    if (event.target.checked) {
      response = await API.post('PrivateAPI', `/api/private/portal/food/menu/${menuId}/mapping`, { body: value.id });
      value.included = true;
    } else {
      response = await API.del('PrivateAPI', `/api/private/portal/food/menu/${menuId}/mapping`, { body: value.id });
      value.included = false;
    }

    dispatch(FoodMenuMappingActions.toggle(value));
    console.info('Successfully updated food menu mapping', response);
  } catch (error) {
    dispatch(FoodMenuMappingActions.toggleError(error));
    console.warn('Unable to update food menu mapping due to error', error);
  }
};

const columns = (history, menuId) => {
  return [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value, dispatch) {
        return (
          <Link component="button" onClick={() => dispatch(FoodItemActions.selectAndNavigate(value, history))} children={value.name} />
        );
      },
    },
    {
      id: 'price',
      label: 'Price',
      align: 'center',
      dataType: DataTypes.DATA_TYPE_FLOAT,
    },
    {
      id: 'capacity',
      label: 'Capacity',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'available',
      label: 'Available',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.available) {
          return <Check />;
        }
        return null;
      },
    },
    {
      id: 'tags',
      label: 'Tags',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.tags && value.tags.length > 0) {
          return value.tags.map((val) => <Chip key={val} label={val} size="small" />);
        }
        return null;
      },
    },
    {
      id: 'included',
      label: 'Included',
      align: 'center',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value, dispatch) {
        return <Checkbox checked={value.included} onChange={dispatch(handleClick(value, menuId))} />;
      },
    },
  ];
};

const getFilterFieldsForScreen = (state, setState, options) => {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [getFilterField('query', 'Search Name or Tag', DataTypes.DATA_TYPE_STRING, filterUpdateMethods)];
};

export default function FoodMenuMapping() {
  const dispatch = useDispatch();
  const history = useHistory();
  const activityMapping = useSelector((state) => state.foodMenuMappingReducer.entityList);
  const processing = useSelector((state) => state.foodMenuMappingReducer.processing);
  const page = useSelector((state) => state.foodMenuMappingReducer.page);
  const error = useSelector((state) => state.foodMenuMappingReducer.error);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const menu = useSelector((state) => state.foodMenuReducer.selectedItem);
  const menuId = menu?.id;
  const [filterState, setFilterState] = useState({
    query: '',
  });

  useEffect(() => {
    const params = { vendorId: vendorId, menuId: menuId };
    if (filterState['query']) {
      params.query = filterState['query'];
    }

    dispatch(FoodMenuMappingActions.fetch(0, 50, params));
  }, [dispatch, filterState, vendorId, menuId]);

  const filters = getFilterFieldsForScreen(filterState, setFilterState);

  return (
    <AutoTable
      title="Food Menu Mapping"
      subheader={'Shows the available items on the selected menu'}
      filters={filters}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) =>
        dispatch(FoodMenuMappingActions.fetch(newPage, pageSize, { vendorId: vendorId, menuId: menuId, query: filterState.query }, {}))
      }
      page={page}
      error={error}
      columns={columns(history, menuId)}
      prefix="FOOD_MENU_MAPPING"
      data={activityMapping}
    />
  );
}
