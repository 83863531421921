import reducerCreator from '../../../../support/reducerCreator';
import { FOOD_ITEM_SEARCH_FAILURE } from '../../../../actions/inRoom/ordering/foodItem/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Item', 'FOOD_ITEM');

function foodItemReducer(state = initial, action) {
  switch (action.type) {
    case FOOD_ITEM_SEARCH_FAILURE:
      return {
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default foodItemReducer;
