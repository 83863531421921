import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/securityRoomMapping/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';

export default function SecurityRoomMappingEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.securityRoomMappingReducer.lastActionType);
  const error = useSelector((state) => state.securityRoomMappingReducer.error);
  const selectedItem = useSelector((state) => state.securityRoomMappingReducer.selectedItem);
  const siteDoorList = useSelector((state) => state.siteDoorReducer.entityList.filter((s) => s.visible));
  const securityDoorList = useSelector((state) => state.securityDoorReducer.entityList.filter((s) => s.visible));

  const [id] = React.useState(selectedItem?.id);
  const [lockDoorId, setLockDoorId] = React.useState(selectedItem?.lockDoorId || '');
  const [securityDoorId, setSecurityDoorId] = React.useState(selectedItem?.securityDoorId || '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'SECURITY_ROOM_MAPPING_UPDATE_SUCCESS' || lastActionType === 'SECURITY_ROOM_MAPPING_DELETE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      id,
      lockDoorId,
      securityDoorId,
      siteId: selectedSite?.id,
    };

    dispatch(Actions.update(payload));
  };

  const isFormReady = lockDoorId && securityDoorId;

  return (
    <Card>
      <CardHeaderWithControls
        header={'Update Room Mapping'}
        subheader={'Mapping room available at the site'}
        buttonName={'Update'}
        disabled={!isFormReady}
        onClick={handleSubmit}
        deleteDisabled={false}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit door - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Lock Room'}
              margin={'none'}
              value={lockDoorId}
              setValue={setLockDoorId}
              options={siteDoorList.map((d) => {
                return { label: d.doorName, value: d.id };
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Security Room'}
              margin={'none'}
              value={securityDoorId}
              setValue={setSecurityDoorId}
              options={securityDoorList.map((d) => {
                return { label: d.doorName, value: d.id };
              })}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
