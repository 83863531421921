import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SITE_FEATURES_FETCH_SUCCESS = 'SITE_FEATURES_FETCH_SUCCESS';
export const SITE_FEATURES_FETCH_FAILURE = 'SITE_FEATURES_FETCH_FAILURE';

export const SITE_FEATURES_TOGGLE_SUCCESS = 'SITE_FEATURES_TOGGLE_SUCCESS';
export const SITE_FEATURES_TOGGLE_FAILURE = 'SITE_FEATURES_TOGGLE_FAILURE';

export const SITE_FEATURES_CLEAR_ERROR = 'SITE_FEATURES_CLEAR_ERROR';

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_FEATURES_CLEAR_ERROR, payload: {} });
};

export const getSiteFeatures = (siteId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/features?siteId=${siteId}`;

  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({ type: SITE_FEATURES_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_FEATURES_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const toggleFeature = (enable, siteId, feature) => async (dispatch) => {
  const requestUrl = `/api/private/portal/features/toggle?siteId=${siteId}&featureId=${feature.id}&enable=${enable}`;

  try {
    await API.post('PrivateAPI', requestUrl, {});
    dispatch({
      type: SITE_FEATURES_TOGGLE_SUCCESS,
      payload: {
        feature: feature,
        siteId: siteId,
        enable: enable,
      },
    });
  } catch (error) {
    dispatch({ type: SITE_FEATURES_TOGGLE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};
