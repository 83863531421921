import actionCreator from '../../support/actionCreator';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';
import API from '@aws-amplify/api';

const PaymentActions = {
  ...actionCreator.createActions('Payment Provider', 'PAYMENT_PROVIDER', '/api/private/portal/payment/providers'),
  duplicate: (item) => async (dispatch) => {
    Objects.notNull(item, 'item cannot be null');

    Swal.fire({
      title: 'Duplicate payment provider',
      text: `Are you sure you want to duplicate the payment provider - ${item.name}?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, Duplicate!',
      cancelButtonText: 'No, Cancel',
      heightAuto: false,
    }).then(async function (result) {
      if (result.value) {
        dispatch({ type: `PAYMENT_PROVIDER_ADD_IN_PROGRESS` });
        try {
          const response = await API.post('PrivateAPI', `/api/private/portal/payment/providers/${item.id}/duplicate`, {});
          dispatch({ type: `PAYMENT_PROVIDER_ADD_SUCCESS`, payload: response });
        } catch (error) {
          dispatch({
            type: `PAYMENT_PROVIDER_ADD_FAILURE`,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        }
      }
    });
  },
};

export default PaymentActions;
