const FlowState = {
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
};

const FlowStateOptions = [
  { value: FlowState.CHECK_OUT, label: 'Check - OUT' },
  { value: FlowState.CHECK_IN, label: 'Check - IN' },
];

const SelectState = {
  YES: 'YES',
  NO: 'NO',
};

const SelectStateOptions = [
  { value: SelectState.YES, label: 'YES' },
  { value: SelectState.NO, label: 'NO' },
];

const SelectTrueFalseOptions = [
  { value: true, label: 'YES' },
  { value: false, label: 'NO' },
];

export default FlowState;
export { FlowStateOptions, SelectStateOptions, SelectTrueFalseOptions, SelectState };
