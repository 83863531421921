import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../../redux/actions/upsell/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import toBase64 from '../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import LanguageOverrideCard from '../language/LanguageOverrideCard';
import PackageMode from '../../../../../support/PackageMode';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../components/form/FormSelect';
import PackageCategory, { PackageCategoryList } from '../../../../../support/PackageCategory';
import PackageCalculationRuleType, { PackageCalculationRuleTypeOptions } from '../../../../../support/PackageCalculationRuleType';
import PackageValidDaysTarget, { PackageValidDaysTargetOptions } from '../../../../../support/PackageValidDaysTarget';
import { DaysOfWeekMedium, EverydayMedium } from '../../../../../support/DaysOfWeekShort';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';

const PACKAGE_MODE_OPTIONS = [
  { label: 'Quantity', value: PackageMode.QUANTITY },
  { label: 'Full Stay', value: PackageMode.FULL_STAY },
];

export default function SitePackageUpsell() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const lastActionType = useSelector((state) => state.siteUpsellPackagesReducer.lastActionType);
  const error = useSelector((state) => state.siteUpsellPackagesReducer.error);
  const selectedItem = useSelector((state) => state.siteUpsellPackagesReducer.selectedItem);
  const processing = useSelector((state) => state.siteUpsellPackagesReducer.processing);

  const [name, setName] = useState(selectedItem?.name || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [code, setCode] = useState(selectedItem?.code || '');
  const [secondaryCodes, setSecondaryCodes] = useState(selectedItem?.secondaryCodes || '');
  const [maxQuantity, setMaxQuantity] = useState(selectedItem?.maxQuantity || 10);
  const [orderNumber, setOrderNumber] = useState(selectedItem?.orderNumber || 1);
  const [packageDescriptions, setPackageDescriptions] = useState(selectedItem ? selectedItem.packageDescriptionList : []);
  const [fileSelected, setFileSelected] = useState(null);
  const [price, setPrice] = useState(selectedItem?.price || null);
  const [additionalCode, setAdditionalCode] = useState(selectedItem?.additionalCode || null);
  const [category, setCategory] = useState(selectedItem?.category || PackageCategory.FOOD);
  const [packageMode, setPackageMode] = useState(selectedItem?.packageMode || PackageMode.QUANTITY);
  const [calculationRuleType, setCalculationRuleType] = useState(
    selectedItem?.calculationRuleType || PackageCalculationRuleType.USE_PMS_CALCULATION
  );
  const [validDaysOfWeek, setValidDaysOfWeek] = useState(selectedItem?.validDaysOfWeek?.split(',') || EverydayMedium);
  const [validDaysTarget, setValidDaysTarget] = useState(selectedItem?.validDaysTarget || PackageValidDaysTarget.ARRIVAL_ONLY);
  const [captureExtras, setCaptureExtras] = useState(selectedItem?.captureExtras || false);
  const [emailNotification, setEmailNotification] = useState(selectedItem?.emailNotification || false);
  const [emailDisplay, setEmailDisplay] = useState(selectedItem?.emailDisplay || false);
  const [path, setPath] = useState(selectedItem?.path || null);
  const [enabled, setEnabled] = useState(selectedItem ? selectedItem.enabled : true);

  useEffect(() => {
    dispatch(Actions.clearError());
    return () => {
      dispatch(Actions.clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.SITE_PACKAGE_DELETE_SUCCESS ||
      lastActionType === Actions.SITE_PACKAGE_UPDATE_SUCCESS ||
      lastActionType === Actions.SITE_PACKAGE_CREATE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleDeletePackage = () => {
    if (selectedSiteId) {
      dispatch(Actions.deleteSitePackage(selectedSiteId, selectedItem.id));
    }
  };

  const handleSubmit = async () => {
    const fileToSend = fileSelected ? await toBase64(fileSelected) : null;
    const fileName = fileSelected ? fileSelected.name : null;

    const payload = {
      ...selectedItem,
      id: selectedItem ? selectedItem.id : null,
      siteId: selectedSiteId,
      name,
      description,
      code,
      secondaryCodes,
      maxQuantity,
      orderNumber,
      packageDescriptionList: packageDescriptions,
      image: fileToSend ? fileToSend : path,
      imageName: fileName ? fileName : path ? path.substring(path.lastIndexOf('/') + 1) : null,
      price,
      additionalCode,
      packageMode,
      calculationRuleType: calculationRuleType || null,
      category,
      captureExtras,
      emailNotification,
      emailDisplay,
      validDaysOfWeek: validDaysOfWeek?.join(','),
      validDaysTarget,
      enabled,
    };

    if (selectedItem?.id) {
      dispatch(Actions.updateSitePackage(payload));
    } else {
      dispatch(Actions.createSitePackage(payload));
    }
  };

  const handleAddDescription = useCallback(() => {
    setPackageDescriptions(packageDescriptions.concat([{ name: name, description: '', locale: '' }]));
  }, [name, packageDescriptions]);

  const handleDescriptionChange = (index) => (event) => {
    setPackageDescriptions(
      packageDescriptions.map(function (pd, pdIdx) {
        if (index !== pdIdx) {
          return pd;
        } else {
          return {
            ...pd,
            [event.target.name]: event.target.value,
          };
        }
      })
    );
  };

  const handleDeleteDescription = (idx) => {
    setPackageDescriptions(packageDescriptions.filter((x, index) => index !== idx));
  };

  const isImagePresent = selectedItem?.id ? true : fileSelected;
  const isPackageFormReady = name && description && code && orderNumber && isImagePresent;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={selectedItem?.id ? 'Edit Upgrade Package' : 'Create Upgrade Package'}
            subheader={'Upgrades are presented to guests after the pre check-in process'}
            buttonName={selectedItem?.id ? 'Update' : 'Create'}
            disabled={!isPackageFormReady}
            onClick={handleSubmit}
            deleteDisabled={!selectedItem}
            buttonLoading={processing}
            onDelete={handleDeletePackage}
          />
          <CardContent>
            {error && (
              <Alert severity="error" variant={'filled'} className={'mb-2'}>{`Unable to add upsell package - ${error.message}`}</Alert>
            )}

            {calculationRuleType !== PackageCalculationRuleType.USE_PMS_CALCULATION && (
              <Alert
                severity="warning"
                variant={'filled'}
                className={'mb-2'}
              >{`The upsell amount will be modified based on the calculation rule`}</Alert>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Package Name'} type={'text'} value={name} setValue={setName} error={!name} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Package Code'} type={'text'} value={code} setValue={setCode} error={!code} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Secondary Package Codes'}
                  placeholder="Enter secondary codes seperated by a comma (,)"
                  type={'text'}
                  value={secondaryCodes}
                  setValue={setSecondaryCodes}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Sundry ID (Optional)'}
                  value={additionalCode}
                  setValue={setAdditionalCode}
                  type={'text'}
                  placeholder="Misc ID or Sundry ID of the package"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Maximum Quantity'} type={'number'} value={maxQuantity} setValue={setMaxQuantity} />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label={'Package Description'}
                  value={description}
                  setValue={setDescription}
                  multiline
                  rows={4}
                  error={!description}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Price'} type={'number'} value={price} setValue={setPrice} inputProps={{ step: 0.01 }} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect label={'Package Category'} value={category} setValue={setCategory} options={PackageCategoryList} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect label={'Package Mode'} value={packageMode} setValue={setPackageMode} options={PACKAGE_MODE_OPTIONS} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Calculate Rule'}
                  value={calculationRuleType}
                  setValue={setCalculationRuleType}
                  options={PackageCalculationRuleTypeOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Capture Extras (Date And Notes)'} value={captureExtras} setValue={setCaptureExtras} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormMultiChipSelect
                  label={'Days Of Week'}
                  value={validDaysOfWeek}
                  setValue={setValidDaysOfWeek}
                  options={DaysOfWeekMedium}
                  displayValue={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Valid Days Target'}
                  value={validDaysTarget}
                  setValue={setValidDaysTarget}
                  options={PackageValidDaysTargetOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Notify Reception On Purchase'} value={emailNotification} setValue={setEmailNotification} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Highlight on emails'} value={emailDisplay} setValue={setEmailDisplay} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Order Number'} type={'number'} value={orderNumber} setValue={setOrderNumber} />
              </Grid>

              <FileImageSelector
                sourceItem={selectedItem}
                sourceItemProperty={'image'}
                displayName={'Upsell Image'}
                onSelect={(file) => setFileSelected(file)}
                imageCleared={(value) => {
                  if (value) {
                    setPath(null);
                  }
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <LanguageOverrideCard
        existingList={packageDescriptions}
        addOverride={handleAddDescription}
        deleteOverride={handleDeleteDescription}
        onFieldChange={handleDescriptionChange}
      />
    </Grid>
  );
}
