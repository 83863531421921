import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, Divider, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import FormField from '../../../../../../components/form/FormField';
import API from '@aws-amplify/api';

export default function ReservationJson() {
  const selectedItem = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [siteId] = useState(selectedItem?.siteId || selectedSiteId);
  const [reservationJson, setReservationJson] = useState('');

  useEffect(() => {
    API.get('PrivateAPI', `/api/private/portal/${siteId}/reservations/${selectedItem.reservationNumber}/json`)
      .then((response) => {
        setReservationJson(JSON.stringify(response, null, 4));
      })
      .catch((error) => {
        console.error('Unable to fetch the reservation json');
      });
  }, [selectedItem.reservationNumber, siteId]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardHeaderWithControls header={'Reservation Data/Debug'} subheader={'Reservation details Json format'} backHidden={true} />

        <Divider variant={'middle'} />
        <CardContent>
          <FormField value={reservationJson} multiline={true} reactInputProps={{ readOnly: true }} />
        </CardContent>
      </Card>
    </Grid>
  );
}
