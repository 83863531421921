import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/appUserAddress/actions';
import AutoTable from '../../../../../components/table/AutoTable';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.delete(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'friendlyName',
    label: 'Friendly Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'addressLine1',
    label: 'Address Line1',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'addressLine2',
    label: 'Address Line2',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'city',
    label: 'City',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'postCode',
    label: 'Post Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'countryCode',
    label: 'Country Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function AppUserAddressList() {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const page = useSelector((state) => state.appUserAddressReducer.page);
  const error = useSelector((state) => state.appUserAddressReducer.error);
  const processing = useSelector((state) => state.appUserAddressReducer.processing);
  const lastActionType = useSelector((state) => state.appUserAddressReducer.lastActionType);
  const requests = useSelector((state) => state.appUserAddressReducer.entityList);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 25, { userId: selectedItem.id }));
    }
  }, [dispatch, selectedItem]);

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedItem) {
      dispatch(Actions.fetch(newPage, pageSize, { userId: selectedItem.id }));
    }
  };

  return (
    <AutoTable
      title="Address Book "
      subheader={'Address list assigned to this user account'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="APP_USER_ADDRESS"
      newButtonName="New Address"
      createNewURL="/app-user/address/new"
      detailURL="/app-user/address/view/{id}"
      idColumn={'id'}
      data={requests}
      lastActionType={lastActionType}
    />
  );
}
