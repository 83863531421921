import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, TextareaAutosize } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from 'components/form/FormField';
import GuestOrdersActions from '../../../../../redux/actions/guestOrders/actions';
import moment from 'moment/moment';
import AppConfig from '../../../../../../AppConfig';
import AutoTable from '../../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../../constants/DataTypes';
import Currency from '../../../../../../support/Currency';
import { Sync } from '@material-ui/icons';
import FoodOrderStatus from '../../../../../../support/FoodOrderStatus';
import { useParams } from 'react-router-dom';
import * as AccessActions from '../../../../../redux/actions/access/actions';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CartUtil from '../../../../../../support/CartUtil';
import OrderPaymentMode, { formatOrderPaymentMode } from '../../../../../../support/OrderPaymentMode';
import { formatLocationType } from '../../../../../../support/FoodOrderLocationType';
import UserRoleHelper from '../../../../../../support/UserRoleHelper';
import UserRole from '../../../../../../support/UserRole';

const getChoices = (choices, subModifiers, currency) => {
  let items = [];
  choices?.forEach((choice) => {
    items.push(
      <Box key={`oc-ch-${choice.id}`}>
        {choice.name} (+${new Currency(choice.price, currency).format()})
        <Box
          style={{
            paddingLeft: '1em',
            borderLeft: 1,
            borderLeftColor: 'black',
          }}
        >
          {subModifiers
            ?.filter((sm) => sm.parent === choice.setItemId)
            .map((modifier) => `${modifier.name} (+${new Currency(modifier.price, currency).format()})`)
            .join(', ')}
        </Box>
      </Box>
    );
  });

  return items;
};

const itemColumns = (currency) => [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'price',
    label: 'Unit Price',
    align: 'left',
    width: 150,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return new Currency(values.price, currency).format('$');
    },
  },
  {
    id: 'choices',
    label: 'Choices Breakdown',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return getChoices(values.choices, values.subModifiers, currency);
    },
  },
  {
    id: 'modifiers',
    label: 'Modifiers Breakdown',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return [...values.modifiers].map((modifier) => `${modifier.name} (+${new Currency(modifier.price, currency).format()})`).join(', ');
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
    width: 50,
  },
  {
    id: 'price',
    label: 'Total Price',
    align: 'left',
    width: 150,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return CartUtil.getCartItemTotal(values, currency).format('$');
    },
  },
  {
    id: 'discountedPrice',
    label: 'Discounted Price',
    align: 'left',
    width: 150,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      if (!values.discountedPrice && CartUtil.getCartDiscountedItemTotal(values, currency)?.amount < 1) {
        return null;
      }

      return CartUtil.getCartDiscountedItemTotal(values, currency).format('$');
    },
  },
  {
    id: 'notes',
    label: 'Notes',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const taxAndChargeColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    width: '25%',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'value',
    label: 'Price',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Currency.transform(values.value).format('$');
    },
  },
];

const discountsColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    width: '25%',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'value',
    label: 'Price',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Currency.transform(values.value).format('$');
    },
  },
];

export default function GuestOrderView() {
  const dispatch = useDispatch();

  const [showOrderResponse, setShowOrderResponse] = useState(false);
  const [showOrderRequest, setShowOrderRequest] = useState(false);
  const lastActionType = useSelector((state) => state.guestOrdersReducer.lastActionType);
  const error = useSelector((state) => state.guestOrdersReducer.error);
  const receiptError = useSelector((state) => state.guestOrdersReducer.receiptError);
  const selectedItem = useSelector((state) => state.guestOrdersReducer.selectedItem);
  const processing = useSelector((state) => state.guestOrdersReducer.processing);
  const profile = useSelector((state) => state.accessReducer.profile);
  const { id } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const emailSiteId = queryParameters.get('emailSiteId');
  const emailOrgId = queryParameters.get('emailOrgId');

  useEffect(() => {
    let orgId = parseInt(emailOrgId);
    let emailSite = parseInt(emailSiteId);
    if (orgId && emailSite) {
      dispatch(AccessActions.selectCorpAndSite(orgId, emailSite));
      dispatch(GuestOrdersActions.selectById(id));
    }
  }, [dispatch, emailOrgId, emailSiteId, id]);

  useEffect(() => {
    dispatch(GuestOrdersActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'GUEST_ORDERS_REPROCESS_SUCCESS') {
      dispatch(GuestOrdersActions.selectById(id));
    }
  }, [lastActionType, dispatch, id]);

  const sanitizedOrderResponse = useMemo(() => {
    if (!selectedItem?.orderResponse) {
      return null;
    }

    try {
      return JSON.stringify(JSON.parse(selectedItem.orderResponse), null, 4);
    } catch (error) {
      return selectedItem.orderResponse;
    }
  }, [selectedItem?.orderResponse]);

  const sanitizedOrderRequest = useMemo(() => {
    if (!selectedItem?.requestPayload) {
      return null;
    }

    try {
      return JSON.stringify(JSON.parse(selectedItem.requestPayload), null, 4);
    } catch (error) {
      return selectedItem.requestPayload;
    }
  }, [selectedItem?.requestPayload]);

  const {
    reference,
    externalReference,
    origin,
    status,
    batchStatus,
    customerDetails,
    locationType,
    locationCode,
    orderSendTime,
    orderDeliveryTime,
    lastModifiedDate,
    createdDate,
    orderItems = [],
    total,
    taxes = [],
    charges,
    discountSummary, // Discount Summary including check and item discounts
    attempts,
    paymentReference,
    paymentCode,
    paymentMode,
    vendorName,
  } = selectedItem ? selectedItem : {};

  const currency = total?.currency || Currency.defaultCurrency();

  return (
    <Card>
      <CardHeaderWithControls
        header={`Guest Order - ${reference}`}
        subheader={'Order details made by a guest'}
        buttonName={'Reprocess'}
        buttonIcon={<Sync />}
        onClick={() => dispatch(GuestOrdersActions.reprocessOrder(id))}
        buttonLoading={processing}
        disabled={status === FoodOrderStatus.COMPLETED}
        secondaryButtonIcon={<LocalAtmIcon />}
        secondaryButtonName={'Refund'}
        secondaryButtonDisabled={
          status !== FoodOrderStatus.FAILED || processing || !UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_ADMIN)
        }
        secondaryButtonAction={OrderPaymentMode.ON_FILE !== paymentMode ? () => dispatch(GuestOrdersActions.refundOrder(id)) : null}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to fetch guest order - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Order Reference'} value={reference} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'POS Reference'} value={externalReference} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Room/Table/Area'} value={locationCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Location Type'} value={formatLocationType(locationType)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Status'} value={status} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Batch Status'} value={batchStatus} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Total'} value={Currency.transform(total).format()} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Order Channel'} value={origin} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Payment Reference'} value={paymentReference || ''} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Payment Mode'} value={formatOrderPaymentMode(paymentMode)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Payment Code'} value={paymentCode || ''} />
          </Grid>

          {customerDetails?.reservationNumber && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Reservation Number'} value={customerDetails?.reservationNumber} />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Customer Name'} value={customerDetails?.customerName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Customer Email'} value={customerDetails?.customerEmail} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Customer Phone'} value={customerDetails?.customerPhone} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Taxes'} value={taxes.map((t) => `${t.name} @ ${Currency.transform(t.value).format()}`).join(',')} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Vendor Name'} value={vendorName} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Send Time'} value={moment.unix(orderSendTime).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Delivery Time'} value={moment.unix(orderDeliveryTime).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Updated'} value={moment.unix(lastModifiedDate).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Created'} value={moment.unix(createdDate).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sync Attempts'} value={attempts} />
          </Grid>

          {selectedItem?.requestPayload && (
            <Grid item xs={6} sm={6}>
              <Button variant={'contained'} color={'default'} onClick={() => setShowOrderRequest(!showOrderRequest)}>
                View Order Request
              </Button>

              {showOrderRequest && (
                <Box pt={1}>
                  <TextareaAutosize label={'Order Request'} value={sanitizedOrderRequest} multiline style={{ width: '100%' }} />
                </Box>
              )}
            </Grid>
          )}
          {selectedItem?.orderResponse && (
            <Grid item xs={selectedItem?.requestPayload ? 6 : 12} sm={selectedItem?.requestPayload ? 6 : 12}>
              <Button variant={'contained'} color={'default'} onClick={() => setShowOrderResponse(!showOrderResponse)}>
                View Order Response
              </Button>

              {showOrderResponse && (
                <Box pt={1}>
                  <TextareaAutosize label={'Order Response'} value={sanitizedOrderResponse} multiline style={{ width: '100%' }} />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </CardContent>
      <AutoTable
        title="Food Items"
        subheader={'Individual items added to the food order cart'}
        backHidden={true}
        data={orderItems}
        error={receiptError}
        columns={itemColumns(currency)}
        buttonName={'Download receipt'}
        onCreateNew={() => dispatch(GuestOrdersActions.downloadReceipt(reference, id))}
        hidePagination
      />

      <AutoTable
        title="Discounts"
        subheader={'Membership or any related discounts applied to this order'}
        backHidden={true}
        data={discountSummary}
        columns={discountsColumns}
        hidePagination
      />

      <AutoTable
        title="Charges"
        subheader={'Additional charges attached to this order'}
        backHidden={true}
        data={charges}
        columns={taxAndChargeColumns}
        hidePagination
      />
    </Card>
  );
}
