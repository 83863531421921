import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodCorpFilterActions from '../../../../redux/actions/inRoom/ordering/foodCorpFilter/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodCorpFilterActions.delete(value, `/api/private/portal/food/corp/filter/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'filterName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'filterType',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'filter',
    label: 'Filter',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value?.filter?.map((val, idx) => <Chip key={`chip-fi-${idx}`} size={'small'} label={val} />);
    },
  },
];

export default function FoodCorpFilterList() {
  const dispatch = useDispatch();
  const corp = useSelector((state) => state.accessReducer.selectedCorporation);
  const entityList = useSelector((state) => state.foodCorpFilterReducer.entityList);
  const processing = useSelector((state) => state.foodCorpFilterReducer.processing);
  const page = useSelector((state) => state.foodCorpFilterReducer.page);
  const error = useSelector((state) => state.foodCorpFilterReducer.error);

  if (!corp) {
    return null;
  }

  return (
    <AutoTable
      title="Corporation Food Filter List"
      subheader={'A corporation level food ordering filters applicable to all sites'}
      handleRefreshReport={() => dispatch(FoodCorpFilterActions.fetch(0, 20, {}, { corpId: corp.id }))}
      selectItem={(item) => dispatch(FoodCorpFilterActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodCorpFilterActions.fetch(newPage, pageSize, {}, { corpId: corp.id }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_CHARGE"
      createNewURL="/food/corporation/filters/new"
      detailURL="/food/corporation/filters/view/{id}"
      idColumn={'id'}
      data={entityList}
    />
  );
}
