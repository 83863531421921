import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('System Calendar Event', 'SYSTEM_CALENDAR_EVENT');

function systemCalendarEventReducer(state = initial, action) {
  switch (action.type) {
    case `SYSTEM_CALENDAR_EVENT_ADD_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList, action.payload],
        events: [
          ...state.events,
          {
            title: action.payload.scheduleName,
            start: action.payload.scheduleFrom,
            end: action.payload.scheduleTo,
            extendedProps: {
              corpId: action.payload.corpId,
              siteId: action.payload.siteId,
              id: action.payload.id,
              eventType: action.payload.eventType,
            },
          },
        ],
        selectedItem: action.payload,
      };
    }
    case `SYSTEM_CALENDAR_EVENT_DELETE_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList].filter((e) => e.id !== action.payload.id),
        events: [...state.events].filter((e) => e.extendedProps?.id !== action.payload.id),
      };
    }
    case `SYSTEM_CALENDAR_EVENT_FETCH_VIEW_IN_PROGRESS`:
    case `SYSTEM_CALENDAR_EVENT_FETCH_TODAY_IN_PROGRESS`:
    case `SYSTEM_CALENDAR_EVENT_SELECT_ALL_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    }
    case `SYSTEM_CALENDAR_EVENT_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload,
      };
    }
    case `SYSTEM_CALENDAR_EVENT_UPDATE_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: state.entityList.map((e) => (e.id !== action.payload.id ? e : action.payload)),
        events: state.events.map((e) =>
          e.extendedProps?.id !== action.payload.id
            ? e
            : {
                title: action.payload.scheduleName,
                start: action.payload.scheduleFrom,
                end: action.payload.scheduleTo,
                extendedProps: { ...e.extendedProps, eventType: action.payload.eventType },
              }
        ),
      };
    }
    case `SYSTEM_CALENDAR_EVENT_FETCH_VIEW_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        events: action.payload,
      };
    }
    case `SYSTEM_CALENDAR_EVENT_FETCH_TODAY_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        eventsToday: action.payload,
      };
    }
    case `SYSTEM_CALENDAR_EVENT_FETCH_VIEW_FAILURE`:
    case `SYSTEM_CALENDAR_EVENT_FETCH_TODAY_FAILURE`:
    case `SYSTEM_CALENDAR_EVENT_SELECT_ALL_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default systemCalendarEventReducer;
