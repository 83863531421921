import React from 'react';
import AccessReservationList from './AccessReservationList';
import ReservationTypes from '../../../../../constants/ReservationTypes';

export default function AccessReservationPastList() {
  return (
    <AccessReservationList
      title="Past Reservations"
      subtitle={'Past reservations who no longer have access to your property'}
      reportType={ReservationTypes.PAST}
    />
  );
}
