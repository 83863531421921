const HouseKeepingStatus = {
  CLEAN: 'CLEAN',
  DIRTY: 'DIRTY',
};

const FrontOfficeStatus = {
  VACANT: 'VACANT',
  OCCUPIED: 'OCCUPIED',
};
export { HouseKeepingStatus, FrontOfficeStatus };
