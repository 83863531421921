import {
  Phone,
  WifiOutlined,
  StarOutline,
  Home,
  PersonOutline,
  CheckCircleOutline,
  LockOpen,
  CalendarToday,
  CameraAltOutlined,
  FingerprintOutlined,
  KeyboardOutlined,
  ShoppingCartOutlined,
  RoomServiceOutlined,
  KingBedOutlined,
  SyncOutlined,
  SaveOutlined,
  LocationOnOutlined,
  Add,
  MailOutlined,
  ScheduleOutlined,
  LocationCityOutlined,
} from '@material-ui/icons';

const Icons = [
  {
    IconLabel: 'Wifi',
    IconName: 'wifi',
  },
  {
    IconLabel: 'Phone',
    IconName: 'phone',
  },
  {
    IconLabel: 'Star',
    IconName: 'star',
  },
  {
    IconLabel: 'Home',
    IconName: 'home',
  },
  {
    IconLabel: 'User',
    IconName: 'user',
  },
  {
    IconLabel: 'Check Circle',
    IconName: 'check-circle',
  },
  {
    IconLabel: 'Lock Open',
    IconName: 'lock-open',
  },
  {
    IconLabel: 'Calendar',
    IconName: 'calendar',
  },
  {
    IconLabel: 'Camera',
    IconName: 'camera',
  },
  {
    IconLabel: 'Fingerprint',
    IconName: 'fingerprint',
  },
  {
    IconLabel: 'Keyboard',
    IconName: 'keyboard',
  },
  {
    IconLabel: 'Shopping Cart',
    IconName: 'shopping-cart',
  },
  {
    IconLabel: 'Concierge',
    IconName: 'concierge-bell',
  },
  {
    IconLabel: 'Bed',
    IconName: 'bed-alt',
  },
  {
    IconLabel: 'Sync',
    IconName: 'sync',
  },
  {
    IconLabel: 'Save',
    IconName: 'save',
  },
  {
    IconLabel: 'Map Marker',
    IconName: 'map-marker-alt',
  },
  {
    IconLabel: 'Plus',
    IconName: 'plus',
  },
  {
    IconLabel: 'Envelope',
    IconName: 'envelope',
  },
  {
    IconLabel: 'Clock',
    IconName: 'clock',
  },
  {
    IconLabel: 'City',
    IconName: 'city',
  },
];

const getIcons = (IconName) => {
  switch (IconName) {
    case 'wifi':
      return <WifiOutlined />;
    case 'phone':
      return <Phone />;
    case 'star':
      return <StarOutline />;
    case 'home':
      return <Home />;
    case 'user':
      return <PersonOutline />;
    case 'check-circle':
      return <CheckCircleOutline />;
    case 'lock-open':
      return <LockOpen />;
    case 'calendar':
      return <CalendarToday />;
    case 'camera':
      return <CameraAltOutlined />;
    case 'fingerprint':
      return <FingerprintOutlined />;
    case 'keyboard':
      return <KeyboardOutlined />;
    case 'shopping-cart':
      return <ShoppingCartOutlined />;
    case 'concierge-bell':
      return <RoomServiceOutlined />;
    case 'bed-alt':
      return <KingBedOutlined />;
    case 'sync':
      return <SyncOutlined />;
    case 'save':
      return <SaveOutlined />;
    case 'map-marker-alt':
      return <LocationOnOutlined />;
    case 'plus':
      return <Add />;
    case 'envelope':
      return <MailOutlined />;
    case 'clock':
      return <ScheduleOutlined />;
    case 'city':
      return <LocationCityOutlined />;
    default:
      return;
  }
};

export { Icons, getIcons };
