import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import ActivityActions from '../../../redux/actions/activity/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(ActivityActions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'tags',
    label: 'Tags',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.tags && value.tags.length > 0) {
        return value.tags.map((val) => <Chip key={val} label={val} />);
      }
      return null;
    },
  },
];

export default function ActivityList() {
  const dispatch = useDispatch();
  const activityList = useSelector((state) => state.activityReducer.entityList);
  const vendor = useSelector((state) => state.vendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const processing = useSelector((state) => state.activityReducer.processing);
  const page = useSelector((state) => state.activityReducer.page);
  const error = useSelector((state) => state.activityReducer.error);

  return (
    <AutoTable
      title="Activity List"
      subheader={'An activity is a service provided by a vendor'}
      handleRefreshReport={() => dispatch(ActivityActions.fetch(0, 0, { vendorId: vendorId }, {}))}
      selectItem={(item) => dispatch(ActivityActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(ActivityActions.fetch(newPage, pageSize, { vendorId: vendorId }, {}))}
      page={page}
      error={error}
      columns={columns}
      prefix="ACTIVITY"
      createNewURL="/activity/new"
      detailURL="/activity/view"
      data={activityList}
    />
  );
}
