import React, { useEffect } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as Actions from '../../../../redux/actions/roomTypes/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Room Type Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'code',
    label: 'Room Type Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'ratePlanList',
    label: 'Rate Plans',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function SiteRoomTypeList() {
  const dispatch = useDispatch();
  const roomTypeList = useSelector((state) => state.siteRoomTypeReducer.data);
  const lastActionType = useSelector((state) => state.siteRoomTypeReducer.lastActionType);
  const page = useSelector((state) => state.siteRoomTypeReducer.page);
  const error = useSelector((state) => state.siteRoomTypeReducer.error);
  const processing = useSelector((state) => state.siteRoomTypeReducer.processing);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetch(0, 25, { siteId: selectedSiteId }));
    }
  }, [selectedSiteId, dispatch]);

  return (
    <AutoTable
      title="Site Room Type List"
      subheader={'Room types available within the PMS for bookings and room upgrades'}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSiteId }))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="site room type list"
      newButtonName="New Room Type"
      createNewURL="/site/roomTypes/view"
      detailURL="/site/roomTypes/view/{id}"
      data={roomTypeList}
      lastActionType={lastActionType}
    />
  );
}
