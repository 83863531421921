import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import VendorScheduleActions from '../../../../redux/actions/inRoom/ordering/vendorSchedule/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, IconButton } from '@material-ui/core';
import { Check, DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { ScheduleTypes } from '../../../../../support/ScheduleTypes';
import { useHistory } from 'react-router-dom';

const columns = (history) => [
  {
    id: 'delete',
    label: '',
    align: 'left',
    width: 70,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Box style={{ width: 60 }}>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(VendorScheduleActions.delete(value, `/api/private/portal/food/vendor/schedule/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(VendorScheduleActions.selectAndNavigate(value, history))}>
            <EditOutlined />
          </IconButton>
        </Box>
      );
    },
  },
  {
    id: 'from',
    label: 'Start Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'to',
    label: 'End Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'schedule',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return ScheduleTypes.find((sc) => sc.value === value.schedule?.type).label || 'UNKNOWN';
    },
  },
  {
    id: 'schedule',
    label: 'Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.schedule?.value) {
        return value.schedule?.value.map((val) => <Chip key={val} label={val} />);
      }
      return '';
    },
  },
  {
    id: 'open',
    label: 'Open',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.open) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodVendorScheduleList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const vendorScheduleList = useSelector((state) => state.foodVendorScheduleReducer.entityList);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const processing = useSelector((state) => state.foodVendorScheduleReducer.processing);
  const page = useSelector((state) => state.foodVendorScheduleReducer.page);
  const error = useSelector((state) => state.foodVendorScheduleReducer.error);

  return (
    <AutoTable
      title="Schedule List"
      subheader={'A Schedule contains which time and days the Vendor is open/closed'}
      handleRefreshReport={() => dispatch(VendorScheduleActions.fetch(0, 20, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(VendorScheduleActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(VendorScheduleActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns(history)}
      prefix="FOOD_VENDOR_SCHEDULE"
      createNewURL="/food/vendor/schedule/new"
      detailURL="/food/vendor/schedule/view/{id}"
      idColumn={'id'}
      data={vendorScheduleList}
    />
  );
}
