export const ComputationInclusion = {
  CHECK: 'CHECK',
  ITEM: 'ITEM',
  CHECK_ITEM: 'CHECK_ITEM',
};

export const ComputationInclusions = [
  { label: 'Check', value: ComputationInclusion.CHECK },
  { label: 'Item', value: ComputationInclusion.ITEM },
  { label: 'Check/Item', value: ComputationInclusion.CHECK_ITEM },
];
