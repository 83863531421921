const OrderPaymentMode = {
  ON_FILE: 'ON_FILE',
  CARD_PRESENT: 'CARD_PRESENT',
  CARD_NOT_PRESENT: 'CARD_NOT_PRESENT',
};

export function formatOrderPaymentMode(type) {
  if (OrderPaymentMode.ON_FILE === type) {
    return 'Room Charge';
  }

  if (OrderPaymentMode.CARD_PRESENT === type || OrderPaymentMode.CARD_NOT_PRESENT === type) {
    return 'Credit Card';
  }

  return type;
}
export default OrderPaymentMode;
