import reducerCreator from '../../support/reducerCreator';
import { MINIBAR_DUPLICATE_FAILURE, MINIBAR_DUPLICATE_SUCCESS } from '../../actions/minibar/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Minibar', 'MINIBAR');

function minibarReducer(state = initial, action) {
  switch (action.type) {
    case MINIBAR_DUPLICATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList, action.payload],
        selectedItem: action.payload,
      };
    case MINIBAR_DUPLICATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default minibarReducer;
