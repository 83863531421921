import Countries from './Countries.json';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
/**
 * Our default phone region
 * TODO : use current timezone to get country code
 */
export const DEFAULT_REGION = 'AU';
/**
 * Min phone length
 */
export const MIN_PHONE_LENGTH = 5;
export const MAX_PHONE_LENGTH = 20;
/**
 * Find an associated country matching the given code
 * @param countryCode 2 digit country code
 * @returns {label, value, flag, dial_code} Country object or null
 */
export function getCountryObject(countryCode) {
  return Countries.find((c) => c.value === countryCode);
}
/**
 * Simple function to check a phone string.
 * @param phoneNumber Phone number as string
 * @returns {*|boolean} True if basic validation is set false otherwise
 */
export function basicPhoneValidation(phoneNumber) {
  return phoneNumber && phoneNumber.trim && phoneNumber.trim().length >= MIN_PHONE_LENGTH && phoneNumber.trim().length <= MAX_PHONE_LENGTH;
}
/**
 * If the number is provided with leading zeros and a country calling code (i.e. 0064XXXXXXXXX)  strip out
 * the zeros and replace with a '+' so the phone library understands the number.
 * @param phoneNumber Phone number as string
 * @param region Country code
 * @returns {*} Phone number as provided or an internationalised number (guessed)
 */
export function mutatePhoneToInternational(phoneNumber, region = DEFAULT_REGION) {
  // this will be parse as-is
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }
  //
  //possible number i.e. 0064 or 0061
  //0064212804433
  if (phoneNumber.startsWith('00')) {
    return phoneNumber.replace('00', '+');
  }
  //00064212804433,NZ
  if (phoneNumber.startsWith('000')) {
    return phoneNumber.replace('000', '+');
  }
  // append the dial_code at the front of the number
  //212804433
  return `${getCountryObject(region).dial_code}${phoneNumber}`;
}
/**
 * Called on mount of PhoneNumberInput.js to get the initial country code selection
 * @param phoneNumber Phone number as string
 * @param defaultRegion Country code to use as the default (optional)
 * @returns {label, value, flag, dial_code} Country object
 */
export function getInitialCountryCode(phoneNumber, defaultRegion = DEFAULT_REGION) {
  if (basicPhoneValidation(phoneNumber)) {
    try {
      // mutate phone will strip out leading zeros and replace with a '+' so the phone library understands the number.
      // but if the number is not provided with a country calling code it will return with country calling code of default_region
      const parsed = parsePhoneNumber(mutatePhoneToInternational(phoneNumber));
      return parsed.isValid() ? getCountryObject(parsed.country) : getCountryObject(defaultRegion);
    } catch (e) {
      console.log(`Unable to parse initial country code - number (${phoneNumber}) error (${e.message})`);
    }
  }
  console.log('default region: ', defaultRegion);
  return getCountryObject(defaultRegion);
}
/**
 * Get the initial value for the phone field.
 * @param phone{phoneNumber}
 * @param defaultRegion Country code to use as the default (optional)
 * @returns Parsed phone number
 */
export function getInitialPhone(phone, defaultRegion = DEFAULT_REGION) {
  if (basicPhoneValidation(phone?.phoneNumber)) {
    const phoneNumber = mutatePhoneToInternational(phone?.phoneNumber, defaultRegion);
    const country = getInitialCountryCode(phoneNumber, defaultRegion);
    try {
      const parsed = parsePhoneNumber(phoneNumber, country.code);
      return parsed.isValid() ? parsed.nationalNumber : '';
    } catch (e) {
      console.log(`Unable to parse initial phone - number (${phoneNumber}) error (${e.message})`);
    }
  }
  return '';
}
export function getPhoneErrorMessage(phoneNumber, isValid, isRequired) {
  if (!phoneNumber && !isRequired) {
    return '';
  }
  if (!phoneNumber && isRequired) {
    return '** Phone number is required';
  }
  if (!isValid) {
    return '** Phone number invalid. Check area code and phone number.';
  }
  return '';
}

export function getCountryCodeFromInternationalPhoneNumber(phoneNumber, siteRegion) {
  if (phoneNumber) {
    try {
      const type = new AsYouType();
      type.input(phoneNumber);
      if (type.isValid() && type.isInternational()) {
        return type.getCountry();
      }
    } catch (e) {
      console.warn('Unable to get country code from phone number', phoneNumber);
    }
  }
  return siteRegion || DEFAULT_REGION;
}
