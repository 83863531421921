import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodModifierGroupActions from '../../../../redux/actions/inRoom/ordering/foodModifierGroup/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Chip, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FoodModifierActions from '../../../../redux/actions/inRoom/ordering/foodModifier/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FoodModifierGroupEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const modifierList = useSelector((state) => state.foodModifierReducer.options || []);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodModifierGroupReducer);
  const externalId = selectedItem?.externalId;
  const [groupName, setGroupName] = useState(selectedItem?.groupName || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [sequence, setSequence] = useState(selectedItem?.sequence || 1);
  const [minQty, setMinQty] = useState(selectedItem?.minQty);
  const [maxQty, setMaxQty] = useState(selectedItem?.maxQty);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [foodModifiers, setFoodModifiers] = useState(selectedItem?.foodModifiers || []);

  const modifierOptions = useMemo(() => {
    return modifierList.map((mod) => ({
      label: mod.modifierName + (mod.description ? ' - ' + mod.description : ''),
      value: mod.id,
    }));
  }, [modifierList]);

  useEffect(() => {
    if (vendorId) {
      dispatch(FoodModifierActions.fetchAll(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    dispatch(FoodModifierGroupActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_MODIFIER_GROUP_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_MODIFIER_GROUP_ADD_SUCCESS' ||
      lastActionType === 'FOOD_MODIFIER_GROUP_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      groupName: groupName,
      description: description,
      sequence: sequence,
      minQty: minQty,
      maxQty: maxQty,
      enabled: enabled,
      foodModifiers: foodModifiers,
      required: false, // Deprecated. Added as a placeholder to be able to add/update
    };

    if (selectedItem) {
      dispatch(FoodModifierGroupActions.update(payload, `/api/private/portal/food/modifier/group/${selectedItem.id}`, false));
    } else {
      dispatch(FoodModifierGroupActions.add(payload, { vendorId: vendorId }));
    }
  };

  const handleModifierUpdate = (_, value) => {
    if (!value || (foodModifiers && foodModifiers.length > 0 && foodModifiers.filter((mg) => mg === value[0].value).length > 0)) {
      return;
    }

    if (modifierList.length > 0) {
      setFoodModifiers([...foodModifiers, value[value.length - 1].value]);
    } else {
      setFoodModifiers([value[0].value]);
    }
  };

  const handleModifierDelete = (value) => {
    setFoodModifiers(foodModifiers.filter((item) => item !== value));
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Modifier Group' : 'Create Food Modifier Group'}
        subheader={'A Food Modifier group contains shared customisations for a Food Item'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!groupName || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() =>
          dispatch(FoodModifierGroupActions.delete(selectedItem, `/api/private/portal/food/modifier/group/${selectedItem.id}`))
        }
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food modifier group - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={groupName} setValue={setGroupName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField disabled label={'External ID'} value={externalId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Description'} value={description} setValue={setDescription} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField type={'number'} step={1} label={'Sequence'} value={sequence} setValue={setSequence} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField type={'number'} step={1} label={'Min Quantity'} value={minQty} setValue={setMinQty} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField type={'number'} step={1} label={'Max Quantity'} value={maxQty} setValue={setMaxQty} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              multiple
              fullWidth
              name={'Modifiers'}
              placeholder={'Modifiers'}
              options={modifierOptions}
              autoComplete={false}
              filterSelectedOptions
              getOptionSelected={(option, selected) => option.value === selected}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField required={false} error={error} {...params} label={'Modifiers'} margin={'dense'} variant="outlined" />
              )}
              value={foodModifiers}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => {
                  const label = modifierOptions.find((mg) => mg.value === option)?.label || 'N/A';
                  return <Chip {...getTagProps({ index })} label={label} onDelete={() => handleModifierDelete(option)} />;
                })
              }
              onChange={handleModifierUpdate}
              clearOnBlur
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
