import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import FormField from '../../../../../../components/form/FormField';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SystemCalendarEvent, SystemCalendarEventOptions, SystemCalendarType } from '../../../../../../support/SystemCalendar';
import FormSelect from '../../../../../../components/form/FormSelect';
import SystemCalendarEventActions from '../../../../../redux/actions/system/event/actions';
import moment from 'moment';

const DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm';

export default function SiteEventEditDialog({ siteId, corpId, event, clearSelection, calendarType, timezone, readOnly = true }) {
  const dispatch = useDispatch();
  const corporationList = useSelector((state) => state.accessReducer.corporationList);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const [scheduleName, setScheduleName] = useState(event?.scheduleName);
  const [scheduleFrom, setScheduleFrom] = useState(moment(event?.scheduleFrom).format(DATE_TIME_FORMAT));
  const [scheduleTo, setScheduleTo] = useState(moment(event?.scheduleTo).format(DATE_TIME_FORMAT));
  const [eventType, setEventType] = useState(event?.eventType);
  const [modalOpen, setModalOpen] = React.useState(event || false);
  const eventTypeOptions =
    calendarType === SystemCalendarType.GLOBAL
      ? SystemCalendarEventOptions
      : SystemCalendarEventOptions.filter((sceo) => sceo.value !== SystemCalendarEvent.GLOBAL_OUTAGE);

  function handleClose() {
    setModalOpen(false);
    clearSelection();
  }

  const handleSubmit = async () => {
    let path = corpId ? `/corp/${corpId}` : '';
    path = siteId ? path + `/site/${siteId}` : path;

    const payload = {
      corpId: corpId || null,
      siteId: siteId || null,
      scheduleName: scheduleName,
      scheduleFrom: scheduleFrom,
      scheduleTo: scheduleTo,
      eventType: eventType,
      timezone: timezone,
    };

    if (event?.id) {
      await dispatch(SystemCalendarEventActions.update(payload, `/api/private/portal/system/calendar/event/${event?.id}`, false, true));
    } else {
      await dispatch(SystemCalendarEventActions.add(payload, { path: path }));
    }

    handleClose();
  };

  const isFormReady = scheduleName && scheduleFrom && scheduleTo && eventType && moment(scheduleFrom)?.isBefore(moment(scheduleTo));

  return (
    <Dialog open={modalOpen} onClose={() => clearSelection()} maxWidth={'md'}>
      <DialogTitle> {event?.id ? (readOnly ? 'View' : 'Edit') : 'Add'} Event</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormField
              label={'Organisation'}
              value={event?.corpId ? corporationList?.find((c) => c.id === event?.corpId)?.name : 'Global'}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              label={'Site'}
              value={
                !event?.corpId && !event?.siteId
                  ? 'Global'
                  : event?.corpId && !event?.siteId
                  ? 'All Sites'
                  : siteList?.find((s) => s.id === event?.siteId)?.name
              }
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label={'Schedule Name'}
              value={scheduleName}
              required
              InputLabelProps={{ shrink: true }}
              setValue={setScheduleName}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth
                size={'small'}
                inputVariant={'outlined'}
                placeholder="From"
                value={scheduleFrom}
                // Workaround. Looks like moment object does not update
                onChange={(d) => setScheduleFrom(d.format(DATE_TIME_FORMAT))}
                disabled={readOnly}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth
                size={'small'}
                inputVariant={'outlined'}
                placeholder="From"
                value={scheduleTo}
                // Workaround. Looks like moment object does not update
                onChange={(d) => setScheduleTo(d.format(DATE_TIME_FORMAT))}
                disabled={readOnly}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              required
              label={'Event Type'}
              value={eventType}
              setValue={setEventType}
              options={readOnly ? SystemCalendarEventOptions : eventTypeOptions}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!readOnly && (
          <Button disabled={!isFormReady} variant={'contained'} color="primary" onClick={handleSubmit}>
            OK
          </Button>
        )}
        <Button variant={'contained'} onClick={handleClose}>
          {readOnly ? 'OK' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
