const CheckInSecurityMode = {
  SWAP: 'SWAP',
  KEEP_CURRENT: 'KEEP_CURRENT',
  SWAP_AND_ADD: 'SWAP_AND_ADD',
};

const CheckInSecurityModeOptions = [
  { value: CheckInSecurityMode.SWAP, label: 'Swap - Auth to Payment' },
  { value: CheckInSecurityMode.KEEP_CURRENT, label: 'Keep Current - Keeps current security type' },
  { value: CheckInSecurityMode.SWAP_AND_ADD, label: 'Swap & Add - Swaps and adds to base amount' },
];

export default CheckInSecurityMode;
export { CheckInSecurityModeOptions };
