import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as DataTypes from '../../../../../../constants/DataTypes';
import moment from 'moment';
import Objects from '../../../../../../support/Objects';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../../components/form/FormField';

export default function EditVostioSystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = useState(selectedItem?.name || '');
  const [enabled, setEnabled] = useState(selectedItem?.enabled || false);
  const [baseUrl, setBaseUrl] = useState(configuration?.baseUrl || 'https://vostio.api.assaabloy.com');
  const [username, setUsername] = useState(configuration?.username || 'srlN6Aj9IIdGrJpmWhfg');
  const [password, setPassword] = useState(configuration?.password || "dVL<vv%OXAT3=n='<](Z");
  const [csUrl, setCsUrl] = useState(configuration?.csUrl || 'https://hospitality.credential-services.api.assaabloy.com');
  const [csUsername, setCsUsername] = useState(configuration?.csUsername || 'Liverton-tenant');
  const [csPassword, setCsPassword] = useState(configuration?.csPassword || 'RjtIhaABREpBrGguW2esKXx0yHhy5zh6');
  const [userPoolId, setUserPoolId] = useState(configuration?.userPoolId || 'eu-west-1_2dEwKGPXv');
  const [connectTimeout, setConnectTimeout] = useState(configuration?.connectTimeout || 5000);
  const [readTimeout, setReadTimeout] = useState(configuration?.readTimeout || 30000);
  const [clientId, setClientId] = useState(configuration?.clientId || '7b4m0ul2t72c8qhgb0h9pndkja');
  const [authorisation, setAuthorisation] = useState(configuration?.authorisation || '');
  const [authorisationValidDateUntil, setAuthorisationValidDateUntil] = useState(
    configuration?.authorisationValidDateUntil !== undefined ? moment(configuration.authorisationValidDateUntil).format('LLL') : ''
  );
  const [availableAuthorisationTime, setAvailableAuthorisationTime] = useState(configuration?.availableAuthorisationTime || 55);
  const [debug, setDebug] = useState(configuration?.debug || false);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        baseUrl: Objects.sanitizeAndTrimString(baseUrl),
        username,
        password,
        csUrl: Objects.sanitizeAndTrimString(csUrl),
        csUsername,
        csPassword,
        userPoolId,
        clientId,
        availableAuthorisationTime,
        siteId: selectedItem?.siteId || selectedSite.id,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    selectedItem,
    name,
    enabled,
    selectedSite.id,
    debug,
    baseUrl,
    username,
    password,
    csUrl,
    csUsername,
    csPassword,
    userPoolId,
    clientId,
    availableAuthorisationTime,
    dispatch,
  ]);

  const configurations = [
    {
      id: 'name',
      label: 'Friendly Name',
      type: 'text',
      value: name,
      setValue: setName,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'baseUrl',
      label: 'Base URL',
      type: 'text',
      value: baseUrl,
      setValue: setBaseUrl,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'username',
      label: 'Username',
      type: 'text',
      value: username,
      setValue: setUsername,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      value: password,
      setValue: setPassword,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'csUrl',
      label: 'Credential Service URL',
      type: 'text',
      value: csUrl,
      setValue: setCsUrl,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'csUsername',
      label: 'Credential Service Username',
      type: 'text',
      value: csUsername,
      setValue: setCsUsername,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'csPassword',
      label: 'Credential Service Password',
      type: 'password',
      value: csPassword,
      setValue: setCsPassword,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'userPoolId',
      label: 'User Pool Id',
      type: 'text',
      value: userPoolId,
      setValue: setUserPoolId,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'client',
      label: 'Client Id',
      type: 'text',
      value: clientId,
      setValue: setClientId,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'authorisation',
      label: 'Authorisation(Read Only)',
      type: 'password',
      placeholder: 'Authorisation will be set up automatically after updating the lock system',
      readOnly: true,
      disabledError: true,
      value: authorisation,
      setValue: setAuthorisation,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'authorisationValidDateUntil',
      label: 'Authorisation Vaild Date Until(Read Only)',
      placeholder: 'Authorisation valid date will be set up automatically after updating the lock system',
      type: 'text',
      readOnly: true,
      disabledError: true,
      value: authorisationValidDateUntil,
      setValue: setAuthorisationValidDateUntil,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'authorisationValidSeconds',
      label: 'Authorisation Valid Time(minutes)',
      type: 'text',
      value: availableAuthorisationTime,
      setValue: setAvailableAuthorisationTime,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'connectTimeout',
      label: 'Connect Timeout(ms)',
      type: 'text',
      value: connectTimeout,
      setValue: setConnectTimeout,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'readTimeout',
      label: 'Read Timeout(ms)',
      type: 'text',
      value: readTimeout,
      setValue: setReadTimeout,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      label: 'Debug',
      value: debug,
      setValue: setDebug,
      dateType: DataTypes.DATA_TYPE_BOOLEAN,
    },
    {
      label: 'Enabled',
      value: enabled,
      setValue: setEnabled,
      dateType: DataTypes.DATA_TYPE_BOOLEAN,
    },
  ];

  const displayConfigurations = (configuration, index) => {
    if (configuration.dateType === DataTypes.DATA_TYPE_STRING) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={6} key={`date-type-string-${index}`}>
          <FormField
            label={configuration.label}
            type={configuration.type}
            placeholder={configuration.placeholder}
            value={configuration.value}
            setValue={configuration.setValue}
            error={!configuration.disabledError && !configuration.value}
            inputProps={{ readOnly: configuration.readOnly }}
          />
        </Grid>
      );
    } else if (configuration.dateType === DataTypes.DATA_TYPE_BOOLEAN) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={6} key={`date-type-boolean-${index}`}>
          <FormBooleanSelect label={configuration.label} value={configuration.value} setValue={configuration.setValue} />
        </Grid>
      );
    }
  };

  const isFormReady =
    name &&
    baseUrl &&
    username &&
    password &&
    userPoolId &&
    clientId &&
    availableAuthorisationTime &&
    csUrl &&
    csUsername &&
    csPassword &&
    readTimeout &&
    connectTimeout;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Vostio Lock System'}
        subheader={'Vostio locking system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          {configurations.map((configuration, index) => displayConfigurations(configuration, index))}
        </Grid>
      </CardContent>
    </Card>
  );
}
