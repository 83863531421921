import React, { useEffect } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as Actions from '../../../../redux/actions/upsell/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

// Table column rendering information.
const columns = [
  {
    id: 'duplicate',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.duplicateSitePackage(value.id))}>
          <FileCopyOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'name',
    label: 'Package Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'code',
    label: 'Package Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'maxQuantity',
    label: 'Max Quantity',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'orderNumber',
    label: 'Order',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

function SitePackageUpsellList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const packageList = useSelector((state) => state.siteUpsellPackagesReducer.data);
  const processing = useSelector((state) => state.siteUpsellPackagesReducer.processing);
  const lastActionType = useSelector((state) => state.siteUpsellPackagesReducer.lastActionType);
  const page = useSelector((state) => state.siteUpsellPackagesReducer.page);
  const error = useSelector((state) => state.siteUpsellPackagesReducer.error);

  useEffect(() => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 25, { siteId: selectedSite.id }));
    }
  }, [selectedSite, dispatch]);

  useEffect(() => {
    if (lastActionType === Actions.SITE_PACKAGE_DUPLICATE_SUCCESS) {
      dispatch(Actions.fetch(0, 25, { siteId: selectedSite.id }));
    }
  }, [dispatch, lastActionType, selectedSite]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSite.id }));
  };

  return (
    <AutoTable
      title="Upgrade Packages"
      subheader={'Upgrade packages available to customers using the mobile app'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SITE_PACKAGE"
      newButtonName="New Upsell Package"
      createNewURL="/site/upsell/view"
      detailURL="/site/upsell/view/{id}"
      data={packageList}
      lastActionType={lastActionType}
    />
  );
}

export default SitePackageUpsellList;
