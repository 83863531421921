import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';

const RmsScopes = [
  { label: 'Kiosk', value: 'kiosk' },
  { label: 'Point Of Sale', value: 'PointOfSale' },
  { label: 'Guest Services', value: 'GuestServices' },
];

const RmsRoomStatuses = [
  { label: 'Clean', value: 'CLEAN' },
  { label: 'Inspected', value: 'INSPECTED' },
  { label: 'Dirty', value: 'DIRTY' },
  { label: 'Maintenance', value: 'MAINTENANCE' },
  { label: 'Occupied', value: 'OCCUPIED' },
];

export default function EditRMS() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);
  const [clientNumber, setClientNumber] = React.useState(configuration?.clientNumber || 7694);
  const [clientPassword, setClientPassword] = React.useState(configuration?.clientPassword || '9i*A$8Dx');
  const [agentId, setAgentId] = React.useState(configuration?.agentId || 6);
  const [agentPassword, setAgentPassword] = React.useState(configuration?.agentPassword || 'V!$tagAt35V8*');
  const [propertyId, setPropertyId] = React.useState(configuration?.propertyId || 7694);
  const [developmentMode, setDevelopmentMode] = React.useState(configuration?.developmentMode || false);
  const [scopes, setScopes] = React.useState(configuration?.scopes || ['kiosk']);
  const [preAuthFlagField, setPreAuthFlagField] = React.useState(configuration?.preAuthFlagField || '');
  const [preAuthAmountField, setPreAuthAmountField] = React.useState(configuration?.preAuthAmountField || '');
  const [preAuthCodeField, setPreAuthCodeField] = React.useState(configuration?.preAuthCodeField || '');
  const [useOnlineConfirmationNumber, setUseOnlineConfirmationNumber] = React.useState(configuration?.useOnlineConfirmationNumber || false);
  const [vacantRoomStatuses, setVacantRoomStatuses] = React.useState(configuration?.vacantRoomStatuses || ['CLEAN']);
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'NZD');
  const [readTimeout, setReadTimeout] = React.useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = React.useState(configuration?.connectTimeout || 5000);
  const [billForRoomTypes, setBillForRoomTypes] = React.useState(configuration?.showFullBillForRoomTypes || '');
  const [settleAllWindows, setSettleAllWindows] = React.useState(configuration?.settleAllWindows || false);

  const handleSubmit = React.useCallback(() => {
    if (selectedSite) {
      const payload = {
        ...selectedItem,
        name: name,
        enabled: enabled,
        systemCategory: selectedItem.systemCategory,
        systemType: selectedItem.systemType,
        siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
        systemConfiguration: JSON.stringify({
          clientNumber: clientNumber,
          clientPassword: clientPassword,
          agentId: agentId,
          agentPassword: agentPassword,
          propertyId: propertyId,
          developmentMode: developmentMode,
          scopes: scopes,
          preAuthFlagField: preAuthFlagField,
          preAuthAmountField: preAuthAmountField,
          preAuthCodeField: preAuthCodeField,
          useOnlineConfirmationNumber: useOnlineConfirmationNumber,
          vacantRoomStatuses: vacantRoomStatuses,
          debug: debug,
          currency: currency,
          readTimeout: readTimeout,
          connectTimeout: connectTimeout,
          showFullBillForRoomTypes: billForRoomTypes,
          settleAllWindows,
        }),
      };

      if (selectedItem.id) {
        dispatch(Actions.updateSiteSystem(payload));
      } else {
        dispatch(Actions.createSiteSystem(payload));
      }
    }
  }, [
    agentId,
    agentPassword,
    clientNumber,
    clientPassword,
    connectTimeout,
    currency,
    debug,
    developmentMode,
    dispatch,
    enabled,
    name,
    preAuthAmountField,
    preAuthCodeField,
    preAuthFlagField,
    propertyId,
    readTimeout,
    scopes,
    selectedItem,
    selectedSite,
    useOnlineConfirmationNumber,
    vacantRoomStatuses,
    settleAllWindows,
    billForRoomTypes,
  ]);

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update RMS PMS' : 'Create RMS PMS'}
        subheader={'RMS Cloud property management system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={false}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'RMS Client No'}
              type={'number'}
              value={clientNumber}
              setValue={setClientNumber}
              error={!clientNumber}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'RMS Client Password'}
              type={'password'}
              value={clientPassword}
              setValue={setClientPassword}
              error={!clientPassword}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'RMS Agent No'} type={'number'} value={agentId} setValue={setAgentId} error={!agentId} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'RMS Agent Password'}
              type={'password'}
              value={agentPassword}
              setValue={setAgentPassword}
              error={!agentPassword}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'RMS Property No'} type={'number'} value={propertyId} setValue={setPropertyId} error={!propertyId} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="scopes">Rms Scopes</InputLabel>
              <Select labelId="scopes" value={scopes} multiple={true} labelWidth={100} onChange={(event) => setScopes(event.target.value)}>
                {RmsScopes.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={scopes.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="vacantRoomStatusesList">Vacant Room Statuses</InputLabel>
              <Select
                labelId="vacantRoomStatusesList"
                value={vacantRoomStatuses}
                multiple={true}
                labelWidth={170}
                onChange={(event) => setVacantRoomStatuses(event.target.value)}
              >
                {RmsRoomStatuses.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={vacantRoomStatuses.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Development Mode'} value={developmentMode} setValue={setDevelopmentMode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect
              label={'Use Online Confirmation No'}
              value={useOnlineConfirmationNumber}
              setValue={setUseOnlineConfirmationNumber}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Pre Auth Flag Field'} value={preAuthFlagField} setValue={setPreAuthFlagField} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Pre Auth Amount Field'}
              type="number"
              step={0.2}
              value={preAuthAmountField}
              setValue={setPreAuthAmountField}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Pre Auth Code Field'} value={preAuthCodeField} setValue={setPreAuthCodeField} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Show Full Bill To Res with Payment Modes'}
              value={billForRoomTypes}
              setValue={setBillForRoomTypes}
              type={'text'}
              placeholder="Comma Separated"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Settle All Folios'} value={settleAllWindows} setValue={setSettleAllWindows} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Connect Timeout (MS)'} value={connectTimeout} setValue={setConnectTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout (MS)'} value={readTimeout} setValue={setReadTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
