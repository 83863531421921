const PurchaseState = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  VOID: 'VOID',
};

const PaymentPurchaseStateOptions = [
  { value: PurchaseState.PENDING, label: 'Pending' },
  { value: PurchaseState.COMPLETED, label: 'Completed' },
  { value: PurchaseState.VOID, label: 'Void' },
];

export default PurchaseState;
export { PaymentPurchaseStateOptions };
