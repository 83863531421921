import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import { ReportScheduleActions } from '../../../../../redux/actions/reservation/report/schedule/actions';
import { useHistory } from 'react-router-dom';
import FormSelect from '../../../../../../components/form/FormSelect';
import FormMultiChip from '../../../../../../components/form/FormMultiChip';
import moment from 'moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ReportTypeOptions } from '../../../../../../support/ReportType';
import { ReportFrequencyOptions } from '../../../../../../support/ReportFrequency';
import MomentUtils from '@date-io/moment';
import { EMAIL_REGEX } from '../../../../../../support/CannedRegex';

export default function ReportScheduleEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedSite, lastActionType: accessLastAction } = useSelector((state) => state.accessReducer);
  const siteId = selectedSite?.id;

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.reportScheduleReducer);
  const [scheduleName, setScheduleName] = React.useState(selectedItem ? selectedItem.scheduleName : '');
  const [reportType, setReportType] = React.useState(selectedItem ? selectedItem.reportType : '');
  const [frequency, setFrequency] = React.useState(selectedItem ? selectedItem.frequency : '');
  const [sendTime, setSendTime] = React.useState(selectedItem ? selectedItem.sendTime : moment().format('HH:00'));
  const [recipients, setRecipients] = React.useState(selectedItem ? selectedItem.recipients : []);

  useEffect(() => {
    dispatch(ReportScheduleActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'REPORT_SCHEDULE_DELETE_SUCCESS' ||
      lastActionType === 'REPORT_SCHEDULE_UPDATE_SUCCESS' ||
      lastActionType === 'REPORT_SCHEDULE_ADD_SUCCESS' ||
      accessLastAction === 'ACCESS_SELECT_SITE'
    ) {
      history.push('/reservation/report/schedule');
    }
  }, [lastActionType, accessLastAction, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      scheduleName,
      reportType,
      frequency,
      sendTime,
      recipients,
    };

    if (selectedItem) {
      dispatch(ReportScheduleActions.update(payload, `/api/private/portal/report/schedule/${selectedItem.id}`, false));
    } else {
      dispatch(ReportScheduleActions.add(payload, { siteId }));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Report Schedule' : 'Create Report Schedule'}
        subheader={'Configure Periodic Report Export'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!scheduleName || !reportType || !frequency || !sendTime || recipients?.length === 0 || processing}
        onDelete={
          selectedItem
            ? () => dispatch(ReportScheduleActions.delete(selectedItem, `/api/private/portal/report/schedule/${selectedItem.id}`))
            : null
        }
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add report schedule - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Schedule Name'} value={scheduleName} setValue={setScheduleName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Report Type'} value={reportType} setValue={setReportType} options={ReportTypeOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Frequency'} value={frequency} setValue={setFrequency} options={ReportFrequencyOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TimePicker
                fullWidth
                label="Send Time"
                value={moment.utc(sendTime, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => setSendTime(moment.utc(time).format('HH:mm'))}
                views={['hours']}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormMultiChip
              label={'Recipients'}
              value={recipients}
              setValue={setRecipients}
              validation={EMAIL_REGEX}
              validationMessage={'Invalid Email Address'}
              transform={(text) => text.toLowerCase()}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
