import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SPECIAL_REQUEST_SUB_ADD_SUCCESS = 'SPECIAL_REQUEST_SUB_ADD_SUCCESS';

export const RequestSubCategoryActions = {
  ...actionCreator.createActions('Special Request Sub Category', 'SPECIAL_REQUEST_SUB', '/api/private/subCategory/{categoryId}'),
  quickToggle: (item, isDeliveryTime) => async (dispatch) => {
    await dispatch({ type: 'SPECIAL_REQUEST_SUB_UPDATE_IN_PROGRESS' });
    try {
      const payload = {
        ...item,
        enabled: isDeliveryTime ? item.enabled : !item.enabled,
        deliveryTimeEnabled: isDeliveryTime ? !item.deliveryTimeEnabled : item.deliveryTimeEnabled,
      };
      const result = await API.put('PrivateAPI', `/api/private/subCategory/quick`, { body: payload });
      await dispatch({ type: `SPECIAL_REQUEST_SUB_UPDATE_SUCCESS`, payload: result });
    } catch (error) {
      dispatch({
        type: `SPECIAL_REQUEST_SUB_UPDATE_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default RequestSubCategoryActions;
