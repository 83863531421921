import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../redux/actions/user/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import UserRoleHelper from '../../../../support/UserRoleHelper';
import AutoTable from '../../../../components/table/AutoTable';
import { getFilterField, getFilterUpdateMethods, getSelectFilterField } from '../../../../components/table/filter/filterUtils';
import UserRole from '../../../../support/UserRole';

// Table column rendering information.
const columns = [
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phoneNumber',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'role',
    label: 'Role',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
    format: function (value) {
      return UserRoleHelper.toFriendlyName(value);
    },
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

function getFilterFieldsForScreen(state, setState, profile) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  let filters = [
    getFilterField('phone', 'Phone Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('email', 'Email Address', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('lastName', 'Last Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
  if (UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER)) {
    filters.push(
      getSelectFilterField(
        'corporationId',
        'Corporation',
        profile.corporations.map((s) => ({
          label: s.name,
          value: s.id,
        })),
        filterUpdateMethods
      )
    );
  }
  return filters;
}

export default function UserList() {
  const dispatch = useDispatch();
  const lastActionType = useSelector((state) => state.userReducer.lastActionType);
  const data = useSelector((state) => state.userReducer.data);
  const page = useSelector((state) => state.userReducer.page);
  const processing = useSelector((state) => state.userReducer.processing);
  const error = useSelector((state) => state.userReducer.error);
  const profile = useSelector((state) => state.accessReducer.profile);
  const [state, setState] = useState({
    phone: '',
    email: '',
    lastName: '',
    corporationId: UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER) ? '' : profile.selectedCorporation.id,
  });

  const filters = getFilterFieldsForScreen(state, setState, profile);

  useEffect(() => {
    dispatch(Actions.findByFilter(0, 25, state.phone, state.email, state.lastName, state.corporationId));
  }, [dispatch, state]);

  React.useEffect(() => {
    dispatch(Actions.selectItem(null));
  }, [dispatch]);

  return (
    <AutoTable
      title="User List"
      subheader={'Users able to access this application/corporation/site'}
      handleRefreshReport={() => dispatch(Actions.fetchUsers(0, 25))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetchUsers(newPage, pageSize))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="USER"
      newButtonName="New User"
      createNewURL="/account/users/new"
      detailURL="/account/users/view/{id}"
      idColumn={'id'}
      data={data}
      lastActionType={lastActionType}
      filters={filters}
      clearableFilter
    />
  );
}
