import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as FirestoreService from '../../../../components/firebase/firebase';
import * as Actions from '../../../redux/actions/chat/actions';
import { Badge, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { DeleteOutlined, SendOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

export default function ChatThread({ classes, selectedChat, data, index }) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);

  const { tripReference, guestUsername, guestEmail, guestFirstName, guestLastName } = data;

  console.debug('THREAD DATA: ', data);
  console.debug('THREAD SITE: ', selectedSite.chatCollectionId);

  const guestName = React.useMemo(() => {
    if (guestFirstName && guestLastName) {
      return `${guestFirstName} ${guestLastName}`;
    } else {
      return 'Unknown Guest Name';
    }
  }, [guestFirstName, guestLastName]);

  const deleteChat = React.useCallback(
    (data) => {
      const collectionId = data.collectionId || selectedSite?.chatCollectionId;
      const docId = data.guestUsername || data.recipient;

      Swal.fire({
        title: `Delete the chat with ${data.guestEmail}?`,
        text: 'Warning: This operation is not reversible',
        icon: 'warning',
        showCancelButton: true,
        heightAuto: false,
      }).then((result) => {
        if (result.value) {
          FirestoreService.deleteDocument(collectionId, docId)
            .then(() => {
              dispatch(Actions.deleteDocument(docId));
            })
            .catch((error) => {
              console.log('Error occurred while deleting document message ', error);
              dispatch(Actions.deleteDocument(docId, error));
              Swal.fire('Something Went Wrong', 'The chat could not be deleted. Please try again later.', 'error');
            });
        }
      });
    },
    [dispatch, selectedSite?.chatCollectionId]
  );

  return (
    <ListItem
      button
      key={`ct-${guestUsername}-${index}`}
      selected={selectedChat?.guestUsername === guestUsername}
      onClick={() => dispatch(Actions.selectChat(data))}
      className={classes.MuiListItemTextPrimary}
    >
      <Badge badgeContent={data.unreadByAdmin} color="secondary" showZero={false} className={classes.unreadMessagesBadgeMargin}>
        <SendOutlined />
      </Badge>

      <ListItemText
        className={classes.listItemTextOverflow}
        primary={guestName}
        secondary={
          <>
            <div>{`Reservation: ${tripReference}`}</div>
            <div>{`Email: ${guestEmail} - ${moment(data.messages[data.messages.length - 1].createdAt).format('LT')}`}</div>
          </>
        }
        classes={{
          primary: classes.guestName,
          secondary: classes.MuiListItemTextSecondary,
        }}
      />

      <IconButton onClick={() => deleteChat(data)} size={'small'} className={classes.deleteIconStyle}>
        <DeleteOutlined />
      </IconButton>
    </ListItem>
  );
}
