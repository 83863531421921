import FormSelect from './FormSelect';
import { useMemo } from 'react';

const AVAILABLE_FONTS = [
  'Arial',
  'Arial Black',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Didot',
  'Times New Roman',
  'Georgia',
  'Lucida Console',
  'Bradley Hand',
  'Brush Script MT',
  'Courier',
  'Roboto',
  'Proxima Nova',
  'ProximaNova-Bold',
  'Pacifico',
  'Albertus Medium',
  'ACaslonPro Italic',
  'Gotham Bold',
  'Gotham Medium',
  'Gotham Book',
  'Lato',
  'Open Sans',
  'Montserrat',
  'Montserrat Bold',
  'CircularXXWeb-Bold',
  'CircularXXWeb-Light',
  'Jazmin Alt SemiBold',
  'SweetSans-Bold',
  'AkzidenzGroteskBE-Md',
  'AkzidenzGroteskBE-Regular',
  'SuperGrotesk',
  'Futura',
  'Faktum Light',
  'Faktum Regular',
  'Faktum Bold',
  'Harbour Light',
  'Harbour Medium',
  'Harbour Bold',
  'Ironick NF',
  'Mesmerize BK',
  'Neuzeit Grotesk Bold',
  'Neuzeit Grotesk Regular',
  'Neuzeit',
  'Noir Regular',
  'Beausite Classic Clear',
  'Beausite Fit Light',
  'Beausite Fit Medium',
  'Beausite Classic Medium',
  'Beausite Grand Light',
];

export default function FormFontSelect({ label, value, setValue, error }) {
  const options = useMemo(() => {
    return AVAILABLE_FONTS.map((f) => ({ label: f, value: f }));
  }, []);
  return <FormSelect label={label} value={value} setValue={setValue} options={options} error={error} />;
}
