import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Objects from '../../../../../../support/Objects';

export default function EditGallagherSystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);

  const [server, setServer] = React.useState(configuration?.server || 'https://192.168.50.239');
  const [port, setPort] = React.useState(configuration?.port || '8904');
  const [apiKey, setApiKey] = React.useState(configuration?.apiKey || '4738-78E9-8D40-F266-7B74-C3FD-CD3C-FA60');
  const [cardTypeDigital, setCardTypeDigital] = React.useState(configuration?.cardTypeDigital || 'Mobile Credential');
  const [division, setDivision] = React.useState(configuration?.division || 'Root Division');
  const [workStation, setWorkStation] = React.useState(configuration?.workStation || 'Liverton REST Client');
  const [personalDataFieldForEmail, setPersonalDataFieldForEmail] = React.useState(configuration?.personalDataFieldForEmail || 'Email');

  const [debug, setDebug] = React.useState(configuration?.debug || false);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        server: Objects.sanitizeAndTrimString(server),
        port: port,
        apiKey: apiKey,
        cardTypeDigital: cardTypeDigital,
        division: division,
        workStation: workStation,
        personalDataFieldForEmail: personalDataFieldForEmail,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    server,
    port,
    apiKey,
    cardTypeDigital,
    division,
    workStation,
    personalDataFieldForEmail,
    debug,
    dispatch,
    enabled,
    name,
    selectedItem,
    selectedSite,
  ]);

  const isFormReady = name && server && port && apiKey && cardTypeDigital && division && workStation && personalDataFieldForEmail;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Gallagher Lock System'}
        subheader={'Gallagher hotel locking system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              type="text"
              label="Friendly Name"
              onChange={(event) => setName(event.target.value)}
              variant={'outlined'}
              value={name}
              error={!name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="server"
              type="text"
              label="Server URL"
              onChange={(event) => setServer(event.target.value)}
              variant={'outlined'}
              value={server}
              error={!server}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="port"
              type="text"
              label="Server port"
              onChange={(event) => setPort(event.target.value)}
              variant={'outlined'}
              value={port}
              error={!port}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="apiKey"
              label="API Key"
              type="password"
              onChange={(event) => setApiKey(event.target.value)}
              variant={'outlined'}
              value={apiKey}
              error={!apiKey}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="cardTypeDigital"
              type="text"
              label="Card type name for Mobile"
              onChange={(event) => setCardTypeDigital(event.target.value)}
              variant={'outlined'}
              value={cardTypeDigital}
              error={!cardTypeDigital}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="division"
              type="text"
              label="Division name"
              onChange={(event) => setDivision(event.target.value)}
              variant={'outlined'}
              value={division}
              error={!division}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="workstation"
              type="text"
              label="Workstation name for REST API"
              onChange={(event) => setWorkStation(event.target.value)}
              variant={'outlined'}
              value={workStation}
              error={!workStation}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="personalDataFieldForEmail"
              type="text"
              label="Personal Data Field name For Email"
              onChange={(event) => setPersonalDataFieldForEmail(event.target.value)}
              variant={'outlined'}
              value={personalDataFieldForEmail}
              error={!personalDataFieldForEmail}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="debug">Debug</InputLabel>
              <Select labelId="debug" value={debug} labelWidth={50} onChange={(event) => setDebug(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="enabled">Enabled</InputLabel>
              <Select labelId="enabled" value={enabled} labelWidth={60} onChange={(event) => setEnabled(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
