import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

// Actions
export const APPUSER_ACTION_IN_PROGRESS = 'USER_ACTION_IN_PROGRESS';

export const APPUSER_GET_APPUSERS_SUCCESS = 'APPUSER_GET_APPUSERS_SUCCESS';
export const APPUSER_GET_APPUSERS_FAILURE = 'APPUSER_GET_APPUSERS_FAILURE';

export const APPUSER_CREATE_SUCCESS = 'APPUSER_CREATE_SUCCESS';
export const APPUSER_CREATE_FAILURE = 'APPUSER_CREATE_FAILURE';

export const APPUSER_DELETE_SUCCESS = 'APPUSER_DELETE_SUCCESS';
export const APPUSER_DELETE_FAILURE = 'APPUSER_DELETE_FAILURE';

export const APPUSER_EDIT_SUCCESS = 'APPUSER_EDIT_SUCCESS';
export const APPUSER_EDIT_FAILURE = 'APPUSER_EDIT_FAILURE';

export const APPUSER_RESET_PASSWORD_SUCCESS = 'APPUSER_RESET_PASSWORD_SUCCESS';
export const APPUSER_RESET_PASSWORD_FAILURE = 'APPUSER_RESET_PASSWORD_FAILURE';

export const APPUSER_SELECTED_ITEM = 'APPUSER_SELECTED_ITEM';
export const APPUSER_CLEAR_ERROR = 'APPUSER_CLEAR_ERROR';

export const fetchAppUsers = (page, size) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/app-user/all?page=${page || 0}&size=${size || 10}`;
    const response = await API.get('PrivateAPI', url, {});
    dispatch({ type: APPUSER_GET_APPUSERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: APPUSER_GET_APPUSERS_FAILURE, payload: error });
  }
};

export const createAppUser = (appUser) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/app-user`;
    const response = await API.post('PrivateAPI', url, { body: appUser });
    dispatch({ type: APPUSER_CREATE_SUCCESS, payload: response });
    await Swal.fire({
      title: 'User Invited',
      html: `App User has been created successfully. <b>${appUser.emailAddress}</b> will receive an email with login details.`,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  } catch (error) {
    dispatch({ type: APPUSER_CREATE_FAILURE, payload: error });
  }
};

export const deleteAppUser = (appUserId) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/app-user/${appUserId}`;
    const response = await API.del('PrivateAPI', url, {});
    dispatch({ type: APPUSER_DELETE_SUCCESS, payload: response });
    await Swal.fire({
      title: 'App User Deleted',
      html: `App User has been deleted successfully.`,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  } catch (error) {
    dispatch({ type: APPUSER_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const findByFilter = (page, size, phoneNumber, emailAddress, appId) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const pageParam = page ? `&page=${page}` : '&page=0';
    const sizeParam = size ? `&size=${size}` : '&size=10';
    const phoneParam = phoneNumber ? `&phoneNumber=${phoneNumber}` : '';
    const emailParam = emailAddress ? `&emailAddress=${emailAddress}` : '';
    const appIdParam = appId ? `&appId=${appId}` : '';
    const url = `/api/private/portal/app-user/search?${pageParam}${sizeParam}${phoneParam}${emailParam}${appIdParam}`;
    const response = await API.get('PrivateAPI', url, {});
    dispatch({ type: APPUSER_GET_APPUSERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: APPUSER_GET_APPUSERS_FAILURE, payload: error });
  }
};

export const editAppUser = (request) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/app-user`;
    const response = await API.put('PrivateAPI', url, { body: request });
    dispatch({ type: APPUSER_EDIT_SUCCESS, payload: response });
    await Swal.fire({
      title: 'App User Updated',
      html: `App User has been updated successfully.`,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  } catch (error) {
    dispatch({ type: APPUSER_EDIT_FAILURE, payload: error });
  }
};

export const resetAppUserPassword = (username, password) => async (dispatch) => {
  dispatch({ type: APPUSER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/app-user/reset-password/${username}`;
    const response = await API.post('PrivateAPI', url, { body: password });
    dispatch({ type: APPUSER_RESET_PASSWORD_SUCCESS, payload: response });
    await Swal.fire('Password Reset Requested', 'The new temp password will be available to login for the app user', 'success');
  } catch (error) {
    dispatch({ type: APPUSER_RESET_PASSWORD_FAILURE, payload: error });
    await Swal.fire('Password Reset Failed', 'Unable to request password reset. Please try again later.', 'error');
  }
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: APPUSER_SELECTED_ITEM, payload: item });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: APPUSER_CLEAR_ERROR });
};
