import { useSelector } from 'react-redux';
import useFetch from '../../../../../../support/hook/useFetch';
import AutoTable from '../../../../../../components/table/AutoTable';
import React, { useMemo } from 'react';
import * as DataTypes from '../../../../../../constants/DataTypes';
import Objects from '../../../../../../support/Objects';

export default function MembershipEnrolmentList() {
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const [data, processing, error, page, updatePage] = useFetch(`/api/membership/list?siteId=${selectedSite.id}`);
  const columns = useMemo(() => getMembershipTableColumns(selectedSite?.timezone), [selectedSite]);
  return (
    <AutoTable
      title="Membership Sign Ups"
      subheader={'Sign up requests made through the Kiosk, mobile or web platforms'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="MEMBERSHIP_ENROLMENTS"
      backHidden
      data={data}
      lastActionType={'MEMBERSHIP_ENROLMENTS'}
    />
  );
}

const getMembershipTableColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Sync Status',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'syncAttempt',
    label: 'Sync Attempts',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'syncResponse',
    label: 'Provider Response',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.updated, timezone);
    },
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export { getMembershipTableColumns };
