import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Schedule Time', 'ACTIVITY_SCHEDULE_TIME');

function activityTimeReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default activityTimeReducer;
