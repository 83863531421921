import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from 'components/form/FormField';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Actions from '../../../../redux/actions/appUserReservationCard/actions';
import FormSelect from '../../../../../components/form/FormSelect';
import ReservationCardStatus, { ReservationCardStatusOptions } from '../../../../../support/ReservationCardStatus';
import moment from 'moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default function AppReservationCard() {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.appUserReservationReducer.error);
  const selectedItem = useSelector((state) => state.appUserReservationReducer.selectedItem);
  const [cardStatus, setCardStatus] = React.useState(selectedItem.status || ReservationCardStatus.COMPLETED);
  const [arrivalDate, setArrivalDate] = React.useState(moment.unix(selectedItem.arrivalDate));
  const [departureDate, setDepartureDate] = React.useState(moment.unix(selectedItem.departureDate));

  const { reservationType, reference, created, updated, expiry, primary, id } = selectedItem;
  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      id: id,
      status: cardStatus,
      arrivalDate: moment(arrivalDate).unix(),
      departureDate: moment(departureDate).unix(),
      // updated is set in the backend
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    }
  };

  const isFormReady = cardStatus && arrivalDate && departureDate;

  return (
    <Card>
      <CardHeaderWithControls
        header={'Reservation Card'}
        subheader={''}
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to update reservation card - ${error.message}`}</Alert>}

        <Grid container spacing={2}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Reference Number'} value={reference} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Reservation Type'} value={reservationType} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect label={'Check Out State'} value={cardStatus} setValue={setCardStatus} options={ReservationCardStatusOptions} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker fullWidth inputVariant={'outlined'} label="Expiry Date" value={expiry} disabled />
            </Grid>{' '}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker fullWidth inputVariant={'outlined'} label="Created" value={created} disabled />
            </Grid>{' '}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker fullWidth inputVariant={'outlined'} label="Updated" value={updated} disabled />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker fullWidth inputVariant={'outlined'} label="Arrival Date" value={arrivalDate} onChange={setArrivalDate} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker
                fullWidth
                inputVariant={'outlined'}
                label="Departure Date"
                value={departureDate}
                onChange={setDepartureDate}
                minDate={arrivalDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Primary Guest'} value={primary ? 'Yes' : 'No'} disabled={true} />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </CardContent>
    </Card>
  );
}
