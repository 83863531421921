import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SITE_AMENITY_FETCH_SUCCESS = 'SITE_AMENITY_FETCH_SUCCESS';
export const SITE_AMENITY_FETCH_FAILURE = 'SITE_AMENITY_FETCH_FAILURE';

export const SITE_AMENITY_CREATE_SUCCESS = 'SITE_AMENITY_CREATE_SUCCESS';
export const SITE_AMENITY_CREATE_FAILURE = 'SITE_AMENITY_CREATE_FAILURE';

export const SITE_AMENITY_UPDATE_SUCCESS = 'SITE_AMENITY_UPDATE_SUCCESS';
export const SITE_AMENITY_UPDATE_FAILURE = 'SITE_AMENITY_UPDATE_FAILURE';

export const SITE_AMENITY_DELETE_SUCCESS = 'SITE_AMENITY_DELETE_SUCCESS';
export const SITE_AMENITY_DELETE_FAILURE = 'SITE_AMENITY_DELETE_FAILURE';

export const SITE_AMENITY_CLEAR_ERROR = 'SITE_AMENITY_CLEAR_ERROR';

export const SITE_AMENITY_SELECTED_ITEM = 'SITE_AMENITY_SELECTED_ITEM';

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: SITE_AMENITY_SELECTED_ITEM, payload: item });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_AMENITY_CLEAR_ERROR });
};

export const fetch = (page, size) => async (dispatch, getState) => {
  const siteId = getState().accessReducer?.selectedSite?.id;
  const url = `/api/private/portal/site/amenities?siteId=${siteId}&page=${page || 0}&size=${size || 10}`;
  try {
    const response = await API.get('PrivateAPI', url, {});
    dispatch({ type: SITE_AMENITY_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_AMENITY_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const add = (request) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/amenities', { body: request });
    dispatch({ type: SITE_AMENITY_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_AMENITY_CREATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const update = (request, amenity) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', `/api/private/portal/site/amenities/${amenity.id}`, { body: request });
    dispatch({ type: SITE_AMENITY_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_AMENITY_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const remove = (amenity) => async (dispatch) => {
  try {
    await API.del('PrivateAPI', `/api/private/portal/site/amenities/${amenity.id}`, {});
    dispatch({ type: SITE_AMENITY_DELETE_SUCCESS, payload: amenity });
  } catch (error) {
    dispatch({ type: SITE_AMENITY_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};
