import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import FormSelect from './FormSelect';
import { useSelector } from 'react-redux';

function mapPlatformValues(platforms) {
  return platforms.map((t) => ({
    label: t.name,
    value: t.id,
  }));
}

export default function FormSelectPlatform({ value, setValue, label, placeholder }) {
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const [platformList, setPlatformList] = useState([]);

  useEffect(() => {
    if (!selectedCorporation) {
      return;
    }

    API.get('PrivateAPI', '/api/private/portal/platform', {})
      .then((response) => {
        setPlatformList(mapPlatformValues(response.content));
      })
      .catch((error) => {
        console.warn('Unable to get themes due to error', error);
      });
  }, [selectedCorporation]);

  return (
    <FormSelect label={label} placeholder={placeholder || label} value={value} setValue={setValue} error={!value} options={platformList} />
  );
}
