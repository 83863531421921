import reducerCreator from '../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Doors', 'SITE_ROOM');

function siteDoorReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default siteDoorReducer;
