import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from 'components/form/FormField';
import SpecialRequestState, { SpecialRequestStateLabel } from '../../../../../../support/SpecialRequestState';
import { SpecialRequestAdditionalState, SpecialRequestAdditionalStateLabel } from '../../../../../../support/SpecialRequestAdditionalState';
import SpecialRequestActions from '../../../../../redux/actions/specialRequest/actions';
import moment from 'moment/moment';
import AppConfig from '../../../../../../AppConfig';
import { Done, DoneAll, Reply, Save } from '@material-ui/icons';
import Objects from '../../../../../../support/Objects';
import AutoTable from '../../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../../constants/DataTypes';
import { getColours } from '../../../../../../theme/MaterialTheme';

const colours = getColours(false);

const columns = [
  {
    id: 'escalationState',
    label: 'Escalation State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_ENUM,
  },
  {
    id: 'createdDate',
    label: 'Escalation Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function GuestRequestEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.specialRequestReducer.lastActionType);
  const error = useSelector((state) => state.specialRequestReducer.error);
  const selectedItem = useSelector((state) => state.specialRequestReducer.selectedItem);

  const { request, requestNumber, requestDate, deliveryTime, escalationHistory, category, subCategory, additionalState } = selectedItem;
  const [requestState, setRequestState] = React.useState(selectedItem.state || SpecialRequestState.UNANSWERED);
  const [note, setNote] = React.useState(selectedItem?.note || '');
  const [hotelComments, setHotelComments] = React.useState(selectedItem?.hotelComments || '');

  useEffect(() => {
    dispatch(SpecialRequestActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'SPECIAL_REQUEST_NOTIFICATION_SUCCESS' || lastActionType === 'SPECIAL_REQUEST_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleReply = async (statusParam) => {
    const payload = {
      ...selectedItem,
      state: requestState,
      note,
      siteId: selectedSite?.id,
      additionalState:
        statusParam === SpecialRequestAdditionalState.NOT_ACKNOWLEDGED ? SpecialRequestAdditionalState.ACKNOWLEDGED : statusParam,
    };

    if (selectedItem) {
      dispatch(SpecialRequestActions.updateNote(payload));
    }
  };

  const handleSave = async (statusParam) => {
    const payload = {
      ...selectedItem,
      hotelComments,
      siteId: selectedSite?.id,
      additionalState: statusParam,
    };
    if (selectedItem) {
      dispatch(SpecialRequestActions.update(payload));
    }
  };

  const isFormReady = note && requestState;
  const isReplyDisabled =
    Objects.isMatch(requestState, [SpecialRequestState.ANSWERED, SpecialRequestState.NOT_APPLICABLE]) ||
    Objects.isMatch(additionalState, [SpecialRequestAdditionalState.NOT_APPLICABLE]);
  const isDiscardDisabled = Objects.isMatch(additionalState, [
    SpecialRequestAdditionalState.ACKNOWLEDGED,
    SpecialRequestAdditionalState.COMPLETED,
    SpecialRequestAdditionalState.NOT_APPLICABLE,
  ]);

  return (
    <Card>
      <CardHeaderWithControls
        header={`Guest Request - ${requestNumber}`}
        subheader={''}
        buttonName={'Save'}
        buttonIcon={<Save />}
        buttonColour={'primary'}
        onClick={() => handleSave(additionalState)}
      />
      {additionalState === SpecialRequestAdditionalState.NOT_ACKNOWLEDGED && (
        <Alert severity="warning" variant={'filled'} className={'mb-2'}>
          This request has not been acknowledged.
          <Button
            className={'ml-2'}
            color={'primary'}
            variant={'contained'}
            onClick={() => handleSave(SpecialRequestAdditionalState.ACKNOWLEDGED)}
            fullWidth={matches}
            size={'small'}
            startIcon={<Done />}
          >
            Acknowledge
          </Button>
          <Button
            className={'ml-2'}
            style={{ backgroundColor: colours['success'], color: colours['light'] }}
            variant={'contained'}
            onClick={() => handleSave(SpecialRequestAdditionalState.COMPLETED)}
            fullWidth={matches}
            size={'small'}
            startIcon={<DoneAll />}
          >
            Complete
          </Button>
        </Alert>
      )}
      {additionalState === SpecialRequestAdditionalState.ACKNOWLEDGED && (
        <Alert severity="error" variant={'filled'} className={'mb-2'}>
          This request has not been completed.
          <Button
            className={'ml-2'}
            color={'primary'}
            variant={'contained'}
            onClick={() => handleSave(SpecialRequestAdditionalState.COMPLETED)}
            fullWidth={matches}
            size={'small'}
            startIcon={<Done />}
          >
            Complete
          </Button>
        </Alert>
      )}

      <CardContent>
        {error && <Alert severity="error">{`Unable to update special request - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Request Number'} value={requestNumber} disabled={true} placeholder="Request Number" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Guest Contacted'} value={SpecialRequestStateLabel[requestState]} setValue={setRequestState} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              label="Special Request"
              type="text"
              variant={'outlined'}
              value={request}
              multiline
              maxRows={8}
              required
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Category'} value={category} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sub Category'} value={subCategory} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Reply Note"
              type="text"
              variant={'outlined'}
              margin={'dense'}
              autoFocus
              onChange={(event) => setNote(event.target.value)}
              value={note}
              multiline
              minRows={4}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={'flex align-items-center'}>
            <Box className={'pt-1'}>
              <Button
                className={'mr-3 mb-2'}
                color={'secondary'}
                variant={'contained'}
                onClick={() => handleReply(additionalState)}
                fullWidth={matches}
                disabled={!isFormReady || isReplyDisabled}
                startIcon={<Reply />}
              >
                Reply and Acknowledge
              </Button>
              <Button
                className={'mb-2'}
                color={'default'}
                variant={'contained'}
                onClick={() => handleReply(SpecialRequestAdditionalState.NOT_APPLICABLE)}
                fullWidth={matches}
                disabled={!isFormReady || isReplyDisabled || isDiscardDisabled}
                startIcon={<Reply />}
              >
                Reply and Discard
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={'Status'} value={SpecialRequestAdditionalStateLabel[additionalState]} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Requested Date'} value={moment.unix(requestDate).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Delivery Time'}
              value={deliveryTime ? moment.unix(deliveryTime).format(AppConfig.DEFAULT_DATE_TIME_FORMAT) : 'N/A'}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Updated'} value={moment.unix(selectedItem.updated).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Created'} value={moment.unix(selectedItem.created).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField
              label="Hotel Comments"
              type="text"
              variant={'outlined'}
              value={hotelComments}
              setValue={setHotelComments}
              multiline
              maxRows={8}
            />
          </Grid>
        </Grid>
      </CardContent>
      {escalationHistory.length > 0 && (
        <AutoTable title={'Escalation History'} columns={columns} data={escalationHistory} hidePagination={true} backHidden={true} />
      )}
    </Card>
  );
}
