import API from '@aws-amplify/api';

export const FETCH_THEME_LANGUAGES_IN_PROGRESS = 'FETCH_THEME_LANGUAGES_IN_PROGRESS';
export const FETCH_THEME_LANGUAGES_SUCCESS = 'FETCH_THEME_LANGUAGES_SUCCESS';
export const FETCH_THEME_LANGUAGES_FAILURE = 'FETCH_THEME_LANGUAGES_FAILURE';

export const FETCH_THEME_MESSAGES_IN_PROGRESS = 'FETCH_THEME_MESSAGES_IN_PROGRESS';
export const FETCH_THEME_MESSAGES_SUCCESS = 'FETCH_THEME_MESSAGES_SUCCESS';
export const FETCH_THEME_MESSAGES_FAILURE = 'FETCH_THEME_MESSAGES_FAILURE';

export const UPDATE_THEME_MESSAGES_IN_PROGRESS = 'UPDATE_THEME_MESSAGES_IN_PROGRESS';
export const UPDATE_THEME_MESSAGES_SUCCESS = 'UPDATE_THEME_MESSAGES_SUCCESS';
export const UPDATE_THEME_MESSAGES_FAILURE = 'UPDATE_THEME_MESSAGES_FAILURE';

export const fetchThemeLanguages = (themeId) => async (dispatch) => {
  dispatch({ type: FETCH_THEME_LANGUAGES_IN_PROGRESS });
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/theme/languages/${themeId}`, {});
    dispatch({ type: FETCH_THEME_LANGUAGES_SUCCESS, payload: response });
  } catch (e) {
    dispatch({ type: FETCH_THEME_LANGUAGES_FAILURE, payload: e });
  }
};

export const fetchThemeMessages = (themeId) => async (dispatch) => {
  dispatch({ type: FETCH_THEME_MESSAGES_IN_PROGRESS });
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/theme/messages/${themeId}`, {});
    dispatch({ type: FETCH_THEME_MESSAGES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FETCH_THEME_MESSAGES_FAILURE, payload: error });
  }
};

export const updateThemeMessages = (request) => async (dispatch) => {
  dispatch({ type: UPDATE_THEME_MESSAGES_IN_PROGRESS });
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/theme/messages', { body: request });
    dispatch({ type: UPDATE_THEME_MESSAGES_SUCCESS, payload: response });
  } catch (e) {
    dispatch({ type: UPDATE_THEME_MESSAGES_FAILURE, payload: e });
  }
};
