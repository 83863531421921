import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodMenuActions from '../../../../redux/actions/inRoom/ordering/menu/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import { CheckBoxSharp, CropSquareSharp, DeleteOutlined, FileCopyOutlined } from '@material-ui/icons';
import { FoodMenuTypes } from '../../../../../support/FoodMenuTypes';
import SequenceColumn from '../../../../../components/table/SequenceColumn';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: 70,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Box style={{ width: 60 }}>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodMenuActions.delete(value, `/api/private/portal/food/menu/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(FoodMenuActions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
        </Box>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return FoodMenuTypes.find((fmt) => fmt.value === value.type).label || 'UNKNOWN';
    },
  },
  {
    id: 'externalId',
    label: 'External ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Order',
    align: 'center',
    width: 150,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <SequenceColumn
          sequence={value.sequence}
          onChange={(nextValue) =>
            dispatch(FoodMenuActions.update({ ...value, sequence: nextValue }, `/api/private/portal/food/menu/${value.id}`, false, true))
          }
        />
      );
    },
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={value.enabled ? 'primary' : 'inherit'}
          onClick={() =>
            dispatch(
              FoodMenuActions.update({ ...value, enabled: !value.enabled }, `/api/private/portal/food/menu/${value.id}`, false, true)
            )
          }
        >
          {value.enabled ? <CheckBoxSharp /> : <CropSquareSharp />}
        </IconButton>
      );
    },
  },
];

export default function FoodMenuList() {
  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.foodMenuReducer.entityList);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const processing = useSelector((state) => state.foodMenuReducer.processing);
  const page = useSelector((state) => state.foodMenuReducer.page);
  const error = useSelector((state) => state.foodMenuReducer.error);

  return (
    <AutoTable
      title="Food Menus"
      subheader={'A menu includes a set of food items and can be scheduled at specific times.'}
      handleRefreshReport={() => dispatch(FoodMenuActions.fetch(0, 25, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodMenuActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodMenuActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_MENU"
      createNewURL="/food/menu/new"
      detailURL="/food/menu/view/{id}"
      idColumn={'id'}
      data={menuList}
      backLink={'/food/vendor'}
    />
  );
}
