import reducerCreator from '../../support/reducerCreator';
import {
  SECONDARY_GUEST_STATE_UPDATE_SUCCESS,
  SECONDARY_GUEST_STATE_UPDATE_FAILURE,
  SECONDARY_GUEST_DISABLE_PROGRESS,
  SECONDARY_GUEST_SHARE_IN_PROGRESS,
  SECONDARY_GUEST_SHARE_FAILURE,
  SECONDARY_GUEST_SHARE_SUCCESS,
} from '../../actions/secondaryGuest/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('SecondaryGuest', 'SECONDARY_GUEST');

function secondaryGuestReducer(state = initial, action) {
  switch (action.type) {
    case SECONDARY_GUEST_DISABLE_PROGRESS:
    case SECONDARY_GUEST_SHARE_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case SECONDARY_GUEST_STATE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: state.entityList.map((e) => {
          if (e.id === action.payload.guestReservationId) {
            return { ...e, enabled: action.payload.enable };
          } else {
            return e;
          }
        }),
      };
    case SECONDARY_GUEST_SHARE_FAILURE:
    case SECONDARY_GUEST_STATE_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case SECONDARY_GUEST_SHARE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: null,
      };
    default:
      return base(state, action);
  }
}

export default secondaryGuestReducer;
