import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../../redux/actions/roomTypes/actions';
import * as AmenitiesActions from '../../../../redux/actions/amenity/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import toBase64 from '../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import LanguageOverrideCard from '../language/LanguageOverrideCard';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import RoomUpsellMappingCard from './roomUpsell/RoomUpsellMappingCard';

const getSelectedAmenities = (selectedItem, amenityList) => {
  if (selectedItem) {
    const currentlySelectedList = amenityList.filter((am) => selectedItem.siteAmenityList.includes(am.id));
    return currentlySelectedList.map(function (am) {
      return {
        value: am.id,
        label: am.name,
      };
    });
  } else {
    return [];
  }
};

export default function SiteRoomType() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const error = useSelector((state) => state.siteRoomTypeReducer.error);
  const lastActionType = useSelector((state) => state.siteRoomTypeReducer.lastActionType);
  const selectedItem = useSelector((state) => state.siteRoomTypeReducer.selectedItem);
  const allRoomTypes = useSelector((state) => state.siteRoomTypeReducer.data);
  const processing = useSelector((state) => state.siteRoomTypeReducer.processing);
  const amenityList = useSelector((state) => state.siteAmenitiesReducer.data);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [description, setDescription] = React.useState(selectedItem?.description || '');
  const [code, setCode] = React.useState(selectedItem?.code || '');
  const [ratePlanList, setRatePlanList] = React.useState(selectedItem?.ratePlanList || '');
  const [orderNumber, setOrderNumber] = React.useState(selectedItem?.orderNumber || 1);
  const [upsellEnabled, setUpsellEnabled] = React.useState(selectedItem?.upsellEnabled || false);
  const [selectedAmenities, setSelectedAmenities] = React.useState(getSelectedAmenities(selectedItem, amenityList));
  const [packageDescriptions, setPackageDescriptions] = React.useState(selectedItem ? selectedItem.roomTypeDescriptionList : []);
  const [upsellRules, setUpsellRules] = React.useState(selectedItem ? selectedItem.roomTypeUpsellList : []);
  const [fileSelected, setFileSelected] = React.useState(null);

  useEffect(() => {
    dispatch(Actions.clearError());
    if (selectedSiteId) {
      dispatch(AmenitiesActions.fetch(0, 25, selectedSiteId));
    }
  }, [dispatch, selectedSiteId]);

  useEffect(() => {
    if (
      lastActionType === Actions.SITE_ROOM_TYPE_DELETE_SUCCESS ||
      lastActionType === Actions.SITE_ROOM_TYPE_UPDATE_SUCCESS ||
      lastActionType === Actions.SITE_ROOM_TYPE_CREATE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const fileToSend = fileSelected ? await toBase64(fileSelected) : null;
    const fileName = fileSelected ? fileSelected.name : null;

    const payload = {
      ...selectedItem,
      siteId: selectedItem?.siteId || selectedSite.id,
      name: name,
      description: description,
      code: code,
      ratePlanList: ratePlanList,
      orderNumber: orderNumber,
      roomTypeDescriptionList: packageDescriptions,
      image: fileToSend,
      imageName: fileName,
      upsellEnabled: upsellEnabled,
      siteAmenityList: selectedAmenities ? selectedAmenities.map((x) => x.value) : null,
      roomTypeUpsellList: upsellRules,
    };

    if (selectedItem) {
      dispatch(Actions.updateRoomType(payload));
    } else {
      dispatch(Actions.createRoomType(payload));
    }
  };

  const handleAddDescription = () => {
    setPackageDescriptions(packageDescriptions.concat([{ name: '', description: '', locale: '' }]));
  };

  const handleAddRule = () => {
    setUpsellRules(
      upsellRules.concat([
        {
          siteRoomType: selectedItem.code,
          roomTypeTo: null,
          roomTypeToCode: '',
          extraAmount: 0.0,
          validDays: [],
          packageCode: '',
          id: selectedItem.id,
        },
      ])
    );
  };

  const getAllRoomTypesMapped = () => {
    return allRoomTypes
      .filter((t) => t.id !== selectedItem?.id)
      .map(function (pd) {
        return {
          id: pd.id,
          label: pd.code,
        };
      });
  };

  const handleDescriptionChange = (index) => (event) => {
    setPackageDescriptions(
      packageDescriptions.map(function (pd, pdIdx) {
        if (index !== pdIdx) {
          return pd;
        } else {
          return {
            ...pd,
            [event.target.name]: event.target.value,
          };
        }
      })
    );
  };

  const handleRoomUpsellChange = (index) => (event) => {
    setUpsellRules(
      upsellRules.map(function (pd, pdIdx) {
        if (index !== pdIdx) {
          return pd;
        } else {
          if (event.target.name === 'roomTypeToCode') {
            let selectedRoomType = allRoomTypes.filter((t) => t.code === event.target.value)[0];
            return {
              ...pd,
              roomTypeTo: selectedRoomType.id,
              [event.target.name]: selectedRoomType.code,
            };
          }
          return {
            ...pd,
            [event.target.name]: event.target.value,
          };
        }
      })
    );
  };

  const handleDeleteDescription = (idx) => {
    setPackageDescriptions(packageDescriptions.filter((x, index) => index !== idx));
  };
  const handleDeleteUpsellRule = (idx) => {
    setUpsellRules(upsellRules.filter((x, index) => index !== idx));
  };

  const isRoomTypeFormReady = name && description && code && orderNumber;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={selectedItem ? 'Update Room Type' : 'Create Room Type'}
            subheader={'Room types available within the PMS for bookings and room upgrades'}
            buttonName={selectedItem ? 'Update' : 'Create'}
            onClick={handleSubmit}
            buttonLoading={processing}
            disabled={!isRoomTypeFormReady}
            onDelete={() => dispatch(Actions.deleteRoomType(selectedItem))}
          />

          <CardContent>
            <Grid container spacing={2}>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" variant={'filled'}>
                    {`Unable to edit/delete room type - ${error.message}`}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Room Type Name"
                  type="text"
                  variant={'outlined'}
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Room Type Code"
                  type="text"
                  variant={'outlined'}
                  onChange={(event) => setCode(event.target.value)}
                  value={code}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Rate Plan List (Comma Seperated)"
                  type="text"
                  variant={'outlined'}
                  onChange={(event) => setRatePlanList(event.target.value)}
                  value={ratePlanList}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="orderNumber"
                  label="Order Number"
                  type="number"
                  variant={'outlined'}
                  onChange={(event) => setOrderNumber(event.target.value)}
                  value={orderNumber}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Description"
                  variant={'outlined'}
                  multiline
                  minRows={4}
                  onChange={(event) => setDescription(event.target.value)}
                  value={description}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormBooleanSelect value={upsellEnabled} setValue={setUpsellEnabled} label={'Allow Room Upsell'} />
              </Grid>

              {amenityList && (
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    name="amenities"
                    placeholder="Please select amenities"
                    options={amenityList.map((am) => {
                      return { value: am.id, label: am.name };
                    })}
                    getOptionSelected={(option, selected) => option.value === selected.value}
                    getOptionLabel={(option) => option.label}
                    multiple
                    onChange={(event, newValue) => {
                      setSelectedAmenities(newValue);
                    }}
                    value={selectedAmenities}
                    renderInput={(params) => <TextField {...params} label="Amenities" variant="outlined" />}
                  />
                </Grid>
              )}

              <FileImageSelector
                sourceItem={selectedItem}
                sourceItemProperty={'imgUrl'}
                displayName={'Room Image'}
                onSelect={(file) => setFileSelected(file)}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <LanguageOverrideCard
        existingList={packageDescriptions}
        addOverride={handleAddDescription}
        deleteOverride={handleDeleteDescription}
        onFieldChange={handleDescriptionChange}
      />

      {selectedItem && (
        <RoomUpsellMappingCard
          existingRules={upsellRules}
          addRule={handleAddRule}
          onFieldChange={handleRoomUpsellChange}
          availableRoomTypes={getAllRoomTypesMapped()}
          deleteOverride={handleDeleteUpsellRule}
          currentRoomType={selectedItem}
        />
      )}
    </Grid>
  );
}
