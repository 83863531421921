import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodItemActions from '../../../../redux/actions/inRoom/ordering/foodItem/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, IconButton } from '@material-ui/core';
import { Check, DeleteOutlined } from '@material-ui/icons';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';
import useDebounce from '../../../../../support/hook/useDebounce';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodItemActions.delete(value, `/api/private/portal/food/item/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'price',
    label: 'Price',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value.price === null ? 'Open Price' : parseFloat(value.price).toFixed(2);
    },
  },
  {
    id: 'capacity',
    label: 'Capacity',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'tags',
    label: 'Tags',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.tags && value.tags.length > 0) {
        return value.tags.map((val) => <Chip key={val} label={val} />);
      }
      return null;
    },
  },
  {
    id: 'inStock',
    label: 'In Stock',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.inStock) {
        return <Check />;
      }
      return null;
    },
  },
  {
    id: 'available',
    label: 'Available',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.available) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodItemList() {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const foodItemList = useSelector((state) => state.foodItemReducer.entityList);
  const processing = useSelector((state) => state.foodItemReducer.processing);
  const page = useSelector((state) => state.foodItemReducer.page);
  const error = useSelector((state) => state.foodItemReducer.error);
  const pathVariables = { vendorId: vendorId };

  const [state, setState] = useState({
    name: '',
    tags: '',
  });
  const debouncedState = useDebounce(state);

  const filters = getFilterFieldsForScreen(state, setState);

  useEffect(() => {
    dispatch(FoodItemActions.fetch(0, 25, debouncedState, pathVariables));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedState]);

  return (
    <AutoTable
      title="Food Items"
      subheader={'Collection of all items available for this vendor. A food item can be assigned to one or more menus.'}
      selectItem={(item) => dispatch(FoodItemActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodItemActions.fetch(newPage, pageSize, {}, pathVariables))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_ITEM"
      createNewURL="/food/item/new"
      detailURL="/food/item/view/{id}"
      idColumn={'id'}
      data={foodItemList}
      filters={filters}
    />
  );
}

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('name', 'Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('tags', 'Tags', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
}
