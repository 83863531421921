import React, { useEffect, useMemo, useState } from 'react';
import * as DataTypes from '../../../../../../constants/DataTypes';
import GuestOrdersActions from '../../../../../redux/actions/guestOrders/actions';
import { useDispatch, useSelector } from 'react-redux';
import AutoTable from '../../../../../../components/table/AutoTable';
import { getFilterField, getFilterUpdateMethods, getMultiSelectFilterField } from '../../../../../../components/table/filter/filterUtils';
import { FoodOrderStatusOptions } from '../../../../../../support/FoodOrderStatus';
import { FoodOrderLocationTypes, formatLocationType } from '../../../../../../support/FoodOrderLocationType';
import moment from 'moment/moment';
import Currency from '../../../../../../support/Currency';
import { formatOrderPaymentMode } from '../../../../../../support/OrderPaymentMode';
import { useHistory, useLocation } from 'react-router-dom';

const columns = [
  {
    id: 'reference',
    label: 'Order Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'externalReference',
    label: 'Cheque Ref',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Currency.transform(values.total).format();
    },
  },
  {
    id: 'locationType',
    label: 'Location',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
    format: function (value) {
      return formatLocationType(value);
    },
  },
  {
    id: 'locationCode',
    label: 'Room/Table',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'paymentMode',
    label: 'Payment Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
    format: function (value) {
      return formatOrderPaymentMode(value);
    },
  },
  {
    id: 'vendorName',
    label: 'Vendor Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'orderSendTime',
    label: 'Send Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'orderDeliveryTime',
    label: 'Delivery Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'createdDate',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

const getFilterFieldsForScreen = (state, setState) => {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getMultiSelectFilterField('status', 'Order Status', FoodOrderStatusOptions, filterUpdateMethods),
    getMultiSelectFilterField('locationType', 'Order Type', FoodOrderLocationTypes, filterUpdateMethods),
    getFilterField('startDate', 'Start Date', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
    getFilterField('endDate', 'End Date', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
  ];
};

export default function GuestOrderList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const page = useSelector((state) => state.guestOrdersReducer.page);
  const error = useSelector((state) => state.guestOrdersReducer.error);
  const processing = useSelector((state) => state.guestOrdersReducer.processing);
  const lastActionType = useSelector((state) => state.guestOrdersReducer.lastActionType);
  const requests = useSelector((state) => state.guestOrdersReducer.entityList);
  const selectedSiteId = selectedSite?.id;
  const pathVariables = { siteId: selectedSiteId };
  const status = new URLSearchParams(location.search).get('status');
  const locationType = new URLSearchParams(location.search).get('locationType');
  const startDate = new URLSearchParams(location.search).get('startDate');
  const endDate = new URLSearchParams(location.search).get('endDate');
  const pageParam = new URLSearchParams(location.search).get('page');
  const sizeParam = new URLSearchParams(location.search).get('size');
  const pageOverride = { ...page, number: page.number || parseInt(pageParam), size: page.size || parseInt(sizeParam) };
  const [state, setState] = useState({
    status: status ? status.split(',') : FoodOrderStatusOptions.map((option) => option.value),
    locationType: locationType ? locationType.split(',') : FoodOrderLocationTypes.map((option) => option.value),
    startDate: startDate ? moment(startDate, 'DD-MM-YYYY') : moment().subtract(1, 'days'),
    endDate: endDate ? moment(endDate, 'DD-MM-YYYY') : moment(),
  });

  useEffect(() => {
    history.replace(
      `/reservation/orders?page=${page?.number}&size=${page?.size}&status=${state?.status}&locationType=${
        state?.locationType
      }&startDate=${state?.startDate?.format('DD-MM-YYYY')}&endDate=${state?.endDate?.format('DD-MM-YYYY')}`
    );
  }, [history, state?.status, state?.locationType, state?.startDate, state?.endDate, page?.number, page?.size]);

  const filters = getFilterFieldsForScreen(state, setState);

  const payload = useMemo(() => {
    return {
      sort: 'lastModifiedDate,desc',
      startDate: moment(state.startDate).format('DD-MM-YYYY'),
      endDate: moment(state.endDate).format('DD-MM-YYYY'),
      status: state.status,
      locationType: state.locationType,
    };
  }, [state?.status, state?.locationType, state?.startDate, state?.endDate]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(GuestOrdersActions.fetch(newPage, pageSize, payload, pathVariables));
  };

  return (
    <AutoTable
      title="Guest Orders"
      subheader={'Orders made by guests'}
      buttonName={'Export'}
      onCreateNew={() => dispatch(GuestOrdersActions.exportAll(selectedSiteId, payload))}
      handleRefreshReport={() =>
        dispatch(GuestOrdersActions.fetch(pageOverride?.number || 0, pageOverride?.size || 25, payload, pathVariables))
      }
      filterPayload={payload}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={pageOverride}
      selectItem={(item) => dispatch(GuestOrdersActions.select(item))}
      error={error}
      clearError={() => dispatch(GuestOrdersActions.clearError())}
      columns={columns}
      prefix="GUEST_ORDERS"
      detailURL="/reservation/orders/view/{id}"
      data={requests}
      lastActionType={lastActionType}
      idColumn={'id'}
      filters={filters}
      clearableFilter={true}
    />
  );
}
