import React, { useEffect, useMemo, useState } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import ActivityBookingActions from '../../../redux/actions/activity/booking/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterUpdateMethods, getSelectFilterField } from '../../../../components/table/filter/filterUtils';
import { ActivityBookingStatus, ActivityBookingStatusOptions } from '../../../../support/ActivityBookingStatus';
import { Chip, IconButton } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import ActivityBookingApprovalDialog from './ActivityBookingApprovalDialog';
import UserRoleHelper from '../../../../support/UserRoleHelper';
import UserRole from '../../../../support/UserRole';
import ActivityBookingRequestDialog from './ActivityBookingRequestDialog';

const columns = [
  {
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(ActivityBookingActions.select(value))}>
            <EditOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'tripReference',
    label: 'Trip Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'activityName',
    label: 'Activity Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'date',
    label: 'Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'from',
    label: 'From',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'to',
    label: 'To',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'rates',
    label: 'Rates',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value.rates.map((val) => (
        <Chip key={`chip-${val.id}-${val.rateName}`} size={'small'} label={`${val.quantity} - ${val.rateName}`} />
      ));
    },
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

const getFilterFieldsForScreen = (state, setState, activityNames) => {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getSelectFilterField('status', 'Status', ActivityBookingStatusOptions, filterUpdateMethods),
    getSelectFilterField('activity', 'Activity', activityNames, filterUpdateMethods),
  ];
};

export default function ActivityBookingList({ bookingId }) {
  const dispatch = useDispatch();
  const activityList = useSelector((state) => state.activityReducer.entityList);
  const activityNames = activityList?.map((act) => ({ label: act.name, value: act.id }));
  const bookingList = useSelector((state) => state.activityBookingReducer.entityList);
  const vendor = useSelector((state) => state.vendorReducer.selectedItem);
  const profile = useSelector((state) => state.accessReducer.profile);
  const vendorId = vendor?.id;
  const selectedItem = useSelector((state) => state.activityBookingReducer.selectedItem);
  const processing = useSelector((state) => state.activityBookingReducer.processing);
  const page = useSelector((state) => state.activityBookingReducer.page);
  const error = useSelector((state) => state.activityBookingReducer.error);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [initialBooking, setInitialBooking] = useState(bookingId);

  const [filterState, setFilterState] = useState({
    status: ActivityBookingStatus.PAID,
    activity: '',
  });

  useEffect(() => {
    setApprovalModalOpen(true);
  }, [selectedItem]);

  useEffect(() => {
    const params = { vendorId };

    if (filterState['status'] || filterState['activity']) {
      if (filterState['status']) {
        params.status = filterState['status'];
      }

      if (filterState['activity']) {
        params.activityId = filterState['activity'];
      }
    }

    dispatch(ActivityBookingActions.fetch(0, 25, params));

    if (initialBooking) {
      dispatch(ActivityBookingActions.find(initialBooking));
      setInitialBooking(null);
    }
  }, [dispatch, vendorId, filterState, initialBooking]);

  const filters = getFilterFieldsForScreen(filterState, setFilterState, activityNames);

  const closeApprovalModal = () => {
    dispatch(ActivityBookingActions.select(null));
    setApprovalModalOpen(false);
  };

  const createFunction = useMemo(() => {
    if (UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER)) {
      return () => setRequestModalOpen(true);
    }

    return null;
  }, [profile]);

  return (
    <>
      {selectedItem && <ActivityBookingApprovalDialog open={approvalModalOpen} setClose={closeApprovalModal} booking={selectedItem} />}
      <ActivityBookingRequestDialog open={requestModalOpen} setClose={() => setRequestModalOpen(false)} booking={selectedItem} />
      <AutoTable
        title="Booking List"
        subheader={'Bookings made by guests staying at a Site'}
        filters={filters}
        backHidden
        handleRefreshReport={() => dispatch(ActivityBookingActions.fetch(0, 0, { vendorId: vendorId }, {}))}
        selectItem={(item) => dispatch(ActivityBookingActions.select(item))}
        processing={processing}
        handleChangePage={(ev, newPage, pageSize) => dispatch(ActivityBookingActions.fetch(newPage, pageSize, { vendorId: vendorId }, {}))}
        page={page}
        error={error}
        columns={columns}
        prefix="ACTIVITY_BOOKING"
        data={bookingList}
        clearableFilter={true}
        onCreateNew={createFunction}
      />
    </>
  );
}
