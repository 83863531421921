const VendorDescriptionDisplay = {
  MENU: 'MENU',
  CART: 'CART',
  MENU_CART: 'MENU_CART',
};

const VendorDescriptionDisplayOptions = [
  { value: VendorDescriptionDisplay.MENU, label: 'Menu' },
  { value: VendorDescriptionDisplay.CART, label: 'Cart' },
  { value: VendorDescriptionDisplay.MENU_CART, label: 'Menu & Cart' },
];

export { VendorDescriptionDisplay, VendorDescriptionDisplayOptions };
