import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../redux/actions/theme/terms/actions';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';

const languageList = [
  { value: 'en', label: 'English' },
  { value: 'ko', label: 'Korean' },
  { value: 'ch', label: 'Chinese' },
  { value: 'ja', label: 'Japanese' },
];

export default function ThemeTermsEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.themeReducer.error);
  const selectedTheme = useSelector((state) => state.themeReducer.selectedItem);
  const lastActionType = useSelector((state) => state.themeTermsReducer.lastActionType);
  const selectedItem = useSelector((state) => state.themeTermsReducer.selectedItem);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [languageCode, setLanguageCode] = React.useState(selectedItem?.languageCode || 'en');
  const [termsContent, setTermsContent] = React.useState(selectedItem?.termsContent || '');
  const [type, setType] = React.useState(selectedItem?.type || 'RESERVATION');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'THEME_TERMS_DELETE_SUCCESS' || lastActionType === 'THEME_TERMS_ADD_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = React.useCallback(() => {
    const id = selectedItem?.id;
    const themeId = selectedTheme?.id;

    const payload = {
      ...selectedItem,
      id: id,
      name: name,
      languageCode: languageCode,
      type: type,
      termsContent: termsContent,
      themeId: themeId,
    };

    if (id) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  }, [dispatch, selectedItem, selectedTheme, name, languageCode, type, termsContent]);

  const isFormReady = name && languageCode && type && termsContent;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Edit Terms And Conditions' : 'Create Terms And Conditions'}
        subheader={'Terms and conditions are displayed to your guests during the check-in process'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/delete terms - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              label="Friendly Name"
              type="text"
              variant={'outlined'}
              onChange={(event) => setName(event.target.value)}
              value={name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="language-code">Language</InputLabel>
              <Select
                labelId="language-code"
                value={languageCode}
                labelWidth={75}
                onChange={(event) => setLanguageCode(event.target.value)}
              >
                {languageList.map((l) => (
                  <MenuItem key={l.value} value={l.value}>
                    {l.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="type">Terms Type</InputLabel>
              <Select labelId="type" value={type} labelWidth={90} onChange={(event) => setType(event.target.value)}>
                <MenuItem value="RESERVATION">RESERVATION</MenuItem>
                <MenuItem value="MEMBERSHIP">MEMBERSHIP</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="termsContent"
              label="Terms Content"
              type="text"
              variant={'outlined'}
              onChange={(event) => setTermsContent(event.target.value)}
              multiline
              rows={8}
              value={termsContent}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
