import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../redux/actions/paymentProvider/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import UserRole from '../../../../support/UserRole';
import Alert from '@material-ui/lab/Alert';
import UserRoleHelper from '../../../../support/UserRoleHelper';
import Objects from '../../../../support/Objects';

const TYPE_TYPE_ONLINE = 'ONLINE_PAYMENT';
const TYPE_TYPE_COMPLETION = 'ONLINE_COMPLETION';

function getItemKeyValue(propertyName, keyType, selectedItem) {
  if (selectedItem && selectedItem.keys?.length > 0) {
    const key = selectedItem.keys.find((k) => k.keyType === keyType);
    if (key) {
      return key[propertyName];
    }
  }
  return '';
}

export default function PaymentProviderEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.accessReducer.profile);
  const error = useSelector((state) => state.paymentProviderReducer.error);
  const lastActionType = useSelector((state) => state.paymentProviderReducer.lastActionType);
  const selectedItem = useSelector((state) => state.paymentProviderReducer.selectedItem);
  const corporationList = useSelector((state) => state.accessReducer.corporationList);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [type, setType] = React.useState(selectedItem?.type || 'WINDCAVE');
  const [corporationId, setCorporationId] = React.useState(selectedItem?.corporationId || 0);
  const [enableAddRebillCard, setAddEnableRebillCard] = React.useState(selectedItem?.enableAddRebillCard);

  //online payment
  const [onlinePaymentUserId, setOnlinePaymentUserId] = React.useState(getItemKeyValue('userId', TYPE_TYPE_ONLINE, selectedItem));
  const [onlinePaymentUserKey, setOnlinePaymentUserKey] = React.useState(getItemKeyValue('userKey', TYPE_TYPE_ONLINE, selectedItem));
  const [onlinePaymentGatewayURL, setOnlinePaymentGatewayURL] = React.useState(
    getItemKeyValue('gatewayUrl', TYPE_TYPE_ONLINE, selectedItem)
  );
  const [onlinePaymentCallbackURL, setOnlinePaymentCallbackURL] = React.useState(
    getItemKeyValue('callbackUrl', TYPE_TYPE_ONLINE, selectedItem)
  );
  const [apiKey, setApiKey] = React.useState(getItemKeyValue('apiKey', TYPE_TYPE_ONLINE, selectedItem));
  const [sharedSecret, setSharedSecret] = React.useState(getItemKeyValue('sharedSecret', TYPE_TYPE_ONLINE, selectedItem));

  const [publicIntegrationKey, setPublicIntegrationKey] = React.useState(
    getItemKeyValue('publicIntegrationKey', TYPE_TYPE_ONLINE, selectedItem)
  );

  const [onlinePaymentMethod, setOnlinePaymentMethod] = React.useState(
    getItemKeyValue('onlinePaymentMethod', TYPE_TYPE_ONLINE, selectedItem)
  );

  const [threeDSType, setThreeDSType] = React.useState(getItemKeyValue('threeDSType', TYPE_TYPE_ONLINE, selectedItem));
  const [developmentMode, setDevelopmentMode] = React.useState(getItemKeyValue('testMode', TYPE_TYPE_ONLINE, selectedItem));

  const [rmsPropertyId, setRmsPropertyId] = React.useState(getItemKeyValue('miscData', TYPE_TYPE_ONLINE, selectedItem));

  //online completion
  const [onlineCompletionUserId, setOnlineCompletionUserId] = React.useState(getItemKeyValue('userId', TYPE_TYPE_COMPLETION, selectedItem));
  const [onlineCompletionUserKey, setOnlineCompletionUserKey] = React.useState(
    getItemKeyValue('userKey', TYPE_TYPE_COMPLETION, selectedItem)
  );
  const [onlineCompletionGatewayURL, setOnlineCompletionGatewayURL] = React.useState(
    getItemKeyValue('gatewayUrl', TYPE_TYPE_COMPLETION, selectedItem)
  );
  const [onlineCompletionCallbackURL, setOnlineCompletionCallbackURL] = React.useState(
    getItemKeyValue('callbackUrl', TYPE_TYPE_COMPLETION, selectedItem)
  );

  const isRmsPay = type === 'RMS_PAY';

  const isAdyenPay = type === 'ADYEN';

  const [merchantId, setMerchantId] = React.useState(getItemKeyValue('apiKey', TYPE_TYPE_COMPLETION, selectedItem));

  const [stationId, setStationId] = React.useState(getItemKeyValue('sharedSecret', TYPE_TYPE_COMPLETION, selectedItem));

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'PAYMENT_PROVIDER_UPDATE_SUCCESS' ||
      lastActionType === 'PAYMENT_PROVIDER_DELETE_SUCCESS' ||
      lastActionType === 'PAYMENT_PROVIDER_ADD_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      type: type,
      corporationId: corporationId,
      enableAddRebillCard: enableAddRebillCard,
      keys: [
        {
          id: getItemKeyValue('id', TYPE_TYPE_ONLINE, selectedItem) || null,
          userId: onlinePaymentUserId,
          userKey: onlinePaymentUserKey,
          keyType: TYPE_TYPE_ONLINE,
          gatewayUrl: onlinePaymentGatewayURL,
          callbackUrl: onlinePaymentCallbackURL,
          sharedSecret: sharedSecret,
          apiKey: apiKey,
          publicIntegrationKey: publicIntegrationKey,
          onlinePaymentMethod: onlinePaymentMethod,
          threeDSType: threeDSType,
          testMode: developmentMode,
          miscData: rmsPropertyId,
        },
        {
          id: getItemKeyValue('id', TYPE_TYPE_COMPLETION, selectedItem) || null,
          userId: onlineCompletionUserId,
          userKey: onlineCompletionUserKey,
          keyType: TYPE_TYPE_COMPLETION,
          gatewayUrl: onlineCompletionGatewayURL,
          callbackUrl: onlineCompletionCallbackURL,
          sharedSecret: merchantId,
          apiKey: stationId,
        },
      ],
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady =
    name &&
    type &&
    !Objects.isNullOrEmpty(corporationId) &&
    onlinePaymentUserId &&
    onlinePaymentUserKey &&
    onlinePaymentGatewayURL &&
    onlinePaymentCallbackURL &&
    onlineCompletionUserId &&
    onlineCompletionUserKey &&
    onlineCompletionGatewayURL &&
    onlineCompletionCallbackURL;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Provider' : 'Create Provider'}
        subheader={'A single payment provider can be applied to multiple properties'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem}
        onDelete={() => dispatch(Actions.delete(selectedItem, '/api/private/portal/payment/providers/' + selectedItem?.id))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/delete payment provider - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Name"
              variant={'outlined'}
              type="text"
              onChange={(event) => setName(event.target.value)}
              value={name}
              error={!name}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="provider-name">Provider Name</InputLabel>
              <Select
                labelId="provider-name"
                name="type"
                value={type}
                labelWidth={105}
                onChange={(event) => setType(event.target.value)}
                required
              >
                <MenuItem value="WINDCAVE">WINDCAVE</MenuItem>
                <MenuItem value="CURRENCY_SELECT">CURRENCY SELECT</MenuItem>
                <MenuItem value="RMS_PAY">RMS PAY</MenuItem>
                <MenuItem value="ADYEN">ADYEN</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="corp-select-label">Linked Organisation</InputLabel>
              <Select
                labelId="corp-select-label"
                name="corporationId"
                value={corporationId}
                labelWidth={145}
                onChange={(event) => setCorporationId(event.target.value)}
                required
              >
                {UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER) && <MenuItem value={'0'}>Global</MenuItem>}
                {corporationList.map((c) => (
                  <MenuItem key={`cselect-${c.id}`} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="enable-add-rebill-card">Enable Add Rebill Card</InputLabel>
              <Select
                labelId="enable-add-rebill-card"
                value={enableAddRebillCard}
                labelWidth={165}
                onChange={(event) => setAddEnableRebillCard(event.target.value)}
              >
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">Online Payment Key</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isRmsPay ? 'Client ID' : isAdyenPay ? 'Merchant Account Code ID' : 'User ID'}
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlinePaymentUserId(event.target.value)}
              value={onlinePaymentUserId}
              required
              autoComplete={'off'}
              autoCorrect={'off'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isRmsPay ? 'Client Password' : isAdyenPay ? 'Client Key' : 'User Key'}
              type="password"
              variant={'outlined'}
              onChange={(event) => setOnlinePaymentUserKey(event.target.value)}
              value={onlinePaymentUserKey}
              required
              autoComplete={'new-password'}
              autoCorrect={'off'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isRmsPay ? 'RMS Endpoint' : 'Gateway URL'}
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlinePaymentGatewayURL(event.target.value)}
              value={onlinePaymentGatewayURL}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Callback URL"
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlinePaymentCallbackURL(event.target.value)}
              value={onlinePaymentCallbackURL}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isRmsPay ? 'Agent ID' : 'API Key'}
              type="password"
              variant={'outlined'}
              onChange={(event) => setApiKey(event.target.value)}
              value={apiKey}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isRmsPay ? 'Agent Password' : isAdyenPay ? 'Theme ID' : 'Shared Secret'}
              type={isAdyenPay ? 'text' : 'password'}
              variant={'outlined'}
              onChange={(event) => setSharedSecret(event.target.value)}
              value={sharedSecret}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="threeDSType">3D Secure </InputLabel>
              <Select
                labelId="threeDSType"
                name="threeDSType"
                value={threeDSType}
                labelWidth={105}
                onChange={(event) => setThreeDSType(event.target.value)}
                required
              >
                <MenuItem value="OFF">OFF</MenuItem>
                <MenuItem value="OPTIONAL">OPTIONAL</MenuItem>
                <MenuItem value="MANDATORY">MANDATORY</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="onlinePaymentMethod">Online Payment Method</InputLabel>
              <Select
                labelId="onlinePaymentMethod"
                name="onlinePaymentMethod"
                value={onlinePaymentMethod}
                labelWidth={105}
                onChange={(event) => setOnlinePaymentMethod(event.target.value)}
                required
              >
                <MenuItem value="IFRAME">IFRAME</MenuItem>
                <MenuItem value="INTEGRATED_JS">INTEGRATED JS</MenuItem>
                <MenuItem value="REDIRECT">REDIRECT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {onlinePaymentMethod === 'INTEGRATED_JS' && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Public Integration Key"
                type="password"
                variant={'outlined'}
                onChange={(event) => setPublicIntegrationKey(event.target.value)}
                value={publicIntegrationKey}
                required={onlinePaymentMethod === 'INTEGRATED_JS'}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel>{isRmsPay ? 'Beta Mode' : 'Development Mode'}</InputLabel>
              <Select value={developmentMode} labelWidth={100} onChange={(event) => setDevelopmentMode(event.target.value)}>
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {isRmsPay && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Property ID"
                type="text"
                variant={'outlined'}
                onChange={(event) => setRmsPropertyId(event.target.value)}
                value={rmsPropertyId}
                required={type === 'RMS_PAY'}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="subtitle1">Online Payment Completion</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isAdyenPay ? 'Merchant Account Code ID' : 'User ID'}
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlineCompletionUserId(event.target.value)}
              value={onlineCompletionUserId}
              required
              autoComplete={'off'}
              autoCorrect={'off'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label={isAdyenPay ? 'Webhook HMAC Key' : 'User Key'}
              type="password"
              variant={'outlined'}
              onChange={(event) => setOnlineCompletionUserKey(event.target.value)}
              value={onlineCompletionUserKey}
              autoComplete={'new-password'}
              autoCorrect={'off'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Gateway URL"
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlineCompletionGatewayURL(event.target.value)}
              value={onlineCompletionGatewayURL}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Callback URL"
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlineCompletionCallbackURL(event.target.value)}
              value={onlineCompletionCallbackURL}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Merchant ID"
              type="text"
              variant={'outlined'}
              onChange={(event) => setMerchantId(event.target.value)}
              value={merchantId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Station ID"
              type="text"
              variant={'outlined'}
              onChange={(event) => setStationId(event.target.value)}
              value={stationId}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
