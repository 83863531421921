import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppActions from '../../../redux/actions/app/actions';
import * as LinkedSitesActions from '../../../redux/actions/app/linkedSites/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Palette } from '@material-ui/icons';
import FormField from '../../../../components/form/FormField';

export default function AppEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedItem = useSelector((state) => state.appReducer.selectedItem);
  const selectedItemId = selectedItem?.id;
  const lastActionType = useSelector((state) => state.appReducer.lastActionType);
  const error = useSelector((state) => state.appReducer.error);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const linkedSiteList = useSelector((state) => state.appLinkedSitesReducer.data);
  const linkedSitesError = useSelector((state) => state.appLinkedSitesReducer.error);

  const [name, setName] = React.useState(selectedItem ? selectedItem.name : '');
  const [firebaseName, setFirebaseName] = React.useState(selectedItem ? selectedItem.firebaseName : makeId(5));
  const [type, setType] = React.useState(selectedItem ? selectedItem.type : 'HOTEL');
  const [code, setCode] = React.useState(selectedItem ? selectedItem.code : '');
  const [appleStoreUrl, setAppleStoreUrl] = React.useState(selectedItem ? selectedItem.appleStoreUrl : 'https://apple.store.com/todo');
  const [androidStoreUrl, setAndroidStoreUrl] = React.useState(
    selectedItem ? selectedItem.androidStoreUrl : 'https://android.store.com/todo'
  );
  const [dynamicLink, setDynamicLink] = React.useState(selectedItem ? selectedItem.dynamicLink : 'https://link.smartcheckmobile.com');
  const [deepLink, setDeepLink] = React.useState(selectedItem ? selectedItem.deepLink : 'https://smartcheckmobile.com/desktop/scm/');
  const [bundleId, setBundleId] = React.useState(selectedItem ? selectedItem.bundleId : 'com.smartcheckmobile');
  const [appStoreId, setAppStoreId] = React.useState(selectedItem ? selectedItem.appStoreId : '1527666789');
  const [packageName, setPackageName] = React.useState(selectedItem ? selectedItem.packageName : 'com.liverton.smartcheck.mobile');
  const [devUserPoolId, setDevUserPoolId] = React.useState(selectedItem ? selectedItem.devUserPoolId : '');
  const [prodUserPoolId, setProdUserPoolId] = React.useState(selectedItem ? selectedItem.prodUserPoolId : '');
  const [socialLogin, setSocialLogin] = React.useState(selectedItem ? selectedItem.socialLogin : true);
  const [bookingEnabled, setBookingEnabled] = React.useState(selectedItem ? selectedItem.bookingEnabled : true);
  const [webApiKey, setWebApiKey] = React.useState(selectedItem ? selectedItem.webApiKey : '');
  const [selectedSites, setSelectedSites] = React.useState([]);

  useEffect(() => {
    dispatch(AppActions.clearError());
    dispatch(LinkedSitesActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItemId) {
      dispatch(LinkedSitesActions.getLinkedSites(selectedItemId));
    }
  }, [dispatch, selectedItemId]);

  useEffect(() => {
    if (linkedSiteList.length > 0) {
      setSelectedSites(
        linkedSiteList.map((s) => {
          return { value: s.id, label: s.name };
        })
      );
    }
  }, [linkedSiteList]);

  useEffect(() => {
    if (lastActionType === 'APP_ADD_SUCCESS' || lastActionType === 'APP_DELETE_SUCCESS' || lastActionType === 'APP_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      firebaseName: firebaseName,
      type: type,
      code: code,
      appleStoreUrl: appleStoreUrl,
      androidStoreUrl: androidStoreUrl,
      dynamicLink: dynamicLink,
      devUserPoolId: devUserPoolId,
      prodUserPoolId: prodUserPoolId,
      socialLogin: socialLogin,
      bookingEnabled: bookingEnabled,
      packageName: packageName,
      deepLink: deepLink,
      bundleId: bundleId,
      appStoreId: appStoreId,
      webApiKey: webApiKey,
    };

    if (selectedItem) {
      dispatch(AppActions.update(payload));
    } else {
      dispatch(AppActions.add(payload));
    }
  };

  const onLinkedSitesChange = React.useCallback(
    (event, values) => {
      setSelectedSites(values);

      const payload = {
        siteIdList: values.map((s) => s.value),
        appId: selectedItemId,
      };

      dispatch(LinkedSitesActions.updateLinkedSites(payload));
    },
    [dispatch, selectedItemId]
  );

  const isFormReady =
    name && type && code && dynamicLink && devUserPoolId && prodUserPoolId && packageName && deepLink && appStoreId && bundleId;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update App' : 'Create App'}
        subheader={'Each mobile app can have a unique brand and app store presence'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(AppActions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to add app - ${error.message}`}
          </Alert>
        )}

        {linkedSitesError && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to update/fetch linked sites - ${linkedSitesError.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              label="App Name"
              type="text"
              variant={'outlined'}
              onChange={(event) => setName(event.target.value)}
              value={name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="firebaseName"
              type="text"
              label="Firebase App Name"
              variant={'outlined'}
              onChange={(event) => setFirebaseName(event.target.value)}
              value={firebaseName}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="app-type">App Type</InputLabel>
              <Select labelId="app-type" value={type} labelWidth={75} onChange={(event) => setType(event.target.value)}>
                <MenuItem value="HOTEL">HOTEL</MenuItem>
                <MenuItem value="ACCESS_CONTROL">ACCESS CONTROL</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="code"
              label="App Code"
              type="number"
              variant={'outlined'}
              onChange={(event) => setCode(event.target.value)}
              value={code}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="appleStoreUrl"
              label="Apple App Store Url"
              type="text"
              variant={'outlined'}
              onChange={(event) => setAppleStoreUrl(event.target.value)}
              value={appleStoreUrl}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="androidStoreUrl"
              label="Android App Store Url"
              type="text"
              variant={'outlined'}
              onChange={(event) => setAndroidStoreUrl(event.target.value)}
              value={androidStoreUrl}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="dynamicLink"
              label="App Dynamic Link"
              type="text"
              variant={'outlined'}
              onChange={(event) => setDynamicLink(event.target.value)}
              value={dynamicLink}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="deepLink"
              label="App Deep Link"
              type="text"
              variant={'outlined'}
              onChange={(event) => setDeepLink(event.target.value)}
              value={deepLink}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="bundleId"
              label="App Bundle Id"
              type="text"
              variant={'outlined'}
              onChange={(event) => setBundleId(event.target.value)}
              value={bundleId}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="appStoreId"
              label="App Store Id"
              type="text"
              variant={'outlined'}
              onChange={(event) => setAppStoreId(event.target.value)}
              value={appStoreId}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="packageName"
              label="App Package Name"
              type="text"
              variant={'outlined'}
              onChange={(event) => setPackageName(event.target.value)}
              value={packageName}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="devUserPoolId"
              label="Developer User Pool Id"
              type="text"
              variant={'outlined'}
              onChange={(event) => setDevUserPoolId(event.target.value)}
              value={devUserPoolId}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="prodUserPoolId"
              label="Production User Pool Id"
              type="text"
              variant={'outlined'}
              onChange={(event) => setProdUserPoolId(event.target.value)}
              value={prodUserPoolId}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Firebase Web Api Key'}
              type={'password'}
              value={webApiKey}
              setValue={setWebApiKey}
              error={!webApiKey}
              margin={'none'}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="booking-enabled">Booking Enabled</InputLabel>
              <Select
                labelId="booking-enabled"
                value={bookingEnabled}
                labelWidth={125}
                onChange={(event) => setBookingEnabled(event.target.value)}
              >
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="social-login">Social Login</InputLabel>
              <Select labelId="social-login" value={socialLogin} labelWidth={100} onChange={(event) => setSocialLogin(event.target.value)}>
                <MenuItem value={true}>YES</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {selectedItem && (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  fullWidth
                  name="sites"
                  placeholder="Please select sites that the app is linked to"
                  options={siteList.map((s) => {
                    return { value: s.id, label: s.name };
                  })}
                  getOptionSelected={(option, selected) => option.value === selected.value}
                  getOptionLabel={(option) => option.label}
                  multiple
                  onChange={onLinkedSitesChange}
                  value={selectedSites}
                  renderInput={(params) => <TextField {...params} label="Linked Sites" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button variant={'contained'} color={'primary'} startIcon={<Palette />} onClick={() => history.push('/apps/theme')}>
                  View Theme
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function makeId(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
