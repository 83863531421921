import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import VendorActions from '../../../redux/actions/vendor/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { AppBar, Card, CardContent, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../components/form/FormField';
import ActivityList from '../activity/ActivityList';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';
import TabPanel from '../../control/TabPanel';
import ActivityBookingList from '../activity/ActivityBookingList';

export default function VendorEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.vendorReducer);
  const [vendorName, setVendorName] = React.useState(selectedItem ? selectedItem.name : '');
  const [description, setDescription] = React.useState(selectedItem ? selectedItem.description : '');
  const [email, setEmail] = React.useState(selectedItem ? selectedItem.email : '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [website, setWebsite] = React.useState(selectedItem ? selectedItem.website : '');

  const tab = new URLSearchParams(location.search).get('tab');
  const bookingId = new URLSearchParams(location.search).get('bookingId');
  const [selectedTab, setSelectedTab] = useState(tab ? parseInt(tab) : 0);
  const tabList = [{ label: 'Activities' }, { label: 'Bookings' }];

  useEffect(() => {
    dispatch(VendorActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'VENDOR_DELETE_SUCCESS' || lastActionType === 'VENDOR_EDIT_SUCCESS' || lastActionType === 'VENDOR_ADD_SUCCESS') {
      history.push('/vendor');
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: vendorName,
      description: description,
      email: email,
      phone: phone.sanitizedPhoneNumber,
      website: website,
    };

    if (selectedItem) {
      dispatch(VendorActions.update(payload, null, false));
    } else {
      dispatch(VendorActions.add(payload));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Vendor' : 'Create Vendor'}
        subheader={'A vendor is a company with one or more properties'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!vendorName || processing}
        onDelete={() => dispatch(VendorActions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add vendor - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Vendor Name'} value={vendorName} setValue={setVendorName} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Website'} value={website} setValue={setWebsite} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Email'} value={email} setValue={setEmail} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={false} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={'Description'} value={description} setValue={setDescription} />
          </Grid>
        </Grid>
      </CardContent>
      {selectedItem && (
        <Paper>
          <AppBar position="static">
            <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
              {selectedItem && tabList.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
            </Tabs>
          </AppBar>
          <div className="content-scrollable-no-padding">
            <TabPanel value={selectedTab} index={0}>
              <ActivityList />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ActivityBookingList bookingId={bookingId} />
            </TabPanel>
          </div>
        </Paper>
      )}
    </Card>
  );
}
