const PmsSystem = {
  OPERA: 'Opera Property Management System',
  RMS: 'RMS Management System',
  MOCK: 'Manual Property Management System',
  PROTEL: 'Protel Property Management System',
  INFOR: 'Infor Property Management System',
  CMS: 'Guest Centrix Management System',
  MEWS: 'Mews Property Management System',
  CLARITY: 'Clarity Property Management System',
};

export default PmsSystem;
