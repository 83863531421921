import Objects from '../../support/Objects';
import AppConfig from '../../AppConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// reminder: the current firestore DB is going to be automatically deleted in 30 days.
// need to configure a production DB and set the security rules there if needed.
// also, .env file could probably be added to gitignore - is this necessary?

const firebaseConfiguration = AppConfig.FIREBASE_CONFIGURATION;

const config = {
  apiKey: firebaseConfiguration.apiKey,
  authDomain: firebaseConfiguration.authDomain,
  databaseURL: firebaseConfiguration.databaseURL,
  projectId: firebaseConfiguration.projectId,
  storageBucket: firebaseConfiguration.storageBucket,
  messagingSenderId: firebaseConfiguration.messagingSenderId,
  appId: firebaseConfiguration.appId,
  measurementId: firebaseConfiguration.measurementId,
};

firebase.initializeApp(config);
const db = firebase.firestore();

export const signInAnonymously = () => {
  return firebase.auth().signInAnonymously();
};

export const getChat = (collectionId, docId) => {
  return db.collection(collectionId).doc(docId).get();
};

export const getChatThreadList = (collectionId) => {
  console.log('Site collection id for which messages are fetched ', collectionId);
  return db.collection(collectionId).get();
};

export const addMessageToChatThread = (message, collectionId, docId) => {
  const messageId = Objects.getUUid();
  return db
    .collection(collectionId)
    .doc(docId)
    .update({
      messages: firebase.firestore.FieldValue.arrayUnion({
        text: message.text,
        createdAt: message.createdAt,
        user: message.user,
        _id: messageId, // is this truly used anywhere?
        isSenderAdmin: true,
      }),
      unreadByUser: firebase.firestore.FieldValue.increment(1),
    });
};

export const streamMessageChanges = (collectionId, observer) => {
  return db.collection(collectionId).onSnapshot(observer);
};

export const setUnreadMessagesToZero = (collectionId, docId) => {
  try {
    return db.collection(collectionId).doc(docId).update({
      unreadByAdmin: 0,
    });
  } catch (error) {
    console.error('Unable to update document read count to zero: ', error);
    return Promise.reject(error);
  }
};

export const createSiteCollection = (collectionId) => {
  const dummyDocId = Objects.getUUid();

  return db.collection(collectionId).doc(dummyDocId).set({
    dummyDocument: true,
    recipient: dummyDocId,
  });
};

export const deleteDocument = (collectionId, docId) => {
  if (docId) {
    console.log(`Trying to delete document (${docId}) from collection (${collectionId})`);

    const collection = db.collection(collectionId);
    if (collection) {
      const document = collection.doc(docId);
      if (document) {
        return document.delete();
      }
    }
    return db.collection(collectionId).doc(docId).delete();
  } else {
    console.warn(`Call to delete document with a NULL docId on collection (${collectionId})`);
    return Promise.reject(new Error('Document ID was null unable to delete'));
  }
};
