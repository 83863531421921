import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodModifierActions from '../../../../redux/actions/inRoom/ordering/foodModifier/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

export default function FoodModifierEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodModifierReducer);
  const externalId = selectedItem?.externalId;
  const [modifierName, setModifierName] = useState(selectedItem?.modifierName || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [price, setPrice] = useState(selectedItem?.price);
  const [sequence, setSequence] = useState(selectedItem?.sequence || 1);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);

  useEffect(() => {
    dispatch(FoodModifierActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_MODIFIER_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_MODIFIER_ADD_SUCCESS' ||
      lastActionType === 'FOOD_MODIFIER_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      modifierName: modifierName,
      description: description,
      price: price,
      sequence: sequence,
      enabled: enabled,
    };

    if (selectedItem) {
      dispatch(FoodModifierActions.update(payload, `/api/private/portal/food/modifier/${selectedItem.id}`, false));
    } else {
      dispatch(FoodModifierActions.add(payload, { vendorId: vendorId }));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Modifier' : 'Create Food Modifier'}
        subheader={'A Food Item Modifier contains customisations for a Food Item'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!modifierName || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodModifierActions.delete(selectedItem, `/api/private/portal/food/modifier/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food item modifier - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={modifierName} setValue={setModifierName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField disabled label={'External ID'} value={externalId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Description'} value={description} setValue={setDescription} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={price || price === 0 ? 'Price' : 'Open Price'} value={price} setValue={setPrice} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField type={'number'} step={1} label={'Sequence'} value={sequence} setValue={setSequence} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} required />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
