const ReceiptGroupingMode = {
  DEFAULT: 'DEFAULT',
  REVENUE_TYPE: 'REVENUE_TYPE',
};

const ReceiptGroupingModeOptions = [
  { value: ReceiptGroupingMode.DEFAULT, label: 'Default' },
  { value: ReceiptGroupingMode.REVENUE_TYPE, label: 'Revenue' },
];

export default ReceiptGroupingMode;
export { ReceiptGroupingModeOptions };
