import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

export const FEEDBACK_FETCH_SUCCESS = 'FEEDBACK_FETCH_SUCCESS';
export const FEEDBACK_FETCH_FAILURE = 'FEEDBACK_FETCH_FAILURE';

export const FEEDBACK_ADD_SUCCESS = 'FEEDBACK_ADD_SUCCESS';
export const FEEDBACK_ADD_FAILURE = 'FEEDBACK_ADD_FAILURE';

export const FEEDBACK_UPDATE_SUCCESS = 'FEEDBACK_UPDATE_SUCCESS';
export const FEEDBACK_UPDATE_FAILURE = 'FEEDBACK_UPDATE_FAILURE';

export const FEEDBACK_DELETE_SUCCESS = 'FEEDBACK_DELETE_SUCCESS';
export const FEEDBACK_DELETE_FAILURE = 'FEEDBACK_DELETE_FAILURE';

export const FEEDBACK_ADD_IN_PROGRESS = 'FEEDBACK_ADD_IN_PROGRESS';
export const FEEDBACK_PROCESSING = 'FEEDBACK_PROCESSING';
export const FEEDBACK_CLEAR_ERROR = 'FEEDBACK_CLEAR_ERROR';
export const FEEDBACK_SELECT = 'FEEDBACK_SELECT';

export const fetch = (page, size, siteId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/feedback-option/site/${siteId}?page=${page || 0}&size=${size || 10}`;

  dispatch({ type: FEEDBACK_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({
      type: FEEDBACK_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
      },
    });
  } catch (error) {
    dispatch({ type: FEEDBACK_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const create = (request) => async (dispatch) => {
  dispatch({ type: FEEDBACK_ADD_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/feedback-option', { body: request });
    dispatch({ type: FEEDBACK_ADD_SUCCESS, payload: response });
    Swal.fire('Feedback Item Created', 'Feedback Item has been created successfully', 'success');
  } catch (error) {
    dispatch({ type: FEEDBACK_ADD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const update = (request) => async (dispatch) => {
  dispatch({ type: FEEDBACK_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/feedback-option', { body: request });
    dispatch({ type: FEEDBACK_UPDATE_SUCCESS, payload: response });
    Swal.fire('Feedback Item Updated', 'Item has been updated successfully', 'success');
  } catch (error) {
    dispatch({ type: FEEDBACK_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteOption = (selectedItem) => async (dispatch) => {
  dispatch({ type: FEEDBACK_PROCESSING });
  Swal.fire({
    title: `Delete Feedback Option?`,
    text: 'Warning: This operation is not reversible',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', `/api/private/portal/feedback-option/${selectedItem}`, {});
        dispatch({ type: FEEDBACK_DELETE_SUCCESS, payload: selectedItem });
      } catch (error) {
        dispatch({ type: FEEDBACK_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
      }
    }
  });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: FEEDBACK_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: FEEDBACK_SELECT, payload: item });
};
