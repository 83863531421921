import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import FormField from '../../../../../components/form/FormField';
import { DeleteOutlined } from '@material-ui/icons';

export default function FoodPriceLevel({ item, index, setItem, deleteItem, setError }) {
  const [priceLevel, setPriceLevel] = useState(item?.priceLevel);
  const [priceOverride, setPriceOverride] = useState(item?.priceOverride);

  useEffect(() => {
    setItem(index)({
      id: item?.id,
      foodItemId: item?.foodItemId,
      priceLevel: priceLevel,
      priceOverride: priceOverride,
    });

    if (!priceLevel || !priceOverride) {
      setError(true);
    } else {
      setError(false);
    }
  }, [priceOverride, priceLevel, item.id, item?.foodItemId, index, setItem, setError]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'center',
          }}
        >
          <IconButton size={'small'} color={'inherit'} onClick={() => deleteItem(index)}>
            <DeleteOutlined />
          </IconButton>
          <FormField
            label={'Price Level'}
            value={priceLevel}
            setValue={setPriceLevel}
            required
            type={'number'}
            inputProps={{ step: '1', min: '1' }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}>
        <FormField
          label={'Price'}
          value={priceOverride}
          setValue={setPriceOverride}
          type={'number'}
          inputProps={{ step: '0.01' }}
          reactInputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography disabled>$</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
