import React from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

export default function FormSelect({
  label,
  value,
  setValue,
  margin = 'dense',
  style,
  options = [],
  error = false,
  hasClear = false,
  clearValueToNull = false,
  ...props
}) {
  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  return (
    <FormControl fullWidth variant={'outlined'} margin={margin} error={error}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        name={label}
        value={value}
        label={label}
        style={style}
        onChange={(event) => setValue(event.target.value)}
        required
        {...props}
        endAdornment={
          hasClear && (
            <InputAdornment position="start">
              <IconButton onClick={() => (clearValueToNull ? setValue(null) : setValue(''))} size={'small'}>
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {options.map((o) => (
          <MenuItem value={o.value} key={`s-${o.label}`}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
