import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodInfoActions from '../../../../redux/actions/inRoom/ordering/foodInfo/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import toBase64 from '../../../../../support/toBase64Converter';

export default function FoodInfoEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodInfoReducer);
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [image, setImage] = useState(selectedItem?.banner || '');
  const [imageChanged, setImageChanged] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);

  useEffect(() => {
    dispatch(FoodInfoActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (image) {
      toBase64(image).then((base64) => {
        setImageBase64(base64);
      });
    }
  }, [image]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_INFO_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_INFO_ADD_SUCCESS' ||
      lastActionType === 'FOOD_INFO_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleImageSelect = (file) => {
    setImage(file);
    setImageChanged(true);
  };

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      description: description,
      image: imageBase64,
      imageChanged: imageChanged,
    };

    if (selectedItem) {
      dispatch(FoodInfoActions.update(payload, `/api/private/portal/food/info/${selectedItem.id}`, false));
    } else {
      dispatch(FoodInfoActions.add(payload, { vendorId: vendorId }));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Info' : 'Create Food Info'}
        subheader={'A Food Info is contains shared short information about the food'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!description || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodInfoActions.delete(selectedItem, `/api/private/portal/food/info/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food info - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Description'} value={description} setValue={setDescription} />
          </Grid>

          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'image'}
            displayName={'Image'}
            onSelect={handleImageSelect}
            imageCleared={(value) => {
              if (value) {
                setImageBase64(null);
                setImage(null);
              }
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
