import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const FoodModifierActions = {
  ...actionCreator.createActions('Food Modifier', 'FOOD_MODIFIER', '/api/private/portal/food/vendor/{vendorId}/food/modifier'),
  selectAndNavigate: (item, history) => async (dispatch) => {
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/modifier/${item.id}`, {});
      await dispatch({ type: 'FOOD_MODIFIER_SELECT', payload: result });
      history.push(`/food/modifier/view/${result.id}`);
    } catch (error) {
      dispatch({ type: `FOOD_MODIFIER_SELECT_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  fetchAll: (vendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_MODIFIER_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/food/modifier/list`, {});
      await dispatch({ type: `FOOD_MODIFIER_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food modifier with result: ', error);
      dispatch({
        type: `FOOD_MODIFIER_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  options: (vendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_MODIFIER_OPTIONS_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/food/modifier/options`, {});
      await dispatch({ type: `FOOD_MODIFIER_OPTIONS_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food modifier with result: ', error);
      dispatch({
        type: `FOOD_MODIFIER_OPTIONS_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default FoodModifierActions;
