import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../../redux/actions/rule/actions';
import { Card, CardContent, Collapse, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as DataTypes from '../../../../../constants/DataTypes';
import AutoTable from '../../../../../components/table/AutoTable';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../components/form/FormField';

// Table column rendering information.
const conditionColumns = [
  {
    id: 'name',
    label: 'Condition Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'conditionType',
    label: 'Condition Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'conditionEvaluation',
    label: 'Condition Evaluation',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  // {
  //   id: 'enabled',
  //   label: 'Enabled',
  //   align: 'left',
  //   dataType: DataTypes.DATA_TYPE_BOOLEAN,
  // },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

// Table column rendering information.
const actionColumns = [
  {
    id: 'name',
    label: 'Action Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'actionType',
    label: 'Action Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  // {
  //   id: 'enabled',
  //   label: 'Enabled',
  //   align: 'left',
  //   dataType: DataTypes.DATA_TYPE_BOOLEAN,
  // },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function Rule() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.siteRuleReducer.error);
  const lastActionType = useSelector((state) => state.siteRuleReducer.lastActionType);
  const selectedItem = useSelector((state) => state.siteRuleReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;

  const [name, setName] = React.useState(selectedItem ? selectedItem.name : '');
  const [orderNo, setOrderNo] = React.useState(selectedItem ? selectedItem.orderNo : 1);
  const [enabled, setEnabled] = React.useState(selectedItem ? selectedItem.enabled : true);

  React.useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  React.useEffect(() => {
    if (lastActionType === Actions.SITE_RULE_DELETE_SUCCESS) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const payload = {
      id: selectedItem ? selectedItem.id : null,
      name: name,
      orderNo: orderNo,
      enabled: enabled,
      conditions: selectedItem ? selectedItem.conditions : [],
      actions: selectedItem ? selectedItem.actions : [],
      siteId: selectedSiteId,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, selectedItem));
    } else {
      dispatch(Actions.addRule(payload));
    }
  };

  const isFormReady = name && orderNo;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={selectedItem ? 'Update Rule' : 'Create Rule'}
            subheader={'Rules are processed for all guests to add or change behaviour under specific conditions'}
            buttonName={selectedItem ? 'Update' : 'Create'}
            onClick={handleSubmit}
            disabled={!isFormReady}
            deleteDisabled={!selectedItem}
            onDelete={() => dispatch(Actions.remove(selectedItem))}
          />

          <CardContent>
            {error && (
              <Alert severity="error" variant={'contained'} className={'mb-2'}>
                {`Unable to edit/delete rule - ${error.message}`}
              </Alert>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Rule Name'} value={name} setValue={setName} error={!name} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Rule Order'} type={'number'} value={orderNo} setValue={setOrderNo} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={selectedItem?.id}>
          <AutoTable
            title="Rule Conditions"
            subheader={'Conditions that have to be matched for the Rule to process'}
            selectItem={(item) => dispatch(Actions.selectItemCondition(item))}
            columns={conditionColumns}
            prefix="CONDITION"
            buttonName="New Condition"
            createNewURL="/site/rules/condition/new"
            detailURL="/site/rules/condition/view/{id}"
            idColumn={'id'}
            data={selectedItem?.conditions || []}
            lastActionType={lastActionType}
            hidePagination={true}
          />
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={selectedItem?.id}>
          <AutoTable
            title="Rule Action"
            subheader={'Actions to be performed on the Reservation'}
            selectItem={(item) => dispatch(Actions.selectItemAction(item))}
            columns={actionColumns}
            prefix="ACTION"
            buttonName="New Action"
            createNewURL="/site/rules/action/new"
            detailURL="/site/rules/action/view/{id}"
            idColumn={'id'}
            data={selectedItem?.actions || []}
            lastActionType={lastActionType}
            hidePagination={true}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
}
