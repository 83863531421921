import React from 'react';
import * as DataTypes from '../../../../../constants/DataTypes';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/securityDoor/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Sync } from '@material-ui/icons';

const columns = [
  {
    id: 'doorName',
    label: 'Door Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'doorId',
    label: 'Door ID',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'doorParent',
    label: 'Door Parent',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'visible',
    label: 'Visible',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function SecurityRoomList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const doorList = useSelector((state) => state.securityDoorReducer.entityList);
  const lastActionType = useSelector((state) => state.securityDoorReducer.lastActionType);
  const processing = useSelector((state) => state.securityDoorReducer.processing);
  const page = useSelector((state) => state.securityDoorReducer.page);
  const error = useSelector((state) => state.securityDoorReducer.error);
  const parameters = { siteId: parseInt(selectedSite?.id), sort: 'doorName,asc' };

  React.useEffect(() => {
    dispatch(Actions.select(null));
  }, [dispatch]);

  return (
    <AutoTable
      title="Security Room List"
      subheader={'Security rooms available at the site'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 50, parameters))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, parameters))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECURITY_ROOM"
      detailURL="/security/rooms/view/{id}"
      idColumn={'id'}
      data={doorList}
      lastActionType={lastActionType}
      createNewURL="/security/rooms/new"
      secondaryButtonAction={() => dispatch(Actions.resyncDoors(selectedSite.id))}
      secondaryButtonName={'Resync Rooms'}
      secondaryButtonIcon={<Sync />}
    />
  );
}
