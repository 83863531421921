import API from '@aws-amplify/api';
import Swal from 'sweetalert2';

export const SITE_RULE_CLEAR_ERROR = 'SITE_RULE_CLEAR_ERROR';
export const SITE_RULE_SELECT = 'SITE_RULE_SELECT';
export const SITE_RULE_ACTION_SELECTED = 'SITE_RULE_ACTION_SELECTED';
export const SITE_RULE_CONDITION_SELECTED = 'SITE_RULE_CONDITION_SELECTED';

export const SITE_RULE_FETCH_IN_PROGRESS = 'SITE_RULE_FETCH_IN_PROGRESS';
export const SITE_RULE_FETCH_SUCCESS = 'SITE_RULE_FETCH_SUCCESS';
export const SITE_RULE_FETCH_FAILURE = 'SITE_RULE_FETCH_FAILURE';

export const SITE_RULE_ADD_SUCCESS = 'SITE_RULE_ADD_SUCCESS';
export const SITE_RULE_ADD_FAILURE = 'SITE_RULE_ADD_FAILURE';

export const SITE_RULE_UPDATE_SUCCESS = 'SITE_RULE_UPDATE_SUCCESS';
export const SITE_RULE_UPDATE_FAILURE = 'SITE_RULE_UPDATE_FAILURE';

export const SITE_RULE_DELETE_SUCCESS = 'SITE_RULE_DELETE_SUCCESS';
export const SITE_RULE_DELETE_FAILURE = 'SITE_RULE_DELETE_FAILURE';

export const SITE_RULE_ACTION_CREATE_SUCCESS = 'SITE_RULE_ACTION_CREATE_SUCCESS';
export const SITE_RULE_ACTION_CREATE_FAILURE = 'SITE_RULE_ACTION_CREATE_FAILURE';

export const SITE_RULE_ACTION_UPDATE_SUCCESS = 'SITE_RULE_ACTION_UPDATE_SUCCESS';
export const SITE_RULE_ACTION_UPDATE_FAILURE = 'SITE_RULE_ACTION_UPDATE_FAILURE';

export const SITE_RULE_ACTION_DELETE_SUCCESS = 'SITE_RULE_ACTION_DELETE_SUCCESS';
export const SITE_RULE_ACTION_DELETE_FAILURE = 'SITE_RULE_ACTION_DELETE_FAILURE';

export const SITE_RULE_CONDITION_CREATE_SUCCESS = 'SITE_RULE_CONDITION_CREATE_SUCCESS';
export const SITE_RULE_CONDITION_CREATE_FAILURE = 'SITE_RULE_CONDITION_CREATE_FAILURE';

export const SITE_RULE_CONDITION_UPDATE_SUCCESS = 'SITE_RULE_CONDITION_UPDATE_SUCCESS';
export const SITE_RULE_CONDITION_UPDATE_FAILURE = 'SITE_RULE_CONDITION_UPDATE_FAILURE';

export const SITE_RULE_CONDITION_DELETE_SUCCESS = 'SITE_RULE_CONDITION_DELETE_SUCCESS';
export const SITE_RULE_CONDITION_DELETE_FAILURE = 'SITE_RULE_CONDITION_DELETE_FAILURE';

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_RULE_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: SITE_RULE_SELECT, payload: item });
};

export const selectItemCondition = (condition) => async (dispatch) => {
  dispatch({ type: SITE_RULE_CONDITION_SELECTED, payload: condition });
};

export const selectItemAction = (action) => async (dispatch) => {
  dispatch({ type: SITE_RULE_ACTION_SELECTED, payload: action });
};

export const fetch = (page, size, parameters) => async (dispatch) => {
  let requestUrl = `/api/private/portal/site/rules?page=${page || 0}&size=${size || 10}`;

  if (parameters) {
    Object.keys(parameters).forEach((key) => {
      requestUrl += `&${key}=${parameters[key]}`;
    });
  }

  dispatch({ type: SITE_RULE_FETCH_IN_PROGRESS });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({
      type: SITE_RULE_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
      },
    });
  } catch (error) {
    dispatch({ type: SITE_RULE_FETCH_FAILURE, payload: error });
  }
};

export const addRule = (request) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/rules', { body: request });
    dispatch({ type: SITE_RULE_ADD_SUCCESS, payload: response });
    Swal.fire('Rule Created', 'Your rule has been created successfully. Add behaviour by adding conditions and actions.', 'success');
  } catch (error) {
    dispatch({ type: SITE_RULE_ADD_FAILURE, payload: error });
  }
};

export const duplicateRule = (rule) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', `/api/private/portal/site/rules/duplicate/${rule.id}`, {});
    dispatch({ type: SITE_RULE_ADD_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_ADD_FAILURE, payload: error });
  }
};

export const update = (request) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/site/rules', { body: request });
    dispatch({ type: SITE_RULE_UPDATE_SUCCESS, payload: response });
    Swal.fire('Rule Updated', 'Your rule has been updated successfully', 'success');
  } catch (error) {
    dispatch({ type: SITE_RULE_UPDATE_FAILURE, payload: error });
  }
};

export const toggleEnabled = (request) => async (dispatch) => {
  const body = {
    ...request,
    enabled: !request.enabled,
  };
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/site/rules', { body });
    dispatch({ type: SITE_RULE_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_UPDATE_FAILURE, payload: error });
  }
};

export const remove = (rule) => async (dispatch) => {
  Swal.fire({
    title: `Delete Rule?`,
    text: 'Warning: This operation is not reversible',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', '/api/private/portal/site/rules/' + rule.id, {});
        dispatch({ type: SITE_RULE_DELETE_SUCCESS, payload: rule });
      } catch (error) {
        dispatch({ type: SITE_RULE_DELETE_FAILURE, payload: error });
      }
    }
  });
};

export const addAction = (action) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/rules/action', { body: action });
    dispatch({ type: SITE_RULE_ACTION_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_ACTION_CREATE_FAILURE, payload: error });
  }
};

export const updateAction = (action) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', `/api/private/portal/site/rules/action/${action.id}`, { body: action });
    dispatch({ type: SITE_RULE_ACTION_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_ACTION_UPDATE_FAILURE, payload: error });
  }
};

export const removeAction = (action) => async (dispatch) => {
  try {
    await API.del('PrivateAPI', '/api/private/portal/site/rules/action/' + action.id, {});
    dispatch({ type: SITE_RULE_ACTION_DELETE_SUCCESS, payload: action });
  } catch (error) {
    dispatch({ type: SITE_RULE_ACTION_DELETE_FAILURE, payload: error });
  }
};

export const addCondition = (condition) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/rules/condition', { body: condition });
    dispatch({ type: SITE_RULE_CONDITION_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_CONDITION_CREATE_FAILURE, payload: error });
  }
};

export const updateCondition = (payload) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', `/api/private/portal/site/rules/condition/${payload.id}`, { body: payload });
    dispatch({ type: SITE_RULE_CONDITION_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_RULE_CONDITION_UPDATE_FAILURE, payload: error });
  }
};

export const removeCondition = (condition) => async (dispatch) => {
  try {
    await API.del('PrivateAPI', '/api/private/portal/site/rules/condition/' + condition.id, {});
    dispatch({ type: SITE_RULE_CONDITION_DELETE_SUCCESS, payload: condition });
  } catch (error) {
    dispatch({ type: SITE_RULE_CONDITION_DELETE_FAILURE, payload: error });
  }
};
