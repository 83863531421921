import * as DataTypes from '../../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';
import Actions, { PURCHASE_EXTERNAL_IN_PROGRESS } from '../../../../../redux/actions/purchase/actions';
import AutoTable from '../../../../../../components/table/AutoTable';
import Currency from '../../../../../../support/Currency';
import PurchaseState from '../../../../../../support/PurchaseState';
import PurchaseProcessState from '../../../../../../support/PurchaseProcessState';
import Swal from 'sweetalert2';
import PaymentProcessor from '../../../../../../components/paymentProcessing/PaymentProcessor';

const columns = [
  {
    id: 'reference',
    label: 'Purchase Ref',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'merchantReference',
    label: 'Merchant Reference ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total (incl. Surcharge)',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Currency.transform(values.total).format();
    },
  },
  {
    id: 'surcharge',
    label: 'Surcharge',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Currency.transform(values.surcharge).format();
    },
  },
  {
    id: 'cardHolder',
    label: 'Card Holder',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardNumber',
    label: 'Card Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardType',
    label: 'Card Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardExpiry',
    label: 'Card Expiry ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardToken',
    label: 'Card Token',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'authCode',
    label: 'Auth Code ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'type',
    label: 'Purchase Type ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'state',
    label: 'State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'date',
    label: 'Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ReservationPurchaseList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const sites = useSelector((state) => state.siteReducer.entityList);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const page = useSelector((state) => state.purchaseReducer.page);
  const error = useSelector((state) => state.purchaseReducer.error);
  const processing = useSelector((state) => state.purchaseReducer.processing);
  const lastActionType = useSelector((state) => state.purchaseReducer.lastActionType);
  const response = useSelector((state) => state.purchaseReducer.response);
  const requests = useSelector((state) => state.purchaseReducer.entityList);
  const selectedSiteId = selectedSite?.id;
  const [amount, setAmount] = React.useState(0);
  const [type, setType] = React.useState('CHARGE');
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);
  const [postToPms, setPostToPms] = React.useState(true);

  const surcharge = useMemo(() => {
    if (sites.length === 0) {
      return 0.0;
    }

    let filter = sites.filter((s) => s.id === selectedSite?.id);
    const selectedSiteDetails = filter.length > 0 ? filter[0] : null;

    if (
      selectedSiteDetails?.surcharge &&
      selectedSiteDetails?.surchargePercent &&
      selectedTransaction?.type === 'PAYMENT' &&
      'REFUND' !== type
    ) {
      return parseFloat((amount * selectedSiteDetails.surchargePercent) / 100).toFixed(3);
    } else {
      return 0.0;
    }
  }, [sites, selectedSite, selectedTransaction, type, amount]);

  useEffect(() => {
    if ('PURCHASE_EXTERNAL_SUCCESS' === lastActionType) {
      let completed = 'COMPLETED' === response.state;
      Swal.fire({
        title: completed ? `Processed ${type}` : `Unable to process ${type}`,
        text: completed
          ? `${type} for $${amount} with $${surcharge} surcharge processed for this reservation`
          : `${type} for $${amount} with $${surcharge} surcharge NOT processed for this reservation`,
        icon: completed ? 'success' : 'error',
        confirmButtonText: 'Done',
        showDenyButton: false,
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          dispatch(Actions.clearError());
          window.location.reload();
        }
      });
    }
    if ('PURCHASE_EXTERNAL_FAILURE' === lastActionType) {
      Swal.fire({
        title: `Unable to Process ${type}`,
        text: `Unable to process ${type} for $${amount} with ${surcharge} surcharge. Error: ${error}`,
        icon: 'error',
        confirmButtonText: 'Done',
        showDenyButton: false,
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close();
          dispatch(Actions.clearError());
          window.location.reload();
        }
      });
    }
  }, [response, amount, dispatch, error, lastActionType, surcharge, type]);

  useEffect(() => {
    if (selectedTransaction) {
      if (
        (selectedTransaction.type === 'PAYMENT' || selectedTransaction.type === 'COMPLETION' || selectedTransaction.type === 'TOKEN') &&
        PurchaseProcessState.REFUND === type
      ) {
        const transAmount = selectedTransaction.total.amount;
        setMaxAmount(transAmount);
        if (amount > transAmount) {
          setAmount(transAmount);
        }
      } else {
        setMaxAmount(5000); // set arbitrary amount
      }
    }
  }, [amount, selectedTransaction, type]);

  const isProcessingAllowed = useMemo(() => {
    const isCardTokenOrAuthTokenExist = requests?.some((p) => Boolean(p.cardToken) || Boolean(p.authCode));
    const filteredRequests = requests.filter((r) => PurchaseState.COMPLETED === r.state);

    if (filteredRequests.length > 0) {
      return !!isCardTokenOrAuthTokenExist;
    }
    return false;
  }, [requests]);

  const handleChange = (event) => {
    const value = Math.max(0, Math.min(maxAmount, Number(event.target.value)));
    setAmount(value);
  };

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  const requestPurchaseItems = useCallback(
    (newPage, pageSize) => {
      if (selectedSiteId && selectedReservation) {
        const params = {
          siteId: selectedSiteId,
          reservationNumber: selectedReservation.reservationNumber,
          sort: 'created,desc',
        };
        dispatch(Actions.fetch(newPage, pageSize, params));
      }
    },
    [selectedSiteId, selectedReservation, dispatch]
  );

  const handleSubmit = useCallback(() => {
    const payload = {
      selectedTransaction: selectedTransaction,
      amount: amount,
      surcharge: surcharge,
      type: type,
      postToPms: postToPms,
      reservation: selectedReservation,
    };
    Swal.fire({
      title: `Process ${type}`,
      text: `${type} for $${amount} with $${surcharge} surcharge will be processed for this reservation`,
      icon: 'success',
      confirmButtonText: 'Process',
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        if (type === 'REFUND') {
          dispatch(Actions.processRefund(payload));
        } else {
          dispatch(Actions.processExternalPayment(payload));
        }
      }
      if (result.isDismissed || result.isDenied) {
        Swal.close();
        dispatch(Actions.clearError());
      }
    });
  }, [amount, dispatch, postToPms, selectedReservation, selectedTransaction, surcharge, type]);

  return (
    <React.Fragment>
      <AutoTable
        title="Purchases "
        subheader={'Purchases for the Guest'}
        handleRefreshReport={() => requestPurchaseItems(0, 25)}
        handleChangePage={(ev, newPage, pageSize) => requestPurchaseItems(newPage, pageSize)}
        processing={processing}
        page={page}
        selectItem={(item) => dispatch(Actions.select(item))}
        error={error}
        clearError={() => dispatch(Actions.clearError())}
        columns={columns}
        prefix="PURCHASE"
        buttonDisabled={true}
        detailURL="/reservation/purchase/view/{id}"
        data={requests}
        lastActionType={lastActionType}
        backHidden
      />
      {isProcessingAllowed && (
        <PaymentProcessor
          type={type}
          surcharge={surcharge}
          amount={amount}
          selectedTransaction={selectedTransaction}
          setSelectedTransaction={setSelectedTransaction}
          setType={setType}
          handleChange={handleChange}
          postToPms={postToPms}
          setPostToPms={setPostToPms}
          transactions={requests}
          handleSubmit={handleSubmit}
          maxAmount={maxAmount}
          isLoading={PURCHASE_EXTERNAL_IN_PROGRESS === lastActionType}
        />
      )}
    </React.Fragment>
  );
}
