import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SITE_PACKAGE_FETCH_SUCCESS = 'SITE_PACKAGE_FETCH_SUCCESS';
export const SITE_PACKAGE_FETCH_FAILURE = 'SITE_PACKAGE_FETCH_FAILURE';

export const SITE_PACKAGE_CREATE_SUCCESS = 'SITE_PACKAGE_CREATE_SUCCESS';
export const SITE_PACKAGE_CREATE_FAILURE = 'SITE_PACKAGE_CREATE_FAILURE';

export const SITE_PACKAGE_UPDATE_SUCCESS = 'SITE_PACKAGE_UPDATE_SUCCESS';
export const SITE_PACKAGE_UPDATE_FAILURE = 'SITE_PACKAGE_UPDATE_FAILURE';
export const SITE_PACKAGE_DUPLICATE_SUCCESS = 'SITE_PACKAGE_DUPLICATE_SUCCESS';
export const SITE_PACKAGE_DUPLICATE_FAILURE = 'SITE_PACKAGE_DUPLICATE_FAILURE';

export const SITE_PACKAGE_DELETE_SUCCESS = 'SITE_PACKAGE_DELETE_SUCCESS';
export const SITE_PACKAGE_DELETE_FAILURE = 'SITE_PACKAGE_DELETE_FAILURE';

export const SITE_PACKAGE_PROCESSING = 'SITE_PACKAGE_PROCESSING';
export const SITE_PACKAGE_CLEAR_ERROR = 'SITE_PACKAGE_CLEAR_ERROR';
export const SITE_PACKAGE_SELECTED_ITEM = 'SITE_PACKAGE_SELECTED_ITEM';

export const fetch = (page, size, parameters) => async (dispatch) => {
  let requestUrl = `/api/private/portal/site/packages?page=${page || 0}&size=${size || 10}`;

  if (parameters) {
    Object.keys(parameters).forEach((key) => {
      requestUrl += `&${key}=${parameters[key]}`;
    });
  }

  dispatch({ type: SITE_PACKAGE_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({ type: SITE_PACKAGE_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PACKAGE_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const createSitePackage = (request) => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_PROCESSING });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/packages', { body: request });
    dispatch({ type: SITE_PACKAGE_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PACKAGE_CREATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const updateSitePackage = (request) => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/site/packages', { body: request });
    dispatch({ type: SITE_PACKAGE_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PACKAGE_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const duplicateSitePackage = (id) => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/site/packages/duplicate/${id}`, {});
    dispatch({ type: SITE_PACKAGE_DUPLICATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PACKAGE_DUPLICATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteSitePackage = (siteId, id) => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_PROCESSING });
  try {
    const response = await API.del('PrivateAPI', `/api/private/portal/site/packages/${id}`, {});
    dispatch({ type: SITE_PACKAGE_DELETE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PACKAGE_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: SITE_PACKAGE_SELECTED_ITEM, payload: item });
};
