import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Vendor', 'FOOD_VENDOR');

function foodVendorReducer(state = initial, action) {
  switch (action.type) {
    case `FOOD_VENDOR_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        vendorOptions: action.payload,
      };
    }
    case `FOOD_VENDOR_FETCH_CODES_SUCCESS`: {
      return {
        ...state,
        vendorCodes: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default foodVendorReducer;
