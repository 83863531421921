import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HomeOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  stdPadding: {
    padding: '15px 15px',
    textAlign: 'center',
  },
  textError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  centerContent: {
    margin: 'auto',
    marginTop: '25vh',
    marginBottom: '25vh',
  },
  iconContainer: {
    margin: 'auto',
    minWidth: '100px',
    textAlign: 'center',
  },
  iconStyle: {
    color: theme.palette.error.main,
    fontSize: '4rem!important',
  },
}));

function InvalidURL() {
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.centerContent}>
        <div className={classes.stdPadding}>
          <Typography variant="h3" className={classes.textError}>
            404 ERROR
          </Typography>
        </div>
        <div className={classes.stdPadding}>
          <Typography variant="body1">
            It looks like this page does not exist... Click the button below if you would like to go back to the home page.
          </Typography>
        </div>
        <div className={classes.stdPadding}>
          <Button component={Link} to="/sccc/home" variant="contained" href="#" color="accent" size="large" startIcon={<HomeOutlined />}>
            GO HOME
          </Button>
        </div>
      </div>
    </Paper>
  );
}

export default withRouter(InvalidURL);
