import { doesSiteHaveFeature } from '../../../../redux/actions/access/actions';
import SiteFeature from '../../../../../support/SiteFeature';
import { Grid } from '@material-ui/core';
import SiteDashboardWidget from '../detail/widget/SiteDashboardWidget';
import DashboardSection from '../../../../../support/DashboardSection';
import LivertonAreaChart from '../detail/chart/LivertonAreaChart';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../../control/TabPanel';
import { useSelector } from 'react-redux';
import API from '@aws-amplify/api';
import Charts from '../detail/Charts';
import Objects from '../../../../../support/Objects';
import ChartCardPair from '../detail/chart/ChartCardPair';
import moment from 'moment';

export default function MobileKeyTab({ selectedTab, tabIndex, timeframe, selectedSite }) {
  const widgetData = useSelector((state) => state.dashboardReducer.widgetData);
  const [mobileKeysIssuedData, setMobileKeysIssuedData] = React.useState([]);
  const [tableViewMap, setTableViewMap] = useState({
    keys: false,
  });

  useEffect(() => {
    const siteId = selectedSite?.id;
    if (!siteId || !timeframe || selectedTab !== tabIndex) {
      return;
    }

    const payload = {
      ...timeframe,
      start: moment(timeframe.start).format('YYYY-MM-DD'),
      end: moment(timeframe.end).format('YYYY-MM-DD'),
    };

    if (doesSiteHaveFeature(selectedSite, SiteFeature.DIGITAL_ROOM_KEY)) {
      API.post('PrivateAPI', `/api/private/portal/dashboard/chart/keys/timeline?siteId=${siteId}`, { body: payload })
        .then((result) => {
          setMobileKeysIssuedData(Charts.prepareChartData(result));
        })
        .catch((error) => {
          console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, timeframe, selectedTab]);

  return (
    <TabPanel value={selectedTab} index={tabIndex} p={3}>
      {doesSiteHaveFeature(selectedSite, SiteFeature.DIGITAL_ROOM_KEY) && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SiteDashboardWidget widgetData={widgetData} site={selectedSite} section={DashboardSection.ROOM_KEY} />
          </Grid>

          <ChartCardPair
            charts={[
              {
                title: 'Mobile Keys Issued',
                subheader: 'Number of digital room keys issued',
                tableView: tableViewMap.keys,
                setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, keys: toggleValue }),
                component: (tableView) => (
                  <LivertonAreaChart data={mobileKeysIssuedData} tableView={tableView} columnOverrides={TABLE_LABELS} />
                ),
              },
            ]}
          />
        </Grid>
      )}
    </TabPanel>
  );
}

const TABLE_LABELS = {
  x: 'Date',
};
