import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../redux/actions/appUsers/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import AutoTable from '../../../../components/table/AutoTable';
import { getFilterField, getFilterUpdateMethods } from '../../../../components/table/filter/filterUtils';
import Objects from '../../../../support/Objects';

export default function AppUserList() {
  const dispatch = useDispatch();
  const lastActionType = useSelector((state) => state.appUsersReducer.lastActionType);
  const data = useSelector((state) => state.appUsersReducer.data);
  const page = useSelector((state) => state.appUsersReducer.page);
  const processing = useSelector((state) => state.appUsersReducer.processing);
  const error = useSelector((state) => state.appUsersReducer.error);
  const [state, setState] = useState({
    phone: '',
    email: '',
    appId: '',
  });

  const filters = getFilterFieldsForScreen(state, setState);

  useEffect(() => {
    dispatch(Actions.findByFilter(0, 25, state.phone, state.email, state.appId));
  }, [dispatch, state]);

  useEffect(() => {
    dispatch(Actions.selectItem(null));
  }, [dispatch]);

  return (
    <AutoTable
      title="App User List"
      subheader={'Access information about users of the app here.'}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetchAppUsers(newPage, pageSize))}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      clearError={() => dispatch(Actions.clearError())}
      prefix="APP USER"
      newButtonName="New User"
      createNewURL="/account/app-users/new"
      detailURL="/account/app-users/view/{id}"
      idColumn={'id'}
      error={error}
      columns={columns}
      processing={processing}
      lastActionType={lastActionType}
      data={data}
      filters={filters}
    />
  );
}

// Table column rendering information.
const columns = [
  {
    id: 'appCode',
    label: 'App ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'username',
    label: 'Username',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phone',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'accountProvider',
    label: 'Account Provider',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return Objects.capitalize(value?.accountProvider);
    },
  },
  {
    id: 'over18',
    label: 'Over 18',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('phone', 'Phone Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('email', 'Email Address', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('appId', 'App ID', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
}
