import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Order Location', 'FOOD_ORDER_LOCATION');

function foodOrderLocationReducer(state = initial, action) {
  switch (action.type) {
    case `FOOD_ORDER_LOCATION_EXPORT_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    }
    case `FOOD_ORDER_LOCATION_EXPORT_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
      };
    }
    case `FOOD_ORDER_LOCATION_EXPORT_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default foodOrderLocationReducer;
