const ReservationFetchType = {
  PRE_ARRIVAL_RESERVATIONS_ONLY: 'PRE_ARRIVAL_RESERVATIONS_ONLY',
  ALL_RESERVATIONS: 'ALL_RESERVATIONS',
};

const ReservationFetchTypeOptions = [
  { label: 'Pre-Arrival Reservations Only', value: ReservationFetchType.PRE_ARRIVAL_RESERVATIONS_ONLY },
  { label: 'All Reservations', value: ReservationFetchType.ALL_RESERVATIONS },
];

export default ReservationFetchType;
export { ReservationFetchTypeOptions };
