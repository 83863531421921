import { Box, Chip } from '@material-ui/core';
import React from 'react';

export default function ChartLegends({ data = [] }) {
  return (
    <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
      {data.map((item, index) => {
        return (
          <Box p={0.5} key={`${item.key}-${index}`}>
            <Chip size={'small'} label={item.key} style={{ backgroundColor: item.colour, color: 'white' }} />
          </Box>
        );
      })}
    </Box>
  );
}
