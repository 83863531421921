import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

export const FOOD_POS_PAYMENT_TYPE_FETCH_SUCCESS = 'FOOD_POS_PAYMENT_TYPE_FETCH_SUCCESS';
export const FOOD_POS_PAYMENT_TYPE_FETCH_FAILURE = 'FOOD_POS_PAYMENT_TYPE_FETCH_FAILURE';

export const FOOD_POS_PAYMENT_TYPE_CREATE_SUCCESS = 'FOOD_POS_PAYMENT_TYPE_CREATE_SUCCESS';
export const FOOD_POS_PAYMENT_TYPE_CREATE_FAILURE = 'FOOD_POS_PAYMENT_TYPE_CREATE_FAILURE';

export const FOOD_POS_PAYMENT_TYPE_UPDATE_SUCCESS = 'FOOD_POS_PAYMENT_TYPE_UPDATE_SUCCESS';
export const FOOD_POS_PAYMENT_TYPE_UPDATE_FAILURE = 'FOOD_POS_PAYMENT_TYPE_UPDATE_FAILURE';

export const FOOD_POS_PAYMENT_TYPE_DELETE_SUCCESS = 'FOOD_POS_PAYMENT_TYPE_DELETE_SUCCESS';
export const FOOD_POS_PAYMENT_TYPE_DELETE_FAILURE = 'FOOD_POS_PAYMENT_TYPE_DELETE_FAILURE';

export const FOOD_POS_PAYMENT_TYPE_CLEAR_ERROR = 'FOOD_POS_PAYMENT_TYPE_CLEAR_ERROR';

export const clearError = () => async (dispatch) => {
  dispatch({ type: FOOD_POS_PAYMENT_TYPE_CLEAR_ERROR, payload: {} });
};

export const fetchAll = (siteId) => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/site/${siteId}/food/pos/payment-types`, {});
    dispatch({
      type: FOOD_POS_PAYMENT_TYPE_FETCH_SUCCESS,
      payload: {
        defaultPaymentTypes: response.filter((p) => !p.overridden),
        foodPosPaymentTypes: response.filter((p) => p.overridden),
      },
    });
  } catch (error) {
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const createPaymentType = (siteId, request) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', `/api/private/portal/site/${siteId}/food/pos/payment-types`, { body: request });
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_CREATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const updatePaymentType = (id, request) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', `/api/private/portal/food/pos/payment-types/${id}`, { body: request });
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deletePaymentType = (id) => async (dispatch) => {
  try {
    await API.del('PrivateAPI', `/api/private/portal/food/pos/payment-types/${id}`, {});
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_DELETE_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: FOOD_POS_PAYMENT_TYPE_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};
