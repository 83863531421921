import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { ChronoUnitSelectOptions } from '../../support/ChronoUnit';

export default function DurationUnitSelect({ label, value, setValue }) {
  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  return (
    <FormControl margin={'dense'} variant={'outlined'} fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select value={value} native label={label} labelId={id} onChange={(event) => setValue(event.target.value)}>
        {ChronoUnitSelectOptions.map((entry) => (
          <option value={entry.value} key={`dlu-${entry.label}`}>
            {entry.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
