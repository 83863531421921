import * as Actions from '../../actions/siteFeatures/actions';

const initial = {
  data: [],
  error: null,
};

export default function siteFeaturesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_FEATURES_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case Actions.SITE_FEATURES_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.SITE_FEATURES_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.SITE_FEATURES_TOGGLE_SUCCESS:
      const nextData = action.payload.enable
        ? [...state.data, action.payload]
        : [...state.data].filter((f) => f.feature.id !== action.payload.feature.id);
      return {
        ...state,
        data: nextData,
        error: null,
      };
    case Actions.SITE_FEATURES_TOGGLE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
