import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/facialRecognition/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

const DETECTION_INPUT_SIZE = { 128: 128, 160: 160, 224: 224, 320: 320, 416: 416, 512: 512, 608: 608 };

export default function FacialRecognitionEdit() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const error = useSelector((state) => state.facialRecognitionReducer.error);
  const selectedItem = useSelector((state) => state.facialRecognitionReducer.selectedItem);

  const [matchPercentage, setMatchPercentage] = React.useState(selectedItem?.matchPercentage || 90);
  const [detectionInputSize, setDetectionInputSize] = React.useState(selectedItem?.detectionInputSize || 512);
  const [detectionScoreThreshold, setDetectionScoreThreshold] = React.useState(selectedItem?.detectionScoreThreshold || null);
  const [drawBoundingBox, setDrawBoundingBox] = React.useState(selectedItem?.drawBoundingBox || true);
  const [detectionTimeout, setDetectionTimeout] = React.useState(selectedItem?.detectionTimeout || 60000);
  const [boxHeight, setBoxHeight] = React.useState(selectedItem?.boxHeight || 25);
  const [boxWidth, setBoxWidth] = React.useState(selectedItem?.boxWidth || 25);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    dispatch(Actions.selectById(selectedSite.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      matchPercentage: matchPercentage,
      detectionInputSize: detectionInputSize,
      detectionScoreThreshold: detectionScoreThreshold,
      drawBoundingBox: drawBoundingBox,
      detectionTimeout: detectionTimeout,
      boxHeight: boxHeight,
      boxWidth: boxWidth,
      siteId: selectedSite?.id,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, null, false));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady = matchPercentage && detectionScoreThreshold && detectionTimeout;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Facial Recognition' : 'Add Facial Recognition'}
        subheader={'Facial Recognition Feature for Guest Detection'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/delete Facial Recognition configuration - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Match Percentage'}
              type={'number'}
              step={1.0}
              value={matchPercentage}
              setValue={setMatchPercentage}
              placeholder="E.g 80,90"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Detection Input Size'}
              value={detectionInputSize}
              setValue={setDetectionInputSize}
              options={Object.keys(DETECTION_INPUT_SIZE).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Detection Threshold'}
              type={'number'}
              step={0.1}
              value={detectionScoreThreshold}
              setValue={setDetectionScoreThreshold}
              placeholder="E.g 0.1-1.0"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Detection Timeout'}
              type={'number'}
              value={detectionTimeout}
              setValue={setDetectionTimeout}
              placeholder="E.g 5000"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect value={drawBoundingBox} setValue={setDrawBoundingBox} label={'Draw Bounding Box'} />
          </Grid>
          {drawBoundingBox && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Bounding Box Width'} type={'number'} value={boxWidth} setValue={setBoxWidth} placeholder="E.g 25" />
            </Grid>
          )}
          {drawBoundingBox && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Bounding Box Height'} type={'number'} value={boxHeight} setValue={setBoxHeight} placeholder="E.g 25" />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
