const RoomStatusTypes = [
  'CLEAN',
  'DIRTY',
  'OUT_OF_SERVICE',
  'TOUCHED',
  'CLEANING_IN_PROGRESS',
  'CHECKED'
];

export default RoomStatusTypes;
