import * as Actions from '../../actions/user/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  processing: false,
  error: null,
  selectedItem: null,
};

export default function userReducer(state = initial, action) {
  switch (action.type) {
    case Actions.USER_ACTION_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case Actions.USER_GET_USERS_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
        processing: false,
      };
    case Actions.USER_GET_USERS_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        data: [],
        error: action.payload,
        processing: false,
      };
    case Actions.USER_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: [...state.data, action.payload],
        processing: false,
      };
    case Actions.USER_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.USER_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((user) => user.id !== action.payload.id),
        processing: false,
      };
    case Actions.USER_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.USER_EDIT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.map((user) => (user.id === action.payload.id ? action.payload : user)),
        selectedItem: action.payload,
        error: null,
        processing: false,
      };
    case Actions.USER_EDIT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.USER_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.USER_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.USER_RESET_PASSWORD_SUCCESS:
    case Actions.USER_SEND_VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: false,
      };
    case Actions.USER_RESET_PASSWORD_FAILURE:
    case Actions.USER_SEND_VALIDATE_CODE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
