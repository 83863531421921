import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as Actions from '../../../../../redux/actions/compendium/article/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Collapse, Grid, IconButton, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import moment from 'moment';
import AppConfig from '../../../../../../AppConfig';
import CompendiumArticleType from '../../../../../../support/CompendiumArticleType';
import FormSelect from '../../../../../../components/form/FormSelect';
import AutoTable from '../../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../../constants/DataTypes';
import { RemoveCircleOutline } from '@material-ui/icons';
import CompendiumContentEditor from '../shared/CompendiumContentEditor';
import CompendiumContentSwitcher from '../shared/CompendiumContentSwitcher';

const columns = [
  {
    id: 'disable',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.deleteImage(value))}>
            <RemoveCircleOutline />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'imageName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'description',
    label: 'Desc',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function ArticleEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const lastActionType = useSelector((state) => state.compendiumArticleReducer.lastActionType);
  const error = useSelector((state) => state.compendiumArticleReducer.error);
  const selectedItem = useSelector((state) => state.compendiumCategoryReducer.selectedItem);
  const selectedArticle = useSelector((state) => state.compendiumArticleReducer.selectedItem);
  const processing = useSelector((state) => state.compendiumArticleReducer.processing);
  const page = useSelector((state) => state.compendiumArticleReducer.imagePage);

  const [orderNo, setOrderNo] = React.useState(selectedArticle?.orderNo || '');
  const [title, setTitle] = React.useState(selectedArticle?.title || '');
  const [body, setBody] = React.useState(selectedArticle?.body || '');
  const [url, setUrl] = React.useState(selectedArticle?.url || '');
  const [type, setType] = React.useState(selectedArticle?.type || CompendiumArticleType.STANDARD);
  const [visualEditor, setVisualEditor] = React.useState(true);
  const imageList = useSelector((state) =>
    selectedArticle ? state.compendiumArticleReducer.imageData.filter((i) => i.articleId === selectedArticle.id) : [],
  );
  const [showImage, setShowImage] = React.useState(selectedArticle ? selectedArticle?.showImage : true);
  const [showImageName, setShowImageName] = React.useState(selectedArticle ? selectedArticle?.showImageName : true);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.ARTICLE_ADD_SUCCESS ||
      lastActionType === Actions.ARTICLE_UPDATE_SUCCESS ||
      lastActionType === Actions.ARTICLE_DELETE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedArticle,
      orderNo,
      title,
      body,
      url,
      type,
      showImage,
      showImageName,
      imageList: type === CompendiumArticleType.STANDARD ? imageList : [],
      categoryId: selectedItem.id,
    };

    if (selectedArticle) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.create(payload));
    }
  };

  useEffect(() => {
    if (selectedItem && selectedArticle) {
      dispatch(Actions.fetchImages(0, 10, selectedArticle.id));
    }
  }, [dispatch, selectedItem, selectedArticle]);

  const handleRefreshReport = (selectedSite, selectedItem) => {
    if (selectedSite && selectedItem) {
      dispatch(Actions.fetchImages(0, 10, selectedArticle.id));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetchImages(newPage, pageSize, selectedArticle.id));
  };

  const isFormReady = orderNo && title && type;

  return (
    <Paper>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={'Compendium Article'}
            subheader={''}
            buttonName={selectedArticle ? 'Update' : 'Add'}
            onClick={handleSubmit}
            disabled={!isFormReady}
          />
          <CardContent>
            {error && <Alert severity="error">{`Unable to update Article - ${error.message}`}</Alert>}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Article Title'} value={title} setValue={setTitle} placeholder="Title" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'State'}
                  value={type}
                  setValue={setType}
                  options={Object.keys(CompendiumArticleType).map((key) => ({
                    label: key,
                    value: key,
                  }))}
                />
              </Grid>

              {CompendiumArticleType.LINK === type && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormField label={'URL'} value={url} setValue={setUrl} placeholder="URL to load" />
                </Grid>
              )}

              {CompendiumArticleType.STANDARD === type && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6} alignContent={'center'}>
                    <CompendiumContentSwitcher visual={visualEditor} setVisual={setVisualEditor} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CompendiumContentEditor value={body} setValue={setBody} visual={visualEditor} />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Order'} value={orderNo} setValue={setOrderNo} placeholder="Order" />
              </Grid>

              {selectedArticle && (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Updated'}
                      value={moment.unix(selectedArticle?.updated).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      label={'Created'}
                      value={moment.unix(selectedArticle?.created).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                      disabled
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Show Image'}
                  value={showImage}
                  setValue={setShowImage}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Show Image Name'}
                  value={showImageName}
                  setValue={setShowImageName}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Collapse in={selectedArticle?.id}>
            <AutoTable
              title="Digital Article Images"
              subheader={''}
              handleRefreshReport={() => handleRefreshReport(0, 20, selectedArticle?.id)}
              handleChangePage={(ev, newPage, pageSize) => handleChangePage(newPage, pageSize, selectedArticle?.id)}
              page={page}
              processing={processing}
              selectItem={(item) => dispatch(Actions.selectImageItem(item))}
              clearError={() => dispatch(Actions.clearImageError())}
              columns={columns}
              prefix="ARTICLE_IMAGE"
              buttonName="New Image"
              createNewURL="/site/compendium/article/image/new"
              detailURL="/site/compendium/article/image/view/{id}"
              idColumn={'id'}
              data={imageList}
              lastActionType={lastActionType}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}
