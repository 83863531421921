import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/corporation/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'enable',
    label: 'Enabled',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function CorporationList() {
  const dispatch = useDispatch();
  const corporationList = useSelector((state) => state.corporationReducer.entityList);
  const lastActionType = useSelector((state) => state.corporationReducer.lastActionType);
  const processing = useSelector((state) => state.corporationReducer.processing);
  const page = useSelector((state) => state.corporationReducer.page);
  const error = useSelector((state) => state.corporationReducer.error);

  return (
    <AutoTable
      title="Corporation List"
      subheader={'A corporation is a company with one or more properties'}
      handleRefreshReport={() => dispatch(Actions.fetch())}
      selectItem={(item) => dispatch(Actions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, null))}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="CORPORATION"
      newButtonName="New Corporation"
      createNewURL="/corporations/new"
      detailURL="/corporations/view/{id}"
      idColumn={'id'}
      data={corporationList}
      lastActionType={lastActionType}
    />
  );
}
