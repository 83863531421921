import React, { useEffect } from 'react';
import * as Actions from '../../../../redux/actions/reservationField/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CardContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeleteOutlined } from '@material-ui/icons';
import BooleanTableColumn from '../../../../../components/table/BooleanTableColumn';
import Card from '@material-ui/core/Card';
import ReservationFieldDialog from './ReservationFieldDialog';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import ReservationFieldTypes from '../../../../../support/ReservationFieldTypes';

export default function ReservationFieldList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const defaultFields = useSelector((state) => state.reservationFieldReducer.defaultFields) || [];
  const siteFields = useSelector((state) => state.reservationFieldReducer.siteFields) || [];
  const error = useSelector((state) => state.reservationFieldReducer.error);
  const selectedSiteId = selectedSite?.id;
  const [selectedField, setSelectedField] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);

  useEffect(() => {
    dispatch(Actions.clearError());
    if (selectedSiteId) {
      dispatch(Actions.fetchAll(selectedSiteId));
    }
  }, [selectedSiteId, dispatch]);

  const merged = defaultFields
    .map((pt) => {
      const siteOverride = siteFields.find((spt) => spt.fieldCode === pt.fieldCode);
      if (siteOverride) {
        return siteOverride;
      } else {
        return pt;
      }
    })
    .concat(siteFields.filter((field) => field.isUdf))
    .sort((a, b) => {
      return a.order < b.order ? -1 : 1;
    });

  function openModal(field, isNew = false) {
    setSelectedField(field);
    setModalOpen(true);
    setIsNew(isNew);
    dispatch(Actions.clearError());
  }

  function closeModalAndClear() {
    setSelectedField(null);
    setModalOpen(false);
    setIsNew(false);
  }

  return (
    <Card>
      {(selectedField || isNew) && (
        <ReservationFieldDialog
          open={modalOpen}
          setClose={closeModalAndClear}
          reservationField={selectedField}
          siteId={selectedSiteId}
          isNew={isNew}
        />
      )}
      <CardHeaderWithControls
        header={'Site Reservation Fields'}
        subheader={'Per site configuration of fields visible/required for reservations'}
        buttonName={'Add New'}
        onClick={() => openModal(null, true)}
      />

      <Divider variant={'middle'} />

      <CardContent>
        {error && (
          <Alert onClose={() => dispatch(Actions.clearError())} severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/delete reservation field: ${error.message}`}
          </Alert>
        )}
        {merged.length <= 0 && <Alert color={'info'}>No fields available - add to get started</Alert>}

        {merged.length > 0 && (
          <TableContainer>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>Visible</TableCell>
                  <TableCell>Required</TableCell>
                  <TableCell>User Defined</TableCell>
                  <TableCell align={'center'}>Overridden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merged.map((field) => (
                  <TableRow key={`reservation-field-${field.id}`}>
                    <TableCell>
                      <IconButton
                        onClick={() => dispatch(Actions.deleteReservationField(field.id))}
                        disabled={!field.siteId}
                        size={'small'}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button style={{ justifyContent: 'flex-start' }} variant={'text'} onClick={() => openModal(field)} color={'primary'}>
                        {field.fieldName}
                      </Button>
                    </TableCell>
                    <TableCell>{field.fieldCode}</TableCell>
                    <TableCell>{ReservationFieldTypes.find((t) => t.value === field.fieldType)?.label || ''}</TableCell>
                    <TableCell>{field.order}</TableCell>
                    <BooleanTableColumn align={'center'} value={!!field.isVisible} />
                    <BooleanTableColumn align={'center'} value={!!field.isRequired} />
                    <BooleanTableColumn align={'center'} value={!!field.isUdf} />
                    <BooleanTableColumn align={'center'} value={!!field.siteId && !field.isUdf} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}
