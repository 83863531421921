import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as Actions from '../../../../redux/actions/minibar/actions';
import toBase64 from '../../../../../support/toBase64Converter';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import MinibarCategory from '../../../../../support/MinibarCategory';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';

export default function MinibarItem() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.minibarReducer.lastActionType);
  const error = useSelector((state) => state.minibarReducer.error);
  const selectedItem = useSelector((state) => state.minibarReducer.selectedItem);

  const [itemName, setItemName] = React.useState(selectedItem?.itemName || '');
  const [price, setPrice] = React.useState(selectedItem?.price || '');
  const [transactionCode, setTransactionCode] = React.useState(selectedItem?.transactionCode || '');
  const [articleCode, setArticleCode] = React.useState(selectedItem?.articleCode || '');
  const [fileSelected, setFileSelected] = React.useState(null);
  const [category, setCategory] = React.useState(selectedItem?.category || MinibarCategory.MISC);
  const [imageUrl, setImageUrl] = React.useState(selectedItem?.imageUrl || null);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'MINIBAR_UPDATE_SUCCESS' ||
      lastActionType === 'MINIBAR_DELETE_SUCCESS' ||
      lastActionType === 'MINIBAR_ADD_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const minibarImage = await toBase64(fileSelected);
    const imageName = fileSelected?.name;
    const payload = {
      ...selectedItem,
      itemName,
      price,
      transactionCode,
      articleCode,
      imageUrl: minibarImage ? minibarImage : imageUrl,
      siteId: selectedSite?.id,
      category,
      imageName: imageName ? imageName : imageUrl ? imageUrl.substring(imageUrl.lastIndexOf('/') + 1) : null,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, selectedItem));
    } else {
      dispatch(Actions.create(payload));
    }
  };

  const isFormReady = itemName && transactionCode && price;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Minibar' : 'Create Minibar'}
        subheader={'Minibar item that is present for the guest to purchase'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteItem(selectedItem.id))}
        deleteDisabled={!selectedItem}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/delete minibar item - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={itemName} setValue={setItemName} placeholder="Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Transaction Code'} value={transactionCode} setValue={setTransactionCode} placeholder="Transaction Code" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Article Code'} value={articleCode} setValue={setArticleCode} placeholder="Article Code" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Price'} value={price} setValue={setPrice} type={'number'} inputProps={{ step: 0.1 }} placeholder="Price" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Category'}
              value={category}
              setValue={setCategory}
              options={Object.keys(MinibarCategory).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} />
          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'imageUrl'}
            displayName={'Minibar Image'}
            onSelect={(file) => {
              setFileSelected(file);
            }}
            imageCleared={(value) => {
              if (value) {
                setImageUrl(null);
              }
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
