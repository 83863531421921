import { Typography, useTheme } from '@material-ui/core';
import React from 'react';

export default function StatusLabel({ text = '', error, warning, bold }) {
  const theme = useTheme();

  let style = {};
  if (error) {
    style.color = theme.palette.error.main;
    style.fontWeight = bold && 'bold';
  } else if (warning) {
    style.color = theme.palette.warning.main;
    style.fontWeight = bold && 'bold';
  }

  return (
    <Typography variant="body2" style={style}>
      {text}
    </Typography>
  );
}
