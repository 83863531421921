import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import Swal from 'sweetalert2';

export const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS';
export const CATEGORY_FETCH_FAILURE = 'CATEGORY_FETCH_FAILURE';

export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS';
export const CATEGORY_ADD_FAILURE = 'CATEGORY_ADD_FAILURE';

export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAILURE = 'CATEGORY_UPDATE_FAILURE';

export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE';

export const CATEGORY_ADD_IN_PROGRESS = 'CATEGORY_ADD_IN_PROGRESS';
export const CATEGORY_PROCESSING = 'CATEGORY_PROCESSING';
export const CATEGORY_CLEAR_ERROR = 'CATEGORY_CLEAR_ERROR';
export const CATEGORY_SELECT = 'CATEGORY_SELECT';

export const fetch = (page, size, siteId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/compendium-category/site/${siteId}?page=${page || 0}&size=${size || 10}`;

  dispatch({ type: CATEGORY_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({
      type: CATEGORY_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
      },
    });
  } catch (error) {
    dispatch({ type: CATEGORY_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const create = (request) => async (dispatch) => {
  dispatch({ type: CATEGORY_ADD_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/compendium-category', { body: request });
    dispatch({ type: CATEGORY_ADD_SUCCESS, payload: response });
    Swal.fire('Category Created', 'Category as been created successfully', 'success');
  } catch (error) {
    dispatch({ type: CATEGORY_ADD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const update = (request) => async (dispatch) => {
  dispatch({ type: CATEGORY_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/compendium-category', { body: request });
    dispatch({ type: CATEGORY_UPDATE_SUCCESS, payload: response });
    Swal.fire('Category Updated', 'Category has been updated successfully', 'success');
  } catch (error) {
    dispatch({ type: CATEGORY_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteItem = (selectedItem) => async (dispatch) => {
  dispatch({ type: CATEGORY_PROCESSING });
  Swal.fire({
    title: `Delete Compendium Category?`,
    text: 'Warning: This will delete all articles associated with this category and is not reversible!',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', `/api/private/portal/compendium-category/${selectedItem.id}`, {});
        dispatch({ type: CATEGORY_DELETE_SUCCESS, payload: selectedItem });
      } catch (error) {
        dispatch({ type: CATEGORY_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
      }
    } else {
      dispatch({ type: CATEGORY_CLEAR_ERROR });
    }
  });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: CATEGORY_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: CATEGORY_SELECT, payload: item });
};
