import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import Charts from '../Charts';
import ChartTableButton from './ChartTableButton';

const ChartCard = ({ title, subheader, tableView, setTableView, ...props }) => {
  return (
    <Card variant={'outlined'} style={styles}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={<ChartTableButton tableView={tableView} setTableView={(value) => setTableView(value)} />}
      />

      <Divider variant={'middle'} />

      <CardContent>
        <ResponsiveContainer
          minWidth={Charts.getDefaultChartDimension()}
          minHeight={Charts.getDefaultChartDimension()}
          width="100%"
          height="100%"
        >
          {props.children}
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

const styles = {
  height: '100%',
};

export default ChartCard;
