import React, { useState } from 'react';
import { Chip, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  chip: {
    '&.MuiChip-root': {
      height: theme.spacing(2.1),
    },
  },
}));

export default function FormMultiChip({
  label,
  value,
  setValue,
  margin = 'dense',
  style,
  disabled = false,
  validation,
  validationMessage,
  transform,
  ...props
}) {
  const [error, setError] = useState(false);

  const handleUpdate = (item) => {
    setError(false);

    if (transform) {
      item = transform(item);
    }

    // Duplicate check
    if (!item || (value?.length > 0 && value.filter((tag) => tag === item).length > 0)) {
      return;
    }

    // Regex Validation
    if (validation && !validation.test(item)) {
      setError(true);
      return;
    }

    if (value?.length > 0) {
      setValue([...value, item]);
    } else {
      setValue([item]);
    }
  };

  const handleDelete = (item) => {
    setValue(value.filter((it) => it !== item));
  };

  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  const classes = useStyles();

  return (
    <FormControl fullWidth variant={'outlined'} margin={margin} error={error} disabled={disabled}>
      <Autocomplete
        multiple
        id={id}
        options={[]}
        defaultValue={[]}
        freeSolo
        filterSelectedOptions
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              placeholder={label}
              variant={'outlined'}
              margin={'dense'}
              error={error}
              helperText={error ? validationMessage : ''}
            />
          );
        }}
        value={value}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => <Chip key={option} label={option} onDelete={() => handleDelete(option)} className={classes.chip} />)
        }
        onChange={(event) => handleUpdate(event.target.value)}
        onClose={(event) => handleUpdate(event.target.value)}
        disabled={disabled}
        {...props}
      />
    </FormControl>
  );
}
