import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const FoodSetChoiceActions = {
  ...actionCreator.createActions('Food Set Choice', 'FOOD_SET_CHOICE', '/api/private/portal/food/vendor/{vendorId}/charge'),
  fetchAll: (itemId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_SET_CHOICE_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/${itemId}/set/choice`, {});
      await dispatch({ type: `FOOD_SET_CHOICE_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food choice with result: ', error);
      dispatch({
        type: `FOOD_SET_CHOICE_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default FoodSetChoiceActions;
