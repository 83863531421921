import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Link } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SpecialRequestCategoryActions from '../../../../redux/actions/specialRequestCategory/actions';

const columns = (history) => [
  {
    id: 'actions',
    label: '',
    align: 'left',
    width: 100,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={'inherit'}
          onClick={() => dispatch(SpecialRequestCategoryActions.delete(value, `/api/private/specialRequestCategory/schedule/${value.id}`))}
        >
          <DeleteOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'expand',
    label: '',
    align: 'right',
    width: 10,
    dataType: DataTypes.DATA_TYPE_EXPAND,
  },
  {
    id: 'category',
    label: 'Guest Request Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Link href={'#'} component="button" onClick={() => dispatch(SpecialRequestCategoryActions.selectAndNavigate(value, history))}>
          {value.category}
        </Link>
      );
    },
  },
  {
    id: 'catOrder',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'escalationEnabled',
    label: 'Escalation Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

const subColumns = [
  {
    id: 'dayOfWeek',
    label: 'Day',
    align: 'left',
    width: '10%',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'startTime',
    label: 'Start Time',
    align: 'left',
    width: '10%',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'endTime',
    label: 'End Time',
    align: 'left',
    width: '80%',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
];

export default function RequestCategoryServiceList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.specialRequestCategoryReducer.entityList);
  const processing = useSelector((state) => state.specialRequestCategoryReducer.processing);
  const page = useSelector((state) => state.specialRequestCategoryReducer.page);
  const error = useSelector((state) => state.specialRequestCategoryReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const parameters = { sort: 'category,asc', siteId: selectedSite?.id };
  const pathVariables = { siteId: selectedSite?.id };
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);

  return (
    <AutoTable
      title="Guest Request Categories"
      subheader={'Configure request categories and service times for guests'}
      buttonName={'Create New'}
      handleRefreshReport={() => dispatch(SpecialRequestCategoryActions.fetch(0, 25, parameters, pathVariables))}
      selectItem={(item) => dispatch(SpecialRequestCategoryActions.select(item))}
      selectedSite={selectedSite}
      selectedCorporation={selectedCorporation}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) =>
        dispatch(SpecialRequestCategoryActions.fetch(newPage, pageSize, parameters, pathVariables))
      }
      page={page}
      error={error}
      columns={columns(history)}
      prefix="SPECIAL_REQUEST_CATEGORY"
      createNewURL="/site/specialRequestCategory/new"
      detailURL="/site/specialRequestCategory/view/{id}"
      idColumn={'id'}
      data={data}
      subColumns={subColumns}
      subColumnAttribute={'scheduleDays'}
    />
  );
}
