import FormSelect from './FormSelect';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationSchemeActions from '../../administration/redux/actions/scheme/notification/actions';

export default function NotificationSchemeSelect({ label = 'Notification Scheme', value, setValue, corporationId }) {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.notificationSchemeReducer.entityList);

  // fetch all payment providers accessible to a user
  useEffect(() => {
    if (corporationId) {
      dispatch(NotificationSchemeActions.fetch(0, 50, { corporationId }));
    }
  }, [dispatch, corporationId]);

  return (
    <FormSelect
      label={label}
      value={value}
      setValue={setValue}
      options={dataList.map((p) => {
        return { value: p.id, label: p.schemeName };
      })}
    />
  );
}
