import * as Action from '../../actions/authentication/actions';

const initial = {
  lastActionType: 'NONE',
  authState: 'loading', //current authentication state
  authData: null, //authentication data from cognito or data required for the next authentication state
  isLoginCheckComplete: false,
  username: null, //username that can be passed between pages
  error: null,
};

export default function authenticationReducer(state = initial, action) {
  switch (action.type) {
    case Action.CHECK_LOGIN_STATUS_IN_PROGRESS: {
      return {
        ...state,
        lastActionType: action.type,
        authState: 'loading',
        authData: null,
      };
    }
    case Action.CHECK_LOGIN_STATUS_SUCCESS: {
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload.nextState,
        authData: action.payload.nextData,
        isLoginCheckComplete: true,
      };
    }
    case Action.CHECK_LOGIN_STATUS_FAILURE: {
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload.nextState,
        authData: action.payload.nextData,
        isLoginCheckComplete: true,
      };
    }
    case Action.LOGIN_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        username: null,
        authData: null,
        error: null,
      };
    case Action.LOGIN_IN_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload.nextState,
        authData: action.payload.nextData,
      };
    case Action.LOGIN_IN_CHALLENGE_REQUIRED:
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload.nextState,
        authData: action.payload.nextData,
        error: null,
      };
    case Action.LOGIN_IN_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Action.REQUEST_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        username: action.payload,
        error: null,
      };
    case Action.SUBMIT_FORGOT_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        username: null,
        error: action.payload,
      };
    case Action.SUBMIT_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Action.REQUEST_FORGOT_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Action.UPDATE_AUTHENTICATION_STATE:
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload.state,
        authData: action.payload.data,
        error: null,
      };
    case Action.LOGOUT_ACCOUNT:
      return {
        ...state,
        lastActionType: action.type,
        authState: action.payload,
        authData: null,
        error: null,
      };
    default:
      return state;
  }
}
