import React, { useLayoutEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  withStyles,
} from '@material-ui/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/react';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment/moment';
import { ActivityBookingStatus } from '../../../../support/ActivityBookingStatus';
import ActivityBookingActions from '../../../redux/actions/activity/booking/actions';
import { useDispatch } from 'react-redux';

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
})(TextField);

const initial = {
  siteName: '',
  activityName: '',
  activityId: null,
  date: '',
  from: '',
  to: '',
  rates: [],
  created: '',
};

const BOOKING_ACTION = {
  APPROVE: { label: 'Approve', status: ActivityBookingStatus.ACCEPTED },
  DECLINE: { label: 'Decline', status: ActivityBookingStatus.DECLINED },
};

export default function ActivityBookingApprovalDialog({ open, setClose, booking = initial }) {
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});

  const handleAction = (action) => {
    Swal.fire({
      title: `${action.label} Booking?`,
      html: 'This operation is not reversible.<br>Do you want to continue?',
      showCancelButton: true,
      heightAuto: false,
      icon: action.status === ActivityBookingStatus.ACCEPTED ? 'info' : 'warning',
      target: '#act-booking-dialog',
    }).then(async (result) => {
      if (result.value) {
        try {
          Swal.fire({
            title: 'Processing...',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
            didClose: () => Swal.hideLoading(),
            allowEnterKey: false,
            target: '#act-booking-dialog',
          });

          await API.patch('PrivateAPI', `/api/private/portal/activity/booking/${booking.id}/status?status=${action.status}`, {});
          await dispatch(ActivityBookingActions.updateStatus({ ...booking, status: action.status }));
          Swal.close();
        } catch (error) {
          console.error(`Failed to ${action.label} Booking`, error);
          Swal.fire(`Failed to ${action.label} Booking`, `Failed to ${action.label} Booking. Please try again later.`, 'error');
        } finally {
          handleClose();
        }
      }
    });
  };

  const renderEvent = (event) => {
    return (
      <Box width={'100%'}>
        <Box className={'fc-event-time'}>{event.timeText}</Box>
        <Box className={'fc-event-title'}>
          {`Remaining: `}
          {event.event.extendedProps.rates.map((rate) => {
            if (rate.capacity) {
              return <Chip key={`chip-c-${rate.id}`} size={'small'} label={`${rate.capacity} - ${rate.name}`} />;
            }
            return null;
          })}
        </Box>
      </Box>
    );
  };

  useLayoutEffect(() => {
    if (booking?.activityId) {
      API.post('PrivateAPI', `/api/private/portal/activity/${booking.activityId}/calendar`, {
        body: { from: booking.date, to: booking.date },
      })
        .then((response) => setEvent(response))
        .catch((error) => {
          console.error('Failed to Event Calendar', error);
          Swal.fire('Failed to Event Calendar', 'Failed to Fetch Calendar for this activity. Please try again later.', 'error');
        });
    }
  }, [booking]);

  function handleClose() {
    setClose(null);
  }

  if (booking) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={'md'} id={'act-booking-dialog'}>
        <DialogTitle> Approve/Decline Booking </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={booking.siteName}
                    label="Site Name"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={booking.activityName}
                    label="Activity Name"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={moment(booking.date).format('LL')}
                    label="Date"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={moment.utc(booking.from, 'HH:mm').format('hh:mm A')}
                    label="From"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={moment.utc(booking.to, 'HH:mm').format('hh:mm A')}
                    label="To"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant={'outlined'} disabled>
                    <Autocomplete
                      multiple
                      id="requested-rates"
                      options={booking.rates}
                      value={booking.rates}
                      getOptionLabel={(option) => `${option.quantity} - ${option.rateName}`}
                      renderInput={(params) => (
                        <DarkerDisabledTextField disabled {...params} label={'Requested Rates'} variant={'outlined'} margin={'dense'} />
                      )}
                      renderTags={(values, _) =>
                        values.map((option, index) => (
                          <Chip key={`chip-r-${option.rateName}`} size={'small'} label={`${option.quantity} - ${option.rateName}`} />
                        ))
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={booking.rates.map((rate) => rate.quantity).reduce((a, b) => a + b, 0)}
                    label="Total Pax"
                    margin={'dense'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DarkerDisabledTextField
                    fullWidth
                    type="text"
                    disabled
                    variant={'outlined'}
                    value={moment.unix(booking.created).format('LLL')}
                    label="Created"
                    margin={'dense'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FullCalendar
                events={event}
                eventContent={renderEvent}
                plugins={[timeGridPlugin]}
                initialView={'timeGridDay'}
                initialDate={new Date(booking.date)}
                slotMinTime={booking.from}
                headerToolbar={{
                  left: '',
                  center: 'title',
                  right: '',
                }}
                displayEventEnd={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {booking.status === ActivityBookingStatus.PAID && (
            <>
              <Button variant={'contained'} color="primary" onClick={() => handleAction(BOOKING_ACTION.APPROVE)}>
                Approve
              </Button>
              <Button variant={'contained'} color="secondary" onClick={() => handleAction(BOOKING_ACTION.DECLINE)}>
                Decline
              </Button>
            </>
          )}
          <Button variant={'contained'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
