import React, { useEffect } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as Actions from '../../../../redux/actions/amenity/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Amenity Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'code',
    label: 'Amenity Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'iconName',
    label: 'Icon Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'iconName',
    label: 'Icon',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_ICON,
  },
];

export default function SiteAmenityList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const amenityList = useSelector((state) => state.siteAmenitiesReducer.data);
  const lastActionType = useSelector((state) => state.siteAmenitiesReducer.lastActionType);
  const processing = useSelector((state) => state.siteAmenitiesReducer.processing);
  const page = useSelector((state) => state.siteAmenitiesReducer.page);
  const error = useSelector((state) => state.siteAmenitiesReducer.error);

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetch(0, 25, { siteId: selectedSiteId }));
    }
  }, [selectedSiteId, dispatch]);

  return (
    <AutoTable
      title="Room Amenities"
      subheader={'Features can be assigned to room types and will be displayed during booking'}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSiteId }))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SITE_PACKAGE"
      newButtonName="New Amenity"
      createNewURL="/site/amenities/new"
      detailURL="/site/amenities/view/{id}"
      idColumn={'id'}
      data={amenityList}
      lastActionType={lastActionType}
    />
  );
}
