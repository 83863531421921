import React, { useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/notificationTemplate/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormSelect from '../../../../../components/form/FormSelect';
import { NotificationTemplateType } from './NotificationTemplateType';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Template Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'locale',
    label: 'Locale',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'type',
    label: 'Template Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function NotificationTemplateList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const notificationTypeList = useSelector((state) => state.notificationTemplateReducer.entityList);
  const lastActionType = useSelector((state) => state.notificationTemplateReducer.lastActionType);
  const page = useSelector((state) => state.notificationTemplateReducer.page);
  const error = useSelector((state) => state.notificationTemplateReducer.error);
  const [templateType, setTemplateType] = useState(NotificationTemplateType.EMAIL);
  const [open, setOpen] = useState(false);

  const selectedSiteId = selectedSite?.id;

  React.useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetch(0, 10, { siteId: selectedSiteId }));
    }
  }, [selectedSiteId, dispatch]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSiteId }));
  };

  const handleSubmit = (templateType) => {
    dispatch(Actions.select(null));
    history.push(`/site/templates/new/${templateType}`);
  };

  const getTemplateOptions = () => {
    return Object.values(NotificationTemplateType)
      .filter((val) => notificationTypeList.every((item) => item.type !== val))
      .map((val) => ({ label: val, value: val }));
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select Type of Template</DialogTitle>
        <DialogContent>
          <FormSelect label={'Template Type'} value={templateType} setValue={setTemplateType} options={getTemplateOptions()} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleSubmit(templateType)}>
            OK
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AutoTable
        title="Notification Template List"
        subheader={'Available Email/SMS templates sent out to customers'}
        handleRefreshReport={() => dispatch(Actions.fetch(0, 10, { siteId: selectedSiteId }))}
        selectItem={(item) => dispatch(Actions.select(item))}
        handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
        page={page}
        error={error}
        clearError={() => dispatch(Actions.clearError())}
        columns={columns}
        prefix="NOTIFICATION_TEMPLATE"
        newButtonName="New Template"
        onCreateNew={() => setOpen(true)}
        buttonDisabled={getTemplateOptions().length === 0}
        detailURL="/site/templates/view/{id}"
        idColumn={'id'}
        data={notificationTypeList}
        lastActionType={lastActionType}
      />
    </>
  );
}
