import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const FoodVendorActions = {
  ...actionCreator.createActions('Food Vendor', 'FOOD_VENDOR', '/api/private/portal/site/{siteId}/food/vendor'),
  addExternal: (siteId, outletCode, masterMenuCodes) => async () => {
    const payload = {
      outletCode: outletCode,
      masterMenuCodes: masterMenuCodes,
    };
    await API.post('PrivateAPI', `/api/private/portal/site/${siteId}/food/vendor/add`, { body: payload });
  },
  forceSync: (siteId, foodVendorId) => async () => {
    await API.post('PrivateAPI', `/api/private/portal/site/${siteId}/food/vendor/${foodVendorId}/sync`, {});
  },
  fetchAll: (siteId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_VENDOR_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/site/${siteId}/food/vendor/list/lite`, {});
      await dispatch({ type: `FOOD_VENDOR_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food vendors with result: ', error);
      dispatch({
        type: `FOOD_VENDOR_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  fetchCodes: (siteId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_VENDOR_FETCH_CODES_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/site/${siteId}/food/vendor/codes`, {});
      await dispatch({ type: `FOOD_VENDOR_FETCH_CODES_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food vendor codes with result: ', error);
      dispatch({
        type: `FOOD_VENDOR_FETCH_CODES_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default FoodVendorActions;
