import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

const FacialRecognitionActions = {
  ...actionCreator.createActions('FacialRecognition', 'FACIAL_RECOGNITION', '/api/private/portal/facialRecognition'),
  selectById: (item) => async (dispatch) => {
    try {
      const response = await API.get('PrivateAPI', `/api/private/portal/facialRecognition/${item}`, {});
      await dispatch({ type: 'FACIAL_RECOGNITION_SELECT', payload: response });
    } catch (error) {
      dispatch({ type: 'FACIAL_RECOGNITION_FETCH_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default FacialRecognitionActions;
