import * as Actions from '../../../actions/compendium/article/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  selectedItem: null,
  processing: false,
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  selectedImage: null,
  imagePage: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  imageData: [],
};

export default function compendiumArticleReducer(state = initial, action) {
  switch (action.type) {
    case Actions.ARTICLE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: false,
      };
    case Actions.ARTICLE_PROCESSING:
    case Actions.ARTICLE_IMAGE_PROCESSING:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: true,
      };
    case Actions.ARTICLE_SELECT:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.ARTICLE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        processing: false,
        error: null,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
        imageData: action.payload.imageData,
      };
    case Actions.ARTICLE_IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        imageData: action.payload.content,
        processing: false,
        error: null,
        imagePage: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
      };
    case Actions.ARTICLE_ADD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: [...state.data, action.payload],
        processing: false,
      };
    case Actions.ARTICLE_IMAGE_ADD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          imageList: [...state.selectedItem.imageList, action.payload],
        },
        imageData: [...state.imageData, action.payload],
        processing: false,
      };
    case Actions.ARTICLE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((e) => e.id !== action.payload.id),
        error: null,
        processing: false,
      };
    case Actions.ARTICLE_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          imageList: state.selectedItem.imageList.filter((e) => e.id !== action.payload.id),
        },
        error: null,
        imageData: state.imageData.filter((e) => e.id !== action.payload.id),
        processing: false,
      };
    case Actions.ARTICLE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.map((e) => (e.id === action.payload.id ? action.payload : e)),
        processing: false,
      };
    case Actions.ARTICLE_IMAGE_SELECT:
      return {
        ...state,
        lastActionType: action.type,
        selectedImage: action.payload,
      };
    case Actions.ARTICLE_DELETE_FAILURE:
    case Actions.ARTICLE_IMAGE_DELETE_FAILURE:
    case Actions.ARTICLE_UPDATE_FAILURE:
    case Actions.ARTICLE_ADD_FAILURE:
    case Actions.ARTICLE_IMAGE_ADD_FAILURE:
    case Actions.ARTICLE_FETCH_FAILURE:
    case Actions.ARTICLE_IMAGE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
