import { Grid } from '@material-ui/core';
import React from 'react';
import ChartCard from './ChartCard';

const ChartCardPair = ({ charts = [] }) => {
  const filteredCharts = charts.filter((c) => c);
  return (
    <Grid container spacing={1} style={styles}>
      {filteredCharts.map((chart) => {
        return (
          <Grid item xs={12} sm={12} md={6}>
            <ChartCard title={chart.title} subheader={chart.subheader} tableView={chart.tableView} setTableView={chart.setTableView}>
              {chart.component(chart.tableView)}
            </ChartCard>
          </Grid>
        );
      })}
    </Grid>
  );
};

const styles = {
  marginBottom: '1.0rem',
};

export default ChartCardPair;
