import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import SiteEventCalendar from './SiteEventCalendar';
import { SystemCalendarEventList } from './SystemCalendarEventList';
import TabPanel from '../../../../control/TabPanel';
import TimeZones from '../../../../../../support/TimeZones';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SiteUtil from '../../../../../../support/SiteUtil';
import { useDispatch, useSelector } from 'react-redux';
import SystemCalendarEventActions from '../../../../../redux/actions/system/event/actions';
import { SystemCalendarEvent, SystemCalendarType } from '../../../../../../support/SystemCalendar';
import moment from 'moment';
import { Language, MeetingRoom, Restaurant } from '@material-ui/icons';

const TIME_FORMAT = 'hh:mm A';

export const SystemCalendarEventsView = ({ calendarType }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const corporationList = useSelector((state) => state.accessReducer.corporationList);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);
  const eventsToday = useSelector((state) => state.systemCalendarEventReducer.eventsToday);
  const lastAction = useSelector((state) => state.systemCalendarEventReducer.lastActionType);
  const [timezone, setTimezone] = useState(SiteUtil.getSiteZone(selectedSite));
  const selectedSiteId = SystemCalendarType.SITE === calendarType ? selectedSite?.id : null;
  const selectedCorpId =
    SystemCalendarType.ORGANISATION === calendarType || SystemCalendarType.SITE === calendarType ? selectedCorp?.id : null;

  const changeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (
      lastAction === 'SYSTEM_CALENDAR_EVENT_UPDATE_SUCCESS' ||
      lastAction === 'SYSTEM_CALENDAR_EVENT_ADD_SUCCESS' ||
      lastAction === 'SYSTEM_CALENDAR_EVENT_DELETE_SUCCESS'
    ) {
      dispatch(SystemCalendarEventActions.fetchToday(selectedCorpId, selectedSiteId, timezone.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lastAction]);

  useEffect(() => {
    if (timezone?.value) {
      dispatch(SystemCalendarEventActions.fetchToday(selectedCorpId, selectedSiteId, timezone.value));
    }
  }, [dispatch, selectedCorpId, selectedSiteId, timezone?.value]);

  const getEventLabel = (event) => {
    return !event?.corpId && !event?.siteId ? 'GLOBAL' : event?.corpId && !event?.siteId ? 'ORG' : 'SITE';
  };

  const displayEventsToday = () => {
    if (eventsToday?.length) {
      return (
        <Grid xs={12} md={2}>
          <Box>
            <CardHeader title={`Events Today`} />
            <CardContent style={{ paddingTop: 0 }}>
              <List disablePadding>
                {eventsToday.map((event) => {
                  return (
                    <ListItem disableGutters>
                      <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
                        {event.extendedProps?.eventType === SystemCalendarEvent.GLOBAL_OUTAGE ? (
                          <Typography
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              fontSize: 12,
                            }}
                          >
                            <Language style={{ width: '100%' }} />
                            {getEventLabel(event.extendedProps)}
                          </Typography>
                        ) : event.extendedProps?.eventType === SystemCalendarEvent.FNB_OUTAGE ? (
                          <Typography
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              fontSize: 12,
                            }}
                          >
                            <Restaurant style={{ width: '100%' }} />
                            {getEventLabel(event.extendedProps)}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              fontSize: 12,
                            }}
                          >
                            <MeetingRoom style={{ width: '100%' }} />
                            {getEventLabel(event.extendedProps)}
                          </Typography>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography style={{ fontWeight: 700 }}>
                            {moment(event.start).format(TIME_FORMAT) + ' - ' + moment(event.end).format(TIME_FORMAT)}
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography variant={'body2'} style={{ fontWeight: 600 }}>
                              {event.extendedProps?.corpId
                                ? corporationList?.find((c) => c.id === event.extendedProps?.corpId)?.name
                                : 'Global'}
                            </Typography>
                            <Typography variant={'body2'}>
                              {event.extendedProps?.siteId
                                ? siteList?.find((c) => c.id === event.extendedProps?.siteId)?.name
                                : 'All Sites'}
                            </Typography>
                            <Typography variant={'caption'}>{event.title}</Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Box>
        </Grid>
      );
    }

    return null;
  };

  return (
    <Card>
      <Box>
        <Tabs value={selectedTab} onChange={changeTab}>
          <Tab label="Calendar View" value={0} />
          <Tab label="Event List View" value={1} />
          <Box
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              paddingBottom: '.3rem',
              paddingTop: '.3rem',
              paddingRight: '1.2rem',
            }}
          >
            <Box style={{ width: '40%' }}>
              <Autocomplete
                fullWidth
                name="Timezone"
                placeholder="Select Timezone"
                options={TimeZones}
                getOptionSelected={(option, selected) => option.value === selected.value}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setTimezone(newValue);
                }}
                value={timezone}
                error={!timezone}
                renderInput={(params) => <TextField {...params} label="Timezone" margin={'dense'} variant="outlined" />}
              />
            </Box>
          </Box>
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={selectedTab} index={0}>
          <Grid container>
            {displayEventsToday()}
            <Grid xs={12} md={eventsToday?.length ? 10 : 12}>
              <SiteEventCalendar calendarType={calendarType} timezone={timezone?.value} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Grid container>
            {displayEventsToday()}
            <Grid xs={12} md={eventsToday?.length ? 10 : 12}>
              <SystemCalendarEventList calendarType={calendarType} timezone={timezone?.value} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Card>
  );
};
