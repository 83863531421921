import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WarningOutlined } from '@material-ui/icons';
import * as Actions from '../../../redux/actions/chat/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  stdPadding: {
    padding: '1.0rem',
    textAlign: 'center',
  },
  textError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  centerContent: {
    maxWidth: '360px',
    margin: 'auto',
    marginTop: '25vh',
    marginBottom: '25vh',
  },
  iconContainer: {
    margin: 'auto',
    minWidth: '100px',
    textAlign: 'center',
  },
  iconStyle: {
    color: theme.palette.error.main,
    fontSize: '4rem!important',
  },
}));

export default function ChatNotSignedIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Paper>
      <div className={classes.centerContent}>
        <div className={classes.iconContainer}>
          <Typography variant="body1" className={classes.iconStyle}>
            <WarningOutlined />
          </Typography>
        </div>
        <div className={classes.stdPadding}>
          <Typography variant="h1" className={classes.textError}>
            Authorization Error
          </Typography>
        </div>
        <div className={classes.stdPadding}>
          <Typography variant="h2">
            It looks like you were not successfully authenticated to use guest messaging. Please click the button below to re-attempt
            authentication.
          </Typography>
        </div>
        <div className={classes.stdPadding}>
          <Button onClick={() => dispatch(Actions.signInAnonymously())} variant="contained" href="#" color="default" size="large">
            AUTHENTICATE
          </Button>
        </div>
      </div>
    </Paper>
  );
}
