import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

const ActivityActions = {
  ...actionCreator.createActions('Activity', 'ACTIVITY', '/api/private/portal/activity'),
  selectById: (item, history) => async (dispatch) => {
    try {
      const response = await API.get('PrivateAPI', `/api/private/portal/activity/${item}`, {});
      await dispatch({ type: 'ACTIVITY_SELECT', payload: response });
      history.push('/activity/view');
    } catch (error) {
      dispatch({ type: 'ACTIVITY_FETCH_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default ActivityActions;
