import React, { useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import EditManualPms from './pms/EditManualPms';
import SiteSystemList from './SiteSystemList';
import { useHistory } from 'react-router-dom';
import EditOpera from './pms/EditOpera';
import EditRMS from './pms/EditRMS';
import EditAssaAbloySystem from './lock/EditAssaAbloySystem';
import * as Actions from '../../../../redux/actions/system/actions';
import EditProtel from './pms/EditProtel';
import EditGallagherSystem from './lock/EditGallagherSystem';
import EditVostioSystem from './lock/EditVostioSystem';
import EditOnitySystem from './lock/EditOnitySystem';
import EditInfor from './pms/EditInfor';
import EditCMS from './pms/EditCMS';
import PmsSystem from '../../../../../constants/PmsSystem';
import LockSystem from '../../../../../constants/LockSystem';
import MembershipSystem from '../../../../../constants/MembershipSystem';
import EditPgr from './membership/EditPgr';
import SystemCategory from '../../../../../constants/SystemCategory';
import MembershipEnrolmentList from './membership/MembershipEnrolmentList';
import { Grid } from '@material-ui/core';
import SecuritySystem from '../../../../../constants/SecuritySystem';
import EditGallagherSecuritySystem from './security/EditGallagherSecuritySystem';
import EditHotSosSecuritySystem from './security/EditHotSosSecuritySystem';
import EditMews from './pms/EditMews';
import FoodPosSystem from '../../../../../constants/FoodPosSystem';
import EditInfrasysPos from './pos/food/EditInfrasysPos';
import EditClarity from './pms/EditClarity';
import ResidentSystem from '../../../../../constants/ResidentSystem';
import EditStarRez from './rms/EditStarRez';

export default function EditSystem() {
  const dispatch = useDispatch();
  const lastActionType = useSelector((state) => state.siteResourcesReducer.lastActionType);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const availableSystemMap = useSelector((state) => state.siteResourcesReducer.availableSystemMap);
  const history = useHistory();
  const systemName = useMemo(() => getSystemName(selectedItem, availableSystemMap), [selectedItem, availableSystemMap]);

  useEffect(() => {
    return () => {
      dispatch(Actions.clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.SITE_SYSTEM_DELETE_SUCCESS ||
      lastActionType === Actions.SITE_SYSTEM_UPDATE_SUCCESS ||
      lastActionType === Actions.SITE_SYSTEM_CREATE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  if (!selectedItem) {
    return <SiteSystemList />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SystemEditForm systemName={systemName} history={history} />
      </Grid>

      <Grid item xs={12}>
        <SystemSpecificControls selectedSystem={selectedItem} />
      </Grid>
    </Grid>
  );
}

function SystemEditForm({ systemName, history }) {
  if (systemName === PmsSystem.OPERA) {
    return <EditOpera />;
  } else if (systemName === PmsSystem.MOCK) {
    return <EditManualPms />;
  } else if (systemName === PmsSystem.RMS) {
    return <EditRMS />;
  } else if (systemName === PmsSystem.CMS) {
    return <EditCMS />;
  } else if (systemName === PmsSystem.PROTEL) {
    return <EditProtel />;
  } else if (systemName === PmsSystem.INFOR) {
    return <EditInfor />;
  } else if (systemName === PmsSystem.MEWS) {
    return <EditMews />;
  } else if (systemName === PmsSystem.CLARITY) {
    return <EditClarity />;
  } else if (systemName === LockSystem.ASSA_ABLOY) {
    return <EditAssaAbloySystem />;
  } else if (systemName === LockSystem.GALLAGHER) {
    return <EditGallagherSystem />;
  } else if (systemName === LockSystem.VOSTIO) {
    return <EditVostioSystem />;
  } else if (systemName === LockSystem.ONITY) {
    return <EditOnitySystem />;
  } else if (systemName === MembershipSystem.PGR) {
    return <EditPgr />;
  } else if (systemName === SecuritySystem.GALLAGHER) {
    return <EditGallagherSecuritySystem />;
  } else if (systemName === SecuritySystem.HOT_SOS) {
    return <EditHotSosSecuritySystem />;
  } else if (systemName === FoodPosSystem.INFRASYS_POS) {
    return <EditInfrasysPos />;
  } else if (systemName === ResidentSystem.STAR_REZ) {
    return <EditStarRez />;
  } else {
    Swal.fire({
      title: 'Missing UI',
      text: 'No UI has been found for this system. Please contact your administrator',
      icon: 'warning',
      showCancelButton: true,
      heightAuto: false,
    }).then(() => {
      history.push('/site/systems');
    });

    return null;
  }
}

function SystemSpecificControls({ selectedSystem }) {
  if (!selectedSystem) {
    return null;
  }

  if (selectedSystem.systemCategory === SystemCategory.MEMBERSHIP) {
    return <MembershipEnrolmentList />;
  }

  return null;
}

const getSystemName = (selectedSystem, availableSystemMap = {}) => {
  if (!selectedSystem) {
    return null;
  }

  const availableSystemsForType = availableSystemMap[selectedSystem.systemCategory] || [];
  return availableSystemsForType.find((sys) => sys.systemClass === selectedSystem.systemType)?.name;
};
