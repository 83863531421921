import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import { useHistory } from 'react-router-dom';
import SecondaryGuestActions, { SECONDARY_GUEST_SHARE_SUCCESS } from '../../../../../redux/actions/secondaryGuest/actions';
import CannedRegex from '../../../../../../support/CannedRegex';
import PhoneNumberInput from '../../../../../../components/phoneNumberInput/PhoneNumberInput';

export default function SecondaryGuestEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const error = useSelector((state) => state.secondaryGuestReducer.error);
  const processing = useSelector((state) => state.secondaryGuestReducer.processing);
  const lastActionType = useSelector((state) => state.secondaryGuestReducer.lastActionType);
  const profile = useSelector((state) => state.accessReducer.profile);
  const [guestName, setGuestName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState({
    phoneNumber: '',
    sanitizedPhoneNumber: '',
    isValid: false,
  });

  useEffect(() => {
    dispatch(SecondaryGuestActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === SECONDARY_GUEST_SHARE_SUCCESS) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      referenceNumber: selectedReservation.reservationNumber,
      siteId: selectedSite?.id,
      guestName,
      guestLastName,
      emailAddress,
      phoneNumber: phoneNumber.sanitizedPhoneNumber,
      sharedBy: profile.email,
    };
    dispatch(SecondaryGuestActions.inviteGuest(payload));
  };

  const isPhoneValid = phoneNumber.isValid;
  const isEmailValid = CannedRegex.isEmailValid(emailAddress);
  const isFormReady = isEmailValid && isPhoneValid && guestName && guestLastName;

  return (
    <Card>
      <CardHeaderWithControls
        header={'Share Trip'}
        subheader={'Share trip with a guest'}
        buttonName={'Invite'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        buttonLoading={processing}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to invite guest - ${error.message}`}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Guest First Name'} value={guestName} setValue={setGuestName} placeholder="First Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Guest Last Name'} value={guestLastName} setValue={setGuestLastName} placeholder="Last Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Guest Email'} value={emailAddress} setValue={setEmailAddress} placeholder="Email" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phoneNumber} setPhone={setPhoneNumber} isRequired={false} defaultRegion={selectedSite?.countryCode} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
