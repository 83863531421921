const SpecialRequestState = {
  UNANSWERED: 'UNANSWERED',
  ANSWERED: 'ANSWERED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

const SpecialRequestStateOptions = [
  { value: SpecialRequestState.ANSWERED, label: 'Yes' },
  { value: SpecialRequestState.UNANSWERED, label: 'No' },
  { value: SpecialRequestState.NOT_APPLICABLE, label: 'No Action' },
];

const SpecialRequestStateLabel = { ANSWERED: 'Yes', UNANSWERED: 'No', NOT_APPLICABLE: 'No Action' };

export default SpecialRequestState;
export { SpecialRequestStateOptions, SpecialRequestStateLabel };
