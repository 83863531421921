import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import FormField from '../../../../../../components/form/FormField';
import { InfrasysEventHookSubTypes } from '../pos/food/InfrasysEventHookType';
import { DeleteOutlined, SaveOutlined } from '@material-ui/icons';
import EventHookActions from '../../../../../redux/actions/eventHook/actions';

export default function EditEventHook({ item, siteId, processing }) {
  const dispatch = useDispatch();
  const [callbackUrl, setCallbackUrl] = useState(item.callbackUrl || '');

  const handleRequest = () => {
    const payload = {
      ...item,
      callbackUrl,
    };

    if (item?.id) {
      dispatch(EventHookActions.delete(payload, `/api/private/portal/event/hook/${item.id}`));
    } else {
      dispatch(EventHookActions.add(payload, { siteId }));
    }
  };

  const isValidUrl = useMemo(() => {
    try {
      new URL(callbackUrl);
      return true;
    } catch (err) {
      return false;
    }
  }, [callbackUrl]);

  if (!item) {
    return null;
  }

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item xs={4} sm={4} md={3} lg={3}>
        <FormField label={'Hook UUID'} value={item.hookId || ''} disabled />
      </Grid>
      <Grid item xs={4} sm={4} md={3} lg={3}>
        <FormField
          label={'Hook Type'}
          value={InfrasysEventHookSubTypes.find((it) => it.value === item?.hookSubtype)?.label || 'Unknown'}
          disabled
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <FormField label={'URL'} value={callbackUrl} setValue={setCallbackUrl} disabled={item?.id} required />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        {item?.id && (
          <Button
            variant={'contained'}
            fullWidth
            onClick={handleRequest}
            startIcon={processing ? <CircularProgress size={15} /> : <DeleteOutlined />}
            disabled={processing}
          >
            Remove
          </Button>
        )}
        {!item?.id && (
          <Button
            variant={'contained'}
            fullWidth
            onClick={handleRequest}
            startIcon={processing ? <CircularProgress size={15} /> : <SaveOutlined />}
            disabled={!isValidUrl || processing}
          >
            Register
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
