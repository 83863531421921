import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Delete } from '@material-ui/icons';

const getDaysOfWeek = () => {
  return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
};
export default function RoomUpsellMappingCard({
  existingRules,
  addRule,
  onFieldChange,
  currentRoomType,
  availableRoomTypes,
  deleteOverride,
}) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeaderWithControls
          header={'Room Upsell Rules'}
          subheader={'Configure Rules for each room type and their pricing'}
          onClick={addRule}
          buttonName={'Add Rule'}
          disableBackButton
        />

        <CardContent>
          {existingRules.length <= 0 && (
            <Alert severity="info" variant={'filled'}>
              No Rules set up to move guests from Room Type {currentRoomType?.code}
            </Alert>
          )}

          <Grid container spacing={1}>
            {existingRules.map((pd, idx) => (
              <Grid item xs={12} key={'desc-' + idx}>
                <Card variant={'outlined'}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button size="large" variant={'text'} startIcon={<Delete />} onClick={() => deleteOverride(idx)} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl fullWidth variant={'outlined'} margin={'dense'}>
                          <InputLabel id="roomTypeTo-select-label">Upgrade To</InputLabel>
                          <Select
                            labelId="roomTypeTo-select-label"
                            value={existingRules[idx].roomTypeToCode}
                            name="roomTypeToCode"
                            labelWidth={100}
                            onChange={onFieldChange(idx)}
                            required
                          >
                            {availableRoomTypes.map((l) => (
                              <MenuItem key={l.label} value={l.label}>
                                {l.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6} md={2} lg={2}>
                        <TextField
                          fullWidth
                          margin={'dense'}
                          type="number"
                          label="Amount"
                          variant={'outlined'}
                          inputProps={{ step: 0.1 }}
                          name="extraAmount"
                          onChange={onFieldChange(idx)}
                          value={existingRules[idx].extraAmount}
                          required
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <FormControl fullWidth variant={'outlined'} margin={'dense'}>
                          <InputLabel id="roomTypeTo-select-label">Valid On</InputLabel>
                          <Select
                            labelId="roomTypeTo-select-label"
                            value={existingRules[idx].validDays}
                            multiple={true}
                            name="validDays"
                            labelWidth={60}
                            onChange={onFieldChange(idx)}
                            required
                          >
                            {getDaysOfWeek().map((l) => (
                              <MenuItem key={l} value={l}>
                                {l}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          fullWidth
                          margin={'dense'}
                          type="text"
                          label="Package Code"
                          variant={'outlined'}
                          name="packageCode"
                          onChange={onFieldChange(idx)}
                          value={existingRules[idx].packageCode}
                          required
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
