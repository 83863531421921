import React, { useEffect } from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import { useDispatch, useSelector } from 'react-redux';
import { ReportScheduleActions } from '../../../../../redux/actions/reservation/report/schedule/actions';
import * as DataTypes from '../../../../../../constants/DataTypes';
import { Chip, IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { ReportTypeOptions } from '../../../../../../support/ReportType';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(ReportScheduleActions.delete(value, `/api/private/portal/report/schedule/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'scheduleName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'reportType',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: (value) => ReportTypeOptions.find((report) => report.value === value.reportType)?.label,
  },
  {
    id: 'frequency',
    label: 'Frequency',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sendTime',
    label: 'Send Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'recipients',
    label: 'Recipients',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.recipients?.length > 0) {
        return value.recipients.map((val) => <Chip key={val} label={val} />);
      }
      return null;
    },
  },
];

export default function ReportSchedule({
  title = 'Scheduled Reports',
  subheader = 'Receive reports by email automatically based on schedules you define.',
}) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const siteId = selectedSite?.id;
  const reportScheduleList = useSelector((state) => state.reportScheduleReducer.entityList);
  const page = useSelector((state) => state.reportScheduleReducer.page);
  const error = useSelector((state) => state.reportScheduleReducer.error);
  const processing = useSelector((state) => state.reportScheduleReducer.processing);

  useEffect(() => {
    dispatch(ReportScheduleActions.fetch(0, 0, null, { siteId }));
  }, [dispatch, siteId]);

  return (
    <AutoTable
      title={title}
      subheader={subheader}
      selectItem={(item) => dispatch(ReportScheduleActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(ReportScheduleActions.fetch(newPage, pageSize, null, { siteId }))}
      page={page}
      error={error}
      columns={columns}
      prefix={'REPORT_SCHEDULE'}
      detailURL="/reservation/report/schedule/view/{id}"
      createNewURL="/reservation/report/schedule/new"
      data={reportScheduleList}
    />
  );
}
