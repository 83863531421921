const CheckOutState = {
  NOT_STARTED: 'NOT_STARTED',
  MINIBAR: 'MINIBAR',
  BILL: 'BILL',
  PAYMENT: 'PAYMENT',
  FEEDBACK: 'FEEDBACK',
  COMPLETED: 'COMPLETED',
};

const CheckOutStateOptions = [
  { label: 'Not Started', value: CheckOutState.NOT_STARTED },
  { label: 'Minibar', value: CheckOutState.MINIBAR },
  { label: 'Bill', value: CheckOutState.BILL },
  { label: 'Payment', value: CheckOutState.PAYMENT },
  { label: 'Feedback', value: CheckOutState.FEEDBACK },
  { label: 'Completed', value: CheckOutState.COMPLETED },
];

export default CheckOutState;
export { CheckOutStateOptions };
