import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as DataTypes from '../../../../../../constants/DataTypes';
import Objects from '../../../../../../support/Objects';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../../components/form/FormField';
import FileImageSelector from '../../../../../../components/file/FileImageSelector';
import toBase64 from '../../../../../../support/toBase64Converter';
import { RssiSignalStrengthOptions } from '../../../../../../support/RssiSignalStrength';
import FormSelect from '../../../../../../components/form/FormSelect';

export default function EditOnitySystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = useState(selectedItem?.name || '');
  const [enabled, setEnabled] = useState(selectedItem?.enabled || false);
  const [pmsUrl, setPmsUrl] = useState(configuration?.pmsUrl || 'https://13.211.251.71:9090/api/v1');
  const [pmsUsername, setPmsUsername] = useState(configuration?.pmsUsername || 'manager');
  const [pmsPassword, setPmsPassword] = useState(configuration?.pmsPassword || 'Manager2023');
  const [directApiUrl, setDirectApiUrl] = useState(configuration?.directApiUrl || 'https://dkintapi.keytest.net/api/ver10');
  const [apiUsername, setApiUsername] = useState(configuration?.apiUsername || '01D001D8');
  const [apiPassword, setApiPassword] = useState(configuration?.apiPassword || 'h!VZYRZ49%SjdmNy');
  const [certificatePassword, setCertificatePassword] = useState(configuration?.certificatePassword || '-#abA6,ASwoQA:l');
  const [apiOwnerId, setApiOwnerId] = useState(configuration?.apiOwnerId || '501');
  const [certificateFile, setCertificateFile] = useState(configuration?.certificateFile || null);
  const [certificateName, setCertificateName] = useState(configuration?.certificateName || null);
  const [syncUrl, setSyncUrl] = useState(configuration?.syncUrl || 'dkintekey.keytest.net');
  const [pinCode, setPinCode] = useState(configuration?.pinCode || '1234');
  const [rssi, setRssi] = useState(configuration?.rssi || -70);
  const [debug, setDebug] = useState(configuration?.debug || false);

  const handleSubmit = React.useCallback(() => {
    const request = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        endpoint: Objects.sanitizeAndTrimString(directApiUrl),
        pmsUrl: Objects.sanitizeAndTrimString(pmsUrl),
        pmsUsername,
        pmsPassword,
        directApiUrl: Objects.sanitizeAndTrimString(directApiUrl),
        apiUsername,
        apiPassword,
        apiOwnerId,
        certificateFile,
        certificateName,
        certificatePassword,
        rssi,
        siteId: selectedItem?.siteId || selectedSite.id,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(request));
    } else {
      dispatch(Actions.createSiteSystem(request));
    }
  }, [
    selectedItem,
    name,
    enabled,
    selectedSite.id,
    debug,
    directApiUrl,
    pmsUrl,
    pmsUsername,
    pmsPassword,
    apiUsername,
    apiPassword,
    apiOwnerId,
    certificateFile,
    certificateName,
    certificatePassword,
    rssi,
    dispatch,
  ]);

  const configurations = [
    {
      id: 'name',
      label: 'Friendly Name',
      type: 'text',
      value: name,
      setValue: setName,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'pmsUrl',
      label: 'PMS URL',
      type: 'text',
      value: pmsUrl,
      setValue: setPmsUrl,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'pmsUsername',
      label: 'PMS Username',
      type: 'text',
      value: pmsUsername,
      setValue: setPmsUsername,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'pmsPassword',
      label: 'PMS Password',
      type: 'password',
      value: pmsPassword,
      setValue: setPmsPassword,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'directApiUrl',
      label: 'Direct API Url',
      type: 'text',
      value: directApiUrl,
      setValue: setDirectApiUrl,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'apiOwnerId',
      label: 'Owner ID',
      value: apiOwnerId,
      setValue: setApiOwnerId,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'apiUsername',
      label: 'API Username',
      type: 'text',
      value: apiUsername,
      setValue: setApiUsername,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'apiPassword',
      label: 'API Password',
      type: 'password',
      value: apiPassword,
      setValue: setApiPassword,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'syncUrl',
      label: 'Sync ID',
      value: syncUrl,
      setValue: setSyncUrl,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'pinCode',
      label: 'Pin Code',
      type: 'password',
      value: pinCode,
      setValue: setPinCode,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'certificateFile',
      label: 'Certificate',
      type: 'text',
      value: certificateFile,
      setValue: setCertificateFile,
      dateType: DataTypes.DATA_TYPE_IMAGE,
    },
    {
      id: 'certificatePassword',
      label: 'Certificate Password',
      type: 'password',
      value: certificatePassword,
      setValue: setCertificatePassword,
      dateType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'rssi',
      label: 'RSSI Signal Strength',
      value: rssi,
      setValue: setRssi,
      dateType: DataTypes.DATA_TYPE_LIST,
    },
    {
      label: 'Debug',
      value: debug,
      setValue: setDebug,
      dateType: DataTypes.DATA_TYPE_BOOLEAN,
    },
    {
      label: 'Enabled',
      value: enabled,
      setValue: setEnabled,
      dateType: DataTypes.DATA_TYPE_BOOLEAN,
    },
  ];

  const displayConfigurations = (configuration, index) => {
    if (configuration.dateType === DataTypes.DATA_TYPE_STRING) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={6} key={`date-type-string-${index}`}>
          <FormField
            label={configuration.label}
            type={configuration.type}
            placeholder={configuration.placeholder}
            value={configuration.value}
            setValue={configuration.setValue}
            error={!configuration.disabledError && !configuration.value}
            inputProps={{ readOnly: configuration.readOnly }}
          />
        </Grid>
      );
    } else if (configuration.dateType === DataTypes.DATA_TYPE_BOOLEAN) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={6} key={`date-type-boolean-${index}`}>
          <FormBooleanSelect label={configuration.label} value={configuration.value} setValue={configuration.setValue} />
        </Grid>
      );
    } else if (configuration.dateType === DataTypes.DATA_TYPE_IMAGE) {
      return (
        <FileImageSelector
          sourceItem={configuration}
          sourceItemProperty={'value'}
          displayName={'Certificate(.pfx)'}
          accept={'.pfx'}
          onSelect={async (file) => {
            const base64File = await toBase64(file);
            setCertificateName(Objects.getUUid());
            setCertificateFile(base64File);
          }}
          imageCleared={(value) => {
            if (value) {
              setCertificateFile(null);
            }
          }}
        />
      );
    } else if (configuration.dateType === DataTypes.DATA_TYPE_LIST) {
      if (configuration.id === 'rssi') {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={configuration.label}
              value={configuration.value}
              setValue={configuration.setValue}
              options={RssiSignalStrengthOptions}
            />
          </Grid>
        );
      }
    }
  };

  const isFormReady =
    name &&
    directApiUrl &&
    apiUsername &&
    apiPassword &&
    apiOwnerId &&
    pmsUrl &&
    pmsPassword &&
    pmsUsername &&
    certificateFile &&
    syncUrl &&
    pinCode &&
    rssi;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Onity Lock System'}
        subheader={'Onity locking system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          {configurations.map((configuration, index) => displayConfigurations(configuration, index))}
        </Grid>
      </CardContent>
    </Card>
  );
}
