import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, CardHeader, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { CloudDownload, CloudUpload, DeleteOutlined, Save } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export default function CardHeaderWithControls({
  header,
  subheader,
  disabled,
  onClick,
  buttonName = 'Save',
  buttonIcon = <Save />,
  buttonColour = 'secondary',
  buttonLoading = false,
  deleteButtonName = 'Delete',
  onDelete,
  deleteDisabled,
  deleteIcon = <DeleteOutlined />,
  deleteProcessing = false,
  backHidden,
  backLink,
  secondaryButtonAction,
  secondaryButtonIcon = <CloudDownload />,
  secondaryButtonName = 'Download',
  secondaryButtonDisabled,
  tertiaryButtonAction,
  tertiaryButtonIcon = <CloudUpload />,
  tertiaryButtonName = 'Upload',
  tertiaryButtonDisabled,
}) {
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleSecondaryButtonClick = () => {
    if (secondaryButtonAction) {
      secondaryButtonAction();
    }
  };

  const handleTertiaryButtonClick = () => {
    if (tertiaryButtonAction) {
      tertiaryButtonAction();
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <CardHeader title={header} subheader={subheader || ''} />
      </Box>

      <Box className={styles.buttons}>
        {onClick && (
          <Button
            color={buttonColour}
            variant={'contained'}
            onClick={handleOnClick}
            fullWidth={matches}
            disabled={disabled || buttonLoading}
            startIcon={buttonLoading ? <CircularProgress size={15} /> : buttonIcon}
          >
            {buttonName}
          </Button>
        )}

        {secondaryButtonAction && (
          <Button
            variant={'contained'}
            startIcon={secondaryButtonIcon}
            onClick={handleSecondaryButtonClick}
            fullWidth={matches}
            disabled={secondaryButtonDisabled}
          >
            {secondaryButtonName}
          </Button>
        )}

        {tertiaryButtonAction && (
          <Button
            variant={'contained'}
            startIcon={tertiaryButtonIcon}
            onClick={handleTertiaryButtonClick}
            fullWidth={matches}
            disabled={tertiaryButtonDisabled}
          >
            {tertiaryButtonName}
          </Button>
        )}

        {onDelete && (
          <Button
            variant={'contained'}
            startIcon={deleteProcessing ? <CircularProgress size={15} /> : deleteIcon}
            onClick={handleDeleteClick}
            fullWidth={matches}
            disabled={deleteDisabled || deleteProcessing}
          >
            {deleteButtonName}
          </Button>
        )}

        {!backHidden && (
          <React.Fragment>
            {backLink && (
              <Button variant={'outlined'} component={Link} to={backLink} fullWidth={matches}>
                Back
              </Button>
            )}

            {!backLink && (
              <Button variant={'outlined'} onClick={handleGoBack} fullWidth={matches}>
                Back
              </Button>
            )}
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'grid',
      gridTemplateColumns: '0.5fr 0.5fr',
      alignContent: 'center',
      gridTemplateRows: '1fr',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '0.6fr 0.4fr',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    header: {},
    buttons: {
      display: 'grid',
      padding: '0 1.0rem',
      gridColumnGap: '1.0rem',
      justifyContent: 'flex-end',
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px,1fr))',
      alignContent: 'center',
      alignItems: 'center',
      gridTemplateRows: '1fr',
      width: '100%',
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px,200px))',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridRowGap: '0.5rem',
      },
    },
  };
});
