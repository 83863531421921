import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const FoodChargeActions = {
  ...actionCreator.createActions('Food Charge', 'FOOD_CHARGE', '/api/private/portal/food/vendor/{vendorId}/charge'),
  fetchAll: (vendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_CHARGE_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/charge/list`, {});
      await dispatch({ type: `FOOD_CHARGE_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food charge with result: ', error);
      dispatch({
        type: `FOOD_CHARGE_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default FoodChargeActions;
