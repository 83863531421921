import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import LicenseActions from '../../../redux/actions/license/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { EventBusy } from '@material-ui/icons';
import { getFilterField, getFilterUpdateMethods, getSelectFilterField } from '../../../../components/table/filter/filterUtils';
import * as Actions from '../../../redux/actions/user/actions';
import moment from 'moment';
import StatusLabel from '../../../../components/common/StatusLabel';

// Table column rendering information.
const columns = [
  {
    id: 'organisationName',
    label: 'Organisation',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'contactName',
    label: 'Contact Name',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'contactEmail',
    label: 'Email',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'expiryDate',
    label: 'ExpiryDate',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      const now = moment();
      const expiryDate = moment.unix(value.expiryDate);
      const error = now.isAfter(expiryDate);
      const warning = now.isBefore(expiryDate) && moment().add(7, 'days').isAfter(expiryDate);
      return <StatusLabel text={expiryDate.format('LLL')} error={error} warning={warning} bold={true} />;
    },
  },
  {
    id: 'licenseType',
    label: 'License Type',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'licenseStatus',
    label: 'Status',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'disable',
    label: 'Expire License',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            disabled={value.licenseStatus === 'EXPIRED'}
            onClick={() => dispatch(LicenseActions.expire(value))}
          >
            <EventBusy />
          </IconButton>
        </>
      );
    },
  },
];

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getSelectFilterField(
      'licenseStatus',
      'Status',
      [
        { value: 'ACTIVE', label: 'Active' },
        { value: 'EXPIRED', label: 'Expired' },
      ],
      filterUpdateMethods
    ),
    getFilterField('organisationName', 'Organisation Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('contactEmail', 'Email', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('expiryDate', 'Expiry Date', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
  ];
}

export default function LicenseList() {
  const dispatch = useDispatch();
  const licenseList = useSelector((state) => state.licenseReducer.entityList);
  const lastActionType = useSelector((state) => state.licenseReducer.lastActionType);
  const processing = useSelector((state) => state.licenseReducer.processing);
  const page = useSelector((state) => state.licenseReducer.page);
  const error = useSelector((state) => state.licenseReducer.error);
  const [state, setState] = useState({
    licenseStatus: '',
    organisationName: '',
    contactEmail: '',
    expiryDate: null,
  });

  const filters = getFilterFieldsForScreen(state, setState);

  useEffect(() => {
    if (state.licenseStatus || state.organisationName || state.contactEmail || state.expiryDate) {
      dispatch(LicenseActions.findByFilter(0, 25, null, state.licenseStatus, state.organisationName, state.contactEmail, state.expiryDate));
    } else {
      dispatch(LicenseActions.fetch(0, 25, null));
    }
  }, [dispatch, state]);

  React.useEffect(() => {
    dispatch(Actions.selectItem(null));
  }, [dispatch]);

  return (
    <AutoTable
      title="Smartcheck Licenses"
      subheader={'Manage Licenses for Smartcheck'}
      handleRefreshReport={() => dispatch(LicenseActions.fetch(0, 25, null))}
      selectItem={(item) => dispatch(LicenseActions.select(item))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(LicenseActions.fetch(newPage, pageSize, null))}
      processing={processing}
      page={page}
      error={error}
      clearError={() => dispatch(LicenseActions.clearError())}
      columns={columns}
      prefix="LICENSE"
      buttonName="New License"
      createNewURL="/licenses/new"
      detailURL="/licenses/view/{id}"
      idColumn={'id'}
      data={licenseList}
      lastActionType={lastActionType}
      filters={filters}
      clearableFilter={true}
    />
  );
}
