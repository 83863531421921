import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Swal from 'sweetalert2';
import Objects from '../../../../support/Objects';
import { clearAccessState } from '../access/actions';

// Actions
export const CHECK_LOGIN_STATUS_IN_PROGRESS = 'CHECK_LOGIN_STATUS_IN_PROGRESS';
export const CHECK_LOGIN_STATUS_SUCCESS = 'CHECK_LOGIN_STATUS_SUCCESS';
export const CHECK_LOGIN_STATUS_FAILURE = 'CHECK_LOGIN_STATUS_FAILURE';

export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const LOGIN_IN_SUCCESS = 'LOGIN_IN_SUCCESS';
export const LOGIN_IN_CHALLENGE_REQUIRED = 'LOGIN_IN_CHALLENGE_REQUIRED';
export const LOGIN_IN_FAILURE = 'LOGIN_IN_FAILURE';

export const REQUEST_FORGOT_PASSWORD_CODE_SUCCESS = 'REQUEST_FORGOT_PASSWORD_CODE_SUCCESS';
export const REQUEST_FORGOT_PASSWORD_CODE_FAILURE = 'REQUEST_FORGOT_PASSWORD_CODE_FAILURE';

export const SUBMIT_FORGOT_PASSWORD_CODE_SUCCESS = 'SUBMIT_FORGOT_PASSWORD_CODE_SUCCESS';
export const SUBMIT_FORGOT_PASSWORD_CODE_FAILURE = 'SUBMIT_FORGOT_PASSWORD_CODE_FAILURE';

export const UPDATE_AUTHENTICATION_STATE = 'UPDATE_AUTHENTICATION_STATE';

export const LOGOUT_ACCOUNT = 'LOGOUT_ACCOUNT';

// Private variables
let registered = false;

/**
 * Bind up a listener to handle federated login event dispatched after logging in via facebook
 */
export const setupLoginListener = () => (dispatch) => {
  if (!registered) {
    const onEventWrapper = {};

    onEventWrapper.onHubCapsule = (capsule) => {
      console.log('setupLoginListener() - event: ', capsule);
      const { payload } = capsule;
      switch (payload.event) {
        case 'cognitoHostedUI':
          console.log('Event (' + payload.event + ') received - login federated login completed successfully');
          dispatch({ type: CHECK_LOGIN_STATUS_SUCCESS, payload: { nextState: 'signedIn', nextData: payload.data } });
          break;
        case 'signIn':
        case 'signUp':
        case 'signOut':
        case 'signIn_failure':
        case 'configured':
        default:
          console.log('Event (' + payload.event + ') received');
          break;
      }
    };

    Hub.listen('auth', onEventWrapper, 'authenticationActionListener');
    registered = true;
  }
};

export const checkLoginStatus = () => async (dispatch) => {
  dispatch(setupLoginListener());

  dispatch({ type: CHECK_LOGIN_STATUS_IN_PROGRESS });

  try {
    const response = await Auth.currentAuthenticatedUser();
    dispatch({ type: CHECK_LOGIN_STATUS_SUCCESS, payload: { nextState: 'signedIn', nextData: response } });
  } catch (error) {
    console.log("NOT SIGNED IN: User is not currently signed in - setting initial state to 'signIn'", error);
    let params = new URLSearchParams(window.location.search);
    let next = params.has('state') ? params.get('state') : 'signIn';
    dispatch({ type: CHECK_LOGIN_STATUS_FAILURE, payload: { nextState: next, nextData: null } });
  }
};

export const requestLogin = (email, password) => async (dispatch) => {
  dispatch({ type: LOGIN_IN_PROGRESS });

  const preparedUsername = email.toLowerCase().trim();
  try {
    const response = await Auth.signIn(preparedUsername, password);
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch({
        type: LOGIN_IN_CHALLENGE_REQUIRED,
        payload: {
          nextState: 'newPasswordRequired',
          nextData: { user: response, email: preparedUsername, password: password },
        },
      });
    } else {
      dispatch({
        type: LOGIN_IN_SUCCESS,
        payload: {
          nextState: 'signedIn',
          nextData: response,
        },
      });
    }
  } catch (error) {
    if (error.code === 'UserNotConfirmedException') {
      try {
        console.warn('User account has been created but is not yet verified');
        const resendResponse = await Auth.resendSignUp(preparedUsername);
        dispatch({
          type: LOGIN_IN_CHALLENGE_REQUIRED,
          payload: {
            nextState: 'confirmSignUp',
            nextData: { ...resendResponse, email, password },
          },
        });
      } catch (le) {
        dispatch({ type: LOGIN_IN_FAILURE, payload: error });
      }
    } else if (error.code === 'PasswordResetRequiredException') {
      dispatch({
        type: LOGIN_IN_CHALLENGE_REQUIRED,
        payload: {
          nextState: 'forgotPassword',
          nextData: { email: email },
        },
      });
    } else {
      dispatch({ type: LOGIN_IN_FAILURE, payload: error });
    }
  }
};

export const requestSignup = (email, password, phone, firstName, lastName) => async (dispatch) => {
  const preparedUsername = email.toLowerCase().trim();

  Auth.signUp({
    username: preparedUsername,
    password: password,
    attributes: {
      email: preparedUsername,
      phone_number: phone,
      given_name: firstName,
      family_name: lastName,
    },
  })
    .then((response) => {
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch({
          type: LOGIN_IN_CHALLENGE_REQUIRED,
          payload: {
            nextState: 'newPasswordRequired',
            nextData: { user: response, email: email, password: password },
          },
        });
      } else {
        dispatch({
          type: LOGIN_IN_CHALLENGE_REQUIRED,
          payload: {
            nextState: 'confirmSignUp',
            nextData: { ...response, email, phone, firstName, lastName },
          },
        });
      }
    })
    .catch((error) => {
      if (error.code === 'InvalidPasswordException') {
        dispatch({
          type: LOGIN_IN_FAILURE,
          payload: new Error('Password must contain 8 characters, a mix of numbers, letters and symbol characters'),
        });
      } else {
        dispatch({ type: LOGIN_IN_FAILURE, payload: error });
      }
    });
};

export const confirmSignUp = (username, code) => async (dispatch) => {
  if (username && code) {
    const preparedUsername = username.toLowerCase().trim();
    const preparedCode = code.trim();
    try {
      const response = await Auth.confirmSignUp(preparedUsername, preparedCode);
      console.log('CONFIRM SIGN UP - Completed with response: ', response);
      Swal.fire('Sign up Completed', 'Your account has been created successfully. You can now login with your account', 'success').then(
        () => {
          dispatch(updateAuthenticationState('signIn'));
        }
      );
    } catch (error) {
      await Swal.fire('Code Validation Error', 'Your validation code was incorrect. Please try again', 'error');
    }
  } else {
    Swal.fire('Username Not Found', 'Please login and try again', 'error').then(() => {
      window.location.replace('/login');
    });
  }
};

export const confirmSignUpResendCode = (username) => async () => {
  if (!username) {
    await Swal.fire('Username Not Found', 'Please login and try again', 'error');
    window.location.replace('/login');
    return;
  }

  const preparedUsername = username.toLowerCase().trim();
  try {
    await Auth.resendSignUp(preparedUsername);
    await Swal.fire('Code Resent Successfully', 'Please check your email for the new code', 'success');
  } catch (error) {
    await Swal.fire('Code Resent Failed', error.message, 'error');
  }
};

export const completeNewPassword = (user, newPassword, confirmPassword, firstName, lastName) => async (dispatch) => {
  if (newPassword !== confirmPassword) {
    dispatch({ type: LOGIN_IN_FAILURE, payload: new Error('New password does not match') });
  } else {
    const attributes = {
      given_name: firstName,
      family_name: lastName,
    };

    try {
      const response = await Auth.completeNewPassword(user, newPassword, attributes);
      dispatch({ type: LOGIN_IN_SUCCESS, payload: { nextState: 'signedIn', nextData: response } });
    } catch (error) {
      dispatch({ type: LOGIN_IN_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  }
};

export const forgotPassword = (username, resend) => async (dispatch) => {
  const preparedUsername = username.toLowerCase().trim();
  try {
    const response = await Auth.forgotPassword(preparedUsername);
    console.log('FORGOT PASSWORD - Reset requested with response: ', response);
    dispatch({ type: REQUEST_FORGOT_PASSWORD_CODE_SUCCESS, payload: preparedUsername });
    if (resend) {
      await Swal.fire('Code Resent Successfully', 'Please check your email for the new code', 'success');
    }
  } catch (error) {
    dispatch({ type: REQUEST_FORGOT_PASSWORD_CODE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const submitForgotPasswordCode = (username, code, newPassword, confirmNewPassword) => async (dispatch) => {
  if (!username) {
    return dispatch({ type: SUBMIT_FORGOT_PASSWORD_CODE_FAILURE, payload: new Error('Username not provided') });
  }

  if (!username) {
    return dispatch({ type: SUBMIT_FORGOT_PASSWORD_CODE_FAILURE, payload: new Error('Code not provided') });
  }

  if (newPassword !== confirmNewPassword) {
    return dispatch({ type: SUBMIT_FORGOT_PASSWORD_CODE_FAILURE, payload: new Error('Passwords does not match') });
  }

  const preparedUsername = username.toLowerCase().trim();
  const preparedCode = code.trim();
  try {
    const response = await Auth.forgotPasswordSubmit(preparedUsername, preparedCode, newPassword);
    dispatch({ type: SUBMIT_FORGOT_PASSWORD_CODE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SUBMIT_FORGOT_PASSWORD_CODE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await Auth.signOut();
    console.log('USER LOGOUT - Completed successfully with response: ', response);
    window.history.pushState({}, 'Login', '/login');
    dispatch({ type: LOGOUT_ACCOUNT, payload: 'signIn' });
    dispatch(clearAccessState());
  } catch (error) {
    console.error('USER LOGOUT - Failed with error: ', error);
  }
};

export const updateAuthenticationState = (state, data) => async (dispatch) => {
  console.log('Updating authentication status: ', state, data);
  dispatch({
    type: UPDATE_AUTHENTICATION_STATE,
    payload: {
      state: state,
      data: data,
    },
  });
};
