import * as Actions from '../../../actions/app/theme/actions';

const initial = {
  lastActionType: 'NONE',
  data: null,
  error: null,
  selectedItem: null,
  processing: false,
};

export default function appThemeReducer(state = initial, action) {
  switch (action.type) {
    case Actions.APP_THEME_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload.item,
      };
    case Actions.APP_THEME_CLEAR_LAST_ACTION:
      return {
        ...state,
        lastActionType: 'NONE',
        data: null,
        processing: false,
      };
    case Actions.APP_THEME_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload,
        error: null,
      };
    case Actions.APP_THEME_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.FIREBASE_APP_THEME_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
        error: null,
      };
    case Actions.FIREBASE_APP_THEME_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.APP_THEME_UPDATE_IN_PROGRESS:
      return {
        ...state,
        processing: true,
      };
    case Actions.APP_THEME_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        data: null,
        processing: false,
      };
    case Actions.APP_THEME_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
