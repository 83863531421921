import UserRole from './UserRole';

export default class UserRoleHelper {
  /**
   * Helper method converts a role name to something friendly
   * @param role UserRole
   * @returns Friendly display name
   */
  static toFriendlyName(role) {
    if (UserRole.ROLE_SUPER === role) {
      return 'Super User';
    } else if (UserRole.ROLE_CORP_ADMIN === role) {
      return 'Corporation Admin';
    } else if (UserRole.ROLE_SITE_ADMIN === role) {
      return 'Site Admin';
    } else if (UserRole.ROLE_SITE_USER === role) {
      return 'Site User';
    } else {
      return role;
    }
  }

  /**
   * Check whether a user has the super profile
   * @param profile User profile matching the logged in user
   * @returns {boolean}  True if a the user has the SUPER role otherwise false
   */
  static isRoleSuper(profile) {
    return UserRoleHelper.hasMatchingRole(profile, UserRole.ROLE_SUPER);
  }

  /**
   * Helper match a given role against a list of roles provided
   * @param role UserRole to match
   * @param candidates UserRole array
   * @returns {boolean} True of the role is in the list, false otherwise
   */
  static roleMatchesList(role, candidates) {
    for (let candidate of candidates) {
      if (role === candidate) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks whether a profile has access to a specific role. This method evaluates an inheritance chain
   * starting from SUPER downward.
   *
   * IMPORTANT: This method should be used to evaluate role base view changes in the UI
   *
   * @param profile User profile matching the logged in user
   * @param role Name of the role to check
   * @returns {boolean} True if a role match is found, false otherwise
   */
  static hasRole(profile, role) {
    if (UserRole.ROLE_SUPER === role) {
      return UserRoleHelper.hasMatchingRoles(profile, [UserRole.ROLE_SUPER]);
    }

    if (UserRole.ROLE_CORP_ADMIN === role) {
      return UserRoleHelper.hasMatchingRoles(profile, [UserRole.ROLE_SUPER, UserRole.ROLE_CORP_ADMIN]);
    }

    if (UserRole.ROLE_SITE_ADMIN === role) {
      return UserRoleHelper.hasMatchingRoles(profile, [UserRole.ROLE_SUPER, UserRole.ROLE_CORP_ADMIN, UserRole.ROLE_SITE_ADMIN]);
    }

    if (UserRole.ROLE_SITE_USER === role) {
      return UserRoleHelper.hasMatchingRoles(profile, [
        UserRole.ROLE_SUPER,
        UserRole.ROLE_CORP_ADMIN,
        UserRole.ROLE_SITE_ADMIN,
        UserRole.ROLE_SITE_USER,
      ]);
    }

    return false;
  }

  /**
   * Checks whether a profile has the role provided
   * @param profile User profile matching the logged in user
   * @param role Name of the role to check
   * @returns {boolean} True if a role match is found, false otherwise
   */
  static hasMatchingRole(profile, role) {
    if (profile && profile.role) {
      if (profile.role === role) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks whether a profile has one of the roles provided
   * @param profile User profile matching the logged in user
   * @param roles List of roles the caller wants to check
   * @returns {boolean} True if a role match is found, false otherwise
   */
  static hasMatchingRoles(profile, roles) {
    return roles.filter((r) => UserRoleHelper.hasMatchingRole(profile, r)).length > 0;
  }

  static returnCurrentAndLesserRoles(role) {
    if (UserRole.ROLE_SUPER === role) {
      return [UserRole.ROLE_SUPER, UserRole.ROLE_CORP_ADMIN, UserRole.ROLE_SITE_ADMIN, UserRole.ROLE_SITE_USER];
    }
    if (UserRole.ROLE_CORP_ADMIN === role) {
      return [UserRole.ROLE_CORP_ADMIN, UserRole.ROLE_SITE_ADMIN, UserRole.ROLE_SITE_USER];
    }
    if (UserRole.ROLE_SITE_ADMIN === role) {
      return [UserRole.ROLE_SITE_ADMIN, UserRole.ROLE_SITE_USER];
    }
    if (UserRole.ROLE_SITE_USER === role) {
      return [UserRole.ROLE_SITE_USER];
    }
  }
}
