import * as FirestoreService from '../../../../components/firebase/firebase';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SITE_MESSAGE_FETCH_SUCCESS = 'SITE_MESSAGE_FETCH_SUCCESS';
export const SITE_MESSAGE_FETCH_FAILURE = 'SITE_MESSAGE_FETCH_FAILURE';
export const SITE_MESSAGE_FETCH_COLLECTION_NOT_FOUND = 'SITE_MESSAGE_FETCH_COLLECTION_NOT_FOUND';

export const SITE_MESSAGE_CREATE_SUCCESS = 'SITE_MESSAGE_CREATE_SUCCESS';
export const SITE_MESSAGE_CREATE_FAILURE = 'SITE_MESSAGE_CREATE_FAILURE';

export const SITE_MESSAGE_SEND_PUSH_NOTIFICATION_SUCCESS = 'SITE_MESSAGE_SEND_PUSH_NOTIFICATION_SUCCESS';
export const SITE_MESSAGE_SEND_PUSH_NOTIFICATION_FAILURE = 'SITE_MESSAGE_SEND_PUSH_NOTIFICATION_FAILURE';

export const SITE_MESSAGE_COLLECTION_CREATE_SUCCESS = 'SITE_MESSAGE_COLLECTION_CREATE_SUCCESS';
export const SITE_MESSAGE_COLLECTION_CREATE_FAILURE = 'SITE_MESSAGE_COLLECTION_CREATE_FAILURE';

export const SITE_MESSAGE_FIREBASE_SIGN_IN_SUCCESS = 'SITE_MESSAGE_FIREBASE_SIGN_IN_SUCCESS';
export const SITE_MESSAGE_FIREBASE_SIGN_IN_FAILURE = 'SITE_MESSAGE_FIREBASE_SIGN_IN_FAILURE';

export const SITE_MESSAGE_CHANGE_EVENT_SUCCESS = 'SITE_MESSAGE_CHANGE_EVENT_SUCCESS';
export const SITE_MESSAGE_CHANGE_EVENT_FAILURE = 'SITE_MESSAGE_CHANGE_EVENT_FAILURE';

export const SITE_MESSAGE_CHANGE_ADDED_EVENT_SUCCESS = 'SITE_MESSAGE_CHANGE_ADDED_EVENT_SUCCESS';
export const SITE_MESSAGE_CHANGE_ADDED_EVENT_FAILURE = 'SITE_MESSAGE_CHANGE_ADDED_EVENT_FAILURE';

export const SITE_MESSAGE_DOCUMENT_DELETE_SUCCESS = 'SITE_MESSAGE_DOCUMENT_DELETE_SUCCESS';
export const SITE_MESSAGE_DOCUMENT_DELETE_FAILURE = 'SITE_MESSAGE_DOCUMENT_DELETE_FAILURE';

export const SITE_MESSAGE_CLEAR_ERROR = 'SITE_MESSAGE_CLEAR_ERROR';
export const SITE_MESSAGE_SELECT_CHAT = 'SITE_MESSAGE_SELECT_CHAT';
export const SITE_MESSAGE_HIDE_ALERT = 'SITE_MESSAGE_HIDE_ALERT';

export const SITE_MESSAGE_COMPLETE_SUBSCRIPTION = 'SITE_MESSAGE_COMPLETE_SUBSCRIPTION';
export const SITE_MESSAGE_UNMOUNT_SUBSCRIPTION = 'SITE_MESSAGE_UNMOUNT_SUBSCRIPTION';

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_MESSAGE_CLEAR_ERROR });
};

export const collectionCreated = (chatCollectionId) => async (dispatch) => {
  console.log('New site collection created: ', chatCollectionId);
  dispatch({ type: SITE_MESSAGE_COLLECTION_CREATE_SUCCESS, payload: chatCollectionId });
};

export const collectionCreateFailed = (error) => async (dispatch) => {
  console.log('Error occurred while creating chat collection in firestore ', error);
  dispatch({ type: SITE_MESSAGE_COLLECTION_CREATE_FAILURE, payload: error });
};

export const fetch = (chatCollectionId) => async (dispatch) => {
  if (chatCollectionId) {
    console.debug('Requesting thread list for site collection with id ', chatCollectionId);
    FirestoreService.getChatThreadList(chatCollectionId)
      .then((response) => {
        console.debug('Response data', response);
        if (response.size >= 1) {
          dispatch({ type: SITE_MESSAGE_FETCH_SUCCESS, payload: response });
        } else {
          console.warn(`Collection ${chatCollectionId} was not found in Firestore - creating...`);
          dispatch({ type: SITE_MESSAGE_FETCH_COLLECTION_NOT_FOUND, payload: response });
          FirestoreService.createSiteCollection(chatCollectionId)
            .then(() => {
              dispatch(collectionCreated(chatCollectionId));
            })
            .catch((error) => {
              dispatch(collectionCreateFailed(error));
            });
        }
      })
      .catch((error) => {
        dispatch({ type: SITE_MESSAGE_FETCH_FAILURE, payload: error });
      });
  }
};

export const addMessage = (message, site, docId) => async (dispatch) => {
  console.log('Requested adding a message to thread ', docId);
  console.log('For site ', site);

  FirestoreService.addMessageToChatThread(message, site, docId)
    .then((response) => {
      dispatch({ type: SITE_MESSAGE_CREATE_SUCCESS, payload: response });
    })
    .catch((error) => {
      dispatch({ type: SITE_MESSAGE_CREATE_FAILURE, payload: error });
    });
};

export const updateStoredMessagesOnEvent = (updatedDoc, error) => async (dispatch) => {
  console.debug('New snapshot received ', updatedDoc);
  if (!error) {
    dispatch({ type: SITE_MESSAGE_CHANGE_EVENT_SUCCESS, payload: updatedDoc });
  } else {
    console.log('An error occurred ', error);
    dispatch({ type: SITE_MESSAGE_CHANGE_EVENT_FAILURE, payload: error });
  }
};

export const updateStoredChatsOnEvent = (collectionId, docId) => async (dispatch, getStore) => {
  try {
    const initialSubscriptionCompleted = getStore().chatReducer.initialSubscriptionCompleted;
    if (!initialSubscriptionCompleted) {
      return;
    }
    const newChat = await FirestoreService.getChat(collectionId, docId);
    dispatch({ type: SITE_MESSAGE_CHANGE_ADDED_EVENT_SUCCESS, payload: newChat.data() });
  } catch (e) {
    dispatch({ type: SITE_MESSAGE_CHANGE_ADDED_EVENT_FAILURE, payload: e });
  }
};

export const selectChat = (message) => async (dispatch, getState) => {
  const collectionId = message.collectionId || getState().accessReducer.selectedSite.chatCollectionId;
  const docId = message.guestUsername || message.recipient;

  FirestoreService.setUnreadMessagesToZero(collectionId, docId)
    .then(() => {
      console.log('The new selected item is ', message);
      dispatch({ type: SITE_MESSAGE_SELECT_CHAT, payload: message });
    })
    .catch((error) => {
      console.log('Failed to update the unread messages counter for selected chat ', error);
      dispatch({ type: SITE_MESSAGE_SELECT_CHAT, payload: message });
    });
};

export const hideAlert = () => async (dispatch) => {
  setTimeout(() => dispatch({ type: SITE_MESSAGE_HIDE_ALERT }), 10000);
};

export const completeInitialSubscription = () => async (dispatch) => {
  setTimeout(() => dispatch({ type: SITE_MESSAGE_COMPLETE_SUBSCRIPTION }), 20000);
};

export const unmountSubscription = () => async (dispatch) => {
  dispatch({ type: SITE_MESSAGE_UNMOUNT_SUBSCRIPTION });
};

export const sendPushNotification = (request) => async (dispatch) => {
  try {
    await API.post('PrivateAPI', '/api/private/portal/notification/push', { body: request });
    dispatch({ type: SITE_MESSAGE_SEND_PUSH_NOTIFICATION_SUCCESS });
  } catch (error) {
    console.warn('push notification could not be sent to user because of error - ', error);
    dispatch({
      type: SITE_MESSAGE_SEND_PUSH_NOTIFICATION_FAILURE,
      payload: new Error(Objects.getErrorFromResponse(error)),
    });
  }
};

export const deleteDocument = (docId, error) => async (dispatch) => {
  if (error) {
    dispatch({ type: SITE_MESSAGE_DOCUMENT_DELETE_FAILURE });
  } else {
    dispatch({ type: SITE_MESSAGE_DOCUMENT_DELETE_SUCCESS, payload: docId });
  }
};

export const signInAnonymously = () => async (dispatch) => {
  try {
    await FirestoreService.signInAnonymously();
    dispatch({ type: SITE_MESSAGE_FIREBASE_SIGN_IN_SUCCESS });
  } catch (e) {
    switch (e.code) {
      case 'auth/operation-not-allowed':
        console.log('Enable anonymous in your firebase console.');
        break;
      default:
        console.error(e);
        break;
    }
    dispatch({ type: SITE_MESSAGE_FIREBASE_SIGN_IN_FAILURE, payload: e });
  }
};
