import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FoodMenuActions from '../../../../redux/actions/inRoom/ordering/menu/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { AppBar, Box, Card, CardContent, Collapse, Grid, IconButton, Paper, Tab, Tabs, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { SelectTrueFalseOptions } from '../../../../../support/FlowStates';
import FormSelect from '../../../../../components/form/FormSelect';
import { FoodMenuTypes } from '../../../../../support/FoodMenuTypes';
import FoodMenuScheduleList from './FoodMenuScheduleList';
import Objects from '../../../../../support/Objects';
import TabPanel from '../../../control/TabPanel';
import FoodMenuMapping from './FoodMenuMapping';
import toBase64 from '../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../components/file/FileImageSelector';

export default function FoodMenuEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodMenuReducer);
  const [open, setOpen] = useState(true);
  const [name, setName] = useState(selectedItem?.name || '');
  const [type, setType] = useState(selectedItem?.type || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [sequence, setSequence] = useState(selectedItem?.sequence || 0);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [image, setImage] = useState(selectedItem?.menuImage || '');
  const [imageChanged, setImageChanged] = useState(false);
  const [imageBase64, setImageBase64] = useState(null);

  const tab = new URLSearchParams(location.search).get('tab');
  const [selectedTab, setSelectedTab] = useState(tab ? parseInt(tab) : 0);
  const tabList = [{ label: 'Schedule' }, { label: 'Mapping' }];

  useEffect(() => {
    dispatch(FoodMenuActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (image) {
      toBase64(image).then((base64) => {
        setImageBase64(base64);
      });
    }
  }, [image]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_MENU_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_MENU_ADD_SUCCESS' ||
      lastActionType === 'FOOD_MENU_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    history.replace(`/food/menu/view/${selectedItem?.id}?tab=${value}`);
  };

  const handleImageSelect = (file) => {
    setImage(file);
    setImageChanged(true);
  };

  const handleMenuDisable = useCallback(
    (nextValue) => {
      setEnabled(nextValue);

      // if disabling and the sequence is not defined move to the bottom
      if (!nextValue) {
        if (!sequence || !sequence === 0) {
          setSequence(30);
        }
      }
    },
    [sequence]
  );

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      name: name,
      type: type,
      description: description,
      menuImage: imageBase64,
      menuImageChanged: imageChanged,
      enabled: enabled,
      sequence: sequence,
    };

    if (selectedItem) {
      dispatch(FoodMenuActions.update(payload, `/api/private/portal/food/menu/${selectedItem.id}`, false));
    } else {
      dispatch(FoodMenuActions.add(payload, { vendorId: vendorId }));
    }
  };
  const isFormReady = !Objects.isNullOrEmpty(name) && !Objects.isNullOrEmpty(type) && !Objects.isNullOrEmpty(enabled);

  return (
    <Card>
      <Collapse in={open} timeout="auto">
        <CardHeaderWithControls
          header={selectedItem ? 'Update Food Menu' : 'Create Food Menu'}
          subheader={'A Food Menu consists of one or many Food Items'}
          buttonName={selectedItem ? 'Update' : 'Create'}
          onClick={handleSubmit}
          disabled={!isFormReady || processing}
          deleteDisabled={!selectedItem?.id}
          onDelete={() => dispatch(FoodMenuActions.delete(selectedItem, `/api/private/portal/food/menu/${selectedItem.id}`))}
          backLink={`/food/vendor/view/${vendorId}?tab=1`}
        />
        <CardContent>
          {error && (
            <Alert severity="error" variant={'filled'} className={'mb-2'}>
              {`Unable to add food menu - ${error.message}`}
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormField label={'Menu Name'} value={name} setValue={setName} required />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormField label={'Description'} value={description} setValue={setDescription} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormSelect label={'Menu Type'} value={type} setValue={setType} options={FoodMenuTypes} required />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormField type={'number'} label={'Sequence'} value={sequence} setValue={setSequence} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormSelect label={'Enabled'} value={enabled} setValue={handleMenuDisable} options={SelectTrueFalseOptions} required />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={0}>
                <FileImageSelector
                  sourceItem={selectedItem}
                  sourceItemProperty={'menuImage'}
                  displayName={'Image'}
                  onSelect={handleImageSelect}
                  imageCleared={(value) => {
                    if (value) {
                      setImageBase64(null);
                      setImage(null);
                    }
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      {selectedItem && (
        <>
          <Box
            style={{
              backgroundColor: theme.palette.grays[10],
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(!open)}
          >
            <IconButton
              size={'small'}
              style={{
                maxHeight: '15px',
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Paper>
            <AppBar position="static">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                {selectedItem && tabList.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
              </Tabs>
            </AppBar>
            <div className="content-scrollable-no-padding">
              <TabPanel value={selectedTab} index={0}>
                <FoodMenuScheduleList />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <FoodMenuMapping />
              </TabPanel>
            </div>
          </Paper>
        </>
      )}
    </Card>
  );
}
