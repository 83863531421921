import actionCreator from '../../support/actionCreator';

export const SPECIAL_REQUEST_REDIRECT_IN_PROGRESS = 'SPECIAL_REQUEST_REDIRECT_IN_PROGRESS';
export const SPECIAL_REQUEST_REDIRECT_SUCCESS = 'SPECIAL_REQUEST_REDIRECT_SUCCESS';
export const SPECIAL_REQUEST_REDIRECT_FAILURE = 'SPECIAL_REQUEST_REDIRECT_FAILURE';

const SpecialRequestRedirectActions = {
  ...actionCreator.createActions('Notification Contact', 'SPECIAL_REQUEST_REDIRECT', '/api/private/specialRequestRedirect'),
};
export default SpecialRequestRedirectActions;
