import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import RequestSubCategoryActions from '../../../../redux/actions/specialRequestSubCategory/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';

export default function RequestSubCategoryEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastActionType = useSelector((state) => state.subCategoryReducer.lastActionType);
  const error = useSelector((state) => state.subCategoryReducer.error);
  const data = useSelector((state) => state.subCategoryReducer.entityList);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedCategory = useSelector((state) => state.specialRequestCategoryReducer.selectedItem);
  const selectedSiteId = selectedSite?.id;
  const [list, setList] = React.useState(data);

  useEffect(() => {
    dispatch(RequestSubCategoryActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'SPECIAL_REQUEST_SUB_UPDATE_SUCCESS' || lastActionType === 'SPECIAL_REQUEST_SUB_ADD_SUCCESS') {
      history.push(`/site/specialRequestCategory/view/${selectedCategory.id}`);
    }
  }, [lastActionType, history, selectedCategory]);

  const handleSubmit = async () => {
    const payload = {
      siteId: selectedSiteId,
      category: selectedCategory.category,
      subCategoryDetails: [...list],
      categoryId: selectedCategory.id,
    };
    dispatch(RequestSubCategoryActions.add(payload, { categoryId: selectedCategory.id }));
  };

  const handleChange = async (index, event, name) => {
    const rowsInput = [...list];
    if (name === 'enabled') {
      rowsInput[index][name] = event.target.checked;
    } else if (name === 'subOrder') {
      rowsInput[index][name] = parseInt(event);
    } else if (name === 'deliveryTimeEnabled') {
      rowsInput[index][name] = event.target.checked;
    } else {
      rowsInput[index][name] = event;
    }
    setList(rowsInput);
  };
  const handleAddNewRow = () => {
    const rowsInput = {
      id: null,
      subCategory: '',
      enabled: true,
      category: selectedCategory.category,
      order: null,
      deliveryTimeEnabled: false,
    };
    setList([...list, rowsInput]);
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={`${selectedCategory?.category} - Categories`}
        subheader="Sub categories allow guests to make specific requests to topics defined by you."
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={list.length === 0}
        secondaryButtonName={'Add New'}
        secondaryButtonAction={handleAddNewRow}
        secondaryButtonIcon={<AddRounded />}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/save sub category data - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sub Category</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Delivery Time Enabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <FormField value={row.subCategory} setValue={(event) => handleChange(index, event, 'subCategory')} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FormField value={row.subOrder} type={'number'} setValue={(event) => handleChange(index, event, 'subOrder')} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Switch checked={row.enabled} onChange={(event) => handleChange(index, event, 'enabled')} color={'primary'} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Switch
                      checked={row.deliveryTimeEnabled}
                      onChange={(event) => handleChange(index, event, 'deliveryTimeEnabled')}
                      color={'primary'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </CardContent>
    </Card>
  );
}
