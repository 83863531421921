import { TextField } from '@material-ui/core';
import React from 'react';

export default function FormField({
  label,
  placeholder,
  value,
  setValue,
  type = 'text',
  error = false,
  disabled = false,
  helperText,
  step,
  style,
  margin = 'dense',
  reactInputProps,
  multiline,
  maxRows,
  ...props
}) {
  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  return (
    <TextField
      {...props}
      fullWidth
      id={id}
      label={label}
      placeholder={placeholder || label}
      type={type}
      variant={'outlined'}
      disabled={disabled}
      margin={margin}
      step={step}
      onChange={(event) => setValue(event.target.value)}
      error={error}
      value={value}
      helperText={helperText}
      InputProps={reactInputProps}
      multiline={multiline}
      maxRows={maxRows}
      style={style}
    />
  );
}
