import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../redux/actions/wallet/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../components/form/FormField';
import FormSelect from '../../../../components/form/FormSelect';
import PlatformType, { PlatformTypeOptions } from '../../../../constants/PlatformType';
import toBase64 from '../../../../support/toBase64Converter';
import AppleWallet from './AppleWallet';
import AndroidWallet from './AndroidWallet';
import { useHistory } from 'react-router-dom';

export default function WalletDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedItem = useSelector((state) => state.walletReducer.selectedItem);
  const processing = useSelector((state) => state.walletReducer.processing);
  const error = useSelector((state) => state.walletReducer.error);
  const lastActionType = useSelector((state) => state.walletReducer.lastActionType);

  const [id] = React.useState(selectedItem?.id || null);
  const [platform, setPlatform] = React.useState(selectedItem?.platform || PlatformType.IOS);
  const [name, setName] = React.useState(selectedItem?.name || null);
  const [value, setValue] = React.useState({});

  useEffect(() => {
    if (
      lastActionType === 'WALLET_DELETE_SUCCESS' ||
      lastActionType === 'WALLET_ADD_SUCCESS' ||
      lastActionType === 'WALLET_UPDATE_SUCCESS'
    ) {
      history.push('/wallet');
    }
  }, [lastActionType, dispatch, history]);

  const handleAddOrUpdate = async () => {
    const applePassCertificate = value['applePassCertificate'];
    const appleWwdrCertificate = value['appleWwdrCertificate'];
    const androidWalletKey = value['androidWalletKey'];
    const applePassCertificateFile = applePassCertificate instanceof File && (await toBase64(applePassCertificate));
    const appleWwdrCertificateFile = appleWwdrCertificate instanceof File && (await toBase64(appleWwdrCertificate));
    const androidWalletKeyFile = androidWalletKey instanceof File && (await toBase64(androidWalletKey));
    const payload = {
      id,
      name,
      platform,
      applePassTypeIdentifier: value['applePassTypeIdentifier'],
      appleTeamIdentifier: value['appleTeamIdentifier'],
      androidIssuerId: value['androidIssuerId'],
      androidClassId: value['androidClassId'],
      applePassCertificateFile,
      applePassCertificateFileName: applePassCertificate?.name,
      appleWwdrCertificateFile,
      appleWwdrCertificateFileName: appleWwdrCertificate?.name,
      androidWalletKeyFile,
      androidWalletKeyFileName: androidWalletKey?.name,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, '/api/private/portal/wallet'));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Edit Wallet' : 'Create Wallet'}
        subheader={'Please complete the form to update a wallet'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        buttonLoading={processing}
        onClick={handleAddOrUpdate}
        deleteDisabled={!selectedItem}
        backLink={'/wallet'}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />

      <Divider variant={'middle'} />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to create or edit user please try again later. Error message (${error.message})`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Platform'} value={platform} setValue={setPlatform} options={PlatformTypeOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} placeholder="Enter Name" required />
          </Grid>

          {platform === PlatformType.IOS && <AppleWallet setValue={setValue} />}

          {platform === PlatformType.ANDROID && <AndroidWallet setValue={setValue} />}
        </Grid>
      </CardContent>
    </Card>
  );
}
