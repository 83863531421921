import reducerCreator from '../../support/reducerCreator';
import {
  GUEST_ORDERS_FETCH_IN_PROGRESS,
  GUEST_ORDERS_FETCH_SUCCESS,
  GUEST_ORDERS_FETCH_FAILURE,
  GUEST_ORDERS_REPROCESS_IN_PROGRESS,
  GUEST_ORDERS_REPROCESS_SUCCESS,
  GUEST_ORDERS_REPROCESS_FAILURE,
  GUEST_ORDERS_SELECT_BY_ID_IN_PROGRESS,
  GUEST_ORDERS_SELECT_BY_ID_SUCCESS,
  GUEST_ORDERS_SELECT_BY_ID_FAILURE,
  GUEST_ORDERS_DOWNLOAD_RECEIPT_IN_PROGRESS,
  GUEST_ORDERS_DOWNLOAD_RECEIPT_SUCCESS,
  GUEST_ORDERS_DOWNLOAD_RECEIPT_FAILURE,
  GUEST_ORDERS_REFUND_IN_PROGRESS,
  GUEST_ORDERS_REFUND_SUCCESS,
  GUEST_ORDERS_REFUND_FAILURE,
  GUEST_ORDERS_EXPORT_IN_PROGRESS,
  GUEST_ORDERS_EXPORT_SUCCESS,
  GUEST_ORDERS_EXPORT_FAILURE,
} from '../../actions/guestOrders/actions';

const initial = {
  ...reducerCreator.getInitialState(),
  receiptError: false,
};

const base = reducerCreator.createReducer('Guest Orders', 'GUEST_ORDERS');

function guestOrdersReducer(state = initial, action) {
  switch (action.type) {
    case GUEST_ORDERS_FETCH_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: false,
        receiptError: false,
      };
    case GUEST_ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload.content,
        page: {
          number: action.payload.number,
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        },
      };
    case GUEST_ORDERS_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case GUEST_ORDERS_REPROCESS_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: false,
      };
    case GUEST_ORDERS_REPROCESS_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
      };
    case GUEST_ORDERS_REPROCESS_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: true,
      };
    case GUEST_ORDERS_SELECT_BY_ID_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: false,
      };
    case GUEST_ORDERS_SELECT_BY_ID_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        selectedItem: action.payload,
      };
    case GUEST_ORDERS_SELECT_BY_ID_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: true,
      };
    case GUEST_ORDERS_DOWNLOAD_RECEIPT_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        receiptError: false,
      };
    case GUEST_ORDERS_DOWNLOAD_RECEIPT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
      };
    case GUEST_ORDERS_DOWNLOAD_RECEIPT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        receiptError: action.payload,
      };
    case GUEST_ORDERS_REFUND_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: false,
      };
    case GUEST_ORDERS_REFUND_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        entityList: state.entityList.map((e) => (e.id !== action.payload.id ? e : action.payload)),
        selectedItem: action.payload,
        processing: false,
      };
    case GUEST_ORDERS_REFUND_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case GUEST_ORDERS_EXPORT_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: false,
      };
    case GUEST_ORDERS_EXPORT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
      };
    case GUEST_ORDERS_EXPORT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default guestOrdersReducer;
