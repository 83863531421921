import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/appUserReservationCard/actions';
import AutoTable from '../../../../../components/table/AutoTable';

const columns = [
  {
    id: 'reference',
    label: 'Reference ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'reservationType',
    label: 'Reservation Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'arrivalDate',
    label: 'Arrival',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'departureDate',
    label: 'Departure',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'expiry',
    label: 'Card Expiry',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function AppUserReservationCardList() {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const page = useSelector((state) => state.appUserReservationReducer.page);
  const error = useSelector((state) => state.appUserReservationReducer.error);
  const processing = useSelector((state) => state.appUserReservationReducer.processing);
  const lastActionType = useSelector((state) => state.appUserReservationReducer.lastActionType);
  const requests = useSelector((state) => state.appUserReservationReducer.entityList);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 10, { username: selectedItem.username, sort: 'status,asc&sort=arrivalDate,desc' }));
    }
  }, [dispatch, selectedItem]);

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedItem) {
      dispatch(Actions.fetch(newPage, pageSize, { username: selectedItem.username, sort: 'status,asc&sort=arrivalDate,desc' }));
    }
  };

  return (
    <AutoTable
      title="Reservation Card "
      subheader={'Linked trip assigned to this user account'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="APP_USER_RESERVATION_CARD"
      buttonDisabled={true}
      detailURL="/app-user/reservation-card/view"
      data={requests}
      lastActionType={lastActionType}
    />
  );
}
