const FoodOrderStatus = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  VOID: 'VOID',
  FAILED: 'FAILED',
  SCHEDULED: 'SCHEDULED',
  REFUNDED: 'REFUNDED',
  UNKNOWN: 'UNKNOWN',
};

const FoodOrderStatusOptions = [
  { value: FoodOrderStatus.PENDING, label: 'Pending' },
  { value: FoodOrderStatus.PROCESSING, label: 'Processing' },
  { value: FoodOrderStatus.COMPLETED, label: 'Completed' },
  { value: FoodOrderStatus.VOID, label: 'Void' },
  { value: FoodOrderStatus.FAILED, label: 'Failed' },
  { value: FoodOrderStatus.SCHEDULED, label: 'Scheduled' },
  { value: FoodOrderStatus.REFUNDED, label: 'Refunded' },
  { value: FoodOrderStatus.UNKNOWN, label: 'Unknown' },
];

export default FoodOrderStatus;
export { FoodOrderStatusOptions };
