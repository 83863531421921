import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export default function FormBooleanSelect({ label, value, setValue, margin = 'dense', ...props }) {
  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  return (
    <FormControl fullWidth variant={'outlined'} margin={margin} {...props}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select labelId={id} value={value} label={label} onChange={(event) => setValue(event.target.value)}>
        <MenuItem value={true}>YES</MenuItem>
        <MenuItem value={false}>NO</MenuItem>
      </Select>
    </FormControl>
  );
}
