import * as DataTypes from '../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import useFetch from '../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'storeCard',
    label: 'Store Card',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'quickKey',
    label: 'Quick Key',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'darkMode',
    label: 'Dark Mode',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function AppUserSetting() {
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(`/api/private/portal/app-user/setting?userId=${selectedItem.id}`);

  return (
    <AutoTable
      title="Settings"
      subheader={'Profile settings for this user'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="APP_USER_PUSH_TOKEN"
      backHidden={true}
      data={data}
      lastActionType={'APP_USER_PUSH_TOKEN'}
    />
  );
}
