import actionCreator from '../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import Swal from 'sweetalert2';

export const ActivityTimeActions = {
  ...actionCreator.createActions('Activity Time', 'ACTIVITY_SCHEDULE_TIME', '/api/private/portal/activity/schedule{path}'),
  add: (leaf, branch) => async (dispatch) => {
    Objects.notNull(leaf, 'Time cannot be null');

    dispatch({ type: `ACTIVITY_SCHEDULE_TIME_ADD_IN_PROGRESS` });

    try {
      const result = await API.post('PrivateAPI', `/api/private/portal/activity/schedule/${branch.id}/time`, { body: leaf });
      await dispatch({ type: `ACTIVITY_SCHEDULE_TIME_ADD_SUCCESS`, payload: result });
      await dispatch({ type: 'ACTIVITY_SCHEDULE_ADD_CHILD', payload: { branch, leaf: result } });
    } catch (error) {
      console.warn('add() call failed with result: ', error);
      dispatch({ type: `ACTIVITY_SCHEDULE_TIME_ADD_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  disable: (value) => async (dispatch) => {
    const payload = { ...value, enabled: !value.enabled };

    dispatch({ type: `ACTIVITY_SCHEDULE_TIME_UPDATE_IN_PROGRESS` });
    try {
      await API.patch('PrivateAPI', `/api/private/portal/activity/schedule/time/${payload.id}?enabled=${payload.enabled}`, {});
      await dispatch({ type: 'ACTIVITY_SCHEDULE_UPDATE_CHILD', payload: payload });
      await dispatch({ type: 'ACTIVITY_SCHEDULE_TIME_UPDATE_SUCCESS', payload: payload });
      await Swal.fire(`Activity Time Updated`, 'Your changes have been saved successfully', 'success');
    } catch (error) {
      dispatch({
        type: 'ACTIVITY_SCHEDULE_TIME_UPDATE_FAILURE',
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default ActivityTimeActions;
