import { Grid } from '@material-ui/core';
import SiteDashboardWidget from '../detail/widget/SiteDashboardWidget';
import DashboardSection from '../../../../../support/DashboardSection';
import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import Charts from '../detail/Charts';
import Objects from '../../../../../support/Objects';
import { useDispatch, useSelector } from 'react-redux';
import TabPanel from '../../../control/TabPanel';
import ChartCardPair from '../detail/chart/ChartCardPair';
import LivertonAreaChart from '../detail/chart/LivertonAreaChart';
import moment from 'moment/moment';
import * as DashboardActions from '../../../../redux/actions/dashboard/actions';
import LivertonBarChart from '../detail/chart/LivertonBarChart';

export default function GuestServiceTab({ selectedTab, tabIndex, timeframe, selectedSite }) {
  const dispatch = useDispatch();
  const widgetData = useSelector((state) => state.dashboardReducer.guestWidgetData);
  const [requestData, setRequestData] = React.useState([]);
  const [requestsByCategoryData, setRequestsByCategory] = React.useState([]);
  const [topTenSubCatRequests, setTopTenSubCatRequests] = React.useState([]);
  const [tableViewMap, setTableViewMap] = useState({
    requests: false,
    requestsByCategory: false,
    topTenSubCategories: false,
  });

  useEffect(() => {
    const siteId = selectedSite?.id;
    if (!siteId || !timeframe || selectedTab !== tabIndex) {
      return;
    }

    const payload = {
      ...timeframe,
      start: moment(timeframe.start).format('YYYY-MM-DD'),
      end: moment(timeframe.end).format('YYYY-MM-DD'),
    };

    dispatch(DashboardActions.getGuestWidgetData(siteId, payload));

    API.post('PrivateAPI', `/api/private/portal/dashboard/guest/chart/request/timeline?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setRequestData(Charts.prepareChartData(response));
      })
      .catch((error) => {
        setRequestData([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });

    API.post('PrivateAPI', `/api/private/portal/dashboard/guest/chart/requests/category/bar?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setRequestsByCategory(Charts.prepareChartLineData(response));
      })
      .catch((error) => {
        setRequestsByCategory([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });

    API.post('PrivateAPI', `/api/private/portal/dashboard/guest/chart/top10/subCategories/bar?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setTopTenSubCatRequests(Charts.prepareChartLineData(response));
      })
      .catch((error) => {
        setTopTenSubCatRequests([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, timeframe, selectedTab]);

  return (
    <TabPanel value={selectedTab} index={tabIndex} p={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SiteDashboardWidget widgetData={widgetData} site={selectedSite} section={DashboardSection.GUEST_SERVICE} />
        </Grid>

        <ChartCardPair
          charts={[
            {
              title: 'Guest Requests',
              subheader: 'Guest Requests over time',
              tableView: tableViewMap.requests,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, requests: toggleValue }),
              component: (tableView) => <LivertonAreaChart data={requestData} tableView={tableView} columnOverrides={TABLE_LABELS} />,
            },
            {
              title: 'Total Requests by Category',
              subheader: 'Number of requests per category',
              tableView: tableViewMap.requestsByCategory,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, requestsByCategory: toggleValue }),
              component: (tableView) => (
                <LivertonBarChart data={requestsByCategoryData} tableView={tableView} columnOverrides={TABLE_LABELS} chartType={'bar'} />
              ),
            },
            {
              title: 'Top 10 Sub Categories',
              subheader: 'Number of requests per top 10 sub category',
              tableView: tableViewMap.topTenSubCategories,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, topTenSubCategories: toggleValue }),
              component: (tableView) => (
                <LivertonBarChart data={topTenSubCatRequests} tableView={tableView} columnOverrides={TABLE_LABELS} chartType={'bar'} />
              ),
            },
          ]}
        />
      </Grid>
    </TabPanel>
  );
}

const TABLE_LABELS = {
  x: 'Date',
};
