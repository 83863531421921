import actionCreator from '../../../../support/actionCreator';

const FoodMenuScheduleActions = {
  ...actionCreator.createActions('Food Menu Schedule', 'FOOD_MENU_SCHEDULE', '/api/private/portal/food/menu/{menuId}/schedule'),
  selectAndNavigate: (item, history) => async (dispatch) => {
    await dispatch({ type: 'FOOD_MENU_SCHEDULE_SELECT', payload: item });
    history.push(`/food/menu/schedule/view/${item.id}`);
  },
};

export default FoodMenuScheduleActions;
