import * as Actions from '../../../actions/reservation/report/actions';

const initial = {
  entityList: [],
  processing: false,
  page: {
    size: 25,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  error: null,
};

function hotelReservationReportReducer(state = initial, action) {
  switch (action.type) {
    case Actions.HOTEL_RESERVATION_REPORT_FETCH_IN_PROGRESS: {
      return {
        ...state,
        processing: true,
        error: null,
      };
    }
    case Actions.HOTEL_RESERVATION_REPORT_FETCH_SUCCESS: {
      return {
        ...state,
        processing: false,
        entityList: action.payload.content,
        page: action.payload.page,
      };
    }
    case Actions.HOTEL_RESERVATION_REPORT_FETCH_FAILURE: {
      return {
        ...state,
        entityList: [],
        processing: false,
        error: action.payload,
      };
    }
    case Actions.HOTEL_RESERVATION_REPORT_EXPORT_IN_PROGRESS:
      return {
        ...state,
        processing: true,
        error: null,
      };
    case Actions.HOTEL_RESERVATION_REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case Actions.HOTEL_RESERVATION_REPORT_EXPORT_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default hotelReservationReportReducer;
