import React from 'react';
import * as DataTypes from '../../../../../constants/DataTypes';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/securityEvent/actions';
import { useDispatch, useSelector } from 'react-redux';

const columns = [
  {
    id: 'eventName',
    label: 'Event Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'eventDoor',
    label: 'Event Door',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'securityEventCode',
    label: 'Security Event Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lockEventCode',
    label: 'Lock Event Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'eventPriority',
    label: 'Event Priority',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function SecurityEventList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const events = useSelector((state) => state.securityEventReducer.entityList);
  const lastActionType = useSelector((state) => state.securityEventReducer.lastActionType);
  const processing = useSelector((state) => state.securityEventReducer.processing);
  const page = useSelector((state) => state.securityEventReducer.page);
  const error = useSelector((state) => state.securityEventReducer.error);
  const parameters = { siteId: selectedSite?.id, sort: 'created,desc' };

  return (
    <AutoTable
      title="Security Event"
      subheader={'Security events synchronised between lock system and security system at the site'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 25, parameters))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, parameters))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECURITY_EVENT"
      idColumn={'id'}
      data={events}
      lastActionType={lastActionType}
    />
  );
}
