const KeyIssueMode = {
  JOIN: 'JOIN',
  OVERRIDE_MOBILE: 'OVERRIDE_MOBILE',
  OVERRIDE: 'OVERRIDE',
};

const KeyIssueModeOptions = [
  { label: 'Join (Keep Existing Keys)', value: 'JOIN' },
  { label: 'Override All (Revoke Existing Keys)', value: 'OVERRIDE_MOBILE' },
  { label: 'Override Mobile Keys (Revoke Existing Mobile Keys)', value: 'OVERRIDE' },
];

export default KeyIssueMode;
export { KeyIssueModeOptions };
