const PurchaseProcessState = {
  AUTH_COMPLETE: 'AUTH_COMPLETE',
  CHARGE: 'CHARGE',
  REFUND: 'REFUND',
};
const PaymentPurchaseStateChargeOptions = [
  { value: PurchaseProcessState.REFUND, label: 'Refund' },
  { value: PurchaseProcessState.CHARGE, label: 'Charge' },
  { value: PurchaseProcessState.AUTH_COMPLETE, label: 'Capture' },
];

export default PurchaseProcessState;
export { PaymentPurchaseStateChargeOptions };
