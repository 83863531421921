/**
 * Created by Adrian Hartwig 17/09/2019.
 */

import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class Error extends React.Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    errorHandler: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="viewport">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 offset-3">
              <div className="text-center">
                <div className="m-2">
                  <i className="fad fa-exclamation-triangle fa-3x" />
                </div>

                <h2>Error Occurred</h2>
                <h3>Sorry an error has occurred during processing</h3>

                <hr />

                {this.props.error && (
                  <h4 className="text-danger">{this.props.error.message ? this.props.error.message : 'Unknown error'}</h4>
                )}

                {!this.props.error && <h4 className="text-danger">Error message is not available</h4>}

                <hr />

                <Link to="/customer/home" className="btn btn-success m-1" onClick={this.props.errorHandler}>
                  <i className={'fad fa-sync'} /> Reset
                </Link>

                <Link to="/customer/home" className="btn btn-sm btn-dark m-1" onClick={this.props.errorHandler}>
                  <i className={'fad fa-book'} /> Report
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
