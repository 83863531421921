import React, { useEffect } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import ActivityImageActions from '../../../redux/actions/activity/image/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Link, TextField } from '@material-ui/core';
import { CloudUpload, DeleteOutlined } from '@material-ui/icons';
import toBase64 from '../../../../support/toBase64Converter';
import { makeStyles } from '@material-ui/core/styles';
import API from '@aws-amplify/api';

const useStyles = makeStyles(() => ({
  media: {
    height: 0,
    backgroundSize: 'cover',
    maxWidth: '192px',
    maxHeight: '108px',
    aspectRatio: 'auto',
    paddingTop: '25%',
    '&:hover': {
      backgroundSize: 'contain',
      transform: 'scale3d(3.5, 3.5, 3.5)',
    },
  },
}));

const handleChange = (id) => (dispatch) => async (event) => {
  try {
    const response = await API.put('PrivateAPI', `/api/private/portal/activity/image/${id}`, { body: event.target.value });
    dispatch(ActivityImageActions.updateSuccess(response));
    console.info('Successfully updated activity mapping', response);
  } catch (error) {
    dispatch(ActivityImageActions.error(error));
    console.warn('Unable to update activity mapping due to error', error);
  }
};

const columns = (classes) => [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={'inherit'}
          onClick={() => dispatch(ActivityImageActions.delete(value, `/api/private/portal/activity/image/${value.id}`))}
        >
          <DeleteOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'url',
    label: 'Image',
    className: classes.media,
    minWidth: 180,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_IMAGE,
  },
  {
    id: 'order',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <TextField
          placeholder={'Order'}
          type={'number'}
          variant={'outlined'}
          margin={'dense'}
          value={value.order}
          step={1}
          onChange={dispatch(handleChange(value.id))}
        />
      );
    },
  },
  {
    id: 'filename',
    label: 'URL',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return <Link component={'a'} href={value.url} target={'_blank'} rel={'noopener noreferrer'} children={value.filename} />;
    },
  },
];

export default function ActivityImageList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const imageUploadId = 'activity-image-upload';
  const activity = useSelector((state) => state.activityReducer.selectedItem);
  const activityId = activity?.id;
  const activityImageList = useSelector((state) => state.activityImageReducer.entityList);
  const processing = useSelector((state) => state.activityImageReducer.processing);
  const page = useSelector((state) => state.activityImageReducer.page);
  const error = useSelector((state) => state.activityImageReducer.error);

  const openFileWindow = () => {
    document.getElementById(imageUploadId).click();
  };

  const handleFileSelected = (dispatch) => async (event) => {
    const images = [];
    for (const file of event.target.files) {
      images.push(await toBase64(file));
    }

    dispatch(ActivityImageActions.add({ images: images }, { activityId: `${activityId}` }));
  };

  useEffect(() => {
    if (activityId) {
      dispatch(ActivityImageActions.fetch(0, 25, {}, { activityId: `${activityId}` }));
    }
  }, [dispatch, activityId]);

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        id={imageUploadId}
        accept="image/*"
        name={imageUploadId}
        onChange={dispatch(handleFileSelected)}
        multiple={true}
      />
      <AutoTable
        title="Activity Images"
        subheader={'Activity Images'}
        buttonName={'Upload'}
        buttonIcon={<CloudUpload />}
        onCreateNew={openFileWindow}
        backHidden={true}
        selectItem={(item) => dispatch(ActivityImageActions.select(item))}
        processing={processing}
        handleChangePage={(ev, newPage, pageSize) =>
          dispatch(ActivityImageActions.fetch(newPage, pageSize, {}, { activityId: `${activityId}` }))
        }
        page={page}
        error={error}
        columns={columns(classes)}
        prefix="ACTIVITY_IMAGE"
        data={activityImageList}
      />
    </>
  );
}
