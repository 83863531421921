import React from 'react';
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Charts from '../Charts';
import { CustomTooltip } from '../CustomTooltip';
import { useTheme } from '@material-ui/core';
import ChartTable from './ChartTable';

const LivertonBarChart = ({ data, tableView, columnOverrides }) => {
  const currentTheme = useTheme();
  return (
    <>
      <ResponsiveContainer
        minWidth={Charts.getDefaultChartDimension()}
        minHeight={Charts.getDefaultChartDimension()}
        width="100%"
        height="100%"
      >
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis stroke={currentTheme.palette.text.secondary} fontSize="80%" />
          <XAxis dataKey="x" stroke={currentTheme.palette.text.secondary} fontSize="80%" />
          <Legend />
          <Tooltip content={({ active, payload, label }) => <CustomTooltip payload={payload} label={label} />} />
          {Charts.getCategoryForChartWithType(data, 'x', 'bar')}
        </BarChart>
      </ResponsiveContainer>

      <ChartTable visible={tableView} chartData={data} columnOverrides={columnOverrides} />
    </>
  );
};

export default LivertonBarChart;
