import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';
import Swal from 'sweetalert2';

const FoodModifierGroupActions = {
  ...actionCreator.createActions(
    'Food Modifier Group',
    'FOOD_MODIFIER_GROUP',
    '/api/private/portal/food/vendor/{vendorId}/food/modifier/group'
  ),
  selectAndNavigate: (item, history) => async (dispatch) => {
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/modifier/group/${item.id}`, {});
      await dispatch({ type: 'FOOD_MODIFIER_GROUP_SELECT', payload: result });
      history.push(`/food/modifier/group/view/${result.id}`);
    } catch (error) {
      dispatch({ type: `FOOD_MODIFIER_GROUP_SELECT_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  fetchAll: (vendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_MODIFIER_GROUP_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/food/modifier/group/list`, {});
      await dispatch({ type: `FOOD_MODIFIER_GROUP_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food modifier group with result: ', error);
      dispatch({
        type: `FOOD_MODIFIER_GROUP_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  duplicate: (item) => async (dispatch) => {
    Objects.notNull(item, 'item cannot be null');

    Swal.fire({
      title: 'Duplicate Modifier Group',
      html: `<input id="fmt-name" class="swal2-input" value="${item.groupName} - Copy">`,
      showCancelButton: true,
      preConfirm: () => {
        if (!document.getElementById('fmt-name').value) {
          Swal.showValidationMessage(`Name Required`);
          return false;
        }
        return { name: document.getElementById('fmt-name').value };
      },
    }).then(async function (result) {
      if (result.isConfirmed) {
        dispatch({ type: `FOOD_MODIFIER_GROUP_ADD_IN_PROGRESS` });
        try {
          const response = await API.post(
            'PrivateAPI',
            `/api/private/portal/food/modifier/group/${item.id}/duplicate?name=${result.value.name}`,
            {}
          );
          dispatch({ type: `FOOD_MODIFIER_GROUP_ADD_SUCCESS`, payload: response });
        } catch (error) {
          dispatch({
            type: `FOOD_MODIFIER_GROUP_ADD_FAILURE`,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        }
      }
    });
  },
};

export default FoodModifierGroupActions;
