import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, IconButton, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SpecialRequestCategoryOptions } from '../../../../../support/SpecialRequestCategory';
import SpecialRequestCategoryActions from '../../../../redux/actions/specialRequestCategory/actions';
import { RequestServiceTimeRenderDays } from './RequestServiceTimeRenderDays';
import * as DataTypes from '../../../../../constants/DataTypes';
import RequestSubCategoryActions from '../../../../redux/actions/specialRequestSubCategory/actions';
import { DeleteOutlined } from '@material-ui/icons';
import AutoTable from '../../../../../components/table/AutoTable';
import FormField from '../../../../../components/form/FormField';
import defaultScheduleDays from '../../../../../support/defaultScheduleDays';

const columns = [
  {
    id: 'actions',
    label: '',
    align: 'left',
    width: 100,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={'inherit'}
          onClick={() => dispatch(RequestSubCategoryActions.delete(value, `/api/private/subCategory/${value.id}`))}
        >
          <DeleteOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'subCategory',
    label: 'Sub Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'subOrder',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Switch
          checked={value.enabled}
          onChange={() => dispatch(RequestSubCategoryActions.quickToggle(value, false))}
          name={'enabled'}
          color={'primary'}
        />
      );
    },
  },
  {
    id: 'deliveryTimeEnabled',
    label: 'Delivery Time Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Switch
          checked={value.deliveryTimeEnabled}
          onChange={() => dispatch(RequestSubCategoryActions.quickToggle(value, true))}
          name={'deliveryTimeEnabled'}
          color={'primary'}
        />
      );
    },
  },
];

export default function RequestCategoryServiceEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.specialRequestCategoryReducer);

  const [category, setCategory] = useState(selectedItem ? selectedItem.category : null);
  const [escalationTimeForAcknowledgement, setEscalationTimeForAcknowledgement] = useState(
    selectedItem ? selectedItem.escalationTimeForAcknowledgement : 180
  );
  const [escalationTimeForCompletion, setEscalationTimeForCompletion] = useState(
    selectedItem ? selectedItem.escalationTimeForCompletion : 180
  );
  const [catOrder, setCatOrder] = useState(selectedItem?.catOrder || 1);
  const [escalationEnabled, setEscalationEnabled] = useState(selectedItem?.escalationEnabled || false);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [scheduleDays, setScheduleDays] = useState(selectedItem ? selectedItem.scheduleDays : defaultScheduleDays);
  const [days, setDays] = useState(() => []);
  const subCategoryPage = useSelector((state) => state.subCategoryReducer.page);
  const subCategoryProcessing = useSelector((state) => state.subCategoryReducer.processing);
  const subCategoryError = useSelector((state) => state.subCategoryReducer.error);
  const subCategoryList = useSelector((state) => state.subCategoryReducer.entityList);
  const subCategoryLastActionType = useSelector((state) => state.subCategoryReducer.lastActionType);

  useEffect(() => {
    dispatch(SpecialRequestCategoryActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (scheduleDays) {
      setDays(scheduleDays.reduce((acc, day) => ({ ...acc, [day.dayOfWeek]: { selected: true, open: false } }), {}));
    }
  }, [scheduleDays]);

  const handleFetchSubCategories = () => {
    dispatch(RequestSubCategoryActions.fetch(subCategoryPage, 50, { siteId: selectedSite.id }, { categoryId: `${selectedItem.id}` }));
  };

  useEffect(() => {
    if (selectedItem) {
      handleFetchSubCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      lastActionType === 'SPECIAL_REQUEST_CATEGORY_DELETE_SUCCESS' ||
      lastActionType === 'SPECIAL_REQUEST_CATEGORY_UPDATE_SUCCESS' ||
      lastActionType === 'SPECIAL_REQUEST_CATEGORY_ADD_SUCCESS'
    ) {
      history.push('/site/notifications');
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      category,
      catOrder,
      escalationEnabled,
      escalationTimeForAcknowledgement,
      escalationTimeForCompletion,
      scheduleDays: scheduleDays.filter((day) => days[day.dayOfWeek].selected),
    };

    if (selectedItem) {
      dispatch(SpecialRequestCategoryActions.update(payload, `/api/private/specialRequestCategory/schedule/${selectedItem.id}`, false));
    } else {
      dispatch(SpecialRequestCategoryActions.add(payload, { siteId: selectedSiteId }));
    }
  };

  const handleTimeClick = (id, val) => {
    setDays({
      ...days,
      [id]: { selected: days[id]?.selected, open: val },
    });
  };

  const handleTimeUpdate = (currentDay, day, time, timeType) => {
    const updatedDay = {
      id: currentDay?.id || null,
      dayOfWeek: day,
      startTime: currentDay?.startTime || null,
      endTime: currentDay?.endTime || null,
    };

    if (timeType === 'start') {
      updatedDay.startTime = moment(time).format('HH:mm');
    } else {
      updatedDay.endTime = moment(time).format('HH:mm');
    }

    setScheduleDays([...scheduleDays.filter((d) => d.dayOfWeek !== day), updatedDay]);
  };

  const handleToggle = (day) => {
    const val = !days[day]?.selected;
    setDays({
      ...days,
      [day]: { selected: val, open: false },
    });

    if (!val) {
      setScheduleDays((prevScheduleDays) => prevScheduleDays.filter((d) => d.dayOfWeek !== day));
    } else {
      setScheduleDays((prevScheduleDays) => {
        const updatedDay = {
          id: null,
          dayOfWeek: day,
          startTime: '00:00',
          endTime: '23:59',
        };
        return [...prevScheduleDays, updatedDay];
      });
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Guest Request Category' : 'Create Guest Request Category'}
        subheader={'By adding a category guests can make requests to back of house.'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!category || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() =>
          dispatch(SpecialRequestCategoryActions.delete(selectedItem, `/api/private/specialRequestCategory/schedule/${selectedItem.id}`))
        }
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add schedule - ${error.message}`}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Guest Category'} value={category} setValue={setCategory} options={SpecialRequestCategoryOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Category Order'} value={catOrder} type={'number'} setValue={setCatOrder} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={escalationEnabled}
                  onChange={(_) => setEscalationEnabled(!escalationEnabled)}
                  name={'Escalation Enabled'}
                  color="primary"
                />
              }
              label={'Escalation Enabled'}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        {escalationEnabled && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField
                label={'Escalation Time For Acknowledgement (Minutes)'}
                type={'number'}
                value={escalationTimeForAcknowledgement}
                setValue={setEscalationTimeForAcknowledgement}
                inputProps={{ step: '1', min: 0, max: 1000 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField
                label={'Escalation Time For Completion (Minutes)'}
                type={'number'}
                value={escalationTimeForCompletion}
                setValue={setEscalationTimeForCompletion}
                inputProps={{ step: '1', min: 0, max: 1000 }}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <Divider variant={'middle'} />
      <CardHeader title={'Category hours'} subheader={'Controls when a category is available to guests'} />
      <CardContent>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={0}>
            <RequestServiceTimeRenderDays
              days={days}
              scheduleDays={scheduleDays}
              handleToggle={handleToggle}
              handleTimeUpdate={handleTimeUpdate}
              handleTimeClick={handleTimeClick}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>

      {selectedItem && (
        <AutoTable
          title="Sub Categories"
          subheader={'Use subcategories to guide the guest to request something specific i.e. "Extra Towels"'}
          processing={subCategoryProcessing}
          handleRefreshReport={() => handleFetchSubCategories()}
          page={subCategoryPage}
          error={subCategoryError}
          selectItem={(item) => {}}
          clearError={() => dispatch(RequestSubCategoryActions.clearError())}
          columns={columns}
          prefix="SPECIAL_REQUEST_SUB"
          data={subCategoryList}
          lastActionType={subCategoryLastActionType}
          createNewURL="/site/specialRequestCategory/subCategory/data"
          buttonName="Edit"
          idColumn={'id'}
        />
      )}
    </Card>
  );
}
