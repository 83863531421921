import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/root';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

/**
 * List of reducers not to persis through app reloads.
 */
const persistConfig = {
  key: 'administration',
  storage,
  blacklist: ['authenticationReducer', 'reportReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = createLogger({
  duration: true,
  diff: false,
});

const administrationStore = () => {
  /**
   * Only enable redux logger while in development mode
   */
  const environment = process.env.NODE_ENV;
  const middleware = environment === 'development' ? [thunkMiddleware, loggerMiddleware] : [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const composedEnhancers = compose(middlewareEnhancer);

  //let store = createStore(persistedReducer, undefined, composedEnhancers);
  let store = createStore(persistedReducer, undefined, composedEnhancers);
  let persistor = persistStore(store);
  return { store: store, persistor: persistor };
};

export default administrationStore;
