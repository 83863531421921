import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/appUserNotification/actions';
import AutoTable from '../../../../../components/table/AutoTable';

const columns = [
  {
    id: 'reference',
    label: 'reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'category',
    label: 'category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'type',
    label: 'type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function AppUserNotificationList() {
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const page = useSelector((state) => state.appUserNotificationReducer.page);
  const error = useSelector((state) => state.appUserNotificationReducer.error);
  const processing = useSelector((state) => state.appUserNotificationReducer.processing);
  const lastActionType = useSelector((state) => state.appUserNotificationReducer.lastActionType);
  const requests = useSelector((state) => state.appUserNotificationReducer.entityList);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 10, { username: selectedItem.username }));
    }
  }, [dispatch, selectedItem]);

  const handleRefreshReport = () => {
    if (selectedItem) {
      dispatch(Actions.fetch(0, 10, { username: selectedItem.username }));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedItem) {
      dispatch(Actions.fetch(newPage, pageSize, { username: selectedItem.username }));
    }
  };

  return (
    <AutoTable
      title="Notifications "
      subheader={'Notifications for the App Users'}
      handleRefreshReport={() => handleRefreshReport()}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="APP_USER_NOTIFICATION"
      buttonDisabled={true}
      data={requests}
      lastActionType={lastActionType}
    />
  );
}
