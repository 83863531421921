import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../../components/form/FormSelect';

const MewsAccountTypes = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Company', value: 'Company' },
];

const MewsRoomStatus = [
  { label: 'Clean', value: 'CLEAN' },
  { label: 'Inspected', value: 'INSPECTED' },
  { label: 'Dirty', value: 'DIRTY' },
  { label: 'OutOfOrder', value: 'OUT_OF_ORDER' },
  { label: 'OutOfService', value: 'OUT_OF_ORDER' },
];

const GUEST_RECEIPT_OPTIONS = [
  { label: 'Send Via PMS', value: 'SEND_DIRECTLY' },
  { label: 'Generate Via PMS', value: 'GENERATE_PDF' },
  { label: 'Send at Check-out', value: 'SEND_AT_CHECK_OUT' },
  { label: 'Use Email Template', value: 'USE_EMAIL_TEMPLATE' },
];

const MewsExtrasFetch = [
  { label: 'BILL', value: 'BILL' },
  { label: 'RATE', value: 'RATE' },
  { label: 'GUESTS', value: 'GUESTS' },
  { label: 'ROOM', value: 'ROOM' },
  { label: 'PRICE', value: 'PRICE' },
  { label: 'SOURCES', value: 'SOURCES' },
  { label: 'PAYMENTS', value: 'PAYMENTS' },
];

export default function EditMews() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);
  const [endpoint, setEndpoint] = React.useState(configuration?.endpoint || 'https://api.mews-demo.com/');
  const [accessToken, setAccessToken] = React.useState(
    configuration?.accessToken || '5F56B9903A834F199E28AD20015E58CA-5C6A1A00550634911534AD6A098E8B7'
  );
  const [clientToken, setClientToken] = React.useState(
    configuration?.clientToken || '2CC71B0660F345019882AD200155B4FE-4A1FC9080A4DD2A404734003674F77E'
  );
  const [client, setClient] = React.useState(configuration?.client || 'SmartCheck 3.4.4');
  const [serviceId, setServiceId] = React.useState(configuration?.serviceId || 'bd26d8db-86da-4f96-9efc-e5a4654a4a94');
  const [enterpriseId, setEnterpriseId] = React.useState(configuration?.enterpriseId || '851df8c8-90f2-4c4a-8e01-a4fc46b25178');
  const [vacantRoomStatuses, setVacantRoomStatuses] = React.useState(configuration?.vacantRoomStatuses || ['CLEAN']);
  const [accountTypes, setAccountTypes] = React.useState(configuration?.accountTypes || ['Customer']);
  const [extrasFetch, setExtrasFetch] = React.useState(
    configuration?.extrasFetch || ['BILL', 'RATE', 'GUESTS', 'ROOM', 'PRICE', 'SOURCES', 'PAYMENTS']
  );
  const [allowOpenBalances, setAllowOpenBalances] = React.useState(configuration?.allowOpenBalances || false);
  const [createdDaysStart, setCreatedDaysStart] = React.useState(configuration?.createdDaysStart || 365);
  const [closeBills, setCloseBills] = React.useState(configuration?.closeBills || true);
  const [receiptMode, setReceiptMode] = React.useState(configuration?.receiptMode || 'GENERATE_PDF');
  const [taxCode, setTaxCode] = React.useState(configuration?.taxCode || 'GBP-20%');
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'GBP');
  const [readTimeout, setReadTimeout] = React.useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = React.useState(configuration?.connectTimeout || 5000);

  const handleSubmit = useCallback(() => {
    if (selectedSite) {
      const payload = {
        ...selectedItem,
        name: name,
        enabled: enabled,
        systemCategory: selectedItem.systemCategory,
        systemType: selectedItem.systemType,
        siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
        systemConfiguration: JSON.stringify({
          endpoint: endpoint,
          accessToken: accessToken,
          clientToken: clientToken,
          client: client,
          serviceId: serviceId,
          enterpriseId: enterpriseId,
          createdDaysStart: createdDaysStart,
          accountTypes: accountTypes,
          allowOpenBalances: allowOpenBalances,
          closeBills: closeBills,
          receiptMode: receiptMode,
          vacantRoomStatuses: vacantRoomStatuses,
          taxCode: taxCode,
          extrasFetch: extrasFetch,
          debug: debug,
          currency: currency,
          readTimeout: readTimeout,
          connectTimeout: connectTimeout,
        }),
      };

      if (selectedItem.id) {
        dispatch(Actions.updateSiteSystem(payload));
      } else {
        dispatch(Actions.createSiteSystem(payload));
      }
    }
  }, [
    accessToken,
    accountTypes,
    allowOpenBalances,
    client,
    clientToken,
    closeBills,
    connectTimeout,
    createdDaysStart,
    currency,
    debug,
    dispatch,
    enabled,
    endpoint,
    enterpriseId,
    extrasFetch,
    name,
    readTimeout,
    receiptMode,
    selectedItem,
    selectedSite,
    serviceId,
    taxCode,
    vacantRoomStatuses,
  ]);

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Mews PMS' : 'Create Mews PMS'}
        subheader={'Mews Property Management System'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={false}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'API Endpoint'} type={'text'} value={endpoint} setValue={setEndpoint} error={!endpoint} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Mews Access Token'}
              type={'password'}
              value={accessToken}
              setValue={setAccessToken}
              error={!accessToken}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Mews Client Token'}
              type={'password'}
              value={clientToken}
              setValue={setClientToken}
              error={!clientToken}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Mews Client Name'} type={'text'} value={client} setValue={setClient} error={!client} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Service ID'} type={'text'} value={serviceId} setValue={setServiceId} error={!serviceId} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Enterprise ID'}
              type={'text'}
              value={enterpriseId}
              setValue={setEnterpriseId}
              error={!enterpriseId}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Created Days Search Days'}
              type={'number'}
              value={createdDaysStart}
              setValue={setCreatedDaysStart}
              error={!createdDaysStart}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Allow Open Balances'} value={allowOpenBalances} setValue={setAllowOpenBalances} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Close Bills'} value={closeBills} setValue={setCloseBills} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Tax Code'} value={taxCode} setValue={setTaxCode} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Guest Receipt Mode'} value={receiptMode} setValue={setReceiptMode} options={GUEST_RECEIPT_OPTIONS} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="extrasFetch">Extras to Fetch</InputLabel>
              <Select
                labelId="extrasFetch"
                value={extrasFetch}
                multiple={true}
                labelWidth={170}
                onChange={(event) => setExtrasFetch(event.target.value)}
              >
                {MewsExtrasFetch.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={extrasFetch.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="accountTypes">Account Types</InputLabel>
              <Select
                labelId="accountTypes"
                value={accountTypes}
                multiple={true}
                labelWidth={100}
                onChange={(event) => setAccountTypes(event.target.value)}
              >
                {MewsAccountTypes.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={accountTypes.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="vacantRoomStatusesList">Vacant Room Statuses</InputLabel>
              <Select
                labelId="vacantRoomStatusesList"
                value={vacantRoomStatuses}
                multiple={true}
                labelWidth={170}
                onChange={(event) => setVacantRoomStatuses(event.target.value)}
              >
                {MewsRoomStatus.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={vacantRoomStatuses.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Connect Timeout (MS)'} value={connectTimeout} setValue={setConnectTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout (MS)'} value={readTimeout} setValue={setReadTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
