import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../redux/actions/reservation/hotel/actions';
import { AppBar, Button, Card, CardContent, Chip, Grid, ListItem, Paper, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import Objects from '../../../../../support/Objects';
import { AvailabilityStatusOptions } from '../../../../../support/AvailabilityStatus';
import ReservationStatus, { ReservationStatusOptions } from '../../../../../support/ReservationStatus';
import Swal from 'sweetalert2';
import CannedRegex from '../../../../../support/CannedRegex';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormRoomSelect from '../../../../../components/form/FormRoomSelect';
import { CloudDownload, Email, Sync } from '@material-ui/icons';
import AppConfig from '../../../../../AppConfig';
import GuestRequestList from './guestRequests/GuestRequestList';

import ReservationUpsellList from './upsell/ReservationUpsellList';
import ReservationTrackingList from './tracking/reservationTrackingList';
import ReservationQueueList from './queue/ReservationQueueList';
import ReservationPurchaseList from './purchase/PurchaseList';
import SecondaryGuestList from './guests/SecondaryGuestList';
import TabPanel from '../../../control/TabPanel';
import ReservationFeedbackList from './feedback/ReservationFeedbackList';
import PhoneNumberInput from '../../../../../components/phoneNumberInput/PhoneNumberInput';
import RoomSelectionList from './reservationRoomSelection/RoomSelectionList';
import ReservationLinkTrackingList from './tracking/reservationLinkTrackingList';
import ValidationUtil from '../../../../../support/ValidationUtil';
import ReservationNotificationList from './notification/ReservationNotificationList';
import ReservationJson from './reservationJson/ReservationJson';

export default function HotelReservationEdit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const lastActionType = useSelector((state) => state.hotelReservationReducer.lastActionType);
  const selectedItem = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const error = useSelector((state) => state.hotelReservationReducer.error);

  const [confirmationNumber] = useState(selectedItem?.confirmationNumber || Objects.getConfirmationNumber(selectedSiteId));
  const [reservationNumber] = useState(selectedItem?.reservationNumber || Objects.getReservationNumber(selectedSiteId));
  const [givenName, setGivenName] = useState(selectedItem?.givenName || '');
  const [familyName, setFamilyName] = useState(selectedItem?.familyName || '');
  const [email, setEmail] = useState(selectedItem?.email || '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [outstanding, setOutstanding] = useState(selectedItem?.outstanding || 350.0);
  const [room, setRoom] = useState(
    selectedItem && selectedItem.roomNumber
      ? {
          label: selectedItem.roomNumber,
          value: selectedItem.roomNumber,
        }
      : null
  );
  const [roomType, setRoomType] = useState(selectedItem?.roomType || 'KING');
  const [ratePlan, setRatePlan] = useState(selectedItem?.ratePlan || 'STANDARD');
  const [status, setStatus] = useState(selectedItem?.status || 'DUE_IN');
  const [availabilityStatus, setAvailabilityStatus] = useState(selectedItem?.availabilityStatus || 'OK');
  const [arrivalDate, setArrivalDate] = useState(selectedItem?.arrivalDate || moment().startOf('day'));
  const [departureDate, setDepartureDate] = useState(selectedItem?.departureDate || moment().add(1, 'days').startOf('day'));
  const [notifications, setNotifications] = useState(selectedItem ? selectedItem.notifications : false);
  const [siteZone] = useState(selectedItem?.siteZone || selectedSite?.zoneId);
  const [siteId] = useState(selectedItem?.siteId || selectedSiteId);
  const [appleWalletFileUrl] = useState(selectedItem?.appleWalletFileUrl);
  const [androidWalletObjectSuffix] = useState(selectedItem?.androidWalletObjectSuffix);
  const [marketCode, setMarketCode] = useState(selectedItem?.marketCode || '');
  const [source, setSource] = useState(selectedItem?.source || '');
  const [origin, setOrigin] = useState(selectedItem?.origin || '');
  const [membershipNumber, setMembershipNumber] = useState(selectedItem?.membershipNumber || '');
  const [membershipType, setMembershipType] = useState(selectedItem?.membershipType || '');
  const [membershipLevel, setMembershipLevel] = useState(selectedItem?.membershipLevel || '');

  const location = useLocation();
  const selectedTab = new URLSearchParams(location.search).get('tab');
  const [value, setValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
  const editTabsList = [
    { label: 'Reservation Tracking' },
    { label: 'Purchase Items' },
    { label: 'Special Requests' },
    { label: 'Secondary Guests' },
    { label: 'Reservation Upsells' },
    { label: 'Reservation Queue' },
    { label: 'Feedback' },
    { label: 'Room Selection' },
    { label: 'Link Tracking' },
    { label: 'Notifications' },
    { label: 'Reservation Data/Debug' },
  ];

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if ('HOTEL_RESERVATION_ADD_SUCCESS' === lastActionType) {
      if (ReservationStatus.DUE_IN === status) {
        history.push('/reservation/hotel/arrivals');
      } else {
        history.push('/reservation/hotel/current');
      }
    }

    if ('HOTEL_RESERVATION_DELETE_SUCCESS' === lastActionType) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, status]);

  const handleSubmit = useCallback(() => {
    const isRoomSelected = room && room.value;
    const selectedRoom = isRoomSelected ? room.value : null;

    if (status === ReservationStatus.CHECKED_IN && !isRoomSelected) {
      Swal.fire('Room Required', 'Room number is required before updating status to checked-in', 'error');
    } else {
      const trimmedEmail = ValidationUtil.trimOnSubmit(email);
      const payload = {
        ...selectedItem,
        confirmationNumber,
        reservationNumber,
        givenName,
        familyName,
        email: trimmedEmail,
        phone: phone.sanitizedPhoneNumber,
        outstanding,
        roomNumber: selectedRoom,
        roomType,
        ratePlan,
        status,
        availabilityStatus,
        arrivalDate: moment(arrivalDate).format('YYYY-MM-DD'),
        departureDate: moment(departureDate).format('YYYY-MM-DD'),
        editable: selectedItem ? selectedItem.editable : false,
        entityType: selectedItem ? selectedItem.entityType : 'LOCAL',
        notifications,
        siteZone: siteZone,
        siteId: siteId,
        marketCode,
        source,
        origin,
        membershipNumber,
        membershipType,
        membershipLevel,
      };

      if (selectedItem) {
        dispatch(Actions.update(payload, `/api/private/portal/${siteId}/reservations/${selectedItem.id}`));
      } else {
        dispatch(Actions.add(payload, { siteId }));
      }
    }
  }, [
    selectedItem,
    confirmationNumber,
    reservationNumber,
    dispatch,
    givenName,
    familyName,
    email,
    phone,
    outstanding,
    room,
    roomType,
    ratePlan,
    status,
    availabilityStatus,
    arrivalDate,
    departureDate,
    notifications,
    siteZone,
    siteId,
    marketCode,
    source,
    origin,
    membershipNumber,
    membershipType,
    membershipLevel,
  ]);

  const moveSelectedTab = useCallback(
    (event, newValue) => {
      setValue(newValue);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tab', newValue);

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location]
  );

  const handleSync = (dispatch) => {
    dispatch(Actions.resyncReservation(reservationNumber, { siteId: selectedSiteId }));
  };

  const isPhoneValid = phone.isValid;
  const isEmailValid = CannedRegex.isEmailValid(email);
  const isEditable = !selectedItem || selectedItem.editable;
  const isFormReadyForSubmit = givenName && familyName && isEmailValid && isPhoneValid && arrivalDate && departureDate;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Reservation' : 'New Hotel Reservation'}
        subheader={'This reservation is managed by the SmartCheck platform'}
        buttonName={selectedItem ? 'Update' : 'Save'}
        onClick={handleSubmit}
        disabled={!isFormReadyForSubmit}
        deleteDisabled={!selectedItem}
        secondaryButtonName={'Notify'}
        secondaryButtonIcon={<Email />}
        secondaryButtonDisabled={!selectedItem}
        backLink={selectedItem?.status === ReservationStatus.CHECKED_IN ? '/reservation/hotel/current' : '/reservation/hotel/arrivals'}
        secondaryButtonAction={() => Actions.notifyReservation(reservationNumber, { siteId: selectedSiteId })}
        onDelete={() => dispatch(Actions.delete(selectedItem, `/api/private/portal/${siteId}/reservations/${selectedItem?.id}`))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to update/create reservation: ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          {selectedItem && (
            <>
              <Grid item xs={8} sm={10} lg={11}>
                <FormField label={'UUID'} value={selectedItem.uuid} disabled={true} />
              </Grid>
              <Grid xs={4} sm={2} lg={1} container justifyContent="center" alignItems="center">
                <Button color={'primary'} variant={'contained'} onClick={handleSync} fullWidth={matches} startIcon={<Sync />}>
                  Sync
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Confirmation Number'} value={confirmationNumber} disabled={true} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Reservation Number'} value={reservationNumber} disabled={true} />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'First Name'} value={givenName} setValue={setGivenName} error={!givenName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Last Name'} value={familyName} setValue={setFamilyName} error={!familyName} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Email'}
              value={email}
              setValue={setEmail}
              error={!isEmailValid}
              helperText={CannedRegex.getEmailMessage(!isEmailValid)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} defaultRegion={selectedSite?.countryCode} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormRoomSelect value={room} setValue={setRoom} disabled={!isEditable} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Room Type'} value={roomType} setValue={setRoomType} error={!roomType} disabled={!isEditable} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Rate Plan'} value={ratePlan} setValue={setRatePlan} error={!ratePlan} disabled={!isEditable} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Reservation Status'}
              value={status}
              setValue={setStatus}
              options={ReservationStatusOptions}
              disabled={!isEditable}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Availability Status'}
              value={availabilityStatus}
              setValue={setAvailabilityStatus}
              options={AvailabilityStatusOptions}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Money Outstanding (Check-out bill)'}
              value={outstanding}
              setValue={setOutstanding}
              step={0.2}
              type={'number'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Send Notifications'} value={notifications} setValue={setNotifications} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Market'} value={marketCode} setValue={setMarketCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Source'} value={source} setValue={setSource} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Origin'} value={origin} setValue={setOrigin} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Membership Number'} value={membershipNumber} setValue={setMembershipNumber} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Membership Type'} value={membershipType} setValue={setMembershipType} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Membership Level'} value={membershipLevel} setValue={setMembershipLevel} />
          </Grid>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DatePicker
                fullWidth
                label={'Arrival Date'}
                autoOk={true}
                inputVariant={'outlined'}
                margin={'dense'}
                value={arrivalDate}
                onChange={(date) => setArrivalDate(date)}
                disabled={status !== ReservationStatus.DUE_IN}
                format="YYYY-MM-DD"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DatePicker
                fullWidth
                label={'Departure Date'}
                autoOk={true}
                inputVariant={'outlined'}
                margin={'dense'}
                value={departureDate}
                minDate={arrivalDate}
                disabled={status === ReservationStatus.CHECKED_OUT}
                onChange={(date) => setDepartureDate(date)}
                format="YYYY-MM-DD"
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Nights'}
              value={arrivalDate && departureDate ? moment(arrivalDate).diff(departureDate, 'days') * -1 : 'Select Dates'}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Time Zone'} value={siteZone} disabled />
          </Grid>

          {selectedItem && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Updated'}
                  value={moment.unix(selectedItem.updated).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Created'}
                  value={moment.unix(selectedItem.created).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                  disabled
                />
              </Grid>
            </>
          )}

          {(appleWalletFileUrl || androidWalletObjectSuffix) && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ListItem>
                {appleWalletFileUrl && (
                  <Chip
                    label="Download Apple Wallet (.pkpass)"
                    icon={<CloudDownload />}
                    onClick={() => (window.location = `${AppConfig.ASSETS_CDN}/${appleWalletFileUrl}`)}
                    className={'mr-1'}
                  />
                )}
                {androidWalletObjectSuffix && (
                  <Chip
                    label="Open Android Wallet"
                    icon={<CloudDownload />}
                    onClick={Actions.openAndroidWalletObject(siteId, reservationNumber)}
                    className={'mr-1'}
                  />
                )}
              </ListItem>
            </Grid>
          )}
        </Grid>
      </CardContent>

      {selectedItem?.id && (
        <Paper>
          <AppBar position="static">
            <Tabs value={value} onChange={moveSelectedTab} variant="scrollable">
              {selectedItem && editTabsList.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
            </Tabs>
          </AppBar>
          <div className="content-scrollable-no-padding">
            <TabPanel value={value} index={0}>
              <ReservationTrackingList />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ReservationPurchaseList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <GuestRequestList fetchAll={false} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <SecondaryGuestList />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ReservationUpsellList />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ReservationQueueList />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ReservationFeedbackList />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <RoomSelectionList />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <ReservationLinkTrackingList />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <ReservationNotificationList />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <ReservationJson />
            </TabPanel>
          </div>
        </Paper>
      )}
    </Card>
  );
}
