import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';

export default function FormSelectLanguage({ value, setValue, label, placeholder }) {
  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    API.get('PrivateAPI', '/api/private/portal/theme/languages', {})
      .then((response) => {
        setAvailableLanguages(response);
      })
      .catch((error) => {
        console.warn('Unable to get languages due to error', error);
      });
  }, []);

  const onChange = (event, values) => {
    console.log('On change: ', event, values);
    setValue(values);
  };

  return (
    <Autocomplete
      fullWidth
      placeholder={placeholder}
      options={availableLanguages}
      getOptionSelected={(option, selected) => option.id === selected.id}
      getOptionLabel={(option) => option.name}
      multiple
      onChange={onChange}
      value={value}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" margin={'dense'} placeholder={placeholder} />}
    />
  );
}
