import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import Swal from 'sweetalert2';

export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';
export const ARTICLE_FETCH_FAILURE = 'ARTICLE_FETCH_FAILURE';

export const ARTICLE_ADD_SUCCESS = 'ARTICLE_ADD_SUCCESS';
export const ARTICLE_ADD_FAILURE = 'ARTICLE_ADD_FAILURE';

export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS';
export const ARTICLE_UPDATE_FAILURE = 'ARTICLE_UPDATE_FAILURE';

export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';
export const ARTICLE_DELETE_FAILURE = 'ARTICLE_DELETE_FAILURE';

export const ARTICLE_ADD_IN_PROGRESS = 'ARTICLE_ADD_IN_PROGRESS';
export const ARTICLE_PROCESSING = 'ARTICLE_PROCESSING';
export const ARTICLE_CLEAR_ERROR = 'ARTICLE_CLEAR_ERROR';
export const ARTICLE_SELECT = 'ARTICLE_SELECT';

export const ARTICLE_IMAGE_FETCH_SUCCESS = 'ARTICLE_IMAGE_FETCH_SUCCESS';
export const ARTICLE_IMAGE_FETCH_FAILURE = 'ARTICLE_IMAGE_FETCH_FAILURE';

export const ARTICLE_IMAGE_ADD_SUCCESS = 'ARTICLE_IMAGE_ADD_SUCCESS';
export const ARTICLE_IMAGE_ADD_FAILURE = 'ARTICLE_IMAGE_ADD_FAILURE';

export const ARTICLE_IMAGE_DELETE_SUCCESS = 'ARTICLE_IMAGE_DELETE_SUCCESS';
export const ARTICLE_IMAGE_DELETE_FAILURE = 'ARTICLE_IMAGE_DELETE_FAILURE';

export const ARTICLE_IMAGE_ADD_IN_PROGRESS = 'ARTICLE_IMAGE_ADD_IN_PROGRESS';
export const ARTICLE_IMAGE_PROCESSING = 'ARTICLE_IMAGE_PROCESSING';
export const ARTICLE_IMAGE_CLEAR_ERROR = 'ARTICLE_IMAGE_CLEAR_ERROR';
export const ARTICLE_IMAGE_SELECT = 'ARTICLE_IMAGE_SELECT';

export const fetch = (page, size, categoryId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/compendium-article/category/${categoryId}?page=${page || 0}&size=${size || 10}`;

  dispatch({ type: ARTICLE_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    const imageData = response.content.map(({ imageList }) => imageList).flat();
    dispatch({
      type: ARTICLE_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
        imageData: imageData,
      },
    });
  } catch (error) {
    dispatch({ type: ARTICLE_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const create = (request) => async (dispatch) => {
  dispatch({ type: ARTICLE_ADD_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/compendium-article', { body: request });
    dispatch({ type: ARTICLE_ADD_SUCCESS, payload: response });
    Swal.fire('Article Item Created', 'Article Item has been created successfully', 'success');
  } catch (error) {
    dispatch({ type: ARTICLE_ADD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const update = (request) => async (dispatch) => {
  dispatch({ type: ARTICLE_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/compendium-article', { body: request });
    dispatch({ type: ARTICLE_UPDATE_SUCCESS, payload: response });
    Swal.fire('Article Item Updated', 'Item has been updated successfully', 'success');
  } catch (error) {
    dispatch({ type: ARTICLE_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteItem = (selectedItem) => async (dispatch) => {
  dispatch({ type: ARTICLE_PROCESSING });
  Swal.fire({
    title: `Delete Article?`,
    text: 'Warning: Do you want to continue? This is not reversible!',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', `/api/private/portal/compendium-article/${selectedItem?.id}`, {});
        dispatch({ type: ARTICLE_DELETE_SUCCESS, payload: selectedItem });
      } catch (error) {
        dispatch({ type: ARTICLE_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
      }
    }
  });
};

export const fetchImages = (page, size, articleId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/compendium-article/image/article/${articleId}?page=${page || 0}&size=${size || 10}`;

  dispatch({ type: ARTICLE_IMAGE_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({
      type: ARTICLE_IMAGE_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
      },
    });
  } catch (error) {
    dispatch({ type: ARTICLE_IMAGE_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const createImage = (request) => async (dispatch) => {
  dispatch({ type: ARTICLE_IMAGE_ADD_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/compendium-article/image', { body: request });
    dispatch({ type: ARTICLE_IMAGE_ADD_SUCCESS, payload: response });
    Swal.fire('Image Added', 'Article Image has been created successfully', 'success');
  } catch (error) {
    dispatch({ type: ARTICLE_IMAGE_ADD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteImage = (selectedItem) => async (dispatch) => {
  dispatch({ type: ARTICLE_IMAGE_PROCESSING });
  Swal.fire({
    title: `Delete Image?`,
    text: 'Warning: Do you want to continue? This is not reversible!',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', `/api/private/portal/compendium-article/image/${selectedItem.id}`, {});
        dispatch({ type: ARTICLE_IMAGE_DELETE_SUCCESS, payload: selectedItem });
      } catch (error) {
        dispatch({ type: ARTICLE_IMAGE_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
      }
    }
  });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: ARTICLE_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: ARTICLE_SELECT, payload: item });
};

export const clearImageError = () => async (dispatch) => {
  dispatch({ type: ARTICLE_IMAGE_CLEAR_ERROR });
};

export const selectImageItem = (item) => async (dispatch) => {
  dispatch({ type: ARTICLE_IMAGE_SELECT, payload: item });
};
