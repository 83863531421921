import * as Actions from '../../../../actions/inRoom/ordering/foodPosPaymentType/actions';

const initial = {
  lastActionType: 'NONE',
  defaultPaymentTypes: [],
  foodPosPaymentTypes: [],
  error: null,
};

export default function foodPosPaymentTypeReducer(state = initial, action) {
  switch (action.type) {
    case Actions.FOOD_POS_PAYMENT_TYPE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        defaultPaymentTypes: action.payload.defaultPaymentTypes,
        foodPosPaymentTypes: action.payload.foodPosPaymentTypes,
        error: null,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        foodPosPaymentTypes: [...state.foodPosPaymentTypes, action.payload],
        error: null,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        foodPosPaymentTypes: [...state.foodPosPaymentTypes].filter((i) => i.id !== action.payload),
        error: null,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        foodPosPaymentTypes: [...state.foodPosPaymentTypes].map((i) => (i.id === action.payload.id ? action.payload : i)),
        error: null,
      };
    case Actions.FOOD_POS_PAYMENT_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
}
