import Big from 'big.js';

/**
 * Big Decimal Global Options - http://mikemcl.github.io/big.js/
 */
Big.RM = 2; //ROUND_HALF_EVEN

/**
 * Number of places to round
 */
export const ROUNDING_PLACES = 2;

export default class Currency {
  /**
   *
   * @param amount Instance of Money
   * @param currency Currency code
   */
  constructor(amount, currency) {
    this.currency = currency;

    if (amount === null || amount === undefined) {
      this.amount = new Big(0.0);
    } else {
      if (amount instanceof Big) {
        this.amount = amount;
      } else if (amount instanceof Currency) {
        this.amount = new Big(amount.toBig());
      } else {
        this.amount = new Big(amount);
      }
    }
  }

  static defaultCurrency() {
    return 'NZD';
  }

  static transform(value) {
    if (!value) {
      return Currency.ZERO(Currency.defaultCurrency());
    }

    if (value.hasOwnProperty('amount') && value.hasOwnProperty('currency')) {
      return new Currency(value.amount, value.currency);
    } else {
      throw new Error(`Expected object with value and currency fields: ${JSON.stringify(value)}`);
    }
  }

  static transformWithDefault(value, defaultCurrency) {
    if (!value) {
      return Currency.ZERO(defaultCurrency);
    }

    if (value.hasOwnProperty('amount') && value.hasOwnProperty('currency')) {
      return new Currency(value.amount, value.currency);
    } else {
      throw new Error(`Expected object with value and currency fields: ${JSON.stringify(value)}`);
    }
  }

  static ROUNDING_PLACES() {
    return ROUNDING_PLACES;
  }

  static ZERO(currency) {
    return new Currency(0.0, currency);
  }

  //operations
  plus(value) {
    return new Currency(this.amount.plus(this.toOperationValue(value)), this.currency);
  }

  minus(value) {
    return new Currency(this.amount.minus(this.toOperationValue(value)), this.currency);
  }

  times(value) {
    return new Currency(this.amount.times(this.toOperationValue(value)), this.currency);
  }

  devide(value) {
    return new Currency(this.amount.div(this.toOperationValue(value)), this.currency);
  }

  absolute() {
    return new Currency(this.amount.abs(), this.currency);
  }

  //formatting

  negate() {
    return new Currency(this.amount.abs().times(-1), this.currency);
  }

  //evaluation methods

  round(points) {
    return new Currency(this.amount.round(points), this.currency);
  }

  format(symbol = '') {
    return `${symbol}${this.amount.toFixed(2)} ${this.currency}`;
  }

  isEqual(value) {
    return this.amount.eq(this.toOperationValue(value));
  }

  lessThanZero() {
    return this.amount.round(4).lt(this.toOperationValue(new Big(0).round(4)));
  }

  lessThan(value) {
    return this.amount.lt(this.toOperationValue(value));
  }

  lessThanEqualTo(value) {
    return this.amount.lte(this.toOperationValue(value));
  }

  lessThanEqualToZero() {
    return this.amount.round(4).lte(new Big(0).round(4));
  }

  greaterThanZero() {
    return this.amount.round(4).gt(this.toOperationValue(new Big(0).round(4)));
  }

  greaterThan(value) {
    return this.amount.gt(this.toOperationValue(value));
  }

  greaterThanEqualTo(value) {
    return this.amount.gte(this.toOperationValue(value));
  }

  greaterThanEqualToZero() {
    return this.amount.gte(new Big(0));
  }

  isZero() {
    return this.amount.round(4).eq(new Big(0).round(4));
  }

  //output
  toFixed(points) {
    return new Currency(this.amount.toFixed(points), this.currency);
  }

  toNumber() {
    return this.amount.toNumber();
  }

  toFixedString(points = ROUNDING_PLACES) {
    return this.amount.toFixed(points);
  }

  toFixedNumber(points = ROUNDING_PLACES) {
    return this.amount.toNumber(points);
  }

  toBig() {
    return this.amount;
  }
}

Currency.prototype.toOperationValue = function (value) {
  if (value === '' || value === undefined || value === null) {
    return new Big(0.0);
  }

  if (value instanceof Currency) {
    return value.toBig();
  } else if (value instanceof Big) {
    return value;
  } else if (value.hasOwnProperty('amount')) {
    return new Big(value.amount);
  } else if (value.hasOwnProperty('total')) {
    return new Big(value.total);
  } else {
    return new Big(value);
  }
};
