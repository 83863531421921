import * as Actions from '../../actions/upsell/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  selectedItem: null,
  processing: false,
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
};

export default function siteUpsellPackagesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_PACKAGE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_PACKAGE_PROCESSING:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: true,
      };
    case Actions.SITE_PACKAGE_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.SITE_PACKAGE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        processing: false,
        error: null,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
      };
    case Actions.SITE_PACKAGE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case Actions.SITE_PACKAGE_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: [...state.data, action.payload],
        processing: false,
      };
    case Actions.SITE_PACKAGE_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.SITE_PACKAGE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((e) => e.id !== action.payload.id),
        error: null,
      };
    case Actions.SITE_PACKAGE_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_PACKAGE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.map((e) => (e.id === action.payload.id ? action.payload : e)),
        processing: false,
      };
    case Actions.SITE_PACKAGE_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    case Actions.SITE_PACKAGE_DUPLICATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.map((e) => (e.id === action.payload.id ? action.payload : e)),
        processing: false,
      };
    case Actions.SITE_PACKAGE_DUPLICATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
