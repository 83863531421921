import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RoomStatusTypes from '../../../../../../support/RoomStatusTypes';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import Objects from '../../../../../../support/Objects';

export default function EditProtel() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);
  const [endpoint, setEndpoint] = React.useState(
    configuration?.endpoint || 'https://service-test.protel.io/services/ProtelApiService.ProtelApiServiceHttpsSoap12Endpoint'
  );
  const [endpointCDProxy, setEndpointCDProxy] = React.useState(
    configuration?.endpointCDProxy || 'https://qa-pci.protel.net/cd-proxy-io/pci/1/io/reservations'
  );
  const [hotelCode, setHotelCode] = React.useState(configuration?.hotelCode || '3428');
  const [authorisation, setAuthorisation] = React.useState(configuration?.authorisation || 'cdea15c0-56cc-4a12-b4a9-a83f61fea2da');
  const [timeout, setTimeout] = React.useState(configuration?.timeout || '10000');
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'NZD');
  const [packageTransactions, setPackageTransactions] = React.useState(configuration?.packageTransactions || false);
  const [checkOutSettlementMode, setCheckOutSettlementMode] = React.useState(configuration?.checkOutSettlementMode || 'FIRST_WINDOW');
  const [allowedRoomStatusTypes, setAllowedRoomStatusTypes] = React.useState(configuration?.allowedRoomStatusTypes || []);
  const [useRoomStatusApi, setUseRoomStatusApi] = React.useState(configuration?.useRoomStatusApi || false);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        endpoint: Objects.sanitizeAndTrimString(endpoint),
        endpointCDProxy: endpointCDProxy,
        hotelCode: hotelCode,
        authorisation: authorisation,
        currency: currency,
        timeout: timeout,
        debug: debug,
        packageTransactions: packageTransactions,
        checkOutSettlementMode: checkOutSettlementMode,
        allowedRoomStatusTypes: allowedRoomStatusTypes,
        useRoomStatusApi: useRoomStatusApi,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    currency,
    debug,
    dispatch,
    enabled,
    endpoint,
    endpointCDProxy,
    hotelCode,
    authorisation,
    timeout,
    name,
    selectedItem,
    packageTransactions,
    checkOutSettlementMode,
    allowedRoomStatusTypes,
    useRoomStatusApi,
    selectedSite.id,
  ]);

  const isFormReady = name && endpoint && endpointCDProxy && authorisation && hotelCode;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Protel' : 'Create Protel'}
        subheader={'Protel property management system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              label="Friendly Name"
              type="text"
              variant={'outlined'}
              onChange={(event) => setName(event.target.value)}
              value={name}
              required
              error={!name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="endpoint"
              type="text"
              label="Base Endpoint URL"
              variant={'outlined'}
              onChange={(event) => setEndpoint(event.target.value)}
              value={endpoint}
              error={!endpoint}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="endpointCDProxy"
              type="text"
              label="Base Endpoint CDProxy URL"
              variant={'outlined'}
              onChange={(event) => setEndpointCDProxy(event.target.value)}
              value={endpointCDProxy}
              error={!endpointCDProxy}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="authorisation"
              type="password"
              label="Protel Authorisation"
              variant={'outlined'}
              onChange={(event) => setAuthorisation(event.target.value)}
              value={authorisation}
              error={!authorisation}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="hotelCode"
              type="text"
              label="Hotel Code"
              variant={'outlined'}
              onChange={(event) => setHotelCode(event.target.value)}
              value={hotelCode}
              error={!hotelCode}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="checkOutSettlementMode">Checkout Settlement Mode</InputLabel>
              <Select
                labelId="checkOutSettlementMode"
                value={checkOutSettlementMode}
                labelWidth={200}
                onChange={(event) => setCheckOutSettlementMode(event.target.value)}
              >
                <MenuItem value={'FIRST_WINDOW'}>First Folio Only</MenuItem>
                <MenuItem value={'PRIMARY_PROFILE_ONLY'}>Primary Profile Only</MenuItem>
                <MenuItem value={'ANY_GUEST_PAYABLE'}>Guest Payable Windows</MenuItem>
                <MenuItem value={'CHECK_BALANCE'}>Check Window Balance</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              fullWidth
              options={RoomStatusTypes}
              multiple
              onChange={(event, newValue) => setAllowedRoomStatusTypes(newValue)}
              value={allowedRoomStatusTypes}
              renderInput={(params) => <TextField {...params} label="Allowed Room Status Types" variant="outlined" />}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="packageTransactions">Package same Transactions</InputLabel>
              <Select
                labelId="packageTransactions"
                value={packageTransactions}
                labelWidth={210}
                onChange={(event) => setPackageTransactions(event.target.value)}
              >
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="useRoomStatusApi">Use Room Status API</InputLabel>
              <Select
                labelId="useRoomStatusApi"
                value={useRoomStatusApi}
                labelWidth={210}
                onChange={(event) => setUseRoomStatusApi(event.target.value)}
              >
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="debug">Debug</InputLabel>
              <Select labelId="debug" value={debug} labelWidth={50} onChange={(event) => setDebug(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="timeout"
              label="Callback Timeout (MS)"
              type="number"
              onChange={(event) => setTimeout(event.target.value)}
              variant={'outlined'}
              value={timeout}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="enabled">Enabled</InputLabel>
              <Select labelId="enabled" value={enabled} labelWidth={60} onChange={(event) => setEnabled(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
