import React from 'react';
import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    '&.MuiChip-root': {
      height: theme.spacing(2.1),
    },
  },
}));

export default function FormMultiChipSelect({
  label,
  value,
  setValue,
  margin = 'dense',
  style,
  options = [],
  error = false,
  disabled = false,
  displayValue = false,
  ...props
}) {
  const id = `${label}-id`.replace(/\s/g, '').toLowerCase();
  const classes = useStyles();

  return (
    <FormControl fullWidth variant={'outlined'} margin={margin} error={error} disabled={disabled}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        name={label}
        value={value}
        label={label}
        style={style}
        multiple
        onChange={(event) => setValue(event.target.value)}
        required
        renderValue={(selected) =>
          selected?.length > 0 &&
          options
            .filter((o) => selected.includes(o.value))
            .map((value) => (
              <Chip size={'small'} key={value.value} label={displayValue ? value.value : value.label} className={classes.chip} />
            ))
        }
        {...props}
      >
        {options.map((o) => (
          <MenuItem value={o.value} key={`s-${o.label}`}>
            <Checkbox checked={value.indexOf(o.value) > -1} />
            <ListItemText primary={o.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
