import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../../redux/actions/reservation/access/actions';
import SiteDoorActions from '../../../../redux/actions/siteDoor/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import GuestDialog from './guest/GuestDialog';
import GuestCard from './guest/GuestCard';
import KeyIssueBehavior, { KeyIssueBehaviorSelectOptions } from '../../../../../support/KeyIssueBehavior';
import DoorType from '../../../../../support/DoorType';
import KeyIssueMode, { KeyIssueModeOptions } from '../../../../../support/KeyIssueMode';
import KeyApprovalStatus from '../../../../../support/KeyApprovalStatus';
import DoorSelector from './components/DoorSelector';
import SiteFeature from '../../../../../support/SiteFeature';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';

export default function AccessReservationCreate() {
  const history = useHistory();
  const dispatch = useDispatch();

  const processing = useSelector((state) => state.accessReservationReducer.processing);
  const error = useSelector((state) => state.accessReservationReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const siteDoorList = useSelector((state) => state.siteDoorReducer.entityList).filter((d) => d.visible);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [resReference, setResReference] = React.useState('');
  const [arrival, setArrival] = React.useState(moment());
  const [departure, setDeparture] = React.useState(null);
  const [issueBehaviour, setIssueBehaviour] = React.useState(KeyIssueBehavior.AUTOMATIC);
  const [issueMode, setIssueMode] = React.useState(KeyIssueMode.JOIN);
  const [shareCard, setShareCard] = React.useState(false);
  const [selectedPrimaryRooms, setSelectedPrimaryRooms] = React.useState([]);
  const [selectedCommonRooms, setSelectedCommonRooms] = React.useState([]);
  const [guestEdit, setGuestEdit] = React.useState(null);
  const [guestList, setGuestList] = React.useState([]);
  const checkOutTime = selectedSite
    ? moment(selectedSite.checkOutTime, 'HH:mm:ss').add(1, 'days')
    : moment().add(1, 'days').hour(10).minute(0).second(0);

  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    dispatch(Actions.select(null)); // unselect any previous items
    dispatch(Actions.clearError());
    return () => {
      dispatch(Actions.clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(SiteDoorActions.fetch(0, 5000, { siteId: selectedSiteId }));
    }
  }, [dispatch, selectedSiteId]);

  const submitReservationToServer = React.useCallback(async () => {
    if (selectedSiteId) {
      const doorList = [...selectedPrimaryRooms, ...selectedCommonRooms].map((e) => {
        return {
          ...e.value,
          id: null,
        };
      });

      const response = await dispatch(
        Actions.add({
          siteId: selectedSiteId,
          reservationReference: resReference,
          arrival: arrival,
          departure: departure,
          issueBehaviour: issueBehaviour,
          issueMode: issueMode,
          shareCard: shareCard,
          approvalStatus: KeyIssueBehavior.AUTOMATIC === issueBehaviour ? KeyApprovalStatus.APPROVED : KeyApprovalStatus.PENDING,
          guestList: guestList,
          doorList: doorList,
        })
      );
      console.log('Added new reservation', response);

      if (response) {
        const now = moment();

        if (moment(arrival).isAfter(now)) {
          history.push('/reservation/access/upcoming');
        } else {
          history.push('/reservation/access/current');
        }
      }
    }
  }, [
    selectedSiteId,
    selectedPrimaryRooms,
    selectedCommonRooms,
    dispatch,
    resReference,
    arrival,
    departure,
    issueBehaviour,
    issueMode,
    shareCard,
    guestList,
    history,
  ]);

  const handleAddOrEditGuest = React.useCallback(
    (payload) => {
      const isEditOperation = guestList.find((g) => g.reference === payload.reference);

      if (isEditOperation) {
        setGuestList([...guestList].map((g) => (g.reference === payload.reference ? payload : g)));
        setGuestEdit(null);
        setModalOpen(false);
      } else {
        setGuestList([...guestList, payload]);
        setModalOpen(false);
      }
    },
    [guestList]
  );

  const handleRemoveGuest = React.useCallback(
    (g) => {
      const newGuests = guestList.filter((guest) => guest.reference !== g.reference);
      setGuestList(newGuests);
    },
    [guestList]
  );

  const handleEditGuest = (guest) => {
    setGuestEdit(guest);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setGuestEdit(null);
    setModalOpen(false);
  };

  const isFormReady = resReference && selectedPrimaryRooms.length > 0 && arrival && departure && guestList.length > 0;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header="New Guest Reservation"
            subheader={'Enter your the guest details below to create a new booking'}
            buttonName="Create"
            buttonLoading={processing}
            onClick={submitReservationToServer}
            disabled={!isFormReady}
          />

          <CardContent>
            {guestList.length > 0 && (
              <Alert severity="info" variant={'filled'} className={'mb-2'}>
                {'You have unsaved changes please click "Create" to save this reservation'}
              </Alert>
            )}

            {selectedSite?.featureList?.filter((sf) => sf.code === SiteFeature.ALWAYS_OVERRIDE_KEYS).length > 0 && (
              <Alert severity="warning" variant={'filled'} className={'mb-2'}>
                Always Override Digital Keys feature is enabled for this site. This means that issuing a key for a specific room will make
                all other keys for this room invalid.
              </Alert>
            )}

            {siteDoorList.length <= 0 && (
              <Alert severity="warning" variant={'filled'} className={'mb-2'}>
                No doors available for this property. Please check the connection to the lock system
              </Alert>
            )}

            {error && (
              <Alert severity="error" variant={'filled'} className={'mb-2'}>
                {`Unable to create reservation. Please try again later. Error : ${error}`}
              </Alert>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Booking Reference'}
                  value={resReference}
                  setValue={setResReference}
                  error={!resReference}
                  margin={'none'}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect label={'Issue Mode'} margin={'none'} value={issueMode} setValue={setIssueMode} options={KeyIssueModeOptions} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Issue Behaviour'}
                  value={issueBehaviour}
                  setValue={setIssueBehaviour}
                  margin={'none'}
                  options={KeyIssueBehaviorSelectOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant={'outlined'}
                  type="text"
                  value={KeyIssueBehavior.AUTOMATIC === issueBehaviour ? 'Key issued automatically' : 'Manual approval required'}
                  label="Key Approval"
                  disabled
                  fullWidth
                  required
                />
              </Grid>

              {siteDoorList.length > 0 && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <DoorSelector
                      doorType={DoorType.PRIMARY}
                      doorList={siteDoorList}
                      selectedDoorList={selectedPrimaryRooms}
                      setSelectedDoorList={setSelectedPrimaryRooms}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <DoorSelector
                      doorType={DoorType.COMMON}
                      doorList={siteDoorList}
                      selectedDoorList={selectedCommonRooms}
                      setSelectedDoorList={setSelectedCommonRooms}
                    />
                  </Grid>
                </React.Fragment>
              )}

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DateTimePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="Start Date"
                    value={arrival}
                    autoOk={true}
                    error={!arrival}
                    errorText={'Please enter a start date'}
                    onChange={(date) => {
                      setArrival(date);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DateTimePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="End Date"
                    value={departure}
                    minDate={arrival}
                    autoOk={true}
                    error={!departure}
                    initialFocusedDate={checkOutTime}
                    errorText={'Please enter a end date'}
                    onChange={(date) => {
                      setDeparture(date);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormBooleanSelect label={'Share Card'} value={shareCard} setValue={setShareCard} margin={'none'} />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={'Guest List'}
            subheader={'Guests who have been assigned to this reservation'}
            buttonName={'Add Guest'}
            buttonColour={'primary'}
            onClick={() => setModalOpen(true)}
            disableBackButton={true}
          />

          <CardContent>
            {guestList.map((guest, idx) => {
              return (
                <GuestCard
                  key={`g-${idx}-${guest.email}`}
                  guest={guest}
                  showEditGuestModal={handleEditGuest}
                  deleteGuest={handleRemoveGuest}
                />
              );
            })}

            {guestList.length <= 0 && (
              <Alert severity="warning" variant={'filled'}>
                {'In order to create a reservation you must have at least one guest'}
              </Alert>
            )}
          </CardContent>
        </Card>
      </Grid>

      <GuestDialog isOpen={modalOpen} onClose={handleCloseModal} onSubmit={handleAddOrEditGuest} guest={guestEdit} />
    </Grid>
  );
}
