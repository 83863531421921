import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import FoodVendorActions from '../../../../../../redux/actions/inRoom/ordering/vendor/actions';
import EventHookActions from '../../../../../../redux/actions/eventHook/actions';
import { InfrasysEventHookSubTypes } from './InfrasysEventHookType';
import SystemEventHookType from '../../../../../../../support/SystemEventHookType';
import EditEventHook from '../../hook/EditEventHook';

export default function InfrasysHookList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSystem = useSelector((state) => state.siteResourcesReducer.selectedItem);

  const hookList = useSelector((state) => state.eventHookReducer.entityList);
  const processing = useSelector((state) => state.eventHookReducer.processing);
  const vendorCodes = useSelector((state) => state.foodVendorReducer.vendorCodes);

  useEffect(() => {
    dispatch(FoodVendorActions.fetchCodes(selectedSite?.id));
    dispatch(EventHookActions.fetchAll(selectedSite?.id, SystemEventHookType.INFRASYS_POS.value));
  }, [selectedSite?.id, dispatch]);

  if (!selectedSite || !vendorCodes?.length) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={'Update Event Hooks'} />
      <CardContent>
        {vendorCodes?.length > 0 &&
          vendorCodes.map((code) => {
            const vendorHookItems = [];
            InfrasysEventHookSubTypes.forEach((hook) => {
              const existingHook = [...hookList]?.find((h) => JSON.parse(h.hookInfo)?.outletCode === code && h.hookSubtype === hook.value);
              if (existingHook) {
                vendorHookItems.push(existingHook);
              } else {
                vendorHookItems.push({
                  systemId: selectedSystem.id,
                  siteId: selectedSite.id,
                  callbackUrl: '',
                  systemType: SystemEventHookType.INFRASYS_POS.code,
                  hookSubtype: hook.value,
                  hookInfo: JSON.stringify({ outletCode: code }),
                });
              }
            });

            return (
              <Box>
                <Typography variant="body1">Outlet - {code}</Typography>
                <Box ml={2} mb={1}>
                  {vendorHookItems?.map((item) => (
                    <EditEventHook item={item} siteId={selectedSite?.id} processing={processing} />
                  ))}
                </Box>
              </Box>
            );
          })}
      </CardContent>
    </Card>
  );
}
