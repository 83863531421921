import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodTaxActions from '../../../../redux/actions/inRoom/ordering/foodTax/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import { PortionType, PortionTypes } from '../../../../../support/PortionTypes';
import { ComputationInclusion, ComputationInclusions } from '../../../../../support/ComputationInclusion';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

export default function FoodTaxEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodTaxReducer);
  const [taxName, setTaxName] = useState(selectedItem?.taxName || '');
  const [taxCode, setTaxCode] = useState(selectedItem?.taxCode || '');
  const [taxAmount, setTaxAmount] = useState(selectedItem?.taxAmount || 0);
  const [taxType, setTaxType] = useState(selectedItem?.taxType || PortionType.RATE);
  const [taxInclusion, setTaxInclusion] = useState(selectedItem?.taxInclusion || ComputationInclusion.CHECK);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [sequence, setSequence] = useState(selectedItem?.sequence || 1);

  useEffect(() => {
    dispatch(FoodTaxActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_TAX_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_TAX_ADD_SUCCESS' ||
      lastActionType === 'FOOD_TAX_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      taxName,
      taxCode,
      taxAmount,
      taxType,
      taxInclusion,
      enabled,
      sequence,
    };

    if (selectedItem) {
      dispatch(FoodTaxActions.update(payload, `/api/private/portal/food/tax/${selectedItem.id}`, false));
    } else {
      dispatch(FoodTaxActions.add(payload, { vendorId: vendorId }));
    }
  };

  const isFormReady = taxName && taxCode && taxAmount && taxType && taxInclusion;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Tax' : 'Create Food Tax'}
        subheader={'A Food Tax contains fixed or percentage amount to be included in a check'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodTaxActions.delete(selectedItem, `/api/private/portal/food/tax/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food tax - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={taxName} setValue={setTaxName} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'External ID'} value={selectedItem?.externalId || ''} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Code'} value={taxCode} setValue={setTaxCode} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Type'} value={taxType} setValue={setTaxType} options={PortionTypes} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Amount'}
              value={taxAmount}
              setValue={setTaxAmount}
              type={'number'}
              inputProps={{ step: '1' }}
              reactInputProps={{
                endAdornment: taxType === PortionType.RATE && <InputAdornment position="end">%</InputAdornment>,
                startAdornment: taxType === PortionType.FIXED && <InputAdornment position="start">$</InputAdornment>,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Inclusion'} value={taxInclusion} setValue={setTaxInclusion} options={ComputationInclusions} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sequence'} value={sequence} setValue={setSequence} type={'number'} inputProps={{ step: '1' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
