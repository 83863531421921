import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Divider, Grid, InputAdornment, Typography } from '@material-ui/core';
import { AddAlarm, AttachMoney, Check, ChevronRight, Close, DateRange, Edit, ExpandMore, Save, ZoomOut } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { TreeItem, TreeView } from '@material-ui/lab';
import ActivityScheduleActions from '../../../redux/actions/activity/schedule/actions';
import ActivityTimeActions from '../../../redux/actions/activity/time/actions';
import ActivityRateActions from '../../../redux/actions/activity/rate/actions';
import FormField from '../../../../components/form/FormField';
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import FormSelect from '../../../../components/form/FormSelect';
import { ScheduleType, ScheduleTypes } from '../../../../support/ScheduleTypes';
import FormMultiChipSelect from '../../../../components/form/FormMultiChipSelect';
import { DaysOfWeekShort, EverydayShort } from '../../../../support/DaysOfWeekShort';
import MultiDatePicker from '../../control/MultiDatePicker';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelLinethrough: {
    textDecoration: 'line-through',
    textDecorationThickness: '1px',
  },
  labelRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0),
  },
  flexRight: {
    justifyContent: 'flex-end',
  },
  flexLeft: {
    justifyContent: 'flex-start',
  },
  flexLeftButton: {
    marginRight: theme.spacing(0.5),
  },
  flexRightButton: {
    marginLeft: theme.spacing(0.5),
  },
}));

const initialSchedule = {
  name: '',
  from: moment(),
  to: moment().add(1, 'days'),
  type: 'schedule',
  enabled: true,
};

const initialTime = {
  from: moment().format('HH:mm'),
  to: moment().add(1, 'hour').format('HH:mm'),
  scheduleType: ScheduleType.DAYS_OF_WEEK,
  schedule: EverydayShort,
  excludedDates: [],
  type: 'time',
  enabled: true,
};

const initialRate = {
  name: '',
  price: 0,
  type: 'rate',
  capacity: 0,
};

export default function ActivityScheduleEdit() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activity = useSelector((state) => state.activityReducer.selectedItem);
  const activityId = activity?.id;
  const schedules = useSelector((state) => state.activityScheduleReducer.entityList);
  // Where the tree is based
  const selected = useSelector((state) => state.activityScheduleReducer.selectedItem) || {};
  const [branch, setBranch] = useState({});
  const [expanded, setExpanded] = useState([]);

  useLayoutEffect(() => {
    try {
      dispatch(ActivityScheduleActions.fetch(0, 999, {}, { path: `${activityId}/schedule` }));
      dispatch(ActivityScheduleActions.select({}));
    } catch {}
  }, [dispatch, activityId]);

  const search = (tree, id) => {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].type + tree[i].id === id) {
        return tree[i];
      } else if (tree[i].times) {
        const result = search(tree[i].times, id);
        if (result) {
          return result;
        }
      } else if (tree[i].rates) {
        const result = search(tree[i].rates, id);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  const handleBranchSelect = async (event, value) => {
    const selected = search(schedules, value) || {};
    dispatch(ActivityScheduleActions.select(selected));
    setBranch(selected);
  };

  const handleUpdate = async (selected) => {
    await dispatch(ActivityScheduleActions.select(selected));
  };

  const handleTimeAdd = async () => {
    await dispatch(ActivityTimeActions.add(selected, branch));
  };

  const handleScheduleDisable = async () => {
    await dispatch(ActivityScheduleActions.disable(selected));
  };

  const handleRateAdd = async () => {
    await dispatch(ActivityRateActions.add(selected, branch));
  };

  const handleTimeUpdate = async () => {
    await dispatch(ActivityTimeActions.update(selected, `/api/private/portal/activity/schedule/time/${selected.id}`, false));
    await dispatch(ActivityScheduleActions.updateChild(selected));
  };

  const handleTimeDisable = async () => {
    await dispatch(ActivityTimeActions.disable(selected));
  };

  const handleRateUpdate = async () => {
    await dispatch(ActivityRateActions.update(selected, null, false));
    await dispatch(ActivityScheduleActions.updateChild(selected));
  };

  const handleToggle = (event, nodeIds) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds);
    }
  };

  const handleCollapse = async () => {
    setExpanded([]);
    await handleUpdate({});
    setBranch({});
  };

  const renderLabel = (itemData) => {
    if (itemData.schedule) {
      return (
        <div className={classes.labelRoot}>
          <Typography variant="body1" className={itemData.enabled === false ? classes.labelLinethrough : classes.labelText}>
            {moment.utc(itemData.from, 'HH:mm').format('hh:mm A')} - {moment.utc(itemData.to, 'HH:mm').format('hh:mm A')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.labelRoot}>
        <Typography variant="body1" className={itemData.enabled === false ? classes.labelLinethrough : classes.labelText}>
          {itemData.name}
        </Typography>
      </div>
    );
  };

  const mapTreeItems = (treeItems) => {
    return treeItems.map((treeItemData) => {
      let children = undefined;
      if (treeItemData.times?.length > 0) {
        children = mapTreeItems(treeItemData.times);
      } else if (treeItemData.rates?.length > 0) {
        children = mapTreeItems(treeItemData.rates);
      }

      return (
        <TreeItem key={treeItemData.type + treeItemData.id} nodeId={treeItemData.type + treeItemData.id} label={renderLabel(treeItemData)}>
          {children}
        </TreeItem>
      );
    });
  };

  const renderFields = () => {
    if (selected.type === 'schedule') {
      return (
        <>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormField
                label={'Schedule Name'}
                value={selected.name}
                InputLabelProps={{ shrink: true }}
                setValue={(value) => handleUpdate({ ...selected, name: value })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker
                fullWidth
                inputVariant={'outlined'}
                placeholder="From"
                value={selected.from}
                autoOk={true}
                onChange={(date) => handleUpdate({ ...selected, from: date })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateTimePicker
                fullWidth
                inputVariant={'outlined'}
                placeholder="To"
                value={selected.to}
                autoOk={true}
                onChange={(date) => handleUpdate({ ...selected, to: date })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                type={'submit'}
                color={'secondary'}
                variant={'contained'}
                startIcon={!selected.id ? <Save /> : <Edit />}
                disabled={branch === selected || !(selected.from && selected.name)}
                onClick={() =>
                  !selected.id
                    ? dispatch(ActivityScheduleActions.add(selected, { path: `${activityId}/schedule` }))
                    : dispatch(ActivityScheduleActions.update(selected, `/api/private/portal/activity/schedule/${selected.id}`, false))
                }
                fullWidth
              >
                {!selected.id ? 'Save' : 'Update'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                type={'submit'}
                variant={'contained'}
                startIcon={selected.enabled ? <Close /> : <Check />}
                onClick={() => (!selected.id ? handleUpdate(branch) : handleScheduleDisable())}
                fullWidth
              >
                {!selected.id ? 'Cancel' : selected.enabled ? 'Disable' : 'Enable'}
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        </>
      );
    } else if (selected.type === 'time') {
      return (
        <>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="From"
                value={moment.utc(selected.from, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => handleUpdate({ ...selected, from: moment.utc(time).format('HH:mm') })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="To"
                value={moment.utc(selected.to, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => handleUpdate({ ...selected, to: moment.utc(time).format('HH:mm') })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect
                label={'Schedule Type'}
                value={selected.scheduleType}
                setValue={(value) => handleUpdate({ ...selected, scheduleType: value })}
                options={ScheduleTypes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {selected.scheduleType === ScheduleType.DAYS_OF_WEEK && (
                <FormMultiChipSelect
                  label={'Schedule'}
                  value={selected.scheduleType === ScheduleType.DAYS_OF_WEEK ? selected.schedule : EverydayShort}
                  setValue={(value) => handleUpdate({ ...selected, schedule: value })}
                  options={DaysOfWeekShort}
                  displayValue={true}
                />
              )}

              {selected.scheduleType === ScheduleType.DATES && (
                <MultiDatePicker
                  key={selected.type + selected.id}
                  label={'Schedule'}
                  selected={selected.schedule}
                  setValue={(value) => handleUpdate({ ...selected, schedule: value })}
                />
              )}
            </Grid>
            {selected.scheduleType === ScheduleType.DAYS_OF_WEEK && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MultiDatePicker
                  key={selected.type + selected.id}
                  label={'Excluded Dates'}
                  selected={selected.excludedDates}
                  setValue={(value) => handleUpdate({ ...selected, excludedDates: value })}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    type={'submit'}
                    color={'secondary'}
                    variant={'contained'}
                    startIcon={<Edit />}
                    disabled={
                      branch === selected || !(selected.from && selected.to && selected.scheduleType && selected.schedule?.length > 0)
                    }
                    onClick={() => (!selected.id ? handleTimeAdd() : handleTimeUpdate())}
                    fullWidth
                  >
                    {!selected.id ? 'Save' : 'Update'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    type={'submit'}
                    variant={'contained'}
                    startIcon={selected.enabled ? <Close /> : <Check />}
                    onClick={() => (!selected.id ? handleUpdate(branch) : handleTimeDisable())}
                    fullWidth
                  >
                    {!selected.id ? 'Cancel' : selected.enabled ? 'Disable' : 'Enable'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </>
      );
    } else if (selected.type === 'rate') {
      return (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField
              label={'Name'}
              value={selected.name}
              InputLabelProps={{ shrink: true }}
              setValue={(value) => handleUpdate({ ...selected, name: value })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Price'}
              value={selected.price}
              InputLabelProps={{ shrink: true }}
              reactInputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              setValue={(value) => handleUpdate({ ...selected, price: value })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Capacity'}
              value={selected.capacity}
              InputLabelProps={{ shrink: true }}
              setValue={(value) => handleUpdate({ ...selected, capacity: value })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={!selected.id ? 6 : 12} lg={!selected.id ? 6 : 12}>
            <Button
              type={'submit'}
              color={'secondary'}
              variant={'contained'}
              startIcon={<Edit />}
              disabled={branch === selected || !(selected.price && selected.capacity && selected.name)}
              onClick={() => (!selected.id ? handleRateAdd() : handleRateUpdate())}
              fullWidth
            >
              {!selected.id ? 'Save' : 'Update'}
            </Button>
          </Grid>
          {!selected.id && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                type={'submit'}
                variant={'contained'}
                startIcon={selected.enabled ? <Close /> : <Check />}
                onClick={() => handleUpdate(branch)}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          )}
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5" className={classes.labelRoot}>
              Select a schedule from the left to manage
            </Typography>
          </Grid>
        </>
      );
    }
  };

  return (
    <Card>
      <CardHeader title={'Activity Availability'} subheader={'Availability of an activity to be used to generate schedules'} />
      <Divider variant={'middle'} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={classes.labelRoot}>
              <div className={classes.flexLeft}>
                <Button
                  variant={'contained'}
                  onClick={() => handleUpdate(initialSchedule)}
                  className={classes.flexLeftButton}
                  disabled={!selected}
                  startIcon={<DateRange />}
                >
                  Add Schedule
                </Button>
                <Button
                  variant={'contained'}
                  onClick={() => (selected.type === 'schedule' ? handleUpdate(initialTime) : handleUpdate(initialRate))}
                  className={classes.flexLeftButton}
                  startIcon={selected.type === 'schedule' ? <AddAlarm /> : <AttachMoney />}
                  disabled={selected.type === 'rate' || !selected.id}
                >
                  Add {selected.type === 'schedule' ? 'Time' : 'Rate'}
                </Button>
              </div>
              <div className={classes.flexRight}>
                <Button variant={'contained'} onClick={handleCollapse} className={classes.flexRightButton} startIcon={<ZoomOut />}>
                  Collapse All
                </Button>
              </div>
            </div>
            {schedules?.length > 0 && (
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                onNodeSelect={handleBranchSelect}
                onNodeToggle={handleToggle}
                expanded={expanded}
              >
                {mapTreeItems(schedules)}
              </TreeView>
            )}
            {schedules?.length === 0 && (
              <Typography variant="h6" className={classes.labelRoot}>
                Add a schedule above to manage
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={1}>
                  {selected && renderFields(selected)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
