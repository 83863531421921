import actionCreator from '../../../../support/actionCreator';

const FoodVendorScheduleActions = {
  ...actionCreator.createActions('Food Vendor Schedule', 'FOOD_VENDOR_SCHEDULE', '/api/private/portal/food/vendor/{vendorId}/schedule'),
  selectAndNavigate: (item, history) => async (dispatch) => {
    await dispatch({ type: 'FOOD_VENDOR_SCHEDULE_SELECT', payload: item });
    history.push(`/food/vendor/schedule/view/${item.id}`);
  },
};

export default FoodVendorScheduleActions;
