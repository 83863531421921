import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import VendorActions from '../../../redux/actions/vendor/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(VendorActions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'website',
    label: 'Website',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phone',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function VendorList() {
  const dispatch = useDispatch();
  const vendorList = useSelector((state) => state.vendorReducer.entityList);
  const processing = useSelector((state) => state.vendorReducer.processing);
  const page = useSelector((state) => state.vendorReducer.page);
  const error = useSelector((state) => state.vendorReducer.error);

  return (
    <AutoTable
      title="Vendor List"
      subheader={'A vendor is an external entity that provides service to the Organisation'}
      handleRefreshReport={() => dispatch(VendorActions.fetch())}
      selectItem={(item) => dispatch(VendorActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(VendorActions.fetch(newPage, pageSize, null))}
      page={page}
      error={error}
      columns={columns}
      prefix="VENDOR"
      createNewURL="/vendor/new"
      detailURL="/vendor/view/{id}"
      idColumn={'id'}
      data={vendorList}
    />
  );
}
