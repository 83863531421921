import React from 'react';
import PropTypes from 'prop-types';

export default class IconWidget extends React.Component {
  static propTypes = {
    title: PropTypes.any,
    message: PropTypes.any,
    icon: PropTypes.object,
    iconBg: PropTypes.string,
    footer: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={`widget ${this.props.className || ''}`}>
        <div className={`widget-icon ${this.props.iconBg || ''}`}>{this.props.icon}</div>

        <div className="widget-body">
          <div className="widget-body-title">{this.props.title}</div>
          <div className="widget-body-message">{this.props.message}</div>
          {this.props.footer && <div className="widget-body-footer">{this.props.footer}</div>}
        </div>
      </div>
    );
  }
}
