import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodChargeActions from '../../../../redux/actions/inRoom/ordering/foodCharge/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Check, DeleteOutlined } from '@material-ui/icons';
import { PortionType } from '../../../../../support/PortionTypes';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodChargeActions.delete(value, `/api/private/portal/food/charge/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'chargeName',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'chargeCode',
    label: 'Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External Id',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'chargeAmount',
    label: 'Amount',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      const chargeAmount = value.chargeAmount;
      return value.chargeType === PortionType.FIXED ? '$ ' + chargeAmount : chargeAmount + '%';
    },
  },
  {
    id: 'chargeType',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'chargeCategory',
    label: 'Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.enabled) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodChargeList() {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const entityList = useSelector((state) => state.foodChargeReducer.entityList);
  const processing = useSelector((state) => state.foodChargeReducer.processing);
  const page = useSelector((state) => state.foodChargeReducer.page);
  const error = useSelector((state) => state.foodChargeReducer.error);

  return (
    <AutoTable
      title="Food Charges"
      subheader={'An additional fixed or percentage charge to be included in a check.'}
      handleRefreshReport={() => dispatch(FoodChargeActions.fetch(0, 25, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodChargeActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodChargeActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_CHARGE"
      createNewURL="/food/charge/new"
      detailURL="/food/charge/view/{id}"
      idColumn={'id'}
      data={entityList}
    />
  );
}
