import FormSelect from './FormSelect';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentProviderActions from '../../administration/redux/actions/paymentProvider/actions';

export default function PaymentProviderSelect({ label = 'Payment Provider', value, setValue, corporationId }) {
  const dispatch = useDispatch();
  const paymentProviderList = useSelector((state) => state.paymentProviderReducer.entityList);

  // fetch all payment providers accessible to a user
  useEffect(() => {
    if (corporationId) {
      dispatch(PaymentProviderActions.fetch(0, 50, { corporationId }));
    }
  }, [dispatch, corporationId]);

  return (
    <FormSelect
      label={label}
      value={value}
      setValue={setValue}
      options={paymentProviderList.map((p) => {
        return { value: p.id, label: p.name };
      })}
    />
  );
}
