const ChargeRule = {
  ROOM_ORDER: 'ROOM_ORDER',
  CREDIT_CARD: 'CREDIT_CARD',
  HOLIDAY: 'HOLIDAY',
  SUNDAY_SURCHARGE: 'SUNDAY_SURCHARGE',
};

const ChargeRuleOptions = [
  { value: ChargeRule.ROOM_ORDER, label: 'Room Order' },
  { value: ChargeRule.CREDIT_CARD, label: 'Credit Card' },
  { value: ChargeRule.HOLIDAY, label: 'Holiday' },
  { value: ChargeRule.SUNDAY_SURCHARGE, label: 'Sunday Surcharge' },
];

export { ChargeRule, ChargeRuleOptions };
