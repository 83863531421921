import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import FormField from '../../../../../components/form/FormField';
import FoodSetItem from './FoodSetItem';
import { AddOutlined, DeleteOutlined } from '@material-ui/icons';

export default function FoodSetChoice({ vendorId, foodItemId, choice, index, setChoice, deleteChoice, setError }) {
  const [choiceName, setChoiceName] = useState(choice?.choiceName || '');
  const [choiceDescription, setChoiceDescription] = useState(choice?.choiceDescription || '');
  const [choiceIndex, setChoiceIndex] = useState(choice?.choiceIndex);
  const [choiceMin, setChoiceMin] = useState(choice?.choiceMin || 0);
  const [choiceMax, setChoiceMax] = useState(choice?.choiceMax || 1);
  const [items, setItems] = useState(choice?.items || []);
  const available = !!choice?.available;
  const [childError, setChildError] = useState(false);

  const handleSetItem = useCallback(
    (index) => (item) => {
      setItems((prev) => {
        const result = [...prev];
        result.splice(index, 1, item);
        return result;
      });
    },
    []
  );

  const handleDeleteItem = useCallback((index) => {
    setItems((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
  }, []);

  useEffect(() => {
    setChoice(index)({
      id: choice?.id,
      foodItemId: foodItemId,
      vendorId: vendorId,
      choiceName: choiceName,
      choiceDescription: choiceDescription,
      choiceIndex: choiceIndex,
      choiceMin: choiceMin,
      choiceMax: choiceMax,
      items: items,
      available: available,
    });

    if (!choiceName || !choiceIndex || (!choiceMin && choiceMin !== 0) || !choiceMax || choiceMax < 1 || childError) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    vendorId,
    choiceName,
    choiceDescription,
    choiceIndex,
    choiceMin,
    choiceMax,
    choice?.foodItemId,
    childError,
    items,
    index,
    choice?.id,
    setChoice,
    setError,
    foodItemId,
    available,
  ]);

  return (
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'center',
            }}
          >
            <IconButton size={'small'} color={'inherit'} onClick={() => setItems((prev) => [...prev, {}])}>
              <AddOutlined />
            </IconButton>
            <IconButton size={'small'} color={'inherit'} onClick={() => deleteChoice(index)}>
              <DeleteOutlined />
            </IconButton>
            <FormField
              label={'Index'}
              value={choiceIndex}
              setValue={setChoiceIndex}
              required
              type={'number'}
              inputProps={{ step: '1', min: '1' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormField label={'Name'} value={choiceName} setValue={setChoiceName} required autoFocus={!choice?.choiceName} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormField label={'Description'} value={choiceDescription} setValue={setChoiceDescription} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormField label={'External ID'} value={choice?.externalId} InputLabelProps={{ shrink: true }} disabled />
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <FormField
            label={'Min'}
            required
            value={choiceMin}
            setValue={setChoiceMin}
            type={'number'}
            error={!choiceMin && choiceMin !== 0}
            inputProps={{ step: '1', min: '0' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <FormField
            label={'Max'}
            required
            value={choiceMax}
            setValue={setChoiceMax}
            type={'number'}
            error={!choiceMax || choiceMax < 1}
            inputProps={{ step: '1', min: '1' }}
          />
        </Grid>
        {items
          .filter((item) => item.available)
          .map((data, idx) => (
            <FoodSetItem
              vendorId={vendorId}
              key={`fsi-${index}-${idx}-${data.id}`}
              choiceIndex={choiceIndex}
              index={idx}
              item={data}
              setItem={handleSetItem}
              deleteItem={handleDeleteItem}
              setChildError={setChildError}
            />
          ))}
      </Grid>
    </Box>
  );
}
