import { createTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core';

export function getColours(isDark) {
  return {
    primary: '#24A9D2',
    secondary: '#ffb13d', //accent colour
    light: '#f9fafb',
    dark: '#3C3744',
    success: '#609e15',
    info: '#24A9D2',
    warning: '#e6ad28',
    danger: '#d70f20',
    black: '#000000',
    white: '#ffffff',
    background: isDark ? '#3c3744' : '#f0f8ff',
    disabled: '#c6c5c7',
    hint: '#c6c5c7',
  };
}

const GRAYS = ['#999', '#777', '#3C4858', '#AAAAAA', '#D2D2D2', '#DDD', '#b4b4b4', '#555555', '#333', '#a9afbb', '#eee', '#e7e7e7'];

const FONTS = [
  'Open Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const getTheme = (isDark) => {
  const colours = getColours(isDark);

  const style = document.documentElement.style;

  // publish as CSS variables
  Object.keys(colours).forEach((key) => {
    style.setProperty(`--${key}`, colours[key]);
  });

  const theme = createTheme({
    type: isDark ? 'dark' : 'light',
    typography: {
      fontSize: 14,
      htmlFontSize: 16,
      useNextVariants: true,
      fontFamily: FONTS.join(','),
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 700,
      },
      allVariants: {
        hint: colours.hint,
      },
    },
    shape: {
      borderRadius: '2px',
    },
    palette: {
      common: {
        black: colours['black'],
        white: colours['white'],
        transparent: 'transparent',
      },
      text: {
        primary: isDark ? colours['white'] : colours['black'],
        secondary: isDark ? colours['white'] : colours['black'], // colour of labels
        disabled: colours['disabled'],
        hint: colours['hint'], // placeholders/calendar picker disabled etc
      },
      primary: {
        // light: colours['primaryLight'],
        main: colours['primary'],
        // dark: colours['primaryDark'],
        contrastText: colours['white'],
      },
      secondary: {
        // light: colours['secondaryLight'],
        main: colours['secondary'],
        // dark: colours['secondaryDark'],
        contrastText: colours['white'],
      },
      success: {
        main: colours['success'],
      },
      info: {
        main: colours['info'],
      },
      warning: {
        main: colours['warning'],
      },
      error: {
        main: colours['danger'],
      },
      background: {
        paper: colours['background'],
        default: colours['background'], // table backgrounds etc
      },
      action: {
        active: isDark ? colours['white'] : colours['black'],
        disabled: colours['disabled'], // The color of a disabled button
      },
      // action: {
      //   // The color of an active action like an icon button.
      //   active: 'rgba(0, 0, 0, 0.54)',
      //   // The color of an hovered action.
      //   hover: 'rgba(0, 0, 0, 0.04)',
      //   hoverOpacity: 0.04,
      //   // The color of a selected action.
      //   selected: 'rgba(0, 0, 0, 0.08)',
      //   selectedOpacity: 0.08,
      //   // The color of a disabled action.
      //   disabled: 'rgba(0, 0, 0, 0.26)',
      //   // The background color of a disabled action.
      //   disabledBackground: 'rgba(0, 0, 0, 0.12)',
      //   disabledOpacity: 0.38,
      //   focus: 'rgba(0, 0, 0, 0.12)',
      //   focusOpacity: 0.12,
      //   activatedOpacity: 0.12
      // },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      //contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      colours: colours,
      grays: GRAYS,
      // shadows: getBoxShadows(colours)
    },
    overrides: {
      MuiCardHeader: {
        title: {
          textTransform: 'capitalize',
        },
      },
      MuiChip: {
        deleteIcon: {
          color: isDark ? 'hsla(0,0%,0%,0.87)' : 'hsla(0,0%,0%,0.26)',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: isDark ? 'hsl(0,0%,75%)' : 'hsla(0,0%,0%,0.23)',
        },
      },
      MuiAutocomplete: {
        option: {
          '&[data-focus="true"]': {
            backgroundColor: colours['primary'],
          },
        },
      },
      MuiAlert: {
        root: {
          marginBottom: '0.5rem',
        },
      },
    },
  });

  return responsiveFontSizes(theme);
};

function convertHexToRgb(input) {
  input = input + '';
  input = input.replace('#', '').trim();
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error(`input (${input}) is not a valid hex color`);
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ', ' + parseInt(second, 16) + ', ' + parseInt(last, 16);
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export { getTheme, convertHexToRgb, hslToHex };
