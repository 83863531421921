import * as DataTypes from '../../../../../../constants/DataTypes';
import * as Actions from '../../../../../redux/actions/compendium/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import FoodVendorActions from '../../../../../redux/actions/inRoom/ordering/vendor/actions';

const columns = [
  {
    id: 'disable',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.deleteItem(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'categoryName',
    label: 'Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'compendiumType',
    label: 'Category Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'orderNo',
    label: 'Order',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function CategoryList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const categories = useSelector((state) => state.compendiumCategoryReducer.data);
  const processing = useSelector((state) => state.compendiumCategoryReducer.processing);
  const lastActionType = useSelector((state) => state.compendiumCategoryReducer.lastActionType);
  const page = useSelector((state) => state.compendiumCategoryReducer.page);
  const error = useSelector((state) => state.compendiumCategoryReducer.error);

  useEffect(() => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 25, selectedSite.id));
      dispatch(FoodVendorActions.fetch(0, 5000, { sort: 'enabled,desc&sort=name,asc' }, { siteId: selectedSite.id }));
    }
  }, [selectedSite, dispatch]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, selectedSite.id));
  };

  return (
    <AutoTable
      title="Digital Compendium Categories"
      subheader={'Categories for articles'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="CATEGORY"
      newButtonName="New Category"
      createNewURL="/site/compendium/category/new"
      detailURL="/site/compendium/category/view/{id}"
      idColumn={'id'}
      data={categories}
      lastActionType={lastActionType}
    />
  );
}
