import React, { useEffect } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/site/actions';
import ThemeActions from '../../../redux/actions/theme/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

// Table column rendering information.
const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Site Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'webCheckInEnabled',
    label: 'Web Check In',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'reservationSync',
    label: 'Reservation Sync',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'reservationSyncSchedule',
    label: 'Reservation Sync (Minutes)',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'reservationLastSync',
    label: 'Last Sync',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function SiteList() {
  const dispatch = useDispatch();
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);
  const siteList = useSelector((state) => state.siteReducer.entityList);
  const lastActionType = useSelector((state) => state.siteReducer.lastActionType);
  const page = useSelector((state) => state.siteReducer.page);
  const error = useSelector((state) => state.siteReducer.error);

  useEffect(() => {
    dispatch(Actions.fetch(0, 25, { corporationId: selectedCorp.id }));
    dispatch(ThemeActions.fetch(0, 25, { corporationId: selectedCorp.id }));
  }, [dispatch, selectedCorp]);

  const selectItem = (item) => {
    dispatch(Actions.select(item));
  };

  const clearError = () => {
    dispatch(Actions.clearError());
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { corporationId: selectedCorp.id }));
  };

  return (
    <AutoTable
      title="Site List"
      subheader={'A site is a unique property property with a PMS or LOCK system'}
      selectItem={(item) => selectItem(item)}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      page={page}
      error={error}
      clearError={() => clearError()}
      columns={columns}
      prefix="SITE"
      newButtonName="New Site"
      createNewURL="/sites/new"
      detailURL="/sites/view/{id}"
      idColumn={'id'}
      data={siteList}
      lastActionType={lastActionType}
    />
  );
}
