import { doesSiteHaveFeature } from '../../../../redux/actions/access/actions';
import SiteFeature from '../../../../../support/SiteFeature';
import { Grid } from '@material-ui/core';
import SiteDashboardWidget from '../detail/widget/SiteDashboardWidget';
import DashboardSection from '../../../../../support/DashboardSection';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '@aws-amplify/api';
import Charts from '../detail/Charts';
import Objects from '../../../../../support/Objects';
import TabPanel from '../../../control/TabPanel';
import ChartCardPair from '../detail/chart/ChartCardPair';
import LivertonLineChart from '../detail/chart/LivertonLineChart';
import LivertonPieChart from '../detail/chart/LivertonPieChart';
import moment from 'moment';

export default function GeneralTab({ selectedTab, tabIndex, timeframe, selectedSite }) {
  const widgetData = useSelector((state) => state.dashboardReducer.widgetData);
  const [checkOutNotifData, setCheckOutNotifData] = useState([]);
  const [checkInNotifData, setCheckInNotifData] = useState([]);
  const [checkInTrackingData, setCheckInTrackingData] = useState([]);
  const [checkOutTrackingData, setCheckOutTrackingData] = useState([]);
  const [tableViewMap, setTableViewMap] = useState({
    checkIn: false,
    checkInTracking: false,
    checkOut: false,
    checkOutTracking: false,
  });

  useEffect(() => {
    const siteId = selectedSite?.id;
    if (!siteId || !timeframe || selectedTab !== tabIndex) {
      return;
    }

    const payload = {
      ...timeframe,
      start: moment(timeframe.start).format('YYYY-MM-DD'),
      end: moment(timeframe.end).format('YYYY-MM-DD'),
    };

    if (doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_IN)) {
      Promise.all([
        API.post('PrivateAPI', `/api/private/portal/dashboard/chart/notifications/timeline?siteId=${siteId}&checkIn=true`, {
          body: payload,
        }),
        API.post('PrivateAPI', `/api/private/portal/dashboard/chart/tracking/pie?siteId=${siteId}&checkIn=true`, { body: payload }),
      ])
        .then((result) => {
          setCheckInNotifData(Charts.prepareChartLineData(result[0]));
          setCheckInTrackingData(Charts.preparePieChartData(result[1]));
        })
        .catch((error) => {
          console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
        });
    }

    if (doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_OUT)) {
      Promise.all([
        API.post('PrivateAPI', `/api/private/portal/dashboard/chart/notifications/timeline?siteId=${siteId}&checkIn=false`, {
          body: payload,
        }),
        API.post('PrivateAPI', `/api/private/portal/dashboard/chart/tracking/pie?siteId=${siteId}&checkIn=false`, { body: payload }),
      ])
        .then((result) => {
          setCheckOutNotifData(Charts.prepareChartLineData(result[0]));
          setCheckOutTrackingData(Charts.preparePieChartData(result[1]));
        })
        .catch((error) => {
          console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, timeframe, selectedTab]);

  return (
    <TabPanel value={selectedTab} index={tabIndex} p={3}>
      {(doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_IN) || doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_OUT)) && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SiteDashboardWidget widgetData={widgetData} site={selectedSite} section={DashboardSection.WEB_PORTAL} />
          </Grid>

          <ChartCardPair
            charts={[
              {
                title: 'Check In Notifications',
                subheader: 'Number of notifications sent for check-in',
                tableView: tableViewMap.checkIn,
                setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, checkIn: toggleValue }),
                component: (tableView) => (
                  <LivertonLineChart data={checkInNotifData} tableView={tableView} columnOverrides={NOTIFICATION_TABLE_LABELS} />
                ),
              },
              {
                title: 'Check In Tracking',
                subheader: 'Guest location breakdown during check-in',
                tableView: tableViewMap.checkInTracking,
                setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, checkInTracking: toggleValue }),
                component: (tableView) => (
                  <LivertonPieChart data={checkInTrackingData} tableView={tableView} columnOverrides={TRACKING_TABLE_LABELS} legend />
                ),
              },
            ]}
          />

          {doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_OUT) && (
            <ChartCardPair
              charts={[
                {
                  title: 'Check Out Notifications',
                  subheader: 'Number of notifications sent for check-out',
                  tableView: tableViewMap.checkOut,
                  setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, checkOut: toggleValue }),
                  component: (tableView) => (
                    <LivertonLineChart data={checkOutNotifData} tableView={tableView} columnOverrides={NOTIFICATION_TABLE_LABELS} />
                  ),
                },
                {
                  title: 'Check Out Tracking',
                  subheader: 'Guest location breakdown during check-out',
                  tableView: tableViewMap.checkOutTracking,
                  setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, checkOutTracking: toggleValue }),
                  component: (tableView) => (
                    <LivertonPieChart data={checkOutTrackingData} tableView={tableView} columnOverrides={TRACKING_TABLE_LABELS} legend />
                  ),
                },
              ]}
            />
          )}
        </Grid>
      )}
    </TabPanel>
  );
}

const NOTIFICATION_TABLE_LABELS = {
  x: 'Date',
};

const TRACKING_TABLE_LABELS = {
  key: 'Location',
  value: 'Count',
};
