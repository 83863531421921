import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import ReportType from '../../../../../support/ReportType';
import moment from 'moment';

export const HOTEL_RESERVATION_REPORT_FETCH_IN_PROGRESS = 'HOTEL_RESERVATION_REPORT_FETCH_IN_PROGRESS';
export const HOTEL_RESERVATION_REPORT_FETCH_SUCCESS = 'HOTEL_RESERVATION_REPORT_FETCH_SUCCESS';
export const HOTEL_RESERVATION_REPORT_FETCH_FAILURE = 'HOTEL_RESERVATION_REPORT_FETCH_FAILURE';

export const HOTEL_RESERVATION_REPORT_EXPORT_IN_PROGRESS = 'HOTEL_RESERVATION_REPORT_EXPORT_IN_PROGRESS';
export const HOTEL_RESERVATION_REPORT_EXPORT_SUCCESS = 'HOTEL_RESERVATION_REPORT_EXPORT_SUCCESS';
export const HOTEL_RESERVATION_REPORT_EXPORT_FAILURE = 'HOTEL_RESERVATION_REPORT_EXPORT_FAILURE';

export const requestReport =
  (reportType, page = 0, size = 25, payload) =>
  async (dispatch) => {
    dispatch({ type: HOTEL_RESERVATION_REPORT_FETCH_IN_PROGRESS });

    const url = getFetchUrlForReport(reportType, page, size);
    if (!url) {
      return dispatch({
        type: HOTEL_RESERVATION_REPORT_FETCH_FAILURE,
        payload: new Error(`No URL mapped for report type (${reportType})`),
      });
    }

    checkAndFormatDates(payload);
    try {
      const response = await API.post('PrivateAPI', url, { body: payload });
      dispatch({
        type: HOTEL_RESERVATION_REPORT_FETCH_SUCCESS,
        payload: {
          ...response,
          page: {
            number: response.number,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: HOTEL_RESERVATION_REPORT_FETCH_FAILURE,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  };

export const exportReport = (reportType, payload) => async (dispatch) => {
  dispatch({ type: HOTEL_RESERVATION_REPORT_EXPORT_IN_PROGRESS });

  const url = getExportUrlForReport(reportType);
  if (!url) {
    return dispatch({
      type: HOTEL_RESERVATION_REPORT_EXPORT_FAILURE,
      payload: new Error(`No URL mapped for report type (${reportType})`),
    });
  }

  checkAndFormatDates(payload);
  try {
    const response = await API.post('PrivateAPI', url, { body: payload });
    dispatch({ type: HOTEL_RESERVATION_REPORT_EXPORT_SUCCESS });
    Objects.saveAndDownloadContent(`${response.fileName}`, `data:application/csv;name=;base64,${response.file}`);
  } catch (error) {
    dispatch({
      type: HOTEL_RESERVATION_REPORT_EXPORT_FAILURE,
      payload: new Error(Objects.getErrorFromResponse(error)),
    });
  }
};

function checkAndFormatDates(payload) {
  payload.startDate = moment(payload.startDate).format('YYYY-MM-DD');
  payload.endDate = moment(payload.endDate).format('YYYY-MM-DD');
}

function getFetchUrlForReport(reportType, page = 0, size = 25) {
  const pagePart = `page=${page || 0}&size=${size || 10}`;

  if (ReportType.PRE_ARRIVAL === reportType) {
    return `/api/private/portal/report/reservations?${pagePart}`;
  }

  if (ReportType.SPECIAL_REQUEST === reportType) {
    return `/api/private/portal/report/specials?${pagePart}`;
  }

  if (ReportType.FEEDBACK_SUBMISSION === reportType) {
    return `/api/private/portal/report/feedback?${pagePart}`;
  }

  if (ReportType.UPSELL === reportType) {
    return `/api/private/portal/report/upsell?${pagePart}`;
  }

  if (ReportType.PAYMENT === reportType) {
    return `/api/private/portal/report/payment?${pagePart}`;
  }

  if (ReportType.ROOM_SELECTION === reportType) {
    return `/api/private/portal/report/roomSelection?${pagePart}`;
  }

  if (ReportType.MEMBERSHIP === reportType) {
    return `/api/private/portal/report/membership?${pagePart}`;
  }

  if (ReportType.CLICK_TRACKING === reportType) {
    return `/api/private/portal/report/clicks?${pagePart}`;
  }

  if (ReportType.NOTIFICATIONS === reportType) {
    return `/api/private/portal/report/notifications?${pagePart}`;
  }

  if (ReportType.GUEST_UPDATE === reportType) {
    return `/api/private/portal/report/guest?${pagePart}`;
  }

  if (ReportType.COMPANY_UPDATE === reportType) {
    return `/api/private/portal/report/company?${pagePart}`;
  }
  if (ReportType.USER_AUDIT === reportType) {
    return `/api/private/portal/report/user?${pagePart}`;
  }
  if (ReportType.UNSUBSCRIBED_USERS === reportType) {
    return `/api/private/portal/report/unsubscribed?${pagePart}`;
  }
  if (ReportType.GUEST_ORDER === reportType) {
    return `/api/private/portal/report/guestOrder?${pagePart}`;
  }
}

function getExportUrlForReport(reportType) {
  if (ReportType.PRE_ARRIVAL === reportType) {
    return '/api/private/portal/report/reservations/export';
  }

  if (ReportType.SPECIAL_REQUEST === reportType) {
    return '/api/private/portal/report/specials/export';
  }

  if (ReportType.FEEDBACK_SUBMISSION === reportType) {
    return '/api/private/portal/report/feedback/export';
  }

  if (ReportType.UPSELL === reportType) {
    return '/api/private/portal/report/upsell/export';
  }

  if (ReportType.PAYMENT === reportType) {
    return '/api/private/portal/report/payment/export';
  }

  if (ReportType.ROOM_SELECTION === reportType) {
    return '/api/private/portal/report/roomSelection/export';
  }

  if (ReportType.MEMBERSHIP === reportType) {
    return '/api/private/portal/report/membership/export';
  }

  if (ReportType.CLICK_TRACKING === reportType) {
    return '/api/private/portal/report/clicks/export';
  }

  if (ReportType.NOTIFICATIONS === reportType) {
    return '/api/private/portal/report/notifications/export';
  }

  if (ReportType.GUEST_UPDATE === reportType) {
    return '/api/private/portal/report/guest/export';
  }

  if (ReportType.COMPANY_UPDATE === reportType) {
    return '/api/private/portal/report/company/export';
  }

  if (ReportType.USER_AUDIT === reportType) {
    return '/api/private/portal/report/user/export';
  }

  if (ReportType.UNSUBSCRIBED_USERS === reportType) {
    return '/api/private/portal/report/unsubscribed/export';
  }

  if (ReportType.GUEST_ORDER === reportType) {
    return '/api/private/portal/report/guestOrder/export';
  }
}
