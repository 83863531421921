import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Report Schedule', 'REPORT_SCHEDULE');

function reportScheduleReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default reportScheduleReducer;
