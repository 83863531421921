export const DATA_TYPE_STRING = 1;
export const DATA_TYPE_DRILL_DOWN_LINK = 2;
export const DATA_TYPE_DATE = 3;
export const DATA_TYPE_DATE_TIME = 4;
export const DATA_TYPE_BOOLEAN = 5;
export const DATA_TYPE_COLOR = 6;
export const DATA_TYPE_FUNCTION = 7;
export const DATA_TYPE_LIST = 8;
export const DATA_TYPE_ICON = 9;
export const DATA_TYPE_IMAGE = 10;
export const DATA_TYPE_TIME = 11;
export const DATA_TYPE_EXPAND = 12;
export const DATA_TYPE_LIST_MULTI_SELECT = 13;
export const DATA_TYPE_FLOAT = 14;
export const DATA_TYPE_ENUM = 15;
