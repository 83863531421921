import { Box, Paper } from '@material-ui/core';

export default function TermsOfUse() {
  return (
    <Paper>
      <Box p={2}>
        <h3>Terms Of Use:</h3>

        <p>
          This application is owned by Liverton Limited. These terms and conditions govern your access to and use of all Liverton Limited
          owned and operated websites and any mobile applications made available by Liverton Limited for download.
        </p>
        <p>
          By accessing this website or by downloading and/or using an Liverton Limited mobile app you agree to be bound by these terms and
          conditions. If you do not agree to these terms and conditions then you should not use this website or download and/or use our
          mobile applications.
        </p>
        <h3>1. Access to particular services</h3>
        <p>
          Certain services available through this website and certain parts of this website (including the online service payment process)
          are available only to website users who register for those services, or who register for access to those parts of this website and
          obtain a username and password. If you register for such services or access, and are issued with a username, you will be
          responsible for all use of these services and this website through use of your username and password.
        </p>
        <h3>2. Payment</h3>
        <p>
          You will pay all fees payable for a charged service at the time your request or application for a charged service is accepted by
          Liverton Limited. Liverton Limited reserves the right to amend any fees from time to time and to adjust any fees recorded on this
          website or the application for a charged service if the price recorded is incorrect.
        </p>
        <p>
          All payments are to be made in advance by credit card (Liverton Limited accepts MasterCard and Visa). Liverton Limited may contact
          you directly to confirm your credit or debit card details. All fees are in New Zealand dollars and are inclusive of GST (being tax
          levied in accordance with the Goods and Services Tax Act 1985 and includes tax levied in substitution for such tax). You will pay
          all other applicable taxes, import duties, levies or tariffs which may be payable (if any).
        </p>
        <h3>3. Confidentiality</h3>
        <p>
          Information available under the charged services may be subject to confidentiality obligations or requirements. Liverton Limited
          retains the right to make any information confidential. You acknowledge and agree:
        </p>
        <ul>
          <li>Liverton Limited may elect not to supply you with any information that Liverton Limited believes is confidential; and/or</li>
          <li>You will comply with any confidentiality obligations notified to you by Liverton Limited;</li>
          <li>Further details and requests regarding confidential information can be obtained or made by contacting Liverton Limited.</li>
        </ul>
        <h3>4. Intellectual property</h3>
        <p>
          All copyright and other intellectual property rights in the website, mobile applications and all associated content is owned by
          Liverton Limited unless the content is identified as being the property of another party.
        </p>
        <p>No part of this website or our mobile applications may be:</p>
        <ul>
          <li>
            Distributed or copied for any commercial purpose, nor incorporated in any other work or publication (whether in hard copy,
            electronic or any other form); or
          </li>
          <li>
            Resold, published, copied, reproduced, transmitted or stored (including in any other website or other electronic form) except
            that you may print, or download to your hard drive, extracts from the website only for informational and non-commercial
            purposes, used without Liverton Limitedâ€™s express prior written permission or, in the case of content owned by Liverton
            Limitedâ€™s content suppliers, the relevant content supplierâ€™s express prior written consent.
          </li>
        </ul>
        <h3>5. Licence of mobile applications</h3>
        <p>
          Liverton Limited grants you a revocable, non-transferrable, non-exclusive licence to download, install and use its mobile apps for
          your own purposes. Any mobile app licence is subject to any limitations or restrictions on the permitted use of the mobile app
          contained in the Online Store Terms where you downloaded the app.
        </p>
        <h3>6. Usage and support of mobile applications</h3>
        <p>
          You must provide your own internet access and mobile device in order to download, install and use the app. You are responsible for
          all internet access, data download and other network charges arising from your downloading, installation or use of the app
          (including any global roaming charges where you use the app overseas) and you acknowledge and agree that Liverton Limited has no
          responsibility or liability for those charges.
        </p>
        <p>
          In downloading and/or using a mobile app, you must grant the app the required permissions and access. If you do not grant these
          permissions and access, you may be unable to use the app or some of its features.
        </p>
        <p>
          While under no obligation to do so, from time to time Liverton Limited may identify issues with its mobile apps and make available
          updates to help rectify any issues. You agree that you will use the most up-to-date version of Liverton Limitedâ€™s mobile apps
          and will check regularly for updates to those apps.
        </p>
        <p>
          Liverton Limited will use reasonable endeavours to generally make the services provided through its mobile apps available.
          However, the availability of those services may depend on various third party suppliers of both you and Liverton Limited, and
          accordingly Liverton Limited does not warrant or guarantee: (a) that you will be able to use the app at any time; or (b) that your
          use of the app will be continuous, uninterrupted, secure or error-free.
        </p>
        <p>
          You acknowledge and agree that the services provided through any app may not be available for use from time to time, and that you
          may be disconnected from your use of those services at any time for any reason, including if: (a) any network connection
          difficulties occur; (b) the systems providing those services are unavailable for any reason (including so that maintenance can be
          performed); (c) you breach any of these terms and conditions or (d) Liverton Limited decides to terminate your access to those
          services for any reason.
        </p>
        <p>In using any Liverton Limited mobile app you agree you must not use it (or attempt to use it) in the following manner:</p>
        <ul>
          <li>For any unlawful or dishonest activity;</li>
          <li>
            To access, transmit, publish or communicate material which is defamatory, offensive, unsuitable for minors, abusive, indecent,
            menacing, or otherwise unlawful, or which contains a virus or other harmful code;
          </li>
          <li>
            To obtain unauthorised access to (or damage, disrupt or interfere with the operation of) any computer, system, application,
            network or service;
          </li>
          <li>To transmit, publish or communicate bulk and/or unsolicited messages; or</li>
          <li>In any way that may cause Liverton Limited, its suppliers or other users of the app to incur liability to a third party.</li>
        </ul>
        <h3>7. Disclaimer</h3>
        <p>
          The information provided on this website and our mobile apps are for general guidance only. Users of the information contained on
          this website and mobile apps must make their own assessment of the suitability and appropriateness of the information or services
          described for their particular use and conditions.
        </p>
        <p>
          Accessing this website, downloading and/or using mobile apps, using our transaction processing services, and the downloading of
          any material is done entirely at the userâ€™s own risk.
        </p>
        <h3>8. All liability excluded</h3>
        <p>To the fullest extent permitted by law:</p>
        <ul>
          <li>
            All warranties, representations and warranties (whether express, implied or statutory) are excluded, including without limit,
            suitability, satisfactory quality, fitness for purpose, compatibility, non-infringement, accuracy, security or completeness of
            this website or the content on, or accessed through it and the completeness of any mobile app and its associated content; and
          </li>
          <li>
            Liverton Limited will not be liable for any damage, loss or expenses, or indirect losses or consequential damages of any kind,
            suffered or incurred by you in connection with either your access to or use of this website or the content on, or accessed
            through the website or in any way resulting from your downloading and/or use (or inability to use) of any Liverton Limited
            mobile app and its associated content.
          </li>
        </ul>
        <p>
          If the New Zealand Consumer Guarantees Act 1993 applies, you may have rights or remedies which are not excluded or limited by the
          above. If you are using this website, our mobile applications and any associated content for business purposes, the above
          exclusions and limits will apply and the New Zealand Consumer Guarantees Act 1993 will not apply.
        </p>
        <p>
          The exclusions and limits set out in these terms and conditions will also operate for the benefit of Liverton Limitedâ€™s relevant
          suppliers, licensors and agents which Liverton Limited use to provide its website and mobile applications.
        </p>
        <h3>9. Errors</h3>
        <p>
          Although Liverton Limited has endeavoured to ensure the content on this website and in its mobile apps is current, accurate and
          complete, Liverton Limited does not warrant that such content will be current, accurate or complete when you access it. Liverton
          Limited will take action to correct any error or inaccuracy which is brought to its attention.
        </p>
        <h3>10. Third party websites and advertising</h3>
        <p>
          This website or our mobile applications may contain links to third party websites not owned by Liverton Limited and/or advertising
          not related to Liverton Limited. If you visit a linked website or contact an advertiser, that dealing will be solely between you
          and that other website or advertiser at your own risk.
        </p>
        <p>
          These websites, any product or service being advertised (as applicable) or any association with their operators, have not been
          prepared by and are not controlled by Liverton Limited and such links are provided for your convenience only, and do not imply
          that Liverton Limited checks, endorses, approves, investigates or agrees with third party websites this website links to. To the
          fullest extent permitted by law, Liverton Limited disclaims any and all liability in respect of use of any third party websites
          this website links to, unless this is specifically stated on this website.
        </p>
        <h3>11. Under 18</h3>
        <p>By accepting these terms and conditions you acknowledge you are over 18 years of age.</p>
        <p>
          If you are under the age of 18, you must only use this website or download and/or use our mobile applications with the consent of
          your parent or guardian.
        </p>
        <h3>12. No representations</h3>
        <p>
          The availability of information on this website and mobile apps does not constitute a recommendation by Liverton Limited or any
          other person. If you enter into any transaction subsequent to accessing this website or mobile app, you do so entirely in reliance
          on your own judgement and inquiries and not in reliance on any statements, warranties or representations made to you or to any
          other person by or on behalf of Liverton Limited.
        </p>
        <h3>13. Compliance with law</h3>
        <p>
          You agree that you will at all times comply fully with all relevant laws, rules, regulations and orders of all relevant regulatory
          bodies in relation to any of the information or services made available to you through this website and mobile applications.
        </p>
        <h3>14. Jurisdiction and governing law</h3>
        <p>
          This website, mobile apps and content have been prepared in accordance with the requirements of New Zealand law. These terms and
          conditions and any matters or disputes connected with this website or any Liverton Limited mobile application will be exclusively
          governed by New Zealand Laws and will be dealt with by New Zealand Courts.
        </p>
        <h3>15. Termination</h3>
        <p>
          You may terminate your use of any of the charged services, or access to any part of this website for which registration is
          required (including the online service payment process), at any time by requesting that Liverton Limited delete your username and
          password for the relevant service or part of this website.
        </p>
        <p>
          Liverton Limited may terminate your right to use any of the charged services, or access to any part of this website for which
          registration is required (including the online service payment process), by giving you one monthsâ€™ notice, or immediately
          without notice if you breach any of these terms and conditions for any charged service or any other service or the online service
          payment terms.
        </p>
        <p>
          Any termination of your right to use any of the charged services, or access to any part of this website for which registration is
          required, will not affect any rights or obligations which have accrued by either you or Liverton Limited prior to termination.
        </p>
        <p>
          You may terminate your use of any downloaded mobile app by uninstalling the app from your device at any time. Liverton Limited may
          immediately terminate and block your access to any mobile app or the services provided by the app for any reason, including if it
          believes you have engaged in conduct contrary to your obligations under these terms and conditions.
        </p>
        <h3>16. Amendments</h3>
        <p>
          Liverton Limited may at any time and without notice, remove, amend and/or vary any of the content which appears on the website and
          mobile applications, or cease operating the website, mobile applications and any related services in whole or in part.
        </p>
        <p>
          We may also amend these terms and conditions from time to time so you should check and read these terms and conditions regularly.
          By continuing to use this website or any Liverton Limited mobile application after any such amendment, you are deemed to have
          agreed to any amended terms and conditions.
        </p>
        <h3>17. Contacting us</h3>
        <p>
          If you have any questions or concerns in relation to the website, any mobile app or these terms and conditions, please&nbsp;
          <a href="https://liverton.com/contact/">contact us</a>.
        </p>
      </Box>
    </Paper>
  );
}
