import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodVendorActions from '../../../../redux/actions/inRoom/ordering/vendor/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodVendorActions.delete(value, `/api/private/portal/food/vendor/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'website',
    label: 'Website',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phone',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'vendorSync',
    label: 'Vendor Sync',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'vendorSyncSchedule',
    label: 'Vendor Sync (Minutes)',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastSync',
    label: 'Last Sync',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function FoodVendorList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const vendorList = useSelector((state) => state.foodVendorReducer.entityList);
  const processing = useSelector((state) => state.foodVendorReducer.processing);
  const page = useSelector((state) => state.foodVendorReducer.page);
  const error = useSelector((state) => state.foodVendorReducer.error);
  const params = { sort: 'enabled,desc&sort=name,asc' };

  return (
    <AutoTable
      title="Food Vendor List"
      subheader={'A vendor is an external entity that provides service to the Organisation'}
      handleRefreshReport={() => dispatch(FoodVendorActions.fetch(0, page.size, params, { siteId: selectedSiteId }))}
      selectItem={(item) => dispatch(FoodVendorActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodVendorActions.fetch(newPage, pageSize, params, { siteId: selectedSiteId }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_VENDOR"
      createNewURL="/food/vendor/new"
      detailURL="/food/vendor/view/{id}"
      idColumn={'id'}
      data={vendorList}
      selectedSite={selectedSite}
    />
  );
}
