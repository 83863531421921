import React from 'react';
import ReservationStatus from '../../../../../support/ReservationStatus';
import HotelReservationList from './HotelReservationList';

export default function HotelReservationInHouse() {
  return (
    <HotelReservationList
      title={'In House Hotel Reservations'}
      subheader={'Hotel reservations currently in house'}
      status={ReservationStatus.CHECKED_IN}
    />
  );
}
