import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import LivertonIcon from '../../../../assets/img/liverton-icon-white.svg';
import * as AuthenticationActions from '../../../redux/actions/authentication/actions';
import * as AccessActions from '../../../redux/actions/access/actions';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  Toolbar,
} from '@material-ui/core';
import LivertonNetworksWhite from '../../../../assets/img/liverton-logo-white.svg';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { HelpOutline, MenuOutlined, NightsStay, WbSunny } from '@material-ui/icons';

const MAX_DRAWER_WIDTH = 280;

const PublicNavigation = ({ history }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.accessReducer.theme);
  const classes = useStyles();

  const navigateToHome = React.useCallback(() => {
    setMobileOpen(false);
    dispatch(AuthenticationActions.updateAuthenticationState('signIn', null));
    history.push('/login');
  }, [dispatch, history]);

  const switchThemeSelected = React.useCallback(() => {
    if (theme === 'dark') {
      dispatch(AccessActions.updateThemePreference('light'));
    } else {
      dispatch(AccessActions.updateThemePreference('dark'));
    }
  }, [theme, dispatch]);

  const isDarkTheme = theme === 'dark';
  return (
    <AppBar position={'fixed'} variant="elevation" color={isDarkTheme ? 'dark' : 'primary'}>
      <Toolbar>
        {/* Desktop */}
        <Hidden smDown>
          <Button color="inherit" onClick={navigateToHome} component={'a'}>
            <picture className="fw-25 fh-25">
              <source srcSet={LivertonIcon} type="image/svg" />
              <img src={LivertonIcon} alt="Alt Text!" />
            </picture>
          </Button>

          <Box flexGrow={1} />

          <IconButton
            aria-label="dark-light-mode"
            aria-controls="dark-light-mode"
            aria-haspopup="true"
            onClick={switchThemeSelected}
            color="inherit"
          >
            {isDarkTheme && <NightsStay />}
            {!isDarkTheme && <WbSunny />}
          </IconButton>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuOutlined />
          </IconButton>

          <Box flexGrow={1} />

          <IconButton
            aria-label="dark-light-mode"
            aria-controls="dark-light-mode"
            aria-haspopup="true"
            onClick={switchThemeSelected}
            color="inherit"
          >
            {isDarkTheme && <NightsStay />}
            {!isDarkTheme && <WbSunny />}
          </IconButton>

          <Drawer open={mobileOpen} onClose={() => setMobileOpen(false)} classes={{ paper: clsx(classes.drawerOpen) }}>
            <div className={classes.toolbar}>
              <div className={classes.toolbar_image}>
                <picture className={classes.toolbar_image_inner}>
                  <img src={LivertonNetworksWhite} alt="Liverton Networks" />
                </picture>
              </div>
            </div>

            <Divider />

            <MenuList>
              <ListItem button component={'a'} href={'https://liverton.com/contact'} target={'_blank'} rel={'noopener noreferrer'}>
                <ListItemIcon>
                  <HelpOutline />
                </ListItemIcon>
                <ListItemText primary={'Contact Us'} />
              </ListItem>
            </MenuList>
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => {
  const navigationBackground = theme.type === 'light' ? theme.palette.colours['dark'] : theme.palette.colours['dark'];
  const navigationText = theme.type === 'light' ? theme.palette.colours['white'] : theme.palette.colours['white'];
  return {
    root: {
      display: 'flex',
    },
    drawer: {
      width: MAX_DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: navigationText,
    },
    drawerOpen: {
      width: MAX_DRAWER_WIDTH,
      backgroundColor: navigationBackground,
      color: navigationText,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: navigationBackground,
      color: navigationText,
      overflowX: 'hidden',
      width: theme.spacing(9) + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      minHeight: '88px',
    },
    toolbar_image: {
      border: 0,
      verticalAlign: 'center',
      margin: '0 1.0rem',
      color: navigationText,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: MAX_DRAWER_WIDTH - 40,
      minHeight: '75px',
    },
    toolbar_image_inner: {
      width: '75%',
    },
    toolbar_button: {
      padding: '6px 1.0rem',
    },
    toolbar_collapse: {
      position: 'absolute',
      right: '0',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  };
});

export default withRouter(PublicNavigation);
