import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import NotificationSchemeActions from '../../../../redux/actions/scheme/notification/actions';
import Objects from '../../../../../support/Objects';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../components/form/FormSelect';
import UnsubscribedList from '../../unsubscribed/UnsubscribedList';

export default function NotificationSchemeEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.notificationSchemeReducer.error);
  const lastActionType = useSelector((state) => state.notificationSchemeReducer.lastActionType);
  const processing = useSelector((state) => state.notificationSchemeReducer.processing);
  const selectedItem = useSelector((state) => state.notificationSchemeReducer.selectedItem);
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);

  const [schemeName, setSchemeName] = useState(selectedItem?.schemeName || '');
  const [emailProvider, setEmailProvider] = useState(selectedItem?.emailProvider || 'AWS');
  const [emailSender, setEmailSender] = useState(selectedItem?.emailSender || '');
  const [emailHost, setEmailHost] = useState(selectedItem?.emailHost || '');
  const [emailPort, setEmailPort] = useState(selectedItem?.emailPort || 465);
  const [emailAuth, setEmailAuth] = useState(selectedItem ? selectedItem.emailAuth : true);
  const [emailId, setEmailId] = useState(selectedItem?.emailId || '');
  const [emailKey, setEmailKey] = useState(selectedItem?.emailKey || '');
  const [emailUnsubscribe, setEmailUnsubscribe] = useState(selectedItem?.emailUnsubscribe || false);
  const [emailUnsubscribeGroupId, setEmailUnsubscribeGroupId] = useState(selectedItem?.emailUnsubscribeGroupId || '');
  const [smsProvider, setSmsProvider] = useState(selectedItem?.smsProvider || 'TWILIO');
  const [smsSender, setSmsSender] = useState(selectedItem?.smsSender || '');
  const [smsSenderAlias, setSmsSenderAlias] = useState(selectedItem?.smsSenderAlias || '');
  const [smsId, setSmsId] = useState(selectedItem?.smsId || '');
  const [smsKey, setSmsKey] = useState(selectedItem?.smsKey || '');
  const [smsAccountSid, setSmsAccountSid] = useState(selectedItem?.smsAccountSid || '');
  const [smsUnsubscribe, setSmsUnsubscribe] = useState(selectedItem?.smsUnsubscribe || false);

  useEffect(() => {
    dispatch(NotificationSchemeActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'NOTIFICATION_SCHEME_DELETE_SUCCESS' ||
      lastActionType === 'NOTIFICATION_SCHEME_ADD_SUCCESS' ||
      lastActionType === 'NOTIFICATION_SCHEME_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = useCallback(() => {
    const payload = {
      ...selectedItem,
      schemeName,
      emailProvider,
      emailSender,
      emailHost,
      emailPort,
      emailAuth,
      emailId,
      emailKey,
      smsProvider,
      smsSender,
      smsSenderAlias,
      smsId,
      smsKey,
      smsAccountSid,
      emailUnsubscribe,
      emailUnsubscribeGroupId,
      smsUnsubscribe,
      corporationId: selectedItem ? selectedItem.corporationId : selectedCorp.id,
    };

    if (selectedItem) {
      dispatch(NotificationSchemeActions.update(payload));
    } else {
      dispatch(NotificationSchemeActions.add(payload));
    }
  }, [
    dispatch,
    emailAuth,
    emailHost,
    emailId,
    emailKey,
    emailPort,
    emailProvider,
    emailSender,
    schemeName,
    selectedCorp.id,
    selectedItem,
    smsId,
    smsKey,
    smsProvider,
    smsSender,
    smsSenderAlias,
    smsAccountSid,
    emailUnsubscribe,
    emailUnsubscribeGroupId,
    smsUnsubscribe,
  ]);

  const isFormDisabled = Objects.areValuesNullOrEmpty([schemeName, emailProvider, emailSender, smsProvider]);
  const isDefault = selectedItem?.id === 1;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={selectedItem ? 'Update Scheme' : 'Create Scheme'}
            subheader={'Delivery credentials used to send notifications to customers'}
            buttonName={selectedItem ? 'Update' : 'Create'}
            buttonLoading={processing}
            disabled={isFormDisabled || processing}
            onClick={handleSubmit}
            deleteDisabled={!selectedItem || isDefault}
            onDelete={() => dispatch(NotificationSchemeActions.delete(selectedItem))}
          />

          <CardContent>
            {error && (
              <Alert severity="error" variant={'filled'} className={'mb-2'}>
                {`Unable to process action - ${error.message}`}
              </Alert>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormField label={'Scheme Name'} value={schemeName} setValue={setSchemeName} error={!schemeName} disabled={isDefault} />
              </Grid>

              <Grid item xs={12}>
                <FormSelect label={'Email Provider'} value={emailProvider} setValue={setEmailProvider} options={EmailProviderOptions} />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'Email Sender Address'}
                  type={'email'}
                  value={emailSender}
                  setValue={setEmailSender}
                  error={!emailSender}
                />
              </Grid>

              {emailProvider === 'AWS' && (
                <>
                  <Grid item xs={6}>
                    <FormField label={'Email Server'} value={emailHost} setValue={setEmailHost} error={!emailHost} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      label={'Email Port'}
                      type={'number'}
                      inputProps={{ min: 0, max: 4096 }}
                      value={emailPort}
                      setValue={setEmailPort}
                      error={!emailPort}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormBooleanSelect label={'Email Auth'} value={emailAuth} setValue={setEmailAuth} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField label={'Email Username'} value={emailId} setValue={setEmailId} error={!emailId} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField label={'Email Password'} type={'password'} value={emailKey} setValue={setEmailKey} error={!emailKey} />
                  </Grid>
                </>
              )}

              {emailProvider !== 'AWS' && (
                <Grid item xs={6}>
                  <FormField label={'Send Grid API Key'} type={'password'} value={emailKey} setValue={setEmailKey} error={!emailKey} />
                </Grid>
              )}
              <Grid item xs={6}>
                <FormBooleanSelect label={'Email Unsubscribe'} value={emailUnsubscribe} setValue={setEmailUnsubscribe} />
              </Grid>
              {emailUnsubscribe && (
                <Grid item xs={6}>
                  <FormField
                    label={'Email Unsubscribe Group ID'}
                    type={'text'}
                    value={emailUnsubscribeGroupId}
                    setValue={setEmailUnsubscribeGroupId}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormSelect label={'SMS Provider'} value={smsProvider} setValue={setSmsProvider} options={SmsProviderOptions} />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'SMS Account SID'}
                  type={'text'}
                  value={smsAccountSid}
                  setValue={setSmsAccountSid}
                  helperText={'** Main Account SID (can be left blank if same as SMS Key ID below)'}
                />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'SMS Sender/Message Service SID'}
                  value={smsSender}
                  setValue={setSmsSender}
                  helperText={'** Sender phone number must be registered in Twilio or Twilio Messaging Service SID'}
                />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'SMS Sender Alias'}
                  value={smsSenderAlias}
                  setValue={setSmsSenderAlias}
                  helperText={'** TXT aliasing must be registered in your Twilio account or it may be blocked'}
                />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'SMS Key ID'}
                  value={smsId}
                  setValue={setSmsId}
                  error={!smsId}
                  helperText={'** Twilio Account SID or API Key SID'}
                />
              </Grid>

              <Grid item xs={6}>
                <FormField
                  label={'SMS Key Secret'}
                  type={'password'}
                  value={smsKey}
                  setValue={setSmsKey}
                  error={!smsKey}
                  helperText={'** Twilio Access Token or API Key Secret'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormBooleanSelect label={'Sms Unsubscribe'} value={smsUnsubscribe} setValue={setSmsUnsubscribe} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {selectedItem && (
        <Grid item xs={12}>
          <Card>
            <UnsubscribedList notificationSchemeId={selectedItem.id} />
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

const EmailProviderOptions = [
  { label: 'AWS', value: 'AWS' },
  { label: 'Twilio SendGrid', value: 'SEND_GRID' },
];

const SmsProviderOptions = [
  { label: 'AWS', value: 'AWS' },
  { label: 'Twilio', value: 'TWILIO' },
];
