import React from 'react';
import ReservationStatus from '../../../../../support/ReservationStatus';
import HotelReservationList from './HotelReservationList';

export default function HotelReservationArrivals() {
  return (
    <HotelReservationList
      title={'Arriving Hotel Reservations'}
      subheader={'Hotel reservations with due in status'}
      status={ReservationStatus.DUE_IN}
    />
  );
}
