const ReportType = {
  PRE_ARRIVAL: 'PRE_ARRIVAL',
  FEEDBACK_SUBMISSION: 'FEEDBACK_SUBMISSION',
  UPSELL: 'UPSELL',
  SPECIAL_REQUEST: 'SPECIAL_REQUEST',
  PAYMENT: 'PAYMENT',
  ROOM_SELECTION: 'ROOM_SELECTION',
  MEMBERSHIP: 'MEMBERSHIP',
  CLICK_TRACKING: 'CLICK_TRACKING',
  NOTIFICATIONS: 'NOTIFICATIONS',
  GUEST_UPDATE: 'GUEST_UPDATE',
  COMPANY_UPDATE: 'COMPANY_UPDATE',
  USER_AUDIT: 'USER_AUDIT',
  UNSUBSCRIBED_USERS: 'UNSUBSCRIBED_USERS',
  GUEST_ORDER: 'GUEST_ORDER',
  FAILED_GUEST_ORDERS: 'FAILED_GUEST_ORDERS',
};

const ReportTypeFilters = [
  { label: 'Pre-Arrival', value: ReportType.PRE_ARRIVAL },
  { label: 'Feedback-Submission', value: ReportType.FEEDBACK_SUBMISSION },
  { label: 'Upsell', value: ReportType.UPSELL },
  { label: 'Special-Request', value: ReportType.SPECIAL_REQUEST },
  { label: 'Payment', value: ReportType.PAYMENT },
  { label: 'Room Selection', value: ReportType.ROOM_SELECTION },
  { label: 'Membership Enrolment', value: ReportType.MEMBERSHIP },
  { label: 'Click Tracking', value: ReportType.CLICK_TRACKING },
  { label: 'Notifications', value: ReportType.NOTIFICATIONS },
  { label: 'Guest Detail Update', value: ReportType.GUEST_UPDATE },
  { label: 'Company Update', value: ReportType.COMPANY_UPDATE },
  { label: 'User Audit', value: ReportType.USER_AUDIT },
  { label: 'Unsubscribed Guests', value: ReportType.UNSUBSCRIBED_USERS },
  { label: 'Guest Order', value: ReportType.GUEST_ORDER },
];

const ReportTypeOptions = [...ReportTypeFilters, { label: 'Failed Guest Orders', value: ReportType.FAILED_GUEST_ORDERS }];

export default ReportType;
export { ReportTypeFilters, ReportTypeOptions };
