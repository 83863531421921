import { Area, Bar, Cell, Line } from 'recharts';
import React from 'react';
import CheckInState from '../../../../../support/CheckInState';
import CheckOutState from '../../../../../support/CheckOutState';

const COLOURS = [
  '#5ac3ed',
  '#14a74c',
  '#ffc107',
  '#FF6326',
  '#20c997',
  '#4264FF',
  '#8884d8',
  '#801601',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#4a128f',
  '#70379f',
  '#ef98bb',
  '#ea7a9b',
  '#e15c77',
  '#d43d51',
];

const STATE_COLOUR_MAP = {
  [CheckInState.SUMMARY]: '#5ac3ed',
  [CheckInState.PAYMENT]: '#ffc107',
  [CheckInState.COMPLETED]: '#14a74c',
  [CheckOutState.MINIBAR]: '#5ac3ed',
  [CheckOutState.BILL]: '#533e9d',
  [CheckOutState.FEEDBACK]: '#FF7F00',
  [CheckInState.ROOM_SELECTION]: '#0000FF',
  [CheckInState.MEMBERSHIP]: '#9400D3',
  [CheckInState.UPSELL]: '#621010',
};

const Charts = {
  getDefaultChartDimension() {
    return 250;
  },

  getDefaultBarWidth() {
    return 60;
  },

  getColours() {
    return COLOURS;
  },

  getColour(index) {
    return COLOURS[index];
  },

  /**
   * Prepare the chart data from the server. The data must be flattened and zero indexed to be handled by recharts
   *
   * For Example when dealing with a BARCHART with clustered data per point each X value i.e the time should have a
   * the value for that property
   *
   * data = [
   * {x: "2018-11-15 12:00", cat1: 0, cat2: 0, cat3: 0, cat4: 0}
   * {x: "2018-11-15 10:00", cat1: 0, cat2: 0, cat3: 0, cat4: 0}
   * ]
   *
   * @param response
   */
  prepareChartData(response) {
    if (!response) {
      throw new Error('prepareChartData() called with null input');
    }

    if (!response.hasOwnProperty('timeSeriesPoints') || !response.hasOwnProperty('dataList')) {
      throw new Error("Chart data expected to have 'timeSeriesPoints' and 'dataList' properties");
    }

    let result = [];

    //time chart
    if (response.timeSeriesPoints.length > 0) {
      for (let i = 0; i < response.timeSeriesPoints.length; i++) {
        result.push({ x: response.timeSeriesPoints[i] });
      }

      for (let i = 0; i < result.length; i++) {
        let point = result[i];

        for (let j = 0; j < response.dataList.length; j++) {
          let chartData = response.dataList[j];

          let name = chartData.name;
          let value = chartData.dataPoints[i];

          if (value === undefined) {
            value = 0;
          }

          point[name] = value;
        }
      }
    } else {
      for (let i = 0; i < response.dataList.length; i++) {
        let chartData = response.dataList[i];
        let x = chartData.name;
        let y = 0;

        /**
         * If the chart data does not have a 'length' property then treat it as an object with each property being a data point
         */
        if (chartData.dataPoints.length === undefined || chartData.dataPoints.length === 0) {
          result.push({ x: x, ...chartData.dataPoints });
        } else {
          for (let i = 0; i < chartData.dataPoints.length; i++) {
            y += chartData.dataPoints[i];
          }

          result.push({
            x: x,
            y: y,
          });
        }
      }
    }

    return result;
  },

  preparePieChartData(response) {
    if (response?.dataList?.coordinates !== undefined) {
      let currentData = response.dataList.coordinates;
      let map = Object.keys(currentData).map((key) => [key, currentData[key]]);
      let i = 0;
      return Array.from(map, function (entry) {
        i++;
        return {
          key: entry[0],
          value: entry[1],
          colour: STATE_COLOUR_MAP[entry[0]] || COLOURS[i % COLOURS.length],
        };
      });
    }
  },
  prepareChartLineData(response) {
    if (!response) {
      throw new Error('prepareChartData() called with null input');
    }

    if (!response.hasOwnProperty('timeSeriesPoints') || !response.hasOwnProperty('dataList')) {
      throw new Error("Chart data expected to have 'timeSeriesPoints' and 'dataList' properties");
    }

    let result = [];

    //line chart
    if (response.timeSeriesPoints.length > 0) {
      for (let i = 0; i < response.timeSeriesPoints.length; i++) {
        result.push({ x: response.timeSeriesPoints[i] });
      }

      for (let i = 0; i < result.length; i++) {
        let point = result[i];

        for (let j = 0; j < response.dataList.length; j++) {
          let chartData = response.dataList[j];

          let lineMapItem = chartData.coordinates[i].lineMap;

          if (Object.keys(lineMapItem).length === 0) {
            if (Object.keys(response.settings).length > 0) {
              Object.values(response.settings).forEach((value) => {
                point[value] = 0;
              });
            } else {
              lineMapItem = new Map();
            }
          } else {
            Object.keys(lineMapItem)
              .map((key) => [key, lineMapItem[key]])
              .forEach((entry) => {
                const [key, val] = entry;
                if (Object.keys(response.settings).length > 0) {
                  if (response.settings[key] !== undefined) {
                    point[response.settings[key]] = val;
                  }
                } else {
                  point[key] = val;
                }
              });
          }
        }
      }
    } else {
      for (let i = 0; i < response.dataList.length; i++) {
        let chartData = response.dataList[i];
        let x = chartData.name;
        let lineMapItem = chartData.coordinates[i].lineMap;

        /**
         * If the chart data does not have a 'length' property then treat it as an object with each property being a data point
         */
        if (Object.keys(lineMapItem).length === 0) {
          if (Object.keys(response.settings).length > 0) {
            let pointsMap = new Map();
            Object.values(response.settings).forEach((value) => {
              pointsMap.set(value, 0);
            });
            lineMapItem = pointsMap;
          } else {
            lineMapItem = new Map();
          }
          let values = Array.from(lineMapItem, ([name, value]) => ({ name, value }));
          result.push({ x: x, ...values });
        } else {
          let keys = Array.from(lineMapItem.keys());
          lineMapItem.forEach((value, key) => {
            keys[key] += value;
          });

          result.push({ x: x, ...keys });
        }
      }
    }

    return result;
  },

  getCategoryForChartWithType(preparedChartData = [], xAxisName = 'x', type) {
    const result = [],
      map = {};

    if (type === 'pie') {
      for (let i = 0; i < preparedChartData.length; i++) {
        let line = preparedChartData[i];
        result.push(<Cell type="monotone" fill={line.colour} stroke={line.colour} key={`cell-${line.key}-${i}`} />);
      }
    } else {
      for (let i = 0; i < preparedChartData.length; i++) {
        let line = preparedChartData[i];

        let colourIndex = 0;

        Object.keys(line).forEach((key) => {
          if (key !== xAxisName && (line.hasOwnProperty(key) || type === 'bar')) {
            if (!map.hasOwnProperty(key)) {
              map[key] = key;

              if (type === 'bar') {
                let color = COLOURS[i % COLOURS.length];
                result.push(
                  <Bar
                    type="monotone"
                    fill={color}
                    stackId="a"
                    dataKey={key}
                    key={`bar-${line.x}-${key}`}
                    barSize={Charts.getDefaultBarWidth()}
                  />
                );
              } else if (type === 'area') {
                let color = COLOURS[colourIndex % COLOURS.length];
                result.push(<Area type="monotone" fill={color} stroke={color} stackId="a" dataKey={key} key={`area-${line.x}-${key}`} />);
              } else if (type === 'line') {
                let color = COLOURS[colourIndex % COLOURS.length];
                result.push(<Line type="monotone" key={`line-${line.x}-${key}`} dataKey={key} stroke={color} />);
              } else {
                return 'Unknown chart type provided (' + type + ')';
              }
              colourIndex++;
            }
          }
        });
      }
    }

    return result;
  },
};
export default Charts;
