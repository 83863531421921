import { TextField } from '@material-ui/core';
import React from 'react';
import CurrencyList from '../../support/CurrencyList';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FormCurrencyList({ value, setValue }) {
  return (
    <Autocomplete
      fullWidth
      options={CurrencyList}
      onChange={(event, newValue) => setValue(newValue)}
      value={value}
      renderInput={(params) => <TextField {...params} label="Currency" variant="outlined" margin={'dense'} />}
    />
  );
}
