import React, { useEffect } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import NotificationActions from '../../../../redux/actions/site/notification/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Link } from '@material-ui/core';
import { DeleteOutlined, FileCopyOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { NotificationType } from './NotificationType';
import { ReservationEventOptions } from './ReservationEvent';
import NotificationExportDialog from './NotificationExportDialog';
import UserRoleHelper from '../../../../../support/UserRoleHelper';
import UserRole from '../../../../../support/UserRole';
import Swal from 'sweetalert2';
import RequestCategoryServiceList from './RequestCategoryServiceList';
import RequestRedirectList from './RequestRedirectList';

const columns = (history) => [
  {
    id: 'actions',
    label: '',
    align: 'left',
    width: 100,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(NotificationActions.delete(value, `/api/private/portal/notification/schedule/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(NotificationActions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'expand',
    label: '',
    align: 'right',
    width: 10,
    dataType: DataTypes.DATA_TYPE_EXPAND,
  },
  {
    id: 'notificationType',
    label: 'Notification Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Link href={'#'} component="button" onClick={() => dispatch(NotificationActions.selectAndNavigate(value, history))}>
          {NotificationType.find((v) => v.value === value.notificationType)?.label}
        </Link>
      );
    },
  },
  {
    id: 'notificationCategory',
    label: 'Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'timeAmount',
    label: 'Amount',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'timeUnit',
    label: 'Unit',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'condition',
    label: 'Condition',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'reservationEvent',
    label: 'Relative To',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return ReservationEventOptions.find((v) => v.value === value.reservationEvent)?.label;
    },
  },
];

const subColumns = [
  {
    id: 'dayOfWeek',
    label: 'Day',
    align: 'left',
    width: '10%',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sendTime',
    label: 'Send Time',
    align: 'left',
    width: '90%',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
];

export default function NotificationScheduleList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const scheduleList = useSelector((state) => state.notificationScheduleReducer.entityList);
  const lastActionType = useSelector((state) => state.notificationScheduleReducer.lastActionType);
  const processing = useSelector((state) => state.notificationScheduleReducer.processing);
  const page = useSelector((state) => state.notificationScheduleReducer.page);
  const error = useSelector((state) => state.notificationScheduleReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const parameters = { sort: 'notificationType,notificationCategory,asc' };
  const pathVariables = { siteId: selectedSite?.id };
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);

  const [exportModalOpen, setExportModalOpen] = React.useState(false);
  const isCorpAdminAndAbove = UserRoleHelper.hasRole(profile, UserRole.ROLE_CORP_ADMIN);

  const imageUploadId = 'notification-schedule-upload';

  function closeExportModal() {
    setExportModalOpen(false);
  }

  function openExportModal() {
    setExportModalOpen(true);
  }

  const openFileWindow = () => {
    document.getElementById(imageUploadId).click();
  };

  useEffect(() => {
    if (lastActionType === 'NOTIFICATION_SCHEDULE_IMPORT_SUCCESS') {
      Swal.fire({
        title: 'Success',
        text: 'Notification Schedule imported successfully',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      dispatch(NotificationActions.fetch(0, 25, parameters, pathVariables));
    }
    if (lastActionType === 'NOTIFICATION_SCHEDULE_IMPORT_FAILURE') {
      Swal.fire({
        title: 'Error',
        text: 'Notification Schedule import failed. Make sure you used the correct file!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      dispatch(NotificationActions.fetch(0, 25, parameters, pathVariables));
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lastActionType]);

  const handleFileSelected = (dispatch) => async (event) => {
    const data = new FormData();
    data.set('siteId', selectedSite.id);
    data.set('uploadedFile', event.target.files[0]);

    dispatch(NotificationActions.import(data, parameters, pathVariables));
    // After handling the file, clear the input field
    event.target.value = null;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <input
          type="file"
          style={{ display: 'none' }}
          id={imageUploadId}
          accept={'.csv'}
          name={imageUploadId}
          onChange={dispatch(handleFileSelected)}
          multiple={false}
        />
        <AutoTable
          title="Notification Schedule List"
          subheader={'Configuration related to site scheduling of notifications'}
          buttonName={'New'}
          handleRefreshReport={() => dispatch(NotificationActions.fetch(0, 25, parameters, pathVariables))}
          selectItem={(item) => dispatch(NotificationActions.select(item))}
          selectedSite={selectedSite}
          selectedCorporation={selectedCorporation}
          processing={processing}
          handleChangePage={(ev, newPage, pageSize) => dispatch(NotificationActions.fetch(newPage, pageSize, parameters, pathVariables))}
          page={page}
          error={error}
          columns={columns(history)}
          prefix="NOTIFICATION_SCHEDULE"
          createNewURL="/site/notifications/new"
          detailURL="/site/notifications/view/{id}"
          idColumn={'id'}
          data={scheduleList}
          subColumns={subColumns}
          subColumnAttribute={'scheduleDays'}
          secondaryButtonAction={isCorpAdminAndAbove && openExportModal}
          secondaryButtonName={'Export'}
          secondaryButtonDisabled={processing}
          tertiaryButtonAction={isCorpAdminAndAbove && openFileWindow}
          tertiaryButtonName={'Import'}
          tertiaryButtonDisabled={processing}
        />
        <NotificationExportDialog open={exportModalOpen} setClose={closeExportModal} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '20px',
        }}
      >
        <RequestCategoryServiceList />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '1.25rem',
        }}
      >
        <RequestRedirectList isEscalation={false} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '1.25rem',
        }}
      >
        <RequestRedirectList isEscalation={true} />
      </Grid>
    </Grid>
  );
}
