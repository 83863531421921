import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SiteDoorActions from '../../../../redux/actions/siteDoor/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Swal from 'sweetalert2';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import GuestDialog from './guest/GuestDialog';
import Actions from '../../../../redux/actions/reservation/access/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import GuestCard from './guest/GuestCard';
import DoorType from '../../../../../support/DoorType';
import KeyIssueBehavior, { KeyIssueBehaviorSelectOptions } from '../../../../../support/KeyIssueBehavior';
import KeyIssueMode, { KeyIssueModeOptions } from '../../../../../support/KeyIssueMode';
import KeyApprovalStatus from '../../../../../support/KeyApprovalStatus';
import DoorSelector from './components/DoorSelector';
import SiteFeature from '../../../../../support/SiteFeature';
import FormSelect from '../../../../../components/form/FormSelect';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

export default function AccessReservationDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.accessReservationReducer.error);
  const processing = useSelector((state) => state.accessReservationReducer.processing);
  const lastActionType = useSelector((state) => state.accessReservationReducer.lastActionType);
  const selectedItem = useSelector((state) => state.accessReservationReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const doorList = useSelector((state) => state.siteDoorReducer.entityList)
    .filter((d) => d.visible)
    .map((d) => {
      return {
        ...d,
        id: null,
      };
    });

  const [guestEditModalOpen, setGuestEditModalOpen] = React.useState(false);
  const [guestEdit, setGuestEdit] = React.useState(null);
  const [arrival, setArrival] = React.useState(selectedItem ? moment.unix(selectedItem.arrival) : moment());
  const [departure, setDeparture] = React.useState(selectedItem ? moment.unix(selectedItem.departure) : moment());
  const [issueBehaviour, setIssueBehaviour] = React.useState(selectedItem ? selectedItem.issueBehaviour : KeyIssueBehavior.AUTOMATIC);
  const [issueMode, setIssueMode] = React.useState(selectedItem ? selectedItem.issueMode : KeyIssueMode.JOIN);
  const [shareCard, setShareCard] = React.useState(selectedItem ? selectedItem.shareCard : true);
  const [approvalStatus] = React.useState(selectedItem ? selectedItem.approvalStatus : KeyApprovalStatus.PENDING);
  const [selectedPrimaryRooms, setSelectedPrimaryRooms] = React.useState(
    getSelectedRooms(
      selectedItem.doorList,
      doorList.filter((s) => s.doorType === DoorType.PRIMARY)
    )
  );
  const [selectedCommonRooms, setSelectedCommonRooms] = React.useState(
    getSelectedRooms(
      selectedItem.doorList,
      doorList.filter((s) => s.doorType !== DoorType.PRIMARY)
    )
  );

  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(SiteDoorActions.fetch(0, 5000, { siteId: selectedSiteId }));
    }
  }, [dispatch, selectedSiteId]);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if ('ACCESS_RESERVATION_DELETE_SUCCESS' === lastActionType) {
      history.goBack();
    }

    if ('ACCESS_RESERVATION_EDIT_SUCCESS' === lastActionType) {
      Swal.fire({
        title: `Reservation Details Updated`,
        text: 'Your changes have been saved successfully',
        type: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        heightAuto: false,
      });
    }
  }, [lastActionType, history]);

  function requestReservationUpdate() {
    dispatch(
      Actions.update(
        {
          ...selectedItem,
          siteId: selectedSiteId,
          arrival: arrival,
          departure: departure,
          issueBehaviour: issueBehaviour,
          issueMode: issueMode,
          shareCard: shareCard,
          approvalStatus: approvalStatus,
          doorList: [
            ...selectedPrimaryRooms.map((cr) => {
              return cr.value;
            }),
            ...selectedCommonRooms.map((cr) => {
              return cr.value;
            }),
          ],
        },
        '/api/private/portal/access/reservation'
      )
    );
  }

  function requestDeleteConfirmation() {
    dispatch(
      Actions.delete(selectedItem, '/api/private/portal/access/reservation/' + selectedItem.id, null, {
        title: `Delete Reservation ${selectedItem.reference}?`,
        text: 'Warning: All mobile keys for this reservation will be REVOKED',
      })
    );
  }

  const showGuestDialog = (guest) => {
    setGuestEdit(guest);
    setGuestEditModalOpen(true);
  };

  const handleCloseGuestDialog = React.useCallback(() => {
    setGuestEditModalOpen(false);

    // add delay to stop UI from fluttering
    setTimeout(() => {
      setGuestEdit(null);
    }, 500);
  }, []);

  const deleteGuest = (guest) => {
    Swal.fire({
      title: `Delete Guest ${guest.email}${guest.parentGuestId === 0 ? ' and linked child guests' : ''}?`,
      text: 'Warning: Mobile keys for this guest will be REVOKED',
      icon: 'warning',
      showCancelButton: true,
      heightAuto: false,
    }).then((result) => {
      if (result.value) {
        setGuestEditModalOpen(false);
        setGuestEdit(null);
        dispatch(Actions.deleteGuest(guest));
      }
    });
  };

  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header="Update Reservation"
            subheader={selectedItem?.reference}
            onClick={requestReservationUpdate}
            buttonLoading={processing}
            onDelete={requestDeleteConfirmation}
          />

          <CardContent className="content-scrollable">
            {selectedSite?.featureList?.filter((sf) => sf.code === SiteFeature.ALWAYS_OVERRIDE_KEYS).length > 0 && (
              <Alert severity="warning" ariant={'filled'} className={'mb-2'}>
                Always Override Digital Keys feature is enabled for this site. This means that issuing a key for a specific room will make
                all other keys for this room invalid.
              </Alert>
            )}

            {(doorList.length === 0 || !doorList) && (
              <Alert severity="warning" ariant={'filled'} className={'mb-2'}>
                Failed to fetch rooms for this site. You will not be able to update the reservation. Please try again later.
              </Alert>
            )}

            {error && (
              <Alert severity="error" variant={'filled'} className={'mb-2'}>
                {error.message}
              </Alert>
            )}

            {/*{selectedItem.keyStatus === 0 && (*/}
            {/*  <Button size='medium' onClick={() => dispatch(Actions.issueKey(selectedSiteId, selectedItem.reference))}>*/}
            {/*    <VpnKeyOutlined /> &nbsp;Issue Keys*/}
            {/*  </Button>*/}
            {/*)}*/}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant={'outlined'}
                  type="text"
                  value={selectedItem?.reference}
                  label="Booking Reference"
                  disabled
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant={'outlined'}
                  type="text"
                  value={selectedItem?.reservationReference}
                  label="Our Reference"
                  disabled
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect label={'Issue Mode'} margin={'none'} value={issueMode} setValue={setIssueMode} options={KeyIssueModeOptions} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelect
                  label={'Issue Behaviour'}
                  value={issueBehaviour}
                  setValue={setIssueBehaviour}
                  margin={'none'}
                  options={KeyIssueBehaviorSelectOptions}
                />
              </Grid>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DateTimePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="Start Date"
                    value={arrival}
                    autoOk={true}
                    onChange={(date) => {
                      setArrival(date);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DateTimePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="End Date"
                    value={departure}
                    minDate={arrival}
                    autoOk={true}
                    onChange={(date) => {
                      setDeparture(date);
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <DoorSelector
                  doorType={DoorType.PRIMARY}
                  doorList={doorList}
                  selectedDoorList={selectedPrimaryRooms}
                  setSelectedDoorList={setSelectedPrimaryRooms}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <DoorSelector
                  doorType={DoorType.COMMON}
                  doorList={doorList}
                  selectedDoorList={selectedCommonRooms}
                  setSelectedDoorList={setSelectedCommonRooms}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormBooleanSelect label={'Share Card'} value={shareCard} setValue={setShareCard} margin={'none'} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={'Guest List'}
            subheader={'Guests who have been assigned to this reservation'}
            buttonName={'Add Guest'}
            buttonColour={'primary'}
            onClick={showGuestDialog}
          />

          <CardContent>
            {selectedItem.guestList.map((guest) => {
              return <GuestCard guest={guest} showEditGuestModal={showGuestDialog} deleteGuest={deleteGuest} />;
            })}

            {selectedItem.guestList.length <= 0 && (
              <Alert severity="info" variant={'filled'}>
                {'Add guests to your reservation'}
              </Alert>
            )}
          </CardContent>
        </Card>
      </Grid>

      <GuestDialog
        isOpen={guestEditModalOpen}
        onClose={handleCloseGuestDialog}
        guest={guestEdit}
        parentGuest={guestEdit && selectedItem?.guestList?.find((g) => g.id === guestEdit.parentGuestId)}
      />
    </Grid>
  );
}

const getSelectedRooms = (selectedItemRooms = [], siteRoomList = []) => {
  if (selectedItemRooms.length <= 0) {
    return [];
  }

  if (siteRoomList.length <= 0) {
    return [];
  }

  const result = [];

  for (let sr of siteRoomList) {
    const found = selectedItemRooms.find((sl) => sl.doorId === sr.doorId);
    if (found) {
      result.push(found);
    }
  }

  return result.map((e) => {
    return {
      value: e,
      label: e.doorName,
    };
  });
};
