import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import FormSelect from './FormSelect';
import { useSelector } from 'react-redux';

function mapThemeValues(themes) {
  return themes.map((t) => ({
    label: t.name,
    value: t.id,
  }));
}

export default function FormSelectTheme({ value, setValue, label, placeholder }) {
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const [availableThemes, setAvailableThemes] = useState([]);

  useEffect(() => {
    if (!selectedCorporation) {
      return;
    }

    API.get('PrivateAPI', '/api/private/portal/theme?corporationId=' + selectedCorporation.id, {})
      .then((response) => {
        setAvailableThemes(mapThemeValues(response.content));
      })
      .catch((error) => {
        console.warn('Unable to get themes due to error', error);
      });
  }, [selectedCorporation]);

  return (
    <FormSelect
      label={label}
      placeholder={placeholder || label}
      value={value}
      error={!value}
      setValue={setValue}
      options={availableThemes}
    />
  );
}
