import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../../components/form/FormSelect';

const ClarityFolioType = {
  MAINS: 'MAINS',
  EXTRAS: 'EXTRAS',
};

const ClarityFolioTypeOptions = [
  { label: 'mains', value: ClarityFolioType.MAINS },
  { label: 'extras', value: ClarityFolioType.EXTRAS },
];

export default function EditClarity() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);
  const [endpoint, setEndpoint] = React.useState(
    configuration?.endpoint || 'https://chmtest.clarityhospitality.co.nz/scripts/mgwms32.dll?wlapp=LIVXML&MGWLPN=CHMTEST_LIV2&SERVICE=XML27'
  );

  const [username, setUsername] = React.useState(configuration?.username || 'liverton');
  const [password, setPassword] = React.useState(configuration?.password || 'farm-spleen-red');
  const [guestFolioType, setGuestFolioType] = React.useState(configuration?.guestFolioType || ClarityFolioType.EXTRAS);
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'AUD');
  const [readTimeout, setReadTimeout] = React.useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = React.useState(configuration?.connectTimeout || 5000);

  const handleSubmit = useCallback(() => {
    if (selectedSite) {
      const payload = {
        ...selectedItem,
        name: name,
        enabled: enabled,
        systemCategory: selectedItem.systemCategory,
        systemType: selectedItem.systemType,
        siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
        systemConfiguration: JSON.stringify({
          endpoint,
          username,
          password,
          guestFolioType,
          debug,
          currency,
          readTimeout,
          connectTimeout,
        }),
      };

      if (selectedItem.id) {
        dispatch(Actions.updateSiteSystem(payload));
      } else {
        dispatch(Actions.createSiteSystem(payload));
      }
    }
  }, [
    connectTimeout,
    currency,
    debug,
    dispatch,
    enabled,
    endpoint,
    name,
    password,
    readTimeout,
    selectedItem,
    selectedSite,
    username,
    guestFolioType,
  ]);

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Clarity PMS' : 'Create Clarity PMS'}
        subheader={'Clarity Property Management System'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={false}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'API Endpoint'} type={'text'} value={endpoint} setValue={setEndpoint} error={!endpoint} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Auth Username'} type={'text'} value={username} setValue={setUsername} error={!username} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Auth Password'} type={'password'} value={password} setValue={setPassword} error={!password} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Guest Folio Type'} value={guestFolioType} setValue={setGuestFolioType} options={ClarityFolioTypeOptions} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Connect Timeout (MS)'} value={connectTimeout} setValue={setConnectTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout (MS)'} value={readTimeout} setValue={setReadTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
