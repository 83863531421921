const FoodThumbnailBehaviour = {
  SHOW: 'SHOW',
  SHOW_NO_PLACEHOLDER: 'SHOW_NO_PLACEHOLDER',
  OFF: 'OFF',
};

const FoodThumbnailBehaviours = [
  { value: FoodThumbnailBehaviour.SHOW, label: 'Show' },
  { value: FoodThumbnailBehaviour.SHOW_NO_PLACEHOLDER, label: 'Show No Placeholder' },
  { value: FoodThumbnailBehaviour.OFF, label: 'Off' },
];

export default FoodThumbnailBehaviour;
export { FoodThumbnailBehaviours };
