import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { DeleteOutlined, SaveAltOutlined } from '@material-ui/icons';
import { FoodOrderLocationTypes } from '../../../../../support/FoodOrderLocationType';
import { FoodOrderCheckModes } from '../../../../../support/FoodOrderCheckMode';
import OrderLocationActions from '../../../../redux/actions/inRoom/ordering/orderLocation/actions';
import { getFilterField, getFilterUpdateMethods, getSelectFilterField } from '../../../../../components/table/filter/filterUtils';

const columns = (setSelectedItem, setOpen, global) => [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      if (!value.id || (global && value.foodVendorId)) {
        return (
          <>
            <IconButton size={'small'} color={'inherit'} disabled>
              <DeleteOutlined />
            </IconButton>
          </>
        );
      }

      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(OrderLocationActions.delete(value, `/api/private/portal/food/order/location/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'locationCode',
    label: 'Location Code',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'locationName',
    label: 'Location Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'locationType',
    label: 'Location Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return FoodOrderLocationTypes.find((v) => value.locationType === v.value)?.label || 'N/A';
    },
  },
  {
    id: 'foodVendorName',
    label: 'Food Vendor Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'navigationCode',
    label: 'Start Tab',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'checkMode',
    label: 'Check Mode',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return FoodOrderCheckModes.find((v) => value.checkMode === v.value)?.label || 'N/A';
    },
  },
  {
    id: 'qrCode',
    label: 'QR Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return (
        <IconButton
          onClick={() => {
            setSelectedItem(value);
            setOpen(true);
          }}
        >
          <img src={`data:image/png;base64, ${value.qrCode}`} width={25} height={25} alt={'qr'} />
        </IconButton>
      );
    },
  },
];

export default function FoodOrderLocationList({ global = true, vendorId }) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const locationList = useSelector((state) => state.foodOrderLocationReducer.entityList);
  const processing = useSelector((state) => state.foodOrderLocationReducer.processing);
  const page = useSelector((state) => state.foodOrderLocationReducer.page);
  const error = useSelector((state) => state.foodOrderLocationReducer.error);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterState, setFilterState] = useState({
    locationCode: '',
    locationType: '',
  });

  const getParams = useMemo(() => {
    const params = {};
    if (filterState['locationCode'] || filterState['locationType']) {
      if (filterState['locationCode']) {
        params.locationCode = filterState['locationCode'] || '';
      }

      if (filterState['locationType']) {
        params.locationType = filterState['locationType'] || '';
      }
    }
    if (vendorId) {
      params.vendorId = vendorId;
    }
    return params;
  }, [filterState, vendorId]);

  const handlePageChangeWithParams = useCallback(
    (page, size, params) => {
      if (global) {
        dispatch(OrderLocationActions.fetch(page, size, params, { siteId: selectedSiteId }));
      } else {
        dispatch(OrderLocationActions.fetchByFoodVendor(page, size, params, vendorId));
      }
    },
    [dispatch, global, selectedSiteId, vendorId]
  );

  useEffect(() => {
    handlePageChangeWithParams(0, 20, getParams);
  }, [getParams, handlePageChangeWithParams]);

  const getFilterFieldsForScreen = (state, setState, options) => {
    const filterUpdateMethods = getFilterUpdateMethods(state, setState);
    return [
      getFilterField('locationCode', 'Location Code', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
      getSelectFilterField('locationType', 'Location Type', options, filterUpdateMethods),
    ];
  };

  return (
    <>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <img src={`data:image/png;base64, ${selectedItem?.qrCode}`} width={200} height={200} alt={'qr'} />
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={2}>
                {selectedItem?.foodVendorName && (
                  <>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: '700' }}>Vendor Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{selectedItem.foodVendorName}</Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: '700' }}>Code</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{selectedItem?.locationCode}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: '700' }}>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{selectedItem?.locationName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: '700' }}>Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{FoodOrderLocationTypes.find((v) => selectedItem?.locationType === v.value)?.label || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: '700' }}>Start Tab</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{selectedItem?.navigationCode}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: '700' }}>Check Mode</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{FoodOrderCheckModes.find((v) => selectedItem?.checkMode === v.value)?.label || 'N/A'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <AutoTable
        title="Location List"
        subheader={'A location is an area where QR codes are placed that links to the food ordering app.'}
        selectItem={(item) =>
          dispatch(
            OrderLocationActions.select({
              ...item,
              global: global,
            })
          )
        }
        secondaryButtonName={'Export'}
        secondaryButtonIcon={<SaveAltOutlined />}
        secondaryButtonAction={() => dispatch(OrderLocationActions.exportAll(selectedSiteId, getParams))}
        secondaryButtonDisabled={processing}
        processing={processing}
        handleChangePage={(ev, newPage, pageSize) => handlePageChangeWithParams(newPage, pageSize, getParams)}
        page={page}
        error={error}
        columns={columns(setSelectedItem, setOpen, global)}
        prefix="FOOD_ORDER_LOCATION"
        createNewURL={`/food/order/location/new?global=${global}`}
        detailURL={`/food/order/location/view/{id}?global=${global}`}
        idColumn={'id'}
        data={locationList}
        filters={getFilterFieldsForScreen(filterState, setFilterState, FoodOrderLocationTypes)}
        backHidden={true}
      />
    </>
  );
}
