const defaultScheduleDays = [
  {
    id: null,
    dayOfWeek: 'MONDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'TUESDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'WEDNESDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'THURSDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'FRIDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'SATURDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
  {
    id: null,
    dayOfWeek: 'SUNDAY',
    startTime: '00:00',
    endTime: '23:59',
  },
];

export default defaultScheduleDays;
