/* eslint-disable no-template-curly-in-string */

const SmsNotificationTemplateTags = [
  { value: 'APP_URL', label: '${APP_URL}' },
  { value: 'WEB_URL', label: '${WEB_URL}' },
  { value: 'CONFIRMATION_NUMBER', label: '${CONFIRMATION_NUMBER}' },
  { value: 'RESERVATION_NUMBER', label: '${RESERVATION_NUMBER}' },
  { value: 'FIRST_NAME', label: '${FIRST_NAME}' },
  { value: 'LAST_NAME', label: '${LAST_NAME}' },
  { value: 'SITE_NAME', label: '${SITE_NAME}' },
  { value: 'FLOW', label: '${FLOW}' },
  { value: 'ROOM_NUMBER', label: '${ROOM_NUMBER}' },
  { value: 'SMART_DATE', label: '${SMART_DATE}' },
  { value: 'ARRIVAL_DATE', label: '${ARRIVAL_DATE}' },
  { value: 'DEPARTURE_DATE', label: '${DEPARTURE_DATE}' },
];

const VendorNotificationTemplateTags = [
  { value: 'SITE_NAME', label: '${SITE_NAME}' },
  { value: 'ACTIVITY_NAME', label: '${ACTIVITY_NAME}' },
  { value: 'SCHEDULE_DATE', label: '${SCHEDULE_DATE}' },
  { value: 'FROM_TIME', label: '${FROM_TIME}' },
  { value: 'TO_TIME', label: '${TO_TIME}' },
  { value: 'RATES', label: '${RATES}' },
];

const ActivityBookingNotificationTemplateTags = [
  { value: 'SITE_NAME', label: '${SITE_NAME}' },
  { value: 'ACTIVITY_NAME', label: '${ACTIVITY_NAME}' },
  { value: 'SCHEDULE_DATE', label: '${SCHEDULE_DATE}' },
  { value: 'FROM_TIME', label: '${FROM_TIME}' },
  { value: 'TO_TIME', label: '${TO_TIME}' },
  { value: 'VENDOR_NAME', label: '${VENDOR_NAME}' },
  { value: 'STATUS', label: '${STATUS}' },
];

const UpsellNotificationTemplateTags = [
  { value: 'GUEST_NAME', label: '${GUEST_NAME}' },
  { value: 'SITE_NAME', label: '${SITE_NAME}' },
  { value: 'RESERVATION_NUMBER', label: '${RESERVATION_NUMBER}' },
  { value: 'PACKAGE_NAME', label: '${PACKAGE_NAME}' },
  { value: 'PACKAGE_QUANTITY', label: '${PACKAGE_QUANTITY}' },
  { value: 'PACKAGE_CODE', label: '${PACKAGE_CODE}' },
];

export {
  UpsellNotificationTemplateTags,
  SmsNotificationTemplateTags,
  VendorNotificationTemplateTags,
  ActivityBookingNotificationTemplateTags,
};
