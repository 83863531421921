const PaymentRuleType = {
  NONE: {
    code: 0,
    displayName: 'None',
  },
  FIXED: {
    code: 1,
    displayName: 'Fixed Amount',
  },
  NIGHTS_TIMES_RATE: {
    code: 2,
    displayName: 'Nights * Daily Rate',
  },
  NIGHTS_TIMES_RATE_AND_AMOUNT: {
    code: 3,
    displayName: 'Nights * (Daily Rate + Amount)',
  },
  NIGHTS_TIMES_RATE_PLUS_AMOUNT: {
    code: 4,
    displayName: '(Nights * Daily Rate) + Amount',
  },
  TOTAL_RATE_PLUS_AMOUNT: {
    code: 5,
    displayName: 'Total Room Rate + Amount',
  },
  TOTAL_RATE_PLUS_PERCENT: {
    code: 6,
    displayName: 'Total Room Rate + Percent',
  },
  CURRENT_BALANCE: {
    code: 7,
    displayName: 'Reservation Balance',
  },
  EXPECTED_BILL: {
    code: 8,
    displayName: 'Expected Bill',
  },
  NIGHTS_TIMES_AMOUNT: {
    code: 9,
    displayName: 'Nights x Amount',
  },
};

export default PaymentRuleType;
