import reducerCreator from '../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Vendor', 'VENDOR');

function vendorReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default vendorReducer;
