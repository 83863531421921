export const ActivityBookingStatus = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const ActivityBookingStatusOptions = [
  { label: 'Pending', value: ActivityBookingStatus.PENDING },
  { label: 'Paid', value: ActivityBookingStatus.PAID },
  { label: 'Accepted', value: ActivityBookingStatus.ACCEPTED },
  { label: 'Declined', value: ActivityBookingStatus.DECLINED },
];
