import * as Actions from '../../actions/roomTypes/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  processing: false,
  selectedItem: null,
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
};

export default function siteRoomTypeReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_ROOM_TYPE_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.SITE_ROOM_TYPE_PROCESSING:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    case Actions.SITE_ROOM_TYPE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_ROOM_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        processing: false,
        error: null,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
      };
    case Actions.SITE_ROOM_TYPE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case Actions.SITE_ROOM_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case Actions.SITE_ROOM_TYPE_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case Actions.SITE_ROOM_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        data: state.data.filter((rt) => rt.id !== action.payload.id),
        error: null,
      };
    case Actions.SITE_ROOM_TYPE_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case Actions.SITE_ROOM_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        data: state.data.map((rt) => (rt.id === action.payload.id ? action.payload : rt)),
        error: null,
      };
    case Actions.SITE_ROOM_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
