import reducerCreator from '../../support/reducerCreator';
import { SPECIAL_REQUEST_SUB_ADD_SUCCESS } from '../../actions/specialRequestSubCategory/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Special Request Sub Category', 'SPECIAL_REQUEST_SUB');

function subCategoryReducer(state = initial, action) {
  switch (action.type) {
    case SPECIAL_REQUEST_SUB_ADD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default subCategoryReducer;
