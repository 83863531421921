import { Box, Button, ButtonGroup, Grid, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TimeRange from './enum/TimeRange';
import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export default function DashboardTimeControl({ timeframe, setTimeframe, processing }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuIconClick = (event) => {
    setMenuAnchor(event.currentTarget);
    setOpenMenu(true);
  };

  const handleMenuIconClose = () => {
    setMenuAnchor(null);
    setOpenMenu(false);
  };

  const setTimeRangeAndCloseMenuIcon = (updatedUnit) => {
    // sessionStorage.setItem('timeRange', timeRange);
    setTimeframe({ ...timeframe, unit: updatedUnit });
    setOpenMenu(false);
  };

  const maxDate = moment();
  return (
    <Grid container spacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <Box pr={1} pt={1}>
          <Hidden lgUp>
            <>
              <IconButton
                aria-label="more"
                aria-controls="time-menu"
                aria-haspopup="true"
                onClick={handleMenuIconClick}
                disabled={processing}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={openMenu}
                onClose={handleMenuIconClose}
                PaperProps={{ style: { maxHeight: 216, width: '15ch' } }}
              >
                {Object.values(TimeRange).map((t) => (
                  <MenuItem key={t.value} selected={t.value === timeframe.unit} onClick={() => setTimeRangeAndCloseMenuIcon(t.value)}>
                    {t.icon}
                  </MenuItem>
                ))}
              </Menu>
            </>
          </Hidden>
          <Hidden mdDown>
            <ButtonGroup color="primary" aria-label="outlined primary button group" disabled={processing}>
              {Object.values(TimeRange).map((t) => {
                const selectedTime = timeframe.unit === t.value;
                const attr = {};
                if (selectedTime) {
                  attr['variant'] = 'contained';
                }
                return (
                  <Button {...attr} disableElevation key={t.value} onClick={() => setTimeRangeAndCloseMenuIcon(t.value)}>
                    {t.icon}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Hidden>
        </Box>
      </Grid>

      {timeframe.unit === TimeRange.CUSTOM.value && (
        <Grid item>
          <Box pr={1}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DatePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="From"
                    value={timeframe.start}
                    autoOk={true}
                    margin={'dense'}
                    maxDate={maxDate}
                    disabled={processing}
                    format="YYYY-MM-DD"
                    onChange={(date) => setTimeframe({ ...timeframe, start: date })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <DatePicker
                    fullWidth
                    inputVariant={'outlined'}
                    placeholder="To"
                    value={timeframe.end}
                    autoOk={true}
                    margin={'dense'}
                    minDate={timeframe.start}
                    maxDate={maxDate}
                    disabled={processing}
                    format="YYYY-MM-DD"
                    onChange={(date) => setTimeframe({ ...timeframe, end: date })}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
