import React from 'react';
import { Box } from '@material-ui/core';
import EditInfrasysConfig from './EditInfrasysConfig';
import InfrasysHookList from './InfrasysHookList';

export default function EditInfrasysPos() {
  return (
    <Box>
      <Box>
        <EditInfrasysConfig />
      </Box>
      <Box>
        <InfrasysHookList />
      </Box>
    </Box>
  );
}
