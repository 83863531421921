import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import FoodPriceScheduleActions from '../../../../redux/actions/inRoom/ordering/priceSchedule/actions';

const columns = (vendorOptions) => [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodPriceScheduleActions.delete(value, `/api/private/portal/food/price/schedule/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'scheduleName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'schedule',
    label: 'Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.schedule?.value) {
        return value.schedule?.value.map((val) => <Chip key={val} label={val} />);
      }
      return '';
    },
  },
  {
    id: 'from',
    label: 'Start Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'to',
    label: 'End Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_TIME,
  },
  {
    id: 'priceLevel',
    label: 'Price Level',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'scheduleOrder',
    label: 'Order',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'vendors',
    label: 'Vendors',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.vendors?.length > 0) {
        return value.vendors?.map((val) => {
          const label = vendorOptions?.find((vendor) => val === parseInt(vendor.value))?.label;
          return <Chip key={val} label={label} />;
        });
      }

      return [];
    },
  },
];

export default function FoodPriceScheduleList() {
  const dispatch = useDispatch();
  const vendorOptions = useSelector((state) => state.foodVendorReducer.vendorOptions);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const scheduleList = useSelector((state) => state.foodPriceScheduleReducer.entityList);
  const processing = useSelector((state) => state.foodPriceScheduleReducer.processing);
  const page = useSelector((state) => state.foodPriceScheduleReducer.page);
  const error = useSelector((state) => state.foodPriceScheduleReducer.error);

  return (
    <AutoTable
      title="Price Override Schedule List"
      subheader={'A Schedule contains which time and days price overrides should apply'}
      handleRefreshReport={() => dispatch(FoodPriceScheduleActions.fetch(0, 20, {}, { siteId: selectedSiteId }))}
      selectItem={(item) => dispatch(FoodPriceScheduleActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) =>
        dispatch(FoodPriceScheduleActions.fetch(newPage, pageSize, {}, { siteId: selectedSiteId }))
      }
      page={page}
      error={error}
      columns={columns(vendorOptions)}
      prefix="FOOD_PRICE_SCHEDULE"
      createNewURL="/food/price/schedule/new"
      detailURL="/food/price/schedule/view/{id}"
      idColumn={'id'}
      data={scheduleList}
    />
  );
}
