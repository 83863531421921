import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FormSelect from '../../../../../components/form/FormSelect';
import SpecialRequestCategory, { SpecialRequestCategoryOptions } from '../../../../../support/SpecialRequestCategory';
import CannedRegex from '../../../../../support/CannedRegex';
import FormField from '../../../../../components/form/FormField';
import SpecialRequestRedirectActions from '../../../../redux/actions/specialRequestRedirect/actions';
import SpecialRequestRedirectType, { SpecialRequestRedirectTypeOptions } from '../../../../../support/SpecialRequestRedirectType';
import PhoneNumberInput from '../../../../../components/phoneNumberInput/PhoneNumberInput';
import SpecialRequestContactGroup from '../../../../../support/SpecialRequestContactGroup';
import SpecialRequestEscalationRedirectActions from '../../../../redux/actions/specialRequestEscalationRedirect/actions';

export default function RequestRedirectModal({ open, handleClose, dataObject, setDataObject, isEscalation }) {
  const dispatch = useDispatch();
  const vendorOptions = useSelector((state) => state.foodVendorReducer.vendorOptions);
  const [id, setId] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [type, setType] = React.useState(SpecialRequestRedirectType.EMAIL);
  const [contactName, setContactName] = React.useState(null);
  const [contactGroup, setContactGroup] = React.useState(
    isEscalation ? SpecialRequestContactGroup.ESCALATION : SpecialRequestContactGroup.DEFAULT
  );
  const [email, setEmail] = React.useState('');
  const [associatedId, setAssociatedId] = React.useState('');
  const [phone, setPhone] = useState({
    phoneNumber: '',
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);

  useEffect(() => {
    if (dataObject) {
      setId(dataObject.id);
      setCategory(dataObject.category);
      setType(dataObject.type);
      setEmail(dataObject.value);
      setContactGroup(dataObject.contactGroup);
      setContactName(dataObject.contactName);
      setAssociatedId(dataObject.associatedId);
      setPhone({
        phoneNumber: dataObject.value,
        sanitizedPhoneNumber: dataObject.value,
        isValid: true,
      });
    } else {
      resetForm();
    }
    // eslint-disable-next-line
  }, [dataObject]);

  const handleSubmit = () => {
    const payload = {
      siteId: selectedSite?.id,
      category: category,
      type: type,
      contactGroup: contactGroup,
      contactName: contactName,
      associatedId: associatedId,
      value: type === SpecialRequestRedirectType.EMAIL ? email : phone.sanitizedPhoneNumber,
    };

    if (id) {
      payload.id = id;
    }

    dataObject
      ? isEscalation
        ? dispatch(SpecialRequestEscalationRedirectActions.update(payload, '/api/private/specialRequestRedirect'))
        : dispatch(SpecialRequestRedirectActions.update(payload, '/api/private/specialRequestRedirect'))
      : isEscalation
      ? dispatch(SpecialRequestEscalationRedirectActions.add(payload))
      : dispatch(SpecialRequestRedirectActions.add(payload));

    resetForm();
  };

  const resetForm = () => {
    setDataObject(null);
    handleClose();
    setCategory(null);
    setType(SpecialRequestRedirectType.EMAIL);
    setContactGroup(isEscalation ? SpecialRequestContactGroup.ESCALATION : SpecialRequestContactGroup.DEFAULT);
    setContactName(null);
    setEmail('');
    setAssociatedId('');
    setPhone({
      phoneNumber: '',
      sanitizedPhoneNumber: '',
      isValid: false,
    });
  };

  useEffect(() => {
    if (type) {
      setEmail('');
    }
  }, [type]);

  const isValueValid = useMemo(() => {
    if (type === SpecialRequestRedirectType.EMAIL) {
      return CannedRegex.isEmailValid(email);
    } else if (type === SpecialRequestRedirectType.PHONE) {
      return phone.isValid;
    } else {
      return false;
    }
  }, [email, phone, type]);

  const formReady = category !== null && isValueValid;

  return (
    <Dialog open={open} onClose={resetForm}>
      <DialogTitle>Notification Contact</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormSelect label={'Category'} value={category} setValue={setCategory} options={SpecialRequestCategoryOptions} />
          <FormSelect label={'Type'} value={type} setValue={setType} options={SpecialRequestRedirectTypeOptions} />
          <FormField label={'Contact Group'} value={contactGroup} setValue={setContactGroup} disabled={true} />
          <FormField label={'Contact Name'} value={contactName} setValue={setContactName} />
          {category === SpecialRequestCategory.DINING && (
            <FormSelect label={'Vendor'} value={associatedId} setValue={setAssociatedId} options={vendorOptions} hasClear />
          )}
          {type === SpecialRequestRedirectType.EMAIL ? (
            <FormField label={'Value'} value={email} setValue={setEmail} error={!isValueValid} />
          ) : (
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" disabled={!formReady} onClick={handleSubmit}>
          {dataObject ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
