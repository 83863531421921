import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodItemActions from '../../../../redux/actions/inRoom/ordering/foodItem/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Box, Card, CardContent, Chip, Collapse, Grid, IconButton, Paper, TextField, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import toBase64 from '../../../../../support/toBase64Converter';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FoodInfoActions from '../../../../redux/actions/inRoom/ordering/foodInfo/actions';
import FoodModifierGroupActions from '../../../../redux/actions/inRoom/ordering/foodModifierGroup/actions';
import { AddOutlined, ExpandLess, ExpandMore, SaveOutlined } from '@material-ui/icons';
import FoodSetChoice from './FoodSetChoice';
import FoodSetChoiceActions from '../../../../redux/actions/inRoom/ordering/foodSetChoice/actions';
import FoodPriceLevel from './FoodPriceLevel';
import Objects from '../../../../../support/Objects';

export default function FoodItemEdit() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const foodInfoSelection = useSelector((state) => state.foodInfoReducer.entityList || []);
  const modifierGroupList = useSelector((state) => state.foodModifierGroupReducer.entityList || []);
  const choiceList = useSelector((state) => state.foodSetChoiceReducer.entityList || []);
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodItemReducer);
  const [name, setName] = useState(selectedItem?.name || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [price, setPrice] = useState(selectedItem?.price || '');
  const [capacity, setCapacity] = useState(selectedItem?.capacity || 0);
  const [sequence, setSequence] = useState(selectedItem?.sequence || null);
  const [available, setAvailable] = useState(!!selectedItem?.available);
  const [inStock, setInStock] = useState(!!selectedItem?.inStock);
  const [tags, setTags] = useState(selectedItem?.tags || []);
  const [infos, setInfos] = useState(selectedItem?.infos || []);
  const [modifierGroups, setModifierGroups] = useState(selectedItem?.modifierGroups || []);
  const [banner, setBanner] = useState(selectedItem?.banner || '');
  const [bannerHash, setBannerHash] = useState(selectedItem?.bannerHash);
  const [bannerBase64, setBannerBase64] = useState(null);
  const [thumbnail, setThumbnail] = useState(selectedItem?.imageThumbnail || '');
  const [thumbnailHash, setThumbnailHash] = useState(selectedItem?.thumbnailHash);
  const [thumbnailBase64, setThumbnailBase64] = useState(null);
  const [open, setOpen] = useState(true);
  const [choices, setChoices] = useState(selectedItem?.choices || []);
  const [isSetMenu, setIsSetMenu] = useState(!!selectedItem?.choices.length);
  const [childError, setChildError] = useState(false);
  const [priceLevels, setPriceLevels] = useState(selectedItem?.priceLevels || []);

  useEffect(() => {
    if (vendorId) {
      dispatch(FoodInfoActions.fetchAll(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    setChoices(choiceList);
  }, [choiceList]);

  useEffect(() => {
    if (selectedItem?.id) {
      dispatch(FoodSetChoiceActions.fetchAll(selectedItem.id));
    }
  }, [dispatch, selectedItem?.id]);

  const infoOptions = useMemo(() => {
    return foodInfoSelection.map((fis) => ({ label: fis.description, value: fis.id }));
  }, [foodInfoSelection]);

  const modifierGroupOptions = useMemo(() => {
    return modifierGroupList.map((mg) => ({
      label: mg.groupName + (mg.externalId ? ' - ' + mg.externalId : ''),
      value: mg.id,
    }));
  }, [modifierGroupList]);

  useEffect(() => {
    if (vendorId) {
      dispatch(FoodInfoActions.fetchAll(vendorId));
      dispatch(FoodModifierGroupActions.fetchAll(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    dispatch(FoodItemActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (thumbnail) {
      toBase64(thumbnail).then((base64) => {
        setThumbnailBase64(base64);
      });
    }
  }, [thumbnail]);

  useEffect(() => {
    if (banner) {
      toBase64(banner).then((base64) => {
        setBannerBase64(base64);
      });
    }
  }, [banner]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_ITEM_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_ITEM_ADD_SUCCESS' ||
      lastActionType === 'FOOD_INFO_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleThumbnailSelect = (file) => {
    setThumbnail(file);
    setThumbnailHash(Objects.hashCode(file.name + file.lastModified));
  };

  const handleDeleteChoice = useCallback((index) => {
    setChoices((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
  }, []);

  const handleBannerSelect = (file) => {
    setBanner(file);
    setBannerHash(Objects.hashCode(file.name + file.lastModified));
  };

  const handleSetChoice = useCallback(
    (index) => (item) => {
      setChoices((prev) => {
        const result = [...prev];
        result.splice(index, 1, item);
        return result;
      });
    },
    []
  );

  const handleDeletePriceLevel = useCallback((index) => {
    setPriceLevels((prev) => {
      const result = [...prev];
      result.splice(index, 1);
      return result;
    });
  }, []);

  const handlePriceLevel = useCallback(
    (index) => (item) => {
      setPriceLevels((prev) => {
        const result = [...prev];
        result.splice(index, 1, item);
        return result;
      });
    },
    []
  );

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      name: name,
      description: description,
      imageThumbnail: thumbnailBase64,
      thumbnailHash: thumbnailHash,
      banner: bannerBase64,
      bannerHash: bannerHash,
      price: price,
      capacity: capacity,
      sequence: sequence,
      available: available,
      inStock: inStock,
      tags: tags,
      infos: infos,
      modifierGroups: modifierGroups,
      choices: choices,
      priceLevels: priceLevels,
    };

    if (selectedItem) {
      dispatch(FoodItemActions.update(payload, `/api/private/portal/food/item/${selectedItem.id}`, false));
    } else {
      dispatch(FoodItemActions.add(payload, { vendorId: vendorId }));
    }
  };

  const handleTagUpdate = (event) => {
    const value = event.target.value;

    if (!value || (tags && tags.length > 0 && tags.filter((tag) => tag === value).length > 0)) {
      return;
    }

    if (tags.length > 0) {
      setTags([...tags, value]);
    } else {
      setTags([value]);
    }
  };

  const handleTagDelete = (value) => {
    setTags(tags.filter((item) => item !== value));
  };

  const handleModifierGroupUpdate = (_, value) => {
    if (!value || (modifierGroups && modifierGroups.length > 0 && modifierGroups.filter((mg) => mg === value[0].value).length > 0)) {
      return;
    }

    if (modifierGroups.length > 0) {
      setModifierGroups([...modifierGroups, value[value.length - 1].value]);
    } else {
      setModifierGroups([value[0].value]);
    }
  };

  const handleModifierGroupDelete = (value) => {
    setModifierGroups(modifierGroups.filter((item) => item !== value));
  };

  const isFormReady = name && !processing && !childError;

  return (
    <Card>
      <Collapse in={open} timeout="auto">
        <CardHeaderWithControls
          header={selectedItem ? 'Update Food Item' : 'Create Food Item'}
          subheader={'A Food Item is contains basic information about the food'}
          buttonName={selectedItem ? 'Update' : 'Create'}
          onClick={handleSubmit}
          disabled={!isFormReady}
          deleteDisabled={!selectedItem?.id}
          onDelete={() => dispatch(FoodItemActions.delete(selectedItem, `/api/private/portal/food/item/${selectedItem.id}`))}
        />
        <CardContent>
          {error && (
            <Alert severity="error" variant={'filled'} className={'mb-2'}>
              {`Unable to add food item - ${error.message}`}
            </Alert>
          )}

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormField label={'Name'} value={name} setValue={setName} required />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormField label={'External ID'} value={selectedItem?.externalId || ''} disabled />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Description'} value={description} setValue={setDescription} />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormField
                label={price ? 'Price' : 'Open Price'}
                value={price}
                setValue={setPrice}
                type={'number'}
                inputProps={{ step: '0.01' }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormField label={'Sequence'} value={sequence} setValue={setSequence} type={'number'} />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormField label={'Capacity'} value={capacity} setValue={setCapacity} type={'number'} />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormBooleanSelect label={'In Stock'} value={inStock} setValue={setInStock} />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormBooleanSelect label={'Set Menu'} disabled={!selectedItem?.id} value={isSetMenu} setValue={setIsSetMenu} />
            </Grid>

            <FileImageSelector
              sourceItem={selectedItem}
              sourceItemProperty={'banner'}
              displayName={'Banner'}
              onSelect={handleBannerSelect}
              imageCleared={(value) => {
                if (value) {
                  setBannerBase64(null);
                  setBanner(null);
                }
              }}
            />

            <FileImageSelector
              sourceItem={selectedItem}
              sourceItemProperty={'imageThumbnail'}
              displayName={'Thumbnail'}
              onSelect={handleThumbnailSelect}
              imageCleared={(value) => {
                if (value) {
                  setThumbnailBase64(null);
                  setThumbnail(null);
                }
              }}
            />

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                multiple
                id="tags"
                options={[]}
                defaultValue={[]}
                freeSolo
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} label={'Tags'} placeholder="Tags" variant={'outlined'} margin={'dense'} />}
                value={tags}
                renderTags={(values, getTagProps) =>
                  values.map((option, index) => <Chip key={option} label={option} onDelete={() => handleTagDelete(option)} />)
                }
                onChange={handleTagUpdate}
                onClose={handleTagUpdate}
                clearOnBlur
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormMultiChipSelect label={'Food Info'} value={infos} setValue={setInfos} options={infoOptions} />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Autocomplete
                multiple
                fullWidth
                name={'Modifier Groups'}
                placeholder={'Modifier Groups'}
                options={modifierGroupOptions}
                autoComplete={false}
                filterSelectedOptions
                getOptionSelected={(option, selected) => option.value === selected}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField required={false} {...params} label={'Modifier Groups'} margin={'dense'} variant="outlined" />
                )}
                value={modifierGroups}
                renderTags={(values, getTagProps) =>
                  values.map((option, index) => {
                    const label = modifierGroupOptions?.find((mg) => mg.value === option)?.label || 'N/A';
                    return (
                      <Chip {...getTagProps({ index })} size={'small'} label={label} onDelete={() => handleModifierGroupDelete(option)} />
                    );
                  })
                }
                onChange={handleModifierGroupUpdate}
                clearOnBlur
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormBooleanSelect label={'Available'} value={available} setValue={setAvailable} />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <Box>
        <Box
          style={{
            backgroundColor: theme.palette.grays[10],
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setOpen(!open)}
        >
          <IconButton
            size={'small'}
            style={{
              maxHeight: '15px',
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Paper>
          {priceLevels?.length > 0 && !isSetMenu && (
            <Card>
              <CardHeaderWithControls
                header={'Price Levels'}
                subheader={'Price Overrides per Schedule'}
                buttonName={'Add Price Level'}
                buttonIcon={<AddOutlined />}
                backHidden
                onClick={() => setPriceLevels((prev) => [...prev, { foodItemId: selectedItem?.id }])}
              />
              <CardContent>
                {priceLevels?.map((priceLevel, idx) => (
                  <FoodPriceLevel
                    key={`fpl-${idx}-${priceLevel.id}`}
                    index={idx}
                    item={priceLevel}
                    setItem={handlePriceLevel}
                    deleteItem={handleDeletePriceLevel}
                    setError={setChildError}
                  />
                ))}
              </CardContent>
            </Card>
          )}
          {isSetMenu && (
            <Card>
              <CardHeaderWithControls
                header={'Set Menu Customisations'}
                subheader={'Modify Set Menu Choices and Sub-Modifiers'}
                buttonName={'Add Choice'}
                buttonIcon={<AddOutlined />}
                secondaryButtonName={'Save'}
                secondaryButtonIcon={<SaveOutlined />}
                secondaryButtonAction={handleSubmit}
                backHidden
                onClick={() => setChoices((prev) => [...prev, {}])}
              />
              <CardContent>
                {choices
                  ?.filter((choice) => choice.available)
                  .map((choice, idx) => (
                    <FoodSetChoice
                      key={`fsc-${choice.id}-${idx}`}
                      index={idx}
                      vendorId={vendorId}
                      choice={choice}
                      setChoice={handleSetChoice}
                      deleteChoice={handleDeleteChoice}
                      setError={setChildError}
                      foodItemId={selectedItem?.id}
                    />
                  ))}
              </CardContent>
            </Card>
          )}
        </Paper>
      </Box>
    </Card>
  );
}
