import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import UserRoleHelper from '../support/UserRoleHelper';

const SecureRouteWithProps = ({ role, component, ...rest }) => {
  const profile = useSelector((state) => state.accessReducer.profile);
  return <Route {...rest}>{UserRoleHelper.hasRole(profile, role) ? component : <Redirect to={'/'} />}</Route>;
};

export default React.memo(SecureRouteWithProps);
