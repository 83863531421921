import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import * as Actions from '../../../../redux/actions/system/actions';
import {
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import NewSystemModal from './NewSystemModal';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Swal from 'sweetalert2';
import { DeleteOutlined } from '@material-ui/icons';
import BooleanTableColumn from '../../../../../components/table/BooleanTableColumn';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';

export default function SiteSystemList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const siteResourcesList = useSelector((state) => state.siteResourcesReducer.data);
  const lastActionType = useSelector((state) => state.siteResourcesReducer.lastActionType);
  const error = useSelector((state) => state.siteResourcesReducer.error);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);

  useEffect(() => {
    if (selectedSite) {
      dispatch(Actions.getSystemListForSite(selectedSite.id));
    }
  }, [selectedSite, dispatch]);

  useEffect(() => {
    if (lastActionType === Actions.SITE_SYSTEM_DELETE_SUCCESS) {
      Swal.fire('Delete Complete', 'System removed successfully', 'success');
    } else if (lastActionType === Actions.SITE_SYSTEM_UPDATE_SUCCESS) {
      Swal.fire('Update Complete', 'System updated successfully', 'success');
    } else if (lastActionType === Actions.SITE_SYSTEM_CREATE_SUCCESS) {
      Swal.fire('Created', 'System created successfully', 'success');
    }
  }, [lastActionType]);

  useEffect(() => {
    if (error) {
      Swal.fire('Something Went Wrong', error.message, 'error');
      dispatch(Actions.clearError());
    }
  }, [dispatch, error]);

  const selectItem = (item) => {
    dispatch(Actions.selectItem(item));
    history.push('/site/systems/view/' + item.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={'Site Systems'}
        subheader={'Systems available at the selected site i.e. PMS and Lock systems'}
        buttonName={'Create'}
        onClick={() => setModalOpen(true)}
      />

      <CardContent>
        {siteResourcesList.length <= 0 && (
          <Alert color={'info'} variant={'filled'} className={'mb-2'}>
            No systems available - add to get started
          </Alert>
        )}

        {siteResourcesList.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>System Type</TableCell>
                  <TableCell>System Category</TableCell>
                  <TableCell align={'center'}>Enabled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {siteResourcesList.map((system) => (
                  <TableRow key={`site-system-${system.id}`}>
                    <TableCell>
                      <IconButton onClick={() => dispatch(Actions.deleteSiteSystem(system))} size={'small'}>
                        <DeleteOutlined />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Link component="button" onClick={() => selectItem(system, system.id)}>
                        {system.name}
                      </Link>
                    </TableCell>
                    <TableCell>{system.systemType}</TableCell>
                    <TableCell>{system.systemCategory}</TableCell>
                    <BooleanTableColumn value={system.enabled} align={'center'} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={siteResourcesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </CardContent>

      <NewSystemModal handleClose={() => setModalOpen(false)} newSystemModalOpen={modalOpen} />
    </Card>
  );
}
