import * as DataTypes from '../../../../../../constants/DataTypes';
import SystemCalendarEventActions from '../../../../../redux/actions/system/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import { getFilterField, getFilterUpdateMethods } from '../../../../../../components/table/filter/filterUtils';
import moment from 'moment';
import { Box, IconButton, Link } from '@material-ui/core';
import SiteEventEditDialog from './SiteEventEditDialog';
import { SystemCalendarEventOptions, SystemCalendarType } from '../../../../../../support/SystemCalendar';
import { DeleteOutlined } from '@material-ui/icons';
import UserRole from '../../../../../../support/UserRole';

const columns = [
  {
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          {/* Add checking to remove delete button per role*/}
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(SystemCalendarEventActions.delete(value, `/api/private/portal/system/calendar/event/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'scheduleName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return <Link component="button" onClick={() => dispatch(SystemCalendarEventActions.select(value))} children={value.scheduleName} />;
    },
  },
  {
    id: 'level',
    label: 'Level',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      if (value.corpId === null && value.siteId === null) {
        return 'Global';
      } else if (value.corpId != null && value.siteId === null) {
        return 'Organisation';
      } else if (value.corpId != null && value.siteId !== null) {
        return 'Site';
      }
      return 'Unknown';
    },
  },
  {
    id: 'scheduleFrom',
    label: 'From',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: (value) => moment(value.scheduleFrom).format('LLL'),
  },
  {
    id: 'scheduleTo',
    label: 'To',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: (value) => moment(value.scheduleTo).format('LLL'),
  },
  {
    id: 'eventType',
    label: 'Event',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: (value) => SystemCalendarEventOptions.find((e) => e.value === value.eventType)?.label,
  },
];

const initial = {
  scheduleName: '',
  scheduleFrom: moment().add(1, 'hour'),
  scheduleTo: moment().add(2, 'hour'),
  eventType: null,
};

export function SystemCalendarEventList({ calendarType, timezone }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);
  const selectedItem = useSelector((state) => state.systemCalendarEventReducer.selectedItem);
  const page = useSelector((state) => state.systemCalendarEventReducer.page);
  const error = useSelector((state) => state.systemCalendarEventReducer.error);
  const processing = useSelector((state) => state.systemCalendarEventReducer.processing);
  const items = useSelector((state) => state.systemCalendarEventReducer.entityList);
  const selectedSiteId = selectedSite?.id;
  const selectedCorpId = selectedCorp?.id;
  const [state, setState] = useState({
    from: moment(),
    to: moment().add(1, 'month'),
  });

  const filters = getFilterFieldsForScreen(state, setState);

  const payload = useMemo(() => {
    let searchPayload = { ...state };
    searchPayload.sort = 'lastModifiedDate,desc';
    searchPayload.timezone = timezone;
    if (SystemCalendarType.ORGANISATION === calendarType) {
      searchPayload.corpId = selectedCorpId;
    } else if (SystemCalendarType.SITE === calendarType) {
      searchPayload.corpId = selectedCorpId;
      searchPayload.siteId = selectedSiteId;
    }

    return searchPayload;
  }, [state, selectedCorpId, selectedSiteId, timezone, calendarType]);

  const calendarName = useMemo(() => {
    if (SystemCalendarType.ORGANISATION === calendarType) {
      return 'Organisation';
    } else if (SystemCalendarType.SITE === calendarType) {
      return 'Site';
    }

    return 'Global';
  }, [calendarType]);

  const isEventReadOnly = (event) => {
    const corpId = event?.corpId || event?.event?.extendedProps?.corpId;

    switch (profile.role) {
      case UserRole.ROLE_SUPER:
        break;
      case UserRole.ROLE_CORP_ADMIN:
        if (corpId != null) {
          break;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch(SystemCalendarEventActions.select(null));
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId && payload) {
      dispatch(SystemCalendarEventActions.fetch(payload.corpId, payload.siteId, payload.from, payload.to, 0, 25, payload.timezone));
    }
  }, [selectedSiteId, dispatch, payload]);

  useEffect(() => {
    dispatch(SystemCalendarEventActions.clearError());
  }, [dispatch]);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(
      SystemCalendarEventActions.fetch(payload.corpId, payload.siteId, payload.from, payload.to, newPage, pageSize, payload.timezone)
    );
  };

  const clearSelected = () => {
    dispatch(SystemCalendarEventActions.select(null));
  };

  const setSelected = () => {
    dispatch(SystemCalendarEventActions.select({ ...initial, corpId: payload?.corpId, siteId: payload?.siteId }));
  };

  return (
    <Box>
      {selectedItem && (
        <SiteEventEditDialog
          event={selectedItem}
          siteId={payload?.siteId}
          corpId={payload?.corpId}
          clearSelection={() => clearSelected()}
          calendarType={calendarType}
          timezone={timezone}
          readOnly={isEventReadOnly(selectedItem)}
        />
      )}

      <AutoTable
        title={`${calendarName} Event Calendar`}
        subheader={'List of Outage and related Events for the Site'}
        onCreateNew={() => setSelected()}
        handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
        processing={processing}
        page={page}
        selectItem={(item) => dispatch(SystemCalendarEventActions.select(item))}
        error={error}
        clearError={() => dispatch(SystemCalendarEventActions.clearError())}
        columns={columns}
        prefix="SYSTEM_CALENDAR_EVENT"
        data={items}
        idColumn={'id'}
        filters={filters}
      />
    </Box>
  );
}

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('from', 'From', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
    getFilterField('to', 'To', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
  ];
}
