import React, { useLayoutEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import dayGridPlugin from '@fullcalendar/daygrid';
import FormSelect from '../../../../components/form/FormSelect';

const initial = {
  siteId: 0,
  tripReference: '',
  timeId: 0,
  date: '',
  rates: [],
};

const DATE_FORMAT = 'YYYY-MM-DD';

export default function ActivityBookingRequestDialog({ open, setClose }) {
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const activityList = useSelector((state) => state.activityReducer.entityList);
  const activityOptions = activityList?.map((act) => ({ label: act.name, value: act.id }));

  const [events, setEvents] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [booking, setBooking] = useState(initial);

  const handleSubmit = async () => {
    const { rates, ...others } = booking;

    const payload = {
      siteId: selectedSite.id,
      ...others,
      rates: rates.map((rate) => ({ id: rate.id, quantity: rate.quantity })),
    };

    try {
      await API.post('PrivateAPI', `/api/private/portal/activity/booking`, { body: payload });
      Swal.fire({
        title: 'Booking Request',
        html: `Booking Request has been sent successfully.`,
        icon: 'success',
        confirmButtonText: 'OK',
        target: '#act-booking-request-dialog',
      }).then((result) => {
        if (result.value) {
          handleClose();
        }
      });
    } catch (error) {
      console.error('Failed to Submit Booking', error);
      Swal.fire({
        title: 'Failed to Submit Booking',
        html: `Failed to Submit Booking. ${error.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
        target: '#act-booking-request-dialog',
      });
    }
  };

  const renderEvent = (event) => {
    return (
      <Box width={'100%'} className={'fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-dot-event'} style={{ padding: 0 }}>
        <Box className={'fc-daygrid-event-dot'} style={{ borderColor: event.borderColor }}></Box>
        <Box className={'fc-event-time'}>{event.timeText}</Box>
      </Box>
    );
  };

  useLayoutEffect(() => {
    if (dateRange && selectedActivity) {
      API.post('PrivateAPI', `/api/private/portal/activity/${selectedActivity}/calendar`, {
        body: {
          from: dateRange.start,
          to: dateRange.end,
        },
      })
        .then((response) => {
          setEvents(response);
        })
        .catch((error) => {
          console.error('Failed to Fetch Calendar', error);
          Swal.fire({
            title: 'Failed to Fetch Calendar',
            html: 'Failed to Fetch Calendar for this activity. Please try again later.',
            icon: 'error',
            target: '#act-booking-request-dialog',
          });
        });
    }
  }, [selectedActivity, dateRange]);

  function handleClose() {
    setEvents([]);
    setSelectedActivity('');
    setDateRange(null);
    setBooking(initial);
    setClose();
  }

  const handleRateChange = (id) => (event) => {
    const updatedRates = booking.rates.map((rate) => {
      if (rate.id === id) {
        return { ...rate, quantity: parseInt(event.target.value) };
      }

      return rate;
    });

    setBooking({ ...booking, rates: updatedRates });
  };

  const handleEventClick = (info) => {
    setBooking({
      tripReference: booking.tripReference,
      timeId: info.event.extendedProps?.timeId,
      date: moment(info.event.start).format(DATE_FORMAT),
      rates: info.event.extendedProps?.rates.map((rate) => ({ id: rate.id, quantity: 0, name: rate.name })),
    });
  };

  const isFormReady = booking.tripReference && booking.rates?.map((rate) => rate.quantity).reduce((a, b) => a + b, 0) > 0;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} id={'act-booking-request-dialog'}>
      <DialogTitle> Request Booking </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormSelect
                  label={'Activity'}
                  value={selectedActivity}
                  setValue={(value) => setSelectedActivity(value)}
                  options={activityOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  required
                  variant={'outlined'}
                  value={booking.tripReference}
                  onChange={(event) => setBooking({ ...booking, tripReference: event.target.value })}
                  label="Trip Reference"
                  margin={'dense'}
                />
              </Grid>
              {booking?.rates?.map((rate, idx) => {
                return (
                  <Grid item xs={4} key={`rate-${idx}`}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="number"
                      variant={'outlined'}
                      value={rate.quantity}
                      onChange={handleRateChange(rate.id)}
                      label={rate.name}
                      margin={'dense'}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  variant={'outlined'}
                  value={booking.date ? moment(booking.date).format('LL') : ''}
                  label="Date"
                  disabled
                  margin={'dense'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <FullCalendar
              slotMinWidth={50}
              events={events}
              eventContent={renderEvent}
              plugins={[dayGridPlugin]}
              datesSet={(args) => {
                setDateRange({
                  start: moment(args.start).format(DATE_FORMAT),
                  end: moment(args.end).format(DATE_FORMAT),
                });
              }}
              displayEventEnd={true}
              dayMaxEventRows={2}
              selectable={true}
              eventClick={handleEventClick}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!isFormReady} variant={'contained'} color="primary" onClick={handleSubmit}>
          OK
        </Button>
        <Button variant={'contained'} onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
