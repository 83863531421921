import * as Actions from '../../../actions/theme/messages/actions';

const initial = {
  lastActionType: 'NONE',
  processing: false,
  error: null,
  theme: null,
  messages: [],
};

export default function themeMessagesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.FETCH_THEME_MESSAGES_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        messages: [],
        processing: true,
        error: null,
      };
    case Actions.FETCH_THEME_MESSAGES_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        messages: action.payload,
        processing: false,
      };
    case Actions.FETCH_THEME_MESSAGES_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        messages: [],
        processing: false,
        error: action.payload,
      };
    case Actions.UPDATE_THEME_MESSAGES_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case Actions.UPDATE_THEME_MESSAGES_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        messages: action.payload,
        processing: false,
      };
    case Actions.UPDATE_THEME_MESSAGES_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
