import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodTaxActions from '../../../../redux/actions/inRoom/ordering/foodTax/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Check, DeleteOutlined } from '@material-ui/icons';
import { PortionType } from '../../../../../support/PortionTypes';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodTaxActions.delete(value, `/api/private/portal/food/tax/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'taxName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'taxCode',
    label: 'Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External Id',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'taxAmount',
    label: 'Amount',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      const taxAmount = value.taxAmount;
      return value.taxType === PortionType.FIXED ? '$ ' + taxAmount : taxAmount + '%';
    },
  },
  {
    id: 'taxType',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'taxInclusion',
    label: 'Inclusion',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.enabled) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodTaxList() {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const entityList = useSelector((state) => state.foodTaxReducer.entityList);
  const processing = useSelector((state) => state.foodTaxReducer.processing);
  const page = useSelector((state) => state.foodTaxReducer.page);
  const error = useSelector((state) => state.foodTaxReducer.error);

  return (
    <AutoTable
      title="Food Taxes"
      subheader={'Taxes can be displayed to a customer when ordering food. Normally you can expect a single tax i.e. GST'}
      handleRefreshReport={() => dispatch(FoodTaxActions.fetch(0, 20, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodTaxActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodTaxActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_TAX"
      createNewURL="/food/tax/new"
      detailURL="/food/tax/view/{id}"
      idColumn={'id'}
      data={entityList}
    />
  );
}
