import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import { exportReport, requestReport } from '../../../../redux/actions/reservation/report/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReportType from '../../../../../support/ReportType';
import UpsellOfferType from '../../../../../support/UpsellOfferType';
import TimeZones from '../../../../../support/TimeZones';
import {
  FeedbackSubmissionColumns,
  PreArrivalColumns,
  SpecialRequestColumns,
  UpsellColumns,
  PaymentColumns,
  RoomSelectionColumns,
  ClickTrackingColumns,
  NotificationsColumns,
  GuestDetailColumns,
  CompanyColumns,
  UserAuditColumns,
  UnsubscribedUsersColumns,
  GuestOrderColumns,
} from './support/reportColumns';
import {
  feedbackFilters,
  preArrivalFilters,
  specialRequestFilters,
  upsellFilers,
  paymentFilters,
  roomSelectionFilters,
  clickTrackingFilters,
  membershipReportFilters,
  reservationNotificationsFilters,
  guestDetailFilters,
  userAuditFilters,
  unsubscribedGuestsFilters,
  guestOrderFilters,
} from './support/reportFilters';
import PurchaseState from '../../../../../support/PurchaseState';
import { getMembershipTableColumns } from '../../site/system/membership/MembershipEnrolmentList';
import ReservationFetchType from '../../../../../support/ReservationFetchType';
import UserRoleHelper from '../../../../../support/UserRoleHelper';
import UnsubscribedTypes from '../../../../../support/UnsubscribedTypes';
import UserRole from '../../../../../support/UserRole';
import { FoodOrderStatusOptions } from '../../../../../support/FoodOrderStatus';
import { FoodOrderLocationTypes } from '../../../../../support/FoodOrderLocationType';

export default function ReservationReport({ title = 'Reservation Report', subheader = 'Reservation report for your property' }) {
  const dispatch = useDispatch();
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const reservationReportList = useSelector((state) => state.hotelReservationReportReducer.entityList);
  const page = useSelector((state) => state.hotelReservationReportReducer.page);
  const error = useSelector((state) => state.hotelReservationReportReducer.error);
  const processing = useSelector((state) => state.hotelReservationReportReducer.processing);
  const role = useSelector((state) => state.accessReducer.profile.role);

  const roles = UserRoleHelper.returnCurrentAndLesserRoles(role);
  const rolesWithoutSuper = roles.filter((role) => role !== UserRole.ROLE_SUPER);

  const [state, setState] = useState({
    startDate: moment().subtract(1, 'days'),
    endDate: moment(),
    reportType: ReportType.PRE_ARRIVAL,
    siteIds: [selectedSiteId],
    reservationNumber: '',
    requestNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    feedbackScore: '',
    offerCode: '',
    offerType: UpsellOfferType.PACKAGE,
    requestState: null,
    additionalState: [],
    escalation: [],
    purchaseState: PurchaseState.COMPLETED,
    timezone: TimeZones.find((t) => t.offset === moment().utcOffset())?.value || TimeZones[TimeZones.length - 1].value,
    reservationFetchType: ReservationFetchType.PRE_ARRIVAL_RESERVATIONS_ONLY,
    roles: rolesWithoutSuper,
    type: UnsubscribedTypes.map((type) => type.value),
    value: '',
    status: FoodOrderStatusOptions.map((status) => status.value),
    locationType: FoodOrderLocationTypes.map((type) => type.value),
  });

  useEffect(() => {
    if (selectedCorporation) {
      setState((prevState) => ({ ...prevState, siteIds: [selectedSiteId] }));
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCorporation]);

  const handleFetchPage = useCallback(
    (newPage, pageSize) => {
      const payload = { ...state };
      dispatch(requestReport(state.reportType, newPage, pageSize, payload));
    },
    [dispatch, state]
  );

  useEffect(() => {
    const payload = { ...state };
    dispatch(requestReport(state.reportType, 0, 25, payload));
  }, [dispatch, state]);

  const exportPreArrivalReservations = useCallback(() => {
    const payload = {
      ...state,
    };
    dispatch(exportReport(state.reportType, payload));
  }, [dispatch, state]);

  const reportColumns = useMemo(() => {
    if (ReportType.PRE_ARRIVAL === state.reportType) {
      return PreArrivalColumns(state.timezone);
    } else if (ReportType.FEEDBACK_SUBMISSION === state.reportType) {
      return FeedbackSubmissionColumns(state.timezone);
    } else if (ReportType.UPSELL === state.reportType) {
      return UpsellColumns(state.timezone);
    } else if (ReportType.SPECIAL_REQUEST === state.reportType) {
      return SpecialRequestColumns(state.timezone);
    } else if (ReportType.PAYMENT === state.reportType) {
      return PaymentColumns(state.timezone);
    } else if (ReportType.ROOM_SELECTION === state.reportType) {
      return RoomSelectionColumns(state.timezone);
    } else if (ReportType.MEMBERSHIP === state.reportType) {
      return getMembershipTableColumns(state.timezone);
    } else if (ReportType.CLICK_TRACKING === state.reportType) {
      return ClickTrackingColumns(state.timezone);
    } else if (ReportType.NOTIFICATIONS === state.reportType) {
      return NotificationsColumns(state.timezone);
    } else if (ReportType.GUEST_UPDATE === state.reportType) {
      return GuestDetailColumns(state.timezone);
    } else if (ReportType.COMPANY_UPDATE === state.reportType) {
      return CompanyColumns(state.timezone);
    } else if (ReportType.USER_AUDIT === state.reportType) {
      return UserAuditColumns(state.timezone);
    } else if (ReportType.UNSUBSCRIBED_USERS === state.reportType) {
      return UnsubscribedUsersColumns();
    } else if (ReportType.GUEST_ORDER === state.reportType) {
      return GuestOrderColumns(state.timezone);
    } else {
      return [];
    }
  }, [state.reportType, state.timezone]);

  const reportFilters = useMemo(() => {
    const sites = selectedCorporation ? siteList.filter((site) => selectedCorporation.id === site.corporationId) : [];
    const context = { state, setState, sites };
    if (ReportType.PRE_ARRIVAL === state.reportType) {
      return preArrivalFilters(context);
    } else if (ReportType.FEEDBACK_SUBMISSION === state.reportType) {
      return feedbackFilters(context);
    } else if (ReportType.UPSELL === state.reportType) {
      return upsellFilers(context);
    } else if (ReportType.SPECIAL_REQUEST === state.reportType) {
      return specialRequestFilters(context);
    } else if (ReportType.PAYMENT === state.reportType) {
      return paymentFilters(context);
    } else if (ReportType.ROOM_SELECTION === state.reportType) {
      return roomSelectionFilters(context);
    } else if (ReportType.MEMBERSHIP === state.reportType) {
      return membershipReportFilters(context);
    } else if (ReportType.CLICK_TRACKING === state.reportType) {
      return clickTrackingFilters(context);
    } else if (ReportType.NOTIFICATIONS === state.reportType) {
      return reservationNotificationsFilters(context);
    } else if (ReportType.GUEST_UPDATE === state.reportType || ReportType.COMPANY_UPDATE === state.reportType) {
      return guestDetailFilters(context);
    } else if (ReportType.USER_AUDIT === state.reportType) {
      return userAuditFilters(context, rolesWithoutSuper);
    } else if (ReportType.UNSUBSCRIBED_USERS === state.reportType) {
      return unsubscribedGuestsFilters(context);
    } else if (ReportType.GUEST_ORDER === state.reportType) {
      return guestOrderFilters(context);
    } else {
      return [];
    }
  }, [rolesWithoutSuper, selectedCorporation, siteList, state]);

  const getIdColumn = useMemo(() => {
    if (ReportType.UPSELL !== state.reportType) {
      return 'reservationNumber';
    } else {
      return 'internalReference';
    }
  }, [state]);

  return (
    <AutoTable
      title={title}
      subheader={subheader}
      buttonName={'Export'}
      handleChangePage={(ev, newPage, pageSize) => handleFetchPage(newPage, pageSize)}
      page={page}
      selectedSite={null}
      selectedCorporation={selectedCorporation}
      error={error}
      processing={processing}
      columns={reportColumns}
      prefix={'HOTEL_RESERVATION_REPORT'}
      detailURL="/reservation/report/reservation/view/{id}"
      onCreateNew={() => exportPreArrivalReservations()}
      data={reservationReportList}
      filters={reportFilters}
      idColumn={getIdColumn}
    />
  );
}
