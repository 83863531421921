import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CardHeaderWithControls from '../card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import * as DataTypes from '../../constants/DataTypes';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputAdornment,
  Tooltip,
  withStyles,
  Typography,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment';
import BooleanTableColumn from './BooleanTableColumn';
import FormField from '../form/FormField';
import FormSelect from '../form/FormSelect';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getIcons } from '../../support/Icons';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FormMultiChipSelect from '../form/FormMultiChipSelect';

function acceptAllFilter() {
  return true;
}

function standardFormat(values) {
  return JSON.stringify(values);
}

function DisplayListByRow(
  column,
  row,
  index,
  editUrl = '',
  selectItemAndNavigate,
  dispatch,
  styles,
  extended,
  handleExtend,
  expanded,
  handleExpand
) {
  if (row) {
    const value = row[column.id];
    const itemKey = `at-td-${column.id}-${index}`;

    if (column.dataType === DataTypes.DATA_TYPE_BOOLEAN) {
      return <BooleanTableColumn key={itemKey} align={column.align} value={value} />;
    } else if (column.dataType === DataTypes.DATA_TYPE_STRING) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <Box
            className={extended[row.id] === column.id ? `${styles.tableCellBody} ${styles.isExpanded}` : styles.tableCellBody}
            onClick={() => handleExtend(row.id, column.id)}
            style={{}}
          >
            {column.format ? column.format(value ? value : 'N/A') : value ? value : 'N/A'}
          </Box>
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_ENUM) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <Box
            className={extended[row.id] === column.id ? `${styles.tableCellBody} ${styles.isExpanded}` : styles.tableCellBody}
            onClick={() => handleExtend(row.id, column.id)}
            style={{}}
          >
            {value === 'NOT_APPLICABLE' ? 'N/A' : value?.replaceAll('_', ' ')}
          </Box>
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_FLOAT) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <Box
            className={extended[row.id] === column.id ? `${styles.tableCellBody} ${styles.isExpanded}` : styles.tableCellBody}
            onClick={() => handleExtend(row.id, column.id)}
            style={{}}
          >
            {parseFloat(value).toFixed(2)}
          </Box>
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_LIST) {
      if (value && value.map) {
        const filterListFunction = column.filter || acceptAllFilter;
        const formatListFunction = column.format || standardFormat;
        return (
          <TableCell key={itemKey} align={column.align}>
            {formatListFunction(value.filter((e) => filterListFunction(e)))}
          </TableCell>
        );
      } else {
        return (
          <TableCell key={itemKey} align={column.align}>
            {value}
          </TableCell>
        );
      }
    } else if (column.dataType === DataTypes.DATA_TYPE_DRILL_DOWN_LINK) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <Link href={editUrl} component="button" align={column.align} onClick={() => selectItemAndNavigate(editUrl, row, 'editPage')}>
            {value}
          </Link>
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_COLOR) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <Chip style={{ backgroundColor: value, color: 'white' }} label={column.id} />
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_DATE) {
      return (
        <TableCell key={itemKey} align={column.align}>
          {value ? moment(value, 'YYYY-MM-DD').format('LL') : 'N/A'}
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_DATE_TIME) {
      return (
        <TableCell key={itemKey} align={column.align}>
          {value ? moment.unix(value).format('LLL') : 'N/A'}
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_FUNCTION) {
      return (
        <TableCell key={itemKey} align={column.align}>
          {column.render(row, dispatch)}
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_ICON) {
      return (
        <TableCell key={itemKey} align={column.align}>
          {getIcons(value)}
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_IMAGE) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <CardMedia image={value} title={column.id} className={column.className} />
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_TIME) {
      return (
        <TableCell key={itemKey} align={column.align}>
          {value ? moment.utc(value, 'HH:mm').format('hh:mm A') : 'N/A'}
        </TableCell>
      );
    } else if (column.dataType === DataTypes.DATA_TYPE_EXPAND) {
      return (
        <TableCell key={itemKey} align={column.align}>
          <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(row.id)}>
            {expanded[row.id] ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
      );
    }
  }
}

function displayCriteria(field, index, clearableFilter, columnCount) {
  const itemKey = `at-f-${index}`;
  const size = columnCount === 1 ? 12 : 6;

  // See getFilterUpdateMethods()
  const fieldValue = field.value(field.id);
  const setFieldValue = (value) => field.setValue(field.id, value);
  return (
    <Grid key={itemKey} item xs={12} sm={12} md={size} lg={size}>
      {field.dataType === DataTypes.DATA_TYPE_STRING && (
        <FormField label={field.label} placeholder={field.placeholder} value={fieldValue} setValue={setFieldValue} />
      )}
      {field.dataType === DataTypes.DATA_TYPE_ENUM && (
        <FormField label={field.label} placeholder={field.placeholder} value={fieldValue} setValue={setFieldValue} />
      )}
      {field.dataType === DataTypes.DATA_TYPE_LIST && (
        <FormSelect
          label={field.label}
          value={fieldValue}
          setValue={setFieldValue}
          options={field.options}
          hasClear={clearableFilter || field.clearableFilter}
          clearValueToNull={field.clearValueToNull}
        />
      )}
      {field.dataType === DataTypes.DATA_TYPE_LIST_MULTI_SELECT && (
        <FormMultiChipSelect label={field.label} value={fieldValue} setValue={setFieldValue} options={field.options} />
      )}
      {field.dataType === DataTypes.DATA_TYPE_DATE && (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            fullWidth
            label={field.label}
            autoOk={true}
            inputVariant={'outlined'}
            margin={'dense'}
            value={fieldValue}
            onChange={setFieldValue}
            format="YYYY-MM-DD"
            InputProps={{
              endAdornment: clearableFilter && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setFieldValue(null)}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      )}
    </Grid>
  );
}
export default function AutoTable({
  title,
  subheader = '',
  buttonName = 'Add New',
  buttonIcon,
  buttonDisabled = false,
  secondaryButtonName,
  secondaryButtonIcon,
  secondaryButtonAction,
  secondaryButtonDisabled = false,
  tertiaryButtonName,
  tertiaryButtonIcon,
  tertiaryButtonAction,
  tertiaryButtonDisabled = false,
  data = [],
  columns = [],
  filters = [],
  page = { size: 25, totalElements: 0, totalPages: 1, number: 0 },
  processing = false,
  selectedSite,
  selectedCorporation,
  error,
  handleRefreshReport, // not required called on load
  filterPayload, // filter values
  handleChangePage, // called on page change
  handleChangeRowsPerPage, // not required called on row size change
  prefix,
  createNewURL, // If provided page will navigate to given url
  onCreateNew, // If provided this method will be called on create
  detailURL,
  selectItem,
  backLink, // provide a url to override the back behaviour
  backHidden = false,
  clearableFilter = false,
  idColumn = 'id',
  subColumns = [],
  subColumnAttribute,
  hidePagination = false,
  hasToolTip = false,
}) {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [extended, setExtended] = useState({});
  const [expanded, setExpanded] = useState({});

  const handleExpand = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const handleExtend = (rowId, columnId) => {
    setExtended((prevState) => ({
      ...prevState,
      [rowId]: prevState[rowId] === columnId ? {} : columnId,
    }));
  };

  useEffect(() => {
    if (handleRefreshReport) {
      handleRefreshReport(dispatch, selectedSite, selectedCorporation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedSite, selectedCorporation, filterPayload]);

  const selectItemAndNavigate = (location, item, pageName) => {
    if (selectItem) {
      selectItem('newPage' === pageName ? null : item);
    }

    if (pageName === 'newPage') {
      history.push(location);
    } else if (pageName === 'editPage') {
      history.push(location.replaceAll('{id}', item[idColumn]));
    }
  };

  const onChangeRowSize = (ev, page) => {
    if (handleChangeRowsPerPage) {
      handleChangeRowsPerPage(ev, page);
    } else {
      handleChangePage(ev, page.number, ev.target.value);
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  return (
    <Grid container className={styles.autoTableContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeaderWithControls
            header={title}
            subheader={subheader}
            buttonName={buttonName}
            buttonIcon={buttonIcon}
            buttonLoading={processing}
            disabled={buttonDisabled}
            secondaryButtonName={secondaryButtonName}
            secondaryButtonIcon={secondaryButtonIcon}
            secondaryButtonAction={secondaryButtonAction}
            secondaryButtonDisabled={secondaryButtonDisabled}
            tertiaryButtonName={tertiaryButtonName}
            tertiaryButtonIcon={tertiaryButtonIcon}
            tertiaryButtonAction={tertiaryButtonAction}
            tertiaryButtonDisabled={tertiaryButtonDisabled}
            backLink={backLink}
            backHidden={backHidden}
            onClick={
              createNewURL
                ? () => {
                    selectItemAndNavigate(createNewURL, null, 'newPage');
                  }
                : onCreateNew
            }
          />

          {filters?.length > 0 && (
            <>
              <Divider variant={'middle'} />
              <CardContent>
                <Grid container spacing={1}>
                  {filters.map((field, index) => displayCriteria(field, index, clearableFilter, filters.length))}
                </Grid>
              </CardContent>
            </>
          )}

          <Divider variant={'middle'} />

          {/*{processing && <LinearProgress variant={'buffer'}/>}*/}

          <CardContent>
            {error && (
              <Alert severity="error" variant={'filled'}>
                {'Unable to complete action for ' + prefix + ' - ' + error.message}
              </Alert>
            )}

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={`at-th-${column.id}-${index}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth, width: column.width || 'auto' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, rowIndex) => {
                    const itemKey = `at-td-${rowIndex}`;
                    return (
                      <>
                        {hasToolTip && (
                          <HtmlTooltip title={<Typography>{row.toolTip}</Typography>} placement="top-end">
                            <TableRow hover key={itemKey}>
                              {columns.map((column, index) =>
                                DisplayListByRow(
                                  column,
                                  row,
                                  index,
                                  detailURL,
                                  selectItemAndNavigate,
                                  dispatch,
                                  styles,
                                  extended,
                                  handleExtend,
                                  expanded,
                                  handleExpand
                                )
                              )}
                            </TableRow>
                          </HtmlTooltip>
                        )}
                        {!hasToolTip && (
                          <TableRow hover key={itemKey}>
                            {columns.map((column, index) =>
                              DisplayListByRow(
                                column,
                                row,
                                index,
                                detailURL,
                                selectItemAndNavigate,
                                dispatch,
                                styles,
                                extended,
                                handleExtend,
                                expanded,
                                handleExpand
                              )
                            )}
                          </TableRow>
                        )}
                        {subColumns?.length > 0 && (
                          <TableRow>
                            <TableCell className={styles.subTable} colSpan={columns.length}>
                              <Collapse in={expanded[row.id]} timeout="auto" unmountOnExit>
                                <Table>
                                  <TableHead>
                                    <TableRow className={styles.subTableHeader}>
                                      {subColumns.map((column, index) => (
                                        <TableCell
                                          key={`sub-at-th-${column.id}-${index}`}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth, width: column.width || 'auto' }}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data[rowIndex][subColumnAttribute]?.map((row, rowIndex) => {
                                      const itemKey = `sub-at-td-${rowIndex}`;
                                      return (
                                        <TableRow hover key={itemKey}>
                                          {subColumns.map((column, index) =>
                                            DisplayListByRow(
                                              column,
                                              row,
                                              index,
                                              detailURL,
                                              selectItemAndNavigate,
                                              dispatch,
                                              styles,
                                              extended,
                                              handleExtend,
                                              expanded,
                                              handleExpand
                                            )
                                          )}
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}

                  {!processing && data.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Alert severity="info" variant={'filled'}>
                          {'Add new items to get started'}
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!hidePagination && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                component="div"
                count={page.totalElements}
                rowsPerPage={page.size}
                page={page.number}
                onPageChange={(ev, newPage) => handleChangePage(ev, newPage, page.size)}
                onRowsPerPageChange={(ev, page) => onChangeRowSize(ev, page)}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  autoTableContainer: {
    width: '100%',
  },
  tableCellBody: {
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.2em',
      maxHeight: '4.8em',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 4,
      maxWidth: '200px' /* or any other value you prefer */,
    },
  },
  isExpanded: {
    '-webkit-line-clamp': 'unset',
    overflow: 'visible',
    whiteSpace: 'normal',
    maxHeight: 'none',
  },
  subTable: {
    paddingLeft: 110,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  subTableHeader: {
    backgroundColor: theme.palette.action.main,
  },
}));
