const NotificationTemplateType = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  UPSELL: 'UPSELL',
  VENDOR: 'VENDOR',
  ACTIVITY_BOOKING: 'ACTIVITY_BOOKING',
};

const SiteNotificationTemplateType = [NotificationTemplateType.EMAIL, NotificationTemplateType.SMS];

export { NotificationTemplateType, SiteNotificationTemplateType };
