import * as Actions from '../../actions/amenity/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  selectedItem: null,
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
};

export default function siteAmenitiesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_AMENITY_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_AMENITY_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        error: null,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
      };
    case Actions.SITE_AMENITY_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };

    case Actions.SITE_AMENITY_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_AMENITY_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_AMENITY_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_AMENITY_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_AMENITY_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_AMENITY_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_AMENITY_SELECTED_ITEM:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    default:
      return state;
  }
}
