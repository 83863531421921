const NotificationType = [
  { value: 'WEB_CHECK_IN', label: 'Web Check-in' },
  { value: 'REMINDER_WEB_CHECK_IN', label: 'Web Check-in Reminder' },
  { value: 'WEB_CHECK_OUT', label: 'Web Check-out' },
  { value: 'REMINDER_WEB_CHECK_OUT', label: 'Web Check-out Reminder' },
  { value: 'MOBILE_CHECK_IN', label: 'Mobile Check-In' },
  { value: 'MOBILE_ACCESS_CHECK_IN', label: 'Mobile Access Check-In' },
];

const NotificationInfographicType = [
  { value: 'CHECK_IN', label: 'Check-in' },
  { value: 'CHECK_IN_COMPLETE', label: 'Check In Complete' },
  { value: 'CHECK_OUT', label: 'Check-out' },
  { value: 'CHECK_OUT_COMPLETE', label: 'Check Out Complete' },
  { value: 'GENERAL_GRAPHIC', label: 'General Graphic' },
];

export { NotificationType, NotificationInfographicType };
