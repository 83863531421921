import React from 'react';
import * as AuthenticationActions from '../../redux/actions/authentication/actions';
import { Button, Card, CardContent, CardHeader, FormControl, TextField } from '@material-ui/core';
import ContentContainer from '../../../components/container/ContentContainer';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckBoxOutlined } from '@material-ui/icons';

const ForgotPassword = () => {
  const [email, setEmail] = React.useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { lastActionType, error } = useSelector((state) => state.authenticationReducer);

  React.useEffect(() => {
    if (lastActionType === AuthenticationActions.REQUEST_FORGOT_PASSWORD_CODE_SUCCESS) {
      dispatch(AuthenticationActions.updateAuthenticationState('forgotPasswordCode'));
    }
  }, [dispatch, lastActionType]);

  const requestPasswordCode = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(AuthenticationActions.forgotPassword(email, false));
    },
    [email, dispatch]
  );

  const navigateBack = () => {
    dispatch(AuthenticationActions.updateAuthenticationState('signIn'));
    history.push('/login');
  };

  return (
    <ContentContainer variant={'centered'}>
      <Card>
        <CardHeader title={'Reset Password'} subheader={'Enter your registered account email address'} />
        <CardContent>
          <form onSubmit={requestPasswordCode}>
            {error && (
              <Alert severity={'error'} variant={'filled'}>
                {error.message}
              </Alert>
            )}

            <FormControl fullWidth={true}>
              <TextField
                id="email"
                margin="dense"
                variant="outlined"
                label="Email Address"
                type="email"
                autoFocus={true}
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button type={'submit'} color={'primary'} variant={'contained'} fullWidth startIcon={<CheckBoxOutlined />}>
                Request Reset
              </Button>
            </FormControl>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button fullWidth onClick={navigateBack}>
                Back
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </ContentContainer>
  );
};

export default ForgotPassword;
