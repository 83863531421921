const PackageCalculationRuleType = {
  USE_PMS_CALCULATION: 'USE_PMS_CALCULATION',
  FLAT_RATE: 'FLAT_RATE',
  PER_ADULT: 'PER_ADULT',
  PER_CHILD: 'PER_CHILD',
  PER_PERSON: 'PER_PERSON',
};

const PackageCalculationRuleTypeOptions = [
  { label: 'Use PMS Calculation Rule', value: PackageCalculationRuleType.USE_PMS_CALCULATION },
  { label: 'Flat Rate', value: PackageCalculationRuleType.FLAT_RATE },
  { label: 'Per Adult', value: PackageCalculationRuleType.PER_ADULT },
  { label: 'Per Child', value: PackageCalculationRuleType.PER_CHILD },
  { label: 'Per Person', value: PackageCalculationRuleType.PER_PERSON },
];

export { PackageCalculationRuleTypeOptions };
export default PackageCalculationRuleType;
