import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SiteDoorActions from '../../administration/redux/actions/siteDoor/actions';

function transformRooms(rooms) {
  return rooms
    .filter((s) => s.doorType === 'PRIMARY')
    .map((s) => {
      return { value: s.doorId, label: s.doorName };
    })
    .sort((s1, s2) => s1.label - s2.label);
}

export default function FormRoomSelect({
  label = 'Room Number',
  placeholder = 'Please select rooms for this reservation',
  value,
  setValue,
  disabled = false,
}) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const siteDoorList = useSelector((state) => state.siteDoorReducer.entityList).filter((d) => d.visible);

  useEffect(() => {
    if (selectedSite) {
      dispatch(SiteDoorActions.fetch(0, 5000, { siteId: selectedSite.id }));
    }
  }, [dispatch, selectedSite]);

  return (
    <Autocomplete
      fullWidth
      name={label}
      placeholder={placeholder}
      options={transformRooms(siteDoorList)}
      getOptionSelected={(option, selected) => option.value === selected.value}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => setValue(newValue)}
      value={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Primary Room"
          variant={'outlined'}
          error={!disabled && !value}
          margin={'dense'}
          helperText={siteDoorList.length <= 0 ? 'No rooms available for this site' : ''}
        />
      )}
    />
  );
}
