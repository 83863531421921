import * as Actions from '../../actions/chat/actions';

const initial = {
  lastActionType: 'NONE',
  signedInFirebase: false,
  data: [],
  selectedItem: null,
  initialSubscriptionCompleted: false,
  error: null,
};

export default function chatReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_MESSAGE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_MESSAGE_FETCH_SUCCESS:
      // const filteredThreadList = action.payload.docs.map((doc) => doc.data()).filter((doc => doc.guestUsername && !doc.dummyDocument));
      const filteredThreadList = action.payload.docs.map((doc) => doc.data()).filter((doc) => !doc.dummyDocument);
      console.log('MESSAGES: ', filteredThreadList);
      return {
        ...state,
        lastActionType: action.type,
        data: filteredThreadList || [],
        selectedItem: filteredThreadList ? filteredThreadList[0] : null,
        error: null,
      };
    case Actions.SITE_MESSAGE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        data: [],
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_MESSAGE_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_SELECT_CHAT:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.SITE_MESSAGE_CHANGE_EVENT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: state.selectedItem?.guestUsername === action.payload.guestUsername ? action.payload : state.selectedItem,
        data: state.data.map(function (doc) {
          if (doc.guestUsername !== action.payload.guestUsername) {
            return doc;
          } else {
            return action.payload;
          }
        }),
      };
    case Actions.SITE_MESSAGE_CHANGE_EVENT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
      };
    case Actions.SITE_MESSAGE_HIDE_ALERT:
      return {
        ...state,
        lastActionType: action.type,
      };
    case Actions.SITE_MESSAGE_DOCUMENT_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
      };
    case Actions.SITE_MESSAGE_DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((doc) => doc.guestUsername !== action.payload),
        selectedItem: state.selectedItem.guestUsername === action.payload ? { messages: [] } : { ...state.selectedItem },
      };
    case Actions.SITE_MESSAGE_FETCH_COLLECTION_NOT_FOUND:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_MESSAGE_COLLECTION_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_MESSAGE_COLLECTION_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_SEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
      };
    case Actions.SITE_MESSAGE_SEND_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_FIREBASE_SIGN_IN_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        signedInFirebase: true,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_FIREBASE_SIGN_IN_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        signedInFirebase: false,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_CHANGE_ADDED_EVENT_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.concat(action.payload),
        error: null,
      };
    case Actions.SITE_MESSAGE_CHANGE_ADDED_EVENT_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_MESSAGE_COMPLETE_SUBSCRIPTION:
      return {
        ...state,
        lastActionType: action.type,
        initialSubscriptionCompleted: true,
      };
    case Actions.SITE_MESSAGE_UNMOUNT_SUBSCRIPTION:
      return {
        ...state,
        lastActionType: action.type,
        initialSubscriptionCompleted: false,
      };
    default:
      return state;
  }
}
