import actionCreator from '../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';

export const ACCESS_RESERVATION_ADD_GUEST_IN_PROGRESS = 'ACCESS_RESERVATION_ADD_GUEST_IN_PROGRESS';
export const ACCESS_RESERVATION_ADD_GUEST_SUCCESS = 'ACCESS_RESERVATION_ADD_GUEST_SUCCESS';
export const ACCESS_RESERVATION_ADD_GUEST_FAILURE = 'ACCESS_RESERVATION_ADD_GUEST_FAILURE';

export const ACCESS_RESERVATION_EDIT_GUEST_IN_PROGRESS = 'ACCESS_RESERVATION_EDIT_GUEST_IN_PROGRESS';
export const ACCESS_RESERVATION_EDIT_GUEST_SUCCESS = 'ACCESS_RESERVATION_EDIT_GUEST_SUCCESS';
export const ACCESS_RESERVATION_EDIT_GUEST_FAILURE = 'ACCESS_RESERVATION_EDIT_GUEST_FAILURE';

export const ACCESS_RESERVATION_DELETE_GUEST_IN_PROGRESS = 'ACCESS_RESERVATION_DELETE_GUEST_IN_PROGRESS';
export const ACCESS_RESERVATION_DELETE_GUEST_SUCCESS = 'ACCESS_RESERVATION_DELETE_GUEST_SUCCESS';
export const ACCESS_RESERVATION_DELETE_GUEST_FAILURE = 'ACCESS_RESERVATION_DELETE_GUEST_FAILURE';

export const ACCESS_RESERVATION_PREFIX = 'ACCESS_RESERVATION';

const AccessReservationActions = {
  ...actionCreator.createActions('Access Reservation', ACCESS_RESERVATION_PREFIX, '/api/private/portal/access/reservation', {
    fetchUrl: '/api/private/portal/access/reservation', //override the fetch URL,
  }),
  findAllByTypeAndSiteId: (page, size, reportType, siteId) => async (dispatch) => {
    dispatch({ type: `${ACCESS_RESERVATION_PREFIX}_FETCH_IN_PROGRESS` });

    /**
     * Base request URL with no frills includes the page and size of the fetch to return
     */
    const requestUrl = `/api/private/portal/access/reservation?page=${page || 0}&size=${
      size || 10
    }&siteId=${siteId}&reportType=${reportType}`;

    API.get('PrivateAPI', `${requestUrl}`, {})
      .then((response) => {
        dispatch({
          type: `${ACCESS_RESERVATION_PREFIX}_FETCH_SUCCESS`,
          payload: {
            ...response,
            page: {
              number: response.number,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: `${ACCESS_RESERVATION_PREFIX}_FETCH_FAILURE`,
          payload: new Error(Objects.getErrorFromResponse(error)),
        });
      });
  },
  addGuest: (guest) => async (dispatch) => {
    dispatch({ type: ACCESS_RESERVATION_ADD_GUEST_IN_PROGRESS });

    console.log('add guest to reservation request', guest);

    let payload = {
      body: guest,
    };

    // TODO probably change BE to return the created guest object!

    API.post('PrivateAPI', '/api/private/portal/access/reservation/guest/add', payload)
      .then((response) => {
        dispatch({ type: ACCESS_RESERVATION_ADD_GUEST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ACCESS_RESERVATION_ADD_GUEST_FAILURE, payload: error });
      });
  },
  editGuest: (guest) => async (dispatch) => {
    dispatch({ type: ACCESS_RESERVATION_EDIT_GUEST_IN_PROGRESS });

    console.log('edit guest in reservation request', guest);

    let payload = {
      body: guest,
    };

    API.patch('PrivateAPI', '/api/private/portal/access/reservation/guest/edit', payload)
      .then((response) => {
        dispatch({ type: ACCESS_RESERVATION_EDIT_GUEST_SUCCESS, payload: response });
      })
      .catch((error) => {
        dispatch({ type: ACCESS_RESERVATION_EDIT_GUEST_FAILURE, payload: error });
      });
  },
  deleteGuest: (guest) => async (dispatch) => {
    dispatch({ type: ACCESS_RESERVATION_DELETE_GUEST_IN_PROGRESS });

    console.log('edit guest in reservation request', guest);

    API.del('PrivateAPI', '/api/private/portal/access/reservation/guest/' + guest.id, {})
      .then(() => {
        dispatch({ type: ACCESS_RESERVATION_DELETE_GUEST_SUCCESS, payload: guest });
      })
      .catch((error) => {
        dispatch({ type: ACCESS_RESERVATION_DELETE_GUEST_FAILURE, payload: error });
      });
  },
  findByFilter: (page, size, reportType, siteId, phone, email) => async (dispatch) => {
    dispatch({ type: `${ACCESS_RESERVATION_PREFIX}_FETCH_IN_PROGRESS` });

    /**
     * Base request URL with no frills includes the page and size of the fetch to return
     */
    const requestUrl = `/api/private/portal/access/reservation?page=${page || 0}&size=${
      size || 10
    }&siteId=${siteId}&reportType=${reportType}&phone=${phone}&email=${email}`;

    API.get('PrivateAPI', `${requestUrl}`, {})
      .then((response) => {
        dispatch({
          type: `${ACCESS_RESERVATION_PREFIX}_FETCH_SUCCESS`,
          payload: {
            ...response,
            page: {
              number: response.number,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: `${ACCESS_RESERVATION_PREFIX}_FETCH_FAILURE`,
          payload: new Error(Objects.getErrorFromResponse(error)),
        });
      });
  },
};

export default AccessReservationActions;
