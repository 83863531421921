import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/theme/terms/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Terms Name',
    minWidth: 80,
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'languageCode',
    label: 'Language',
    minWidth: 80,
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'type',
    label: 'Terms Type',
    minWidth: 80,
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'createdDate',
    label: 'Created',
    minWidth: 80,
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ThemeTermsList() {
  const dispatch = useDispatch();
  const selectedTheme = useSelector((state) => state.themeReducer.selectedItem);
  const siteThemeTermsList = useSelector((state) => state.themeTermsReducer.entityList);
  const lastActionType = useSelector((state) => state.themeTermsReducer.lastActionType);
  const page = useSelector((state) => state.themeTermsReducer.page);
  const error = useSelector((state) => state.themeTermsReducer.error);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { themeId: selectedTheme.id }));
  };

  return (
    <AutoTable
      title="Terms And Conditions List"
      subheader={'Terms and conditions are displayed to your guests during the check-in process'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 10, { themeId: selectedTheme?.id }))}
      selectItem={(item) => dispatch(Actions.select(item))}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="THEME_TERMS"
      newButtonName="Add New"
      createNewURL="/themes/terms/new"
      detailURL="/themes/view/terms/view/{id}"
      idColumn={'id'}
      data={siteThemeTermsList}
      lastActionType={lastActionType}
    />
  );
}
