const SiteFeature = {
  BOOKING: 'SCF-0001',
  CHECK_IN: 'SCF-0002',
  CHECK_OUT: 'SCF-0003',
  PRE_ARRIVAL: 'SCF-0004',
  DIGITAL_ROOM_KEY: 'SCF-0005',
  PHYSICAL_ROOM_KEY: 'SCF-0006',
  UPSELL: 'SCF-0007',
  MEMBERSHIP: 'SCF-0008',
  DEMO_MODE: 'SCF-0009',
  ALWAYS_OVERRIDE_KEYS: 'SCF-0010',
  CHAT: 'SCF-0011',
  MINIBAR: 'SCF-0012',
  FEEDBACK: 'SCF-0013',
  SPECIAL_REQUEST_NOTIFICATIONS: 'SCF-0014',
  SPECIAL_REQUEST: 'SCF-0014-P',
  DIGITAL_COMPENDIUM: 'SCF-0015',
  SHARE_RESERVATIONS: 'SCF-0016',
  ACTIVITY: 'SCF-0017',
  ROOM_SELECTION: 'SCF-0018',
  WALLET: 'SCF-0019',
  FACIAL_RECOGNITION: 'SCF-0020',
  IN_ROOM_ORDERING: 'SCF-0021',
};

export default SiteFeature;
