import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, CardHeader, Divider, IconButton, Paper, Tooltip, Typography, useTheme } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import SiteEventEditDialog from './SiteEventEditDialog';
import SystemCalendarEventActions from '../../../../../redux/actions/system/event/actions';
import { SystemCalendarEvent, SystemCalendarEventOptions, SystemCalendarType } from '../../../../../../support/SystemCalendar';
import { HighlightOff, Language, MeetingRoom, Restaurant } from '@material-ui/icons';
import Swal from 'sweetalert2';
import UserRoleHelper from '../../../../../../support/UserRoleHelper';
import UserRole from '../../../../../../support/UserRole';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm';
const TIME_FORMAT = 'hh:mm A';

export default function SiteEventCalendar({ calendarType, timezone }) {
  const dispatch = useDispatch();
  const eventRef = useRef(null);
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);
  const error = useSelector((state) => state.systemCalendarEventReducer.error);
  const events = useSelector((state) => state.systemCalendarEventReducer.events);
  const selectedSiteId = SystemCalendarType.SITE === calendarType ? selectedSite?.id : null;
  const selectedCorpId =
    SystemCalendarType.ORGANISATION === calendarType || SystemCalendarType.SITE === calendarType ? selectedCorp?.id : null;
  const theme = useTheme();

  const [dateRange, setDateRange] = useState(null);
  const [event, setEvent] = useState(null);

  const isCalendarReadOnly = useMemo(() => {
    if (calendarType === SystemCalendarType.GLOBAL) {
      return !UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER);
    }

    return !UserRoleHelper.hasRole(profile, UserRole.ROLE_CORP_ADMIN);
  }, [profile, calendarType]);

  const isEventReadOnly = (event) => {
    const corpId = event?.corpId || event?.event?.extendedProps?.corpId;

    switch (profile.role) {
      case UserRole.ROLE_SUPER:
        break;
      case UserRole.ROLE_CORP_ADMIN:
        if (corpId != null) {
          break;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return true;
    }

    return false;
  };

  const handleUpdate = async (event) => {
    eventRef.current = event;
    const payload = {
      scheduleName: event.event.title,
      scheduleFrom: moment(event.event.start).format(DATE_TIME_FORMAT),
      scheduleTo: moment(event.event.end).format(DATE_TIME_FORMAT),
      eventType: event.event.extendedProps?.eventType,
      timezone: timezone,
    };

    await dispatch(
      SystemCalendarEventActions.update(payload, `/api/private/portal/system/calendar/event/${event.event.extendedProps?.id}`, false, true)
    );
  };

  const calendarName = useMemo(() => {
    if (SystemCalendarType.ORGANISATION === calendarType) {
      return 'Organisation';
    } else if (SystemCalendarType.SITE === calendarType) {
      return 'Site';
    }

    return 'Global';
  }, [calendarType]);

  useEffect(() => {
    if (error) {
      Swal.fire(`Event Update Failed`, error.message, 'error');
      eventRef?.current?.revert();
      dispatch(SystemCalendarEventActions.clearError());
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (dateRange) {
      dispatch(SystemCalendarEventActions.fetchCalendar(selectedCorpId, selectedSiteId, dateRange, timezone));
    }
  }, [dispatch, selectedCorpId, selectedSiteId, dateRange, timezone]);

  const handleEventClick = (event) => {
    setEvent({
      corpId: event.event.extendedProps?.corpId,
      siteId: event.event.extendedProps?.siteId,
      id: event.event.extendedProps?.id,
      scheduleName: event.event.title,
      scheduleFrom: moment(event.event.start).format(DATE_TIME_FORMAT),
      scheduleTo: moment(event.event.end).format(DATE_TIME_FORMAT),
      eventType: event.event.extendedProps?.eventType,
    });
  };

  const renderTooltip = (event) => {
    return (
      <Tooltip
        title={
          <Box className={'fc-event-title'} style={{ textOverflow: 'ellipsis' }}>
            <Typography variant={'body1'} display={'block'}>
              {event.event.title}
            </Typography>
            <Typography variant={'caption'} display={'block'}>{`${moment(event.event.start).format(TIME_FORMAT)} - ${moment(
              event.event.end
            ).format(TIME_FORMAT)}`}</Typography>
            <Typography variant={'caption'} display={'block'}>
              {SystemCalendarEventOptions.find((e) => e.value === event.event.extendedProps?.eventType)?.label}
            </Typography>
          </Box>
        }
        disableFocusListener
        arrow
        placement={'left-start'}
        style={{ background: 'white', cursor: 'pointer' }}
      >
        <Box
          width={'100%'}
          className={'fc-event fc-daygrid-dot-event'}
          style={{ padding: '0', cursor: 'zoom-in' }}
          onClick={() => handleEventClick(event)}
        >
          {event.event.extendedProps?.eventType === SystemCalendarEvent.GLOBAL_OUTAGE ? (
            <Language fontSize={'inherit'} style={{ color: theme.palette.primary.main }} />
          ) : event.event.extendedProps?.eventType === SystemCalendarEvent.FNB_OUTAGE ? (
            <Restaurant fontSize={'inherit'} style={{ color: theme.palette.primary.main }} />
          ) : (
            <MeetingRoom fontSize={'inherit'} style={{ color: theme.palette.primary.main }} />
          )}
          <Box className={'fc-event-time'}>{event.timeText}</Box>
          <Box className={'fc-event-title'} style={{ textOverflow: 'ellipsis' }}>
            {event.event.title}
          </Box>
          {!isCalendarReadOnly && !isEventReadOnly(event) && (
            <Box>
              <IconButton
                color={'inherit'}
                style={{ padding: 0 }}
                onClick={(e) => {
                  dispatch(
                    SystemCalendarEventActions.delete(
                      { id: event.event.extendedProps?.id },
                      `/api/private/portal/system/calendar/event/${event.event.extendedProps?.id}`
                    )
                  );
                  e.stopPropagation();
                }}
              >
                <HighlightOff style={{ fontSize: '1rem' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Tooltip>
    );
  };

  return (
    <Paper>
      <CardHeader title={`${calendarName} Event Calendar`} subheader={'List of Outage and related Events for the Site'} />
      <Divider variant={'middle'} />
      {event && (
        <SiteEventEditDialog
          corpId={selectedCorpId}
          siteId={selectedSiteId}
          event={event}
          clearSelection={() => setEvent(null)}
          calendarType={calendarType}
          timezone={timezone}
          readOnly={isEventReadOnly(event)}
        />
      )}
      <Box p={3}>
        <FullCalendar
          events={events}
          plugins={[dayGridPlugin, interactionPlugin]}
          aspectRatio={2.5}
          eventContent={renderTooltip}
          datesSet={(args) => {
            setDateRange({
              start: moment(args.start).format(DATE_FORMAT),
              end: moment(args.end).format(DATE_FORMAT),
            });
          }}
          dayCellClassNames={!isCalendarReadOnly ? 'fc-event-day' : ''}
          displayEventEnd={true}
          dayMaxEventRows={5}
          dateClick={(date) =>
            setEvent({
              corpId: selectedCorpId,
              siteId: selectedSiteId,
              scheduleFrom: moment().set('month', date.date.getMonth()).set('date', date.date.getDate()),
              scheduleTo: moment().set('month', date.date.getMonth()).set('date', date.date.getDate()).add(1, 'hour'),
            })
          }
          editable={!isCalendarReadOnly}
          eventChange={handleUpdate}
        />
      </Box>
    </Paper>
  );
}
