import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'feedbackText',
    label: 'Feedback Text',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'feedbackScore',
    label: 'Feedback Score',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'flowName',
    label: 'Flow Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Updated Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ReservationFeedbackList() {
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/feedback-submission?siteId=${selectedReservation.siteId}&reservationNumber=${selectedReservation?.reservationNumber}`
  );

  return (
    <AutoTable
      title="Guest Feedback"
      subheader={'Guest feedback captured at the end of the check-in/out processes'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="FEEDBACK_SUBMISSION"
      backHidden
      data={data}
      lastActionType={'FEEDBACK_SUBMISSION'}
    />
  );
}
