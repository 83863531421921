import React, { useLayoutEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { ConfirmationNumberOutlined } from '@material-ui/icons';

const DATE_FORMAT = 'YYYY-MM-DD';

export default function ActivityCalendar({ activityId }) {
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  useLayoutEffect(() => {
    if (dateRange) {
      API.post('PrivateAPI', `/api/private/portal/activity/${activityId}/calendar`, {
        body: {
          from: dateRange.start,
          to: dateRange.end,
        },
      })
        .then((response) => {
          setEvents(response);
        })
        .catch((error) => {
          console.error('Failed to Fetch Calendar', error);
          Swal.fire('Failed to Fetch Calendar', 'Failed to Fetch Calendar for this activity. Please try again later.', 'error');
        });
    }
  }, [activityId, dateRange]);

  const renderTooltip = (event) => {
    return (
      <Tooltip
        disableFocusListener
        title={
          <List dense={true} disablePadding={true}>
            {event.event.extendedProps?.rates.map((rate) => {
              return (
                <ListItem dense disableGutters disablePadding>
                  <ListItemIcon>
                    <ConfirmationNumberOutlined />
                  </ListItemIcon>
                  <ListItemText primary={`${rate.name} - ${rate.capacity}`} />
                </ListItem>
              );
            })}
          </List>
        }
        arrow
        placement={'right-end'}
        style={{ background: 'white' }}
      >
        <Box width={'100%'} className={'fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-dot-event'} style={{ padding: 0 }}>
          <Box className={'fc-daygrid-event-dot'} style={{ borderColor: event.borderColor }}></Box>
          <Box className={'fc-event-time'}>{event.timeText}</Box>
          <Box className={'fc-event-title'} style={{ textOverflow: 'ellipsis' }}>
            {event.event.title}
          </Box>
        </Box>
      </Tooltip>
    );
  };

  return (
    <Paper>
      <Box p={3}>
        <FullCalendar
          events={events}
          plugins={[dayGridPlugin]}
          aspectRatio={3}
          eventContent={renderTooltip}
          datesSet={(args) => {
            setDateRange({
              start: moment(args.start).format(DATE_FORMAT),
              end: moment(args.end).format(DATE_FORMAT),
            });
          }}
          displayEventEnd={true}
          dayMaxEventRows={3}
        />
      </Box>
    </Paper>
  );
}
