import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Chip, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../../../components/form/FormBooleanSelect';
import Objects from '../../../../../../../support/Objects';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function EditInfrasysConfig() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = useMemo(
    () => (selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {}),
    [selectedItem.systemConfiguration]
  );

  const [name, setName] = useState(selectedItem?.name || '');
  const [enabled, setEnabled] = useState(selectedItem?.enabled || false);
  const [endpoint, setEndpoint] = useState(configuration?.endpoint || 'https://api.hero-cloud.com:8243/pos/8.1.0');
  const [tokenEndpoint, setTokenEndpoint] = useState(configuration?.tokenEndpoint || 'https://api.hero-cloud.com:8243/token');
  const [username, setUsername] = useState(configuration?.username || 'ffscEcwJakZFVPfRp0U9Vxl6Oowa');
  const [password, setPassword] = useState(configuration?.password || 'GRKAtRTRAduv7wXt68nmGnoDUtca');
  const [secretKey, setSecretKey] = useState(configuration?.secretKey || '65275a48-c9a4-4838-a4f9-46bb0a000ac3');
  const [shopCode, setShopCode] = useState(configuration?.shopCode || 'SHIJIAU');
  const [masterMenuCodes, setMasterMenuCodes] = useState(configuration?.masterMenuCodes || []);
  const [connectTimeout, setConnectTimeout] = useState(configuration?.connectTimeout || 5000);
  const [readTimeout, setReadTimeout] = useState(configuration?.readTimeout || 30000);

  const handleMenuCodeUpdate = (event) => {
    const value = event.target.value;

    if (!value || (masterMenuCodes && masterMenuCodes.length > 0 && masterMenuCodes.filter((code) => code === value).length > 0)) {
      return;
    }

    if (masterMenuCodes.length > 0) {
      setMasterMenuCodes([...masterMenuCodes, value]);
    } else {
      setMasterMenuCodes([value]);
    }
  };

  const handleMenuCodeDelete = (value) => {
    setMasterMenuCodes(masterMenuCodes.filter((item) => item !== value));
  };

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        endpoint: Objects.sanitizeAndTrimString(endpoint),
        username,
        password,
        tokenEndpoint: Objects.sanitizeAndTrimString(tokenEndpoint),
        secretKey,
        shopCode,
        masterMenuCodes: masterMenuCodes,
        connectTimeout,
        readTimeout,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  };

  const isFormReady = name && endpoint && username && password && tokenEndpoint && secretKey && shopCode;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Infrasys POS' : 'Create Infrasys POS'}
        subheader={'Infrasys POS system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Endpoint'} value={endpoint} setValue={setEndpoint} error={!endpoint} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Token Endpoint'} value={tokenEndpoint} setValue={setTokenEndpoint} error={!tokenEndpoint} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Username'} value={username} setValue={setUsername} error={!username} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Password'} type={'password'} value={password} setValue={setPassword} error={!password} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Secret Key'} value={secretKey} setValue={setSecretKey} error={!secretKey} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Shop Code'} value={shopCode} setValue={setShopCode} error={!shopCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              multiple
              id="outlets"
              options={[]}
              defaultValue={[]}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label={'Menu Codes'} placeholder="Menu Codes" variant={'outlined'} margin={'dense'} />
              )}
              value={masterMenuCodes}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => <Chip key={option} label={option} onDelete={() => handleMenuCodeDelete(option)} />)
              }
              onChange={handleMenuCodeUpdate}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Connect Timeout (MS)'}
              value={connectTimeout}
              setValue={setConnectTimeout}
              error={!connectTimeout}
              type={'number'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout (MS)'} value={readTimeout} setValue={setReadTimeout} error={!readTimeout} type={'number'} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
