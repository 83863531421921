import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import Objects from '../../../../../../support/Objects';

export default function EditInfor() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);
  const [endpoint, setEndpoint] = React.useState(configuration?.endpoint || 'https://ics-hmsats.infor.com/api');
  const [username, setUsername] = React.useState(configuration?.username || 'LIVERTON@SLH_TEST');
  const [password, setPassword] = React.useState(configuration?.password || 'Liverton@2021');
  const [propertyCode, setPropertyCode] = React.useState(configuration?.propertyCode || 'LTRSRT');
  const [tokenProvider, setTokenProvider] = React.useState(configuration?.tokenProvider || 'CP');
  const [exclusionTransactionCodes, setExclusionTransactionCodes] = React.useState(configuration?.exclusionTransactionCodes || '');
  const [exclusionSubfolios, setExclusionSubfolios] = React.useState(configuration?.exclusionSubfolios || '');
  const [skipTokenPosting, setSkipTokenPosting] = React.useState(configuration?.skipTokenPosting || false);
  const [timeout, setTimeout] = React.useState(configuration?.timeout || '10000');
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'NZD');

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        endpoint: Objects.sanitizeAndTrimString(endpoint),
        username,
        password,
        propertyCode,
        tokenProvider,
        exclusionTransactionCodes,
        exclusionSubfolios,
        skipTokenPosting,
        currency,
        timeout,
        debug,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    selectedItem,
    name,
    enabled,
    selectedSite.id,
    endpoint,
    username,
    password,
    propertyCode,
    tokenProvider,
    exclusionTransactionCodes,
    exclusionSubfolios,
    skipTokenPosting,
    currency,
    timeout,
    debug,
    dispatch,
  ]);

  const isFormReady = name && endpoint && username && password && propertyCode;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Infor' : 'Create Infor'}
        subheader={'Infor property management system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Endpoint'} value={endpoint} setValue={setEndpoint} error={!endpoint} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Username'} value={username} setValue={setUsername} error={!username} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Password'} type={'password'} value={password} setValue={setPassword} error={!password} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Property Code'} value={propertyCode} setValue={setPropertyCode} error={!propertyCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Token Provider'} value={tokenProvider} setValue={setTokenProvider} error={!tokenProvider} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Exclusion Transaction Codes'} value={exclusionTransactionCodes} setValue={setExclusionTransactionCodes} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Exclusion Subfolios'} value={exclusionSubfolios} setValue={setExclusionSubfolios} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Skip Token Posting'} value={skipTokenPosting} setValue={setSkipTokenPosting} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug Logging'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Callback Timeout (MS)'} value={timeout} setValue={setTimeout} error={!timeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
