import React from 'react';
import ForgotPassword from './public/ForgotPassword';
import Login from './public/Login';
import { useSelector } from 'react-redux';
import NewPasswordRequired from './public/NewPasswordRequired';
import VerificationPasswordCode from './public/VerificationPasswordCode';

export default function LoginRoutes() {
  const authState = useSelector((state) => state.authenticationReducer.authState);

  switch (authState) {
    case 'loading':
      return <div>Loading...</div>;
    case 'newPasswordRequired':
      return <NewPasswordRequired />;
    case 'forgotPassword':
      return <ForgotPassword />;
    case 'forgotPasswordCode':
      return <VerificationPasswordCode />;
    case 'signIn':
    default:
      return <Login />;
  }
}
