import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import UnsubscribedActions from '../../../redux/actions/unsubscribed/action';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { getFilterField, getFilterUpdateMethods, getMultiSelectFilterField } from '../../../../components/table/filter/filterUtils';
import UnsubscribedTypes from '../../../../support/UnsubscribedTypes';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: '10%',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(UnsubscribedActions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    width: '10%',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'value',
    label: 'Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'siteId',
    label: 'Site ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function UnsubscribedList({ notificationSchemeId }) {
  const dispatch = useDispatch();
  const unsubscribeList = useSelector((state) => state.unsubscribedReducer.entityList);
  const processing = useSelector((state) => state.unsubscribedReducer.processing);
  const page = useSelector((state) => state.unsubscribedReducer.page);
  const error = useSelector((state) => state.unsubscribedReducer.error);

  const [state, setState] = useState({
    value: '',
    type: UnsubscribedTypes.map((type) => type.value),
  });

  const filters = getFilterFieldsForScreen(state, setState);

  useEffect(() => {
    dispatch(UnsubscribedActions.fetch(0, 25, { notificationSchemeId: notificationSchemeId, value: state.value, type: state.type }));
  }, [dispatch, notificationSchemeId, state]);

  return (
    <AutoTable
      title="Manage Unsubscribed Guests"
      subheader={'Review, search for, or remove guests who have opted out of notifications'}
      handleRefreshReport={() => dispatch(UnsubscribedActions.fetch(0, 25, { notificationSchemeId: notificationSchemeId }))}
      selectItem={(item) => dispatch(UnsubscribedActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) =>
        dispatch(
          UnsubscribedActions.fetch(newPage, pageSize, { notificationSchemeId: notificationSchemeId, value: state.value, type: state.type })
        )
      }
      page={page}
      error={error}
      columns={columns}
      prefix="UNSUBSCRIBED"
      filters={filters}
      idColumn={'id'}
      data={unsubscribeList}
    />
  );
}

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getMultiSelectFilterField('type', 'Type', UnsubscribedTypes, filterUpdateMethods),
    getFilterField('value', 'Value', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
}
