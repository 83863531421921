import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppTopbar from './SideMenu';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AppRoutes from './AppRoutes';
import * as AccessActions from '../redux/actions/access/actions';
import * as AuthenticationActions from '../redux/actions/authentication/actions';
import Error from '../../support/Error';
import { ThemeProvider } from '@material-ui/core/styles';
import { getTheme } from '../../theme/MaterialTheme';
import LoginRoutes from './LoginRoutes';
import PublicNavigation from './public/structure/PublicNavigation';
import ContentContainer from '../../components/container/ContentContainer';
import * as FirestoreService from '../../components/firebase/firebase';
import * as ChatActions from '../redux/actions/chat/actions';
import SiteActions from '../redux/actions/site/actions';
import MainBackground from '../../assets/img/main-background-2560.jpg';
import MainBackgroundWebp from '../../assets/img/main-background-2560.webp';
import MainBackground1920 from '../../assets/img/main-background-1920.jpg';
import MainBackground1920Webp from '../../assets/img/main-background-1920.webp';
import MainBackground1024 from '../../assets/img/main-background-1024.jpg';
import MainBackground1024Webp from '../../assets/img/main-background-1024.webp';
import Objects from '../../support/Objects';
import AppDynamicLink from './public/AppDynamicLink';
import PrivacyPolicy from './public/policy/PrivacyPolicy';
import TermsOfUse from './public/policy/TermsOfUse';
import VerificationPasswordCode from './public/VerificationPasswordCode';

function MainBackgroundImage() {
  return (
    <picture className="main-background">
      <source media="(max-width: 1024px)" type="image/webp" srcSet={MainBackground1024Webp} />
      <source media="(max-width: 1024px)" srcSet={MainBackground1024} />
      <source media="(max-width: 1920px)" type="image/webp" srcSet={MainBackground1920Webp} />
      <source media="(max-width: 1920px)" srcSet={MainBackground1920} />
      <source media="(min-width: 1921px)" type="image/webp" srcSet={MainBackgroundWebp} />
      <img src={MainBackground} alt={'not found'} />
    </picture>
  );
}

/**
 * Holds a list of Firebase subscriptions - these are cleared on unmount
 */
let FIREBASE_SUBSCRIPTIONS = [];

export default function AppApplication() {
  const { isLoginCheckComplete, lastActionType, authState } = useSelector((state) => state.authenticationReducer);
  const { profileCheckCompleted, error, theme, selectedSite } = useSelector((state) => state.accessReducer);
  const lastChatActionType = useSelector((state) => state.chatReducer.lastActionType);
  const signedInFirebase = useSelector((state) => state.chatReducer.signedInFirebase);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedSiteId = selectedSite?.id;
  const selectedChatCollection = selectedSite?.chatCollectionId;

  const [notificationBarOpen, setNotificationBarOpen] = React.useState(false);

  useEffect(() => {
    dispatch(AuthenticationActions.checkLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === AuthenticationActions.LOGIN_IN_SUCCESS || AuthenticationActions.CHECK_LOGIN_STATUS_SUCCESS === lastActionType) {
      dispatch(AccessActions.fetchCustomerProfile());
    }
  }, [history, lastActionType, dispatch]);

  useEffect(() => {
    dispatch(ChatActions.signInAnonymously());
  }, [dispatch]);

  //Checks and creates a chat collection for a site
  useEffect(() => {
    if (selectedSiteId) {
      if (selectedChatCollection) {
        dispatch(ChatActions.fetch(selectedChatCollection));
      } else {
        const collectionId = Objects.getUUid();

        console.log('SITE COLLECTION - Checking for site: ', selectedSiteId);
        FirestoreService.createSiteCollection(collectionId)
          .then((response) => {
            console.log('SITE COLLECTION - Created with response: ', response);
            dispatch(ChatActions.collectionCreated(collectionId));
            dispatch(SiteActions.updateChatCollectionId(selectedSiteId, collectionId));
          })
          .catch((error) => {
            dispatch(ChatActions.collectionCreateFailed(error));
          });
      }
    }
  }, [dispatch, selectedSiteId, selectedChatCollection]);

  useEffect(() => {
    dispatch(ChatActions.unmountSubscription());
  }, [selectedSite, dispatch]);

  //Subscribes to firebase changes and dispatches message notifications
  useEffect(() => {
    if (selectedChatCollection) {
      const existing = FIREBASE_SUBSCRIPTIONS.find((subscription) => subscription.collectionId === selectedChatCollection);
      if (existing) {
        console.log('FIREBASE subscription already exists for collection: ', selectedChatCollection);
      } else {
        dispatch(ChatActions.completeInitialSubscription());

        const unsubscribe = FirestoreService.streamMessageChanges(selectedChatCollection, {
          next: (querySnapshot) => {
            querySnapshot.docChanges().forEach(function (change) {
              if (change.type === 'added') {
                dispatch(ChatActions.updateStoredChatsOnEvent(selectedChatCollection, change.doc.id));
              }
              if (change.type === 'modified') {
                console.debug('New document: ', change.doc.data());
                dispatch(ChatActions.updateStoredMessagesOnEvent(change.doc.data()));
              }
              if (change.type === 'removed') {
                console.debug('Removed document: ', change.doc.data());
                dispatch(ChatActions.deleteDocument(change.doc.data().recipient));
              }
            });
          },
          error: () => dispatch(ChatActions.updateStoredMessagesOnEvent(null, 'Error fetching messages')),
        });
        console.log('FIREBASE subscription created for collection: ', selectedChatCollection);
        FIREBASE_SUBSCRIPTIONS.push({ collectionId: selectedChatCollection, unsubscribe });
      }
    }

    return () => {
      FIREBASE_SUBSCRIPTIONS.forEach((subscription) => subscription.unsubscribe());
      FIREBASE_SUBSCRIPTIONS = [];
    };
  }, [signedInFirebase, selectedChatCollection, dispatch]);

  useEffect(() => {
    let timer = null;
    if (ChatActions.SITE_MESSAGE_CHANGE_EVENT_SUCCESS === lastChatActionType) {
      timer = setTimeout(() => setNotificationBarOpen(true), 2000);
      dispatch(ChatActions.hideAlert());
    } else if (
      ChatActions.SITE_MESSAGE_FETCH_SUCCESS === lastChatActionType ||
      ChatActions.SITE_MESSAGE_CREATE_SUCCESS === lastChatActionType ||
      ChatActions.SITE_MESSAGE_HIDE_ALERT === lastChatActionType
    ) {
      setNotificationBarOpen(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [lastChatActionType, dispatch]);

  const getSelectedTheme = React.useCallback(() => {
    return theme === 'light' ? getTheme(false) : getTheme(true);
  }, [theme]);

  const selectedTheme = getSelectedTheme();
  if (isLoginCheckComplete) {
    if (error) {
      return <Error error={error} errorHandler={() => window.location.reload()} />;
    }
    switch (authState) {
      case 'signedIn':
        return (
          <ThemeProvider theme={selectedTheme}>
            <MainBackgroundImage />

            {!profileCheckCompleted && (
              <ContentContainer variant={'centered'}>
                <div>Getting your profile please wait...</div>
              </ContentContainer>
            )}

            {profileCheckCompleted && (
              <div className="sidebar-and-content">
                <AppTopbar />
                <AppRoutes />
                <Snackbar open={notificationBarOpen} autoHideDuration={10000} onClose={() => setNotificationBarOpen(false)}>
                  <MuiAlert variant="filled" onClose={() => setNotificationBarOpen(false)} severity="info" elevation={5}>
                    You have a new message!
                  </MuiAlert>
                </Snackbar>
              </div>
            )}
          </ThemeProvider>
        );
      default:
        return (
          <ThemeProvider theme={selectedTheme}>
            <PublicNavigation />
            <MainBackgroundImage />
            <Switch>
              <Redirect from="/" to="/login" exact />
              <Route path="/desktop/:id" component={AppDynamicLink} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/terms" component={TermsOfUse} />
              <Route path="/code" component={VerificationPasswordCode} />
              <Route component={LoginRoutes} />
            </Switch>
          </ThemeProvider>
        );
    }
  } else {
    return (
      <ThemeProvider theme={selectedTheme}>
        <ContentContainer variant={'centered'}>
          <div>Loading</div>
        </ContentContainer>
      </ThemeProvider>
    );
  }
}
