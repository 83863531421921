import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import moment from 'moment/moment';

export const DASHBOARD_WIDGET_FETCH_IN_PROGRESS = 'DASHBOARD_WIDGET_FETCH_IN_PROGRESS';
export const DASHBOARD_WIDGET_GET_SUCCESS = 'DASHBOARD_WIDGET_GET_SUCCESS';
export const DASHBOARD_WIDGET_GET_FAILURE = 'DASHBOARD_WIDGET_GET_FAILURE';
export const IRD_DASHBOARD_WIDGET_FETCH_IN_PROGRESS = 'IRD_DASHBOARD_WIDGET_FETCH_IN_PROGRESS';
export const IRD_DASHBOARD_WIDGET_GET_SUCCESS = 'IRD_DASHBOARD_WIDGET_GET_SUCCESS';
export const IRD_DASHBOARD_WIDGET_GET_FAILURE = 'IRD_DASHBOARD_WIDGET_GET_FAILURE';
export const GUEST_DASHBOARD_WIDGET_FETCH_IN_PROGRESS = 'GUEST_DASHBOARD_WIDGET_FETCH_IN_PROGRESS';
export const GUEST_DASHBOARD_WIDGET_GET_SUCCESS = 'GUEST_DASHBOARD_WIDGET_GET_SUCCESS';
export const GUEST_DASHBOARD_WIDGET_GET_FAILURE = 'GUEST_DASHBOARD_WIDGET_GET_FAILURE';

export const DASHBOARD_CLEAR_ERROR = 'DASHBOARD_CLEAR_ERROR';
export const IRD_DASHBOARD_CLEAR_ERROR = 'IRD_DASHBOARD_CLEAR_ERROR';

export const getWidgetData = (siteId, timeframe) => async (dispatch) => {
  dispatch({ type: DASHBOARD_WIDGET_FETCH_IN_PROGRESS });

  const payload = {
    ...timeframe,
    start: moment(timeframe.start).format('YYYY-MM-DD'),
    end: moment(timeframe.end).format('YYYY-MM-DD'),
  };

  try {
    const response = await API.post('PrivateAPI', `/api/private/portal/dashboard/widgets?siteId=${siteId}`, { body: payload });
    dispatch({
      type: DASHBOARD_WIDGET_GET_SUCCESS,
      payload: {
        totalRevenue: response.totalRevenue,
        numberOfPurchases: response.numberOfPurchases,
        currentActiveMobileKeys: response.currentActiveMobileKeys,
        issuedKeys: response.issuedKeys,
        checkIns: response.checkIns,
        checkOuts: response.checkOuts,
        upsellRevenue: response.upsellRevenue,
        directBookingsRevenue: response.directBookingsRevenue,
        checkInReservationsNotified: response.checkInReservationsNotified,
        checkOutReservationsNotified: response.checkOutReservationsNotified,
        onlineCheckInCompleted: response.onlineCheckInCompleted,
        onlineCheckOutCompleted: response.onlineCheckOutCompleted,
        popularLinkType: response.popularLinkType,
        optedOut: response.optedOut,
      },
    });
  } catch (error) {
    dispatch({ type: DASHBOARD_WIDGET_GET_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const getIrdWidgetData = (siteId, timeframe) => async (dispatch) => {
  dispatch({ type: IRD_DASHBOARD_WIDGET_FETCH_IN_PROGRESS });

  const payload = {
    ...timeframe,
    start: moment(timeframe.start).format('YYYY-MM-DD'),
    end: moment(timeframe.end).format('YYYY-MM-DD'),
  };

  try {
    const response = await API.post('PrivateAPI', `/api/private/portal/dashboard/ird/widgets?siteId=${siteId}`, { body: payload });
    dispatch({
      type: IRD_DASHBOARD_WIDGET_GET_SUCCESS,
      payload: {
        totalRevenue: response.totalRevenue,
        totalCovers: response.totalCovers,
        averageCheckPerCover: response.averageCheckPerCover,
      },
    });
  } catch (error) {
    dispatch({ type: IRD_DASHBOARD_WIDGET_GET_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const getGuestWidgetData = (siteId, timeframe) => async (dispatch) => {
  dispatch({ type: GUEST_DASHBOARD_WIDGET_FETCH_IN_PROGRESS });

  const payload = {
    ...timeframe,
    start: moment(timeframe.start).format('YYYY-MM-DD'),
    end: moment(timeframe.end).format('YYYY-MM-DD'),
  };

  try {
    const response = await API.post('PrivateAPI', `/api/private/portal/dashboard/guest/widgets?siteId=${siteId}`, { body: payload });
    dispatch({
      type: GUEST_DASHBOARD_WIDGET_GET_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: GUEST_DASHBOARD_WIDGET_GET_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: DASHBOARD_CLEAR_ERROR });
};
