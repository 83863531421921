import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodInfoActions from '../../../../redux/actions/inRoom/ordering/foodInfo/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  media: {
    height: 0,
    backgroundSize: '50%',
    maxWidth: '50px',
    maxHeight: '38px',
    paddingTop: '10%',
  },
}));

const columns = (classes) => [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodInfoActions.delete(value, `/api/private/portal/food/info/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'image',
    label: 'Image',
    className: classes.media,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_IMAGE,
  },
];

export default function FoodInfoList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const foodInfoList = useSelector((state) => state.foodInfoReducer.entityList);
  const processing = useSelector((state) => state.foodInfoReducer.processing);
  const page = useSelector((state) => state.foodInfoReducer.page);
  const error = useSelector((state) => state.foodInfoReducer.error);

  return (
    <AutoTable
      title="Food Info List"
      subheader={'A Food Info is contains short descriptions such as category or allergens with image'}
      handleRefreshReport={() => dispatch(FoodInfoActions.fetch(0, 20, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodInfoActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodInfoActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns(classes)}
      prefix="FOOD_INFO"
      createNewURL="/food/info/new"
      detailURL="/food/info/view/{id}"
      idColumn={'id'}
      data={foodInfoList}
    />
  );
}
