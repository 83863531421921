import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

export const SITE_SYSTEM_FETCH_SUCCESS = 'SITE_SYSTEM_FETCH_SUCCESS';
export const SITE_SYSTEM_FETCH_FAILURE = 'SITE_SYSTEM_FETCH_FAILURE';

export const SITE_SYSTEM_CREATE_SUCCESS = 'SITE_SYSTEM_CREATE_SUCCESS';
export const SITE_SYSTEM_CREATE_FAILURE = 'SITE_SYSTEM_CREATE_FAILURE';

export const SITE_SYSTEM_UPDATE_SUCCESS = 'SITE_SYSTEM_UPDATE_SUCCESS';
export const SITE_SYSTEM_UPDATE_FAILURE = 'SITE_SYSTEM_UPDATE_FAILURE';

export const SITE_SYSTEM_DELETE_SUCCESS = 'SITE_SYSTEM_DELETE_SUCCESS';
export const SITE_SYSTEM_DELETE_FAILURE = 'SITE_SYSTEM_DELETE_FAILURE';

export const SITE_SYSTEM_FETCH_AVAILABLE_SUCCESS = 'SITE_SYSTEM_FETCH_AVAILABLE_SUCCESS';
export const SITE_SYSTEM_FETCH_AVAILABLE_FAILURE = 'SITE_SYSTEM_FETCH_AVAILABLE_FAILURE';

export const SITE_SYSTEM_FIND_POS_MENUS_SUCCESS = 'SITE_SYSTEM_FIND_POS_MENUS_SUCCESS';
export const SITE_SYSTEM_FIND_POS_MENUS_FAILURE = 'SITE_SYSTEM_FIND_POS_MENUS_FAILURE';

export const SITE_SYSTEM_CLEAR_ERROR = 'SITE_SYSTEM_CLEAR_ERROR';

export const SITE_SYSTEM_SELECT = 'SITE_SYSTEM_SELECT';

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_SYSTEM_CLEAR_ERROR });
};

export const getSystemListForSite = (siteId) => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/site/systems?siteId=${siteId}`, {});
    dispatch({ type: SITE_SYSTEM_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: SITE_SYSTEM_FETCH_FAILURE,
      payload: Objects.getErrorFromResponse(error),
    });
  }
};

export const getAvailableSystemList = () => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', '/api/private/portal/site/systems/available', {});
    dispatch({ type: SITE_SYSTEM_FETCH_AVAILABLE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: SITE_SYSTEM_FETCH_AVAILABLE_FAILURE,
      payload: Objects.getErrorFromResponse(error),
    });
  }
};

/**
 * Create a new system configuration
 * @param system {id, name, systemCategory, systemType, systemConfiguration, enabled, siteId } Updated system
 */
export const createSiteSystem = (system) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/systems', { body: system });
    dispatch({ type: SITE_SYSTEM_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: SITE_SYSTEM_CREATE_FAILURE,
      payload: Objects.getErrorFromResponse(error),
    });
  }
};

/**
 * Update an existing system configuration
 * @param system {id, name, systemCategory, systemType, systemConfiguration, enabled, siteId } Updated system
 */
export const updateSiteSystem = (system) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/site/systems', { body: system });
    dispatch({ type: SITE_SYSTEM_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: SITE_SYSTEM_UPDATE_FAILURE,
      payload: Objects.getErrorFromResponse(error),
    });
  }
};

export const deleteSiteSystem = (system) => async (dispatch) => {
  const choice = await Swal.fire({
    title: `Delete ${system.name}`,
    text: 'Warning: This operation is not reversible',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  });

  if (choice.value) {
    try {
      await API.del('PrivateAPI', `/api/private/portal/site/systems/${system.id}`, {});
      dispatch({ type: SITE_SYSTEM_DELETE_SUCCESS, payload: system });
    } catch (error) {
      dispatch({
        type: SITE_SYSTEM_DELETE_FAILURE,
        payload: Objects.getErrorFromResponse(error),
      });
    }
  }
};

export const selectItem = (item, history) => async (dispatch) => {
  await dispatch({ type: SITE_SYSTEM_SELECT, payload: item });
  history.push('/site/systems/view');
};

export const fetchPosSystemMenus = (siteId) => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/site/systems/pos/food/menu/list?siteId=${siteId}`, {});
    dispatch({ type: SITE_SYSTEM_FIND_POS_MENUS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: SITE_SYSTEM_FIND_POS_MENUS_FAILURE,
      payload: Objects.getErrorFromResponse(error),
    });
  }
};
