import { Button } from '@material-ui/core';
import React from 'react';

export default function CompendiumContentSwitcher({ visual, setVisual }) {
  return (
    <Button color={'primary'} variant={'outlined'} onClick={() => setVisual(!visual)}>
      {visual ? 'Switch to Basic Editor' : 'Switch to Visual Editor'}
    </Button>
  );
}
