import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Menu', 'FOOD_MENU');

function foodMenuReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default foodMenuReducer;
