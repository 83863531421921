import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../redux/actions/theme/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import toBase64 from '../../../../support/toBase64Converter';
import FileImageSelector from '../../../../components/file/FileImageSelector';
import Objects from '../../../../support/Objects';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import FormField from '../../../../components/form/FormField';
import FormSelectLanguage from '../../../../components/form/FormSelectLanguage';
import FormFontSelect from '../../../../components/form/FormFontSelect';

const ThemeImageType = {
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
  BACKGROUND_LOGO: 'BACKGROUND_LOGO',
  MOBILE_LOGO: 'MOBILE_LOGO',
  CUSTOM_LOGO: 'CUSTOM_IMAGE',
  REGISTRATION_CARD: 'REGISTRATION_CARD',
  THEME_ICON: 'THEME_ICON',
  MEMBERSHIP_LOGO: 'MEMBERSHIP_LOGO',
  BANNER_IMAGE: 'BANNER_IMAGE',
};

export default function ThemeEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.themeReducer.error);
  const lastActionType = useSelector((state) => state.themeReducer.lastActionType);
  const processing = useSelector((state) => state.themeReducer.processing);
  const selectedItem = useSelector((state) => state.themeReducer.selectedItem);
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);

  const [name, setName] = useState(selectedItem?.name || '');
  const [primary, setPrimary] = useState(selectedItem?.primary || '#38a5fa');
  const [accent, setAccent] = useState(selectedItem?.accent || '#fdaf3a');
  const [fontColour, setFontColour] = useState(selectedItem?.fontColour || '#000');
  const [fontColourDark, setFontColourDark] = useState(selectedItem?.fontColourDark || '#ffffff');
  const [titleFontFamily, setTitleFontFamily] = useState(selectedItem?.titleFontFamily || 'Open Sans');
  const [subtitleFontFamily, setSubtitleFontFamily] = useState(selectedItem?.subtitleFontFamily || 'Open Sans');
  const [baseFontFamily, setBaseFontFamily] = useState(selectedItem?.baseFontFamily || 'Open Sans');
  const [accentFontFamily, setAccentFontFamily] = useState(selectedItem?.accentFontFamily || 'Open Sans');
  const [backgroundColour, setBackgroundColour] = useState(selectedItem?.backgroundColour || '#ffffff');
  const [walletBackgroundColour, setWalletBackgroundColour] = useState(selectedItem?.walletBackgroundColour || '#ffffff');
  const [surfaceColour, setSurfaceColour] = useState(selectedItem?.surfaceColour || '#d7d7d7');
  const [imageList, setImageList] = useState(selectedItem?.imageList || []);
  const [languageList, setLanguageList] = useState(selectedItem?.languageList || []);
  const [customStyles, setCustomStyles] = useState(selectedItem?.customStyles || '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'THEME_DELETE_SUCCESS' || lastActionType === 'THEME_ADD_SUCCESS' || lastActionType === 'THEME_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = useCallback(() => {
    const payload = {
      ...selectedItem,
      name,
      primary,
      accent,
      fontColour,
      fontColourDark,
      titleFontFamily,
      subtitleFontFamily,
      baseFontFamily,
      accentFontFamily,
      backgroundColour,
      walletBackgroundColour,
      surfaceColour,
      languageList,
      customStyles,
      imageList,
      corporationId: selectedItem?.corporationId || selectedCorp.id,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  }, [
    accent,
    accentFontFamily,
    backgroundColour,
    walletBackgroundColour,
    baseFontFamily,
    dispatch,
    fontColour,
    fontColourDark,
    imageList,
    languageList,
    customStyles,
    name,
    primary,
    selectedCorp?.id,
    selectedItem,
    subtitleFontFamily,
    surfaceColour,
    titleFontFamily,
  ]);

  const isFormDisabled = Objects.areValuesNullOrEmpty([
    name,
    primary,
    accent,
    fontColour,
    fontColourDark,
    titleFontFamily,
    subtitleFontFamily,
    baseFontFamily,
    accentFontFamily,
    backgroundColour,
    surfaceColour,
  ]);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Theme' : 'Create Theme'}
        subheader={'Theme elements including images and colours can be customised for each site'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        buttonLoading={processing}
        disabled={isFormDisabled || processing}
        onClick={handleSubmit}
        deleteDisabled={!selectedItem}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Error with updating theme - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField label={'Theme Name'} value={name} setValue={setName} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Primary Colour'}
              type={'color'}
              value={primary}
              setValue={setPrimary}
              error={!primary}
              helperText={primary ? 'This is your primary theme colour' : 'Please select a primary colour'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Accent Colour'}
              type={'color'}
              value={accent}
              setValue={setAccent}
              error={!accent}
              helperText={primary ? 'This is your accent theme colour' : 'Please select a accent colour'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Font Colour'} type={'color'} value={fontColour} setValue={setFontColour} error={!fontColour} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Font Colour (Dark)'}
              type={'color'}
              value={fontColourDark}
              setValue={setFontColourDark}
              error={!fontColourDark}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormFontSelect label={'Title Font Family'} value={titleFontFamily} setValue={setTitleFontFamily} error={!titleFontFamily} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormFontSelect
              label={'Subtitle Font Family'}
              value={subtitleFontFamily}
              setValue={setSubtitleFontFamily}
              error={!subtitleFontFamily}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormFontSelect label={'Base Font Family'} value={baseFontFamily} setValue={setBaseFontFamily} error={!baseFontFamily} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormFontSelect
              label={'Accent Font Family'}
              value={accentFontFamily}
              setValue={setAccentFontFamily}
              error={!accentFontFamily}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Background Colour'}
              type={'color'}
              value={backgroundColour}
              setValue={setBackgroundColour}
              error={!backgroundColour}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Surface Colour'} type={'color'} value={surfaceColour} setValue={setSurfaceColour} error={!surfaceColour} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Wallet Background Colour'}
              type={'color'}
              value={walletBackgroundColour}
              setValue={setWalletBackgroundColour}
              error={!walletBackgroundColour}
            />
          </Grid>

          {selectedItem && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormSelectLanguage
                  label={'Site Languages'}
                  value={languageList}
                  setValue={setLanguageList}
                  placeholder={'Languages available to customers'}
                />
              </Grid>
            </>
          )}

          <ThemeImageSelector
            label={'Theme Image (Hotel Background Image)'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.BACKGROUND_IMAGE}
          />

          <ThemeImageSelector
            label={'Theme Image (Hotel Banner Image)'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.BANNER_IMAGE}
          />

          <ThemeImageSelector
            label={'Theme Logo'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.BACKGROUND_LOGO}
          />

          <ThemeImageSelector
            label={'Mobile Logo (Hotel Logo displayed in App)'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.MOBILE_LOGO}
          />

          <ThemeImageSelector
            label={'Last Page Image (Web & App)'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.CUSTOM_LOGO}
          />

          <ThemeImageSelector
            label={'PDF Image (Registration Card & Email Receipt)'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.REGISTRATION_CARD}
          />

          <ThemeImageSelector
            label={'Theme Icon'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.THEME_ICON}
          />

          <ThemeImageSelector
            label={'Membership Logo'}
            imageList={imageList}
            setImageList={setImageList}
            imageType={ThemeImageType.MEMBERSHIP_LOGO}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormField label="Custom CSS Styles" value={customStyles} setValue={setCustomStyles} multiline rows={7} focused />
        </Grid>
      </CardContent>
    </Card>
  );
}

async function constructImageDto(file, imageType) {
  if (file) {
    const base64 = await toBase64(file);
    return {
      imageType,
      imageName: file.name,
      imageBase64: base64,
      imageUrl: null,
    };
  } else {
    return null;
  }
}

function ThemeImageSelector({ label, imageList, setImageList, imageType }) {
  return (
    <FileImageSelector
      sourceItem={imageList.find((i) => i.imageType === imageType)}
      sourceItemProperty={'imageUrl'}
      displayName={label}
      onSelect={(file) => {
        constructImageDto(file, imageType).then((parsed) => {
          const existing = imageList.find((i) => i.imageType === imageType);
          if (existing) {
            setImageList(imageList.map((i) => (i.imageType === imageType ? parsed : i)).filter((i) => i !== null));
          } else {
            if (parsed) {
              setImageList([...imageList, parsed]);
            }
          }
        });
      }}
    />
  );
}
