const ReservationCardStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
};

const ReservationCardStatusOptions = [
  { label: 'Pending', value: ReservationCardStatus.PENDING },
  { label: 'Active', value: ReservationCardStatus.ACTIVE },
  { label: 'Archieved', value: ReservationCardStatus.ARCHIVED },
  { label: 'Completed', value: ReservationCardStatus.COMPLETED },
];

export default ReservationCardStatus;
export { ReservationCardStatusOptions };
