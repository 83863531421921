import API from '@aws-amplify/api';

export const SITE_ROOM_TYPE_CLEAR_ERROR = 'SITE_ROOM_TYPE_CLEAR_ERROR';
export const SITE_ROOM_TYPE_SELECTED_ITEM = 'SITE_ROOM_TYPE_SELECTED_ITEM';
export const SITE_ROOM_TYPE_PROCESSING = 'SITE_ROOM_TYPE_PROCESSING';

export const SITE_ROOM_TYPE_FETCH_SUCCESS = 'SITE_ROOM_TYPE_FETCH_SUCCESS';
export const SITE_ROOM_TYPE_FETCH_FAILURE = 'SITE_ROOM_TYPE_FETCH_FAILURE';

export const SITE_ROOM_TYPE_CREATE_SUCCESS = 'SITE_ROOM_TYPE_CREATE_SUCCESS';
export const SITE_ROOM_TYPE_CREATE_FAILURE = 'SITE_ROOM_TYPE_CREATE_FAILURE';

export const SITE_ROOM_TYPE_UPDATE_SUCCESS = 'SITE_ROOM_TYPE_UPDATE_SUCCESS';
export const SITE_ROOM_TYPE_UPDATE_FAILURE = 'SITE_ROOM_TYPE_UPDATE_FAILURE';

export const SITE_ROOM_TYPE_DELETE_SUCCESS = 'SITE_ROOM_TYPE_DELETE_SUCCESS';
export const SITE_ROOM_TYPE_DELETE_FAILURE = 'SITE_ROOM_TYPE_DELETE_FAILURE';

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: SITE_ROOM_TYPE_SELECTED_ITEM, payload: item });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_ROOM_TYPE_CLEAR_ERROR, payload: {} });
};

export const fetch = (page, size, parameters) => async (dispatch) => {
  let requestUrl = `/api/private/portal/site/roomTypes?page=${page || 0}&size=${size || 10}`;

  if (parameters) {
    Object.keys(parameters).forEach((key) => {
      requestUrl += `&${key}=${parameters[key]}`;
    });
  }

  dispatch({ type: SITE_ROOM_TYPE_PROCESSING });

  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({ type: SITE_ROOM_TYPE_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_ROOM_TYPE_FETCH_FAILURE, payload: error });
  }
};

export const createRoomType = (request) => async (dispatch) => {
  dispatch({ type: SITE_ROOM_TYPE_PROCESSING });
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/site/roomTypes', { body: request });
    dispatch({ type: SITE_ROOM_TYPE_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_ROOM_TYPE_CREATE_FAILURE, payload: error });
  }
};

export const updateRoomType = (request) => async (dispatch) => {
  dispatch({ type: SITE_ROOM_TYPE_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/site/roomTypes', { body: request });
    dispatch({ type: SITE_ROOM_TYPE_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_ROOM_TYPE_UPDATE_FAILURE, payload: error });
  }
};

export const deleteRoomType = (roomType) => async (dispatch) => {
  dispatch({ type: SITE_ROOM_TYPE_PROCESSING });
  try {
    const response = await API.del('PrivateAPI', '/api/private/portal/site/roomTypes/' + roomType.id, {});
    dispatch({ type: SITE_ROOM_TYPE_DELETE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_ROOM_TYPE_DELETE_FAILURE, payload: error });
  }
};
