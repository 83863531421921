const PackageCategory = {
  OTHER: 'OTHER',
  ACCOMMODATION: 'ACCOMMODATION',
  FOOD: 'FOOD',
  BEVERAGE: 'BEVERAGE',
  PARKING: 'PARKING',
  EXPERIENCE: 'EXPERIENCE',
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
};

const PackageCategoryList = [
  { label: 'Other', value: PackageCategory.OTHER },
  { label: 'Accommodation', value: PackageCategory.ACCOMMODATION },
  { label: 'Food', value: PackageCategory.FOOD },
  { label: 'Beverage', value: PackageCategory.BEVERAGE },
  { label: 'Parking', value: PackageCategory.PARKING },
  { label: 'Experience', value: PackageCategory.EXPERIENCE },
  { label: 'Breakfast', value: PackageCategory.BREAKFAST },
  { label: 'Lunch', value: PackageCategory.LUNCH },
  { label: 'Dinner', value: PackageCategory.DINNER },
];

export { PackageCategoryList };
export default PackageCategory;
