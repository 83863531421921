import React, { useCallback, useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import Countries from './Countries.json';
import FormField from '../form/FormField';
import { basicPhoneValidation, getCountryObject, getInitialCountryCode, getInitialPhone, getPhoneErrorMessage } from './PhoneNumberUtils';
import Autocomplete from '@material-ui/lab/Autocomplete';

function hasString(phoneNumber) {
  return /[^\d\s]/.test(phoneNumber); // checks if there's a non-digit character excluding spaces
}

export default function PhoneNumberInput({ phone, setPhone, isRequired, defaultRegion, label = 'Phone Number' }) {
  const [areaCodeOpen, setAreaCodeOpen] = useState(false);
  const [country, setCountry] = useState(() => getInitialCountryCode(phone?.phoneNumber, defaultRegion));
  const [phoneNum, setPhoneNum] = useState(() => getInitialPhone(phone, defaultRegion));

  const handleUpdateToParent = useCallback((updatedValue) => {
    setPhone(updatedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAreaCodeTextEvent = (e) => {
    let value = e.target.value;

    if (!value) {
      return;
    }

    if (!value.startsWith('+')) {
      // if it's not a number exit..
      if (isNaN(value)) {
        return;
      }

      //append country code
      value = '+' + value;
    }

    const match = Countries.find((c) => c.dial_code === value);
    if (match) {
      setCountry(match);
      setAreaCodeOpen(false);
    }
  };

  const openAreaCode = useCallback(() => setAreaCodeOpen(true), []);
  const closeAreaCode = useCallback(() => setAreaCodeOpen(false), []);

  useEffect(() => {
    if (phone?.autoComplete) {
      setCountry(getCountryObject(defaultRegion));
      setPhoneNum(() => getInitialPhone(phone, defaultRegion));
      handleUpdateToParent({ ...phone, autoComplete: false });
    } else {
      if (basicPhoneValidation(phoneNum)) {
        try {
          const sanitizedPhoneNum = parsePhoneNumber(phoneNum, country.value);
          handleUpdateToParent({
            phoneNumber: phoneNum,
            sanitizedPhoneNumber: sanitizedPhoneNum.number,
            isValid: hasString(phoneNum) ? false : sanitizedPhoneNum.isValid(),
          });
          return;
        } catch (e) {
          console.log(`Unable to parse input - number (${phoneNum}) error (${e.message})`);
        }
      }
      handleUpdateToParent({ phoneNumber: phoneNum, sanitizedPhoneNumber: '', isValid: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country.value, phoneNum, handleUpdateToParent, defaultRegion, phone?.autoComplete]);

  return (
    <Grid spacing={1} container direction="row" alignItems="center" justifyContent="space-between">
      <Grid item xs={5} sm={3} md={6} lg={4}>
        <Autocomplete
          open={areaCodeOpen}
          onOpen={openAreaCode}
          onClose={closeAreaCode}
          name={'Area Code'}
          placeholder={'Area Code'}
          options={Countries}
          getOptionSelected={(option, selected) => option.value === selected.value}
          getOptionLabel={(option) => option.value}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              (option) =>
                option.dial_code.toLowerCase().replace('+', '') === inputValue.toLowerCase().replace('+', '') ||
                option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                option.value.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          renderOption={(option) => `${option.flag} ${option.label} (${option.value})`}
          value={country}
          onChange={(event, newValue) => {
            if (newValue !== null && newValue !== undefined) {
              setCountry(newValue);
            } else {
              setCountry({
                label: '',
                value: '',
                flag: '',
                dial_code: ' ',
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Area Code'}
              required={isRequired}
              error={Boolean(getPhoneErrorMessage(phoneNum, phone.isValid, isRequired))}
              margin={'dense'}
              variant="outlined"
              onChange={handleAreaCodeTextEvent}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'tel-country-code',
                inputMode: 'tel',
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" style={{ marginRight: '0' }}>
                    {country.flag}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={7} sm={9} md={6} lg={8}>
        <FormField
          inputMode={'text'}
          autoComplete={'tel-national'}
          label={label}
          value={phoneNum}
          required={isRequired}
          setValue={setPhoneNum}
          error={Boolean(getPhoneErrorMessage(phoneNum, phone.isValid, isRequired))}
          reactInputProps={{
            startAdornment: <InputAdornment position="start">{`(${country?.dial_code})`}</InputAdornment>,
          }}
        />
      </Grid>
      <Typography variant={'caption'} color={'error'} style={{ paddingLeft: '20px' }}>
        {getPhoneErrorMessage(phoneNum, phone.isValid, isRequired)}
      </Typography>
    </Grid>
  );
}
