import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Vendor Schedule', 'FOOD_VENDOR_SCHEDULE');

function foodVendorScheduleReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default foodVendorScheduleReducer;
