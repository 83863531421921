export const SpecialRequestEscalationState = {
  NON_ACKNOWLEDGEMENT_ESCALATION: 'NON_ACKNOWLEDGEMENT_ESCALATION',
  NON_COMPLETION_ESCALATION: 'NON_COMPLETION_ESCALATION',
  NO_ESCALATION: 'NO_ESCALATION',
};

export const SpecialRequestEscalationStateLabel = {
  NON_ACKNOWLEDGEMENT_ESCALATION: 'Acknowledgement SLA Breached',
  NON_COMPLETION_ESCALATION: 'Completion SLA Breached',
};

export const SpecialRequestEscalationBoolean = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
];

export const SpecialRequestEscalationStateOptions = [
  {
    value: [SpecialRequestEscalationState.NON_ACKNOWLEDGEMENT_ESCALATION],
    label: 'Acknowledgement SLA Breached',
  },
  {
    value: [SpecialRequestEscalationState.NON_COMPLETION_ESCALATION],
    label: 'Completion SLA Breached',
  },
  {
    value: [SpecialRequestEscalationState.NON_COMPLETION_ESCALATION, SpecialRequestEscalationState.NON_ACKNOWLEDGEMENT_ESCALATION],
    label: 'Both',
  },
];
