import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/securityDoor/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

export default function SecurityRoomEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.securityDoorReducer.lastActionType);
  const error = useSelector((state) => state.securityDoorReducer.error);
  const selectedItem = useSelector((state) => state.securityDoorReducer.selectedItem);

  const [doorName, setDoorName] = React.useState(selectedItem?.doorName || '');
  const [doorId, setDoorId] = React.useState(selectedItem?.doorId || '');
  const [doorParent, setDoorParent] = React.useState(selectedItem?.doorParent || '');
  const [visible, setVisible] = React.useState(selectedItem?.visible || false);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'SECURITY_ROOM_UPDATE_SUCCESS' ||
      lastActionType === 'SECURITY_ROOM_ADD_SUCCESS' ||
      lastActionType === 'SECURITY_ROOM_DELETE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const id = selectedItem?.id;
    const payload = {
      ...selectedItem,
      doorName,
      doorId,
      doorParent,
      visible,
      siteId: selectedSite?.id,
    };
    if (id) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={'Update Door'}
        subheader={'Door available at the site'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit door - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Door Name'} value={doorName} setValue={setDoorName} placeholder="Door Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Door ID'} value={doorId} setValue={setDoorId} placeholder="Door ID" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Door Group'} value={doorParent} setValue={setDoorParent} placeholder="Door Parent" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Visible'} value={visible} setValue={setVisible} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
