import { Button } from '@material-ui/core';
import React from 'react';

export default function ChartTableButton({ tableView, setTableView }) {
  if (!setTableView) {
    return null;
  }

  return (
    <Button
      onClick={() => setTableView(!tableView)}
      fullWidth={true}
      color={tableView ? 'primary' : 'default'}
      variant={'contained'}
      size={'small'}
    >
      {`${tableView ? 'Table View' : 'Chart View'}`}
    </Button>
  );
}
