const MfaMode = {
  NONE: 'NONE',
  ALL: 'ALL',
};

const MfaModeOptions = [
  { value: MfaMode.NONE, label: 'No Authentication' },
  { value: MfaMode.ALL, label: 'Email & Phone Authentication' },
];

export default MfaMode;
export { MfaModeOptions };
