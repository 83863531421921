import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Tab, Tabs } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import TimeRange from './enum/TimeRange';
import * as DashboardActions from '../../../redux/actions/dashboard/actions';
import SiteFeature from '../../../../support/SiteFeature';
import { doesSiteHaveFeature } from '../../../redux/actions/access/actions';
import FinancialsTab from './tab/FinancialsTab';
import MobileKeyTab from './tab/MobileKeyTab';
import GeneralTab from './tab/GeneralTab';
import DashboardTimeControl from './DashboardTimeControl';
import moment from 'moment';
import FoodAndBeverageTab from './tab/FoodAndBeverageTab';
import GuestServiceTab from './tab/GuestServiceTab';

export default function Dashboard() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const error = useSelector((state) => state.dashboardReducer.error);
  const processing = useSelector((state) => state.dashboardReducer.processing);
  const [timeframe, setTimeframe] = useState({
    start: moment().subtract(7, 'days'),
    end: moment(),
    unit: TimeRange.DAY.value,
  });

  const trackingEnabled =
    doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_IN) || doesSiteHaveFeature(selectedSite, SiteFeature.CHECK_OUT);
  const irdEnabled = doesSiteHaveFeature(selectedSite, SiteFeature.IN_ROOM_ORDERING);
  const guestRequestEnabled = doesSiteHaveFeature(selectedSite, SiteFeature.SPECIAL_REQUEST);
  const [selectedTab, setSelectedTab] = useState(trackingEnabled ? 0 : 2);

  useEffect(() => {
    if (selectedSite) {
      dispatch(DashboardActions.getWidgetData(selectedSite.id, timeframe));
      if (irdEnabled) {
        dispatch(DashboardActions.getIrdWidgetData(selectedSite.id, timeframe));
      }
    }
  }, [dispatch, timeframe, selectedSite, irdEnabled]);

  const changeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <CardHeader title={'Site Dashboard'} subheader={selectedSite?.name} />
        </Grid>

        <Grid item xs={6} md={6} className="flex-end">
          <DashboardTimeControl timeframe={timeframe} setTimeframe={setTimeframe} processing={processing} />
        </Grid>
      </Grid>

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {'Unable to update dashboard data - ' + error.message}
          </Alert>
        )}

        <Box>
          <Tabs value={selectedTab} onChange={changeTab}>
            {trackingEnabled && <Tab label="Reservation Tracking" value={0} />}
            {trackingEnabled && <Tab label="Financials" value={1} />}
            {doesSiteHaveFeature(selectedSite, SiteFeature.DIGITAL_ROOM_KEY) && <Tab label="Room Key Tracking" value={2} />}
            {irdEnabled && <Tab label="Food And Beverage" value={3} />}
            {guestRequestEnabled && <Tab label="Guest Services" value={4} />}
          </Tabs>
        </Box>

        <GeneralTab selectedSite={selectedSite} selectedTab={selectedTab} tabIndex={0} timeframe={timeframe} />
        <FinancialsTab selectedSite={selectedSite} selectedTab={selectedTab} tabIndex={1} timeframe={timeframe} />
        <MobileKeyTab selectedSite={selectedSite} selectedTab={selectedTab} tabIndex={2} timeframe={timeframe} />
        <FoodAndBeverageTab selectedSite={selectedSite} selectedTab={selectedTab} tabIndex={3} timeframe={timeframe} />
        <GuestServiceTab selectedSite={selectedSite} selectedTab={selectedTab} tabIndex={4} timeframe={timeframe} />
      </CardContent>
    </Card>
  );
}
