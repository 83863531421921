import actionCreator from '../../../support/actionCreator';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import Objects from '../../../../../support/Objects';

export const HOTEL_RESERVATION_PREFIX = 'HOTEL_RESERVATION';

const BASE_URL = '/api/private/portal/{siteId}/reservations';

const HotelReservationActions = {
  ...actionCreator.createActions('Reservation', HOTEL_RESERVATION_PREFIX, BASE_URL),
  resyncReservations: (parameters) => {
    const url = actionCreator.markupBaseUrl(BASE_URL, parameters);

    API.post('PrivateAPI', `${url}/resync`, {})
      .then(() => {
        Swal.fire('Resync Requested', 'This may take up to 5 minutes to complete. Please be patient', 'success');
      })
      .catch(() => {
        Swal.fire('Resync Failed', 'Unable to request reservation resync. Please try again later', 'error');
      });
  },
  resyncReservation: (reference, parameters) => {
    const url = actionCreator.markupBaseUrl(BASE_URL, parameters);

    API.post('PrivateAPI', `${url}/resync/${reference}`, {})
      .then(() => {
        Swal.fire('Resync Requested', 'This may take several minutes. Please be patient', 'success');
      })
      .catch(() => {
        Swal.fire('Resync Failed', 'Unable to request reservation resync. Please try again later', 'error');
      });
  },
  notifyReservation: (reference, parameters) => {
    const url = actionCreator.markupBaseUrl(BASE_URL, parameters);

    API.get('PrivateAPI', `${url}/notify?reference=${reference}`, {})
      .then(() => {
        Swal.fire('Notification Issued', 'This may take several minutes. Please be patient', 'success');
      })
      .catch(() => {
        Swal.fire('Request Failed', 'Unable to notify customer. Please try again later', 'error');
      });
  },
  fetchById: (id, parameters) => async (dispatch) => {
    try {
      const url = actionCreator.markupBaseUrl(BASE_URL, parameters);
      const response = await API.get('PrivateAPI', `${url}/${id}`);
      dispatch({ type: 'HOTEL_RESERVATION_SELECT', payload: response });
    } catch (error) {
      dispatch({ type: 'HOTEL_RESERVATION_FETCH_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  unselectReservation: () => async (dispatch) => {
    dispatch({ type: 'HOTEL_RESERVATION_UNSELECT' });
  },

  openAndroidWalletObject: (site, reservation) => async () => {
    try {
      const file = await API.get('PrivateAPI', `/api/wallet/android/${site}/${reservation}`);
      const a = document.createElement('a');
      a.href = file;
      a.click();
    } catch (error) {
      console.error('Unable to get the android wallet object', error);
    }
  },
};

export default HotelReservationActions;
