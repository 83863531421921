import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import Objects from '../../../support/Objects';

function markupBaseUrl(baseUrl, map = {}) {
  let value = baseUrl;

  // accepts and replaces values in the base url with the ones given in the map
  Object.keys(map).forEach((key) => {
    value = value.replaceAll(`{${key}}`, map[key]);
  });

  return value;
}

const actionCreator = {
  markupBaseUrl,
  createActions: (name, prefix, baseUrl, overrides, projection) => {
    return {
      fetch: (page, size, parameters, pathVariables) => async (dispatch) => {
        dispatch({ type: `${prefix}_FETCH_IN_PROGRESS` });

        /**
         * You can overrides the base fetch using by providing overrides.fetchUrl when creating
         * the actions
         */
        const baseFetchUrl = overrides?.fetchUrl
          ? markupBaseUrl(overrides?.fetchUrl, pathVariables)
          : markupBaseUrl(baseUrl, pathVariables);

        /**
         * Base request URL with no frills includes the page and size of the fetch to return
         */
        let requestUrl = `${baseFetchUrl}?page=${page || 0}&size=${size || 10}`;

        /**
         * parameters is a JS object which will be appended as HTTP parameters to the fetch request
         */
        if (parameters) {
          Object.keys(parameters).forEach((key) => {
            if (parameters[key] !== '') {
              requestUrl += `&${key}=${parameters[key]}`;
            }
          });
        }

        try {
          const response = await API.get('PrivateAPI', `${requestUrl}`, {});
          const page = response.page ? response.page : { ...response };

          dispatch({
            type: `${prefix}_FETCH_SUCCESS`,
            payload: {
              ...response,
              page: {
                number: page.number,
                size: page.size,
                totalElements: page.totalElements,
                totalPages: page.totalPages,
              },
            },
          });
        } catch (error) {
          dispatch({
            type: `${prefix}_FETCH_FAILURE`,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        }
      },
      add: (item, pathVariables) => async (dispatch) => {
        Objects.notNull(item, 'item cannot be null');

        dispatch({ type: `${prefix}_ADD_IN_PROGRESS` });

        const url = markupBaseUrl(baseUrl, pathVariables);
        try {
          const response = await API.post('PrivateAPI', `${url}`, { body: item });
          if (projection) {
            const requestUrl = new URL(response._links.self.href).pathname + `?projection=${projection}`;
            const fetched = API.get('PrivateAPI', requestUrl, {});
            return dispatchForAdd(fetched, dispatch);
          } else {
            return dispatchForAdd(response, dispatch);
          }
        } catch (error) {
          console.warn('add() call failed with result: ', error);
          dispatch({ type: `${prefix}_ADD_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
        }
      },
      update:
        (item, overrideRequestUrl, isPatch = true, quite = false) =>
        async (dispatch) => {
          Objects.notNull(item, 'item cannot be null');

          dispatch({ type: `${prefix}_UPDATE_IN_PROGRESS` });
          const updatePath = getItemSelfLink(overrideRequestUrl, item);

          try {
            let response;

            if (isPatch) {
              response = await API.patch('PrivateAPI', updatePath, { body: item });
            } else {
              response = await API.put('PrivateAPI', updatePath, { body: item });
            }

            if (projection) {
              const requestUrl = getItemSelfLink(null, response) + `?projection=${projection}`;
              API.get('PrivateAPI', requestUrl, {}).then((res) => {
                dispatchForUpdate(res, dispatch, quite);
              });
            } else {
              dispatchForUpdate(response, dispatch, quite);
            }
          } catch (error) {
            console.warn('update() call failed with result: ', error);
            dispatch({ type: `${prefix}_UPDATE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
          }
        },

      delete: (item, overrideRequestUrl, parameters, promptOverrides) => async (dispatch) => {
        Objects.notNull(item, 'item');

        let deletePath = getItemSelfLink(overrideRequestUrl, item);

        /**
         * parameters is a JS object which will be appended as HTTP parameters to the fetch request
         */
        if (parameters) {
          deletePath = deletePath + '?';
          let counter = 0;

          Object.keys(parameters).forEach((key) => {
            if (counter === 0) {
              deletePath += `${key}=${parameters[key]}`;
              counter++;
            } else {
              deletePath += `&${key}=${parameters[key]}`;
              counter++;
            }
          });
        }

        Swal.fire({
          title: promptOverrides?.title || `Delete ${name}?`,
          text: promptOverrides?.text || 'Warning: This operation is not reversible',
          icon: 'warning',
          showCancelButton: true,
          heightAuto: false,
        }).then((result) => {
          if (result.value) {
            dispatch({ type: `${prefix}_DELETE_IN_PROGRESS` });

            API.del('PrivateAPI', deletePath, {})
              .then(() => {
                dispatch({ type: `${prefix}_DELETE_SUCCESS`, payload: item });
              })
              .catch((error) => {
                dispatch({ type: `${prefix}_DELETE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
              });
          }
        });
      },
      clearError: () => async (dispatch) => {
        dispatch({ type: `${prefix}_CLEAR_ERROR` });
      },
      select: (item) => async (dispatch) => {
        dispatch({ type: `${prefix}_SELECT`, payload: item });
      },
    };

    function getItemSelfLink(overrideUrl, item) {
      if (overrideUrl) {
        return overrideUrl;
      }

      if (item) {
        if (item.links && item.links.length > 0) {
          const link = item.links.find((e) => e.rel === 'self').href;
          return new URL(link).pathname;
        }

        if (item._links && item._links.length > 0) {
          return new URL(item._links.self.href).pathname;
        }

        if (item.id) {
          return `${baseUrl}/${item.id}`;
        }
      }

      return baseUrl;
    }

    function dispatchForAdd(response, dispatch) {
      if (response) {
        dispatch({ type: `${prefix}_ADD_SUCCESS`, payload: response });
        Swal.fire(`${name} Created`, 'Your changes have been saved successfully', 'success');
      } else {
        dispatch({
          type: `${prefix}_ADD_FAILURE`,
          payload: new Error('Response returned back from server was null or invalid. Check server response'),
        });
      }
      return response;
    }

    function dispatchForUpdate(response, dispatch, quite = false) {
      if (response) {
        dispatch({ type: `${prefix}_UPDATE_SUCCESS`, payload: response });

        if (!quite) {
          Swal.fire(`${name} Updated`, 'Your changes have been saved successfully', 'success');
        }
      } else {
        dispatch({
          type: `${prefix}_UPDATE_FAILURE`,
          payload: new Error('Response returned back from server was null or invalid. Check server response'),
        });
      }
    }
  },
};

export default actionCreator;
