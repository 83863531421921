import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../../components/form/FormField';
import Objects from '../../../../../../support/Objects';

const CMSRateCode = [
  { label: 'BAR', value: 'BAR' },
  { label: '18', value: '18' },
];

const CMSRoomTypes = [
  { label: '1BD', value: '1BD' },
  { label: '1BDE', value: '1BDE' },
  { label: '1BDH', value: '1BDH' },
  { label: '2BD', value: '2BD' },
  { label: '2BD1B', value: '2BD1B' },
];

export default function EditCMS() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [endpoint, setEndpoint] = React.useState(
    configuration?.endpoint || 'http://vendortestn.guestcentrix.net/KioskWS_Liverton/OTAService.asmx'
  );
  const [enabled, setEnabled] = React.useState(selectedItem ? selectedItem.enabled : true);
  const [username, setUsername] = React.useState(configuration?.username || 'LIVERTON');
  const [password, setPassword] = React.useState(configuration?.password || 'liverton');
  const [hotelCode, setHotelCode] = React.useState(configuration?.hotelCode || 'RICH');
  const [requesterId, setRequesterId] = React.useState(configuration?.requesterId || 'HIUSA');
  const [uploadPhoto, setUploadPhoto] = React.useState(configuration?.uploadPhoto || true);
  const [uploadDocument, setUploadDocument] = React.useState(configuration?.uploadDocument || true);
  const [rateCode, setRateCode] = React.useState(configuration?.rateCode || []);
  const [allowedRoomTypeCode, setAllowedRoomTypeCode] = React.useState(configuration?.allowedRoomTypeCode || []);
  const [roomTypes, setRoomTypes] = React.useState(configuration?.roomTypes || []);
  const [uploadSignature, setUploadSignature] = React.useState(configuration?.uploadSignature || false);
  const [travelAgentSkipBill, setTravelAgentSkipBill] = React.useState(configuration?.travelAgentSkipBill || false);
  const [travelAgentWithBalance, setTravelAgentWithBalance] = React.useState(configuration?.travelAgentWithBalance || false);
  const [requesterIDType, setRequesterIDType] = React.useState(configuration?.requesterIDType || '13');
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'NZD');
  const [readTimeout, setReadTimeout] = React.useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = React.useState(configuration?.connectTimeout || 5000);

  const handleSubmit = React.useCallback(() => {
    if (selectedSite) {
      const payload = {
        ...selectedItem,
        name: name,
        enabled: enabled,
        systemCategory: selectedItem.systemCategory,
        systemType: selectedItem.systemType,
        siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
        systemConfiguration: JSON.stringify({
          endpoint: Objects.sanitizeAndTrimString(endpoint),
          username,
          password,
          hotelCode,
          requesterId,
          uploadPhoto,
          uploadDocument,
          uploadSignature,
          rateCode,
          allowedRoomTypeCode,
          roomTypes,
          travelAgentSkipBill,
          travelAgentWithBalance,
          requesterIDType,
          debug,
          currency,
          readTimeout,
          connectTimeout,
        }),
      };

      if (selectedItem.id) {
        dispatch(Actions.updateSiteSystem(payload));
      } else {
        dispatch(Actions.createSiteSystem(payload));
      }
    }
  }, [
    allowedRoomTypeCode,
    connectTimeout,
    currency,
    debug,
    dispatch,
    enabled,
    endpoint,
    hotelCode,
    name,
    password,
    rateCode,
    readTimeout,
    requesterIDType,
    requesterId,
    roomTypes,
    selectedItem,
    selectedSite,
    travelAgentSkipBill,
    travelAgentWithBalance,
    uploadDocument,
    uploadPhoto,
    uploadSignature,
    username,
  ]);

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update CMS PMS' : 'Create CMS PMS'}
        subheader={'GuestCentrix property management system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={false}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit/add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={name} setValue={setName} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Endpoint'} value={endpoint} setValue={setEndpoint} error={!endpoint} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Liverton Account Username'} value={username} setValue={setUsername} error={!username} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Liverton User Password'} value={password} setValue={setPassword} type={'password'} error={!password} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Hotel Code'} value={hotelCode} setValue={setHotelCode} error={!hotelCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Liverton Requester ID'} value={requesterId} setValue={setRequesterId} error={!requesterId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Liverton Requester ID Type'}
              value={requesterIDType}
              setValue={setRequesterIDType}
              error={!requesterIDType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="rateCodes">Rate Codes</InputLabel>
              <Select
                labelId="rateCodes"
                value={rateCode}
                multiple={true}
                labelWidth={100}
                onChange={(event) => setRateCode(event.target.value)}
              >
                {CMSRateCode.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={rateCode.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Allowed room type codes'}
              value={allowedRoomTypeCode}
              setValue={setAllowedRoomTypeCode}
              placeholder={'Comma Seperated e.g. ABC,ABD,ABE'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="roomTypes">Room Types</InputLabel>
              <Select
                labelId="roomTypes"
                value={roomTypes}
                multiple={true}
                labelWidth={100}
                onChange={(event) => setRoomTypes(event.target.value)}
              >
                {CMSRoomTypes.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={roomTypes.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Upload Document Photo'} value={uploadPhoto} setValue={setUploadPhoto} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Upload Full Document'} value={uploadDocument} setValue={setUploadDocument} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Upload Signature'} value={uploadSignature} setValue={setUploadSignature} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Travel Agent Check Out'} value={travelAgentSkipBill} setValue={setTravelAgentSkipBill} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Travel Agent With Balance'} value={travelAgentWithBalance} setValue={setTravelAgentWithBalance} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Connect Timeout'} value={connectTimeout} setValue={setConnectTimeout} type={'number'} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout'} value={readTimeout} setValue={setReadTimeout} type={'number'} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
