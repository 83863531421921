import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VacantRoomStatusList from '../../../../../../support/VacantRoomStatusList';
import BillWindowList from '../../../../../../support/BillWindowList';
import FormField from '../../../../../../components/form/FormField';
import FormSelect from '../../../../../../components/form/FormSelect';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import Objects from '../../../../../../support/Objects';
import Autocomplete from '@material-ui/lab/Autocomplete';

const NON_PAYABLE_OPTIONS = [
  { label: 'Send to reception', value: 'SEND_RECEPTION' },
  { label: 'Check Balance', value: 'CHECK_BALANCE' },
  { label: 'Transfer', value: 'TRANSFER' },
  { label: 'Transfer and Settle', value: 'TRANSFER_SETTLEMENT' },
];

const UPDATE_PAYMENT_TYPE_OPTIONS = [
  { label: 'Do Not Update', value: 'NONE' },
  { label: 'Replace Cash', value: 'REPLACE_CASH' },
  { label: 'Update Next Window', value: 'ADD_TOKEN' },
];

const PAYMENT_GATEWAY_OPTIONS = [
  { label: 'No Payment Gateway', value: 'NONE' },
  { label: 'Opera Payment Interface (OPI)', value: 'OPI' },
  { label: 'Micros Payment Gateway (MPG)', value: 'MPG' },
];

const MAKE_PAYMENT_OPTIONS = [
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Credit Card Approved', value: 'CREDIT_CARD_APPROVED' },
  { label: 'Credit Card Approve (Dummy Number)', value: 'CREDIT_CARD_APPROVED_DUMMY' },
];

const GUEST_RECEIPT_OPTIONS = [
  { label: 'Send Via PMS', value: 'SEND_DIRECTLY' },
  { label: 'Generate Via PMS', value: 'GENERATE_PDF' },
  { label: 'Send at Check-out', value: 'SEND_AT_CHECK_OUT' },
  { label: 'Use Email Template', value: 'USE_EMAIL_TEMPLATE' },
];

const CHECK_OUT_MODE_OPTIONS = [
  { label: 'All Guests', value: 'ALL_GUESTS' },
  { label: 'Only Guest Folios', value: 'ONLY_GUEST_FOLIOS' },
];

const PROFILE_FETCH_MODE = [
  { label: 'Off', value: 'OFF' },
  { label: 'Conditional', value: 'CONDITIONAL' },
  { label: 'Always', value: 'ALWAYS' },
];

export default function EditOpera() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = useState(selectedItem?.name || '');
  const [enabled, setEnabled] = useState(selectedItem?.enabled || true);
  const [endpoint, setEndpoint] = useState(configuration?.endpoint || 'https://selenium.liverton.dmz/OWS_WS_51');
  const [username, setUsername] = useState(configuration?.username || 'OEDS$OWS');
  const [password, setPassword] = useState(configuration?.password || '$$$OEDS$OWS$$');
  const [hotelCode, setHotelCode] = useState(configuration?.hotelCode || '');
  const [chainCode, setChainCode] = useState(configuration?.chainCode || 'CHA');
  const [vacantRoomStatuses, setVacantRoomStatuses] = useState(configuration?.vacantRoomStatuses || ['IP', 'CL']);
  const [payableBillWindows, setPayableBillWindows] = useState(configuration?.payableBillWindows || [1]);
  const [nonPayableBillWindowAction, setNonPayableBillWindowAction] = useState(
    configuration?.nonPayableBillWindowAction || 'CHECK_BALANCE'
  );
  const [updatePaymentTypeMode, setUpdatePaymentTypeMode] = useState(configuration?.updatePaymentTypeMode || 'NONE');
  const [paymentGatewayMode, setPaymentGatewayMode] = useState(configuration?.paymentGatewayMode || 'NONE');
  const [makePaymentMode, setMakePaymentMode] = useState(configuration?.makePaymentMode || 'CREDIT_CARD');
  const [useHouseKeepingStatus, setUseHouseKeepingStatus] = useState(
    Objects.isNull(configuration?.useHouseKeepingStatus) ? true : configuration?.useHouseKeepingStatus
  );
  const [useOperaCloud, setUseOperaCloud] = useState(configuration?.useOperaCloud || false);
  const [cloudUsername, setCloudUsername] = useState(configuration?.cloudUsername || '');
  const [cloudPassword, setCloudPassword] = useState(configuration?.cloudPassword || '');
  const [useKioskAvailability, setUseKioskAvailability] = useState(configuration?.useKioskAvailability || false);
  const [traceLogEnabled, setTraceLogEnabled] = useState(configuration?.traceLogEnabled || false);
  const [bookingSessionControl, setBookingSessionControl] = useState(configuration?.bookingSessionControl || false);
  const [debug, setDebug] = useState(configuration?.debug || false);
  const [currency, setCurrency] = useState(configuration?.currency || 'NZD');
  const [readTimeout, setReadTimeout] = useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = useState(configuration?.connectTimeout || 5000);
  const [receiptMode, setReceiptMode] = useState(configuration?.receiptMode || 'SEND_AT_CHECK_OUT');
  const [unavailableRooms, setUnavailableRooms] = useState(configuration?.unavailableRooms || []);
  const [checkoutOutMode, setCheckoutOutMode] = useState(configuration?.checkoutOutMode || 'ALL_GUESTS');
  const [fetchFullProfile, setFetchFullProfile] = useState(configuration?.fetchFullProfile || false);
  const [allowFreePackages, setAllowFreePackages] = useState(configuration?.allowFreePackages || false);
  const [profileFetchMode, setProfileFetchMode] = useState(configuration?.profileFetchMode || 'OFF');
  const [profilePrivacy, setProfilePrivacy] = useState(configuration?.allowProfilePrivacy || false);
  const [domainInHeader, setDomainInHeader] = useState(
    Objects.isNull(configuration?.domainInHeader) ? false : configuration?.domainInHeader
  );

  const handleSubmit = useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        endpoint: Objects.sanitizeAndTrimString(endpoint),
        username: username,
        password: password,
        hotelCode: hotelCode,
        chainCode: chainCode,
        vacantRoomStatuses: vacantRoomStatuses,
        payableBillWindows: payableBillWindows,
        nonPayableBillWindowAction: nonPayableBillWindowAction,
        updatePaymentTypeMode: updatePaymentTypeMode,
        paymentGatewayMode: paymentGatewayMode,
        makePaymentMode: makePaymentMode,
        useHouseKeepingStatus: useHouseKeepingStatus,
        useOperaCloud: useOperaCloud,
        cloudUsername: cloudUsername,
        cloudPassword: cloudPassword,
        useKioskAvailability: useKioskAvailability,
        traceLogEnabled: traceLogEnabled,
        bookingSessionControl: bookingSessionControl,
        debug: debug,
        currency: currency,
        connectTimeout: connectTimeout,
        readTimeout: readTimeout,
        receiptMode: receiptMode,
        unavailableRooms: unavailableRooms,
        checkoutOutMode: checkoutOutMode,
        fetchFullProfile: fetchFullProfile,
        allowFreePackages: allowFreePackages,
        domainInHeader: domainInHeader,
        profileFetchMode: profileFetchMode,
        profilePrivacy: profilePrivacy,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    bookingSessionControl,
    chainCode,
    cloudPassword,
    cloudUsername,
    connectTimeout,
    currency,
    debug,
    dispatch,
    enabled,
    endpoint,
    hotelCode,
    makePaymentMode,
    name,
    nonPayableBillWindowAction,
    password,
    payableBillWindows,
    paymentGatewayMode,
    readTimeout,
    selectedItem,
    selectedSite.id,
    traceLogEnabled,
    updatePaymentTypeMode,
    useHouseKeepingStatus,
    useKioskAvailability,
    useOperaCloud,
    username,
    vacantRoomStatuses,
    receiptMode,
    unavailableRooms,
    checkoutOutMode,
    fetchFullProfile,
    allowFreePackages,
    domainInHeader,
    profileFetchMode,
    profilePrivacy,
  ]);

  const handleUnavailableRoomRemove = (value) => {
    setUnavailableRooms(unavailableRooms.filter((item) => item !== value));
  };
  const handleUnavailableRoomUpdate = (value) => {
    if (!value || (unavailableRooms && unavailableRooms.length > 0 && unavailableRooms.filter((tag) => tag === value).length > 0)) {
      return;
    }

    if (unavailableRooms.length > 0) {
      setUnavailableRooms([...unavailableRooms, value]);
    } else {
      setUnavailableRooms([value]);
    }
  };

  const isFormReady = name && endpoint && username && password && hotelCode && chainCode;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Opera' : 'Create Opera'}
        subheader={'Opera 5.5/Opera Cloud property management system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Endpoint URL'} value={endpoint} setValue={setEndpoint} error={!endpoint} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Opera Username'} value={username} setValue={setUsername} error={!username} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Opera Password'} type={'password'} value={password} setValue={setPassword} error={!password} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Hotel Code'} value={hotelCode} setValue={setHotelCode} error={!hotelCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Chain Code'} value={chainCode} setValue={setChainCode} error={!chainCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="vacantRoomStatusesList">Vacant Room Statuses</InputLabel>
              <Select
                labelId="vacantRoomStatusesList"
                value={vacantRoomStatuses}
                multiple={true}
                labelWidth={170}
                onChange={(event) => setVacantRoomStatuses(event.target.value)}
              >
                {VacantRoomStatusList.map((vs) => (
                  <MenuItem key={`vs-${vs.value}`} value={vs.value} selected={vacantRoomStatuses.find((v) => v === vs.value)}>
                    {vs.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Use House Keeping Status'} value={useHouseKeepingStatus} setValue={setUseHouseKeepingStatus} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel id="payableBillWindows">Payable Bill Windows</InputLabel>
              <Select
                labelId="payableBillWindows"
                value={payableBillWindows}
                multiple={true}
                labelWidth={160}
                onChange={(event) => setPayableBillWindows(event.target.value)}
              >
                {BillWindowList.map((i) => (
                  <MenuItem key={`bw-${i.value}`} value={i.value} selected={payableBillWindows.find((p) => p === i.value)}>
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              multiple
              id="unavailableRooms"
              options={[]}
              defaultValue={[]}
              freeSolo
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Hide Rooms from Cache'}
                  placeholder="Number followed by X. e.g 202 = 2XX, 9001 = 9XXX"
                  variant={'outlined'}
                  margin={'dense'}
                />
              )}
              value={unavailableRooms}
              renderTags={(values) =>
                values.map((option) => <Chip key={option} label={option} onDelete={() => handleUnavailableRoomRemove(option)} />)
              }
              onChange={(event) => handleUnavailableRoomUpdate(event.target.value)}
              onClose={(event) => handleUnavailableRoomUpdate(event.target.value)}
              clearOnBlur
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Non Payable Bill Window Action'}
              value={nonPayableBillWindowAction}
              setValue={setNonPayableBillWindowAction}
              options={NON_PAYABLE_OPTIONS}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Payment Type Mode'}
              value={updatePaymentTypeMode}
              setValue={setUpdatePaymentTypeMode}
              options={UPDATE_PAYMENT_TYPE_OPTIONS}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Payment Gateway Mode'}
              value={paymentGatewayMode}
              setValue={setPaymentGatewayMode}
              options={PAYMENT_GATEWAY_OPTIONS}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Make Payment Mode'} value={makePaymentMode} setValue={setMakePaymentMode} options={MAKE_PAYMENT_OPTIONS} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Guest Receipt Mode'} value={receiptMode} setValue={setReceiptMode} options={GUEST_RECEIPT_OPTIONS} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Check Out Mode'} value={checkoutOutMode} setValue={setCheckoutOutMode} options={CHECK_OUT_MODE_OPTIONS} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Opera Cloud'} value={useOperaCloud} setValue={setUseOperaCloud} />
          </Grid>

          {useOperaCloud && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Opera Cloud Username'} value={cloudUsername} setValue={setCloudUsername} error={!cloudUsername} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField
                  label={'Opera Cloud Password'}
                  type={'password'}
                  value={cloudPassword}
                  setValue={setCloudPassword}
                  error={!cloudPassword}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Use Kiosk Availability'} value={useKioskAvailability} setValue={setUseKioskAvailability} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Booking Session Control'} value={bookingSessionControl} setValue={setBookingSessionControl} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Trace Logging'} value={traceLogEnabled} setValue={setTraceLogEnabled} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Send Domain Header'} value={domainInHeader} setValue={setDomainInHeader} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug Logging'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Connect Timeout (MS)'} type={'number'} value={connectTimeout} setValue={setConnectTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Read Timeout (MS)'} type={'number'} value={readTimeout} setValue={setReadTimeout} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Fetch Full Profile'} value={fetchFullProfile} setValue={setFetchFullProfile} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Allow Free Packages'} value={allowFreePackages} setValue={setAllowFreePackages} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Profile Fetch Mode'} value={profileFetchMode} setValue={setProfileFetchMode} options={PROFILE_FETCH_MODE} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Populate Profile Privacy'} value={profilePrivacy} setValue={setProfilePrivacy} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
