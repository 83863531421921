import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';

export const ActivityImageActions = {
  ...actionCreator.createActions('Activity Image', 'ACTIVITY_IMAGE', '/api/private/portal/activity/{activityId}/image'),
  updateSuccess: (item) => async (dispatch) => {
    dispatch({ type: `ACTIVITY_IMAGE_REORDER_SUCCESS`, payload: item });
  },
  error: (error) => async (dispatch) => {
    dispatch({ type: `ACTIVITY_IMAGE_UPDATE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
  },
};

export default ActivityImageActions;
