import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import * as FirestoreService from '../../../../components/firebase/firebase';
import * as Actions from '../../../redux/actions/chat/actions';
import Swal from 'sweetalert2';
import { Box, Button, Grid, TextField } from '@material-ui/core';

export default function ChatInput({ selectedChat, guestMessageList }) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const userEmail = useSelector((state) => state.accessReducer.profile.email);
  const userId = useSelector((state) => state.accessReducer.profile.identifier);
  const [userInput, setUserInput] = React.useState('');

  const selectedSiteId = selectedSite.id;
  const selectedSiteName = selectedSite.name;

  const sendChatMessage = React.useCallback(
    (message) => {
      if (selectedChat) {
        const chatMessageBody = {
          text: message,
          createdAt: new Date().getTime(),
          user: {
            _id: userId,
            email: userEmail,
            name: 'Reception Staff',
          },
        };

        setUserInput('');

        FirestoreService.addMessageToChatThread(chatMessageBody, selectedChat.collectionId, selectedChat.guestUsername)
          .then(() => {
            dispatch(
              Actions.sendPushNotification({
                username: selectedChat.recipient,
                siteName: selectedSiteName,
                siteId: selectedSiteId,
                messageText: message,
              })
            );

            FirestoreService.setUnreadMessagesToZero(selectedChat.collectionId, selectedChat.guestUsername)
              .then(() => {
                console.log('Unread message counter set to 0 for ', selectedChat.guestUsername);
              })
              .catch((error) => {
                console.log('Failed to update the unread messages counter for selected chat ', error);
              });
          })
          .catch((error) => {
            console.log('Could not send message to chat service', error);
            Swal.fire('Message Not Sent', 'Could not send message to chat service. Please try again later', 'error');
          });
      } else {
        Swal.fire('Message Not Sent', 'Could not get recipient information for message', 'error');
      }
    },
    [dispatch, selectedChat, selectedSiteName, selectedSiteId, userEmail, userId]
  );

  return (
    <Box mb={1}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendChatMessage(userInput);
        }}
        hidden={!guestMessageList}
      >
        <Grid container spacing={0} justifyContent="space-between" alignItems={'center'}>
          <Grid item style={{ flex: 1 }}>
            <TextField
              id="userInput"
              inputProps={{ autoFocus: true }}
              type="text"
              variant={'outlined'}
              autoFocus={true}
              fullWidth
              onChange={(event) => setUserInput(event.target.value)}
              value={userInput}
              autoComplete="off"
              placeholder="Type your message..."
              required
            />
          </Grid>
          <Grid item>
            <Button type="submit" color={'primary'} disabled={!userInput}>
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
