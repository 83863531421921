import React from 'react';
import ContentContainer from '../../../components/container/ContentContainer';
import { Button, Card, CardContent, CardHeader, FormControl, Hidden, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as AuthenticationActions from '../../redux/actions/authentication/actions';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { ExitToAppOutlined, LockOpenOutlined } from '@material-ui/icons';

export default function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { lastActionType, error } = useSelector((state) => state.authenticationReducer);

  const requestAccountLogin = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(AuthenticationActions.requestLogin(email, password));
    },
    [dispatch, email, password]
  );

  const requestNavigation = React.useCallback(
    (location) => {
      history.push(`/${location}`);
      dispatch(AuthenticationActions.updateAuthenticationState(location));
    },
    [history, dispatch]
  );

  const isLoginProcessing = lastActionType === AuthenticationActions.LOGIN_IN_PROGRESS;
  return (
    <ContentContainer variant={'centered'}>
      <Card>
        <CardHeader title={'SmartCheck Mobile'} subheader={'Welcome please login to get started'} />

        <CardContent>
          <form onSubmit={requestAccountLogin}>
            {error && <Alert severity={'error'} variant={'filled'} className={'mb-2'}>{`Unable to login - ${error.message}`}</Alert>}

            {isLoginProcessing && (
              <Alert severity={'info'} variant={'filled'} className={'mb-2'}>
                {'Logging in please wait...'}
              </Alert>
            )}

            <FormControl fullWidth={true}>
              <TextField
                id="email"
                margin="dense"
                variant="outlined"
                label="Email Address"
                type="email"
                autoFocus={true}
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="password"
                margin="dense"
                variant="outlined"
                label="Password"
                type="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button
                variant="contained"
                color="primary"
                type={'submit'}
                disabled={isLoginProcessing}
                fullWidth={true}
                startIcon={<ExitToAppOutlined />}
              >
                Login
              </Button>
            </FormControl>

            <Hidden mdUp>
              <FormControl fullWidth={true} margin={'dense'}>
                <Button
                  variant="outlined"
                  type={'button'}
                  color="primary"
                  fullWidth={true}
                  startIcon={<ExitToAppOutlined />}
                  onClick={() => requestNavigation('signUp')}
                >
                  Create Account
                </Button>
              </FormControl>
            </Hidden>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button
                type={'button'}
                variant={'text'}
                fullWidth={true}
                onClick={() => requestNavigation('forgotPassword')}
                startIcon={<LockOpenOutlined />}
              >
                Reset Password
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </ContentContainer>
  );
}
