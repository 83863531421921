import { Box, Paper } from '@material-ui/core';

export default function PrivacyPolicy() {
  return (
    <Paper>
      <Box p={2}>
        <h3>Privacy Policy:</h3>

        <h3>
          <b>1. Introduction:</b>
        </h3>
        <p>
          This application (“SmartCheck“) has been developed and is operated and maintained by or on behalf of Liverton Limited (“Liverton“,
          “we” or “us“).
        </p>
        <p>
          Liverton is committed to protecting the privacy of your personal information whilst striving to provide the very best user
          experience. We want our apps to be safe and enjoyable for everyone.<span className="Apple-converted-space">&nbsp;</span>
        </p>

        <h3>
          <b>2. Runtime Permissions:</b>
        </h3>
        <p>
          In order to provide mobile room keys SmartCheck requires access to your location (while the app is open) and bluetooth
          permissions. SmartCheck does not use your location for marketing purposes and does not share this information with 3rd parties.
          Location data is only used to provide the bluetooth keys functionality and to improve opening performance.
        </p>

        <h3>
          <b>3. The types of personal information we collect and how we collect it:</b>
        </h3>
        <ul>
          <li>
            Information you provide upon purchasing the App via the App Store: when you purchase the App via an application store (including
            but not limited to the iTunes App Store, Blackberry App World, Amazon Appstore, Nook Books, Google Play and/or the Windows App
            Store (“App Store”)) , you will need to register an account with and provide certain personal information to the App Store, if
            you have not already done so. You will be able to select how the App Store and Liverton can use that information for marketing
            purposes. You can update your marketing preferences at any time by following the instructions to amend your details, normally in
            the account management section of the App Store. We will only use your information in accordance with your instructions and as
            set out in this privacy policy.
          </li>
          <li>
            Information you provide upon entering a promotion or competition run in an App: if you enter a promotion or competition we will
            use your personal information in accordance with the terms of the promotion or competition.
          </li>
          <li>
            Information you provide when taking part in a poll or survey run in an App: if you take part in a poll or survey run in an App
            we will use your personal information in accordance with the terms of the poll or survey.
          </li>
          <li>
            Demographic and other data: the App uses cookies and other tracking technology to collect information about your use of our App,
            information about your device such as your device ID, IP address and browser type, demographic data and, if you arrived at the
            App via a link from a site other than the relevant App Store, the URL of the linking page. It may also collect your name and
            email address. The data collected through cookies and other tracking technology may be transferred to third parties. For more
            information on how this App uses cookies and other tracking technology, see our cookie policy.
          </li>
          <li>
            Email communications: we use web beacons in our emails to track the success of our marketing campaigns. Our web beacons donâ€™t
            store any information on your computer but, by communicating with our cookies on your computer, they can tell us when you have
            opened an email from us. We may keep track of the emails that we send you. We also keep a record of what communications you have
            selected to receive or not to receive. If you would like to opt out of receiving such emails, please click the “unsubscribe”
            link in the email and follow the instructions.
          </li>
        </ul>
        <h3>
          <b>4. How we use the information that we collect</b>
        </h3>
        <p>
          The relevant App Store owns the information it collects when you purchase this App through the App Store. They may share some of
          that information with us, depending on the selections you have made. We will use it, as applicable, to:
        </p>
        <ul>
          <li>
            provide you with services or information that you have requested or products or services that you have ordered, such as Apps and
            subscriptions to Apps
          </li>
          <li>confirm or fulfill an order you have submitted</li>
          <li>inform you if you have won a prize</li>
          <li>monitor or improve the performance of, and products and services available through, this App</li>
          <li>
            monitor compliance with our terms and conditions of use of the App, enforce our rights and protect the safety of others
            including investigating and if necessary removing any content about which we receive a complaint
          </li>
          <li>
            send you promotional materials or special offers on our behalf or on behalf of our group companies and marketing partners. If
            you do not want to receive this marketing information you can notify us of this as part of the App Store registration process
            and/or App purchase process. You can stop receiving this information at any time by following the unsubscribe instructions on
            the correspondence you receive or by amending your marketing preferences, normally in the App Store account management section
          </li>
          <li>for any purpose if we are required to do so by any law or other regulatory or government authority</li>
        </ul>
        <p>
          By providing us with your personal information, you consent to us: (a) processing your information as set out in this privacy
          policy and in any other manner you expressly consent to; (b) processing your sensitive personal data, such as health data (if
          provided); and (c) transferring your information to countries or jurisdictions which do not provide the same level of data
          protection as NZ, if necessary for these purposes. If we do make such a transfer, we will, if appropriate, put a contract in place
          to ensure that your information is protected.
        </p>

        <h3>
          <b>5. Who we may share your personal information with</b>
        </h3>
        <p>
          <b>
            <i>Agents:</i>
          </b>{' '}
          we use third parties to perform certain functions on our behalf including fulfilling orders, sending emails, organising and
          managing competitions, promotions, surveys or polls, removing repetitive information from customer lists, App analysis, data
          analysis and processing credit card payments. They will have access to your personal information if necessary to perform their
          function but they cannot use it or disclose it for any other purpose, unless you have specifically given them consent to do so.
        </p>
        <p>
          <b>
            <i>Aggregate Information:</i>
          </b>{' '}
          we may share aggregate, non-personally identifiable information, such as demographics and App usage statistics, with advertisers,
          sponsors and other organisations.
        </p>
        <p>
          <b>
            <i>Third-Party Ad Serving Services:</i>
          </b>{' '}
          we or the App Store may use a third-party network advertiser to serve advertisements within the App. Network advertisers are third
          parties that display advertisements based on the category the App falls into. Third-party ad serving enables us to target
          advertisements to you for products or services you might be interested in. This Appâ€™s advertisers and/or sponsors may themselves
          set and access their own cookies on your device if you choose to have your cookies enabled in your browser.
        </p>
        <p>
          <b>
            <i>Third Party Analytics Services:</i>
          </b>{' '}
          we may use the Google Analytics, Flurry or other analytics services to anonymously collect and analyse which pages of the App are
          viewed and for how long and which links are followed, so that we can provide more content which is of interest to you.
        </p>

        <h3>
          6. <b>Maintenance and storage of information:</b>
        </h3>
        <p>
          Your information is collected through the relevant App Store and certain information is passed on to us. We Store that information
          on servers which are owned and maintained by or on behalf of Liverton and its group companies. We may keep your information for a
          reasonable period for the purposes set out in this privacy policy.
        </p>

        <h3>
          <b>7. Links to other websites:</b>
        </h3>
        <p>
          This App may contain links to other websites and we may display advertisements from third parties in our App. We may monetise some
          of these links through the use of third party affiliate programmes. Notwithstanding such affiliate programmes, we are not
          responsible for the content or privacy policies of these websites or for third party advertisers, even if they use the Appâ€™s
          logo as part of a co-branding agreement, nor for the way in which information about their users is treated. Before submitting any
          personal information to such websites, you should check their privacy policy.
        </p>

        <h3>
          <b>8. Security:</b>
        </h3>
        <p>
          Liverton will adopt appropriate security procedures to help prevent unauthorised access to your information. Neither Liverton nor
          any of its group companies shall be liable for any attempt to hack or crack or otherwise gain access to any of your information on
          servers owned and maintained by or on behalf of Liverton and its group companies.
        </p>

        <h3>
          <b>9. A note for parents concerning privacy:</b>
        </h3>
        <p>
          This App and the App Store is for a general audience although age gating may be set by the relevant App Store based upon
          information we provide when we submit the App to the relevant App Store. If, in the Liverton, we collect personally identifiable
          information from children through an App Store, we will do so in compliance with all relevant laws and regulations including,
          without limitation, obtaining parental consent where necessary. The Internet offers children wonderful educational and
          entertainment resources. Your guidance and involvement are essential to help ensure that children have a safe and rewarding online
          experience.
        </p>

        <h3>
          <b>10. Policy changes:</b>
        </h3>
        <p>
          By using this App you agree to the terms of this privacy policy, our cookies policy and our terms of use. We may amend this
          privacy policy and/or our cookies policy and/or the terms of use from time to time. If we do so, an updated version will be
          available through a link in the App. You will be bound by the new terms upon your continued use of this App.
        </p>

        <h3>
          <b>11. Questions about this App and the information we hold about you:</b>
        </h3>
        <p>
          If you have any general questions about this App or the information we have collected about you and how we use it, you can contact
          the Liverton Team at the following&nbsp;<a href="https://liverton.com/contact/">link.</a>
        </p>
      </Box>
    </Paper>
  );
}
