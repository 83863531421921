import React, { useEffect, useMemo, useState } from 'react';
import API from '@aws-amplify/api';
import FormSelect from '../FormSelect';
import { Grid } from '@material-ui/core';

function mapSelectValues(values, emptyOption) {
  const result = values.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  return emptyOption ? [{ label: 'No State', value: -1 }, ...result] : result;
}

export default function FormCountryAndDivisionSelect({
  countryId,
  setCountryId,
  countryLabel = 'Country',
  divisionId,
  setDivisionId,
  divisionLabel = 'Select State/Area',
}) {
  const [countries, setCountries] = useState([]);

  const selectableDivisions = useMemo(() => {
    const selectedCountry = countries.find((c) => c.id === countryId);
    return selectedCountry ? mapSelectValues(selectedCountry.divisions, true) : [];
  }, [countryId, countries]);

  const selectableCountries = useMemo(() => {
    return mapSelectValues(countries);
  }, [countries]);

  useEffect(() => {
    API.get('PrivateAPI', '/api/private/country', {})
      .then((response) => {
        setCountries(response);
      })
      .catch((error) => {
        console.warn('Unable to get countries due to error', error);
      });
  }, []);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormSelect label={countryLabel} value={countryId} error={!countryId} setValue={setCountryId} options={selectableCountries} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormSelect label={divisionLabel} value={divisionId} setValue={setDivisionId} options={selectableDivisions} />
      </Grid>
    </>
  );
}
