import * as Actions from '../../../actions/app/linkedSites/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  selectedItem: null,
};

export default function appLinkedSitesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.LINKED_SITES_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.LINKED_SITES_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload,
        error: null,
      };
    case Actions.LINKED_SITES_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.LINKED_SITES_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        data: [],
      };
    case Actions.LINKED_SITES_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
}
