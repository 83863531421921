import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const PURCHASE_EXTERNAL_IN_PROGRESS = 'PURCHASE_EXTERNAL_IN_PROGRESS';
export const PURCHASE_EXTERNAL_SUCCESS = 'PURCHASE_EXTERNAL_SUCCESS';
export const PURCHASE_EXTERNAL_FAILURE = 'PURCHASE_EXTERNAL_FAILURE';

export const PURCHASE_RESET_STATE = 'PURCHASE_RESET_STATE';

const PurchaseActions = {
  ...actionCreator.createActions('Purchase', 'PURCHASE', '/api/payment/filter'),
  processExternalPayment: (payload) => async (dispatch) => {
    try {
      dispatch({ type: PURCHASE_EXTERNAL_IN_PROGRESS });
      const response = await API.post('PrivateAPI', `/api/payment/admin/cnp`, { body: payload });
      await dispatch({ type: PURCHASE_EXTERNAL_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: PURCHASE_EXTERNAL_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  processRefund: (payload) => async (dispatch) => {
    try {
      dispatch({ type: PURCHASE_EXTERNAL_IN_PROGRESS });
      const response = await API.post('PrivateAPI', `/api/payment/admin/refund`, { body: payload });
      await dispatch({ type: PURCHASE_EXTERNAL_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: PURCHASE_EXTERNAL_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  resetState: () => async (dispatch) => {
    dispatch({ type: PURCHASE_RESET_STATE });
  },
};
export default PurchaseActions;
