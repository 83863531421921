import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import FoodDiscountActions from '../../../../redux/actions/inRoom/ordering/foodDiscount/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Check, DeleteOutlined } from '@material-ui/icons';
import { PortionType } from '../../../../../support/PortionTypes';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodDiscountActions.delete(value, `/api/private/portal/food/discount/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'discountName',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'discountCode',
    label: 'Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External Id',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'discountAmount',
    label: 'Amount',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      const discountAmount = value.discountAmount;
      return value.discountType === PortionType.FIXED ? '$ ' + discountAmount : discountAmount + '%';
    },
  },
  {
    id: 'discountType',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'discountInclusion',
    label: 'Inclusion',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.enabled) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodDiscountList() {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const entityList = useSelector((state) => state.foodDiscountReducer.entityList);
  const processing = useSelector((state) => state.foodDiscountReducer.processing);
  const page = useSelector((state) => state.foodDiscountReducer.page);
  const error = useSelector((state) => state.foodDiscountReducer.error);

  return (
    <AutoTable
      title="Food Discount List"
      subheader={'A Food Discount contains fixed or percentage amount to be included in a check'}
      handleRefreshReport={() => dispatch(FoodDiscountActions.fetch(0, 20, {}, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodDiscountActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodDiscountActions.fetch(newPage, pageSize, {}, { vendorId: vendorId }))}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_DISCOUNT"
      createNewURL="/food/discount/new"
      detailURL="/food/discount/view/{id}"
      idColumn={'id'}
      data={entityList}
    />
  );
}
