import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import FormField from '../../../../components/form/FormField';
import FileImageSelector from '../../../../components/file/FileImageSelector';

export default function AppleWallet({ setValue }) {
  const selectedItem = useSelector((state) => state.walletReducer.selectedItem);

  const [applePassTypeIdentifier, setApplePassTypeIdentifier] = React.useState(
    selectedItem?.applePassTypeIdentifier || 'pass.com.smartcheck.mobile.wallet'
  );
  const [appleTeamIdentifier, setAppleTeamIdentifier] = React.useState(selectedItem?.appleTeamIdentifier || 'M43988H9Z9');
  const [applePassCertificate, setApplePassCertificate] = React.useState(selectedItem?.applePassCertificate || '');
  const [appleWwdrCertificate, setAppleWwdrCertificate] = React.useState(selectedItem?.appleWwdrCertificate || '');
  const [applePassCerExpiry] = React.useState(selectedItem?.applePassCerExpiry || '');
  const [appleWwdrCerExpiry] = React.useState(selectedItem?.appleWwdrCerExpiry || '');

  useEffect(() => {
    setValue({
      applePassTypeIdentifier,
      appleTeamIdentifier,
      applePassCertificate,
      appleWwdrCertificate,
    });
  }, [applePassCertificate, applePassTypeIdentifier, appleTeamIdentifier, setValue, appleWwdrCertificate]);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormField
          label={'Pass Type Identifier'}
          value={applePassTypeIdentifier}
          setValue={setApplePassTypeIdentifier}
          placeholder="Enter Pass Type Identifier"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormField
          label={'Team Identifier'}
          value={appleTeamIdentifier}
          setValue={setAppleTeamIdentifier}
          placeholder="Enter Apple Team Identifier"
        />
      </Grid>

      <FileImageSelector
        sourceItem={selectedItem}
        sourceItemProperty={'applePassCertificate'}
        displayName={'Pass Certificate(.p12)'}
        onSelect={(file) => setApplePassCertificate(file)}
        accept={'.p12'}
      />

      <FileImageSelector
        sourceItem={selectedItem}
        sourceItemProperty={'appleWwdrCertificate'}
        displayName={'WWDR Certificate(.cer)'}
        onSelect={(file) => setAppleWwdrCertificate(file)}
        accept={'.cer'}
      />

      {applePassCertificate && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormField label={'Pass Certificate Expiry'} value={applePassCerExpiry} />
        </Grid>
      )}

      {appleWwdrCertificate && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormField label={'WWDR Certificate Expiry'} value={appleWwdrCerExpiry} />
        </Grid>
      )}
    </>
  );
}
