import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'reference',
    label: 'Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'referenceExternal',
    label: 'Reference External ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'completed',
    label: 'Completed',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'outcome',
    label: 'Outcome',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'attemptCount',
    label: 'Attempt Count',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastAttempt',
    label: 'Last Attempt',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'queueTask',
    label: 'Queue Task',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'startWindow',
    label: 'Start Window',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'endWindow',
    label: 'End Window',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ReservationQueueList() {
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/reservation-queue?siteId=${selectedReservation.siteId}&reservationNumber=${selectedReservation?.reservationNumber}`
  );

  return (
    <AutoTable
      title="Reservation Tasks"
      subheader={'Tasks in queue for this reservation'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="RESERVATION_QUEUE"
      backHidden
      data={data}
      lastActionType={'RESERVATION_QUEUE'}
    />
  );
}
