import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Actions from '../../../../../redux/actions/notificationTemplate/infoGraphic/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../components/form/FormField';
import FormSelect from '../../../../../../components/form/FormSelect';
import { NotificationInfographicType } from '../../notification/NotificationType';
import FileImageSelector from '../../../../../../components/file/FileImageSelector';
import toBase64 from '../../../../../../support/toBase64Converter';
import { PositionType } from '../../notification/PositionType';

export default function InfographicEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const lastActionType = useSelector((state) => state.notificationInfographicReducer.lastActionType);
  const error = useSelector((state) => state.notificationInfographicReducer.error);
  const selectedItem = useSelector((state) => state.notificationInfographicReducer.selectedItem);
  const templateItem = useSelector((state) => state.notificationTemplateReducer.selectedItem);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [infographicType, setInfographicType] = React.useState(selectedItem?.infographicType || '');
  const [position, setPosition] = React.useState(selectedItem?.position || '');
  const [defaultInfographicImage, setDefaultInfographicImage] = React.useState(selectedItem?.infographicImage || '');
  const [infographicImage, setInfographicImage] = React.useState(null);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'NOTIFICATION_INFOGRAPHIC_ADD_SUCCESS' || lastActionType === 'NOTIFICATION_INFOGRAPHIC_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const infographicBase64Image = await toBase64(infographicImage);
    const payload = {
      ...selectedItem,
      name,
      infographicType,
      position,
      infographicImage: infographicBase64Image,
      infographicImageName: infographicImage ? infographicImage.name : defaultInfographicImage,
      templateId: templateItem.id,
    };

    if (selectedItem?.id) {
      dispatch(Actions.update(payload, null, true));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady = name && infographicType && position;

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeaderWithControls
          header={'Infographic'}
          subheader={'Please fill in the infographic details to add the graphic in an email template'}
          buttonName={selectedItem?.id ? 'Update' : 'Add'}
          onClick={handleSubmit}
          disabled={!isFormReady}
        />
        <CardContent>
          {error && <Alert severity="error">{`Unable to update infographic - ${error.message}`}</Alert>}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Name'} value={name} setValue={setName} placeholder="Name" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect
                label={'Infographic Type'}
                value={infographicType}
                setValue={setInfographicType}
                options={NotificationInfographicType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect
                label={'Position'}
                value={position}
                setValue={setPosition}
                options={Object.values(PositionType).map((value) => {
                  return { value: value, label: value };
                })}
              />
            </Grid>

            <FileImageSelector
              sourceItem={selectedItem}
              sourceItemProperty={'infographicImage'}
              displayName={'Infographic Image'}
              onSelect={(file) => setInfographicImage(file)}
              imageCleared={(value) => {
                if (value) {
                  setInfographicImage(null);
                  setDefaultInfographicImage(null);
                }
              }}
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
