import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppUserList from './private/appUsers/AppUserList';
import AppUserDetail from './private/appUsers/AppUserDetail';
import UserList from './private/users/UserList';
import UserDetail from './private/users/UserDetail';
import CorporationList from './private/corporations/CorporationList';
import CorporationEdit from './private/corporations/CorporationEdit';
import PlatformList from './private/platform/PlatformList';
import PlatformEdit from './private/platform/PlatformEdit';
import SiteList from './private/site/SiteList';
import SiteEdit from './private/site/SiteEdit';
import SiteFeatures from './private/site/SiteFeatures';
import InvalidURL from '../../support/InvalidURL';
import SecureRoute from '../../components/SecureRoute';
import UserRole from '../../support/UserRole';
import SiteSystemList from './private/site/system/SiteSystemList';
import EditSystem from './private/site/system/EditSystem';
import SitePackageUpsellList from './private/site/upsell/SitePackageUpsellList';
import SitePackageUpsell from './private/site/upsell/SitePackageUpsell';
import SiteRoomTypeList from './private/site/roomTypes/SiteRoomTypeList';
import SiteRoomType from './private/site/roomTypes/SiteRoomType';
import SiteAmenityList from './private/site/amenity/SiteAmenityList';
import SiteAmenityEdit from './private/site/amenity/SiteAmenityEdit';
import ThemeList from './private/theme/ThemeList';
import ThemeEditContainer from './private/theme/ThemeEditContainer';
import ThemeTermsEdit from './private/theme/ThemeTermsEdit';
import NotificationTemplateList from './private/site/template/NotificationTemplateList';
import InfographicEdit from './private/site/template/infographic/InfographicEdit';
import HotelReservationList from './private/reservation/hotel/HotelReservationList';
import HotelReservationEdit from './private/reservation/hotel/HotelReservationEdit';
import AccessReservationCreate from './private/reservation/access/AccessReservationCreate';
import AccessReservationDetail from './private/reservation/access/AccessReservationDetail';
import AppList from './private/app/AppList';
import Dashboard from './private/dashboard/Dashboard';
import PaymentProviderList from './private/paymentProvider/PaymentProviderList';
import PaymentProviderEdit from './private/paymentProvider/PaymentProviderEdit';
import Chat from './private/chat/Chat';
import HotelReservationArrivals from './private/reservation/hotel/HotelReservationArrivals';
import HotelReservationInHouse from './private/reservation/hotel/HotelReservationInHouse';
import HotelReservationDeparted from './private/reservation/hotel/HotelReservationDeparted';
import MyAccountEdit from './private/account/MyAccountEdit';
import PaymentTypeList from './private/site/mappings/PaymentTypeList';
import AccessReservationUpcomingList from './private/reservation/access/AccessReservationUpcomingList';
import AccessReservationList from './private/reservation/access/AccessReservationList';
import AccessReservationPastList from './private/reservation/access/AccessReservationPastList';
import PrivacyPolicy from './public/policy/PrivacyPolicy';
import TermsOfUse from './public/policy/TermsOfUse';
import RuleList from './private/site/rules/RuleList';
import Rule from './private/site/rules/Rule';
import RuleCondition from './private/site/rules/RuleCondition';
import RuleAction from './private/site/rules/RuleAction';
import AppEdit from './private/app/AppEdit';
import AppTheme from './private/app/AppTheme';
import SiteRoomList from './private/site/doors/SiteRoomList';
import SiteRoomEdit from './private/site/doors/SiteRoomEdit';
import LicenseList from './private/license/LicenseList';
import LicenseEdit from './private/license/LicenseEdit';
import LicenseUpload from './private/license/LicenseUpload';
import ReservationFieldList from './private/site/mappings/ReservationFieldList';
import NotificationTemplateChoice from './private/site/template/NotificationTemplateChoice';
import ReservationReport from './private/reservation/report/ReservationReport';
import MinibarList from './private/site/minibar/MinibarList';
import MinibarItem from './private/site/minibar/MinibarItem';
import FeedbackOptionList from './private/site/feedback/FeedbackOptionList';
import FeedbackOption from './private/site/feedback/FeedbackOption';
import GuestRequestList from './private/reservation/hotel/guestRequests/GuestRequestList';
import GuestRequestEdit from './private/reservation/hotel/guestRequests/GuestRequestEdit';
import ReservationTrackingEdit from './private/reservation/hotel/tracking/reservationTrackingEdit';
import PurchaseItemList from './private/reservation/hotel/purchase/PurchaseItemList';
import CategoryList from './private/site/compendium/category/CategoryList';
import CategoryEdit from './private/site/compendium/category/CategoryEdit';
import ArticleEdit from './private/site/compendium/article/ArticleEdit';
import ImageEdit from './private/site/compendium/article/image/ImageEdit';
import AppUserAddress from './private/appUsers/appUserAddress/AppUserAddress';
import AppReservationCard from './private/appUsers/appUserReservationCard/AppReservationCard';
import SecondaryGuestEdit from './private/reservation/hotel/guests/SecondaryGuestEdit';
import VendorEdit from './private/vendor/VendorEdit';
import VendorList from './private/vendor/VendorList';
import ActivityEdit from './private/activity/ActivityEdit';
import ActivityListMapping from './private/activity/ActivityListMapping';
import RoomSelectionEdit from './private/site/roomSelection/RoomSelectionEdit';
import RoomSelectionFloors from './private/site/roomSelection/RoomSelectionFloors';
import ReservationReportEdit from './private/reservation/report/ReservationReportEdit';
import NotificationScheduleList from './private/site/notification/NotificationScheduleList';
import NotificationScheduleEdit from './private/site/notification/NotificationScheduleEdit';
import NotificationSchemeList from './private/scheme/notifications/NotificationSchemeList';
import NotificationSchemeEdit from './private/scheme/notifications/NotificationSchemeEdit';
import WalletList from './private/wallet/WalletList';
import WalletDetails from './private/wallet/WalletDetails';
import ReportSchedule from './private/reservation/report/schedule/ReportSchedule';
import ReportScheduleEdit from './private/reservation/report/schedule/ReportScheduleEdit';
import SecurityEventSettingList from './private/security/eventSetting/SecurityEventSettingList';
import SecurityEventSettingEdit from './private/security/eventSetting/SecurityEventSettingEdit';
import SecurityRoomList from './private/security/rooms/SecurityRoomList';
import SecurityRoomEdit from './private/security/rooms/SecurityRoomEdit';
import SecurityRoomMappingList from './private/security/roomMapping/SecurityRoomMappingList';
import SecurityRoomMappingEdit from './private/security/roomMapping/SecurityRoomMappingEdit';
import SecurityCardholderList from './private/security/cardholder/SecurityCardholderList';
import SecurityEventList from './private/security/event/SecurityEventList';
import FacialRecognitionEdit from './private/site/facialRecognition/FacialRecognitionEdit';
import FoodVendorList from './private/inRoom/ordering/FoodVendorList';
import FoodVendorEdit from './private/inRoom/ordering/FoodVendorEdit';
import FoodVendorScheduleList from './private/inRoom/ordering/FoodVendorScheduleList';
import FoodVendorScheduleEdit from './private/inRoom/ordering/FoodVendorScheduleEdit';
import FoodMenuList from './private/inRoom/ordering/FoodMenuList';
import FoodMenuEdit from './private/inRoom/ordering/FoodMenuEdit';
import FoodMenuScheduleList from './private/inRoom/ordering/FoodMenuScheduleList';
import FoodMenuScheduleEdit from './private/inRoom/ordering/FoodMenuScheduleEdit';
import FoodItemList from './private/inRoom/ordering/FoodItemList';
import FoodItemEdit from './private/inRoom/ordering/FoodItemEdit';
import FoodInfoList from './private/inRoom/ordering/FoodInfoList';
import FoodInfoEdit from './private/inRoom/ordering/FoodInfoEdit';
import FoodModifierGroupList from './private/inRoom/ordering/FoodModifierGroupList';
import FoodModifierGroupEdit from './private/inRoom/ordering/FoodModifierGroupEdit';
import FoodModifierList from './private/inRoom/ordering/FoodModifierList';
import FoodModifierEdit from './private/inRoom/ordering/FoodModifierEdit';
import FoodOrderLocationList from './private/inRoom/ordering/FoodOrderLocationList';
import FoodOrderLocationEdit from './private/inRoom/ordering/FoodOrderLocationEdit';
import FoodTaxEdit from './private/inRoom/ordering/FoodTaxEdit';
import FoodTaxList from './private/inRoom/ordering/FoodTaxList';
import FoodChargeList from './private/inRoom/ordering/FoodChargeList';
import FoodChargeEdit from './private/inRoom/ordering/FoodChargeEdit';
import FoodDiscountList from './private/inRoom/ordering/FoodDiscountList';
import FoodDiscountEdit from './private/inRoom/ordering/FoodDiscountEdit';
import FoodPosPaymentTypeList from './private/inRoom/ordering/FoodPosPaymentTypeList';
import GuestOrderList from './private/reservation/hotel/guestOrders/GuestOrderList';
import GuestOrderView from './private/reservation/hotel/guestOrders/GuestOrderView';
import RequestCategoryServiceEdit from './private/site/notification/RequestCategoryServiceEdit';
import RequestSubCategoryEdit from './private/site/notification/RequestSubCategoryEdit';
import FoodCorpFilterList from './private/inRoom/ordering/FoodCorpFilterList';
import FoodCorpFilterEdit from './private/inRoom/ordering/FoodCorpFilterEdit';
import FoodPriceScheduleList from './private/inRoom/ordering/FoodPriceScheduleList';
import FoodPriceScheduleEdit from './private/inRoom/ordering/FoodPriceScheduleEdit';
import { SystemCalendarEventsView } from './private/site/system/calendar/SystemCalendarEventsView';
import { SystemCalendarType } from '../../support/SystemCalendar';
import SecureRouteWithProps from '../../components/SecureRouteWithProps';

export default function AppRoutes() {
  const selectedSection = useSelector((state) => state.accessReducer.selectedSection);
  const selectedSectionRedirect = selectedSection && selectedSection.path ? selectedSection.path : '/reservation/dashboard';
  return (
    <Switch>
      <Redirect from="/" to={'/dashboard'} exact />
      <Redirect from="/index.html" to={selectedSectionRedirect} exact />
      <Redirect from="/login" to={selectedSectionRedirect} exact />
      <Redirect from="/logout" to="/login" exact />
      <Route path="/privacy" exact component={PrivacyPolicy} />
      <Route path="/terms" exact component={TermsOfUse} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/my-account" exact component={MyAccountEdit} />
      <Route path="/reservation/dashboard" exact component={Dashboard} />
      <Route path="/reservation/hotel" exact component={HotelReservationList} />
      <Route path="/reservation/hotel/arrivals" exact component={HotelReservationArrivals} />
      <Route path="/reservation/hotel/current" exact component={HotelReservationInHouse} />
      <Route path="/reservation/hotel/past" exact component={HotelReservationDeparted} />
      <Route path="/reservation/hotel/new" exact component={HotelReservationEdit} />
      <Route path="/reservation/hotel/view/:id" exact component={HotelReservationEdit} />
      <Route path="/reservation/access/current" exact component={AccessReservationList} />
      <Route path="/reservation/access/upcoming" exact component={AccessReservationUpcomingList} />
      <Route path="/reservation/access/past" exact component={AccessReservationPastList} />
      <Route path="/reservation/access/register" exact component={AccessReservationCreate} />
      <Route path="/reservation/access/view/:id?" exact component={AccessReservationDetail} />
      <Route path="/reservation/report/reservation" exact component={ReservationReport} />
      <Route path="/reservation/report/reservation/view/:id" exact component={ReservationReportEdit} />
      <Route path="/reservation/report/schedule" exact component={ReportSchedule} />
      <Route path="/reservation/report/schedule/new" exact component={ReportScheduleEdit} />
      <Route path="/reservation/report/schedule/view/:id" exact component={ReportScheduleEdit} />
      <Route path="/reservation/specialRequest/new" exact render={(props) => <GuestRequestList fetchAll={true} {...props} />} />
      <Route path="/reservation/specialRequest/view/:id" exact component={GuestRequestEdit} />
      <Route path="/reservation/tracking/view/:id" exact component={ReservationTrackingEdit} />
      <Route path="/reservation/orders" exact component={GuestOrderList} />
      <Route path="/reservation/orders/view/:id" exact component={GuestOrderView} />
      <Route path="/app-user/address/new" exact component={AppUserAddress} />
      <Route path="/app-user/address/view/:id" exact component={AppUserAddress} />
      <Route path="/app-user/reservation-card/view" exact component={AppReservationCard} />
      <Route path="/reservation/purchase/view/:id" exact component={PurchaseItemList} />
      <SecureRoute path="/reservation/secondary/new" exact component={SecondaryGuestEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/app-users" exact component={AppUserList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/app-users/view/:id" exact component={AppUserDetail} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/app-users/new" exact component={AppUserDetail} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/users" exact component={UserList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/users/view/:id" exact component={UserDetail} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/account/users/new" exact component={UserDetail} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/corporations" exact component={CorporationList} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/corporations/view/:id" exact component={CorporationEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/corporations/new" exact component={CorporationEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/vendor" exact component={VendorList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/vendor/view/:id" exact component={VendorEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/vendor/new" exact component={VendorEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/activity/view" exact component={ActivityEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/activity/new" exact component={ActivityEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/activity/mapping" exact component={ActivityListMapping} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/platforms" exact component={PlatformList} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/platforms/view/:id" exact component={PlatformEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/platforms/new" exact component={PlatformEdit} role={UserRole.ROLE_SUPER} />
      <SecureRouteWithProps
        path="/calendar"
        exact
        component={<SystemCalendarEventsView calendarType={SystemCalendarType.GLOBAL} />}
        role={UserRole.ROLE_SUPER}
      />
      <SecureRouteWithProps
        path="/organisation/calendar"
        exact
        component={<SystemCalendarEventsView calendarType={SystemCalendarType.ORGANISATION} />}
        role={UserRole.ROLE_CORP_ADMIN}
      />
      <SecureRoute path="/sites" exact component={SiteList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/sites/view/:id" exact component={SiteEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/sites/new" exact component={SiteEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/site/features" exact component={SiteFeatures} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRouteWithProps
        path="/site/calendar"
        exact
        component={<SystemCalendarEventsView calendarType={SystemCalendarType.SITE} />}
        role={UserRole.ROLE_SITE_ADMIN}
      />
      <SecureRoute path="/site/systems" exact component={SiteSystemList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/site/systems/view/:id?" exact component={EditSystem} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/site/upsell" exact component={SitePackageUpsellList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/upsell/view/:id?" exact component={SitePackageUpsell} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/roomTypes" exact component={SiteRoomTypeList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/roomTypes/view/:id?" exact component={SiteRoomType} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/payment-types" exact component={PaymentTypeList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/reservation-fields" exact component={ReservationFieldList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/facialRecognition" exact component={FacialRecognitionEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rooms" exact component={SiteRoomList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rooms/view/:id?" exact component={SiteRoomEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/amenities" exact component={SiteAmenityList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/amenities/new" exact component={SiteAmenityEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/amenities/view/:id" exact component={SiteAmenityEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/templates" exact component={NotificationTemplateList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/templates/new/:templateType?" exact component={NotificationTemplateChoice} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/templates/infographic/new" exact component={InfographicEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/templates/infographic/view/:id" exact component={InfographicEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/templates/view/:id?" exact component={NotificationTemplateChoice} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/notifications" exact component={NotificationScheduleList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/notifications/new" exact component={NotificationScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/notifications/view/:id?" exact component={NotificationScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/specialRequestCategory/new" exact component={RequestCategoryServiceEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute
        path="/site/specialRequestCategory/view/:id?"
        exact
        component={RequestCategoryServiceEdit}
        role={UserRole.ROLE_SITE_ADMIN}
      />
      <SecureRoute
        path="/site/specialRequestCategory/subCategory/data"
        exact
        component={RequestSubCategoryEdit}
        role={UserRole.ROLE_SITE_ADMIN}
      />
      <SecureRoute path="/site/rules" exact component={RuleList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/new" exact component={Rule} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/view/:id?" exact component={Rule} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/condition/new" exact component={RuleCondition} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/condition/view/:id?" exact component={RuleCondition} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/action/new" exact component={RuleAction} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/rules/action/view/:id?" exact component={RuleAction} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/minibar" exact component={MinibarList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/minibar/new" exact component={MinibarItem} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/minibar/view/:id" exact component={MinibarItem} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/themes" exact component={ThemeList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/themes/new" exact component={ThemeEditContainer} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/themes/view/:id" exact component={ThemeEditContainer} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/themes/terms/new" exact component={ThemeTermsEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/themes/view/terms/view/:id" component={ThemeTermsEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/apps" exact component={AppList} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/apps/new" exact component={AppEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/apps/view/:id" exact component={AppEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/apps/theme" exact component={AppTheme} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/payment-providers" exact component={PaymentProviderList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/payment-providers/new" exact component={PaymentProviderEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/payment-providers/view/:id" exact component={PaymentProviderEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/scheme/notifications" exact component={NotificationSchemeList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/scheme/notifications/new" exact component={NotificationSchemeEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/scheme/notifications/view/:id" exact component={NotificationSchemeEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/themes" exact component={ThemeList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/licenses" exact component={LicenseList} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/licenses/new" exact component={LicenseUpload} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/licenses/view/:id" exact component={LicenseEdit} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/wallet" exact component={WalletList} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/wallet/view/:id?" exact component={WalletDetails} role={UserRole.ROLE_SUPER} />
      <SecureRoute path="/site/feedback" exact component={FeedbackOptionList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/feedback/new" exact component={FeedbackOption} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/feedback/view/:id" exact component={FeedbackOption} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/category" exact component={CategoryList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/category/new" exact component={CategoryEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/category/view/:id?" exact component={CategoryEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/article/new" exact component={ArticleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/article/view/:id?" exact component={ArticleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/article/image/new" exact component={ImageEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/compendium/article/image/view/:id?" exact component={ImageEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/roomSelection" exact component={RoomSelectionEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/site/roomSelection/floors" exact component={RoomSelectionFloors} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/event-setting" exact component={SecurityEventSettingList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/event-setting/view/:id" exact component={SecurityEventSettingEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/event-setting/new" exact component={SecurityEventSettingEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/rooms" exact component={SecurityRoomList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/rooms/view/:id?" exact component={SecurityRoomEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/rooms/new" exact component={SecurityRoomEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/room-mapping" exact component={SecurityRoomMappingList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/room-mapping/view/:id?" exact component={SecurityRoomMappingEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/room-mapping/new" exact component={SecurityRoomMappingEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/cardholders" exact component={SecurityCardholderList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/security/events" exact component={SecurityEventList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor" exact component={FoodVendorList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor/view/:id" exact component={FoodVendorEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor/new" exact component={FoodVendorEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor/schedule" exact component={FoodVendorScheduleList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor/schedule/view/:id" exact component={FoodVendorScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/vendor/schedule/new" exact component={FoodVendorScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu" exact component={FoodMenuList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu/view/:id" exact component={FoodMenuEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu/new" exact component={FoodMenuEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu/schedule" exact component={FoodMenuScheduleList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu/schedule/view/:id" exact component={FoodMenuScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/menu/schedule/new" exact component={FoodMenuScheduleEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/item" exact component={FoodItemList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/item/view/:id" exact component={FoodItemEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/item/new" exact component={FoodItemEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/info" exact component={FoodInfoList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/info/view/:id" exact component={FoodInfoEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/info/new" exact component={FoodInfoEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier/group" exact component={FoodModifierGroupList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier/group/view/:id" exact component={FoodModifierGroupEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier/group/new" exact component={FoodModifierGroupEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier" exact component={FoodModifierList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier/view/:id" exact component={FoodModifierEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/modifier/new" exact component={FoodModifierEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/order/location" exact component={FoodOrderLocationList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/order/location/:action/:id?" exact component={FoodOrderLocationEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/tax" exact component={FoodTaxList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/tax/view/:id" exact component={FoodTaxEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/tax/new" exact component={FoodTaxEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/charge" exact component={FoodChargeList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/charge/view/:id" exact component={FoodChargeEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/charge/new" exact component={FoodChargeEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/discount" exact component={FoodDiscountList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/discount/view/:id" exact component={FoodDiscountEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/discount/new" exact component={FoodDiscountEdit} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/payment-types" exact component={FoodPosPaymentTypeList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/corporation/filters" exact component={FoodCorpFilterList} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/food/corporation/filters/view/:id" exact component={FoodCorpFilterEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/food/corporation/filters/new" exact component={FoodCorpFilterEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/food/price/schedule" exact component={FoodPriceScheduleList} role={UserRole.ROLE_SITE_ADMIN} />
      <SecureRoute path="/food/price/schedule/view/:id" exact component={FoodPriceScheduleEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <SecureRoute path="/food/price/schedule/new" exact component={FoodPriceScheduleEdit} role={UserRole.ROLE_CORP_ADMIN} />
      <Route path="/chat" exact component={Chat} />
      <Route component={InvalidURL} />
    </Switch>
  );
}
