import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const OrderLocationActions = {
  ...actionCreator.createActions('Food Order Location', 'FOOD_ORDER_LOCATION', '/api/private/portal/site/{siteId}/food/order/location'),
  addByVendor: (item, foodVendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_ORDER_LOCATION_ADD_IN_PROGRESS' });
    try {
      const result = await API.post('PrivateAPI', `/api/private/portal/food/vendor/${foodVendorId}/order/location`, { body: item });

      await dispatch({
        type: `FOOD_ORDER_LOCATION_ADD_SUCCESS`,
        payload: result,
      });
    } catch (error) {
      console.warn('Failed to add food order location: ', error);
      dispatch({
        type: `FOOD_ORDER_LOCATION_ADD_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  updateByVendor: (item, locationId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_ORDER_LOCATION_UPDATE_IN_PROGRESS' });
    try {
      const result = await API.put('PrivateAPI', `/api/private/portal/food/order/location/${locationId}`, { body: item });

      await dispatch({
        type: `FOOD_ORDER_LOCATION_UPDATE_SUCCESS`,
        payload: result,
      });
    } catch (error) {
      console.warn('Failed to update food order location: ', error);
      dispatch({
        type: `FOOD_ORDER_LOCATION_UPDATE_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  fetchByFoodVendor: (page, size, params, foodVendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_ORDER_LOCATION_FETCH_IN_PROGRESS' });
    try {
      const requestUrl = Objects.appendParamsUrl(
        params,
        `/api/private/portal/food/vendor/${foodVendorId}/order/location?page=${page || 0}&size=${size || 10}`
      );
      const result = await API.get('PrivateAPI', requestUrl, {});
      await dispatch({
        type: `FOOD_ORDER_LOCATION_FETCH_SUCCESS`,
        payload: {
          ...result,
          page: {
            number: result.number,
            size: result.size,
            totalElements: result.totalElements,
            totalPages: result.totalPages,
          },
        },
      });
    } catch (error) {
      console.warn('Failed to export food order location QR export: ', error);
      dispatch({
        type: `FOOD_ORDER_LOCATION_FETCH_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  exportAll: (siteId, params) => async (dispatch) => {
    await dispatch({ type: 'FOOD_ORDER_LOCATION_EXPORT_IN_PROGRESS' });
    try {
      const requestUrl = Objects.appendParamsUrl(params, `/api/private/portal/site/${siteId}/food/order/location/export`);
      const response = await API.get('PrivateAPI', requestUrl, {});
      await dispatch({ type: `FOOD_ORDER_LOCATION_EXPORT_SUCCESS` });
      Objects.saveAndDownloadContent(`${response.fileName}`, `data:application/zip;name=;base64,${response.file}`);
    } catch (error) {
      console.warn('Failed to export food order location QR export: ', error);
      dispatch({
        type: `FOOD_ORDER_LOCATION_EXPORT_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default OrderLocationActions;
