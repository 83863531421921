import * as DataTypes from '../../../../../../constants/DataTypes';
import Currency from '../../../../../../support/Currency';
import { CheckOutlined, ClearOutlined } from '@material-ui/icons';
import Objects from '../../../../../../support/Objects';
import UserRoleHelper from '../../../../../../support/UserRoleHelper';
import { FoodOrderLocationTypes } from '../../../../../../support/FoodOrderLocationType';
import { Chip } from '@material-ui/core';
import { SpecialRequestEscalationStateLabel } from '../../../../../../support/SpecialRequestEscalationState';
import React from 'react';
import { SpecialRequestStateLabel } from '../../../../../../support/SpecialRequestState';
import { SpecialRequestAdditionalStateLabel } from '../../../../../../support/SpecialRequestAdditionalState';

const PreArrivalColumns = (timezone) => [
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'email',
    label: 'Email Address',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Res Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'arrivalDate',
    label: 'Arrival Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'departureDate',
    label: 'Departure Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'notificationCategory',
    label: 'Notification Category',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_LIST,
    format: function (values) {
      return values.length > 1 ? values.join(', ') : values;
    },
  },
  {
    id: 'checkInState',
    label: 'Check-In Completed',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return 'COMPLETED' === values.checkInState ? <CheckOutlined color={'primary'} /> : <ClearOutlined color={'error'} />;
    },
  },
  {
    id: 'checkOutState',
    label: 'Check-Out Completed',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return 'COMPLETED' === values.checkOutState ? <CheckOutlined color={'primary'} /> : <ClearOutlined color={'error'} />;
    },
  },
  {
    id: 'purchaseResponse',
    label: 'Purchase Response',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'marketCode',
    label: 'Market Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'source',
    label: 'Source',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'origin',
    label: 'Origin',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return values.created ? Objects.convertTimestampToDateTimezone(values.created, timezone) : 'N/A';
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const FeedbackSubmissionColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'feedbackText',
    label: 'Feedback Text',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'feedbackScore',
    label: 'Feedback Score',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'flowName',
    label: 'Flow Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'updated',
    label: 'Updated Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.updated, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const UpsellColumns = (timezone) => [
  {
    id: 'externalReference',
    label: 'External Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'internalReference',
    label: 'Internal Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'offerName',
    label: 'Offer Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return new Currency(value.offerTotal, value.offerCurrency).format();
    },
  },
  {
    id: 'offerType',
    label: 'Offer Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'offerCode',
    label: 'Offer Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'updated',
    label: 'Updated Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.updated, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const SpecialRequestColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'requestNumber',
    label: 'Request Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'state',
    label: 'Guest Contacted',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return SpecialRequestStateLabel[values.state];
    },
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'request',
    label: 'Request',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'note',
    label: 'Note',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'additionalState',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return SpecialRequestAdditionalStateLabel[value.additionalState];
    },
  },
  {
    id: 'escalation',
    label: 'Escalation State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.escalation && value.escalation.length > 0) {
        return value.escalation.map((val) => <Chip key={val} label={SpecialRequestEscalationStateLabel[val]} />);
      }
      return 'No Escalation';
    },
  },
  {
    id: 'created',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'requestDate',
    label: 'Request Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.requestDate, timezone);
    },
  },
  {
    id: 'deliveryTime',
    label: 'Delivery Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      if (values.deliveryTime) {
        return Objects.convertTimestampToDateTimezone(values.deliveryTime, timezone);
      } else {
        return 'Not Required';
      }
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const PaymentColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value?.total ? Currency.transform(value.total).format() : '0.00';
    },
  },
  {
    id: 'purchaseState',
    label: 'Purchase State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'purchaseType',
    label: 'Purchase Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'channel',
    label: 'Channel',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardHolder',
    label: 'Card Holder',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardType',
    label: 'Card Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardExpiry',
    label: 'Card Expiry',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'purchaseResponse',
    label: 'Purchase Response',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const RoomSelectionColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'originalRoomNumber',
    label: 'Original Room Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'originalRoomType',
    label: 'Original Room Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'selectedRoomNumber',
    label: 'Selected Room Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'selectedRoomType',
    label: 'Selected Room Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'upgradePrice',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return parseFloat(value.upgradePrice).toFixed(2);
    },
  },
  {
    id: 'seconds',
    label: 'Time (Seconds)',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'selectionDate',
    label: 'Selection Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.selectionDate, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];
const ClickTrackingColumns = (timezone) => [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'userAgent',
    label: 'Device/Browser',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'medium',
    label: 'Clicked Via',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const NotificationsColumns = (timezone) => [
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'notificationCategory',
    label: 'Notification Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'outcome',
    label: 'Outcome',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const CompanyColumns = (timezone) => [
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'guestName',
    label: 'Guest Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phone',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'fieldName',
    label: 'Field Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'originValue',
    label: 'Origin Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'updatedValue',
    label: 'Updated Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'siteName',
    label: 'Site Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const UserAuditColumns = (timezone) => [
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phoneNumber',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'role',
    label: 'Role',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
    format: function (value) {
      return UserRoleHelper.toFriendlyName(value);
    },
  },
  {
    id: 'sites',
    label: 'Sites',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_LIST,
    format: function (values) {
      return values.length > 1 ? values.join(', ') : values;
    },
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.created, timezone);
    },
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

const UnsubscribedUsersColumns = () => [
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'value',
    label: 'Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'siteName',
    label: 'Site',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const GuestOrderColumns = (timezone) => [
  {
    id: 'reference',
    label: 'Order Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalReference',
    label: 'Cheque Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value.total ? new Currency(value.total.amount, value.total.currency).format() : '';
    },
  },
  {
    id: 'locationType',
    label: 'Location Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return FoodOrderLocationTypes.find((v) => value.locationType === v.value)?.label || 'N/A';
    },
  },
  {
    id: 'locationCode',
    label: 'Room/Table',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'paymentMode',
    label: 'Payment Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'orderSendTime',
    label: 'Send Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.orderSendTime, timezone);
    },
  },
  {
    id: 'orderDeliveryTime',
    label: 'Delivery Time',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.orderDeliveryTime, timezone);
    },
  },
  {
    id: 'createdDate',
    label: 'Created Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.convertTimestampToDateTimezone(values.createdDate, timezone);
    },
  },
  {
    id: 'vendorName',
    label: 'Vendor Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

const GuestDetailColumns = (timezone) => [...CompanyColumns(timezone).filter((c) => !['guestName', 'email', 'phone'].includes(c.id))];

export {
  PreArrivalColumns,
  FeedbackSubmissionColumns,
  UpsellColumns,
  SpecialRequestColumns,
  PaymentColumns,
  RoomSelectionColumns,
  ClickTrackingColumns,
  NotificationsColumns,
  GuestDetailColumns,
  CompanyColumns,
  UserAuditColumns,
  UnsubscribedUsersColumns,
  GuestOrderColumns,
};
