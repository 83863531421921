import reducerCreator from '../../support/reducerCreator';
import * as Actions from '../../actions/rule/actions';

const initial = {
  ...reducerCreator.getInitialState(),
  selectedCondition: null,
  selectedAction: null,
};

const base = reducerCreator.createReducer('Site Rule', 'SITE_RULE', true);

export default function siteRuleReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_RULE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_RULE_CONDITION_SELECTED:
      return {
        ...state,
        lastActionType: action.type,
        selectedCondition: action.payload,
      };
    case Actions.SITE_RULE_ACTION_SELECTED:
      return {
        ...state,
        lastActionType: action.type,
        selectedAction: action.payload,
      };
    case Actions.SITE_RULE_CONDITION_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          conditions: [...state.selectedItem.conditions, action.payload],
        },
      };
    case Actions.SITE_RULE_CONDITION_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          conditions: state.selectedItem.conditions.map((e) => (e.id === action.payload.id ? action.payload : e)),
        },
      };
    case Actions.SITE_RULE_CONDITION_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          conditions: state.selectedItem.conditions.filter((e) => e.id !== action.payload.id),
        },
      };
    case Actions.SITE_RULE_ACTION_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          actions: [...state.selectedItem.actions, action.payload],
        },
      };
    case Actions.SITE_RULE_ACTION_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          actions: state.selectedItem.actions.map((e) => (e.id === action.payload.id ? action.payload : e)),
        },
      };
    case Actions.SITE_RULE_ACTION_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          actions: state.selectedItem.actions.filter((e) => e.id !== action.payload.id),
        },
      };
    case Actions.SITE_RULE_CONDITION_CREATE_FAILURE:
    case Actions.SITE_RULE_CONDITION_UPDATE_FAILURE:
    case Actions.SITE_RULE_CONDITION_DELETE_FAILURE:
    case Actions.SITE_RULE_ACTION_CREATE_FAILURE:
    case Actions.SITE_RULE_ACTION_UPDATE_FAILURE:
    case Actions.SITE_RULE_ACTION_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}
