import Auth from '@aws-amplify/auth';
import Objects from './Objects';

export default class AuthorisationHelper {
  static async getAuthorisationToken() {
    try {
      return {
        header: 'Authorization',
        value: (await Auth.currentSession()).accessToken.jwtToken,
      };
    } catch (error) {
      throw new Error(Objects.getErrorFromResponse(error));
    }
  }
}
