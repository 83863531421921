import * as Actions from '../../actions/sitePaymentType/actions';

const initial = {
  lastActionType: 'NONE',
  defaultPaymentTypes: [],
  sitePaymentTypes: [],
  error: null,
};

export default function sitePaymentTypeReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_PAYMENT_TYPE_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_PAYMENT_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        defaultPaymentTypes: action.payload.defaultPaymentTypes,
        sitePaymentTypes: action.payload.sitePaymentTypes,
        error: null,
      };
    case Actions.SITE_PAYMENT_TYPE_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_PAYMENT_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        sitePaymentTypes: [...state.sitePaymentTypes, action.payload],
        error: null,
      };
    case Actions.SITE_PAYMENT_TYPE_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_PAYMENT_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        sitePaymentTypes: [...state.sitePaymentTypes].filter((i) => i.id !== action.payload),
        error: null,
      };
    case Actions.SITE_PAYMENT_TYPE_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_PAYMENT_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        sitePaymentTypes: [...state.sitePaymentTypes].map((i) => (i.id === action.payload.id ? action.payload : i)),
        error: null,
      };
    case Actions.SITE_PAYMENT_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
}
