import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Order Item', 'FOOD_ORDER_ITEM');

function foodOrderItemReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default foodOrderItemReducer;
