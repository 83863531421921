const ReservationEvent = {
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
};

const ReservationEventOptions = [
  { value: ReservationEvent.CHECK_IN, label: 'CHECK IN' },
  { value: ReservationEvent.CHECK_OUT, label: 'CHECK OUT' },
];

export default ReservationEvent;
export { ReservationEventOptions };
