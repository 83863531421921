import reducerCreator from '../../support/reducerCreator';
import {
  PURCHASE_EXTERNAL_FAILURE,
  PURCHASE_EXTERNAL_IN_PROGRESS,
  PURCHASE_EXTERNAL_SUCCESS,
  PURCHASE_RESET_STATE,
} from '../../actions/purchase/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Purchase', 'PURCHASE');

function purchaseReducer(state = initial, action) {
  switch (action.type) {
    case PURCHASE_EXTERNAL_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case PURCHASE_EXTERNAL_SUCCESS:
      return {
        ...state,
        processing: false,
        response: action.payload,
        lastActionType: action.type,
      };
    case PURCHASE_EXTERNAL_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case PURCHASE_RESET_STATE:
    default:
      return base(state, action);
  }
}

export default purchaseReducer;
