import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

export const FOOD_ITEM_SEARCH_FAILURE = 'FOOD_ITEM_SEARCH_FAILURE';

const FoodItemActions = {
  ...actionCreator.createActions('Food item', 'FOOD_ITEM', '/api/private/portal/food/vendor/{vendorId}/food/item'),
  search: (vendorId, criteria) => async (dispatch) => {
    try {
      return await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/item/search?q=${criteria}`, {});
    } catch (error) {
      dispatch({ type: FOOD_ITEM_SEARCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  findById: (id) => async (dispatch) => {
    try {
      return await API.get('PrivateAPI', `/api/private/portal/food/item/${id}`, {});
    } catch (error) {
      dispatch({ type: FOOD_ITEM_SEARCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  selectAndNavigate: (item, history) => async (dispatch) => {
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/item/${item.id}`, {});
      await dispatch({ type: 'FOOD_ITEM_SELECT', payload: result });
      history.push(`/food/item/view/${result.id}`);
    } catch (error) {
      dispatch({ type: `FOOD_ITEM_SELECT_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default FoodItemActions;
