const SpecialRequestCategory = {
  STAY: 'STAY',
  ACTIVITY: 'ACTIVITY',
  ARRIVAL: 'ARRIVAL',
  DEPARTURE: 'DEPARTURE',
  MISC: 'MISC',
  HOUSEKEEPING: 'HOUSEKEEPING',
  DINING: 'DINING',
  LAUNDRY: 'LAUNDRY',
  MAINTENANCE: 'MAINTENANCE',
  AMENITIES: 'AMENITIES',
};

const SpecialRequestCategoryOptions = [
  { value: SpecialRequestCategory.STAY, label: 'Stay' },
  { value: SpecialRequestCategory.ACTIVITY, label: 'Activity' },
  { value: SpecialRequestCategory.ARRIVAL, label: 'Pre-Arrival' },
  { value: SpecialRequestCategory.DEPARTURE, label: 'Pre-Departure' },
  { value: SpecialRequestCategory.MISC, label: 'Miscellaneous' },
  { value: SpecialRequestCategory.HOUSEKEEPING, label: 'Housekeeping' },
  { value: SpecialRequestCategory.DINING, label: 'Dining' },
  { value: SpecialRequestCategory.LAUNDRY, label: 'Laundry' },
  { value: SpecialRequestCategory.MAINTENANCE, label: 'Maintenance' },
  { value: SpecialRequestCategory.AMENITIES, label: 'Amenities' },
];

export default SpecialRequestCategory;
export { SpecialRequestCategoryOptions };
