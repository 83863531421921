import reducerCreator from '../../support/reducerCreator';
import {
  SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_FAILURE,
  SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_IN_PROGRESS,
  SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_SUCCESS,
} from '../../actions/specialRequestEscalationRedirect/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('SpecialRequestEscalationRedirect', 'SPECIAL_REQUEST_ESCALATION_REDIRECT');

function specialRequestEscalationRedirectReducer(state = initial, action) {
  switch (action.type) {
    case SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_IN_PROGRESS: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    }
    case SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_SUCCESS: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload.content,
        links: action.payload.links,
        page: action.payload.page,
      };
    }
    case SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_FAILURE: {
      return {
        ...state,
        lastActionType: action.type,
        entityList: [],
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default specialRequestEscalationRedirectReducer;
