import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';
import Currency from '../../../../../../support/Currency';

const columns = [
  {
    id: 'offerName',
    label: 'Offer Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'offerCode',
    label: 'Offer Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'offerType',
    label: 'Offer Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'offerTotal',
    label: 'Offer Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return new Currency(values.offerTotal, values.offerCurrency).format();
    },
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ReservationUpsellList() {
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/${selectedReservation?.siteId}/reservations/packages?reservationNumber=${selectedReservation?.reservationNumber}`
  );

  return (
    <AutoTable
      title="Upsell Reservations"
      subheader={'Upsell Reservations  for the Guest'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="RESERVATION_UPSELL"
      backHidden
      detailURL="/reservation/upsell/view/{id}"
      data={data}
      lastActionType={'upsells'}
    />
  );
}
