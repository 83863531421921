import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../redux/actions/minibar/actions';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined, FileCopyOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.deleteMinibar(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'itemName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'articleCode',
    label: 'Article Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'transactionCode',
    label: 'Transaction Code',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'price',
    label: 'Price',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function MinibarList() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const minibarList = useSelector((state) => state.minibarReducer.entityList);
  const error = useSelector((state) => state.minibarReducer.error);
  const processing = useSelector((state) => state.minibarReducer.processing);
  const lastActionType = useSelector((state) => state.minibarReducer.lastActionType);
  const page = useSelector((state) => state.minibarReducer.page);

  React.useEffect(() => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 10, selectedSite.id));
    }
  }, [selectedSite, dispatch]);

  const handleRefreshReport = (selectedSite) => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 10, selectedSite.id));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, selectedSite.id));
  };

  return (
    <AutoTable
      title="Minibar"
      subheader={'Minibar items available in room'}
      handleRefreshReport={() => handleRefreshReport(selectedSite)}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="MINIBAR"
      newButtonName="New Minibar Item"
      createNewURL="/site/minibar/new"
      detailURL="/site/minibar/view/{id}"
      idColumn={'id'}
      data={minibarList}
      lastActionType={lastActionType}
    />
  );
}
