import actionCreator from '../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';

export const APP_THEME_SELECTED_ITEM = 'APP_THEME_SELECT';
export const APP_THEME_CLEAR_LAST_ACTION = 'APP_THEME_CLEAR_LAST_ACTION';
export const APP_THEME_FETCH_SUCCESS = 'APP_THEME_FETCH_SUCCESS';
export const APP_THEME_FETCH_FAILURE = 'APP_THEME_FETCH_FAILURE';
export const APP_THEME_UPDATE_IN_PROGRESS = 'APP_THEME_UPDATE_IN_PROGRESS';
export const APP_THEME_UPDATE_SUCCESS = 'APP_THEME_UPDATE_SUCCESS';
export const APP_THEME_UPDATE_FAILURE = 'APP_THEME_UPDATE_FAILURE';

export const FIREBASE_APP_THEME_FETCH_SUCCESS = 'FIREBASE_APP_THEME_FETCH_SUCCESS';
export const FIREBASE_APP_THEME_FETCH_FAILURE = 'FIREBASE_APP_THEME_FETCH_FAILURE';

const AppThemeActions = {
  ...actionCreator.createActions('App Theme', 'APP_THEME', '/api/private/portal/appTheme'),
  clearLastAction: () => async (dispatch) => {
    dispatch({ type: APP_THEME_CLEAR_LAST_ACTION });
  },
  fetchThemeByName: (appName) => async (dispatch) => {
    API.get('PrivateAPI', `/api/public/app/${appName}`, {})
      .then((response) => {
        dispatch({
          type: FIREBASE_APP_THEME_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: FIREBASE_APP_THEME_FETCH_FAILURE,
          payload: new Error(Objects.getErrorFromResponse(error)),
        });
      });
  },
  sendInvite: (appName, phone) => {
    return API.post('PrivateAPI', `/api/public/app/${appName}/invite/${phone}`, {});
  },
};

export default AppThemeActions;
