import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/reservation/access/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';
import SiteDoorActions from '../../../../redux/actions/siteDoor/actions';
import ReservationTypes from '../../../../../constants/ReservationTypes';

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('phone', 'Phone Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('email', 'Email Address', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
}

export default function AccessReservationList({
  title = 'Current Reservations',
  subheader = 'Access reservations with active keys',
  reportType = ReservationTypes.ARRIVED,
}) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const reservationList = useSelector((state) => state.accessReservationReducer.entityList);
  const processing = useSelector((state) => state.accessReservationReducer.processing);
  const page = useSelector((state) => state.accessReservationReducer.page);
  const error = useSelector((state) => state.accessReservationReducer.error);
  const selectedSiteId = selectedSite?.id;
  const [state, setState] = useState({
    phone: '',
    email: '',
  });

  const filters = getFilterFieldsForScreen(state, setState);

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.findByFilter(0, 20, reportType, selectedSiteId, state.phone, state.email));
      dispatch(SiteDoorActions.fetch(0, 5000, { siteId: selectedSiteId }));
    }
  }, [reportType, selectedSiteId, state, dispatch]);

  const handleFetchPage = React.useCallback(
    (ev, newPage, pageSize) => {
      dispatch(Actions.findByFilter(newPage, pageSize, reportType, selectedSiteId, state.phone, state.email));
    },
    [reportType, dispatch, selectedSiteId, state]
  );

  return (
    <AutoTable
      title={title}
      subheader={subheader}
      handleChangePage={(ev, newPage, pageSize) => handleFetchPage(ev, newPage, pageSize)}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      selectedSite={selectedSite}
      error={error}
      processing={processing}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix={'ACCESS_RESERVATION'}
      createNewURL="/reservation/access/register"
      detailURL="/reservation/access/view"
      data={reservationList}
      filters={filters}
    />
  );
}

// Table column rendering information.
const columns = [
  {
    id: 'reference',
    label: 'Reference',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'guestList',
    label: 'Guests',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_LIST,
    format: function (values) {
      const guestList = values.map((g) => `${g.firstName} ${g.lastName}`);
      return guestList.length > 1 ? `${guestList[0]} (${guestList.length - 1} others)` : guestList.join(', ');
    },
  },
  {
    id: 'doorList',
    label: 'Primary Room',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_LIST,
    filter: function (e) {
      return e.doorType === 'PRIMARY';
    },
    format: function (values) {
      const doorList = values.map((v) => v.doorName);
      return doorList.length > 0 ? doorList.join(', ') : 'No Doors Assigned';
    },
  },
  {
    id: 'doorList',
    label: 'Common Rooms',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_LIST,
    filter: function (e) {
      return e.doorType !== 'PRIMARY';
    },
    format: function (values) {
      const doorList = values.map((v) => v.doorName);
      return doorList.length > 0 ? doorList.join(', ') : 'No Common Rooms';
    },
  },
  {
    id: 'issueMode',
    label: 'Issue Mode',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'approvalStatus',
    label: 'Approved',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'arrival',
    label: 'Check In',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'departure',
    label: 'Check Out',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];
