import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../../redux/actions/rule/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SiteRuleConditionType from './support/SiteRuleConditionType';
import ConditionEvaluationType from './support/ConditionEvaluationType';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';

export default function RuleCondition() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.siteRuleReducer.error);
  const lastActionType = useSelector((state) => state.siteRuleReducer.lastActionType);
  const selectedItem = useSelector((state) => state.siteRuleReducer.selectedItem);
  const selectedCondition = useSelector((state) => state.siteRuleReducer.selectedCondition);
  const [name, setName] = useState(selectedCondition ? selectedCondition.name : '');
  const [conditionType, setConditionType] = useState(selectedCondition ? selectedCondition.conditionType : null);
  const [conditionEvaluation, setConditionEvaluation] = useState(selectedCondition ? selectedCondition.conditionEvaluation : null);
  const [orderNo, setOrderNo] = useState(selectedCondition ? selectedCondition.orderNo : 1);
  const [input, setInput] = useState(selectedCondition ? selectedCondition.input : 0);
  // const [enabled, setEnabled] = useState(selectedCondition ? selectedCondition.enabled : true);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      // check names generated by actionCreator
      lastActionType === Actions.SITE_RULE_CONDITION_DELETE_SUCCESS ||
      lastActionType === Actions.SITE_RULE_CONDITION_CREATE_SUCCESS ||
      lastActionType === Actions.SITE_RULE_CONDITION_UPDATE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const payload = {
      id: selectedCondition ? selectedCondition.id : null,
      name: name,
      conditionType: conditionType,
      conditionEvaluation: conditionEvaluation,
      input: input,
      orderNo: orderNo,
      ruleId: selectedItem?.id,
    };

    if (selectedCondition) {
      dispatch(Actions.updateCondition(payload));
    } else {
      dispatch(Actions.addCondition(payload));
    }
  };

  const isFormReady = name && orderNo && conditionType && conditionEvaluation;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedCondition ? 'Update Condition' : 'Create Condition'}
        subheader={'Conditions evaluate properties on a reservation to determine if the rule actions will be run'}
        buttonName={selectedCondition ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.removeCondition(selectedCondition))}
        deleteDisabled={!selectedCondition}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {'Unable to edit/delete condition - ' + error.message}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={name} setValue={setName} error={!name} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Order No'} type={'number'} value={orderNo} setValue={setOrderNo} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Condition Type'}
              value={conditionType}
              setValue={setConditionType}
              options={Object.keys(SiteRuleConditionType).map((key) => ({
                label: SiteRuleConditionType[key].displayName,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Condition Evaluation'}
              value={conditionEvaluation}
              setValue={setConditionEvaluation}
              options={Object.keys(ConditionEvaluationType).map((key) => ({
                label: ConditionEvaluationType[key].displayName,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Condition Input'} value={input} setValue={setInput} />
          </Grid>
          {/*<Grid item xs={12} sm={12} md={6} lg={6}>*/}
          {/*  <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />*/}
          {/*</Grid>*/}
        </Grid>
      </CardContent>
    </Card>
  );
}
