import { TextField } from '@material-ui/core';
import ReactQuill from 'react-quill';
import React from 'react';

export default function CompendiumContentEditor({ value, setValue, visual = true }) {
  if (visual) {
    return <ReactQuill theme={'snow'} value={value} onChange={(value) => setValue(value)} placeholder={'Enter your content...'} />;
  } else {
    return (
      <TextField
        fullWidth
        label="Content"
        placeholder={'Enter your content...'}
        type="text"
        variant={'outlined'}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        multiline
        minRows={6}
        maxRows={8}
      />
    );
  }
}
