import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Corp Filter', 'FOOD_CORP_FILTER');

function foodCorpFilterReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default foodCorpFilterReducer;
