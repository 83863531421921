import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from 'components/form/FormField';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Actions from '../../../../redux/actions/appUserAddress/actions';

export default function AppUserAddress() {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.appUserAddressReducer.error);
  const selectedItem = useSelector((state) => state.appUserAddressReducer.selectedItem);
  const selectedUser = useSelector((state) => state.appUsersReducer.selectedItem);

  const [addressLine1, setAddressLine1] = React.useState(selectedItem?.addressLine1 || null);
  const [addressLine2, setAddressLine2] = React.useState(selectedItem?.addressLine2 || null);
  const [friendlyName, setFriendlyName] = React.useState(selectedItem?.friendlyName || null);
  const [city, setCity] = React.useState(selectedItem?.city || null);
  const [postCode, setPostCode] = React.useState(selectedItem?.postCode || null);
  const [countryCode, setCountryCode] = React.useState(selectedItem?.countryCode || null);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      friendlyName,
      id: selectedItem?.id || null,
      appUserId: selectedUser.id,
      addressLine1,
      addressLine2,
      city,
      postCode,
      countryCode,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady = friendlyName && addressLine1 && city && postCode && countryCode;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update User Address' : 'Create User Address'}
        subheader={''}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to Address - ${error.message}`}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={friendlyName} setValue={setFriendlyName} placeholder="Friendly Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Address Line1'} value={addressLine1} setValue={setAddressLine1} placeholder="Address Line1" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Address Line2'} value={addressLine2} setValue={setAddressLine2} placeholder="Address Line2" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'City'} value={city} setValue={setCity} placeholder="City" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Post Code'} value={postCode} setValue={setPostCode} placeholder="Post Code" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Country Code'} value={countryCode} setValue={setCountryCode} placeholder="Country Code" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
