import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as Actions from '../../../../../../redux/actions/compendium/article/actions';
import CardHeaderWithControls from '../../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../../../components/form/FormField';
import toBase64 from '../../../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../../../components/file/FileImageSelector';

export default function ImageEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const lastActionType = useSelector((state) => state.compendiumArticleReducer.lastActionType);
  const error = useSelector((state) => state.compendiumArticleReducer.error);
  const selectedItem = useSelector((state) => state.compendiumArticleReducer.selectedItem);
  const selectedImage = useSelector((state) => state.compendiumArticleReducer.selectedImage);

  const [orderNo, setOrderNo] = React.useState(selectedImage?.orderNo || 1);
  const [imageName, setImageName] = React.useState(selectedImage?.imageName || '');
  const [description, setDescription] = React.useState(selectedImage?.description || '');
  const [url, setUrl] = React.useState(selectedImage?.imageUrl || '');
  const [fileSelected, setFileSelected] = React.useState(null);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.ARTICLE_IMAGE_ADD_SUCCESS ||
      lastActionType === Actions.ARTICLE_UPDATE_SUCCESS ||
      lastActionType === Actions.ARTICLE_IMAGE_DELETE_SUCCESS
    ) {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const fileToSend = fileSelected ? await toBase64(fileSelected) : null;
    if (selectedImage) {
      const updatedImageList = selectedItem.imageList.map((image) => {
        if (image.id === selectedImage.id) {
          return {
            ...image,
            orderNo,
            imageName,
            description,
            imageUrl: fileToSend ? fileToSend : url ? url.substring(url.lastIndexOf('/') + 1) : null,
            articleId: selectedItem.id,
          };
        }
        return image;
      });
      const payload = {
        ...selectedItem,
        imageList: updatedImageList,
      };
      dispatch(Actions.update(payload));
    } else {
      const payload = {
        ...selectedImage,
        orderNo,
        imageName,
        description,
        imageUrl: fileToSend,
        articleId: selectedItem.id,
      };
      dispatch(Actions.createImage(payload));
    }
  };

  const isFormReady = orderNo && imageName && (fileSelected || url);

  return (
    <Card>
      <CardHeaderWithControls
        header={'Article Image'}
        subheader={''}
        buttonName={selectedImage ? 'Update' : 'Add'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to update image - ${error.message}`}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Image Name'} value={imageName} setValue={setImageName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Order'} value={orderNo} setValue={setOrderNo} placeholder="Order" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              label="Description"
              type="text"
              variant={'outlined'}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              multiline
              rowsMax={8}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FileImageSelector
              sourceItem={selectedImage}
              sourceItemProperty={'imageUrl'}
              displayName={'Image'}
              onSelect={(file) => setFileSelected(file)}
              imageCleared={(value) => {
                if (value) {
                  setUrl(null);
                }
              }}
            />
          </Grid>
          {url && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'URL'} value={url} placeholder="URL of the image" disabled />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
