import React, { useCallback, useState } from 'react';
import { AppBar, Box, Divider, Drawer, Hidden, IconButton, Menu, MenuItem, Toolbar, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import * as AuthenticationActions from '../redux/actions/authentication/actions';
import * as AccessActions from '../redux/actions/access/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import SiteSelector from './control/SiteSelector';
import CorporationSelector from './control/CorporationSelector';
import SectionSelector from './control/SectionSelector';
import SideMenuContent from './SideMenuContent';
import { makeStyles } from '@material-ui/core/styles';
import { AccountBox, MenuOutlined, NightsStay, WbSunny } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

const MAX_DRAWER_WIDTH = 280;

function SideMenu() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const themePreference = useSelector((state) => state.accessReducer.theme);
  const open = Boolean(anchorEl);
  const isDarkTheme = themePreference === 'dark';

  const handleMenuSelection = useCallback(
    (index, location) => {
      if (selectedIndex === index) {
        setSelectedIndex(-1);
        history.push(location);
      } else {
        setSelectedIndex(index);
        if (location) {
          history.push(location);
          setMobileOpen(false);
        }
      }
    },
    [history, selectedIndex, setMobileOpen]
  );

  const handleNestedMenuSelection = useCallback(
    (location) => {
      if (location) {
        history.push(location);
      }
      setMobileOpen(false);
    },
    [history, setMobileOpen]
  );

  const switchThemeSelected = React.useCallback(() => {
    if (themePreference === 'dark') {
      dispatch(AccessActions.updateThemePreference('light'));
    } else {
      dispatch(AccessActions.updateThemePreference('dark'));
    }
  }, [themePreference, dispatch]);

  const handleLogout = React.useCallback(() => {
    setAnchorEl(null);
    dispatch(AuthenticationActions.logout());
  }, [dispatch]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMenuOpen = isMobile ? mobileOpen : true;
  return (
    <React.Fragment>
      <AppBar position={'relative'} color={'primary'} style={{ width: '100vw' }}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMobileOpen(!mobileOpen)}>
              <MenuOutlined />
            </IconButton>
          </Hidden>

          <Box flexGrow={1} />

          <SectionSelector />

          <CorporationSelector />

          {selectedCorporation && <SiteSelector />}

          <IconButton
            aria-label="dark-light-mode"
            aria-controls="dark-light-mode"
            aria-haspopup="true"
            onClick={switchThemeSelected}
            color="inherit"
          >
            {isDarkTheme && <NightsStay />}
            {!isDarkTheme && <WbSunny />}
          </IconButton>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountBox />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} disabled={true}>
              {profile.email}
            </MenuItem>
            <Link to="/my-account" className={classes.linkStyle}>
              <MenuItem onClick={handleClose}>My Account</MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        open={isMenuOpen}
        anchor={'left'}
        variant={isMobile ? 'temporary' : 'permanent'}
        className={clsx(classes.drawer)}
        classes={{ paper: clsx(classes.drawerOpen) }}
        onClose={() => setMobileOpen(false)}
      >
        <Divider />

        <SideMenuContent
          classes={classes}
          handleMenuSelection={handleMenuSelection}
          handleNestedMenuSelection={handleNestedMenuSelection}
          selectedIndex={selectedIndex}
        />
      </Drawer>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => {
  // const navigationBackground = theme.type === 'light' ? theme.palette.colours['dark'] : theme.palette.colours['dark'];
  // const navigationText = theme.type === 'light' ? theme.palette.colours['white'] : theme.palette.colours['white'];

  const navigationBackground = theme.type === 'light' ? theme.palette.colours['dark'] : theme.palette.background.paper;
  const navigationText = theme.palette.primary.contrastText;
  return {
    root: {
      display: 'flex',
    },
    drawer: {
      width: MAX_DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: navigationText,
    },
    drawerOpen: {
      width: MAX_DRAWER_WIDTH,
      backgroundColor: navigationBackground,
      color: navigationText,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: navigationBackground,
      color: navigationText,
      overflowX: 'hidden',
      width: theme.spacing(9) + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    menuIcon: {
      color: navigationText,
    },
    menuItem: {
      color: navigationText,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      minHeight: '88px',
    },
    toolbar_image: {
      border: 0,
      verticalAlign: 'center',
      margin: '0 1.0rem',
      color: navigationText,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: MAX_DRAWER_WIDTH - 40,
      minHeight: '75px',
    },
    toolbar_image_inner: {
      width: '75%',
    },
    toolbar_button: {
      padding: '6px 1.0rem',
    },
    toolbar_collapse: {
      position: 'absolute',
      right: '0',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    linkStyle: {
      textDecoration: 'none',
      display: 'block',
      color: theme.type === 'light' ? 'black' : 'white',
    },
  };
});

export default React.memo(SideMenu);
