export const AmenitiesNavigation = {
  FOOD: 'FOOD',
  COMPENDIUM: 'COMPENDIUM',
  MEMBERSHIP: 'MEMBERSHIP',
  SITE: 'SITE',
};

export const AmenitiesNavigationOptions = [
  { label: 'Food', value: AmenitiesNavigation.FOOD },
  { label: 'Compendium', value: AmenitiesNavigation.COMPENDIUM },
  { label: 'Membership', value: AmenitiesNavigation.MEMBERSHIP },
  { label: 'Site', value: AmenitiesNavigation.SITE },
];
