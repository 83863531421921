import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

const BASE_URL = '/api/private/portal/site/payment-types';

export const SITE_PAYMENT_TYPE_FETCH_SUCCESS = 'SITE_PAYMENT_TYPE_FETCH_SUCCESS';
export const SITE_PAYMENT_TYPE_FETCH_FAILURE = 'SITE_PAYMENT_TYPE_FETCH_FAILURE';

export const SITE_PAYMENT_TYPE_CREATE_SUCCESS = 'SITE_PAYMENT_TYPE_CREATE_SUCCESS';
export const SITE_PAYMENT_TYPE_CREATE_FAILURE = 'SITE_PAYMENT_TYPE_CREATE_FAILURE';

export const SITE_PAYMENT_TYPE_UPDATE_SUCCESS = 'SITE_PAYMENT_TYPE_UPDATE_SUCCESS';
export const SITE_PAYMENT_TYPE_UPDATE_FAILURE = 'SITE_PAYMENT_TYPE_UPDATE_FAILURE';

export const SITE_PAYMENT_TYPE_DELETE_SUCCESS = 'SITE_PAYMENT_TYPE_DELETE_SUCCESS';
export const SITE_PAYMENT_TYPE_DELETE_FAILURE = 'SITE_PAYMENT_TYPE_DELETE_FAILURE';

export const SITE_PAYMENT_TYPE_CLEAR_ERROR = 'SITE_PAYMENT_TYPE_CLEAR_ERROR';

export const clearError = () => async (dispatch) => {
  dispatch({ type: SITE_PAYMENT_TYPE_CLEAR_ERROR, payload: {} });
};

export const fetchAll = (siteId) => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', `${BASE_URL}?siteId=${siteId}`, {});
    dispatch({
      type: SITE_PAYMENT_TYPE_FETCH_SUCCESS,
      payload: {
        defaultPaymentTypes: response.filter((p) => !p.overridden),
        sitePaymentTypes: response.filter((p) => p.overridden),
      },
    });
  } catch (error) {
    dispatch({ type: SITE_PAYMENT_TYPE_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const createPaymentType = (request) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', BASE_URL, { body: request });
    dispatch({ type: SITE_PAYMENT_TYPE_CREATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PAYMENT_TYPE_CREATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const updatePaymentType = (request) => async (dispatch) => {
  try {
    const response = await API.put('PrivateAPI', `${BASE_URL}/${request.id}`, { body: request });
    dispatch({ type: SITE_PAYMENT_TYPE_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SITE_PAYMENT_TYPE_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deletePaymentType = (paymentType) => async (dispatch) => {
  try {
    await API.del('PrivateAPI', `${BASE_URL}/${paymentType.id}`, {});
    dispatch({ type: SITE_PAYMENT_TYPE_DELETE_SUCCESS, payload: paymentType.id });
  } catch (error) {
    dispatch({ type: SITE_PAYMENT_TYPE_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};
