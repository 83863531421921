import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'userAgent',
    label: 'Device/Browser',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'medium',
    label: 'Clicked Via',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function ReservationLinkTrackingList() {
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/${selectedReservation?.siteId}/reservations/clicks?reservationNumber=${selectedReservation?.reservationNumber}`
  );

  return (
    <AutoTable
      title="Click Tracking"
      subheader={'Number of times the guest has interacted with the web portal link'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="RESERVATION_LINK_TRACKING"
      buttonDisabled={true}
      data={data}
      idColumn={'id'}
      backHidden={true}
    />
  );
}
