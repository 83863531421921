import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/roomSelection/actions';
import RoomFloorActions from '../../../../redux/actions/roomSelection/roomFloor/actions';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';
import { RoomNumberMode, SelectionMode, UpsellMode } from './RoomSelectionEnums';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import * as DataTypes from '../../../../../constants/DataTypes';
import { DeleteOutlined } from '@material-ui/icons';
import AutoTable from '../../../../../components/table/AutoTable';

const columns = [
  {
    id: 'delete',
    label: 'Controls',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={'inherit'}
          onClick={() => dispatch(Actions.delete(value, `/api/private/portal/roomSelection/image/${value.id}`))}
        >
          <DeleteOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'floorNumber',
    label: 'Floor Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'imageUrl',
    label: 'Image',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function RoomSelectionEdit() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const error = useSelector((state) => state.roomSelectionReducer.error);
  const selectedItem = useSelector((state) => state.roomSelectionReducer.selectedItem);
  const roomFloorProcessing = useSelector((state) => state.roomFloorReducer.processing);
  const roomFloorLastActionType = useSelector((state) => state.roomFloorReducer.lastActionType);
  const roomFloorPage = useSelector((state) => state.roomFloorReducer.page);
  const roomFloorData = useSelector((state) => state.roomFloorReducer.entityList);
  const roomFloorError = useSelector((state) => state.roomFloorReducer.error);

  const [roomNumberMode, setRoomNumberMode] = React.useState(selectedItem?.roomNumberMode || null);
  const [upsellMode, setUpsellMode] = React.useState(selectedItem?.upsellMode || null);
  const [selectionMode, setSelectionMode] = React.useState(selectedItem?.selectionMode || null);
  const [displayRoomImage, setDisplayRoomImage] = React.useState(selectedItem?.displayRoomImage || false);
  const [displayRoomDesc, setDisplayRoomDesc] = React.useState(selectedItem?.displayRoomDesc || false);
  const [hiddenRoomTypes, setHiddenRoomTypes] = React.useState(selectedItem?.hiddenRoomTypes || '');
  const [upsellTransactionCode, setUpsellTransactionCode] = React.useState(selectedItem?.upsellTransactionCode || '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    dispatch(Actions.selectById(selectedSite.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchRoomFloors = () => {
    dispatch(RoomFloorActions.fetch(roomFloorPage, 50, { siteId: selectedSite.id }, { id: `${selectedItem.id}` }));
  };

  useEffect(() => {
    if (selectedItem) {
      handleFetchRoomFloors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      roomNumberMode: roomNumberMode,
      selectionMode: selectionMode,
      displayRoomImage: displayRoomImage,
      displayRoomDesc: displayRoomDesc,
      hiddenRoomTypes: hiddenRoomTypes,
      upsellTransactionCode: upsellTransactionCode,
      upsellMode: upsellMode,
      siteId: selectedSite?.id,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, null, false));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady = roomNumberMode && selectionMode && upsellMode;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Room Selection' : 'Add Room Selection'}
        subheader={'Room Selection feature for controlling what the guest sees on screen'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/delete Room Selection configuration - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Selection Offered To'}
              value={selectionMode}
              setValue={setSelectionMode}
              options={Object.keys(SelectionMode).map((key) => ({
                label: SelectionMode[key].displayName,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Room Number Determination Mode'}
              value={roomNumberMode}
              setValue={setRoomNumberMode}
              options={Object.keys(RoomNumberMode).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect value={displayRoomDesc} setValue={setDisplayRoomDesc} label={'Display Room Description'} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect value={displayRoomImage} setValue={setDisplayRoomImage} label={'Display Room Image'} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Hide Room Types'} value={hiddenRoomTypes} setValue={setHiddenRoomTypes} placeholder="E.g ETC, EKC, B1B" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Upsell Mode'}
              value={upsellMode}
              setValue={setUpsellMode}
              options={Object.keys(UpsellMode).map((key) => ({
                label: UpsellMode[key].displayName,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Upsell Transaction Code'}
              value={upsellTransactionCode}
              setValue={setUpsellTransactionCode}
              placeholder="E.g. 1000"
            />
          </Grid>
        </Grid>
      </CardContent>
      {selectedItem && (
        <AutoTable
          title="Floor Images"
          subheader={'List of floor svgs currently available'}
          processing={roomFloorProcessing}
          handleRefreshReport={() => handleFetchRoomFloors()}
          page={roomFloorPage}
          error={roomFloorError}
          selectItem={(item) => {}}
          clearError={() => dispatch(RoomFloorActions.clearError())}
          columns={columns}
          prefix="ROOM_FLOOR"
          data={roomFloorData}
          lastActionType={roomFloorLastActionType}
          createNewURL="/site/roomSelection/floors"
          buttonName="Edit Floors"
          idColumn={'id'}
        />
      )}
    </Card>
  );
}
