import React, { useCallback } from 'react';
import ThemeEdit from './ThemeEdit';
import { useSelector } from 'react-redux';
import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import ThemeTermsList from './ThemeTermsList';
import SiteTermsMessages from './ThemeMessages';
import { useHistory, useLocation } from 'react-router-dom';
import TabPanel from '../../control/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const editTabsList = [{ label: 'Terms And Conditions' }, { label: 'Messages' }];

export default function ThemeEditContainer() {
  const history = useHistory();
  const location = useLocation();
  const selectedTab = new URLSearchParams(location.search).get('tab');
  const [value, setValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
  const selectedItem = useSelector((state) => state.themeReducer.selectedItem);

  // useEffect(() => {
  //   history.push({
  //     pathname: window.location.pathname,
  //     search: '?tab=0'
  //   });
  // }, [history]);

  const moveSelectedTab = useCallback(
    (event, newValue) => {
      setValue(newValue);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tab', newValue);

      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location]
  );

  if (selectedItem) {
    return (
      <Paper>
        <AppBar position="static">
          <Tabs value={value} onChange={moveSelectedTab}>
            <Tab label="Terms And Colours" {...a11yProps(0)} />
            {selectedItem && editTabsList.map((item, idx) => <Tab label={item.label} {...a11yProps(idx + 1)} />)}
          </Tabs>
        </AppBar>
        <div className="content-'crollable-no-padding">
          <TabPanel value={value} index={0}>
            <ThemeEdit />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ThemeTermsList />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SiteTermsMessages />
          </TabPanel>
        </div>
      </Paper>
    );
  } else {
    return <ThemeEdit />;
  }
}
