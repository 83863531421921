import React from 'react';
import AccessReservationList from './AccessReservationList';
import ReservationTypes from '../../../../../constants/ReservationTypes';

export default function AccessReservationUpcomingList() {
  return (
    <AccessReservationList
      title="Upcoming Reservations"
      subtitle={'Reservations due for arrival that do not have access'}
      reportType={ReservationTypes.FUTURE_ARRIVALS}
    />
  );
}
