const ChronoUnit = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};

const ChronoUnitSelectOptions = [
  { label: 'Minutes', value: ChronoUnit.MINUTES },
  { label: 'Hours', value: ChronoUnit.HOURS },
  { label: 'Days', value: ChronoUnit.DAYS },
  { label: 'Weeks', value: ChronoUnit.WEEKS },
  { label: 'Months', value: ChronoUnit.MONTHS },
];

export { ChronoUnitSelectOptions };

export default ChronoUnit;
