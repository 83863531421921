import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../redux/actions/user/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PasswordResetModal from './PasswordResetModal';
import UserRoleHelper from '../../../../support/UserRoleHelper';
import UserRole from '../../../../support/UserRole';
import CannedRegex from '../../../../support/CannedRegex';
import Objects from '../../../../support/Objects';
import FormField from '../../../../components/form/FormField';
import FormBooleanSelect from '../../../../components/form/FormBooleanSelect';
import moment from 'moment';
import FormSelect from '../../../../components/form/FormSelect';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';
import ResetPasswordType from '../../../../constants/ResetPasswordType';

export default function UserDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.accessReducer.profile);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const corporationList = useSelector((state) => state.accessReducer.corporationList);
  const selectedItem = useSelector((state) => state.userReducer.selectedItem);

  const lastActionType = useSelector((state) => state.userReducer.lastActionType);
  const processing = useSelector((state) => state.userReducer.processing);
  const error = useSelector((state) => state.userReducer.error);

  const [email, setEmail] = React.useState(selectedItem?.email || '');
  const [firstName, setFirstName] = React.useState(selectedItem?.firstName || '');
  const [lastName, setLastName] = React.useState(selectedItem?.lastName || '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phoneNumber,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [role, setRole] = React.useState(selectedItem?.role || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);
  const [corporationId, setCorporationId] = React.useState(
    selectedItem && selectedItem.corporations.length > 0 ? selectedItem.corporations[0].id : ''
  );
  const [siteId, setSiteId] = React.useState(selectedItem && selectedItem.sites.length > 0 ? selectedItem.sites[0].id : '');
  const [userResetPasswordModalOpen, setUserResetPasswordModalOpen] = React.useState(false);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === Actions.USER_DELETE_SUCCESS ||
      lastActionType === Actions.USER_CREATE_SUCCESS ||
      lastActionType === Actions.USER_RESET_PASSWORD_SUCCESS ||
      lastActionType === Actions.USER_SEND_VALIDATE_CODE_SUCCESS
    ) {
      history.goBack();
    }
  }, [history, lastActionType]);

  function handleAddOrUpdate() {
    if (selectedItem) {
      dispatch(
        Actions.editUser({
          ...selectedItem,
          username: selectedItem.username,
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          phoneNumber: phone.sanitizedPhoneNumber,
          userRole: role,
          enabled: enabled,
          corporationId: corporationId || null,
          siteId: siteId || null,
        })
      );
    } else {
      dispatch(
        Actions.createUser({
          username: null,
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          phoneNumber: phone.sanitizedPhoneNumber,
          userRole: role,
          enabled: enabled,
          corporationId: corporationId || null,
          siteId: siteId || null,
        })
      );
    }
  }

  const handleRoleUpdate = (updatedRole) => {
    if (UserRole.ROLE_SUPER === updatedRole) {
      setCorporationId(null);
      setSiteId(null);
    }

    if (UserRole.ROLE_CORP_ADMIN === updatedRole) {
      setSiteId(null);
    }

    setRole(updatedRole);
  };

  const handleClose = () => {
    setUserResetPasswordModalOpen(false);
  };

  const requestPasswordReset = (resetPasswordType, password) => {
    setUserResetPasswordModalOpen(false);

    if (resetPasswordType === ResetPasswordType.SEND_VERIFICATION_CODE_EMAIL.value) {
      dispatch(Actions.sendValidateCode(selectedItem.username));
    } else if (resetPasswordType === ResetPasswordType.UPDATE_TEMP_PASSWORD.value) {
      dispatch(Actions.resetUserPassword(selectedItem.username, password));
    } else {
      console.warn('Unable to find correct reset password type', resetPasswordType);
    }
  };

  const isEmailValid = CannedRegex.isEmailValid(email);
  const isFormReady = checkFormAndRoles(processing, email, phone, firstName, lastName, role, corporationId, siteId);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Edit User' : 'Create User'}
        subheader={selectedItem ? selectedItem.email : 'Please complete the form to create a new user'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        buttonLoading={processing}
        onClick={handleAddOrUpdate}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem}
        onDelete={() => dispatch(Actions.deleteUser(selectedItem))}
      />

      <PasswordResetModal handleClose={handleClose} isOpen={userResetPasswordModalOpen} handleResetPassword={requestPasswordReset} />

      <Divider variant={'middle'} />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to create or edit user please try again later. Error message (${error.message})`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'First Name'} value={firstName} setValue={setFirstName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Last Name'} value={lastName} setValue={setLastName} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Email Address'}
              type={'email'}
              value={email}
              setValue={setEmail}
              error={!isEmailValid}
              disabled={selectedItem?.id}
              helperText={CannedRegex.getEmailMessage(!isEmailValid)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'} margin={'dense'}>
              <InputLabel>User Role</InputLabel>
              <Select value={role} labelWidth={70} onChange={(event) => handleRoleUpdate(event.target.value)}>
                {UserRoleHelper.hasRole(profile, UserRole.ROLE_SUPER) && (
                  <MenuItem value={UserRole.ROLE_SUPER}>{UserRoleHelper.toFriendlyName(UserRole.ROLE_SUPER)}</MenuItem>
                )}
                {UserRoleHelper.hasRole(profile, UserRole.ROLE_CORP_ADMIN) && (
                  <MenuItem value={UserRole.ROLE_CORP_ADMIN}>{UserRoleHelper.toFriendlyName(UserRole.ROLE_CORP_ADMIN)}</MenuItem>
                )}
                {UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_ADMIN) && (
                  <MenuItem value={UserRole.ROLE_SITE_ADMIN}>{UserRoleHelper.toFriendlyName(UserRole.ROLE_SITE_ADMIN)}</MenuItem>
                )}
                {UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_USER) && (
                  <MenuItem value={UserRole.ROLE_SITE_USER}>{UserRoleHelper.toFriendlyName(UserRole.ROLE_SITE_USER)}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          {UserRole.ROLE_SUPER !== role && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect
                label={'Assigned Organisation'}
                value={corporationId}
                setValue={(value) => {
                  setCorporationId(value);
                  setSiteId(null);
                }}
                options={corporationList.map((c) => ({ label: c.name, value: c.id }))}
                error={Objects.isNullOrEmpty(corporationId)}
              />
            </Grid>
          )}

          {UserRoleHelper.roleMatchesList(role, [UserRole.ROLE_SITE_ADMIN, UserRole.ROLE_SITE_USER]) && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect
                label={'Assigned Site'}
                value={siteId}
                setValue={setSiteId}
                options={siteList
                  .filter((s) => s.corporationId === corporationId)
                  .map((s) => ({
                    label: s.name,
                    value: s.id,
                  }))}
                error={Objects.isNullOrEmpty(siteId)}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'User Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>

          {selectedItem && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Updated'} value={moment.unix(selectedItem.updated).format('LLL')} disabled />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Created'} value={moment.unix(selectedItem.created).format('LLL')} disabled />
              </Grid>

              <Grid item xs={12}>
                <Button variant={'contained'} onClick={() => setUserResetPasswordModalOpen(true)}>
                  Reset Password
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function checkFormAndRoles(processing, email, phone, firstName, lastName, role, corporationId, siteId) {
  if (processing) {
    return false;
  }

  if (CannedRegex.isEmailValid(email) && phone.isValid && firstName && lastName && role) {
    if (UserRole.ROLE_SUPER === role) {
      return true;
    }

    if (UserRole.ROLE_CORP_ADMIN === role) {
      return !Objects.isNullOrEmpty(corporationId);
    }

    if (UserRole.ROLE_SITE_ADMIN === role || UserRole.ROLE_SITE_USER) {
      return !Objects.isNullOrEmpty(corporationId) && !Objects.isNullOrEmpty(siteId);
    }

    //fall off unknown role
  }

  return false;
}
