import DatePicker from 'react-datepicker';
import React, { forwardRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import FormField from '../../../components/form/FormField';

function parseDate(date, format) {
  const dt = moment(date, format, true);
  if (dt.isValid()) {
    return dt.startOf('day').toDate();
  }

  return null;
}

export default function MultiDatePicker({ label, format = 'YYYY-MM-DD', fieldFormat = 'MMM-DD', selected, setValue, ...props }) {
  const [selectedDates, setSelectedDates] = useState(selected?.map((date) => parseDate(date, format)).filter((date) => !!date) || []);

  const handleSelect = (value) => {
    let result;
    if (!!selectedDates.find((d) => d.getTime() === value.getTime())) {
      result = selectedDates?.filter((dates) => dates.getTime() !== value.getTime());
    } else {
      result = [...selectedDates, value];
    }

    setSelectedDates(result);
    setValue(result?.map((date) => moment(date).format(format)));
  };

  const CustomField = forwardRef(({ onClick }, ref) => (
    <FormField
      label={label}
      value={selectedDates?.map((date) => moment(date).format(fieldFormat)).join(', ')}
      InputLabelProps={{ shrink: true }}
      onClick={onClick}
      setValue={{}}
      inputRef={ref}
      {...props}
    />
  ));

  return (
    <DatePicker
      wrapperClassName={'MuiFormControl-fullWidth'}
      highlightDates={selectedDates}
      shouldCloseOnSelect={false}
      selected={''}
      onChange={handleSelect}
      customInput={<CustomField />}
    />
  );
}
