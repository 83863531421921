import * as Action from '../../actions/dashboard/actions';

const initial = {
  error: null,
  widgetData: {},
  processing: false,
};

function dashboardReducer(state = initial, action) {
  switch (action.type) {
    case Action.DASHBOARD_WIDGET_FETCH_IN_PROGRESS:
    case Action.IRD_DASHBOARD_WIDGET_FETCH_IN_PROGRESS:
    case Action.GUEST_DASHBOARD_WIDGET_FETCH_IN_PROGRESS:
      return {
        ...state,
        error: null,
        processing: true,
      };
    case Action.DASHBOARD_WIDGET_GET_SUCCESS:
      return {
        ...state,
        widgetData: {
          totalRevenue: action.payload.totalRevenue,
          numberOfPurchases: action.payload.numberOfPurchases,
          currentActiveMobileKeys: action.payload.currentActiveMobileKeys,
          issuedKeys: action.payload.issuedKeys,
          checkIns: action.payload.checkIns,
          checkOuts: action.payload.checkOuts,
          upsellRevenue: action.payload.upsellRevenue,
          directBookingsRevenue: action.payload.directBookingsRevenue,
          checkInReservationsNotified: action.payload.checkInReservationsNotified,
          checkOutReservationsNotified: action.payload.checkOutReservationsNotified,
          onlineCheckInCompleted: action.payload.onlineCheckInCompleted,
          onlineCheckOutCompleted: action.payload.onlineCheckOutCompleted,
          popularLinkType: action.payload.popularLinkType,
          optedOut: action.payload.optedOut,
        },
        processing: false,
      };
    case Action.IRD_DASHBOARD_WIDGET_GET_SUCCESS:
      return {
        ...state,
        irdWidgetData: {
          totalRevenue: action.payload.totalRevenue,
          totalCovers: action.payload.totalCovers,
          averageCheckPerCover: action.payload.averageCheckPerCover,
        },
        processing: false,
      };
    case Action.GUEST_DASHBOARD_WIDGET_GET_SUCCESS:
      return {
        ...state,
        guestWidgetData: {
          totalRequests: action.payload.totalRequests,
          totalEscalations: action.payload.totalEscalations,
          topRequestCategory: action.payload.topRequestCategory,
          topRequestSubcategory: action.payload.topRequestSubcategory,
        },
        processing: false,
      };
    case Action.DASHBOARD_WIDGET_GET_FAILURE:
    case Action.IRD_DASHBOARD_WIDGET_GET_FAILURE:
    case Action.GUEST_DASHBOARD_WIDGET_GET_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case Action.DASHBOARD_CLEAR_ERROR:
    case Action.IRD_DASHBOARD_CLEAR_ERROR:
      return {
        ...state,
        processing: false,
        error: null,
      };
    default:
      return state;
  }
}

export default dashboardReducer;
