import { ReportTypeFilters } from '../../../../../../support/ReportType';
import { UpsellOfferTypeOptions } from '../../../../../../support/UpsellOfferType';
import {
  getFilterField,
  getFilterUpdateMethods,
  getMultiSelectFilterField,
  getSelectFilterField,
} from '../../../../../../components/table/filter/filterUtils';
import { SpecialRequestStateOptions } from '../../../../../../support/SpecialRequestState';
import { DATA_TYPE_DATE, DATA_TYPE_STRING } from '../../../../../../constants/DataTypes';
import { PaymentPurchaseStateOptions } from '../../../../../../support/PurchaseState';
import { FlowStateOptions, SelectStateOptions, SelectTrueFalseOptions } from '../../../../../../support/FlowStates';
import TimeZones from '../../../../../../support/TimeZones';
import { ReservationFetchTypeOptions } from '../../../../../../support/ReservationFetchType';
import UserRoleHelper from '../../../../../../support/UserRoleHelper';
import * as DataTypes from '../../../../../../constants/DataTypes';
import UnsubscribedTypes from '../../../../../../support/UnsubscribedTypes';
import { FoodOrderStatusOptions } from '../../../../../../support/FoodOrderStatus';
import { FoodOrderLocationTypes } from '../../../../../../support/FoodOrderLocationType';
import { SpecialRequestEscalationStateOptions } from '../../../../../../support/SpecialRequestEscalationState';
import { SpecialRequestAdditionalStateOptions } from '../../../../../../support/SpecialRequestAdditionalState';

function getCommonFilters(updateMethods, sites) {
  return [
    getSelectFilterField('reportType', 'Report Type', ReportTypeFilters, updateMethods),
    getMultiSelectFilterField(
      'siteIds',
      'Site',
      sites.map((s) => ({
        label: s.name,
        value: s.id,
      })),
      updateMethods
    ),
    getFilterField('startDate', 'Start Date', DATA_TYPE_DATE, updateMethods),
    getFilterField('endDate', 'End Date', DATA_TYPE_DATE, updateMethods),
    getSelectFilterField('timezone', 'Timezone', TimeZones, updateMethods),
  ];
}

const preArrivalFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('reservationNumber', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
    getFilterField('firstName', 'Given Name', DATA_TYPE_STRING, updateMethods),
    getFilterField('lastName', 'Family Name', DATA_TYPE_STRING, updateMethods),
    getFilterField('email', 'Email', DATA_TYPE_STRING, updateMethods),
    getSelectFilterField('reservationFetchType', 'Reservation Fetch Type', ReservationFetchTypeOptions, updateMethods),
  ];
};

const feedbackFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('reservationNumber', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
    getFilterField('feedbackScore', 'Feedback Score', DATA_TYPE_STRING, updateMethods),
  ];
};

const upsellFilers = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('offerCode', 'Package Code', DATA_TYPE_STRING, updateMethods),
    getSelectFilterField('offerType', 'Package Type', UpsellOfferTypeOptions, updateMethods),
  ];
};

const specialRequestFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('requestNumber', 'Request Number', DATA_TYPE_STRING, updateMethods),
    getSelectFilterField('requestState', 'Guest Contacted', SpecialRequestStateOptions, updateMethods, true, true),
    getMultiSelectFilterField('additionalState', 'Request Status', SpecialRequestAdditionalStateOptions, updateMethods),
    getSelectFilterField('escalation', 'Request Escalation', SpecialRequestEscalationStateOptions, updateMethods, true, true),
  ];
};

const paymentFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('reservationNumber', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
    getSelectFilterField('purchaseState', 'Purchase State', PaymentPurchaseStateOptions, updateMethods),
  ];
};

const roomSelectionFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('externalReference', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
  ];
};
const clickTrackingFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('reservationNumber', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
  ];
};

const reservationNotificationsFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    ...getCommonFilters(updateMethods, sites),
    getFilterField('reservationNumber', 'Reservation Number', DATA_TYPE_STRING, updateMethods),
    getSelectFilterField('state', 'Check-In/out', FlowStateOptions, updateMethods),
    getSelectFilterField('reminders', 'Reminders', SelectStateOptions, updateMethods),
  ];
};

const guestDetailFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [...getCommonFilters(updateMethods, sites)];
};

const membershipReportFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [...getCommonFilters(updateMethods, sites)];
};

const userAuditFilters = ({ state, setState, sites }, roles) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    getSelectFilterField('reportType', 'Report Type', ReportTypeFilters, updateMethods),
    getMultiSelectFilterField(
      'siteIds',
      'Site',
      sites.map((s) => ({
        label: s.name,
        value: s.id,
      })),
      updateMethods
    ),
    getFilterField('phoneNumber', 'Phone Number', DATA_TYPE_STRING, updateMethods),
    getFilterField('email', 'Email', DATA_TYPE_STRING, updateMethods),
    getFilterField('lastName', 'Last Name', DATA_TYPE_STRING, updateMethods),
    getMultiSelectFilterField(
      'roles',
      'Roles',
      roles.map((role) => ({
        label: UserRoleHelper.toFriendlyName(role),
        value: role,
      })),
      updateMethods
    ),
    getSelectFilterField('enabled', 'Enabled Status', SelectTrueFalseOptions, updateMethods),
  ];
};

const unsubscribedGuestsFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  return [
    getSelectFilterField('reportType', 'Report Type', ReportTypeFilters, updateMethods),
    getMultiSelectFilterField(
      'siteIds',
      'Site',
      sites.map((s) => ({
        label: s.name,
        value: s.id,
      })),
      updateMethods
    ),
    getMultiSelectFilterField('type', 'Type', UnsubscribedTypes, updateMethods),
    getFilterField('value', 'Value', DataTypes.DATA_TYPE_STRING, updateMethods),
  ];
};

const guestOrderFilters = ({ state, setState, sites }) => {
  const updateMethods = getFilterUpdateMethods(state, setState);
  console.log(state);
  return [
    getSelectFilterField('reportType', 'Report Type', ReportTypeFilters, updateMethods),
    getMultiSelectFilterField(
      'siteIds',
      'Site',
      sites.map((s) => ({
        label: s.name,
        value: s.id,
      })),
      updateMethods
    ),
    getFilterField('startDate', 'Start Date', DATA_TYPE_DATE, updateMethods),
    getFilterField('endDate', 'End Date', DATA_TYPE_DATE, updateMethods),
    getSelectFilterField('timezone', 'Timezone', TimeZones, updateMethods),
    getMultiSelectFilterField('status', 'Order Status', FoodOrderStatusOptions, updateMethods),
    getMultiSelectFilterField('locationType', 'Order Type', FoodOrderLocationTypes, updateMethods),
  ];
};

export {
  preArrivalFilters,
  feedbackFilters,
  upsellFilers,
  specialRequestFilters,
  paymentFilters,
  roomSelectionFilters,
  membershipReportFilters,
  clickTrackingFilters,
  reservationNotificationsFilters,
  guestDetailFilters,
  userAuditFilters,
  unsubscribedGuestsFilters,
  guestOrderFilters,
};
