import { Grid } from '@material-ui/core';
import SiteDashboardWidget from '../detail/widget/SiteDashboardWidget';
import DashboardSection from '../../../../../support/DashboardSection';
import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import Charts from '../detail/Charts';
import Objects from '../../../../../support/Objects';
import { useDispatch, useSelector } from 'react-redux';
import TabPanel from '../../../control/TabPanel';
import ChartCardPair from '../detail/chart/ChartCardPair';
import LivertonAreaChart from '../detail/chart/LivertonAreaChart';
import moment from 'moment/moment';
import * as DashboardActions from '../../../../redux/actions/dashboard/actions';
import LivertonBarChart from '../detail/chart/LivertonBarChart';

export default function FoodAndBeverageTab({ selectedTab, tabIndex, timeframe, selectedSite }) {
  const dispatch = useDispatch();
  const widgetData = useSelector((state) => state.dashboardReducer.irdWidgetData);
  const [ordersByVendorData, setOrdersByVendorData] = React.useState([]);
  const [revenueData, setRevenueData] = React.useState([]);
  const [ordersByPaymentTypeData, setOrdersByPaymentTypeData] = React.useState([]);
  const [tableViewMap, setTableViewMap] = useState({
    ordersByVendor: false,
    revenue: false,
    ordersByPaymentType: false,
  });

  useEffect(() => {
    const siteId = selectedSite?.id;
    if (!siteId || !timeframe || selectedTab !== tabIndex) {
      return;
    }

    const payload = {
      ...timeframe,
      start: moment(timeframe.start).format('YYYY-MM-DD'),
      end: moment(timeframe.end).format('YYYY-MM-DD'),
    };

    dispatch(DashboardActions.getIrdWidgetData(siteId, payload));

    API.post('PrivateAPI', `/api/private/portal/dashboard/ird/chart/vendor/orders/timeline?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setOrdersByVendorData(Charts.prepareChartLineData(response));
      })
      .catch((error) => {
        setOrdersByVendorData([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });

    API.post('PrivateAPI', `/api/private/portal/dashboard/ird/chart/revenue/timeline?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setRevenueData(Charts.prepareChartLineData(response));
      })
      .catch((error) => {
        setRevenueData([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });

    API.post('PrivateAPI', `/api/private/portal/dashboard/ird/chart/vendor/orders/payment/bar?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setOrdersByPaymentTypeData(Charts.prepareChartLineData(response));
      })
      .catch((error) => {
        setOrdersByPaymentTypeData([]);
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, timeframe, selectedTab]);

  return (
    <TabPanel value={selectedTab} index={tabIndex} p={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SiteDashboardWidget widgetData={widgetData} site={selectedSite} section={DashboardSection.FOOD_AND_BEVERAGE} />
        </Grid>

        <ChartCardPair
          charts={[
            {
              title: 'Orders per Vendor',
              subheader: 'Completed orders per vendor over time',
              tableView: tableViewMap.ordersByVendor,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, ordersByVendor: toggleValue }),
              component: (tableView) => (
                <LivertonAreaChart data={ordersByVendorData} tableView={tableView} columnOverrides={TABLE_LABELS} />
              ),
            },
            {
              title: 'Revenue per Vendor',
              subheader: 'Revenue per vendor over time',
              tableView: tableViewMap.revenue,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, revenue: toggleValue }),
              component: (tableView) => <LivertonAreaChart data={revenueData} tableView={tableView} columnOverrides={TABLE_LABELS} />,
            },
            {
              title: 'Orders by Payment Type',
              subheader: 'Number of orders per payment type per vendor',
              tableView: tableViewMap.ordersByPaymentType,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, ordersByPaymentType: toggleValue }),
              component: (tableView) => (
                <LivertonBarChart data={ordersByPaymentTypeData} tableView={tableView} columnOverrides={TABLE_LABELS} chartType={'bar'} />
              ),
            },
          ]}
        />
      </Grid>
    </TabPanel>
  );
}

const TABLE_LABELS = {
  x: 'Date',
};
