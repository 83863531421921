import Autocomplete from '@material-ui/lab/Autocomplete';
import DoorType from '../../../../../../support/DoorType';
import { TextField } from '@material-ui/core';
import React, { useMemo } from 'react';

export default function DoorSelector({ doorType = DoorType.PRIMARY, doorList = [], selectedDoorList, setSelectedDoorList }) {
  const availableDoorList = useMemo(() => {
    return doorList
      .filter((s) => s.doorType === doorType && !selectedDoorList.find((o) => o.value.doorId === s.doorId))
      .map((s) => {
        return { value: s, label: s.doorName };
      })
      .sort((s1, s2) => s1.label - s2.label);
  }, [doorList, doorType, selectedDoorList]);

  return (
    <Autocomplete
      fullWidth
      name={DoorType.PRIMARY === doorType ? 'Primary Doors' : 'Common Doors'}
      placeholder={
        DoorType.PRIMARY === doorType ? 'Select the primary doors for this reservation' : 'Select the common areas for this reservation'
      }
      options={availableDoorList}
      multiple
      getOptionSelected={(option, selected) => option.value === selected.value}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        setSelectedDoorList(newValue);
      }}
      value={selectedDoorList}
      renderInput={(params) => (
        <TextField
          {...params}
          error={DoorType.PRIMARY === doorType ? selectedDoorList.length <= 0 : false}
          label={DoorType.PRIMARY === doorType ? 'Primary Rooms' : 'Common Doors'}
          variant="outlined"
        />
      )}
    />
  );
}
