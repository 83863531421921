import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { CheckOutlined, DeleteOutlined, Edit, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import AccessControlGuestType from '../../../../../../support/AccessControlGuestType';
import API from '@aws-amplify/api';
import clsx from 'clsx';
import moment from 'moment';
import Objects from '../../../../../../support/Objects';

export default function GuestCard({ guest, showEditGuestModal, deleteGuest }) {
  const classes = useStyles();
  const [overview, setOverview] = React.useState({});
  const [processing, setProcessing] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (guest && guest.id) {
      setProcessing(true);

      API.get('PrivateAPI', '/api/private/portal/access/reservation/guest/overview/' + guest.id, {})
        .then((response) => {
          setOverview(response);
        })
        .catch((error) => {
          console.warn('Unable to get guest overview details', error);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [guest]);

  function showEditDialog() {
    if (showEditGuestModal) {
      showEditGuestModal(guest);
    }
  }

  function deleteCurrentGuest() {
    if (deleteGuest) {
      deleteGuest(guest);
    }
  }

  const overviewProfile = overview?.profile;
  const registeredDeviceCount = overview?.deviceList ? overview?.deviceList.length : 0;
  return (
    <Card className={classes.cardBackground}>
      <CardHeader
        title={`${guest.firstName} ${guest.lastName} (${Objects.capitalize(overviewProfile?.accountProvider)} Sign In)`}
        subheader={`${guest.email} (${guest.guestType || AccessControlGuestType.PRIMARY})`}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {guest.firstName[0].toUpperCase()}
          </Avatar>
        }
        action={
          <React.Fragment>
            <IconButton onClick={showEditDialog}>
              <Edit />
            </IconButton>
            <IconButton onClick={deleteCurrentGuest}>
              <DeleteOutlined />
            </IconButton>
          </React.Fragment>
        }
      />

      {/*{overviewProfile &&*/}
      {/*<CardContent className={classes.reducedPaddingCardInner}>*/}
      {/*  <Typography variant='body2' component='p'>*/}
      {/*    {overview.profile.loggedIn ? `App installed by customer on ${registeredDeviceCount} devices.` : 'Customer invited but has not installed the App.'}*/}
      {/*  </Typography>*/}
      {/*</CardContent>*/}
      {/*}*/}

      {overviewProfile && (
        <CardActions>
          <Button size="small" variant={'text'} color={'primary'} startIcon={overview?.profile?.loggedIn ? <CheckOutlined /> : null}>
            {overview?.profile?.loggedIn ? `App Installed On ${registeredDeviceCount} Devices` : 'No Signed In Yet'}
          </Button>

          <Button size="small" onClick={() => setExpanded(!expanded)}>
            {getMobileKeyStatusText(overview)}
          </Button>

          {registeredDeviceCount > 0 && (
            <IconButton
              color={'secondary'}
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {processing ? <CircularProgress size={15} /> : <ExpandMore />}
            </IconButton>
          )}
        </CardActions>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.reducedPaddingCardInner}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Device ID</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Device Build ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Endpoint ID</TableCell>
                <TableCell>SEOS ID</TableCell>
                <TableCell>Registered</TableCell>
                <TableCell>Key List</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overview?.deviceList?.map((registration) => {
                const keyList = overview?.keyMap[registration.deviceId] || [];
                return (
                  <TableRow key={registration.deviceId}>
                    <TableCell>{registration.deviceId}</TableCell>
                    <TableCell>{registration.deviceName}</TableCell>
                    <TableCell>{registration.deviceBuildId}</TableCell>
                    <TableCell>{registration.status}</TableCell>
                    <TableCell>{registration.lockProviderEndpoint}</TableCell>
                    <TableCell>{registration.lockProviderReference}</TableCell>
                    <TableCell>{moment.unix(registration.created).format('LLL')}</TableCell>
                    <TableCell>{`${keyList.length} Keys`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function getMobileKeyStatusText(overview) {
  if (overview?.deviceList?.length > 0) {
    for (let registration of overview.deviceList) {
      const keys = overview.keyMap[registration.deviceId];
      if (keys?.length > 0) {
        return 'Keys Issued';
      }
    }

    return 'Registered Keys Not Issued';
  } else {
    return 'Phone Not Registered';
  }
}

const useStyles = makeStyles((theme) => ({
  cardBackground: {
    backgroundColor: theme.type === 'dark' ? 'hsl(210,10%,20%)' : 'hsl(210,10%,92%)',
    margin: '1.0rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  reducedPaddingCardInner: {
    padding: '0 1.0rem',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));
