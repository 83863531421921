export const FoodOrderLocationType = {
  ROOM: 'ROOM',
  TABLE: 'TABLE',
  COMMON_AREA: 'COMMON_AREA',
  STATIC_ROOM: 'STATIC_ROOM',
  DIRECT: 'DIRECT',
};

export const FoodOrderLocationTypeAlias = {
  ROOM: 'r',
  STATIC_ROOM: 'sr',
  TABLE: 't',
  COMMON_AREA: 'c',
  DIRECT: 'd',
};

export const FoodOrderLocationTypes = [
  { label: 'Room', value: FoodOrderLocationType.ROOM },
  { label: 'Static Room', value: FoodOrderLocationType.STATIC_ROOM },
  { label: 'Table', value: FoodOrderLocationType.TABLE },
  { label: 'Common Area', value: FoodOrderLocationType.COMMON_AREA },
];

export function formatLocationType(type) {
  return FoodOrderLocationTypes.find((t) => t.value === type)?.label || type;
}

export const FoodOrderLocationTypesAlias = [
  { label: 'Room', value: FoodOrderLocationTypeAlias.ROOM },
  { label: 'Static Room', value: FoodOrderLocationTypeAlias.STATIC_ROOM },
  { label: 'Table', value: FoodOrderLocationTypeAlias.TABLE },
  { label: 'Common Area', value: FoodOrderLocationTypeAlias.COMMON_AREA },
];
