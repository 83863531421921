export const FoodChargeCategory = {
  SERVICE: 'SERVICE',
  EXTRA: 'EXTRA',
  STATIC_ITEM: 'STATIC_ITEM',
};

export const FoodChargeCategories = [
  { label: 'Service', value: FoodChargeCategory.SERVICE },
  { label: 'Extra', value: FoodChargeCategory.EXTRA },
  { label: 'Static Item', value: FoodChargeCategory.STATIC_ITEM },
];
