import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fade, Hidden, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as AccessActions from '../../redux/actions/access/actions';
import { ApartmentOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  outer: {
    margin: '0 0.5rem',
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  listItemText: {
    color: theme.palette.secondary.main,
  },
}));

export default function SiteSelector() {
  const selectorStyles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);

  const sitesFiltered = useMemo(() => {
    return selectedCorporation
      ? siteList.filter((site) => selectedCorporation.id === site.corporationId).sort((s1, s2) => s1.name.localeCompare(s2.name))
      : [];
  }, [selectedCorporation, siteList]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, site) => {
    dispatch(AccessActions.selectSite(site));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonDisabled = sitesFiltered?.length <= 1;
  return (
    <div className={selectorStyles.outer}>
      <Hidden mdUp>
        <IconButton
          aria-label="site-selection"
          aria-controls="site-selection"
          aria-haspopup="true"
          color={'inherit'}
          disabled={buttonDisabled}
          onClick={handleClickListItem}
        >
          <ApartmentOutlined />
        </IconButton>
      </Hidden>

      <Hidden smDown>
        <List component="nav" aria-label="Site Selection" color={'primary'}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Current Site"
            className={selectorStyles.listItem}
            disabled={buttonDisabled}
            onClick={handleClickListItem}
          >
            <ListItemText
              primary="Current Site"
              secondary={selectedSite ? selectedSite.name : 'No Site Selected'}
              secondaryTypographyProps={{ className: selectorStyles.listItemText }}
            />
          </ListItem>
        </List>
      </Hidden>

      <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} TransitionComponent={Fade}>
        {sitesFiltered.map((s) => (
          <MenuItem
            key={`site-option-${s.id}`}
            selected={selectedSite && selectedSite.id === s.id}
            onClick={(event) => handleMenuItemClick(event, s)}
          >
            {s.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
