import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/securityEventSetting/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import Objects from '../../../../../support/Objects';

const columns = [
  {
    id: 'eventName',
    label: 'Event Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'lockEventCode',
    label: 'Lock Event Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'securityEventCode',
    label: 'Security Event Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'eventPriority',
    label: 'Event Priority',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'eventMessage',
    label: 'Event Message',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return Objects.dropLongSentence(values.eventMessage);
    },
  },
];

export default function SecurityEventSettingList() {
  const dispatch = useDispatch();
  const eventSettingList = useSelector((state) => state.securityEventSettingReducer.entityList);
  const lastActionType = useSelector((state) => state.securityEventSettingReducer.lastActionType);
  const page = useSelector((state) => state.securityEventSettingReducer.page);
  const processing = useSelector((state) => state.securityEventSettingReducer.processing);
  const error = useSelector((state) => state.securityEventSettingReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const parameters = { siteId: selectedSite?.id, sort: 'eventName,asc' };

  return (
    <AutoTable
      title="Event Setting"
      subheader={'Each event can be mapped between lock system and security system'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 10, parameters))}
      selectItem={(item) => dispatch(Actions.select(item))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, parameters))}
      processing={processing}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECURITY_EVENT_SETTING"
      buttonName="New Event Setting"
      createNewURL="/security/event-setting/new"
      detailURL="/security/event-setting/view/{id}"
      idColumn={'id'}
      data={eventSettingList}
      lastActionType={lastActionType}
    />
  );
}
