import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import FormField from '../../../../../components/form/FormField';

export default function ThemeMessageDialog({ open, setOpen, addThemeMessages, languageList = [] }) {
  const [messageKey, setMessageKey] = useState('');
  const [messageValues, setMessageValues] = useState({});

  useEffect(() => {
    if (!open) {
      setMessageKey('');
      setMessageValues({});
    }
  }, [open]);

  const onDialogSave = useCallback(() => {
    const prepared = Object.keys(messageValues).map((m) => ({
      messageKey,
      messageValue: messageValues[m],
      languageCode: m,
      overridden: true,
      removal: false,
    }));

    addThemeMessages(prepared);

    setOpen(false);
  }, [addThemeMessages, messageKey, messageValues, setOpen]);

  const isDialogDisabled = !messageKey || Object.keys(messageValues).length === 0;
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-title">New Translation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Add customised translations for things like packages, room types or custom wording
        </DialogContentText>

        <FormField label={'Message Key'} value={messageKey} setValue={setMessageKey} />

        {languageList.map((l) => (
          <FormField
            label={`Message ${l.displayName} (${l.name})`}
            key={`cm-${l.code}`}
            value={messageValues[l.code]}
            setValue={(value) =>
              setMessageValues({
                ...messageValues,
                [l.code]: value,
              })
            }
          />
        ))}
      </DialogContent>
      <DialogActions className={'mb-1'}>
        <Button onClick={() => setOpen(false)} autoFocus>
          Close
        </Button>
        <Button color="primary" variant={'contained'} className={'mr-2'} onClick={onDialogSave} disabled={isDialogDisabled}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
