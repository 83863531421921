import timeZoneList from './TimeZones';

export const DEFAULT_TIMEZONE = { label: 'Pacific/Auckland', value: 'Pacific/Auckland' };

export default class SiteUtil {
  static getSiteZone = (site) => {
    if (site) {
      return timeZoneList.find((zone) => zone.value === site.zoneId) || DEFAULT_TIMEZONE;
    } else {
      return DEFAULT_TIMEZONE;
    }
  };
}
