import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/reservation/hotel/actions';
import SpecialRequestActions from '../../../../redux/actions/specialRequest/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { DeleteOutlined, Sync } from '@material-ui/icons';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';
import useDebounce from '../../../../../support/hook/useDebounce';
import useFetch from '../../../../../support/hook/useFetch';

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('confirmationNumber', 'Reservation Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('lastName', 'Last Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('roomNumber', 'Room Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
}

export default function HotelReservationList({ title = 'Hotel Reservations', subheader = 'Hotel reservations for your property', status }) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [parameters, setParameters] = useState({
    status: status,
    confirmationNumber: '',
    lastName: '',
    roomNumber: '',
    sort: 'arrivalDate,desc',
  });
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/${selectedSiteId}/reservations`,
    useDebounce(parameters)
  );
  const filters = getFilterFieldsForScreen(parameters, setParameters);

  useEffect(() => {
    dispatch(Actions.unselectReservation());
    dispatch(SpecialRequestActions.unselectRequests());
  });

  return (
    <AutoTable
      title={title}
      subheader={subheader}
      secondaryButtonName={'Resync'}
      secondaryButtonIcon={<Sync />}
      secondaryButtonAction={() => Actions.resyncReservations({ siteId: selectedSiteId })}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      selectedSite={selectedSite}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      processing={processing}
      prefix={'HOTEL_RESERVATION'}
      createNewURL="/reservation/hotel/new"
      detailURL="/reservation/hotel/view/{id}"
      data={data}
      filters={filters}
      idColumn={'id'}
    />
  );
}

// Table column rendering information.
const columns = [
  {
    id: 'controls',
    label: '',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: (row, dispatch) => {
      return (
        <Button onClick={() => dispatch(Actions.delete(row, `/api/private/portal/${row.siteId}/reservations/${row.id}`))}>
          <DeleteOutlined />
        </Button>
      );
    },
  },
  {
    id: 'confirmationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'givenName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'familyName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'roomNumber',
    label: 'Room',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'arrivalDate',
    label: 'Arrival Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'departureDate',
    label: 'Departure Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'phone',
    label: 'Phone',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'entityType',
    label: 'Entity Type',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];
