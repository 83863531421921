const RoomNumberMode = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
};

const SelectionMode = {
  NO_ROOMS_ONLY: {
    value: 'NO_ROOMS_ONLY',
    displayName: 'Guests with NO Room',
  },
  SAME_CATEGORY: {
    displayName: 'Same Category',
    value: 'SAME_CATEGORY',
  },
  UPSELL: {
    displayName: 'Upsell',
    value: 'UPSELL',
  },
};

const UpsellMode = {
  ALL: {
    displayName: 'Everyone',
    value: 'ALL',
  },
  NO_ROOMS: {
    displayName: 'Guests No Rooms',
    value: 'NO_ROOMS',
  },
};

export { RoomNumberMode, SelectionMode, UpsellMode };
