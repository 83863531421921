import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/roomSelection/roomFloor/actions';
import toBase64 from '../../../../../support/toBase64Converter';
import { Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import FormField from '../../../../../components/form/FormField';
import { AddRounded } from '@material-ui/icons';

export default function RoomSelectionFloors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedItem = useSelector((state) => state.roomSelectionReducer.selectedItem);
  const lastActionType = useSelector((state) => state.roomFloorReducer.lastActionType);
  const error = useSelector((state) => state.roomFloorReducer.error);
  const data = useSelector((state) => state.roomFloorReducer.entityList);

  const [list, setList] = React.useState(data);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'ROOM_FLOOR_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      images: [...list],
    };
    dispatch(Actions.update(payload, `/api/private/portal/roomSelection/${selectedItem.id}/floors`, false));
  };

  const handleChange = async (index, event, name, remove = false) => {
    const rowsInput = [...list];
    if (name === 'imageUrl') {
      if (!remove) {
        const image = await toBase64(event);
        const imageName = event?.name;
        let originalUrl = rowsInput[index]['imageUrl'];
        rowsInput[index]['imageUrl'] = image ? image : rowsInput[index]['imageUrl'];
        rowsInput[index]['imageName'] = imageName
          ? imageName
          : originalUrl
          ? originalUrl.substring(originalUrl.lastIndexOf('/') + 1)
          : null;
      } else {
        rowsInput[index]['imageUrl'] = null;
        rowsInput[index]['imageName'] = null;
      }
    } else {
      rowsInput[index][name] = event;
    }
    setList(rowsInput);
  };
  const handleAddNewRow = () => {
    const rowsInput = {
      id: null,
      floorNumber: '',
      imageUrl: null,
      imageName: null,
    };
    setList([...list, rowsInput]);
  };

  return (
    <Card>
      <CardHeaderWithControls
        header="Update Floors"
        subheader="Floor images in SVG format for each floor present at the hotel"
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={list.length === 0}
        secondaryButtonName={'New Floor'}
        secondaryButtonAction={handleAddNewRow}
        secondaryButtonIcon={<AddRounded />}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/save floor data - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Floor</TableCell>
                <TableCell>Floor Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <FormField value={row.floorNumber} setValue={(event) => handleChange(index, event, 'floorNumber')} />
                  </TableCell>
                  <TableCell>
                    <FileImageSelector
                      sourceItem={row}
                      sourceItemProperty={'imageUrl'}
                      onSelect={(file) => handleChange(index, file, 'imageUrl')}
                      imageCleared={() => handleChange(index, null, 'imageUrl', true)}
                      displayName={'Floor SVG Image ' + index}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </CardContent>
    </Card>
  );
}
