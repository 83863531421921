module.exports = {
  NONE: {
    code: 0,
    displayName: 'No Action',
  },
  REQUIRE_AUTHORISATION: {
    code: 1,
    displayName: 'Check-In Authorization',
  },
  REQUIRE_PAYMENT: {
    code: 2,
    displayName: 'Check-In Payment',
  },
  REQUIRE_NO_PAYMENT: {
    code: 3,
    displayName: 'Check-In No Payment',
  },
  BLACKLIST: {
    code: 4,
    displayName: 'Blacklist Reservation',
  },
  BLOCKED: {
    code: 5,
    displayName: 'Block Reservation',
  },
  ADD_UDF_FIELD: {
    code: 6,
    displayName: 'Add UDF Field',
  },
  SKIP_REMAINING_ACTIONS: {
    code: 7,
    displayName: 'Skip Remaining Actions',
  },
  SKIP_REMAINING_RULES: {
    code: 8,
    displayName: 'Skip Remaining Rules',
  },
  RUN_EXECUTOR: {
    code: 9,
    displayName: 'Run Executor',
  },
  NO_POST: {
    code: 10,
    displayName: 'Set NO POST',
  },
  MAX_PAX: {
    code: 11,
    displayName: 'Set MAX PAX',
  },
  REMOVE_EMAIL: {
    code: 12,
    displayName: 'Remove Email',
  },
  COMMON_AREA_ACTION: {
    code: 13,
    displayName: 'Add Common Area',
  },
  ALLOW_NOTIFICATIONS: {
    code: 14,
    displayName: 'Allow Guest Notification',
  },
  ADD_RESERVATION_INCLUSION: {
    code: 15,
    displayName: 'Add Reservation Inclusions',
  },
  ADD_RESERVATION_MESSAGE: {
    code: 16,
    displayName: 'Add Reservation Message',
  },
  SKIP_NOTIFICATION: {
    code: 17,
    displayName: 'Skip Notification',
  },
  HIDE_PACKAGE_ACTION: {
    code: 18,
    displayName: 'Hide Package',
  },
  SHOW_PACKAGE_ACTION: {
    code: 19,
    displayName: 'Show Package',
  },
  REQUIRE_TOKENIZATION: {
    code: 20,
    displayName: 'Check-In Tokenize',
  },
};
