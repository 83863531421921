import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CheckOutlined, DeleteOutline, FileCopyOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  imageSize: {
    maxWidth: '100px',
    maxHeight: '100px',
  },
  media: {
    height: 0,
    margin: '0 auto',
    backgroundSize: 'contain',
    maxWidth: '200px',
    aspectRatio: 'auto',
    paddingTop: '25%', // 16:9
  },
}));

export default function FileImageSelector({ sourceItem, sourceItemProperty, displayName, onSelect, imageCleared, accept, fullWidth }) {
  const id = `${displayName}-id`.replace(/\s/g, '').toLowerCase();
  const [image, setImage] = React.useState(null);
  const [cleared, setCleared] = React.useState(null);
  const imageUrl = sourceItem ? sourceItem[sourceItemProperty] : null;
  const classes = useStyles();

  const openFileWindow = () => {
    document.getElementById(id).click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    setImage(file);
    onSelect(file);
    setCleared(false);
    imageCleared(false);
  };

  const handleFileClear = () => {
    setImage(null);
    onSelect(null);
    setCleared(true);
    imageCleared(true);
  };

  const getName = accept ? 'File' : 'Image';
  return (
    <React.Fragment>
      <input
        type="file"
        style={{ display: 'none' }}
        id={id}
        accept={accept || 'image/*'}
        name={sourceItemProperty}
        onChange={handleFileSelected}
      />

      <Grid item xs={12} sm={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 6}>
        <Card variant={'outlined'}>
          <CardHeader
            title={`${displayName}: `}
            titleTypographyProps={{ variant: 'body1' }}
            action={
              <React.Fragment>
                <Button size="small" onClick={openFileWindow} variant={'outlined'} color={image ? 'primary' : 'inherit'}>
                  {image && <CheckOutlined />}
                  {!image && <FileCopyOutlined />}
                  {image ? `${getName} Selected` : imageUrl ? `Change ${getName}` : `Select ${getName}`}
                </Button>
                <Button size="small" onClick={handleFileClear} variant={'outlined'} className={'ml-1'}>
                  <DeleteOutline />
                  Clear
                </Button>
              </React.Fragment>
            }
          />

          {!cleared && imageUrl && (
            <CardMedia
              className={classes.media}
              style={{ height: imageUrl.includes('.svg') ? '250px' : '' }}
              image={imageUrl}
              title="Image View"
            />
          )}

          {image && (
            <CardContent>
              <Typography variant="body1" color={'primary'}>
                {`Pending Upload: ` + image.name}
                <Button size="small" onClick={handleFileClear} variant={'outlined'} className="ml-1">
                  Undo Changes
                </Button>
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
    </React.Fragment>
  );
}
