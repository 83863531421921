import CardHeaderWithControls from '../card/CardHeaderWithControls';
import React from 'react';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import FormField from '../form/FormField';
import FormSelect from '../form/FormSelect';
import { PaymentPurchaseStateChargeOptions } from '../../support/PurchaseProcessState';
import FormBooleanSelect from '../form/FormBooleanSelect';

export default function PaymentProcessor({
  amount,
  selectedTransaction,
  setSelectedTransaction,
  surcharge,
  type,
  setType,
  postToPms,
  setPostToPms,
  transactions,
  handleSubmit,
  handleChange,
  maxAmount,
  isLoading,
}) {
  const isFormReady = selectedTransaction && amount > 0 && (selectedTransaction?.authCode || selectedTransaction?.cardToken);

  const getFilteredTransactions = transactions
    .filter((t) => t.state === 'COMPLETED')
    .filter(function (s) {
      if (type === 'REFUND') {
        return s.type === 'TOKEN' || s.type === 'PAYMENT' || s.type === 'COMPLETION';
      } else if (type === 'AUTH_COMPLETE') {
        return s.type === 'AUTHORISATION';
      } else if (type === 'CHARGE') {
        return s.type === 'TOKEN' || s.type === 'PAYMENT' || s.type === 'TOKENIZE';
      } else {
        return false;
      }
    });

  const getSelectionLabelAndValue =
    getFilteredTransactions.length > 0
      ? getFilteredTransactions.map((p) => {
          return { value: p, label: p.reference };
        })
      : [{ label: 'No Transactions available for this type', value: null }];

  return (
    <Card>
      <CardHeaderWithControls
        header={'Payment Processing'}
        subheader={'Charge/Refund/Capture for existing transactions'}
        buttonName={'Process'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        buttonLoading={isLoading}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Payment Type'} value={type} setValue={setType} options={PaymentPurchaseStateChargeOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Transaction'}
              value={selectedTransaction}
              setValue={setSelectedTransaction}
              error={!selectedTransaction}
              options={getSelectionLabelAndValue}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Amount"
              variant={'outlined'}
              type="number"
              min={'0'}
              max={maxAmount}
              onChange={handleChange}
              value={amount}
              error={!amount}
              required
              margin={'dense'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Surcharge'} value={surcharge} disabled={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Post To PMS'} value={postToPms} setValue={setPostToPms} />
          </Grid>
          {selectedTransaction && (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Card Number'} value={selectedTransaction.cardNumber} disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Card Type'} value={selectedTransaction.cardType} disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Card Expiry'} value={selectedTransaction.cardExpiry} disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Auth Token'} value={selectedTransaction.authCode} disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormField label={'Card Token'} value={selectedTransaction.cardToken} disabled={true} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
