import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import ActivityMappingActions from '../../../redux/actions/activity/mapping/actions';
import ActivityActions from '../../../redux/actions/activity/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Chip, Link } from '@material-ui/core';
import API from '@aws-amplify/api';
import { getFilterField, getFilterUpdateMethods, getSelectFilterField } from '../../../../components/table/filter/filterUtils';
import { useHistory } from 'react-router-dom';

const handleClick = (value) => (dispatch) => async (event) => {
  let response;
  value.enabled = false;

  try {
    if (event.target.checked) {
      value.enabled = true;
      response = await API.post('PrivateAPI', `/api/private/portal/activity/mapping/${value.siteId}`, { body: value.id });
    } else {
      response = await API.del('PrivateAPI', `/api/private/portal/activity/mapping/${value.siteId}`, { body: value.id });
    }

    dispatch(ActivityMappingActions.toggle(value));
    console.info('Successfully updated activity mapping', response);
  } catch (error) {
    dispatch(ActivityMappingActions.toggleError(error));
    console.warn('Unable to update activity mapping due to error', error);
  }
};

const selectItem = (item, history) => (dispatch) => {
  dispatch(ActivityActions.selectById(item.id, history));
};

const columns = (history) => {
  return [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value, dispatch) {
        return <Link component="button" onClick={() => dispatch(selectItem(value, history))} children={value.name} />;
      },
    },
    {
      id: 'vendorName',
      label: 'Vendor',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'tags',
      label: 'Tags',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.tags && value.tags.length > 0) {
          return value.tags.map((val) => <Chip key={val} label={val} />);
        }
        return null;
      },
    },
    {
      id: 'enabled',
      label: 'Enabled',
      align: 'center',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value, dispatch) {
        return <Checkbox checked={value.enabled} onChange={dispatch(handleClick(value))} />;
      },
    },
  ];
};

const getFilterFieldsForScreen = (state, setState, options) => {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getSelectFilterField('vendor', 'Vendor', options, filterUpdateMethods),
    getFilterField('tag', 'Tag', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
  ];
};

export default function ActivityListMapping() {
  const dispatch = useDispatch();
  const history = useHistory();
  const activityMapping = useSelector((state) => state.activityMappingReducer.entityList);
  const processing = useSelector((state) => state.activityMappingReducer.processing);
  const page = useSelector((state) => state.activityMappingReducer.page);
  const error = useSelector((state) => state.activityMappingReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [vendorNames, setVendorNames] = useState([]);
  const [filterState, setFilterState] = useState({
    vendor: '',
    tag: '',
  });

  useEffect(() => {
    const params = { siteId: selectedSiteId };

    if (filterState['vendor'] || filterState['tag']) {
      if (filterState['vendor']) {
        params.vendorId = filterState['vendor'];
      }

      if (filterState['tag']) {
        params.tag = filterState['tag'];
      }
    }

    dispatch(ActivityMappingActions.fetch(0, 25, params));
  }, [dispatch, filterState, selectedSiteId]);

  const filters = getFilterFieldsForScreen(filterState, setFilterState, vendorNames);

  useEffect(() => {
    API.get('PrivateAPI', '/api/private/portal/vendor/name', {})
      .then((response) => {
        setVendorNames(response.map((vendor) => ({ label: vendor.name, value: vendor.id })));
      })
      .catch((error) => {
        console.warn('Unable to get vendors due to error', error);
      });
  }, []);

  return (
    <AutoTable
      title="Activity Mapping"
      filters={filters}
      subheader={'An activity is a service provided by a vendor'}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(ActivityMappingActions.fetch(newPage, pageSize))}
      page={page}
      error={error}
      columns={columns(history)}
      prefix="ACTIVITY_MAPPING"
      data={activityMapping}
      clearableFilter={true}
    />
  );
}
