import UserRoleHelper from '../../support/UserRoleHelper';
import UserRole from '../../support/UserRole';
import React, { useState } from 'react';
import { Badge, Box, Collapse, Divider, ListItem, ListItemIcon, ListItemText, MenuList, Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LivertonLogo from '../../assets/img/liverton-logo-white.svg';
import {
  BarChartOutlined,
  BuildOutlined,
  CalendarTodayOutlined,
  CheckBoxOutlineBlank,
  CloudCircleOutlined,
  ColorizeOutlined,
  CreditCardOutlined,
  EmailOutlined,
  ExitToAppOutlined,
  Gavel,
  GroupOutlined,
  HighlightOutlined,
  HotelOutlined,
  HouseOutlined,
  KingBed,
  LocalOfferOutlined,
  LocationCity,
  MeetingRoom,
  MessageOutlined,
  NoteOutlined,
  PaymentOutlined,
  PhoneAndroidOutlined,
  PlaylistAddCheck,
  InsertInvitation,
  SupervisedUserCircle,
  ExpandLess,
  ExpandMore,
  LocalBar,
  RateReview,
  FolderSpecial,
  TableChart,
  StorefrontOutlined,
  RowingOutlined,
  NotificationsActive,
  RoomOutlined,
  VpnKeySharp,
  AccountBalanceWallet,
  Schedule,
  EventOutlined,
  SwapHoriz,
  CardTravel,
  Settings,
  FaceTwoTone,
  Restaurant,
  DeckOutlined,
  LocalDining,
  EditAttributes,
  AlarmAdd,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SiteFeature from '../../support/SiteFeature';
import SystemCategory from '../../constants/SystemCategory';

function doesSiteHavePms(site) {
  return site && site.systems?.filter((s) => s.systemCategory === SystemCategory.PMS).length > 0;
}

function doesSiteHavePos(site) {
  return site && site.systems?.filter((s) => s.systemCategory === SystemCategory.FOOD_POS_SYSTEM).length > 0;
}

function doesSiteHaveLock(site) {
  return site && site.systems?.filter((s) => s.systemCategory === SystemCategory.LOCK).length > 0;
}

function doesSiteHaveSecurity(site) {
  return site && site.systems?.filter((s) => s.systemCategory === SystemCategory.SECURITY).length > 0;
}

function doesSiteHaveFeature(site, feature) {
  return site && site?.featureList?.filter((sf) => sf.code === feature).length > 0;
}

/**
 * Menu Sections
 */
const AdminSections = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <BarChartOutlined />,
    role: UserRole.ROLE_SITE_ADMIN,
    isShown: () => true,
  },
  {
    label: 'Calendar',
    path: '/calendar',
    icon: <EventOutlined />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'Organisation List',
    path: '/corporations',
    icon: <LocationCity />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'Platform List',
    path: '/platforms',
    icon: <CloudCircleOutlined />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'User List',
    path: '/account/users',
    icon: <GroupOutlined />,
    role: UserRole.ROLE_SITE_ADMIN,
    isShown: () => true,
  },
  {
    label: 'App List',
    path: '/apps',
    icon: <PhoneAndroidOutlined />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'App Users',
    path: '/account/app-users',
    icon: <SupervisedUserCircle />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'Licenses',
    path: '/licenses',
    icon: <NoteOutlined />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'Wallet',
    path: '/wallet',
    icon: <AccountBalanceWallet />,
    role: UserRole.ROLE_SUPER,
    isShown: () => true,
  },
  {
    label: 'Organisation Management',
    groupKey: 'org',
    labelSecondary: (selectedCorporation) => selectedCorporation?.name,
    role: UserRole.ROLE_CORP_ADMIN,
    isShown: (selectedCorporation) => selectedCorporation,
    children: [
      { label: 'Sites', path: '/sites', icon: <HotelOutlined />, role: UserRole.ROLE_CORP_ADMIN },
      { label: 'Calendar', path: '/organisation/calendar', icon: <EventOutlined />, role: UserRole.ROLE_CORP_ADMIN },
      { label: 'Themes', path: '/themes', icon: <ColorizeOutlined />, role: UserRole.ROLE_CORP_ADMIN },
      { label: 'Payments', path: '/payment-providers', icon: <CreditCardOutlined />, role: UserRole.ROLE_CORP_ADMIN },
      { label: 'Notification Schemes', path: '/scheme/notifications', icon: <VpnKeySharp />, role: UserRole.ROLE_SUPER },
      { label: 'Food Filters', path: '/food/corporation/filters', icon: <EditAttributes />, role: UserRole.ROLE_CORP_ADMIN },
    ],
  },
  {
    label: 'Site Management',
    groupKey: 'site',
    labelSecondary: (selectedCorporation, selectedSite) => selectedSite?.name,
    role: UserRole.ROLE_SITE_ADMIN,
    isShown: (selectedCorporation, selectedSite) => selectedSite,
    children: [
      {
        label: 'Site Calendar',
        path: '/site/calendar',
        icon: <EventOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Site Features',
        path: '/site/features',
        icon: <CheckBoxOutlineBlank />,
        role: UserRole.ROLE_SUPER,
      },
      {
        label: 'Site Systems',
        path: '/site/systems',
        icon: <BuildOutlined />,
        role: UserRole.ROLE_CORP_ADMIN,
      },
      {
        label: 'Notifications',
        path: '/site/notifications',
        icon: <NotificationsActive />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Site Rules',
        path: '/site/rules',
        icon: <Gavel />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Site Templates',
        path: '/site/templates',
        icon: <EmailOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Upsell Offers',
        path: '/site/upsell',
        icon: <LocalOfferOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Payment Types',
        path: '/site/payment-types',
        icon: <PaymentOutlined />,
        role: UserRole.ROLE_CORP_ADMIN,
      },
      {
        label: 'Reservation Fields',
        path: '/site/reservation-fields',
        icon: <PlaylistAddCheck />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Room Selection',
        path: '/site/roomSelection',
        icon: <RoomOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Facial Recognition',
        path: '/site/facialRecognition',
        icon: <FaceTwoTone />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Site Rooms',
        path: '/site/rooms',
        icon: <MeetingRoom />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Room Types',
        path: '/site/roomTypes',
        icon: <KingBed />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Room Amenities',
        path: '/site/amenities',
        icon: <HighlightOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Minibar',
        path: '/site/minibar',
        icon: <LocalBar />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Feedback',
        path: '/site/feedback',
        icon: <RateReview />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Digital Compendium',
        path: '/site/compendium/category',
        icon: <TableChart />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
    ],
  },
  {
    label: 'Security System',
    groupKey: 'sec',
    labelSecondary: (selectedCorporation, selectedSite) => selectedSite?.name,
    role: UserRole.ROLE_SITE_ADMIN,
    isShown: (selectedCorporation, selectedSite) => doesSiteHaveSecurity(selectedSite),
    children: [
      { label: 'Event Setting', path: '/security/event-setting', icon: <Settings />, role: UserRole.ROLE_SITE_ADMIN },
      { label: 'Events', path: '/security/events', icon: <EventOutlined />, role: UserRole.ROLE_SITE_ADMIN },
      { label: 'Cardholders', path: '/security/cardholders', icon: <CardTravel />, role: UserRole.ROLE_SITE_ADMIN },
      { label: 'Room Mapping', path: '/security/room-mapping', icon: <SwapHoriz />, role: UserRole.ROLE_SITE_ADMIN },
      { label: 'Security Rooms', path: '/security/rooms', icon: <MeetingRoom />, role: UserRole.ROLE_SITE_ADMIN },
    ],
  },
  {
    label: 'In-Room Ordering',
    groupKey: 'iro',
    role: UserRole.ROLE_SITE_ADMIN,
    labelSecondary: (selectedCorporation, selectedSite) => selectedSite?.name,
    isShown: (selectedCorporation, selectedSite) => doesSiteHaveFeature(selectedSite, SiteFeature.IN_ROOM_ORDERING),
    children: [
      {
        label: 'Food Vendors',
        path: '/food/vendor',
        icon: <Restaurant />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Price Schedule',
        path: '/food/price/schedule',
        icon: <AlarmAdd />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Locations',
        path: '/food/order/location',
        icon: <DeckOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
      {
        label: 'Payment Types',
        path: '/food/payment-types',
        icon: <PaymentOutlined />,
        role: UserRole.ROLE_SITE_ADMIN,
      },
    ],
  },
  {
    label: 'External',
    groupKey: 'ext',
    role: UserRole.ROLE_CORP_ADMIN,
    labelSecondary: (selectedCorporation, selectedSite) => selectedSite?.name,
    isShown: (selectedCorporation, selectedSite) => doesSiteHaveFeature(selectedSite, SiteFeature.ACTIVITY),
    children: [
      {
        label: 'Vendors',
        path: '/vendor',
        icon: <StorefrontOutlined />,
        role: UserRole.ROLE_CORP_ADMIN,
      },
      {
        label: 'Activities',
        path: '/activity/mapping',
        icon: <RowingOutlined />,
        role: UserRole.ROLE_CORP_ADMIN,
      },
    ],
  },
];
const UserSections = [
  {
    label: 'General',
    isShown: () => true,
    children: [{ label: 'Dashboard', path: '/reservation/dashboard', icon: <BarChartOutlined /> }],
  },
  {
    label: 'Access Control',
    isShown: (selectedCorporation, selectedSite) => doesSiteHaveLock(selectedSite),
    children: [
      { label: 'Future Arrivals', path: '/reservation/access/upcoming', icon: <CalendarTodayOutlined /> },
      { label: 'Arrived', path: '/reservation/access/current', icon: <HouseOutlined /> },
      { label: 'Past Guests', path: '/reservation/access/past', icon: <ExitToAppOutlined /> },
    ],
  },
  {
    label: 'Hotel Reservations',
    isShown: (selectedCorporation, selectedSite) => doesSiteHavePms(selectedSite),
    children: [
      { label: 'Arrivals', path: '/reservation/hotel/arrivals', icon: <CalendarTodayOutlined /> },
      { label: 'In House', path: '/reservation/hotel/current', icon: <HouseOutlined /> },
      { label: 'Past Guests', path: '/reservation/hotel/past', icon: <ExitToAppOutlined /> },
    ],
  },
  {
    label: 'Reports',
    isShown: (selectedCorporation, selectedSite) => doesSiteHavePms(selectedSite),
    children: [
      { label: 'Report List', path: '/reservation/report/reservation', icon: <InsertInvitation /> },
      { label: 'Report Schedules', path: '/reservation/report/schedule', icon: <Schedule />, role: UserRole.ROLE_SITE_ADMIN },
    ],
  },
  {
    label: 'Guest Requests',
    isShown: (selectedCorporation, selectedSite) => doesSiteHaveFeature(selectedSite, SiteFeature.SPECIAL_REQUEST),
    children: [{ label: 'All Requests', path: '/reservation/specialRequest/new', icon: <FolderSpecial /> }],
  },
  {
    label: 'Guest Orders',
    isShown: (selectedCorporation, selectedSite) =>
      doesSiteHavePos(selectedSite) && doesSiteHaveFeature(selectedSite, SiteFeature.IN_ROOM_ORDERING),
    children: [{ label: 'All Orders', path: '/reservation/orders', icon: <LocalDining /> }],
  },
  {
    label: 'Guest Messaging',
    isShown: (selectedCorporation, selectedSite) => {
      return doesSiteHaveFeature(selectedSite, SiteFeature.CHAT);
    },
    children: [{ label: 'Messages', path: '/chat', icon: <MessageOutlined /> }],
  },
];

const collapsibleSections = AdminSections.filter((section) => section.children).reduce(
  (section, item) => ({ ...section, [item.label]: true }),
  {},
);

function SideMenuContent({ classes, selectedIndex, handleMenuSelection }) {
  const theme = useTheme();
  const history = useHistory();
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSection = useSelector((state) => state.accessReducer.selectedSection);
  const threadData = useSelector((state) => state.chatReducer.data);
  const initialUnreadMessages = 0;
  const [collapsibleMenu, setCollapsibleMenu] = useState(collapsibleSections);

  const handleClick = (key) => {
    setCollapsibleMenu({ ...collapsibleMenu, [key]: !collapsibleMenu[key] });
  };

  if (selectedSection) {
    if (selectedSection.id === 'configuration') {
      return (
        <MenuList>
          <MenuTop profile={profile} theme={theme} />

          {AdminSections.map((parent, pIndex) => {
            if (UserRoleHelper.hasRole(profile, parent.role) && parent.isShown(selectedCorporation, selectedSite)) {
              if (parent.children) {
                return (
                  <div key={`g-${pIndex}`}>
                    <ListItem key={`as-${parent.label}-${pIndex}`} onClick={() => handleClick(parent.label)}>
                      <ListItemText
                        primary={parent.label}
                        secondary={parent.labelSecondary(selectedCorporation, selectedSite)}
                        secondaryTypographyProps={{ className: classes.menuItem }}
                      />
                      {collapsibleMenu[parent.label] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={collapsibleMenu[parent.label]} timeout="auto" unmountOnExit className={'pl-2'}>
                      {parent.children.map((child, cIndex) => {
                        if (UserRoleHelper.hasRole(profile, child.role)) {
                          return (
                            <ListItem
                              button
                              selected={history.location.path === child.path}
                              onClick={() => handleMenuSelection(cIndex, child.path)}
                              key={`acs-${child.label}-${cIndex}`}
                            >
                              <ListItemIcon className={classes.menuIcon}>{child.icon}</ListItemIcon>
                              <ListItemText className={classes.menuItem} primary={child.label} />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <ListItem
                    button
                    selected={history.location.path === parent.path}
                    key={`as-${pIndex}-${parent.label}`}
                    onClick={() => handleMenuSelection(pIndex, parent.path)}
                  >
                    <ListItemIcon className={classes.menuIcon}>{parent.icon}</ListItemIcon>
                    <ListItemText className={classes.menuItem} primary={parent.label} />
                  </ListItem>
                );
              }
            } else {
              return null;
            }
          })}
        </MenuList>
      );
    } else {
      return (
        <MenuList>
          {UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_USER) && (
            <div>
              <MenuTop profile={profile} theme={theme} />

              {UserSections.map((parent, pIndex) => {
                if (parent.isShown(selectedCorporation, selectedSite)) {
                  return (
                    <div key={`r-${pIndex}`}>
                      <ListItem key={`ps-${parent.label}-${pIndex}`}>
                        <ListItemText primary={parent.label} />
                      </ListItem>
                      {parent.children &&
                        parent.children.map((child, cIndex) => {
                          return (
                            <ListItem
                              button
                              selected={history.location.path === child.path}
                              onClick={() => handleMenuSelection(cIndex, child.path)}
                              key={`pss-${child.label}-${cIndex}`}
                            >
                              {child.path === 'chat' && (
                                <ListItemIcon className={classes.menuIcon}>
                                  <Badge
                                    showZero={false}
                                    badgeContent={
                                      threadData.length > 0
                                        ? threadData.reduce(
                                            (accumulator, currentValue) => accumulator + currentValue.unreadByAdmin,
                                            initialUnreadMessages,
                                          )
                                        : 0
                                    }
                                    color="secondary"
                                  >
                                    {child.icon}
                                  </Badge>
                                </ListItemIcon>
                              )}

                              {child.path !== 'chat' && <ListItemIcon className={classes.menuIcon}>{child.icon}</ListItemIcon>}
                              <ListItemText className={classes.menuItem} primary={child.label} />
                            </ListItem>
                          );
                        })}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </MenuList>
      );
    }
  }

  return (
    <MenuList>
      {UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_USER) && (
        <div>
          <MenuTop profile={profile} theme={theme} />
          <ListItem button selected={selectedIndex === 0}>
            <ListItemText className={classes.menuItem} primary={'PLEASE SELECT A SECTION!'} />
          </ListItem>
        </div>
      )}
    </MenuList>
  );
}

const useStyles = makeStyles((theme) => ({
  menuSecondaryText: {
    color: 'rgba(255,255,255, 0.70)',
  },
  logoContainer: {
    width: '70%',
    minWidth: '150px',
  },
  logo: {
    width: '100%',
  },
  profileSection: {
    margin: '0 1.0rem 0.5rem 1.0rem',
    textAlign: 'center',
  },
  profileHeader: {
    fontWeight: '500',
    fontSize: '1.0rem',
  },
  profileInfo: {
    color: theme.palette.text.hint,
    fontSize: '0.85rem',
  },
}));

function MenuTop({ profile, theme }) {
  const styles = useStyles();
  return (
    <>
      <Box p={1} display={'flex'} justifyContent={'center'}>
        <Box className={styles.logoContainer}>
          <img src={LivertonLogo} width={'100%'} className={styles.logo} alt={'Liverton Logo'} />
        </Box>
      </Box>

      <Box className={styles.profileSection}>
        <Typography variant={'body2'} className={styles.profileHeader}>
          Management Portal
        </Typography>
        <Typography variant={'body2'} className={styles.profileInfo}>
          {profile.email}
        </Typography>
        <Typography variant={'body2'} className={styles.profileInfo}>
          {UserRoleHelper.toFriendlyName(profile.role)}
        </Typography>
      </Box>

      <Divider variant={'middle'} color={theme.palette.text.hint} />
    </>
  );
}

SideMenuContent.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleMenuSelection: PropTypes.func.isRequired,
};

export default React.memo(SideMenuContent);
