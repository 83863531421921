const UnsubscribedTypes = [
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'SMS',
    value: 'SMS',
  },
];

export default UnsubscribedTypes;
