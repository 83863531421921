import * as DataTypes from '../../../../../../constants/DataTypes';
import { Chip, IconButton, Switch } from '@material-ui/core';
import Actions from '../../../../../redux/actions/secondaryGuest/actions';
import { Check, DeleteOutlined, RemoveCircle } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoTable from '../../../../../../components/table/AutoTable';
import SiteFeature from '../../../../../../support/SiteFeature';
import UserRoleHelper from '../../../../../../support/UserRoleHelper';
import UserRole from '../../../../../../support/UserRole';

const columns = [
  {
    id: 'delete',
    label: 'Delete',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'disable',
    label: 'Sharing Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <Switch checked={value.enabled} onChange={() => dispatch(Actions.changeState(value))} />
          <Chip label={value.enabled ? 'Enabled' : 'Disabled'} icon={value.enabled ? <Check /> : <RemoveCircle />} />
        </>
      );
    },
  },
  {
    id: 'firstName',
    label: 'First Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function SecondaryGuestList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const page = useSelector((state) => state.secondaryGuestReducer.page);
  const error = useSelector((state) => state.secondaryGuestReducer.error);
  const processing = useSelector((state) => state.secondaryGuestReducer.processing);
  const lastActionType = useSelector((state) => state.secondaryGuestReducer.lastActionType);
  const guests = useSelector((state) => state.secondaryGuestReducer.entityList);
  const featureEnabled = selectedSite?.featureList.filter((sf) => sf.code === SiteFeature.SHARE_RESERVATIONS).length > 0;
  const profile = useSelector((state) => state.accessReducer.profile);
  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId && selectedReservation) {
      dispatch(Actions.fetch(0, 25, { siteId: selectedSiteId, reference: selectedReservation.reservationNumber }));
    }
  }, [selectedSiteId, dispatch, selectedReservation]);

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedSiteId && selectedReservation) {
      dispatch(
        Actions.fetch(newPage, pageSize, {
          siteId: selectedSiteId,
          reference: selectedReservation.reservationNumber,
        })
      );
    }
  };

  return (
    <AutoTable
      title="Secondary Guests"
      subheader={'Guests that have access to this reservation'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={() => {}}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECONDARY_GUEST"
      buttonName="Invite New Guest"
      createNewURL="/reservation/secondary/new"
      detailURL="/reservation/secondary/view/{id}"
      backHidden
      buttonDisabled={!featureEnabled || !UserRoleHelper.hasRole(profile, UserRole.ROLE_SITE_ADMIN)}
      data={guests}
      lastActionType={lastActionType}
    />
  );
}
