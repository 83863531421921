import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../redux/actions/feedback/actions';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';

const columns = [
  {
    id: 'feedbackLabel',
    label: 'Label',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'feedbackScore',
    label: 'Score',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'feedbackIcon',
    label: 'Icon',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'order',
    label: 'Order',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function FeedbackOptionList() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const feedbackOptions = useSelector((state) => state.feedbackReducer.entityList);
  const error = useSelector((state) => state.feedbackReducer.error);
  const processing = useSelector((state) => state.feedbackReducer.processing);
  const lastActionType = useSelector((state) => state.feedbackReducer.lastActionType);
  const page = useSelector((state) => state.feedbackReducer.page);

  React.useEffect(() => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 10, selectedSite.id));
    }
  }, [selectedSite, dispatch]);

  const handleRefreshReport = (selectedSite) => {
    if (selectedSite) {
      dispatch(Actions.fetch(0, 10, selectedSite.id));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, selectedSite.id));
  };

  return (
    <AutoTable
      title="Feedback Options"
      subheader={'Feedback Options available for guest'}
      handleRefreshReport={() => handleRefreshReport(selectedSite)}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.selectItem(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="FEEDBACK"
      newButtonName="New Feedback Option"
      createNewURL="/site/feedback/new"
      detailURL="/site/feedback/view/{id}"
      idColumn={'id'}
      data={feedbackOptions}
      lastActionType={lastActionType}
    />
  );
}
