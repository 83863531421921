import React, { useEffect, useState } from 'react';
import * as Actions from '../../../../redux/actions/inRoom/ordering/foodPosPaymentType/actions';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BooleanTableColumn from '../../../../../components/table/BooleanTableColumn';
import { DeleteOutlined } from '@material-ui/icons';

export default function FoodPosPaymentTypeList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const defaultPaymentTypes = useSelector((state) => state.foodPosPaymentTypeReducer.defaultPaymentTypes) || [];
  const foodPostPaymentTypes = useSelector((state) => state.foodPosPaymentTypeReducer.foodPosPaymentTypes) || [];
  const [paymentType, setPaymentType] = React.useState(null);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);

  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetchAll(selectedSiteId));
    }
  }, [selectedSiteId, dispatch]);

  const merged = defaultPaymentTypes.map((pt) => {
    const siteOverride = foodPostPaymentTypes.find((spt) => spt.mappedType === pt.mappedType);
    if (siteOverride) {
      return siteOverride;
    } else {
      return pt;
    }
  });

  const openModalAndSetSelected = (pt) => {
    setPaymentType(pt);
    setPaymentModalOpen(true);
  };

  const closeModalAndClear = () => {
    setPaymentType(null);
    setPaymentModalOpen(false);
  };

  return (
    <Card>
      <CardHeader title={'Food Pos Payment Type Mapping'} subheader={'Payment type mapping for POS'} />
      <PaymentTypeEditDialog open={paymentModalOpen} setClose={closeModalAndClear} paymentType={paymentType} siteId={selectedSiteId} />
      <CardContent>
        {merged.length <= 0 && <Alert color={'info'}>No systems available - add to get started</Alert>}

        {merged.length > 0 && (
          <TableContainer>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>POS Code</TableCell>
                  <TableCell align={'center'}>Overridden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merged.map((pt) => (
                  <TableRow key={`payment-type-${pt.id}`}>
                    <TableCell>
                      <IconButton onClick={() => dispatch(Actions.deletePaymentType(pt.id))} disabled={!pt.overridden} size={'small'}>
                        <DeleteOutlined />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button variant={'text'} onClick={() => openModalAndSetSelected(pt)} color={'primary'}>
                        {pt.mappedType}
                      </Button>
                    </TableCell>
                    <TableCell>{pt.posCode}</TableCell>

                    <BooleanTableColumn align={'center'} value={pt.posCode !== null} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}

function PaymentTypeEditDialog({ open, setClose, paymentType, siteId }) {
  const dispatch = useDispatch();
  const [posCode, setPosCode] = useState(paymentType ? paymentType.posCode : '');

  useEffect(() => {
    if (paymentType) {
      setPosCode(paymentType.posCode);
    } else {
      setPosCode('');
    }
  }, [paymentType]);

  const overrideAndClose = () => {
    if (paymentType.overridden) {
      dispatch(
        Actions.updatePaymentType(paymentType.id, {
          ...paymentType,
          posCode: posCode,
        })
      );
    } else {
      dispatch(
        Actions.createPaymentType(siteId, {
          ...paymentType,
          paymentTypeId: paymentType.id,
          posCode: posCode,
        })
      );
    }

    setClose(null);
  };

  const handleClose = () => {
    setClose(null);
  };

  if (!paymentType) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Payment Type</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField fullWidth type="text" disabled={true} variant={'outlined'} value={paymentType.mappedType} label="Payment Code" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              variant={'outlined'}
              onChange={(event) => setPosCode(event.target.value)}
              value={posCode}
              label="Pos Code"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={overrideAndClose}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
