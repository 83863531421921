import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Objects from '../../../../../../support/Objects';

export default function EditAssaAbloySystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);

  const [csUrl, setCSUrl] = React.useState(configuration?.csUrl || 'https://onboarding.credential-services.api.assaabloy.com');
  const [csUsername, setCSUsername] = React.useState(configuration?.csUsername || 'Liverton-demo-tenant');
  const [csPassword, setCSPassword] = React.useState(configuration?.csPassword || '6rJ3sDPlhfWTzN4IRjlx7WAvX4mhwTCI');
  const [csSystemId, setCSSystemId] = React.useState(configuration?.csSystemId || 1);
  const [visionlineUrl, setVisionlineUrl] = React.useState(
    configuration?.visionlineUrl || 'https://ec2-13-211-251-71.ap-southeast-2.compute.amazonaws.com'
  );
  const [visionlineUsername, setVisionlineUsername] = React.useState(configuration?.visionlineUsername || 'sym');
  const [visionlinePassword, setVisionlinePassword] = React.useState(configuration?.visionlinePassword || 'sym');
  const [visionlineMobileAccessAccount, setVisionlineMobileAccessAccount] = React.useState(
    configuration?.visionlineMobileAccessAccount || 'LivertonCert'
  );
  const [lockServiceCode, setLockServiceCode] = React.useState(configuration?.lockServiceCode || '9');
  const [visionlineCommonAreaCode, setVisionlineCommonAreaCode] = React.useState(
    configuration.visionlineCommonAreaCode ? configuration.visionlineCommonAreaCode.join(', ') : []
  );

  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [readTimeout, setReadTimeout] = React.useState(configuration?.readTimeout || 60000);
  const [connectTimeout, setConnectTimeout] = React.useState(configuration?.connectTimeout || 5000);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        csUrl: Objects.sanitizeAndTrimString(csUrl),
        csUsername: csUsername,
        csPassword: csPassword,
        csSystemId: csSystemId,
        visionlineUrl: Objects.sanitizeAndTrimString(visionlineUrl),
        visionlineUsername: visionlineUsername,
        visionlinePassword: visionlinePassword,
        connectTimeout: connectTimeout,
        readTimeout: readTimeout,
        visionlineMobileAccessAccount: visionlineMobileAccessAccount,
        visionlineCommonAreaCode: visionlineCommonAreaCode.length > 0 ? visionlineCommonAreaCode.split(',') : [],
        lockServiceCode: lockServiceCode,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    csPassword,
    csSystemId,
    csUrl,
    csUsername,
    connectTimeout,
    debug,
    dispatch,
    enabled,
    lockServiceCode,
    name,
    readTimeout,
    selectedItem,
    visionlineCommonAreaCode,
    visionlineMobileAccessAccount,
    visionlinePassword,
    visionlineUrl,
    visionlineUsername,
    selectedSite,
  ]);

  const isFormReady =
    name && csUrl && csUsername && csPassword && visionlineUrl && visionlineUsername && visionlinePassword && visionlineMobileAccessAccount;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Assa Abloy Lock System'}
        subheader={'Vingcard Visionline hotel locking system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              type="text"
              label="Friendly Name"
              onChange={(event) => setName(event.target.value)}
              variant={'outlined'}
              value={name}
              error={!name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="csUrl"
              type="text"
              label="Credential Service URL"
              onChange={(event) => setCSUrl(event.target.value)}
              variant={'outlined'}
              value={csUrl}
              error={!csUrl}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="csUsername"
              type="text"
              label="Credential Service Username"
              onChange={(event) => setCSUsername(event.target.value)}
              variant={'outlined'}
              value={csUsername}
              error={!csUsername}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="csPassword"
              label="Credential Service Password"
              type="password"
              onChange={(event) => setCSPassword(event.target.value)}
              variant={'outlined'}
              value={csPassword}
              error={!csPassword}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="visionlineMobileAccessAccount"
              type="text"
              label="Visionline Mobile Access Account Name"
              onChange={(event) => setVisionlineMobileAccessAccount(event.target.value)}
              variant={'outlined'}
              value={visionlineMobileAccessAccount}
              error={!visionlineMobileAccessAccount}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="csSystemId"
              type="number"
              label="Visionline Mobile Access System ID"
              onChange={(event) => setCSSystemId(event.target.value)}
              variant={'outlined'}
              value={csSystemId}
              error={!csSystemId}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="visionlineUrl"
              type="text"
              label="Visionline Server URL"
              onChange={(event) => setVisionlineUrl(event.target.value)}
              variant={'outlined'}
              value={visionlineUrl}
              error={!visionlineUrl}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="visionlineCommonAreaCode"
              type="text"
              label="Global Common Area Codes (Comma Seperated)"
              onChange={(event) => setVisionlineCommonAreaCode(event.target.value)}
              variant={'outlined'}
              value={visionlineCommonAreaCode}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="visionlineUsername"
              type="text"
              label="Visionline Username"
              onChange={(event) => setVisionlineUsername(event.target.value)}
              variant={'outlined'}
              value={visionlineUsername}
              error={!visionlineUsername}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="visionlinePassword"
              type="password"
              label="Visionline Password"
              onChange={(event) => setVisionlinePassword(event.target.value)}
              variant={'outlined'}
              value={visionlinePassword}
              error={!visionlinePassword}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="lockServiceCode"
              type="text"
              label="Visionline Lock Service Code (UUID)"
              onChange={(event) => setLockServiceCode(event.target.value)}
              variant={'outlined'}
              value={lockServiceCode}
              error={!lockServiceCode}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="connectTimeout"
              type="number"
              label="Connect Timeout (MS)"
              onChange={(event) => setConnectTimeout(event.target.value)}
              variant={'outlined'}
              value={connectTimeout}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="readTimeout"
              type="number"
              label="Read Timeout (MS)"
              onChange={(event) => setReadTimeout(event.target.value)}
              variant={'outlined'}
              value={readTimeout}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="debug">Debug</InputLabel>
              <Select labelId="debug" value={debug} labelWidth={50} onChange={(event) => setDebug(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="enabled">Enabled</InputLabel>
              <Select labelId="enabled" value={enabled} labelWidth={60} onChange={(event) => setEnabled(event.target.value)}>
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
