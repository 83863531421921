const SystemCategory = {
  PMS: 'PMS',
  LOCK: 'LOCK',
  MEMBERSHIP: 'MEMBERSHIP',
  SECURITY: 'SECURITY',
  FOOD_POS_SYSTEM: 'FOOD_POS_SYSTEM',
  RMS: 'RMS',
};

const SystemCategorySelectOptions = [
  { label: 'PMS System', value: SystemCategory.PMS },
  { label: 'Lock System', value: SystemCategory.LOCK },
  { label: 'Membership System', value: SystemCategory.MEMBERSHIP },
  { label: 'Security System', value: SystemCategory.SECURITY },
  { label: 'Food POS System', value: SystemCategory.FOOD_POS_SYSTEM },
  { label: 'Resident Management System', value: SystemCategory.RMS },
];

export { SystemCategorySelectOptions };

export default SystemCategory;
