import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NotificationActions from '../../../../redux/actions/site/notification/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Box, Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import { NotificationTemplateType } from '../template/NotificationTemplateType';
import ChronoUnit from '../../../../../support/ChronoUnit';
import FormSelect from '../../../../../components/form/FormSelect';
import { NotificationType } from './NotificationType';
import { NotificationCategory } from './NotificationCategory';
import DurationUnitSelect from '../../../../../components/form/DurationUnitSelect';
import { ScheduleCondition, ScheduleConditionOptions } from './ScheduleConditionOptions';
import ReservationEvent, { ReservationEventOptions } from './ReservationEvent';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { DaysOfWeekLong } from '../../../../../support/DaysOfWeekShort';

export default function NotificationScheduleEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.notificationScheduleReducer);

  const [notificationType, setNotificationType] = useState(selectedItem ? selectedItem.notificationType : '');
  const [notificationCategory, setNotificationCategory] = useState(
    selectedItem ? selectedItem.notificationCategory : NotificationTemplateType.EMAIL
  );
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [timeAmount, setTimeAmount] = useState(selectedItem ? selectedItem.timeAmount : 1);
  const [timeUnit, setTimeUnit] = useState(selectedItem ? selectedItem.timeUnit : ChronoUnit.DAYS);
  const [condition, setCondition] = useState(selectedItem ? selectedItem.condition : '');
  const [reservationEvent, setReservationEvent] = useState(selectedItem ? selectedItem.reservationEvent : '');
  const [scheduleDays, setScheduleDays] = useState(selectedItem ? selectedItem.scheduleDays : []);
  const [days, setDays] = useState(() => []);

  useEffect(() => {
    dispatch(NotificationActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (scheduleDays) {
      setDays(scheduleDays.reduce((acc, day) => ({ ...acc, [day.dayOfWeek]: { selected: true, open: false } }), {}));
    }
  }, [scheduleDays]);

  useEffect(() => {
    if (
      lastActionType === 'NOTIFICATION_SCHEDULE_DELETE_SUCCESS' ||
      lastActionType === 'NOTIFICATION_SCHEDULE_UPDATE_SUCCESS' ||
      lastActionType === 'NOTIFICATION_SCHEDULE_ADD_SUCCESS'
    ) {
      history.push('/site/notifications');
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      notificationType,
      notificationCategory,
      timeAmount,
      timeUnit,
      condition,
      reservationEvent,
      scheduleDays: scheduleDays.filter((day) => days[day.dayOfWeek].selected),
    };

    if (selectedItem) {
      dispatch(NotificationActions.update(payload, `/api/private/portal/notification/schedule/${selectedItem.id}`, false));
    } else {
      dispatch(NotificationActions.add(payload, { siteId: selectedSiteId }));
    }
  };

  const isFormReady = () => {
    if (condition === ScheduleCondition.ON) {
      return notificationType && notificationCategory && condition && reservationEvent;
    } else {
      return notificationType && notificationCategory && condition && reservationEvent && timeAmount && timeUnit;
    }
  };

  const renderDays = useMemo(() => {
    const handleTimeClick = (id, val) => {
      setDays({
        ...days,
        [id]: { selected: days[id]?.selected, open: val },
      });
    };

    const handleTimeUpdate = (currentDay, day, time) => {
      setScheduleDays([
        ...scheduleDays.filter((d) => d.dayOfWeek !== day),
        {
          id: currentDay?.id || null,
          dayOfWeek: day,
          sendTime: moment(time).format('HH:mm'),
        },
      ]);
    };

    const handleToggle = (id) => {
      const val = !days[id]?.selected;
      setDays({
        ...days,
        [id]: { selected: val, open: val },
      });
    };

    const defaultTime = ReservationEvent.CHECK_OUT === reservationEvent ? selectedSite?.checkOutTime : selectedSite?.checkInTime;
    const defaultSelectionDate = defaultTime ? moment(defaultTime, 'HH:mm:ss') : moment();

    return Object.keys(DaysOfWeekLong).map((day, idx) => {
      const currentDay = scheduleDays.find((cd) => cd.dayOfWeek === day);
      return (
        <Box display={'flex'} key={day}>
          <Grid item>
            <Grid container spacing={0} style={{ width: 180 }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={days[day]?.selected || false} onChange={(_) => handleToggle(day)} name={day} color="primary" />}
                  label={day}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12}>
                <TimePicker
                  open={days[day]?.open || false}
                  onClose={() => handleTimeClick(day, false)}
                  margin={'dense'}
                  onChange={(time) => handleTimeUpdate(currentDay, day, time)}
                  disabled={!days[day]?.selected || false}
                  initialFocusedDate={defaultSelectionDate}
                  InputComponent={(params) => (
                    <TextField
                      label={'Time'}
                      onClick={(e) => {
                        // Workaround. onClick is still propagated event if disabled
                        if (!e.target.disabled) {
                          handleTimeClick(day, true);
                        }
                      }}
                      variant={'outlined'}
                      value={currentDay?.sendTime ? moment.utc(currentDay.sendTime, 'HH:mm').format('hh:mm A') : null}
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {idx < 6 && <Divider orientation="vertical" variant={'middle'} />}
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, scheduleDays, reservationEvent, selectedSite]);

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Schedule' : 'Create Schedule'}
        subheader={'A schedule dictates the time when a notification is to be sent out'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady() || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(NotificationActions.delete(selectedItem, `/api/private/portal/notification/schedule/${selectedItem.id}`))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add schedule - ${error.message}`}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Notification Type'} value={notificationType} setValue={setNotificationType} options={NotificationType} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Category'} value={notificationCategory} setValue={setNotificationCategory} options={NotificationCategory} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Time Amount'} value={timeAmount} setValue={setTimeAmount} type={'number'} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <DurationUnitSelect label={'Time Unit'} value={timeUnit} setValue={setTimeUnit} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Condition'} value={condition} setValue={setCondition} options={ScheduleConditionOptions} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Relative To'} value={reservationEvent} setValue={setReservationEvent} options={ReservationEventOptions} />
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant={'middle'} />
      <CardHeader title={'Sending Time'} subheader={'Override the sending time depending on the day of week'} />
      <CardContent>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={0}>
            {renderDays}
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
}
