import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Modifier', 'FOOD_MODIFIER');

function foodModifierReducer(state = initial, action) {
  switch (action.type) {
    case `FOOD_MODIFIER_OPTIONS_IN_PROGRESS`:
    case `FOOD_MODIFIER_SELECT_ALL_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    }
    case `FOOD_MODIFIER_OPTIONS_SUCCESS`:
    case `FOOD_MODIFIER_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        options: action.payload,
      };
    }
    case `FOOD_MODIFIER_OPTIONS_FAILURE`:
    case `FOOD_MODIFIER_SELECT_ALL_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default foodModifierReducer;
