import API from '@aws-amplify/api';

export const RESERVATION_FIELD_FETCH_SUCCESS = 'RESERVATION_FIELD_FETCH_SUCCESS';
export const RESERVATION_FIELD_FETCH_FAILURE = 'RESERVATION_FIELD_FETCH_FAILURE';

export const RESERVATION_FIELD_CREATE_SUCCESS = 'RESERVATION_FIELD_CREATE_SUCCESS';
export const RESERVATION_FIELD_CREATE_FAILURE = 'RESERVATION_FIELD_CREATE_FAILURE';

export const RESERVATION_FIELD_UPDATE_SUCCESS = 'RESERVATION_FIELD_UPDATE_SUCCESS';
export const RESERVATION_FIELD_UPDATE_FAILURE = 'RESERVATION_FIELD_UPDATE_FAILURE';

export const RESERVATION_FIELD_DELETE_SUCCESS = 'RESERVATION_FIELD_DELETE_SUCCESS';
export const RESERVATION_FIELD_DELETE_FAILURE = 'RESERVATION_FIELD_DELETE_FAILURE';

export const RESERVATION_FIELD_CLEAR_ERROR = 'RESERVATION_FIELD_CLEAR_ERROR';

export const clearError = () => async (dispatch) => {
  dispatch({ type: RESERVATION_FIELD_CLEAR_ERROR, payload: {} });
};

export const fetchAll = (siteId) => async (dispatch) => {
  Promise.all([
    API.get('PrivateAPI', `/api/private/portal/site/reservation-field`, {}),
    API.get('PrivateAPI', `/api/private/portal/site/${siteId}/reservation-field`, {}),
  ])
    .then((response) => {
      console.log('FETCH ALL RESPONSE: ', response);
      const defaultFields = response[0];
      console.log(defaultFields);

      const siteFields = response[1];
      console.log(siteFields);

      dispatch({
        type: RESERVATION_FIELD_FETCH_SUCCESS,
        payload: {
          defaultFields: defaultFields,
          siteFields: siteFields,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: RESERVATION_FIELD_FETCH_FAILURE, payload: error });
    });
};

export const createReservationField = (siteId, request) => async (dispatch) => {
  let payload = {
    body: {
      ...request,
      siteId,
    },
  };

  API.post('PrivateAPI', `/api/private/portal/site/${siteId}/reservation-field`, payload)
    .then((response) => {
      dispatch({ type: RESERVATION_FIELD_CREATE_SUCCESS, payload: response });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: RESERVATION_FIELD_CREATE_FAILURE, payload: error });
    });
};

export const updateReservationField = (fieldId, request) => async (dispatch) => {
  let payload = {
    body: request,
  };

  API.put('PrivateAPI', `/api/private/portal/reservation-field/${fieldId}`, payload)
    .then((response) => {
      dispatch({ type: RESERVATION_FIELD_UPDATE_SUCCESS, payload: response });
    })
    .catch((error) => {
      dispatch({ type: RESERVATION_FIELD_UPDATE_FAILURE, payload: error });
    });
};

export const deleteReservationField = (fieldId) => async (dispatch) => {
  API.del('PrivateAPI', `/api/private/portal/reservation-field/${fieldId}`, {})
    .then(() => {
      dispatch({ type: RESERVATION_FIELD_DELETE_SUCCESS, payload: fieldId });
    })
    .catch((error) => {
      dispatch({ type: RESERVATION_FIELD_DELETE_FAILURE, payload: error });
    });
};
