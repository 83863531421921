import actionCreator from '../../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../../support/Objects';

const FoodInfoActions = {
  ...actionCreator.createActions('Food Info', 'FOOD_INFO', '/api/private/portal/food/vendor/{vendorId}/food/info'),
  fetchAll: (vendorId) => async (dispatch) => {
    await dispatch({ type: 'FOOD_INFO_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/food/vendor/${vendorId}/food/info/list`, {});
      await dispatch({ type: `FOOD_INFO_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all food info with result: ', error);
      dispatch({
        type: `FOOD_INFO_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default FoodInfoActions;
