import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Objects from '../../../../../../support/Objects';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../../components/form/FormField';

export default function EditHotSosSecuritySystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);

  const [server, setServer] = React.useState(configuration?.server || 'https://ifc.int.hot-sos.net/api/service.svc/rest');
  const [username, setUsername] = React.useState(configuration?.username || 'API_H90VWLI6');
  const [password, setPassword] = React.useState(configuration?.password || 'axdoJPsliDPhoOF1OIkNvsTbNUz4E!');
  const [debug, setDebug] = React.useState(configuration?.debug || false);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        server: Objects.sanitizeAndTrimString(server),
        username,
        password,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [selectedItem, name, enabled, selectedSite.id, debug, server, username, password, dispatch]);

  const isFormReady = name && server && username && password;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Hot Sos Security System'}
        subheader={'Hot Sos security system to synchronise between two lock systems'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={name} setValue={setName} type={'text'} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Server URL'} value={server} setValue={setServer} type={'text'} error={!server} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Username'} value={username} setValue={setUsername} type={'text'} error={!username} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Password'} value={password} setValue={setPassword} type={'password'} error={!password} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
