const UpsellOfferType = {
  PACKAGE: 'PACKAGE',
  ROOM_UPGRADE: 'ROOM_UPGRADE',
};

const UpsellOfferTypeOptions = [
  { label: 'Package', value: UpsellOfferType.PACKAGE },
  { label: 'Room Upgrade', value: UpsellOfferType.ROOM_UPGRADE },
];

export default UpsellOfferType;
export { UpsellOfferTypeOptions };
