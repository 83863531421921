import actionCreator from '../../support/actionCreator';
import { API } from '@aws-amplify/api';
import Swal from 'sweetalert2';
import Objects from '../../../../support/Objects';

export const SECONDARY_GUEST_DISABLE_PROGRESS = 'SECONDARY_GUEST_DISABLE_PROGRESS';
export const SECONDARY_GUEST_STATE_UPDATE_SUCCESS = 'SECONDARY_GUEST_STATE_UPDATE_SUCCESS';
export const SECONDARY_GUEST_STATE_UPDATE_FAILURE = 'SECONDARY_GUEST_STATE_UPDATE_FAILURE';
export const SECONDARY_GUEST_SHARE_IN_PROGRESS = 'SECONDARY_GUEST_SHARE_IN_PROGRESS';
export const SECONDARY_GUEST_SHARE_SUCCESS = 'SECONDARY_GUEST_SHARE_SUCCESS';
export const SECONDARY_GUEST_SHARE_FAILURE = 'SECONDARY_GUEST_SHARE_FAILURE';

const SecondaryGuestActions = {
  ...actionCreator.createActions('SecondaryGuest', 'SECONDARY_GUEST', '/api/private/secondary'),

  changeState: (value) => async (dispatch, getState) => {
    const selectedSite = getState().accessReducer.selectedSite;
    const selectedItem = getState().hotelReservationReducer.selectedItem;
    dispatch({ type: SECONDARY_GUEST_DISABLE_PROGRESS });
    try {
      let request = {
        guestReservationId: value.id,
        enable: !value.enabled,
        reference: selectedItem.reservationNumber,
        siteId: selectedSite.id,
      };

      await API.post('PrivateAPI', '/api/private/secondary/update', { body: request });

      dispatch({ type: SECONDARY_GUEST_STATE_UPDATE_SUCCESS, payload: request });
      Swal.fire(
        `Access ${!value.enabled ? 'Granted' : 'Revoked'} `,
        `Access ${!value.enabled ? 'granted to ' : 'revoked from '} ${value.firstName} ${value.lastName}`,
        'success'
      );
    } catch (error) {
      dispatch({ type: SECONDARY_GUEST_STATE_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  inviteGuest: (payload) => async (dispatch) => {
    dispatch({ type: SECONDARY_GUEST_SHARE_IN_PROGRESS });
    try {
      await API.post('PrivateAPI', '/api/private/secondary', { body: payload });
      dispatch({ type: SECONDARY_GUEST_SHARE_SUCCESS });
      Swal.fire(`${payload.guestName} ${payload.guestLastName} Invited `, `Invite will be sent out to the guest shortly`, 'success');
    } catch (error) {
      dispatch({ type: SECONDARY_GUEST_SHARE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};
export default SecondaryGuestActions;
