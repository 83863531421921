import moment from 'moment/moment';

/**
 * Utility class which provides common methods applied to JS objects i.e. null checking, string is empty
 */
export default class Objects {
  static sanitizeAndTrimString(value) {
    if (value && value.trim) {
      return value.trim();
    }

    return '';
  }

  static isNull(object) {
    if (object === null || object === undefined) {
      return true;
    } else {
      if (typeof object === 'string' || typeof object === 'number' || typeof object === 'boolean') {
        return false;
      }

      return !(object || object === 0 || object.length === 0);
    }
  }

  static isNullOrEmpty(object) {
    if (this.isNull(object)) {
      return true;
    } else {
      return '' === object || 0 === object.length;
    }
  }

  static areValuesNullOrEmpty(values) {
    Objects.notNull(values, 'values cannot be null');

    if (values.hasOwnProperty('length')) {
      return values.filter((value) => Objects.isNullOrEmpty(value)).length > 0;
    } else {
      return Objects.isNullOrEmpty(values);
    }
  }

  static validateEmailAddress(email) {
    Objects.notNull(email, 'email cannot be null');

    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
  }

  static notNull(object, field) {
    if (this.isNull(object)) {
      throw new Error(field + ' is null or undefined');
    }
  }

  static absolute(value) {
    if (value === undefined || value === null) {
      return value;
    } else {
      return Math.abs(value);
    }
  }

  static clearInterval(taskId) {
    if (taskId) {
      console.debug('Clearing interval: ' + taskId);
      clearInterval(taskId);
    }
  }

  static clearTimeout(taskId) {
    if (taskId) {
      console.debug('Clearing timeout: ' + taskId);
      clearTimeout(taskId);
    }
  }

  static negate(value) {
    if (value === undefined || value === null) {
      return value;
    } else {
      return Math.abs(value) * -1;
    }
  }

  static isMatch(match, array) {
    if (typeof array.filter === 'function') {
      return array.filter((item) => item === match).length > 0;
    } else {
      return match === array;
    }
  }

  /**
   * Converts the provided date to MS
   * @param value
   */
  static prepareDate(value) {
    if (value === undefined || value === null) {
      return new Date().getTime();
    } else {
      if (value.getTime) {
        return value.getTime();
      } else {
        return new Date(value).getTime();
      }
    }
  }

  static getBoolForFalse = (field) => {
    if (field) {
      return field;
    } else {
      return false;
    }
  };

  static getErrorFromResponse(httpError) {
    if (!httpError) {
      return 'Unknown error occurred - no message';
    }

    const defaultError = httpError.message || 'Unable to process response. Please try again later';
    try {
      if (httpError.response) {
        if (httpError.response.data) {
          return `${httpError.response.statusText} ${httpError.response.status} : ${httpError.response.data?.message || defaultError}`;
        } else {
          return `${httpError.response.statusText} ${httpError.response.status} : ${httpError.response.message || defaultError}`;
        }
      }

      return defaultError;
    } catch (error) {
      console.error('Unable to get error message from response', error);
    }

    return defaultError;
  }

  static logPropAndStateChange(currentProps, prevProps, currentState, prevState) {
    Object.entries(currentProps).forEach(([key, val]) => prevProps[key] !== val && console.log(`PROP CHANGE WITH KEY '${key}' changed`));
    Object.entries(currentState).forEach(([key, val]) => prevState[key] !== val && console.log(`STATE CHANGE WITH KEY '${key}' changed`));
  }

  static getCurrentPageNumberByPageSize(pageNumber, pageSize, totalElements) {
    return Math.ceil(totalElements / pageSize) - 1 < pageNumber ? Math.ceil(totalElements / pageSize) - 1 : pageNumber;
  }

  static capitalize(s) {
    if (!s) {
      return '';
    }
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  static getUUid(length) {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-mixed-operators
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;

      if (length > 0) {
        return v.toString(16).substring(0, length);
      } else {
        return v.toString(16);
      }
    });
  }

  static getReservationNumber(siteId) {
    return `R-${siteId}${Math.random().toString().substring(2, 12)}`.toUpperCase();
  }

  static getConfirmationNumber(siteId) {
    return `C-${siteId}${Math.random().toString().substring(2, 12)}`.toUpperCase();
  }

  static saveAndDownloadContent(filename, data) {
    const link = document.createElement('a');
    link.download = filename;
    link.href = data;
    link.click();
  }

  static convertTimestampToDateTimezone(date, timezone) {
    return moment(moment.unix(date).toDate().toLocaleString('en-US', { timeZone: timezone })).format('LLL');
  }

  static getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const timezoneDate = new Date(date.toLocaleString('en-US', { timeZone }));
    // offset minutes
    return (timezoneDate.getTime() - utcDate.getTime()) / 6e4;
  };

  static getOffsetToHour = (timeZone) => {
    const minutes = Objects.getOffset(timeZone);
    const offsetHour = Math.floor(minutes / 60);
    const offsetMinutes = Math.abs(minutes % 60);
    return `${offsetHour > 0 ? '+' : '-'}${String(Math.abs(offsetHour)).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
  };

  static dropLongSentence(message, length = 30) {
    if (message.length <= length) {
      return message;
    } else {
      return `${message.substring(0, length)}...`;
    }
  }

  static appendParamsUrl(params, requestUrl) {
    const hasQuestionMark = requestUrl.includes('?');
    Object.keys(params).forEach((key, index) => {
      if (params[key] !== '') {
        requestUrl += `${hasQuestionMark ? '&' : index === 0 ? '?' : '&'}${key}=${params[key]}`;
      }
    });
    return requestUrl;
  }

  static hashCode(s) {
    let h = 0;
    for (let i = 0; i < s.length; i++) {
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    }

    return h;
  }
}
