import * as Actions from '../../actions/reservationField/actions';

const initial = {
  lastActionType: 'NONE',
  defaultFields: [],
  siteFields: [],
  error: null,
};

export default function reservationFieldReducer(state = initial, action) {
  switch (action.type) {
    case Actions.RESERVATION_FIELD_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.RESERVATION_FIELD_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        defaultFields: action.payload.defaultFields,
        siteFields: action.payload.siteFields,
        error: null,
      };
    case Actions.RESERVATION_FIELD_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.RESERVATION_FIELD_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        siteFields: [...state.siteFields, action.payload],
        error: null,
      };
    case Actions.RESERVATION_FIELD_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.RESERVATION_FIELD_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        siteFields: [...state.siteFields].filter((i) => i.id !== action.payload),
        error: null,
      };
    case Actions.RESERVATION_FIELD_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.RESERVATION_FIELD_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        siteFields: [...state.siteFields].map((i) => (i.id === action.payload.id ? action.payload : i)),
        error: null,
      };
    case Actions.RESERVATION_FIELD_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
}
