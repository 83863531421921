import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FoodVendorActions from '../../../../redux/actions/inRoom/ordering/vendor/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import PhoneNumberInput from '../../../../../components/phoneNumberInput/PhoneNumberInput';
import TabPanel from '../../../control/TabPanel';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import toBase64 from '../../../../../support/toBase64Converter';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FoodVendorScheduleList from './FoodVendorScheduleList';
import FoodMenuList from './FoodMenuList';
import FoodItemList from './FoodItemList';
import FoodInfoList from './FoodInfoList';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FoodModifierGroupList from './FoodModifierGroupList';
import FoodModifierList from './FoodModifierList';
import FoodPosSystem from '../../../../../constants/FoodPosSystem';
import Swal from 'sweetalert2';
import PaymentProviderSelect from '../../../../../components/form/PaymentProviderSelect';
import FormCurrencyList from '../../../../../components/form/FormCurrencyList';
import FoodOrderLocationList from './FoodOrderLocationList';
import FoodTaxList from './FoodTaxList';
import FoodChargeList from './FoodChargeList';
import FoodDiscountList from './FoodDiscountList';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';
import * as SystemActions from '../../../../redux/actions/system/actions';
import { FoodOrderLocationTypesAlias } from '../../../../../support/FoodOrderLocationType';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FoodModifierActions from '../../../../redux/actions/inRoom/ordering/foodModifier/actions';
import FormSelect from '../../../../../components/form/FormSelect';
import { FoodThumbnailBehaviours } from '../../../../../support/FoodThumbnailBehaviour';
import { FoodVendorReceiptOptions, FoodVendorReceiptType } from '../../../../../support/FoodVendorReceiptType';
import moment from 'moment/moment';
import AppConfig from '../../../../../AppConfig';
import { VendorDescriptionDisplayOptions } from '../../../../../support/VendorDescriptionDisplay';

export default function FoodVendorEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const menuCodes = useSelector((state) => state.siteResourcesReducer.menuCodes);
  const selectedSiteId = selectedSite?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodVendorReducer);
  const [paymentProviderId, setPaymentProviderId] = useState(selectedItem?.paymentProviderId || '');
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const modifierOptions = useSelector((state) => state.foodModifierReducer.options);
  const [open, setOpen] = useState(true);
  const [vendorName, setVendorName] = useState(selectedItem?.name || '');
  const [externalId, setExternalId] = useState(selectedItem?.externalId || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [email, setEmail] = useState(selectedItem?.email || '');
  const [website, setWebsite] = useState(selectedItem?.website || '');
  const [banner, setBanner] = useState(selectedItem?.banner || '');
  const [currencyCode, setCurrencyCode] = useState(selectedItem?.currencyCode || '');
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [vendorSync, setVendorSync] = useState(selectedItem ? selectedItem?.vendorSync : true);
  const [vendorSyncSchedule, setVendorSyncSchedule] = useState(selectedItem?.vendorSyncSchedule || 60);

  const [bannerChanged, setBannerChanged] = useState(false);
  const [bannerBase64, setBannerBase64] = useState(null);
  const [modalOpen, setModalOpen] = useState(!selectedItem);
  const sysType = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };
  const [systemType, setSystemType] = useState(sysType.INTERNAL);
  const [externalSystemType, setExternalSystemType] = useState(FoodPosSystem.INFRASYS_POS);
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [surcharge, setSurcharge] = useState(selectedItem ? selectedItem.surcharge : false);
  const [paymentCode, setPaymentCode] = useState(selectedItem ? selectedItem.paymentCode : '');
  const [surchargePercent, setSurchargePercent] = useState(selectedItem ? selectedItem.surchargePercent : 0.0);
  const [surchargeTransactionCode, setSurchargeTransactionCode] = useState(selectedItem?.surchargeTransactionCode || '');
  const [batchOrders, setBatchOrders] = useState(!!selectedItem?.batchOrders);
  const [orderBatchCutoff, setOrderBatchCutoff] = useState(selectedItem?.orderBatchCutoff || 0);
  const [maxOrderAttempts, setMaxOrderAttempts] = useState(selectedItem?.maxOrderAttempts || 0);
  const [masterMenu, setMasterMenu] = useState(selectedItem?.masterMenuCodes || []);
  const [serviceLocations, setServiceLocations] = useState(selectedItem?.serviceLocations || []);
  const [scheduledOrderOffset, setScheduledOrderOffset] = useState(selectedItem?.scheduledOrderOffset || 0);
  const [checkNoteEnabled, setCheckNoteEnabled] = useState(!!selectedItem?.checkNoteEnabled);
  // List of comment modifiers but allow only one for now.
  const [commentModifiers, setCommentModifiers] = useState(selectedItem?.commentModifiers || []);
  const [foodThumbnailBehaviour, setFoodThumbnailBehaviour] = useState(selectedItem?.foodThumbnailBehaviour || 'SHOW');
  const [scheduleEnabled, setScheduleEnabled] = useState(!!selectedItem?.scheduleEnabled);
  const [receiptType, setReceiptType] = useState(selectedItem?.receiptType || FoodVendorReceiptType.VENDOR_RECEIPT);
  const [descriptionDisplay, setDescriptionDisplay] = useState(selectedItem?.descriptionDisplay);
  const [receiptText, setReceiptText] = useState(selectedItem?.receiptText);
  const [discountEnabled, setDiscountEnabled] = useState(!!selectedItem?.discountEnabled);

  const tab1 = new URLSearchParams(location.search).get('tab1') || 0;
  const [selectedTab1, setSelectedTab1] = useState(tab1 ? parseInt(tab1) : 0);
  const tab2 = new URLSearchParams(location.search).get('tab2') || 0;
  const [selectedTab2, setSelectedTab2] = useState(tab2 ? parseInt(tab2) : 0);
  const tabList1 = [
    { label: 'Vendor Details' },
    { label: 'Payment' },
    { label: 'Configuration' },
    { label: 'Schedule' },
    { label: 'Receipt' },
  ];
  const tabList2 = [
    { label: 'Menu' },
    { label: 'Food Items' },
    { label: 'Modifier Groups' },
    { label: 'Modifiers' },
    { label: 'Locations' },
    { label: 'Taxes' },
    { label: 'Charges' },
    { label: 'Discounts' },
    { label: 'Food Info' },
  ];

  const isTextOversize = useMemo(() => {
    return receiptText?.length >= 512;
  }, [receiptText]);

  useEffect(() => {
    if (selectedItem?.id) {
      dispatch(FoodModifierActions.options(selectedItem.id));
    }
    dispatch(FoodVendorActions.clearError());
    dispatch(SystemActions.fetchPosSystemMenus(selectedSiteId));
  }, [dispatch, selectedSiteId, selectedItem?.id]);

  useEffect(() => {
    if (banner) {
      toBase64(banner)
        .then((base64) => {
          setBannerBase64(base64);
        })
        .catch((error) => {
          console.warn('Unable to convert banner to base64', error);
        });
    }
  }, [banner]);

  useEffect(() => {
    if (lastActionType === 'FOOD_VENDOR_DELETE_SUCCESS') {
      history.push(`/food/vendor`);
    }
  }, [lastActionType, dispatch, history]);

  const handleBannerSelect = (file) => {
    setBanner(file);
    setBannerChanged(true);
  };

  const handleForceSync = async () => {
    dispatch(FoodVendorActions.forceSync(selectedSiteId, selectedItem?.id));
    await Swal.fire(
      `Outlet ${externalId} Sync Requested`,
      'PLEASE NOTE: This process will take some time to complete. Changes will be applied automatically.',
      'success'
    );
    history.push(`/food/vendor`);
  };

  const handleAddExternal = async () => {
    dispatch(FoodVendorActions.addExternal(selectedSiteId, externalId, masterMenu));
    await Swal.fire(
      `Outlet ${externalId} Sync Started`,
      'PLEASE NOTE: The sync process will run in the background. Your outlet will appear once the SYNC process has completed. Please be patient. This will take some time to complete.',
      'success'
    );
    history.push(`/food/vendor`);
  };

  const handleTabChange1 = (event, value) => {
    setSelectedTab1(value);
    history.replace(`/food/vendor/view/${selectedItem?.id}?tab1=${value}&tab2=${tab2}`);
  };

  const handleTabChange2 = (event, value) => {
    setSelectedTab2(value);
    history.replace(`/food/vendor/view/${selectedItem?.id}?tab1=${tab1}&tab2=${value}`);
  };

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      name: vendorName,
      description: description,
      email: email,
      phone: phone.sanitizedPhoneNumber,
      banner: bannerBase64,
      bannerChanged: bannerChanged,
      website: website,
      enabled: enabled,
      paymentProviderId: paymentProviderId,
      paymentCode: paymentCode,
      currencyCode: currencyCode,
      surcharge: surcharge,
      surchargePercent: surchargePercent,
      surchargeTransactionCode: surchargeTransactionCode,
      batchOrders: batchOrders,
      orderBatchCutoff: orderBatchCutoff,
      maxOrderAttempts: maxOrderAttempts,
      masterMenuCodes: masterMenu,
      serviceLocations: serviceLocations,
      scheduledOrderOffset: scheduledOrderOffset,
      checkNoteEnabled: checkNoteEnabled,
      commentModifiers: commentModifiers,
      foodThumbnailBehaviour: foodThumbnailBehaviour,
      scheduleEnabled: scheduleEnabled,
      receiptType: receiptType,
      vendorSync: vendorSync,
      vendorSyncSchedule: vendorSyncSchedule,
      descriptionDisplay: descriptionDisplay || null,
      receiptText: receiptText || null,
      discountEnabled: discountEnabled || false,
    };

    if (selectedItem) {
      dispatch(FoodVendorActions.update(payload, `/api/private/portal/food/vendor/${selectedItem.id}`, false));
    } else {
      dispatch(FoodVendorActions.add(payload, { siteId: selectedSiteId }));
    }
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setModalOpen(false);
  };

  const handleModifierUpdate = (_, value) => {
    if (!value || (commentModifiers && commentModifiers.length > 0 && commentModifiers.filter((mg) => mg === value[0].value).length > 0)) {
      return;
    }

    if (modifierOptions.length > 0) {
      setCommentModifiers([...commentModifiers, value[value.length - 1].value]);
    } else {
      setCommentModifiers([value[0].value]);
    }
  };

  const handleModifierDelete = (value) => {
    setCommentModifiers(commentModifiers.filter((item) => item !== value));
  };

  return (
    <Card>
      <Dialog open={modalOpen} onClose={handleModalClose} disableEscapeKeyDown>
        <DialogTitle>Select Food Vendor Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select value={systemType} onChange={(event) => setSystemType(event.target.value)} fullWidth>
                <MenuItem value={sysType.INTERNAL}>Internal</MenuItem>
                <MenuItem value={sysType.EXTERNAL}>External</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} hidden={systemType === sysType.INTERNAL}>
              <Select value={externalSystemType} onChange={(event) => setExternalSystemType(event.target.value)} fullWidth>
                <MenuItem value={FoodPosSystem.INFRASYS_POS}>Infrasys Cloud POS</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => history.push(`/food/vendor`)}>Cancel</Button>
          <Button color="primary" onClick={() => setModalOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={open} timeout="auto">
        <CardHeaderWithControls
          header={selectedItem ? `Update - ${selectedItem.name}` : 'Create Food Vendor'}
          subheader={'A Food Vendor is a company that prepares and sells food items'}
          buttonName={selectedItem ? 'Update' : 'Create'}
          onClick={handleSubmit}
          disabled={!vendorName || processing}
          deleteDisabled={!selectedItem?.id}
          onDelete={() => dispatch(FoodVendorActions.delete(selectedItem, `/api/private/portal/food/vendor/${selectedItem.id}`))}
          backLink={'/food/vendor'}
        />
        {error && (
          <CardContent>
            <Alert severity="error" variant={'filled'} className={'mb-2'}>
              {`Unable to add food vendor - ${error.message}`}
            </Alert>
          </CardContent>
        )}
        {systemType === sysType.EXTERNAL && (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormMultiChipSelect label={'Master Menu'} value={masterMenu} setValue={setMasterMenu} options={menuCodes} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <FormField label={'External ID'} value={externalId} setValue={setExternalId} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} alignItems={'center'} style={{ display: 'flex' }}>
                    <Button
                      variant={'contained'}
                      color="primary"
                      onClick={handleAddExternal}
                      disabled={!externalId || !masterMenu?.length > 0}
                      fullWidth
                    >
                      Sync
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
        {(selectedItem || systemType === sysType.INTERNAL) && (
          <>
            <AppBar position={'static'} elevation={0}>
              <Tabs value={selectedTab1} onChange={handleTabChange1}>
                {selectedItem && tabList1.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
              </Tabs>
            </AppBar>
            <TabPanel value={selectedTab1} index={0}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormField label={'Vendor Name'} value={vendorName} setValue={setVendorName} required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormField label={'Website'} value={website} setValue={setWebsite} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormField label={'Email'} value={email} setValue={setEmail} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={false} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormField label={'Description'} value={description} setValue={setDescription} inputProps={{ maxLength: 255 }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormBooleanSelect label={'Sync Vendor'} value={vendorSync} setValue={setVendorSync} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormField
                              label={'Sync Schedule (Minutes)'}
                              type={'number'}
                              inputProps={{ step: '1' }}
                              value={vendorSyncSchedule}
                              setValue={setVendorSyncSchedule}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {selectedItem?.lastSync && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormField
                            label={'Vendor Last Sync'}
                            value={moment.unix(selectedItem?.lastSync).format(AppConfig.DEFAULT_DATE_TIME_FORMAT)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container>
                      {externalId && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                              <FormField label={'External ID'} value={externalId} disabled />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} alignItems={'center'} style={{ display: 'flex' }}>
                              <Button variant={'contained'} color="primary" onClick={handleForceSync} disabled={!externalId} fullWidth>
                                Sync
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <FileImageSelector
                        sourceItem={selectedItem}
                        sourceItemProperty={'banner'}
                        displayName={'Banner Image'}
                        onSelect={handleBannerSelect}
                        imageCleared={(value) => {
                          if (value) {
                            setBannerBase64(null);
                            setBanner(null);
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </TabPanel>
            <TabPanel value={selectedTab1} index={1}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PaymentProviderSelect
                      value={paymentProviderId}
                      setValue={setPaymentProviderId}
                      corporationId={selectedCorporation?.id}
                    />
                  </Grid>
                  {externalId && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormField label={'Room Charge Payment Code'} value={paymentCode} setValue={setPaymentCode} />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormCurrencyList value={currencyCode} setValue={setCurrencyCode} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormBooleanSelect label={'Surcharge Enabled'} value={surcharge} setValue={setSurcharge} />
                  </Grid>
                  {surcharge && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormField
                          label={'Surcharge Percent'}
                          type={'number'}
                          inputProps={{ step: '0.01' }}
                          value={surchargePercent}
                          setValue={setSurchargePercent}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormField
                          label={'Surcharge Transaction Code'}
                          value={surchargeTransactionCode}
                          setValue={setSurchargeTransactionCode}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </TabPanel>
            <TabPanel value={selectedTab1} index={2}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormMultiChipSelect label={'Master Menu'} value={masterMenu} setValue={setMasterMenu} options={menuCodes} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormMultiChipSelect
                      label={'Service Locations'}
                      value={serviceLocations}
                      setValue={setServiceLocations}
                      options={FoodOrderLocationTypesAlias}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormBooleanSelect label={'Check Note Enabled'} value={checkNoteEnabled} setValue={setCheckNoteEnabled} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      multiple
                      fullWidth
                      name={'Comment Modifier'}
                      placeholder={'Comment Modifier'}
                      options={commentModifiers?.length > 0 ? [] : modifierOptions || []}
                      autoComplete={false}
                      filterSelectedOptions
                      getOptionSelected={(option, selected) => option.value === selected}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          required={false}
                          error={error}
                          {...params}
                          label={'Comment Modifier'}
                          margin={'dense'}
                          variant="outlined"
                        />
                      )}
                      value={commentModifiers}
                      renderTags={(values, getTagProps) =>
                        values.map((option, index) => {
                          const label = modifierOptions?.find((mg) => mg.value === option)?.label || 'N/A';
                          return (
                            <Chip {...getTagProps({ index })} size={'small'} label={label} onDelete={() => handleModifierDelete(option)} />
                          );
                        })
                      }
                      onChange={handleModifierUpdate}
                      clearOnBlur
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormBooleanSelect label={'Batch Orders'} value={batchOrders} setValue={setBatchOrders} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormBooleanSelect label={'Enable Scheduled Orders'} value={scheduleEnabled} setValue={setScheduleEnabled} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      type={'number'}
                      label={'Order Cut-off Minutes'}
                      value={orderBatchCutoff}
                      setValue={setOrderBatchCutoff}
                      inputProps={{ step: '1' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      type={'number'}
                      label={'Order Retry Attempts'}
                      value={maxOrderAttempts}
                      setValue={setMaxOrderAttempts}
                      inputProps={{ step: '1' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormField
                      type={'number'}
                      label={'Scheduled Order Offset (Minutes)'}
                      value={scheduledOrderOffset}
                      setValue={setScheduledOrderOffset}
                      inputProps={{ step: '1', min: 0, max: 1000 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormSelect
                      label={'Food Thumbnail Behaviour'}
                      value={foodThumbnailBehaviour}
                      setValue={setFoodThumbnailBehaviour}
                      options={FoodThumbnailBehaviours}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormSelect label={'Receipt Type'} value={receiptType} setValue={setReceiptType} options={FoodVendorReceiptOptions} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormSelect
                      label={'Description Display'}
                      value={descriptionDisplay}
                      setValue={setDescriptionDisplay}
                      options={VendorDescriptionDisplayOptions}
                      hasClear
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormBooleanSelect label={'Discount Enabled'} value={discountEnabled} setValue={setDiscountEnabled} />
                  </Grid>
                </Grid>
              </CardContent>
            </TabPanel>
            <TabPanel value={selectedTab1} index={3}>
              <FoodVendorScheduleList />
            </TabPanel>
            <TabPanel value={selectedTab1} index={4}>
              <CardHeader title={'Update receipt text'} subheader={'Customise the text on the bottom of the receipt'} />
              <CardContent>
                <FormField
                  label="Receipt Text"
                  value={receiptText}
                  setValue={setReceiptText}
                  error={isTextOversize}
                  helperText={isTextOversize ? 'Text must be 512 characters or less' : ''}
                  multiline={true}
                  maxRows={6}
                />
              </CardContent>
            </TabPanel>
          </>
        )}
      </Collapse>
      {selectedItem && (
        <>
          <Box
            style={{
              backgroundColor: theme.palette.grays[10],
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(!open)}
          >
            <IconButton
              size={'small'}
              style={{
                maxHeight: '15px',
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Paper>
            <AppBar position="static">
              <Tabs value={selectedTab2} onChange={handleTabChange2} variant={'scrollable'}>
                {selectedItem && tabList2.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
              </Tabs>
            </AppBar>
            <div className="content-scrollable-no-padding">
              <TabPanel value={selectedTab2} index={0}>
                <FoodMenuList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={1}>
                <FoodItemList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={2}>
                <FoodModifierGroupList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={3}>
                <FoodModifierList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={4}>
                <FoodOrderLocationList global={false} vendorId={selectedItem.id} />
              </TabPanel>
              <TabPanel value={selectedTab2} index={5}>
                <FoodTaxList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={6}>
                <FoodChargeList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={7}>
                <FoodDiscountList />
              </TabPanel>
              <TabPanel value={selectedTab2} index={8}>
                <FoodInfoList />
              </TabPanel>
            </div>
          </Paper>
        </>
      )}
    </Card>
  );
}
