import React from 'react';
import Actions from '../../../redux/actions/paymentProvider/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import { DeleteOutlined, FileCopyOutlined } from '@material-ui/icons';
import PaymentProviderActions from '../../../redux/actions/paymentProvider/actions';
import AutoTable from '../../../../components/table/AutoTable';

// Table column rendering information.
const columns = [
  {
    id: 'copy',
    label: '',
    align: 'center',
    width: 70,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Box style={{ width: 60 }}>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(PaymentProviderActions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(PaymentProviderActions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
        </Box>
      );
    },
  },
  {
    id: 'name',
    label: 'Payment Provider Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function PaymentProviderList() {
  const dispatch = useDispatch();
  const paymentProviderList = useSelector((state) => state.paymentProviderReducer.entityList);
  const lastActionType = useSelector((state) => state.paymentProviderReducer.lastActionType);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const page = useSelector((state) => state.paymentProviderReducer.page);
  const error = useSelector((state) => state.paymentProviderReducer.error);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { corporationId: selectedCorporation.id }));
  };

  return (
    <AutoTable
      title="Payment Providers"
      subheader={'Credit card payment merchant used to provide card processing services'}
      handleRefreshReport={() => dispatch(Actions.fetch(null, null, { corporationId: selectedCorporation.id }))}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="Payment Provider List"
      newButtonName="New Payment Provider"
      createNewURL="/payment-providers/new"
      detailURL="/payment-providers/view/{id}"
      idColumn={'id'}
      data={paymentProviderList}
      lastActionType={lastActionType}
      selectedCorporation={selectedCorporation}
    />
  );
}
