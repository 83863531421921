import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

//Minimum 8 characters including, special, lower case, upper case character and number
const PASSWORD_REGEX = new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/);

const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export default class CannedRegex {
  static isPhoneValid(target) {
    if (target && target.length > 6 && target.length < 16) {
      const result = parsePhoneNumberFromString(target);
      if (result && result.isValid()) {
        return true;
      }
    }
    return false;
  }

  static getPhoneMessage(invalid) {
    return invalid ? '** Phone must be in international format +64 XX XXX XXX' : '';
  }

  static getPhonePlaceholder() {
    return '** Phone number in international format +64 XX XXX XXX';
  }

  static isPasswordValid(target) {
    return target && PASSWORD_REGEX.test(target);
  }

  static getPasswordMessage(invalid) {
    return invalid ? '** Must be 8 characters long including a number and a special character' : '';
  }

  static isEmailValid(target) {
    return target && EMAIL_REGEX.test(target.trim());
  }

  static getEmailMessage(invalid) {
    return invalid ? '** Please enter a valid email address' : '';
  }

  static getEmailPlaceholder() {
    return 'Enter valid email address i.e. guest@gmail.com';
  }
}

export { EMAIL_REGEX };
