import * as DataTypes from '../../../../../../constants/DataTypes';
import Actions from '../../../../../redux/actions/specialRequest/actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import {
  getFilterField,
  getFilterUpdateMethods,
  getMultiSelectFilterField,
  getSelectFilterField,
} from '../../../../../../components/table/filter/filterUtils';
import { SpecialRequestCategoryOptions } from '../../../../../../support/SpecialRequestCategory';
import SpecialRequestState, { SpecialRequestStateOptions } from '../../../../../../support/SpecialRequestState';
import SpecialRequestActions from '../../../../../redux/actions/specialRequest/actions';
import {
  SpecialRequestAdditionalState,
  SpecialRequestAdditionalStateOptions,
} from '../../../../../../support/SpecialRequestAdditionalState';
import { SpecialRequestEscalationBoolean } from '../../../../../../support/SpecialRequestEscalationState';
import { Box, Button, useTheme } from '@material-ui/core';
import { CheckOutlined, ClearOutlined, Warning } from '@material-ui/icons';
import moment from 'moment/moment';
import { getColours } from '../../../../../../theme/MaterialTheme';
import SplitButton from '../../../../../../components/table/SplitButton';

const colours = getColours(false);
const options = ['Acknowledge', 'Complete'];
const columns = (theme, handleStatusTransition) => {
  return [
    {
      id: 'requestNumber',
      label: 'Request Number',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
    },
    {
      id: 'confirmationNumber',
      label: 'Conf Number',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'category',
      label: 'Category',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'subCategory',
      label: 'Sub Category',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_STRING,
    },
    {
      id: 'requestDate',
      label: 'Request Date',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_DATE_TIME,
    },
    {
      id: 'updated',
      label: 'Last Updated',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_DATE_TIME,
    },
    {
      id: 'deliveryTime',
      label: 'Delivery Time',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.deliveryTime) {
          return (
            <Box className="text-primary" width={1}>
              {moment.unix(value.deliveryTime).format('LLL')}
            </Box>
          );
        } else {
          return 'ASAP';
        }
      },
    },
    {
      id: 'state',
      label: 'Guest Contacted',
      align: 'center',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.state === SpecialRequestState.ANSWERED) {
          return (
            <div style={{ color: theme.palette.primary.main }}>
              <CheckOutlined />
            </div>
          );
        } else if (value.state === SpecialRequestState.UNANSWERED) {
          return (
            <div style={{ color: theme.palette.colours.dark }}>
              <ClearOutlined />
            </div>
          );
        } else {
          return 'No Action';
        }
      },
    },
    {
      id: 'additionalState',
      label: 'Status',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.additionalState === SpecialRequestAdditionalState.NOT_ACKNOWLEDGED) {
          return <Box width={1}>Pending</Box>;
        } else if (value.additionalState === SpecialRequestAdditionalState.ACKNOWLEDGED) {
          return <Box width={1}>Acknowledged</Box>;
        } else if (value.additionalState === SpecialRequestAdditionalState.COMPLETED) {
          return (
            <Box className="text-success" width={1}>
              Completed
            </Box>
          );
        } else if (value.additionalState === SpecialRequestAdditionalState.NOT_APPLICABLE) {
          return 'Dismissed';
        } else {
          return 'No Action';
        }
      },
    },
    {
      id: 'escalation',
      label: 'Escalated',
      align: 'left',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.escalation && value.escalation.length > 0) {
          return (
            <Box className="text-danger flex align-items-center" width={1}>
              <Warning />
              Escalated
            </Box>
          );
        }
        return 'No Escalation';
      },
    },
    {
      id: 'action',
      label: 'Transition Status',
      align: 'center',
      dataType: DataTypes.DATA_TYPE_FUNCTION,
      render: function (value) {
        if (value.additionalState === SpecialRequestAdditionalState.ACKNOWLEDGED) {
          return (
            <Button
              style={{
                backgroundColor: colours['success'],
                color: colours['light'],
              }}
              variant={'contained'}
              onClick={() => handleStatusTransition(value, 'Complete')}
              size={'small'}
              fullWidth
            >
              Complete
            </Button>
          );
        }
        if (value.additionalState === SpecialRequestAdditionalState.NOT_ACKNOWLEDGED) {
          return <SplitButton options={options} value={value} handleClick={handleStatusTransition} />;
        }
      },
    },
  ];
};

export default function GuestRequestList({ fetchAll = true }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const page = useSelector((state) => state.specialRequestReducer.page);
  const error = useSelector((state) => state.specialRequestReducer.error);
  const processing = useSelector((state) => state.specialRequestReducer.processing);
  const lastActionType = useSelector((state) => state.specialRequestReducer.lastActionType);
  const requests = useSelector((state) => state.specialRequestReducer.entityList);
  const requestWithToolTipContent = requests.map((r) => ({ ...r, toolTip: 'Request: ' + r.request }));
  const selectedSiteId = selectedSite?.id;
  const [state, setState] = useState({
    category: [],
    state: [],
    confirmationNumber: '',
    additionalState: [],
    escalation: [],
    startDate: moment().subtract(2, 'days'),
    endDate: moment(),
  });

  const filters = getFilterFieldsForScreen(state, setState);

  const payload = useMemo(() => {
    let searchPayload = { ...state };
    searchPayload.sort = 'lastModifiedDate,desc';
    searchPayload.siteId = selectedSiteId;
    searchPayload.startDate = moment(state.startDate).format('DD-MM-YYYY');
    searchPayload.endDate = moment(state.endDate).format('DD-MM-YYYY');
    return searchPayload;
  }, [state, selectedSiteId]);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId && !fetchAll) {
      if (selectedReservation) {
        dispatch(Actions.fetchItems(0, 25, selectedSiteId, selectedReservation.reservationNumber));
      }
    } else {
      dispatch(Actions.fetch(0, 10, payload));
    }
  }, [selectedSiteId, dispatch, selectedReservation, fetchAll, payload]);

  const handleChangePage = (ev, newPage, pageSize) => {
    if (fetchAll) {
      dispatch(Actions.fetch(newPage, pageSize, payload));
    } else {
      dispatch(Actions.fetchItems(newPage, pageSize, selectedSiteId, selectedReservation.reservationNumber));
    }
  };

  const handleStatusTransition = async (selectedItem, action) => {
    const updatedPayload = {
      ...selectedItem,
      siteId: selectedSite?.id,
      additionalState: action === 'Complete' ? SpecialRequestAdditionalState.COMPLETED : SpecialRequestAdditionalState.ACKNOWLEDGED,
    };
    if (selectedItem) {
      await dispatch(SpecialRequestActions.update(updatedPayload));
      dispatch(Actions.fetch(0, 10, payload));
    }
  };

  return (
    <AutoTable
      title="Guest Requests"
      subheader={'Special Requests for the Guest'}
      buttonName={'Export'}
      onCreateNew={() => dispatch(SpecialRequestActions.exportAll(selectedSiteId, payload))}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns(theme, handleStatusTransition)}
      prefix="SPECIAL_REQUEST"
      detailURL="/reservation/specialRequest/view/{id}"
      data={requestWithToolTipContent}
      lastActionType={lastActionType}
      idColumn={'id'}
      filters={!fetchAll ? null : filters}
      clearableFilter={true}
      hasToolTip={true}
    />
  );
}

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('startDate', 'Start Date', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
    getFilterField('endDate', 'End Date', DataTypes.DATA_TYPE_DATE, filterUpdateMethods),
    getMultiSelectFilterField('category', 'Request Category', SpecialRequestCategoryOptions, filterUpdateMethods),
    getSelectFilterField('state', 'Guest Contacted', SpecialRequestStateOptions, filterUpdateMethods),
    getFilterField('confirmationNumber', 'Confirmation Number', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getMultiSelectFilterField('additionalState', 'Request Status', SpecialRequestAdditionalStateOptions, filterUpdateMethods),
    getSelectFilterField('escalation', 'Request Escalation', SpecialRequestEscalationBoolean, filterUpdateMethods),
  ];
}
