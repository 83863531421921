import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';

export const ActivityMappingActions = {
  ...actionCreator.createActions('Activity Mapping', 'ACTIVITY_MAPPING', '/api/private/portal/activity/mapping'),
  toggle: (item) => async (dispatch) => {
    dispatch({ type: `ACTIVITY_MAPPING_UPDATE_SUCCESS`, payload: item });
  },
  toggleError: (error) => async (dispatch) => {
    dispatch({ type: `ACTIVITY_MAPPING_UPDATE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
  },
};

export default ActivityMappingActions;
