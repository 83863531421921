import { DATA_TYPE_LIST, DATA_TYPE_LIST_MULTI_SELECT } from '../../../constants/DataTypes';

function getFilterUpdateMethods(state, setState) {
  return {
    value: (name) => state[name],
    setValue: (name, value) => setState({ ...state, [name]: value }),
  };
}

function getFilterField(fieldName, label, dataType, updateMethods) {
  if (dataType === DATA_TYPE_LIST) {
    throw new Error('Use getSelectFilterField() when using a select field');
  }

  return {
    id: fieldName,
    label: label,
    options: [],
    dataType: dataType,
    ...updateMethods,
  };
}

function getSelectFilterField(fieldName, label, options = [], updateMethods, clearableFilter, clearValueToNull) {
  return {
    id: fieldName,
    label: label,
    options: options,
    dataType: DATA_TYPE_LIST,
    clearableFilter: clearableFilter,
    clearValueToNull: clearValueToNull,
    ...updateMethods,
  };
}

function getMultiSelectFilterField(fieldName, label, options = [], updateMethods) {
  return {
    id: fieldName,
    label: label,
    options: options,
    dataType: DATA_TYPE_LIST_MULTI_SELECT,
    ...updateMethods,
  };
}

export { getFilterUpdateMethods, getFilterField, getSelectFilterField, getMultiSelectFilterField };
