import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const ACCESS_CLEAR_STATE = 'ACCESS_CLEAR_STATE';

export const ACCESS_GET_USER_SITES_IN_PROGRESS = 'ACCESS_GET_USER_SITES_IN_PROGRESS';
export const ACCESS_GET_USER_SITES_SUCCESS = 'ACCESS_GET_USER_SITES_SUCCESS';
export const ACCESS_GET_USER_SITES_FAILURE = 'ACCESS_GET_USER_SITES_FAILURE';

export const PROFILE_CHECK_IN_PROGRESS = 'PROFILE_CHECK_IN_PROGRESS';
export const PROFILE_CHECK_SUCCESS = 'PROFILE_CHECK_SUCCESS';
export const PROFILE_CHECK_FAILURE = 'PROFILE_CHECK_FAILURE';

export const ACCESS_SELECT_SITE = 'ACCESS_SELECT_SITE';
export const ACCESS_SELECT_CORP = 'ACCESS_SELECT_CORP';
export const UPDATE_SELECTED_CORP_AND_SITE = 'UPDATE_SELECTED_CORP_AND_SITE';

export const ACCESS_SELECTED_SITE_UPDATE_CHAT_COLLECTION_ID = 'ACCESS_SELECTED_SITE_UPDATE_CHAT_COLLECTION_ID';

export const ACCESS_SELECT_SECTION = 'ACCESS_SELECT_SECTION';

export const UPDATE_THEME_PREFERENCE = 'UPDATE_THEME_PREFERENCE';

export const clearAccessState = () => async (dispatch) => {
  dispatch({ type: ACCESS_CLEAR_STATE });
};

export const getSiteList = (request) => async (dispatch) => {
  dispatch({ type: ACCESS_GET_USER_SITES_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/access/user/site', { body: request });
    if (response && response.length > 0) {
      dispatch({ type: ACCESS_SELECT_SITE, payload: response[0] });
    }

    dispatch({ type: ACCESS_GET_USER_SITES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ACCESS_GET_USER_SITES_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const fetchCustomerProfile = () => async (dispatch, getState) => {
  dispatch({ type: PROFILE_CHECK_IN_PROGRESS });

  try {
    console.log('PROFILE FETCH - Sending profile request');
    const response = await API.post('PrivateAPI', '/api/private/portal/access/user', { body: {} });
    console.log('PROFILE FETCH - Login profile returned as: ', response);
    const siteList = response.sites || [];
    const corporationList = response.corporations || [];

    let nextSelectedCorp = null;
    let nextSelectedSite = null;

    if (siteList.length > 0 || corporationList.length > 0) {
      const selectedCorporation = getState().accessReducer.selectedCorporation;
      const selectedSite = getState().accessReducer.selectedSite;

      nextSelectedCorp = selectedCorporation
        ? corporationList.find((c) => c.id === selectedCorporation.id)
        : corporationList.length > 0
        ? corporationList[0]
        : null;

      nextSelectedSite = filterSelectedSite(nextSelectedCorp, selectedSite, response);
    }

    console.log('PROFILE FETCH - Profile check completed successfully');
    console.log('PROFILE FETCH - selecting corporation: ', nextSelectedCorp);
    console.log('PROFILE FETCH - selecting site: ', nextSelectedSite);
    dispatch({
      type: PROFILE_CHECK_SUCCESS,
      payload: {
        ...response,
        selectedCorporation: nextSelectedCorp,
        selectedSite: nextSelectedSite,
      },
    });
    console.log('PROFILE FETCH - Post dispatch');
  } catch (error) {
    dispatch({ type: PROFILE_CHECK_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

function filterSelectedSite(currentSelectedCorp, currentSelectedSite, profile) {
  const filteredSites = currentSelectedCorp ? profile.sites.filter((s) => s.corporationId === currentSelectedCorp.id) : [];

  // try find a match for a previously selected site
  if (currentSelectedSite) {
    const match = filteredSites.find((s) => s.id === currentSelectedSite.id);
    if (match) {
      return match;
    }
  }

  if (filteredSites.length > 0) {
    return filteredSites[0];
  } else {
    return null;
  }
}

export const selectSite = (site) => async (dispatch) => {
  dispatch({ type: ACCESS_SELECT_SITE, payload: site });
};

export const selectCorp = (corp) => async (dispatch) => {
  dispatch({ type: ACCESS_SELECT_CORP, payload: corp });
  dispatch(selectSite(null));
};

export const selectCorpAndSite = (corpId, siteId) => async (dispatch, getState) => {
  const corporations = getState().accessReducer.corporationList;
  let selectedCorp = corporations.filter((c) => c.id === corpId)[0];
  dispatch(selectCorp(selectedCorp));
  dispatch(selectSiteFromList(siteId));
};

export const selectSiteFromList = (siteId) => async (dispatch, getState) => {
  const sites = getState().accessReducer.siteList;
  let selectedSite = sites.filter((s) => s.id === siteId)[0];
  dispatch(selectSite(selectedSite));
};

export const updateThemePreference = (name) => async (dispatch) => {
  dispatch({ type: UPDATE_THEME_PREFERENCE, payload: name });
};

export const checkAndSetThemePreference = () => async (dispatch, getState) => {
  const existing = getState().accessReducer.theme;

  if ('default' === existing) {
    const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (prefersDark) {
      dispatch({ type: UPDATE_THEME_PREFERENCE, payload: 'dark' });
    } else {
      dispatch({ type: UPDATE_THEME_PREFERENCE, payload: 'light' });
    }
  }
};

export const updateSelectedCorporationAndSite = (corp, site) => async (dispatch) => {
  console.log('Updating corporation and site: ', corp, site);
  dispatch({
    type: UPDATE_SELECTED_CORP_AND_SITE,
    payload: {
      selectedCorporation: corp,
      selectedSite: site,
    },
  });
};

export const selectSection = (section) => async (dispatch) => {
  dispatch({ type: ACCESS_SELECT_SECTION, payload: section });
};

export const updateChatCollectionId = (chatCollectionId) => async (dispatch) => {
  dispatch({ type: ACCESS_SELECTED_SITE_UPDATE_CHAT_COLLECTION_ID, payload: chatCollectionId });
};

export function doesSiteHaveFeature(site, feature) {
  return site && site?.featureList?.filter((sf) => sf.code === feature).length > 0;
}
