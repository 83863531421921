import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemplatePreviewModal(props) {
  const { modalOpen, setModalOpen, htmlString } = props;

  return (
    <Dialog
      open={modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModalOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Template Preview</DialogTitle>
      <DialogContent>
        <iframe title="Template Preview Dialog" srcDoc={htmlString} style={{ minHeight: '57vh', minWidth: '25vw' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="primary" autoFocus>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
