import React, { useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Link } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import RequestRedirectModal from './RequestRedirectModal';
import SpecialRequestRedirectActions from '../../../../redux/actions/specialRequestRedirect/actions';
import FoodVendorActions from '../../../../redux/actions/inRoom/ordering/vendor/actions';
import SpecialRequestEscalationRedirectActions from '../../../../redux/actions/specialRequestEscalationRedirect/actions';

const columns = (history, setDataObject, setCreateCategoryEmailModalOpen, vendorOptions, isEscalation) => [
  {
    id: 'actions',
    label: '',
    align: 'left',
    width: 100,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={'inherit'}
          onClick={() =>
            isEscalation
              ? dispatch(SpecialRequestEscalationRedirectActions.delete(value))
              : dispatch(SpecialRequestRedirectActions.delete(value))
          }
        >
          <DeleteOutlined />
        </IconButton>
      );
    },
  },
  {
    id: 'category',
    label: 'Guest Request Category',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'associatedId',
    label: 'Vendor Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return vendorOptions?.find((vendor) => value.associatedId === parseInt(vendor.value))?.label;
    },
  },
  {
    id: 'type',
    label: 'Redirect Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'contactGroup',
    label: 'Contact Group',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_ENUM,
  },
  {
    id: 'contactName',
    label: 'Contact Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'value',
    label: 'Value',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return (
        <Link
          component="button"
          onClick={() => {
            setDataObject({
              id: value.id,
              category: value.category,
              type: value.type,
              contactGroup: value.contactGroup,
              contactName: value.contactName,
              value: value.value,
              associatedId: value.associatedId,
            });
            setCreateCategoryEmailModalOpen(true);
          }}
          children={value.value}
        />
      );
    },
  },
];

export default function RequestRedirectList({ isEscalation }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.specialRequestRedirectReducer.entityList);
  const escalationData = useSelector((state) => state.specialRequestEscalationRedirectReducer.entityList);
  const processing = useSelector((state) => state.specialRequestRedirectReducer.processing);
  const page = useSelector((state) => state.specialRequestRedirectReducer.page);
  const escalationPage = useSelector((state) => state.specialRequestEscalationRedirectReducer.page);
  const error = useSelector((state) => state.specialRequestRedirectReducer.error);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const vendorOptions = useSelector((state) => state.foodVendorReducer.vendorOptions);
  const parameters = { sort: 'category,asc', siteId: selectedSite?.id };
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const [createCategoryEmailModalOpen, setCreateCategoryEmailModalOpen] = useState(false);
  const [dataObject, setDataObject] = useState(null);

  useEffect(() => {
    if (selectedSite?.id) {
      dispatch(FoodVendorActions.fetchAll(selectedSite?.id));
    }
  }, [dispatch, selectedSite?.id]);

  function closeCreateCategoryEmailModal() {
    setCreateCategoryEmailModalOpen(false);
    setDataObject(null);
  }

  function fetchRecipients(isEscalation) {
    if (isEscalation) {
      return () => dispatch(SpecialRequestEscalationRedirectActions.fetchEscalationRecipients(0, 25, parameters));
    } else {
      return () => dispatch(SpecialRequestRedirectActions.fetch(0, 25, parameters));
    }
  }

  function handlePageChange(isEscalation, ev, newPage, pageSize) {
    if (isEscalation) {
      return dispatch(SpecialRequestEscalationRedirectActions.fetchEscalationRecipients(newPage, pageSize, parameters));
    } else {
      return dispatch(SpecialRequestRedirectActions.fetch(newPage, pageSize, parameters));
    }
  }

  return (
    <React.Fragment>
      <RequestRedirectModal
        open={createCategoryEmailModalOpen}
        handleClose={closeCreateCategoryEmailModal}
        dataObject={dataObject}
        setDataObject={setDataObject}
        isEscalation={isEscalation}
      />
      <AutoTable
        title={isEscalation ? 'Guest Request Escalation Notifications' : 'Guest Request Notifications'}
        subheader={
          isEscalation
            ? 'Sent EMAIL or SMS notifications when a request is not actioned in time'
            : 'Sent EMAIL or SMS notifications when a request is made'
        }
        handleRefreshReport={fetchRecipients(isEscalation)}
        selectedSite={selectedSite}
        selectedCorporation={selectedCorporation}
        processing={processing}
        handleChangePage={(ev, newPage, pageSize) => handlePageChange(isEscalation, ev, newPage, pageSize)}
        page={isEscalation ? escalationPage : page}
        error={error}
        columns={columns(history, setDataObject, setCreateCategoryEmailModalOpen, vendorOptions, isEscalation)}
        prefix="NOTIFICATION_SCHEDULE"
        idColumn={'id'}
        data={isEscalation ? escalationData : data}
        buttonName={'Create New'}
        onCreateNew={() => setCreateCategoryEmailModalOpen(true)}
      />
    </React.Fragment>
  );
}
