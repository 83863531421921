import { Box, Button, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useMemo, useRef } from 'react';

export default function ChartTable({ chartData = [], columnOverrides = {}, visible }) {
  const tableId = useRef(`ct-${crypto.randomUUID()}`);

  const tableColumns = useMemo(() => {
    const map = {};
    chartData.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== 'colour') {
          map[key] = { label: columnOverrides[key] || key, key: key };
        }
      });
    });

    return Object.values(map);
  }, [chartData, columnOverrides]);

  if (chartData.length <= 0) {
    return null;
  }

  return (
    <Collapse in={visible}>
      <Box display={'flex'} justifyContent={'end'}>
        <Button color="primary" size={'small'} onClick={() => downloadCSVFromJson(`${Date.now()}.csv`, tableColumns, chartData)}>
          Download CSV
        </Button>
      </Box>

      <Table id={tableId.current}>
        <TableHead>
          <TableRow>
            {tableColumns.map((entry) => {
              return <TableCell key={`${tableId.current}-th-${entry.key}`}>{entry.label}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {chartData.map((data, index) => {
            return (
              <TableRow key={`${tableId.current}-tr-${index}`}>
                {tableColumns.map((entry) => {
                  return <TableCell key={`${tableId.current}-td-${entry.key}-${index}`}>{data[entry.key]}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Collapse>
  );
}

const downloadCSVFromJson = (filename, tableColumns = [], tableData = []) => {
  let csv = tableData.map((row) => tableColumns.map((header) => row[header.key]).join(','));
  csv.unshift(tableColumns.map((e) => e.label).join(','));
  csv = csv.join('\r\n');

  // Create link; and download
  const link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
