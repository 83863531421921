import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Image', 'ACTIVITY_IMAGE');

function activityImageReducer(state = initial, action) {
  switch (action.type) {
    case `ACTIVITY_IMAGE_ADD_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList, ...action.payload],
        selectedItem: action.payload,
      };
    }
    case `ACTIVITY_IMAGE_REORDER_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: state.entityList
          .map((e) => (e.id !== action.payload.id ? e : action.payload))
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
        selectedItem: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default activityImageReducer;
