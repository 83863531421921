import { Grid } from '@material-ui/core';
import SiteDashboardWidget from '../detail/widget/SiteDashboardWidget';
import DashboardSection from '../../../../../support/DashboardSection';
import React, { useEffect, useState } from 'react';
import SiteFeature from '../../../../../support/SiteFeature';
import { doesSiteHaveFeature } from '../../../../redux/actions/access/actions';
import API from '@aws-amplify/api';
import Charts from '../detail/Charts';
import Objects from '../../../../../support/Objects';
import { useSelector } from 'react-redux';
import TabPanel from '../../../control/TabPanel';
import ChartCardPair from '../detail/chart/ChartCardPair';
import LivertonAreaChart from '../detail/chart/LivertonAreaChart';
import moment from 'moment/moment';
import LivertonLineChart from '../detail/chart/LivertonLineChart';

export default function FinancialsTab({ selectedTab, tabIndex, timeframe, selectedSite }) {
  const widgetData = useSelector((state) => state.dashboardReducer.widgetData);
  const [upsellRevenueData, setUpsellRevenueData] = React.useState([]);
  const [purchasesData, setPurchasesData] = React.useState([]);
  const [tableViewMap, setTableViewMap] = useState({
    upsell: false,
    purchases: false,
  });

  useEffect(() => {
    const siteId = selectedSite?.id;
    if (!siteId || !timeframe || selectedTab !== tabIndex) {
      return;
    }

    const payload = {
      ...timeframe,
      start: moment(timeframe.start).format('YYYY-MM-DD'),
      end: moment(timeframe.end).format('YYYY-MM-DD'),
    };

    if (doesSiteHaveFeature(selectedSite, SiteFeature.UPSELL)) {
      API.post('PrivateAPI', `/api/private/portal/dashboard/chart/upsell/timeline?siteId=${siteId}`, { body: payload })
        .then((response) => {
          setUpsellRevenueData(Charts.prepareChartLineData(response));
        })
        .catch((error) => {
          console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
        });
    }

    API.post('PrivateAPI', `/api/private/portal/dashboard/chart/purchases/timeline?siteId=${siteId}`, { body: payload })
      .then((response) => {
        setPurchasesData(Charts.prepareChartData(response));
      })
      .catch((error) => {
        console.warn('Unable to fetch chart data: ', Objects.getErrorFromResponse(error));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, timeframe, selectedTab]);

  return (
    <TabPanel value={selectedTab} index={tabIndex} p={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SiteDashboardWidget widgetData={widgetData} site={selectedSite} section={DashboardSection.FINANCIALS} />
        </Grid>

        <ChartCardPair
          charts={[
            {
              title: 'Purchases',
              subheader: 'Purchases made through web check-in or the mobile app',
              tableView: tableViewMap.purchases,
              setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, purchases: toggleValue }),
              component: (tableView) => <LivertonAreaChart data={purchasesData} tableView={tableView} columnOverrides={TABLE_LABELS} />,
            },
            doesSiteHaveFeature(selectedSite, SiteFeature.UPSELL)
              ? {
                  title: 'Upsell Revenue',
                  subheader: 'Addons revenue captured during check-in',
                  tableView: tableViewMap.upsell,
                  setTableView: (toggleValue) => setTableViewMap({ ...tableViewMap, upsell: toggleValue }),
                  component: (tableView) => (
                    <LivertonLineChart data={upsellRevenueData} tableView={tableView} columnOverrides={TABLE_LABELS} />
                  ),
                }
              : null,
          ]}
        />
      </Grid>
    </TabPanel>
  );
}

const TABLE_LABELS = {
  x: 'Date',
};
