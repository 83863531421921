import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Mapping', 'ACTIVITY_MAPPING');

function activityMappingReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default activityMappingReducer;
