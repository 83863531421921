import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../../redux/actions/notificationTemplate/actions';
import NotificationInfographicActions from '../../../../redux/actions/notificationTemplate/infoGraphic/actions';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TemplatePreviewModal from './TemplatePreviewModal';
import toBase64 from '../../../../../support/toBase64Converter';
import API from '@aws-amplify/api';
import FileImageSelector from '../../../../../components/file/FileImageSelector';
import { useTheme } from '@material-ui/styles';
import { CheckOutlined, DeleteOutlined, SearchOutlined } from '@material-ui/icons';
import { NotificationTemplateType } from './NotificationTemplateType';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../components/form/FormField';
import AutoTable from '../../../../../components/table/AutoTable';
import InfographicActions from '../../../../redux/actions/notificationTemplate/infoGraphic/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { NotificationInfographicType } from '../notification/NotificationType';

const columns = [
  {
    id: 'disable',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(InfographicActions.delete(value))}>
            <DeleteOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'infographicType',
    label: 'Infographic Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return Object.values(NotificationInfographicType).find((n) => n.value === value.infographicType)?.label;
    },
  },
  {
    id: 'position',
    label: 'Position',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function EmailNotificationTemplate() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.notificationTemplateReducer.error);
  const lastActionType = useSelector((state) => state.notificationTemplateReducer.lastActionType);
  const processing = useSelector((state) => state.notificationTemplateReducer.processing);
  const selectedItem = useSelector((state) => state.notificationTemplateReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const infographicLastActionType = useSelector((state) => state.notificationInfographicReducer.lastActionType);
  const infographicList = useSelector((state) => state.notificationInfographicReducer.entityList);
  const page = useSelector((state) => state.notificationInfographicReducer.page);
  const infographicError = useSelector((state) => state.notificationInfographicReducer.error);
  const infographicProcessing = useSelector((state) => state.notificationInfographicReducer.processing);
  const selectedSiteId = selectedSite?.id;

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [banner, setBanner] = React.useState(selectedItem?.bannerColor || '#ffffff');
  const [paragraph1, setParagraph1] = React.useState(selectedItem?.paragraph1 || '');
  const [paragraph2, setParagraph2] = React.useState(selectedItem?.paragraph2 || '');
  const [locale, setLocale] = React.useState(selectedItem?.locale || 'en');
  const [enableApp, setEnableApp] = React.useState(selectedItem?.enableApp || false);
  const [enableUpsell, setEnableUpsell] = React.useState(selectedItem?.enableUpsell || false);
  const [enableInfoGraphic, setEnableInfoGraphic] = React.useState(selectedItem?.enableInfoGraphic || false);
  const [displayFooterImage, setDisplayFooterImage] = React.useState(selectedItem?.displayFooterImage || false);
  const [hotelImageSelected, setHotelImageSelected] = React.useState(null);
  const [footerImageSelected, setFooterImageSelected] = React.useState(null);
  const [titleImageSelected, setTitleImageSelected] = React.useState(null);
  const [previewHtmlString, setPreviewHtmlString] = React.useState(null);
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const [defaultHotelImage, setDefaultHotelImage] = React.useState(selectedItem?.hotelImageUrl || null);
  const [defaultTitleImage, setDefaultTitleImage] = React.useState(selectedItem?.titleImageUrl || null);
  const [defaultFooterImage, setDefaultFooterImage] = React.useState(selectedItem?.footerImageUrl || null);

  React.useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      lastActionType === 'NOTIFICATION_TEMPLATE_DELETE_SUCCESS' ||
      lastActionType === 'NOTIFICATION_TEMPLATE_ADD_SUCCESS' ||
      lastActionType === 'NOTIFICATION_TEMPLATE_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const titleImageToSend = await toBase64(titleImageSelected);
    const hotelImageToSend = await toBase64(hotelImageSelected);
    const footerImageToSend = await toBase64(footerImageSelected);

    const payload = {
      id: selectedItem ? selectedItem.id : null,
      name: name,
      bannerColor: banner,
      paragraph1: paragraph1,
      paragraph2: paragraph2,
      locale: locale,
      type: NotificationTemplateType.EMAIL,
      enableUpsell: enableUpsell,
      enableApp: enableApp,
      siteId: selectedSiteId,
      titleImage: titleImageToSend,
      titleImageName: titleImageSelected ? titleImageSelected.name : defaultTitleImage,
      hotelImage: hotelImageToSend,
      hotelImageName: hotelImageSelected ? hotelImageSelected.name : defaultHotelImage,
      footerImageName: footerImageSelected ? footerImageSelected.name : defaultFooterImage,
      footerImage: footerImageToSend,
      displayFooterImage: displayFooterImage,
      enableInfoGraphic: enableInfoGraphic,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, '/api/private/portal/site/template/update'));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const handleOnClickPreview = React.useCallback(() => {
    const payload = {
      body: {
        id: selectedItem ? selectedItem.id : null,
        name: name,
        banner: banner,
        paragraph1: paragraph1,
        paragraph2: paragraph2,
        enableApp: enableApp,
        enableUpsell: enableUpsell,
        locale: locale,
        type: NotificationTemplateType.EMAIL,
        siteId: selectedSiteId,
        displayFooterImage: displayFooterImage,
        enableInfoGraphic: enableInfoGraphic,
      },
    };
    API.post('PrivateAPI', `/api/private/portal/site/template/preview/${selectedSiteId}`, payload)
      .then((response) => {
        setPreviewHtmlString(response.content);
        setPreviewModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setPreviewModalOpen(false);
        setPreviewHtmlString('<p>Error - unable to generate the preview!</p>');
      });
  }, [
    selectedItem,
    name,
    banner,
    paragraph1,
    paragraph2,
    enableApp,
    enableUpsell,
    locale,
    selectedSiteId,
    displayFooterImage,
    enableInfoGraphic,
  ]);

  const handleRefreshGraphic = (selectedSite, selectedItem) => {
    if (selectedSite && selectedItem) {
      dispatch(NotificationInfographicActions.fetch(0, 10, { templateId: selectedItem.id }));
    }
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(NotificationInfographicActions.fetch(newPage, pageSize, { templateId: selectedItem.id }));
  };

  return (
    <Card>
      <NotificationTemplateHeader
        header={selectedItem ? 'Edit Notification Template' : 'New Notification Template'}
        subheader={'Colours and images configured will be used to brand notification emails sent to customers'}
        onClick={handleSubmit}
        processing={processing}
        onPreviewClick={handleOnClickPreview}
        selectedItem={selectedItem}
        disabled={!name || processing}
        goBack={() => history.goBack()}
      />

      <TemplatePreviewModal modalOpen={previewModalOpen} setModalOpen={setPreviewModalOpen} htmlString={previewHtmlString} />

      <CardContent>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12} sm={12}>
              <Alert severity="error" variant={'filled'}>
                {`Unable to edit/delete notification template - ${error.message}`}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField value={name} label={'Notification Template Name'} setValue={setName} type={'text'} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField value={locale} label={'Language'} setValue={setLocale} type={'text'} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField value={banner} label={'Banner Colour'} setValue={setBanner} type={'color'} error={!banner} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect fullWidth label={'Reservation Upsell'} value={enableUpsell} setValue={setEnableUpsell} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect fullWidth label={'Display Footer Image'} value={displayFooterImage} setValue={setDisplayFooterImage} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect fullWidth label={'Mobile App'} value={enableApp} setValue={setEnableApp} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect fullWidth label={'Infographic'} value={enableInfoGraphic} setValue={setEnableInfoGraphic} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              type="text"
              label="Additional Text - Web Check In"
              variant={'outlined'}
              minRows={5}
              onChange={(event) => setParagraph1(event.target.value)}
              multiline
              value={paragraph1}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              id="paragraph2"
              label="Additional Text - Web Check In Complete"
              type="text"
              variant={'outlined'}
              onChange={(event) => setParagraph2(event.target.value)}
              multiline
              minRows={5}
              value={paragraph2}
            />
          </Grid>

          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'titleImageUrl'}
            displayName={'Site Logo'}
            onSelect={(file) => setTitleImageSelected(file)}
            imageCleared={(value) => {
              if (value) {
                setTitleImageSelected(null);
                setDefaultTitleImage(null);
              }
            }}
          />

          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'hotelImageUrl'}
            displayName={'Site Image'}
            onSelect={(file) => setHotelImageSelected(file)}
            imageCleared={(value) => {
              if (value) {
                setHotelImageSelected(null);
                setDefaultHotelImage(null);
              }
            }}
          />
          {displayFooterImage && (
            <FileImageSelector
              sourceItem={selectedItem}
              sourceItemProperty={'footerImageUrl'}
              displayName={'Footer Image'}
              onSelect={(file) => setFooterImageSelected(file)}
              imageCleared={(value) => {
                if (value) {
                  setFooterImageSelected(null);
                  setDefaultFooterImage(null);
                }
              }}
            />
          )}
        </Grid>
      </CardContent>

      <Grid item xs={12}>
        <Collapse in={selectedItem?.id}>
          <AutoTable
            title="Infographic"
            subheader={'This infographic will be applied to the email template depending on Notification type'}
            handleRefreshReport={() => handleRefreshGraphic(selectedSite, selectedItem)}
            handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
            processing={infographicProcessing}
            page={page}
            selectItem={(item) => dispatch(NotificationInfographicActions.select(item))}
            error={infographicError}
            clearError={() => dispatch(NotificationInfographicActions.clearError())}
            columns={columns}
            prefix="NOTIFICATION_INFOGRAPHIC"
            buttonName="New Graphic"
            createNewURL="/site/templates/infographic/new"
            detailURL="/site/templates/infographic/view/{id}"
            idColumn={'id'}
            data={infographicList}
            lastActionType={infographicLastActionType}
          />
        </Collapse>
      </Grid>
    </Card>
  );
}

function NotificationTemplateHeader({ header, subheader, selectedItem, disabled, goBack, onClick, onPreviewClick, processing }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleDeleteTemplate = () => {
    dispatch(Actions.delete(selectedItem, `/api/private/portal/site/template/delete/${selectedItem.id}`));
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item xs={12} sm={12} md={6}>
        <CardHeader title={header} subheader={subheader} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} justifyContent={matches ? 'flex-end' : 'center'} className={'flex pr-2'}>
        <Button
          color={'secondary'}
          onClick={handleOnClick}
          startIcon={processing ? <CircularProgress size={15} /> : <CheckOutlined />}
          variant={'contained'}
          disabled={disabled}
          className={'mr-1'}
        >
          {selectedItem ? 'Update' : 'Create'}
        </Button>

        {selectedItem && (
          <Button variant={'contained'} className={'mr-1'} color={'primary'} onClick={onPreviewClick} startIcon={<SearchOutlined />}>
            Preview
          </Button>
        )}

        {selectedItem && (
          <Button variant={'text'} className={'mr-1'} onClick={handleDeleteTemplate}>
            Delete
          </Button>
        )}

        <Button variant={'text'} onClick={handleGoBack}>
          Back
        </Button>
      </Grid>
    </Grid>
  );
}
