import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';

export const LINKED_SITES_FETCH_SUCCESS = 'LINKED_SITES_FETCH_SUCCESS';
export const LINKED_SITES_FETCH_FAILURE = 'LINKED_SITES_FETCH_FAILURE';

export const LINKED_SITES_UPDATE_SUCCESS = 'LINKED_SITES_UPDATE_SUCCESS';
export const LINKED_SITES_UPDATE_FAILURE = 'LINKED_SITES_UPDATE_FAILURE';

export const LINKED_SITES_CLEAR_ERROR = 'LINKED_SITES_CLEAR_ERROR';

export const clearError = () => async (dispatch) => {
  dispatch({ type: LINKED_SITES_CLEAR_ERROR });
};

export const getLinkedSites = (appId) => async (dispatch) => {
  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/appSites?appId=${appId}`);
    dispatch({ type: LINKED_SITES_FETCH_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: LINKED_SITES_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const updateLinkedSites = (request) => async (dispatch) => {
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/appSites/update', { body: request });
    dispatch({ type: LINKED_SITES_UPDATE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: LINKED_SITES_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};
