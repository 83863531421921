import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Schedule Rate', 'ACTIVITY_SCHEDULE_RATE');

function activityRateReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default activityRateReducer;
