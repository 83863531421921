import * as DataTypes from '../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import useFetch from '../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'deviceId',
    label: 'Device Id',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'token',
    label: 'token',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function AppUserPushTokenList() {
  const selectedItem = useSelector((state) => state.appUsersReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(`/api/private/portal/app-user/push-token?username=${selectedItem.username}`);

  return (
    <AutoTable
      title="Push Tokens"
      subheader={'Mobile app push notification token assigned to this account '}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="APP_USER_PUSH_TOKE"
      backHidden={true}
      data={data}
      lastActionType={'APP_USER_PUSH_TOKEN'}
    />
  );
}
