import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'code',
    label: 'Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'name',
    label: 'Name  ',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      return parseFloat(values.total).toFixed(2);
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function PurchaseItemList() {
  const purchaseItem = useSelector((state) => state.purchaseReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(`/api/payment/filter/${purchaseItem.id}/items`);

  return (
    <AutoTable
      title={`Purchase Items - ${purchaseItem?.reference}`}
      subheader={'Line items contained in this purchase'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      page={page}
      processing={processing}
      error={error}
      columns={columns}
      prefix="PURCHASE_ITEM"
      detailURL="/reservation/purchase-item/view"
      data={data}
    />
  );
}
