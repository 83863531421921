import actionCreator from '../../support/actionCreator';
import Objects from '../../../../support/Objects';
import API from '@aws-amplify/api';

const ThemeActions = {
  ...actionCreator.createActions('Theme', 'THEME', '/api/private/portal/theme'),
  duplicate: (theme) => async (dispatch) => {
    Objects.notNull(theme, 'item cannot be null');

    dispatch({ type: `THEME_ADD_IN_PROGRESS` });
    try {
      const response = await API.post('PrivateAPI', `/api/private/portal/theme/duplicate/${theme.id}`, {});
      dispatch({ type: `THEME_ADD_SUCCESS`, payload: response });
    } catch (error) {
      dispatch({ type: `THEME_ADD_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default ThemeActions;
