import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

const EventHookActions = {
  ...actionCreator.createActions('Event Hook', 'EVENT_HOOK', '/api/private/portal/site/{siteId}/event/hook'),
  fetchAll: (siteId, hookType) => async (dispatch) => {
    await dispatch({ type: 'EVENT_HOOK_SELECT_ALL_IN_PROGRESS' });
    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/site/${siteId}/event/hook/type/${hookType}`, {});
      await dispatch({ type: `EVENT_HOOK_SELECT_ALL_SUCCESS`, payload: result });
    } catch (error) {
      console.warn('Failed to fetch all event hook with result: ', error);
      dispatch({
        type: `FOOD_CHARGE_SELECT_ALL_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default EventHookActions;
