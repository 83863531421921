import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../redux/actions/securityEventSetting/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, Link } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import EventPriority from '../../../../../support/EventPriority';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';

export default function SecurityEventSettingEdit() {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.securityEventSettingReducer.lastActionType);
  const selectedItem = useSelector((state) => state.securityEventSettingReducer.selectedItem);
  const error = useSelector((state) => state.securityEventSettingReducer.error);

  const [eventName, setEventName] = React.useState(selectedItem ? selectedItem.eventName : '');
  const [eventPriority, setEventPriority] = React.useState(selectedItem ? selectedItem.eventPriority : EventPriority.LOG_ON_EVENT);
  const [lockEventCode, setLockEventCode] = React.useState(selectedItem ? selectedItem.lockEventCode : '');
  const [securityEventCode, setSecurityEventCode] = React.useState(selectedItem ? selectedItem.securityEventCode : '');
  const [eventMessage, setEventMessage] = React.useState(selectedItem ? selectedItem.eventMessage : '');
  const [eventSubMessage, setEventSubMessage] = React.useState(selectedItem ? selectedItem.eventSubMessage : '');
  const [eventAccessZone, setEventAccessZone] = React.useState(selectedItem ? selectedItem.eventAccessZone : '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'SECURITY_EVENT_SETTING_DELETE_SUCCESS' ||
      lastActionType === 'SECURITY_EVENT_SETTING_ADD_SUCCESS' ||
      lastActionType === 'SECURITY_EVENT_SETTING_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      eventName,
      lockEventCode,
      securityEventCode,
      eventPriority,
      eventMessage,
      eventSubMessage,
      eventAccessZone,
      siteId: selectedSite?.id,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReadyForSubmit = lockEventCode && securityEventCode && eventPriority && eventName && eventMessage;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Event Setting' : 'Create Event Setting'}
        subheader={'Event configuration details for each event'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReadyForSubmit}
        deleteDisabled={false}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert
            severity="error"
            action={
              <Link component="button" onClick={() => dispatch(Actions.clearError())}>
                <ClearOutlined />
              </Link>
            }
          >
            {'Unable to update event setting - ' + error.message}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Event Name'} value={eventName} setValue={setEventName} type={'text'} error={!eventName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Event Priority'}
              value={eventPriority}
              setValue={setEventPriority}
              options={Object.keys(EventPriority).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Lock Event Code'} value={lockEventCode} setValue={setLockEventCode} type={'text'} error={!lockEventCode} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Security Event Code'}
              value={securityEventCode}
              setValue={setSecurityEventCode}
              type={'text'}
              error={!securityEventCode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Event Message'} value={eventMessage} setValue={setEventMessage} type={'text'} error={!eventMessage} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Event Sub Message'}
              value={eventSubMessage}
              setValue={setEventSubMessage}
              type={'text'}
              error={!eventSubMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Event Access Zone Name'}
              value={eventAccessZone}
              setValue={setEventAccessZone}
              type={'text'}
              error={!eventAccessZone}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
