const reducerCreator = {
  getInitialState: () => {
    return {
      lastActionType: 'NONE',
      entityList: [],
      selectedItem: null, //currently selected item
      links: {},
      processing: false,
      page: {
        size: 25,
        totalElements: 0,
        totalPages: 0,
        number: 0,
      },
      error: null,
    };
  },
  createReducer: (name, prefix) => {
    return function reducer(state = {}, action) {
      switch (action.type) {
        case `${prefix}_FETCH_IN_PROGRESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: true,
            error: null,
          };
        }
        case `${prefix}_FETCH_SUCCESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            entityList: action.payload.content,
            links: action.payload.links,
            page: action.payload.page,
          };
        }
        case `${prefix}_FETCH_FAILURE`: {
          return {
            ...state,
            lastActionType: action.type,
            entityList: [],
            processing: false,
            error: action.payload,
          };
        }
        case `${prefix}_ADD_IN_PROGRESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: true,
            error: null,
          };
        }
        case `${prefix}_ADD_SUCCESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            entityList: [...state.entityList, action.payload],
            selectedItem: action.payload,
          };
        }
        case `${prefix}_ADD_FAILURE`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: action.payload,
          };
        }
        case `${prefix}_UPDATE_IN_PROGRESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: true,
            error: null,
          };
        }
        case `${prefix}_UPDATE_SUCCESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            entityList: state.entityList.map((e) => (e.id !== action.payload.id ? e : action.payload)),
            selectedItem: action.payload,
          };
        }
        case `${prefix}_UPDATE_FAILURE`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: action.payload,
          };
        }
        case `${prefix}_DELETE_IN_PROGRESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: true,
            error: null,
          };
        }
        case `${prefix}_DELETE_SUCCESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            entityList: [...state.entityList].filter((e) => e.id !== action.payload.id),
          };
        }
        case `${prefix}_DELETE_FAILURE`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: action.payload,
          };
        }
        case `${prefix}_SELECT`: {
          return {
            ...state,
            selectedItem: action.payload,
          };
        }
        case `${prefix}_CLEAR_ERROR`: {
          return {
            ...state,
            lastActionType: action.type,
            error: null,
          };
        }
        case `${prefix}_IMPORT_IN_PROGRESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: true,
            error: null,
          };
        }
        case `${prefix}_IMPORT_SUCCESS`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: null,
          };
        }
        case `${prefix}_NO_CHANGES`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: null,
          };
        }
        case `${prefix}_IMPORT_FAILURE`: {
          return {
            ...state,
            lastActionType: action.type,
            processing: false,
            error: action.payload,
          };
        }

        default:
          return state;
      }
    };
  },
};

export default reducerCreator;
