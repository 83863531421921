import reducerCreator from '../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Theme', 'THEME');

function siteThemeReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default siteThemeReducer;
