import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function ContentContainer({ variant, children }) {
  const styles = useStyles();
  return (
    <div className={variant === 'centered' ? styles.centered : styles.container}>
      <div className={styles.maxWidth}>{children}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    display: 'grid',
  },
  maxWidth: {
    width: '100%',
    minHeight: '350px',
    minWidth: '700px',
    maxWidth: '960px',
    padding: '1.0rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px',
      minHeight: '300px',
    },
  },
}));
