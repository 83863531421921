import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ActivityActions from '../../../redux/actions/activity/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { AppBar, Card, CardContent, Chip, Grid, Paper, Tab, Tabs, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../components/form/FormField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ActivityImageList from './ActivityImageList';
import TabPanel from '../../control/TabPanel';
import ActivityScheduleEdit from './ActivityScheduleEdit';
import ActivityCalendar from './ActivityCalendar';

export default function ActivityEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.activityReducer);
  const activityId = selectedItem?.id;
  const vendor = useSelector((state) => state.vendorReducer.selectedItem);
  const [name, setName] = useState(selectedItem ? selectedItem.name : '');
  const [description, setDescription] = useState(selectedItem ? selectedItem.description : '');
  const [tags, setTags] = useState(selectedItem ? selectedItem.tags : []);

  const tab = new URLSearchParams(location.search).get('tab');
  const [selectedTab, setSelectedTab] = useState(tab ? parseInt(tab) : 0);
  const tabList = [{ label: 'Images' }, { label: 'Schedule' }, { label: 'Calendar' }];

  useEffect(() => {
    dispatch(ActivityActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'ACTIVITY_DELETE_SUCCESS' ||
      lastActionType === 'ACTIVITY_EDIT_SUCCESS' ||
      lastActionType === 'ACTIVITY_ADD_SUCCESS'
    ) {
      history.push(`/vendor/view/${vendor.id}`);
    }
  }, [lastActionType, dispatch, history, vendor]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      name: name,
      description: description,
      tags: tags,
    };

    if (selectedItem) {
      dispatch(ActivityActions.update(payload, null, false));
    } else {
      payload.vendorId = vendor.id;
      dispatch(ActivityActions.add(payload));
    }
  };

  const handleTagUpdate = (value) => {
    if (!value || (tags && tags.length > 0 && tags.filter((tag) => tag === value).length > 0)) {
      return;
    }

    if (tags.length > 0) {
      setTags([...tags, value]);
    } else {
      setTags([value]);
    }
  };

  const handleTagDelete = (value) => {
    setTags(tags.filter((item) => item !== value));
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Activity' : 'Create Activity'}
        subheader={'An activity is a service provided by a vendor'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!name || processing}
        onDelete={() => dispatch(ActivityActions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add activity - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Activity Name'} value={name} setValue={setName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              multiple
              id="tags"
              options={[]}
              defaultValue={[]}
              freeSolo
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label={'Tags'} placeholder="Tags" variant={'outlined'} margin={'dense'} />}
              value={tags}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => <Chip key={option} label={option} onDelete={() => handleTagDelete(option)} />)
              }
              onChange={(event) => handleTagUpdate(event.target.value)}
              onClose={(event) => handleTagUpdate(event.target.value)}
              clearOnBlur
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormField label={'Description'} value={description} setValue={setDescription} multiline rows={2} />
          </Grid>
        </Grid>
      </CardContent>
      {selectedItem && (
        <Paper>
          <AppBar position="static">
            <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
              {selectedItem && tabList.map((item) => <Tab label={item.label} key={`t-${item.label}`} />)}
            </Tabs>
          </AppBar>
          <div className="content-scrollable-no-padding">
            <TabPanel value={selectedTab} index={0}>
              <ActivityImageList />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <ActivityScheduleEdit />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <ActivityCalendar activityId={activityId} />
            </TabPanel>
          </div>
        </Paper>
      )}
    </Card>
  );
}
