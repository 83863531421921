import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Charge', 'FOOD_CHARGE');

function foodChargeReducer(state = initial, action) {
  switch (action.type) {
    case `FOOD_CHARGE_SELECT_ALL_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
      };
    }
    case `FOOD_CHARGE_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: action.payload,
      };
    }
    case `FOOD_CHARGE_SELECT_ALL_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default foodChargeReducer;
