const KeyIssueBehavior = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
};

const KeyIssueBehaviorSelectOptions = [
  { label: 'Manual', value: 'MANUAL' },
  { label: 'Automatic', value: 'AUTOMATIC' },
];

export default KeyIssueBehavior;
export { KeyIssueBehaviorSelectOptions };
