import React from 'react';
import { Provider } from 'react-redux';
import AppApplication from './administration/view/AppApplication';
import administrationStore from './administration/redux/administrationStore';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import './assets/sass/admin.scss';
import { PersistGate } from 'redux-persist/integration/react';
import AppConfig from './AppConfig';

const redux = administrationStore();

AppConfig.configure();

export default class App extends React.Component {
  render() {
    return (
      <Provider store={redux.store}>
        <PersistGate persistor={redux.persistor}>
          <CssBaseline />
          <BrowserRouter>
            <AppApplication />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}
