import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Booking', 'ACTIVITY_BOOKING');

function activityBookingReducer(state = initial, action) {
  switch (action.type) {
    case `ACTIVITY_BOOKING_FIND_SUCCESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        selectedItem: action.payload,
      };
    }
    case `ACTIVITY_BOOKING_FIND_IN_PROGRESS`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    }
    case `ACTIVITY_BOOKING_FIND_FAILURE`: {
      return {
        ...state,
        lastActionType: action.type,
        entityList: [],
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default activityBookingReducer;
