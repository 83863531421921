import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import Charts from '../Charts';
import ChartLegends from './ChartLegends';
import ChartTable from './ChartTable';

const LivertonPieChart = ({ data = [], legend = false, tableView = false, columnOverrides }) => {
  return (
    <>
      <ResponsiveContainer
        minWidth={Charts.getDefaultChartDimension()}
        minHeight={Charts.getDefaultChartDimension()}
        width="100%"
        height="100%"
      >
        <PieChart width="100%" height="100%">
          <Pie
            data={data}
            labelLine={false}
            label={({ percent, index }) => (percent ? `${(percent * 100).toFixed(0)}% (${data[index].value})` : '')}
            outerRadius={80}
            innerRadius={60}
            fill="#18949d"
            dataKey="value"
            nameKey="name"
            paddingAngle={5}
          >
            {Charts.getCategoryForChartWithType(data, 'x', 'pie')}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {legend && <ChartLegends data={data} />}

      <ChartTable visible={tableView} chartData={data} columnOverrides={columnOverrides} />
    </>
  );
};

export default LivertonPieChart;
