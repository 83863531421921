import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import { ScheduleType, ScheduleTypes } from '../../../../../support/ScheduleTypes';
import moment from 'moment/moment';
import { DaysOfWeekShort, EverydayShort } from '../../../../../support/DaysOfWeekShort';
import FoodPriceScheduleActions from '../../../../redux/actions/inRoom/ordering/priceSchedule/actions';
import Objects from '../../../../../support/Objects';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';
import MultiDatePicker from '../../../control/MultiDatePicker';
import FoodVendorActions from '../../../../redux/actions/inRoom/ordering/vendor/actions';
import FormField from '../../../../../components/form/FormField';

export default function FoodPriceScheduleEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedItem = useSelector((state) => state.foodPriceScheduleReducer.selectedItem);
  const error = useSelector((state) => state.foodPriceScheduleReducer.error);
  const lastActionType = useSelector((state) => state.foodPriceScheduleReducer.lastActionType);
  const processing = useSelector((state) => state.foodPriceScheduleReducer.processing);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const siteId = selectedSite?.id;
  const vendorOptions = useSelector((state) => state.foodVendorReducer.vendorOptions);
  const [scheduleName, setScheduleName] = useState(selectedItem?.scheduleName);
  const [type, setType] = useState(selectedItem?.schedule?.type || ScheduleType.DAYS_OF_WEEK);
  const [from, setFrom] = useState(selectedItem?.from || moment().format('HH:mm'));
  const [to, setTo] = useState(selectedItem?.to || moment().format('HH:mm'));
  const [value, setValue] = useState(selectedItem?.schedule?.value || EverydayShort);
  const [priceLevel, setPriceLevel] = useState(selectedItem?.priceLevel);
  const [scheduleOrder, setScheduleOrder] = useState(selectedItem?.scheduleOrder);
  const [vendors, setVendors] = useState(selectedItem?.vendors?.map((v) => v.toString()) || []);

  useEffect(() => {
    if (siteId) {
      dispatch(FoodVendorActions.fetchAll(siteId));
    }
  }, [dispatch, siteId]);

  useEffect(() => {
    dispatch(FoodPriceScheduleActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_PRICE_SCHEDULE_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_PRICE_SCHEDULE_UPDATE_SUCCESS' ||
      lastActionType === 'FOOD_PRICE_SCHEDULE_ADD_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, selectedItem]);

  const handleUpdate = useCallback((type) => {
    setType(type);
    setValue([]);
  }, []);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      scheduleName: scheduleName,
      schedule: {
        type: type,
        value: value,
      },
      from: from,
      to: to,
      priceLevel: priceLevel,
      scheduleOrder: scheduleOrder,
      vendors: vendors,
    };

    if (selectedItem) {
      dispatch(FoodPriceScheduleActions.update(payload, `/api/private/portal/food/price/schedule/${selectedItem.id}`, false));
    } else {
      dispatch(FoodPriceScheduleActions.add(payload, { siteId: siteId }));
    }
  };

  const isFormReady =
    !Objects.isNullOrEmpty(scheduleName) &&
    !Objects.isNullOrEmpty(from) &&
    !Objects.isNullOrEmpty(to) &&
    !Objects.isNullOrEmpty(type) &&
    !Objects.areValuesNullOrEmpty(value) &&
    !Objects.isNullOrEmpty(scheduleOrder) &&
    !Objects.isNullOrEmpty(priceLevel);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Price Override Schedule' : 'Create Price Override Schedule'}
        subheader={'A Schedule contains which time and days price overrides should apply'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() =>
          dispatch(FoodPriceScheduleActions.delete(selectedItem, `/api/private/portal/food/price/schedule/${selectedItem.id}`))
        }
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food price schedule - ${error.message}`}
          </Alert>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Name'} value={scheduleName} setValue={setScheduleName} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect label={'Schedule Type'} value={type} setValue={handleUpdate} options={ScheduleTypes} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="From"
                value={moment.utc(from, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => setFrom(moment.utc(time).format('HH:mm'))}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="To"
                value={moment.utc(to, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => setTo(moment.utc(time).format('HH:mm'))}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {type === ScheduleType.DAYS_OF_WEEK && (
                <FormMultiChipSelect label={'Schedule'} value={value} setValue={setValue} options={DaysOfWeekShort} displayValue={true} />
              )}
              {type === ScheduleType.DATES && <MultiDatePicker label={'Schedule'} selected={value} setValue={setValue} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Price Level'} value={priceLevel} setValue={setPriceLevel} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormField label={'Order'} value={scheduleOrder} setValue={setScheduleOrder} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormMultiChipSelect label={'Vendors'} value={vendors} setValue={setVendors} options={vendorOptions} />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
}
