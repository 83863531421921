import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';
import API from '@aws-amplify/api';

export const ActivityBookingActions = {
  ...actionCreator.createActions('Activity Booking', 'ACTIVITY_BOOKING', '/api/private/portal/activity/booking'),
  updateStatus: (item) => async (dispatch) => {
    dispatch({ type: `ACTIVITY_BOOKING_UPDATE_SUCCESS`, payload: item });
  },
  find: (id) => async (dispatch) => {
    Objects.notNull(id, 'id cannot be null');

    dispatch({ type: `ACTIVITY_BOOKING_FIND_IN_PROGRESS` });

    try {
      const result = await API.get('PrivateAPI', `/api/private/portal/activity/booking/${id}`, {});
      await dispatch({ type: `ACTIVITY_BOOKING_FIND_SUCCESS`, payload: result });
    } catch (error) {
      dispatch({ type: `ACTIVITY_BOOKING_FIND_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default ActivityBookingActions;
