import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';
import API from '@aws-amplify/api';

export const ActivityRateActions = {
  ...actionCreator.createActions('Activity Schedule Rate', 'ACTIVITY_SCHEDULE_RATE', '/api/private/portal/activity/schedule/time/rate'),
  add: (leaf, branch) => async (dispatch) => {
    Objects.notNull(leaf, 'Rate cannot be null');

    dispatch({ type: `ACTIVITY_SCHEDULE_RATE_ADD_IN_PROGRESS` });

    try {
      const result = await API.post('PrivateAPI', `/api/private/portal/activity/schedule/time/${branch.id}/rate`, { body: leaf });
      await dispatch({ type: `ACTIVITY_SCHEDULE_RATE_ADD_SUCCESS`, payload: result });
      await dispatch({ type: 'ACTIVITY_SCHEDULE_ADD_CHILD', payload: { branch, leaf: result } });
    } catch (error) {
      console.warn('add() call failed with result: ', error);
      dispatch({ type: `ACTIVITY_SCHEDULE_RATE_ADD_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default ActivityRateActions;
