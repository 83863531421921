import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../../redux/actions/amenity/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Box, Card, CardContent, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import { Icons, getIcons } from '../../../../../support/Icons';

export default function SiteAmenityEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.siteAmenitiesReducer.error);
  const lastActionType = useSelector((state) => state.siteAmenitiesReducer.lastActionType);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const selectedItem = useSelector((state) => state.siteAmenitiesReducer.selectedItem);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [code, setCode] = React.useState(selectedItem?.code || '');
  const [iconName, setIconName] = React.useState(selectedItem?.iconName || '');

  useEffect(() => {
    dispatch(Actions.clearError());
    return () => {
      dispatch(Actions.clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'SITE_AMENITY_UPDATE_SUCCESS' ||
      lastActionType === 'SITE_AMENITY_DELETE_SUCCESS' ||
      lastActionType === 'SITE_AMENITY_CREATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      code: code,
      iconName: iconName,
      siteId: selectedSiteId,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, selectedItem));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReady = name && code && iconName;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Amenity' : 'Create Amenity'}
        subheader={'Features can be assigned to room types and will be displayed during booking'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.remove(selectedItem))}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/delete amenity - ${error.message}`}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="name"
              type="text"
              label="Name"
              variant={'outlined'}
              onChange={(event) => setName(event.target.value)}
              value={name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="code"
              label="Code"
              type="text"
              variant={'outlined'}
              onChange={(event) => setCode(event.target.value)}
              value={code}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {iconName && <Box sx={{ p: 1 }}>{getIcons(iconName)}</Box>}
            <FormSelect
              label={'Icon Name'}
              value={iconName}
              setValue={setIconName}
              options={Icons.map((i) => ({ label: i.IconLabel, value: i.IconName }))}
              required
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
