import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import * as Actions from '../../../../redux/actions/feedback/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';
import FeedbackOptionIcons from '../../../../../support/FeedbackOptionIcons';
import {
  SentimentVerySatisfied,
  SentimentSatisfied,
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
} from '@material-ui/icons';
import Swal from 'sweetalert2';

export default function FeedbackOption() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.feedbackReducer.lastActionType);
  const entityList = useSelector((state) => state.feedbackReducer.entityList);
  const error = useSelector((state) => state.feedbackReducer.error);
  const selectedItem = useSelector((state) => state.feedbackReducer.selectedItem);

  const nextOrder = Math.max(...entityList.map((o) => o.order)) + 1;
  const [feedbackLabel, setFeedbackLabel] = React.useState(selectedItem?.feedbackLabel || '');
  const [feedbackScore, setFeedbackScore] = React.useState(selectedItem?.feedbackScore || 0);
  const [order, setOrder] = React.useState(selectedItem?.order || nextOrder);
  const [feedbackIcon, setFeedbackIcon] = React.useState(selectedItem?.feedbackIcon || 'sentiment_very_satisfied');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    const payload = {
      ...selectedItem,
      feedbackIcon,
      feedbackScore,
      feedbackLabel,
      order,
      siteId: selectedSite?.id,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, selectedItem));
    } else {
      dispatch(Actions.create(payload));
    }
  }, [dispatch, feedbackIcon, feedbackScore, feedbackLabel, order, selectedSite, selectedItem]);

  useEffect(() => {
    console.log('lastActionType', lastActionType);
    if (
      lastActionType === 'FEEDBACK_UPDATE_SUCCESS' ||
      lastActionType === 'FEEDBACK_DELETE_SUCCESS' ||
      lastActionType === 'FEEDBACK_ADD_SUCCESS'
    ) {
      console.log('lastActionType', lastActionType);
      history.goBack();
    }
    if (lastActionType === 'FEEDBACK_ADD_FAILURE' || lastActionType === 'FEEDBACK_UPDATE_FAILURE') {
      Swal.fire({
        title: 'Error!',
        text: 'There is an error in the form. Please check the fields or connection and try again.',
        icon: 'error',
        confirmButtonText: 'Retry',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit();
        }
        if (result.isDismissed) {
          dispatch(Actions.clearError());
        }
      });
    }
  }, [lastActionType, history, handleSubmit, dispatch]);

  const getDisplayIcon = (feedbackIcon) => {
    switch (feedbackIcon) {
      case 'sentiment_satisfied':
        return <SentimentSatisfiedAlt />;
      case 'sentiment_neutral':
        return <SentimentSatisfied />;
      case 'sentiment_very_dissatisfied':
        return <SentimentVeryDissatisfied />;
      case 'sentiment_dissatisfied':
        return <SentimentDissatisfied />;
      case 'sentiment_very_satisfied':
        return <SentimentVerySatisfied />;
      default:
        return <SentimentVerySatisfied />;
    }
  };

  const isFormReady = feedbackLabel && feedbackScore > 0 && feedbackScore <= 10 && feedbackIcon && order > 0;

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Feedback Option' : 'Create Feedback Option'}
        subheader={'Option to display to guest when completing a flow'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteOption(selectedItem.id))}
        deleteDisabled={!selectedItem}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit/delete feedback option - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={feedbackLabel} setValue={setFeedbackLabel} placeholder="Option Name" error={!feedbackLabel} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Score (1-10)'}
              value={feedbackScore}
              setValue={setFeedbackScore}
              type={'number'}
              inputProps={{ step: 1 }}
              placeholder="Score"
              error={!feedbackScore || feedbackScore < 1 || feedbackScore > 10}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Order (1-10)'}
              value={order}
              setValue={setOrder}
              placeholder="Order"
              type={'number'}
              error={!order || order < 1 || order > 10}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Feedback Icon'} value={feedbackIcon} setValue={setFeedbackIcon} options={FeedbackOptionIcons} />
            {feedbackIcon && getDisplayIcon(feedbackIcon)}
          </Grid>
        </Grid>
      </CardContent>
      <style jsx>{`
        .swal2-container {
          z-index: 9999;
        }
      `}</style>
    </Card>
  );
}
