import reducerCreator from '../../support/reducerCreator';
import { SITE_DUPLICATE_IN_PROGRESS, SITE_DUPLICATE_SUCCESS, SITE_DUPLICATE_FAILURE } from '../../actions/site/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Site', 'SITE');

function siteReducer(state = initial, action) {
  switch (action.type) {
    case SITE_DUPLICATE_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case SITE_DUPLICATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList, action.payload],
        selectedItem: action.payload,
      };
    case SITE_DUPLICATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default siteReducer;
