import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';
import API from '@aws-amplify/api';

const NotificationSchemeActions = {
  ...actionCreator.createActions('Notification Schemes', 'NOTIFICATION_SCHEME', '/api/scheme/notification'),
  duplicate: (scheme) => async (dispatch) => {
    Objects.notNull(scheme, 'item cannot be null');

    dispatch({ type: `NOTIFICATION_SCHEME_ADD_IN_PROGRESS` });
    try {
      const response = await API.post('PrivateAPI', `/api/scheme/notification/duplicate/${scheme.id}`, {});
      dispatch({ type: `NOTIFICATION_SCHEME_ADD_SUCCESS`, payload: response });
    } catch (error) {
      dispatch({ type: `NOTIFICATION_SCHEME_ADD_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
};

export default NotificationSchemeActions;
