module.exports = {
  EQUALS: {
    code: 1,
    displayName: 'Equals',
  },
  NOT_EQUALS: {
    code: 2,
    displayName: 'Not Equals',
  },
  LIST_CONTAINS: {
    code: 3,
    displayName: 'List Contains',
  },
  LIST_NOT_CONTAINS: {
    code: 4,
    displayName: 'List Does Not Contain',
  },
  GREATER_THAN: {
    code: 5,
    displayName: 'Greater Than',
  },
  LESS_THAN: {
    code: 6,
    displayName: 'Less Than',
  },
  GREATER_THAN_EQUAL: {
    code: 7,
    displayName: 'Greater Than OR Equal',
  },
  LESS_THAN_EQUAL: {
    code: 8,
    displayName: 'Less Than OR Equal',
  },
  WORD_CONTAINS: {
    code: 9,
    displayName: 'Partial Word Match - Word Contains',
  },
};
