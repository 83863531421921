import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Box, Grid, Hidden, IconButton, List, ListItem, ListItemText, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatNotSignedIn from './ChatNotSignedIn';
import { ArrowBack } from '@material-ui/icons';
import ChatThread from './ChatThread';
import ChatBubble from './ChatBubble';
import ChatInput from './ChatInput';

export default function Chat() {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const messagesEndRef = useRef(null);
  const selectedTheme = useSelector((state) => state.accessReducer.theme);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const chatThreadList = useSelector((state) => state.chatReducer.data);
  const selectedChat = useSelector((state) => state.chatReducer.selectedItem);
  const signedInFirebase = useSelector((state) => state.chatReducer.signedInFirebase);

  React.useEffect(() => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedChat]);

  if (signedInFirebase) {
    const selectedChatName = selectedChat
      ? `${selectedChat.recipient_first_name || ''}, ${selectedChat.recipient_last_name || ''}`
      : 'Unknown';
    const selectedChatMessageList = selectedChat ? selectedChat.messages : [];
    return (
      <Paper className={classes.root}>
        <AppBar position={'relative'} color={selectedTheme === 'dark' ? 'dark' : 'primary'} className={classes.appBar}>
          <Toolbar className={classes.toolbarFlexContainer}>
            <div>
              <Hidden mdUp>
                <div hidden={!mobileOpen}>
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMobileOpen(!mobileOpen)}>
                    <ArrowBack />
                  </IconButton>
                </div>
              </Hidden>

              <Hidden mdUp>
                <Typography variant="h6" className={'pt-1'} hidden={mobileOpen}>
                  Guest Messaging
                </Typography>
                <Typography variant="subtitle1" hidden={mobileOpen}>
                  {selectedSite.name}
                </Typography>
              </Hidden>

              <Hidden smDown>
                <Box pt={1} pb={1}>
                  <Typography variant="h6">Guest Messaging</Typography>
                  <Typography variant="subtitle1">{selectedSite.name}</Typography>
                </Box>
              </Hidden>
            </div>
            {selectedChat ? (
              <div className={classes.selectedChatTextOverflow} hidden={!mobileOpen}>
                <Typography variant="h6" className={'pt-1'}>
                  {selectedChatName}
                </Typography>
                <Typography variant="body1">{selectedChat.recipient_email}</Typography>
              </div>
            ) : null}
          </Toolbar>
        </AppBar>
        {/* Desktop View */}
        <Hidden smDown>
          <Grid container spacing={0} className={classes.gridContainerMargin}>
            <Grid item xs={4} className={classes.recipientsContainer}>
              <List>
                {chatThreadList.map((data, index) => (
                  <ChatThread selectedChat={selectedChat} data={data} index={index} classes={classes} />
                ))}
                {chatThreadList.length <= 0 && (
                  <ListItem className={classes.MuiListItemTextPrimary}>
                    <ListItemText
                      className={classes.listItemTextOverflow}
                      primary={'No conversations available'}
                      secondary={'No guests have initiated conversations at this time. Please check later.'}
                      classes={{
                        secondary: classes.MuiListItemTextSecondary, // class name, e.g. `classes-nesting-root-x`
                      }}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={8} className={classes.outerMessageFlexContainer}>
              <div className={classes.messageFlexContainer}>
                {selectedChatMessageList.map((message, idx) => {
                  return <ChatBubble classes={classes} idx={idx} message={message} selectedChat={selectedChat} />;
                })}
                <div ref={messagesEndRef} />
              </div>
              <ChatInput selectedChat={selectedChat} guestMessageList={selectedChatMessageList} />
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile View */}
        <Hidden mdUp>
          <Grid container spacing={2} className={classes.gridContainerMargin}>
            <Grid item xs={12} hidden={mobileOpen} className={classes.mobileScrollableContainer}>
              <List>
                {chatThreadList.map((data, index) => (
                  <ChatThread selectedChat={selectedChat} data={data} index={index} classes={classes} />
                ))}
                {chatThreadList.length <= 0 && (
                  <ListItem className={classes.MuiListItemTextPrimary}>
                    <ListItemText
                      className={classes.listItemTextOverflow}
                      primary={'No conversations available'}
                      secondary={'No guests have initiated conversations at this time. Please check later.'}
                      classes={{
                        secondary: classes.MuiListItemTextSecondary, // class name, e.g. `classes-nesting-root-x`
                      }}
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12} hidden={!mobileOpen} className={classes.mobileScrollableContainer} style={{ paddingLeft: '1.0rem' }}>
              <Grid item xs={8} className={classes.outerMessageFlexContainer}>
                <div className={classes.messageFlexContainer}>
                  {selectedChatMessageList.map((message, idx) => {
                    return <ChatBubble classes={classes} idx={idx} message={message} selectedChat={selectedChat} />;
                  })}
                  <div ref={messagesEndRef} />
                </div>
                <ChatInput selectedChat={selectedChat} guestMessageList={selectedChatMessageList} />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Paper>
    );
  } else {
    return <ChatNotSignedIn />;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 0,
  },
  gridContainerMargin: {
    marginTop: '0.1rem',
  },
  recipientsContainer: {
    overflowY: 'auto',
    height: '75vh',
    backgroundColor: theme.palette.grays[10],
  },
  mobileScrollableContainer: {
    overflowY: 'auto',
    height: '80vh',
  },
  outerMessageFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    // overflowY: 'auto',
    height: '75vh',
    justifyContent: 'space-between',
    paddingLeft: '0.5rem',
  },
  messageFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
  },
  appUserMessageFlexItem: {
    alignSelf: 'flex-start',
    maxWidth: '50%',
    overflowWrap: 'break-word',
    marginBottom: '0.25rem',
  },
  portalUserMessageFlexItem: {
    alignSelf: 'flex-end',
    maxWidth: '50%',
    overflowWrap: 'break-word',
    marginBottom: '0.25rem',
  },
  appUserMessageBorder: {
    border: `2px solid ${theme.palette.grays[10]}`,
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.grays[10],
    padding: '0.25rem',
    color: 'black',
  },
  portalUserMessageBorder: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.primary.main,
    padding: '0.25rem',
    color: theme.palette.primary.contrastText,
  },
  dateTextStyle: {
    fontSize: '0.8rem',
    // color: 'rgba(0, 0, 0, 0.5)'
  },
  listItemTextOverflow: {
    overflowWrap: 'break-word',
  },
  MuiListItemTextPrimary: {
    color: 'black',
  },
  MuiListItemTextSecondary: {
    color: theme.palette.grays[1],
  },
  guestName: {
    textTransform: 'capitalize',
  },
  messageDateFlexItem: {
    alignSelf: 'center',
    maxWidth: '70%',
    overflowWrap: 'break-word',
  },
  messageDateText: {
    color: theme.palette.grays[6],
  },
  unreadMessagesBadgeMargin: {
    marginRight: '1rem',
  },
  deleteIconStyle: {
    marginLeft: '5px',
    color: 'black',
  },
  toolbarFlexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedChatTextOverflow: {
    overflowWrap: 'break-word',
    maxWidth: '30vw',
  },
}));
