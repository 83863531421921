const RssiSignalStrength = {
  EXCELLENT: 'EXCELLENT(>-70)',
  GOOD: 'GOOD(>-85)',
  FAIR: 'FAIR(>-100)',
};

const RssiSignalStrengthOptions = [
  { label: RssiSignalStrength.EXCELLENT, value: -70 },
  { label: RssiSignalStrength.GOOD, value: -85 },
  { label: RssiSignalStrength.FAIR, value: -100 },
];
export default RssiSignalStrength;
export { RssiSignalStrengthOptions };
