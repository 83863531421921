const SpecialRequestContactGroup = {
  DEFAULT: 'DEFAULT',
  ESCALATION: 'ESCALATION',
};

const SpecialRequestContactGroupOptions = [
  { value: SpecialRequestContactGroup.DEFAULT, label: 'Default' },
  { value: SpecialRequestContactGroup.ESCALATION, label: 'Escalation' },
];

export default SpecialRequestContactGroup;
export { SpecialRequestContactGroupOptions };
