import React, { useEffect } from 'react';
import * as Actions from '../../../../redux/actions/sitePaymentType/actions';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BooleanTableColumn from '../../../../../components/table/BooleanTableColumn';
import { DeleteOutlined } from '@material-ui/icons';

export default function PaymentTypeList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const defaultPaymentTypes = useSelector((state) => state.sitePaymentTypeReducer.defaultPaymentTypes) || [];
  const sitePaymentTypes = useSelector((state) => state.sitePaymentTypeReducer.sitePaymentTypes) || [];
  const [paymentType, setPaymentType] = React.useState(null);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);

  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.fetchAll(selectedSiteId));
    }
  }, [selectedSiteId, dispatch]);

  const merged = defaultPaymentTypes.map((pt) => {
    const siteOverride = sitePaymentTypes.find((spt) => spt.mappedType === pt.mappedType);
    if (siteOverride) {
      return siteOverride;
    } else {
      return pt;
    }
  });

  function openModalAndSetSelected(pt) {
    setPaymentType(pt);
    setPaymentModalOpen(true);
  }

  function closeModalAndClear() {
    setPaymentType(null);
    setPaymentModalOpen(false);
  }

  return (
    <Card>
      <CardHeader title={'Site Payment Type Mapping'} subheader={'Payment type mapping for property management systems'} />

      <PaymentTypeEditDialog open={paymentModalOpen} setClose={closeModalAndClear} paymentType={paymentType} siteId={selectedSiteId} />

      <CardContent>
        {merged.length <= 0 && <Alert color={'info'}>No systems available - add to get started</Alert>}

        {merged.length > 0 && (
          <TableContainer>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Online Type (Settlement Type)</TableCell>
                  <TableCell>Offline Type (Transaction Code)</TableCell>
                  <TableCell align={'center'}>Overridden</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merged.map((pt) => (
                  <TableRow key={`payment-type-${pt.id}`}>
                    <TableCell>
                      <IconButton onClick={() => dispatch(Actions.deletePaymentType(pt))} disabled={!pt.siteId} size={'small'}>
                        <DeleteOutlined />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button variant={'text'} onClick={() => openModalAndSetSelected(pt)} color={'primary'}>
                        {pt.mappedName}
                      </Button>
                    </TableCell>
                    <TableCell>{pt.onlineCode}</TableCell>
                    <TableCell>{pt.offlineCode}</TableCell>

                    <BooleanTableColumn align={'center'} value={pt.siteId !== null} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}

function PaymentTypeEditDialog({ open, setClose, paymentType, siteId }) {
  const dispatch = useDispatch();
  const [onlineCode, setOnlineCode] = React.useState(paymentType ? paymentType.onlineCode : '');
  const [offlineCode, setOfflineCode] = React.useState(paymentType ? paymentType.onlineCode : '');

  React.useEffect(() => {
    if (paymentType) {
      setOnlineCode(paymentType.onlineCode);
      setOfflineCode(paymentType.offlineCode);
    } else {
      setOnlineCode('');
      setOfflineCode('');
    }
  }, [paymentType]);

  function overrideAndClose() {
    if (paymentType.siteId) {
      dispatch(
        Actions.updatePaymentType({
          ...paymentType,
          onlineCode: onlineCode,
          offlineCode: offlineCode,
        })
      );
    } else {
      dispatch(
        Actions.createPaymentType({
          mappedName: paymentType.mappedName,
          mappedType: paymentType.mappedType,
          onlineCode: onlineCode,
          offlineCode: offlineCode,
          siteId: siteId,
        })
      );
    }

    setClose(null);
  }

  function handleClose() {
    setClose(null);
  }

  if (!paymentType) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Payment Type</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField fullWidth type="text" disabled={true} variant={'outlined'} value={paymentType.mappedName} label="Payment Name" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth type="text" disabled={true} variant={'outlined'} value={paymentType.mappedType} label="Payment Type" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              variant={'outlined'}
              onChange={(event) => setOnlineCode(event.target.value)}
              value={onlineCode}
              label="Online Code"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant={'outlined'}
              type="text"
              onChange={(event) => setOfflineCode(event.target.value)}
              value={offlineCode}
              label="Offline Code"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={overrideAndClose}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
