import reducerCreator from '../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('AppUserCardToken', 'APP_USER_CARD_TOKEN');

function appUserCardTokenReducer(state = initial, action) {
  switch (action.type) {
    default:
      return base(state, action);
  }
}

export default appUserCardTokenReducer;
