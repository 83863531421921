/*
 *   Customer specific customisations to the app.
 */
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';

// Build up origin if not found
if (!window.location.origin) {
  window.location.origin =
    window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

const FIREBASE_CONFIGURATION = {
  apiKey: 'AIzaSyAJXpKyP4bPrcrddJMu2njax5L7g_UI2R4',
  authDomain: 'smartcheckmobile.firebaseapp.com',
  databaseURL: 'https://smartcheckmobile.firebaseio.com',
  projectId: 'smartcheckmobile',
  storageBucket: 'smartcheckmobile.appspot.com',
  messagingSenderId: '683651351778',
  appId: '1:683651351778:web:b75a913065d9efde488abb',
  measurementId: 'G-LXCEHF3PK0',
};

/**
 * Environment vars
 */
const ENVIRONMENT = process.env.NODE_ENV || 'development';
const API_URL = process.env.REACT_APP_API_URL || window.location.origin;
const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION || 'ap-southeast-2';
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const COGNITO_CLIENT_SECRET = process.env.REACT_APP_COGNITO_CLIENT_SECRET;

/**
 * ON_PREMISE: Whether this is a pointing to AWS or a local server
 * DEFAULT_THEME: Theme name (not currently used)
 */
const AppConfig = {
  APP_NAME: 'SmartCheck Mobile',
  DEFAULT_DATE_FORMAT: 'DD MMM YY',
  DEFAULT_DATE_TIME_FORMAT: 'DD MMM YY HH:mm:ss',
  DEFAULT_DATE_TIME_FORMAT_MOBILE: 'DD-MM-YY',
  DEFAULT_TIME_FORMAT: 'HH:mm:ss',
  ASSETS_CDN: 'https://assets.smartcheckmobile.com',
  ENVIRONMENT: ENVIRONMENT,
  ENDPOINT: API_URL,
  FIREBASE_CONFIGURATION,
  configure: () => {
    console.log('Starting App');
    console.log('Environment: ', ENVIRONMENT);
    console.log('Endpoint: ' + API_URL);
    console.log('Client Region: ' + COGNITO_REGION);
    console.log('Client ID: ' + COGNITO_CLIENT_ID);

    if (ENVIRONMENT !== 'test') {
      if (!COGNITO_CLIENT_ID || !COGNITO_CLIENT_SECRET) {
        throw new Error('Missing environment variables - check read me setup');
      }
    }

    /**
     * Configure Amplify
     */
    // Logger level
    Amplify.Logger.LOG_LEVEL = 'INFO';

    Amplify.configure({
      Auth: {
        region: COGNITO_REGION,
        userPoolId: COGNITO_CLIENT_ID,
        userPoolWebClientId: COGNITO_CLIENT_SECRET,
      },
      API: {
        endpoints: [
          {
            name: 'PublicAPI',
            endpoint: window.location.origin,
          },
          {
            name: 'PrivateAPI',
            endpoint: API_URL,
            custom_header: async () => {
              let token = null;
              try {
                token = (await Auth.currentSession()).accessToken.jwtToken;
              } catch (error) {
                console.error('Unable to add authorization header: ', error);
              }
              return {
                Authorization: token,
              };
            },
          },
        ],
      },
    });
  },
};

export default AppConfig;
