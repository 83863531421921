import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltipStyle: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.9,
    padding: '8px',
    borderRadius: '3px',
  },
}));

export function CustomTooltip(props) {
  const { payload, label, generateTotal } = props;
  const classes = useStyles();
  let total = 0;

  return (
    <div className={`custom-tooltip ${classes.tooltipStyle}`}>
      <div className="custom-tooltip-label">{label}</div>
      {payload &&
        payload.map((payload) => {
          if (generateTotal) {
            total += payload.value;
          }

          const key = payload.name;
          const value = payload.value;
          return (
            <div key={key} className="custom-tooltip-item" style={{ color: payload.fill }}>
              {`${key} : ${value}`}
            </div>
          );
        })}

      {generateTotal && <div className="custom-tooltip-total">{`Total : ${total}`}</div>}
    </div>
  );
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  label: PropTypes.any,
  generateTotal: PropTypes.bool,
};
