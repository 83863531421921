import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/platform/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'Platform Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'baseUrl',
    label: 'API Base URL',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'bucketName',
    label: 'Bucket Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function PlatformList() {
  const dispatch = useDispatch();
  const platformList = useSelector((state) => state.platformReducer.entityList);
  const lastActionType = useSelector((state) => state.platformReducer.lastActionType);
  const processing = useSelector((state) => state.platformReducer.processing);
  const page = useSelector((state) => state.platformReducer.page);
  const error = useSelector((state) => state.platformReducer.error);

  React.useEffect(() => {
    dispatch(Actions.select(null));
  }, [dispatch]);

  return (
    <AutoTable
      title="Platform List"
      subheader={'Cloud platform and account details for different environments'}
      handleRefreshReport={() => dispatch(Actions.fetch(page.number, page.size))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, null))}
      page={page}
      processing={processing}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="PLATFORM"
      newButtonName="New Platform"
      createNewURL="/platforms/new"
      detailURL="/platforms/view/{id}"
      idColumn={'id'}
      data={platformList}
      lastActionType={lastActionType}
    />
  );
}
