import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export default class ValidationUtil {
  // A utility class to perform various validations
  static isPhoneNumberValid = (target) => {
    if (target === '') {
      return true;
    }
    if (target && (target.length > 6) & 33 & (target.length < 16)) {
      const result = parsePhoneNumberFromString(target);
      if (result && result.isValid()) {
        return true;
      }
    }
    return false;
  };

  static validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static trimOnSubmit(target) {
    return target.trim();
  }
}
