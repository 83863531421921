import React, { useEffect } from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/theme/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined, FileCopyOutlined } from '@material-ui/icons';

// Table column rendering information.
const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(Actions.delete(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'name',
    label: 'Theme Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'primary',
    label: 'Primary Colour',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_COLOR,
  },
  {
    id: 'accent',
    label: 'Accent Colour',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_COLOR,
  },
  {
    id: 'fontColour',
    label: 'Font Colour',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_COLOR,
  },
];

function ThemeList() {
  const dispatch = useDispatch();
  const selectedCorp = useSelector((state) => state.accessReducer.selectedCorporation);
  const siteThemeList = useSelector((state) => state.themeReducer.entityList);
  const processing = useSelector((state) => state.themeReducer.processing);
  const lastActionType = useSelector((state) => state.themeReducer.lastActionType);
  const page = useSelector((state) => state.themeReducer.page);
  const error = useSelector((state) => state.themeReducer.error);

  const handleRefreshReport = (selectedCorp) => {
    if (selectedCorp) {
      dispatch(Actions.fetch(0, 10, { corporationId: selectedCorp.id }));
    }
  };

  useEffect(() => {
    if (selectedCorp) {
      dispatch(Actions.fetch(0, 10, { corporationId: selectedCorp.id }));
    }
  }, [dispatch, selectedCorp]);

  const selectItem = (item) => {
    dispatch(Actions.select(item));
  };

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(Actions.fetch(newPage, pageSize, { corporationId: selectedCorp.id }));
  };

  return (
    <AutoTable
      title="Site Theme"
      subheader={'A colour scheme for a site which is applied to the app, emails and web check-in'}
      handleRefreshReport={() => handleRefreshReport(selectedCorp)}
      selectItem={(item) => selectItem(item)}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="SITE_THEME"
      newButtonName="New Site Theme"
      createNewURL="/themes/new"
      detailURL="/themes/view/{id}"
      idColumn={'id'}
      data={siteThemeList}
      lastActionType={lastActionType}
    />
  );
}

export default ThemeList;
