const DaysOfWeekLong = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

const DaysShort = {
  MONDAY: 'MO',
  TUESDAY: 'TU',
  WEDNESDAY: 'WE',
  THURSDAY: 'TH',
  FRIDAY: 'FR',
  SATURDAY: 'SA',
  SUNDAY: 'SU',
};

const DaysMedium = {
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun',
};

const DaysOfWeekShort = [
  { label: 'Monday', value: DaysShort.MONDAY },
  { label: 'Tuesday', value: DaysShort.TUESDAY },
  { label: 'Wednesday', value: DaysShort.WEDNESDAY },
  { label: 'Thursday', value: DaysShort.THURSDAY },
  { label: 'Friday', value: DaysShort.FRIDAY },
  { label: 'Saturday', value: DaysShort.SATURDAY },
  { label: 'Sunday', value: DaysShort.SUNDAY },
];

const DaysOfWeekMedium = [
  { label: 'Monday', value: DaysMedium.MONDAY },
  { label: 'Tuesday', value: DaysMedium.TUESDAY },
  { label: 'Wednesday', value: DaysMedium.WEDNESDAY },
  { label: 'Thursday', value: DaysMedium.THURSDAY },
  { label: 'Friday', value: DaysMedium.FRIDAY },
  { label: 'Saturday', value: DaysMedium.SATURDAY },
  { label: 'Sunday', value: DaysMedium.SUNDAY },
];

const EverydayShort = [
  DaysShort.MONDAY,
  DaysShort.TUESDAY,
  DaysShort.WEDNESDAY,
  DaysShort.THURSDAY,
  DaysShort.FRIDAY,
  DaysShort.SATURDAY,
  DaysShort.SUNDAY,
];

const EverydayMedium = [
  DaysMedium.MONDAY,
  DaysMedium.TUESDAY,
  DaysMedium.WEDNESDAY,
  DaysMedium.THURSDAY,
  DaysMedium.FRIDAY,
  DaysMedium.SATURDAY,
  DaysMedium.SUNDAY,
];

export { DaysOfWeekShort, EverydayShort, EverydayMedium, DaysOfWeekMedium, DaysOfWeekLong };
