import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';
import NotificationActions from '../../../../redux/actions/site/notification/actions';

export default function NotificationExportDialog({ open, setClose }) {
  const dispatch = useDispatch();
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const siteListInSelectedCorporation = siteList.filter((site) => site.corporationId === selectedCorporation.id);

  const siteListAsOptions = siteList.map((site) => {
    return { label: site.name, value: site.id };
  });
  const [sites, setSites] = React.useState(siteListInSelectedCorporation.map((site) => site.id));

  function handleClose() {
    setClose(null);
  }

  function exportNotification() {
    dispatch(NotificationActions.export(sites));
    setClose(null);
  }

  const formReady = sites.length > 0;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Export Notification Schedule List</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormMultiChipSelect label={'Sites'} value={sites} setValue={setSites} options={siteListAsOptions} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" disabled={!formReady} onClick={exportNotification}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
