const PlatformType = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
};

const PlatformTypeOptions = [
  { label: 'IOS', value: PlatformType.IOS },
  { label: 'Android', value: PlatformType.ANDROID },
];

export default PlatformType;
export { PlatformTypeOptions };
