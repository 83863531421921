import { TableCell } from '@material-ui/core';
import React from 'react';
import { useTheme } from '@material-ui/core';
import { CheckOutlined, ClearOutlined } from '@material-ui/icons';

export default function BooleanTableColumn({ align = 'center', value, trueIcon = <CheckOutlined />, falseIcon = <ClearOutlined /> }) {
  const theme = useTheme();
  return (
    <TableCell align={align}>
      {value ? (
        <div style={{ color: theme.palette.primary.main }}>{trueIcon}</div>
      ) : (
        <div style={{ color: theme.palette.error.main }}>{falseIcon}</div>
      )}
    </TableCell>
  );
}
