import * as Actions from '../../../actions/compendium/category/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  error: null,
  selectedItem: null,
  processing: false,
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
};

export default function compendiumCategoryReducer(state = initial, action) {
  switch (action.type) {
    case Actions.CATEGORY_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: false,
      };
    case Actions.CATEGORY_PROCESSING:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        processing: true,
      };
    case Actions.CATEGORY_SELECT:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload.content,
        processing: false,
        error: null,
        page: {
          size: action.payload.size,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
        },
      };
    case Actions.CATEGORY_ADD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: [...state.data, action.payload],
        processing: false,
      };

    case Actions.CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((e) => e.id !== action.payload.id),
        error: null,
        processing: false,
      };
    case Actions.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.map((e) => (e.id === action.payload.id ? action.payload : e)),
        processing: false,
      };
    case Actions.CATEGORY_DELETE_FAILURE:
    case Actions.CATEGORY_FETCH_FAILURE:
    case Actions.CATEGORY_ADD_FAILURE:
    case Actions.CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
}
