import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodChargeActions from '../../../../redux/actions/inRoom/ordering/foodCharge/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import { PortionType, PortionTypes } from '../../../../../support/PortionTypes';
import { FoodChargeCategories, FoodChargeCategory } from '../../../../../support/FoodChargeCategory';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FoodItemActions from '../../../../redux/actions/inRoom/ordering/foodItem/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ChargeRuleOptions } from '../../../../../support/ChargeRules';

export default function FoodChargeEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodChargeReducer);
  const [chargeName, setChargeName] = useState(selectedItem?.chargeName || '');
  const [chargeCode, setChargeCode] = useState(selectedItem?.chargeCode || '');
  const [foodItemId, setFoodItemId] = useState(selectedItem?.foodItemId || null);
  const [chargeAmount, setChargeAmount] = useState(selectedItem?.chargeAmount);
  const [chargeType, setChargeType] = useState(selectedItem?.chargeType || PortionType.FIXED);
  const [chargeCategory, setChargeCategory] = useState(selectedItem?.chargeCategory || FoodChargeCategory.EXTRA);
  const [enabled, setEnabled] = useState(!!selectedItem?.enabled);
  const [sequence, setSequence] = useState(selectedItem?.sequence || 1);
  const [searchResults, setSearchResults] = useState([]);
  const [linkedFoodItem, setLinkedFoodItem] = useState(null);
  const [chargeRule, setChargeRule] = useState(selectedItem?.chargeRule || null);

  useEffect(() => {
    const fetch = async () => {
      if (foodItemId) {
        const foodItemOption = await dispatch(FoodItemActions.findById(foodItemId));
        setLinkedFoodItem({ value: foodItemOption?.id, label: foodItemOption?.name, price: foodItemOption?.price });
      }
    };
    fetch();
  }, [dispatch, foodItemId]);

  useEffect(() => {
    dispatch(FoodChargeActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_CHARGE_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_CHARGE_ADD_SUCCESS' ||
      lastActionType === 'FOOD_CHARGE_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      chargeName,
      chargeCode,
      foodItemId,
      chargeAmount,
      chargeType,
      chargeCategory,
      enabled,
      sequence,
      chargeRule: chargeRule || null,
    };

    if (selectedItem) {
      dispatch(FoodChargeActions.update(payload, `/api/private/portal/food/charge/${selectedItem.id}`, false));
    } else {
      dispatch(FoodChargeActions.add(payload, { vendorId: vendorId }));
    }
  };

  const handleSearch = async (event, criteria) => {
    if (criteria) {
      const result = await dispatch(FoodItemActions.search(vendorId, criteria));
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const isFormReady = () => {
    if (chargeCategory === FoodChargeCategory.STATIC_ITEM) {
      return chargeName && chargeCode && foodItemId;
    }
    return chargeName && chargeCode && chargeAmount && chargeType && chargeCategory;
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Charge' : 'Create Food Charge'}
        subheader={'A Food Charge contains fixed or percentage amount to be included in a check'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady() || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() => dispatch(FoodChargeActions.delete(selectedItem, `/api/private/portal/food/charge/${selectedItem.id}`))}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food charge - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Name'} value={chargeName} setValue={setChargeName} required />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormField label={'Code'} value={chargeCode} setValue={setChargeCode} required />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormField label={'External ID'} value={selectedItem?.externalId || ''} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Category'} value={chargeCategory} setValue={setChargeCategory} options={FoodChargeCategories} required />
          </Grid>
          {chargeCategory === FoodChargeCategory.STATIC_ITEM && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Autocomplete
                fullWidth
                freeSolo
                options={searchResults}
                getOptionSelected={(option, selected) => option?.value === selected?.value}
                getOptionLabel={(option) => option?.label || ''}
                onInputChange={handleSearch}
                onChange={(event, value) => setFoodItemId(value?.value)}
                value={linkedFoodItem}
                renderInput={(params) => (
                  <TextField
                    label={'Food Item'}
                    margin={'dense'}
                    variant="outlined"
                    InputProps={{
                      type: 'search',
                    }}
                    {...params}
                  />
                )}
              />
            </Grid>
          )}
          {(chargeCategory !== FoodChargeCategory.STATIC_ITEM || linkedFoodItem?.price === null) && (
            <>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormSelect label={'Type'} value={chargeType} setValue={setChargeType} options={PortionTypes} required />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormField
                  label={'Amount'}
                  value={chargeAmount}
                  setValue={setChargeAmount}
                  type={'number'}
                  inputProps={{ step: '1' }}
                  reactInputProps={{
                    endAdornment: chargeType === PortionType.RATE && <InputAdornment position="end">%</InputAdornment>,
                    startAdornment: chargeType === PortionType.FIXED && <InputAdornment position="start">$</InputAdornment>,
                  }}
                  required
                />
              </Grid>
            </>
          )}
          {chargeCategory === FoodChargeCategory.STATIC_ITEM && linkedFoodItem?.price !== null && (
            <>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormField label={'Type'} value={chargeType} disabled />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormField
                  label={'Item Price'}
                  value={linkedFoodItem?.price}
                  reactInputProps={{
                    startAdornment: chargeType === PortionType.FIXED && (
                      <InputAdornment position="start">
                        <Typography disabled>$</Typography>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Charge Rule'} value={chargeRule} setValue={setChargeRule} options={ChargeRuleOptions} hasClear />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sequence'} value={sequence} setValue={setSequence} type={'number'} inputProps={{ step: '1' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
