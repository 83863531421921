import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import * as AccessActions from '../../../../redux/actions/access/actions';
import Actions from '../../../../redux/actions/reservation/hotel/actions';
import HotelReservationEdit from '../hotel/HotelReservationEdit';

export default function ReservationReportEdit() {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const selectedItem = useSelector((state) => state.hotelReservationReducer.selectedItem);

  const queryParameters = new URLSearchParams(window.location.search);
  const resNumber = queryParameters.get('resNumber');
  const emailSiteId = queryParameters.get('emailSiteId');
  const emailOrgId = queryParameters.get('emailOrgId');
  const { id } = useParams();

  useEffect(() => {
    let orgId = parseInt(emailOrgId);
    let emailSite = parseInt(emailSiteId);
    if (orgId && emailSite) {
      dispatch(AccessActions.selectCorpAndSite(orgId, emailSite));
    }
  }, [dispatch, emailOrgId, emailSiteId]);

  useEffect(() => {
    dispatch(Actions.unselectReservation());
    dispatch(Actions.fetchById(emailSiteId ? resNumber : id, { siteId: emailSiteId ? emailSiteId : selectedSiteId }));
  }, [dispatch, emailSiteId, id, resNumber, selectedSiteId]);

  if (!selectedItem) {
    return null;
  }

  return <HotelReservationEdit />;
}
