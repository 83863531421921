import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import FoodOrderLocationActions from '../../../../redux/actions/inRoom/ordering/orderLocation/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormField from '../../../../../components/form/FormField';
import FormSelect from '../../../../../components/form/FormSelect';
import { FoodOrderLocationType, FoodOrderLocationTypes } from '../../../../../support/FoodOrderLocationType';
import { FoodOrderCheckModes } from '../../../../../support/FoodOrderCheckMode';
import { AmenitiesNavigationOptions } from '../../../../../support/AmenitiesNavigation';

export default function FoodOrderLocationEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { action } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const global = searchParams.get('global') === 'true';

  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodOrderLocationReducer);
  const [locationCode, setLocationCode] = useState(selectedItem?.locationCode || '');
  const [locationName, setLocationName] = useState(selectedItem?.locationName || '');
  const [locationType, setLocationType] = useState(selectedItem?.locationType || (global ? '' : FoodOrderLocationType.TABLE));
  const [checkMode, setCheckMode] = useState(selectedItem?.checkMode || 'CLOSED');
  const [navigationCode, setNavigationCode] = useState(selectedItem?.navigationCode || '');
  const disabled = useMemo(
    () => action === 'view' && (!selectedItem?.id || (global && selectedItem.foodVendorId) || (!global && !selectedItem.foodVendorId)),
    [global, selectedItem, action]
  );

  const locationOptions = useMemo(
    () =>
      global
        ? action === 'view'
          ? FoodOrderLocationTypes
          : FoodOrderLocationTypes.filter((fol) => fol.value !== FoodOrderLocationType.TABLE)
        : FoodOrderLocationTypes.filter((fol) => fol.value === FoodOrderLocationType.TABLE),
    [global, action]
  );
  useEffect(() => {
    dispatch(FoodOrderLocationActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_ORDER_LOCATION_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_ORDER_LOCATION_UPDATE_SUCCESS' ||
      lastActionType === 'FOOD_ORDER_LOCATION_ADD_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId, global]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      locationType: locationType,
      locationCode: locationCode,
      locationName: locationName,
      checkMode: checkMode,
      navigationCode: navigationCode || null,
    };

    if (global) {
      if (selectedItem?.id) {
        dispatch(FoodOrderLocationActions.update(payload, `/api/private/portal/food/order/location/${selectedItem.id}`, false));
      } else {
        dispatch(FoodOrderLocationActions.add(payload, { siteId: selectedSiteId }));
      }
    } else {
      if (selectedItem?.id) {
        dispatch(FoodOrderLocationActions.updateByVendor(payload, selectedItem.id));
      } else {
        dispatch(FoodOrderLocationActions.addByVendor(payload, vendorId));
      }
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem?.id ? 'Update Food Order Location' : 'Create Food Order Location'}
        subheader={'A Food Order Location is an area where QR codes are placed that links to the food ordering app'}
        buttonName={selectedItem?.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!locationCode || processing || disabled}
        deleteDisabled={!selectedItem?.id || (global && selectedItem.foodVendorId) || (!global && !selectedItem.foodVendorId)}
        onDelete={() =>
          dispatch(FoodOrderLocationActions.delete(selectedItem, `/api/private/portal/food/order/location/${selectedItem.id}`))
        }
      />
      {error && (
        <CardContent>
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food order location - ${error.message}`}
          </Alert>
        </CardContent>
      )}
      <CardContent>
        <Grid container spacing={1} disabled>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField disabled={disabled} label={'Location Code'} value={locationCode} setValue={setLocationCode} required />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField disabled={disabled} label={'Location Name'} value={locationName} setValue={setLocationName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              disabled={disabled}
              label={'Location Type'}
              value={locationType}
              setValue={setLocationType}
              options={locationOptions}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect disabled={disabled} label={'Check Mode'} value={checkMode} setValue={setCheckMode} options={FoodOrderCheckModes} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              disabled={disabled}
              label={'Start Navigation'}
              value={navigationCode}
              setValue={setNavigationCode}
              options={AmenitiesNavigationOptions}
              hasClear
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
