import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormCurrencyList from '../../../../../../components/form/FormCurrencyList';
import FormField from '../../../../../../components/form/FormField';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';

export default function EditManualPms() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || true);
  const [delayTime, setDelayTime] = React.useState(configuration?.delayTime || 2000);
  const [simulateFailure, setSimulateFailure] = React.useState(configuration?.simulateFailure || false);
  const [autoSearchPrefix, setAutoSearchPrefix] = React.useState(configuration?.autoSearchPrefix || 'SCM');
  const [debug, setDebug] = React.useState(configuration?.debug || false);
  const [currency, setCurrency] = React.useState(configuration?.currency || 'NZD');

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        delayTime: delayTime,
        simulateFailure: simulateFailure,
        debug: debug,
        currency: currency,
        autoSearchPrefix: autoSearchPrefix,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  };

  const isFormReady = name && currency;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem.id ? 'Update Manual PMS' : 'New Manual PMS'}
        subheader={'Self managed PMS stored locally within the SmartCheck system'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        deleteDisabled={!selectedItem.id}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to edit system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={name} setValue={setName} error={!name} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Auto Search Prefix'}
              value={autoSearchPrefix}
              setValue={setAutoSearchPrefix}
              error={!autoSearchPrefix}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Delay Time (MS)'} value={delayTime} setValue={setDelayTime} error={!delayTime} required />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormCurrencyList value={currency} setValue={setCurrency} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Simulate Failure'} value={simulateFailure} setValue={setSimulateFailure} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
