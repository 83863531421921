import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import FormField from '../../../../components/form/FormField';
import moment from 'moment/moment';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import UserRoleHelper from '../../../../support/UserRoleHelper';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';

export default function MyAccountEdit() {
  const profile = useSelector((state) => state.accessReducer.profile);
  const [firstName, setFirstName] = useState(profile?.firstName || '');
  const [lastName, setLastName] = useState(profile?.lastName || '');
  const [phone, setPhone] = useState({
    phoneNumber: profile?.phoneNumber,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(() => {
    const payload = {
      ...profile,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone.sanitizedPhoneNumber,
    };

    setError(null);

    API.put('PrivateAPI', '/api/private/portal/user/profile/' + payload.username, { body: payload })
      .then(async () => {
        await Swal.fire('Profile Updated', 'Your changes have been saved successfully', 'success');
      })
      .catch(async (error) => {
        setError(error);
        await Swal.fire('Update Failed', 'Unable to update profile. Please try again later', 'error');
      });
  }, [firstName, lastName, phone, profile]);

  const isPhoneValid = phone.isValid;
  const isFormReady = firstName && lastName && phone && isPhoneValid;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Your Profile'}
        subheader={profile?.email}
        buttonName="Update"
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Profile update failed: ${Objects.getErrorFromResponse(error)}`}
          </Alert>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'First Name'} value={firstName} setValue={setFirstName} error={!firstName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Last Name'} value={lastName} setValue={setLastName} error={!lastName} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Email'} value={profile?.email} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Role'} value={UserRoleHelper.toFriendlyName(profile?.role)} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Sites'} value={profile?.sites?.length || 0} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Updated'} value={moment.unix(profile?.updated).format('LLL')} disabled />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Created'} value={moment.unix(profile?.created).format('LLL')} disabled />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
