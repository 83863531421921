import React, { useMemo, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import { Check, DeleteOutlined } from '@material-ui/icons';
import FoodModifierActions from '../../../../redux/actions/inRoom/ordering/foodModifier/actions';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: 70,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Box style={{ width: 60 }}>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodModifierActions.delete(value, `/api/private/portal/food/modifier/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
        </Box>
      );
    },
  },
  {
    id: 'modifierName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'price',
    label: 'Price',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      return value.price === null ? 'Open Price' : value.price;
    },
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value) {
      if (value.enabled) {
        return <Check />;
      }
      return null;
    },
  },
];

export default function FoodModifierList() {
  const dispatch = useDispatch();
  const modifierList = useSelector((state) => state.foodModifierReducer.entityList);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const processing = useSelector((state) => state.foodModifierReducer.processing);
  const page = useSelector((state) => state.foodModifierReducer.page);
  const error = useSelector((state) => state.foodModifierReducer.error);
  const [filterState, setFilterState] = useState({
    modifierName: '',
    externalId: '',
  });

  const payload = useMemo(() => {
    let formattedPayload = { ...filterState };
    if (filterState['modifierName']) {
      formattedPayload.modifierName = filterState['modifierName'] || '';
    }

    if (filterState['externalId']) {
      formattedPayload.externalId = filterState['externalId'] || '';
    }
    return formattedPayload;
  }, [filterState]);

  const getFilterFieldsForScreen = (state, setState) => {
    const filterUpdateMethods = getFilterUpdateMethods(state, setState);
    return [
      getFilterField('modifierName', 'Modifier Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
      getFilterField('externalId', 'External Id', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    ];
  };

  return (
    <AutoTable
      title="Modifiers"
      subheader={'A modifier will alter the meal and is assigned to a group for display. Think an extra shot for a Latte!'}
      handleRefreshReport={() => dispatch(FoodModifierActions.fetch(0, 25, payload, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodModifierActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) => dispatch(FoodModifierActions.fetch(newPage, pageSize, payload, { vendorId: vendorId }))}
      filterPayload={payload}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_MODIFIER"
      createNewURL="/food/modifier/new"
      detailURL="/food/modifier/view/{id}"
      idColumn={'id'}
      data={modifierList}
      backLink={'/food/vendor'}
      filters={getFilterFieldsForScreen(filterState, setFilterState)}
    />
  );
}
