import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Actions from '../../../../redux/actions/siteDoor/actions';
import { Card, CardContent, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import FormSelect from '../../../../../components/form/FormSelect';
import FormField from '../../../../../components/form/FormField';
import SiteDoorType from '../../../../../support/SiteDoorType';
import { FrontOfficeStatus, HouseKeepingStatus } from '../../../../../support/RoomStatus';
import DoorType from '../../../../../support/DoorType';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';

export default function SiteRoomEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const lastActionType = useSelector((state) => state.siteDoorReducer.lastActionType);
  const error = useSelector((state) => state.siteDoorReducer.error);
  const selectedItem = useSelector((state) => state.siteDoorReducer.selectedItem);

  const [doorName, setDoorName] = React.useState(selectedItem?.doorName || '');
  const [doorId, setDoorId] = React.useState(selectedItem?.doorId || '');
  const [doorType, setDoorType] = React.useState(selectedItem?.doorType || SiteDoorType.PRIMARY);
  const [doorGroup, setDoorGroup] = React.useState(selectedItem?.doorGroup || '');
  const [foStatus, setFoStatus] = React.useState(selectedItem?.foStatus || FrontOfficeStatus.VACANT);
  const [hkStatus, setHkStatus] = React.useState(selectedItem?.hkStatus || HouseKeepingStatus.CLEAN);
  const [floor, setFloor] = React.useState(selectedItem?.floor || '');
  const [displayRoomNumber, setDisplayRoomNumber] = React.useState(selectedItem?.displayRoomNumber || selectedItem?.doorName);
  const [visible, setVisible] = React.useState(selectedItem?.visible || false);
  const [authorisationNumber, setAuthorisationNumber] = React.useState(selectedItem?.authorisationNumber || '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'SITE_ROOM_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, history]);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      doorName,
      doorId,
      doorType,
      doorGroup,
      foStatus,
      hkStatus,
      floor,
      displayRoomNumber,
      authorisationNumber,
      visible,
      siteId: selectedSite?.id,
    };

    dispatch(Actions.update(payload));
  };

  const isFormReady = floor && displayRoomNumber;

  return (
    <Card>
      <CardHeaderWithControls
        header={'Update Door'}
        subheader={'Door available at the site'}
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={!isFormReady}
      />
      <CardContent>
        {error && <Alert severity="error">{`Unable to edit door - ${error.message}`}</Alert>}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Room Name'} value={doorName} setValue={setDoorName} placeholder="Door Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Door ID'} value={doorId} setValue={setDoorId} placeholder="Door ID" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Door Group'} value={doorGroup} setValue={setDoorGroup} placeholder="Door Group (Guestrooms)" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Door Type'}
              value={doorType}
              setValue={setDoorType}
              options={Object.keys(DoorType).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'Front Office Status'}
              value={foStatus}
              setValue={setFoStatus}
              options={Object.keys(FrontOfficeStatus).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect
              label={'House Keeping Status'}
              value={hkStatus}
              setValue={setHkStatus}
              options={Object.keys(HouseKeepingStatus).map((key) => ({
                label: key,
                value: key,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Floor'} value={floor} setValue={setFloor} type={'text'} placeholder="Floor" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Display Room Number'}
              value={displayRoomNumber}
              setValue={setDisplayRoomNumber}
              type={'text'}
              placeholder="Display Room Number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Authorisation Number'}
              value={authorisationNumber}
              setValue={setAuthorisationNumber}
              type={'text'}
              placeholder="Authorisation Number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Visible'} value={visible} setValue={setVisible} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
