import actionCreator from '../../../support/actionCreator';
import Objects from '../../../../../support/Objects';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import { NotificationType } from '../../../../view/private/site/notification/NotificationType';
import AuthorisationHelper from '../../../../../support/AuthorisationHelper';

export const NotificationScheduleActions = {
  ...actionCreator.createActions(
    'Notification Schedule',
    'NOTIFICATION_SCHEDULE',
    '/api/private/portal/site/{siteId}/notification/schedule'
  ),
  export: (payload) => async (dispatch) => {
    try {
      const response = await API.post('PrivateAPI', `/api/private/portal/notification/schedule/export`, { body: payload });
      dispatch({ type: `NOTIFICATION_SCHEDULE_EXPORT_SUCCESS`, payload: response });
      Objects.saveAndDownloadContent(`${response.fileName}`, `data:application/csv;name=;base64,${response.file}`);
    } catch (error) {
      dispatch({
        type: `NOTIFICATION_SCHEDULE_EXPORT_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  import: (payload, parameters, pathVariables) => async (dispatch) => {
    AuthorisationHelper.getAuthorisationToken().then((token) => {
      dispatch({ type: 'NOTIFICATION_SCHEDULE_IMPORT_IN_PROGRESS' });
      fetch(`/api/private/portal/notification/schedule/import`, {
        method: 'POST',
        headers: { [token.header]: token.value },
        body: payload,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: 'NOTIFICATION_SCHEDULE_IMPORT_SUCCESS' });
          } else {
            return Promise.reject(response);
          }
        })
        .catch((error) => {
          console.error('Unable to complete tag import', error);
          dispatch({ type: 'NOTIFICATION_SCHEDULE_IMPORT_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
        });
    });
  },
  selectAndNavigate: (item, history) => async (dispatch) => {
    await dispatch({ type: 'NOTIFICATION_SCHEDULE_SELECT', payload: item });
    history.push(`/site/notifications/view/${item.id}`);
  },
  duplicate: (item) => async (dispatch) => {
    Objects.notNull(item, 'item cannot be null');

    let notificationTypeOptions = [];
    NotificationType.forEach((t) => notificationTypeOptions.push(t.value));
    let categoryOptions = ['SMS', 'EMAIL'];

    Swal.fire({
      title: 'Select Notification Type and Category',
      html:
        '<select id="notif-type-options" class="swal2-input">' +
        notificationTypeOptions.map((option) => `<option value="${option}">${option}</option>`) +
        '</select>' +
        '<select id="category-options" class="swal2-input">' +
        categoryOptions.map((option) => `<option value="${option}">${option}</option>`) +
        '</select>',
      confirmButtonText: 'Submit',
      showCancelButton: true,
      preConfirm: () => {
        if (!document.getElementById('notif-type-options').value || !document.getElementById('category-options').value) {
          Swal.showValidationMessage(`Notification Type and Category required`);
          return false;
        }
        return {
          notificationType: document.getElementById('notif-type-options').value,
          category: document.getElementById('category-options').value,
        };
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async function (result) {
      if (result.isConfirmed) {
        dispatch({ type: `NOTIFICATION_SCHEDULE_ADD_IN_PROGRESS` });
        try {
          const response = await API.post(
            'PrivateAPI',
            `/api/private/portal/notification/schedule/duplicate/${item.id}?notificationType=${result.value.notificationType}&notificationCategory=${result.value.category}`,
            {}
          );
          dispatch({ type: `NOTIFICATION_SCHEDULE_ADD_SUCCESS`, payload: response });
        } catch (error) {
          dispatch({
            type: `NOTIFICATION_SCHEDULE_ADD_FAILURE`,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        }
      }
    });
  },
};

export default NotificationScheduleActions;
