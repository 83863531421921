const CheckInState = {
  NOT_STARTED: 'NOT_STARTED',
  SUMMARY: 'SUMMARY',
  MEMBERSHIP: 'MEMBERSHIP',
  ROOM_SELECTION: 'ROOM_SELECTION',
  UPSELL: 'UPSELL',
  PAYMENT: 'PAYMENT',
  COMPLETED: 'COMPLETED',
};

const CheckInStateOptions = [
  { label: 'Not Started', value: CheckInState.NOT_STARTED },
  { label: 'Summary', value: CheckInState.SUMMARY },
  { label: 'Membership', value: CheckInState.MEMBERSHIP },
  { label: 'Room Selection', value: CheckInState.ROOM_SELECTION },
  { label: 'Upsell', value: CheckInState.UPSELL },
  { label: 'Payment', value: CheckInState.PAYMENT },
  { label: 'Completed', value: CheckInState.COMPLETED },
];

export default CheckInState;
export { CheckInStateOptions };
