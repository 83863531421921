import * as DataTypes from '../../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Actions from '../../../../../redux/actions/reservationTracking/actions';
import AutoTable from '../../../../../../components/table/AutoTable';

const columns = [
  {
    id: 'reservationNumber',
    label: 'Reservation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'checkInState',
    label: 'Check In State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'checkOutState',
    label: 'Check Out State',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'challengeCode',
    label: 'Challenge Code',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'challengeCodeExpiry',
    label: 'Challenge Code Expiry',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Last Update',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
];

export default function ReservationTrackingList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const page = useSelector((state) => state.reservationTrackingReducer.page);
  const error = useSelector((state) => state.reservationTrackingReducer.error);
  const processing = useSelector((state) => state.reservationTrackingReducer.processing);
  const lastActionType = useSelector((state) => state.reservationTrackingReducer.lastActionType);
  const requests = useSelector((state) => state.reservationTrackingReducer.entityList);
  const selectedSiteId = selectedSite?.id;

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSiteId && selectedReservation) {
      dispatch(Actions.fetch(0, 10, { siteId: selectedSiteId, reservationNumber: selectedReservation.reservationNumber }));
    }
  }, [selectedSiteId, dispatch, selectedReservation]);

  const handleChangePage = (ev, newPage, pageSize) => {
    if (selectedSiteId && selectedReservation) {
      dispatch(Actions.fetch(newPage, pageSize, { siteId: selectedSiteId, reservationNumber: selectedReservation.reservationNumber }));
    }
  };

  return (
    <AutoTable
      title="Reservations Tracking"
      subheader={'Reservation Tracking for the Guest'}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="RESERVATION_TRACKING"
      buttonDisabled={true}
      detailURL="/reservation/tracking/view/{id}"
      data={requests}
      lastActionType={lastActionType}
      backHidden
    />
  );
}
