import actionCreator from '../../support/actionCreator';
import Objects from '../../../../support/Objects';
import { API } from '@aws-amplify/api';
import Swal from 'sweetalert2';

export const LICENSE_DOWNLOAD_IN_PROGRESS = 'LICENSE_DOWNLOAD_IN_PROGRESS';
export const LICENSE_DOWNLOAD_SUCCESS = 'LICENSE_DOWNLOAD_SUCCESS';
export const LICENSE_DOWNLOAD_FAILURE = 'LICENSE_DOWNLOAD_FAILURE';

export const LICENSE_UPLOAD_IN_PROGRESS = 'LICENSE_UPLOAD_IN_PROGRESS';
export const LICENSE_UPLOAD_SUCCESS = 'LICENSE_UPLOAD_SUCCESS';
export const LICENSE_UPLOAD_FAILURE = 'LICENSE_UPLOAD_FAILURE';

const LicenseActions = {
  ...actionCreator.createActions('License', 'LICENSE', '/api/license'),
  uploadFile: (file) => {
    return async (dispatch) => {
      dispatch({ type: LICENSE_UPLOAD_IN_PROGRESS });

      const data = new FormData();
      data.set('uploadFile', file, file.name);
      data.set('Content-Type', 'multipart/form-data');

      API.post('PrivateAPI', `/api/license/private/portal/newLicense`, { body: data })
        .then((response) => {
          if (response.duplicate) {
            Swal.fire('License Uploaded Failed', 'License already exists for organisation ' + response.organisationName, 'error');
            dispatch({
              type: LICENSE_UPLOAD_FAILURE,
              error: 'Duplicate License',
            });
          } else {
            Swal.fire('License Uploaded', 'License Uploaded for organisation ' + response.organisationName, 'success');
            dispatch({
              type: LICENSE_UPLOAD_SUCCESS,
              payload: response,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: LICENSE_UPLOAD_FAILURE,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        });
    };
  },
  downloadLicense: (id) => {
    return async (dispatch) => {
      dispatch({ type: LICENSE_DOWNLOAD_IN_PROGRESS });

      API.get('PrivateAPI', `/api/license/private/portal/downloadLicenseKey/` + id, {})
        .then((response) => {
          Objects.saveAndDownloadContent(response.fileName, `data:application/csv;name=;base64,${response.file}`);
          dispatch({
            type: LICENSE_DOWNLOAD_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          dispatch({
            type: LICENSE_DOWNLOAD_FAILURE,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        });
    };
  },
  expire: (item) => {
    return async (dispatch) => {
      dispatch({ type: 'LICENSE_UPDATE_IN_PROGRESS' });

      API.get('PrivateAPI', `/api/license/private/portal/expire/` + item.id, {})
        .then((response) => {
          dispatch({
            type: 'LICENSE_UPDATE_SUCCESS',
            payload: response,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'LICENSE_UPDATE_FAILURE',
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        });
    };
  },
  findByFilter: (page, size, parameters, licenseStatus, organisationName, contactEmail, expiryDate) => async (dispatch) => {
    try {
      const pageParam = page ? `&page=${page}` : '&page=0';
      const sizeParam = size ? `&size=${size}` : '&size=10';
      let parametersParam = '';
      if (parameters) {
        const entries = Object.entries(parameters);
        parametersParam = entries.map((entry) => `&${entry[0]}=${entry[1]}`).join('');
      }
      const licenseStatusParam = licenseStatus ? `&licenseStatus=${licenseStatus}` : '';
      const organisationNameParam = organisationName ? `&organisationName=${organisationName}` : '';
      const contactEmailParam = contactEmail ? `&contactEmail=${contactEmail}` : '';
      const expiryDateParam = expiryDate ? `&expiryDate=${expiryDate.toISOString()}` : '';
      const url = `/api/license?${parametersParam}${pageParam}${sizeParam}${licenseStatusParam}${organisationNameParam}${contactEmailParam}${expiryDateParam}`;
      console.log('url', url);
      const response = await API.get('PrivateAPI', url, {});
      dispatch({ type: 'LICENSE_FETCH_SUCCESS', payload: response });
    } catch (error) {
      dispatch({
        type: 'LICENSE_FETCH_FAILURE',
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default LicenseActions;
