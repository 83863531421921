const ScheduleCondition = {
  BEFORE: 'BEFORE',
  ON: 'ON',
  AFTER: 'AFTER',
};

const ScheduleConditionOptions = [
  { value: ScheduleCondition.BEFORE, label: 'BEFORE' },
  { value: ScheduleCondition.ON, label: 'ON' },
  { value: ScheduleCondition.AFTER, label: 'AFTER' },
];

export { ScheduleConditionOptions, ScheduleCondition };
