import actionCreator from '../../support/actionCreator';
import { API } from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

export const GUEST_ORDERS_FETCH_IN_PROGRESS = 'GUEST_ORDERS_FETCH_IN_PROGRESS';
export const GUEST_ORDERS_FETCH_SUCCESS = 'GUEST_ORDERS_FETCH_SUCCESS';
export const GUEST_ORDERS_FETCH_FAILURE = 'GUEST_ORDERS_FETCH_FAILURE';
export const GUEST_ORDERS_REPROCESS_IN_PROGRESS = 'GUEST_ORDERS_REPROCESS_IN_PROGRESS';
export const GUEST_ORDERS_REPROCESS_SUCCESS = 'GUEST_ORDERS_REPROCESS_SUCCESS';
export const GUEST_ORDERS_REPROCESS_FAILURE = 'GUEST_ORDERS_REPROCESS_FAILURE';
export const GUEST_ORDERS_SELECT_BY_ID_IN_PROGRESS = 'GUEST_ORDERS_SELECT_BY_ID_IN_PROGRESS';
export const GUEST_ORDERS_SELECT_BY_ID_SUCCESS = 'GUEST_ORDERS_SELECT_BY_ID_SUCCESS';
export const GUEST_ORDERS_SELECT_BY_ID_FAILURE = 'GUEST_ORDERS_SELECT_BY_ID_FAILURE';
export const GUEST_ORDERS_DOWNLOAD_RECEIPT_IN_PROGRESS = 'GUEST_ORDERS_DOWNLOAD_RECEIPT_IN_PROGRESS';
export const GUEST_ORDERS_DOWNLOAD_RECEIPT_SUCCESS = 'GUEST_ORDERS_DOWNLOAD_RECEIPT_SUCCESS';
export const GUEST_ORDERS_DOWNLOAD_RECEIPT_FAILURE = 'GUEST_ORDERS_DOWNLOAD_RECEIPT_FAILURE';
export const GUEST_ORDERS_REFUND_IN_PROGRESS = 'GUEST_ORDERS_REFUND_IN_PROGRESS';
export const GUEST_ORDERS_REFUND_SUCCESS = 'GUEST_ORDERS_REFUND_SUCCESS';
export const GUEST_ORDERS_REFUND_FAILURE = 'GUEST_ORDERS_REFUND_FAILURE';
export const GUEST_ORDERS_EXPORT_IN_PROGRESS = 'GUEST_ORDERS_EXPORT_IN_PROGRESS';
export const GUEST_ORDERS_EXPORT_SUCCESS = 'GUEST_ORDERS_EXPORT_SUCCESS';
export const GUEST_ORDERS_EXPORT_FAILURE = 'GUEST_ORDERS_EXPORT_FAILURE';

const BASE_URL = '/api/private/food/orders';

const GuestOrdersActions = {
  ...actionCreator.createActions('Guest Orders', 'GUEST_ORDERS', BASE_URL, {
    fetchUrl: '/api/private/food/orders/search/site/{siteId}',
  }),
  selectById: (id) => async (dispatch) => {
    dispatch({ type: 'GUEST_ORDERS_SELECT_BY_ID_IN_PROGRESS' });
    try {
      const response = await API.get('PrivateAPI', `${BASE_URL}/${id}`, {});
      dispatch({ type: 'GUEST_ORDERS_SELECT_BY_ID_SUCCESS', payload: response });
    } catch (error) {
      dispatch({ type: 'GUEST_ORDERS_SELECT_BY_ID_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  reprocessOrder: (id) => async (dispatch) => {
    dispatch({ type: 'GUEST_ORDERS_REPROCESS_IN_PROGRESS' });
    try {
      const response = await API.post('PrivateAPI', `${BASE_URL}/${id}/reprocess`, {});
      dispatch({ type: 'GUEST_ORDERS_REPROCESS_SUCCESS', payload: response });
    } catch (error) {
      dispatch({ type: 'GUEST_ORDERS_REPROCESS_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  refundOrder: (id) => async (dispatch) => {
    Swal.fire({
      title: `Refund Order?`,
      text: 'Warning: Do you want to continue? This is not reversible!',
      icon: 'warning',
      showCancelButton: true,
      heightAuto: false,
    }).then(async (result) => {
      if (result.value) {
        dispatch({ type: GUEST_ORDERS_REFUND_IN_PROGRESS });
        try {
          const response = await API.post('PrivateAPI', `${BASE_URL}/${id}/refund`, {});
          dispatch({ type: GUEST_ORDERS_REFUND_SUCCESS, payload: response });
          Swal.fire(`Refund Completed`, 'Your request have been completed successfully', 'success');
        } catch (error) {
          dispatch({ type: GUEST_ORDERS_REFUND_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
          Swal.fire(`Refund Failed`, 'Unable to refund for this order. Please try again later.', 'error');
        }
      }
    });
  },
  downloadReceipt: (reference, id) => async (dispatch) => {
    dispatch({ type: 'GUEST_ORDERS_DOWNLOAD_RECEIPT_IN_PROGRESS' });
    try {
      await API.get('PrivateAPI', `${BASE_URL}/${id}/receipt`, {}).then((response) => {
        Objects.saveAndDownloadContent(`${reference}.pdf`, `data:application/pdf;base64,${response}`);
        dispatch({ type: 'GUEST_ORDERS_DOWNLOAD_RECEIPT_SUCCESS' });
      });
    } catch (error) {
      dispatch({ type: 'GUEST_ORDERS_DOWNLOAD_RECEIPT_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  exportAll: (siteId, params) => async (dispatch) => {
    await dispatch({ type: GUEST_ORDERS_EXPORT_IN_PROGRESS });
    try {
      const requestUrl = Objects.appendParamsUrl(params, `/api/private/food/orders/search/site/${siteId}/export`);
      const response = await API.get('PrivateAPI', requestUrl, {});
      await dispatch({ type: GUEST_ORDERS_EXPORT_SUCCESS });
      Objects.saveAndDownloadContent(`${response.fileName}`, `data:application/csv;name=;base64,${response.file}`);
    } catch (error) {
      console.warn('Failed to export food order: ', error);
      dispatch({
        type: GUEST_ORDERS_EXPORT_FAILURE,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};
export default GuestOrdersActions;
