import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_IN_PROGRESS = 'SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_IN_PROGRESS';
export const SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_SUCCESS = 'SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_SUCCESS';
export const SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_FAILURE = 'SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_FAILURE';

const SpecialRequestEscalationRedirectActions = {
  ...actionCreator.createActions(
    'Escalation Notification Contact',
    'SPECIAL_REQUEST_ESCALATION_REDIRECT',
    '/api/private/specialRequestRedirect'
  ),

  fetchEscalationRecipients: (page, size, parameters) => async (dispatch) => {
    let requestParams = '';
    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        if (parameters[key] !== '') {
          requestParams += `&${key}=${parameters[key]}`;
        }
      });
    }
    await dispatch({ type: 'SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_IN_PROGRESS' });

    try {
      const result = await API.get(
        'PrivateAPI',
        `/api/private/specialRequestRedirect/escalation?page=${page}&size=${size}${requestParams}`,
        {}
      );

      await dispatch({
        type: `SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_SUCCESS`,
        payload: {
          ...result,
          page: {
            number: result.number,
            size: result.size,
            totalElements: result.totalElements,
            totalPages: result.totalPages,
          },
        },
      });
    } catch (error) {
      console.warn('Failed to fetch escalation redirect due to error: ', error);
      dispatch({
        type: `SPECIAL_REQUEST_ESCALATION_REDIRECT_FETCH_FAILURE`,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};
export default SpecialRequestEscalationRedirectActions;
