import React from 'react';
import * as DataTypes from '../../../../../constants/DataTypes';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/securityRoomMapping/actions';
import SiteRoomActions from '../../../../redux/actions/siteDoor/actions';
import SecurityRoomActions from '../../../../redux/actions/securityDoor/actions';
import { useDispatch, useSelector } from 'react-redux';

const columns = [
  {
    id: 'lockDoorName',
    label: 'Lock Door Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'securityDoorName',
    label: 'Security Door Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function SecurityRoomMappingList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const mappingList = useSelector((state) => state.securityRoomMappingReducer.entityList);
  const lastActionType = useSelector((state) => state.securityRoomMappingReducer.lastActionType);
  const processing = useSelector((state) => state.securityRoomMappingReducer.processing);
  const page = useSelector((state) => state.securityRoomMappingReducer.page);
  const error = useSelector((state) => state.securityRoomMappingReducer.error);
  const parameters = { siteId: selectedSite?.id, sort: 'id,asc' };

  React.useEffect(() => {
    dispatch(Actions.select(null));
    dispatch(SiteRoomActions.fetch(0, 5000, { siteId: selectedSite?.id }));
    dispatch(SecurityRoomActions.fetch(0, 5000, { siteId: selectedSite?.id }));
  }, [dispatch, selectedSite?.id]);

  return (
    <AutoTable
      title="Room Mapping List"
      subheader={'Rooms mapping list available at the site'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 25, parameters))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, parameters))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECURITY_ROOM_MAPPING"
      buttonName="New Mapping"
      createNewURL="/security/room-mapping/new"
      detailURL="/security/room-mapping/view/{id}"
      idColumn={'id'}
      data={mappingList}
      lastActionType={lastActionType}
    />
  );
}
