import * as Actions from '../../actions/access/actions';

const initial = {
  lastActionType: 'NONE',
  profileCheckCompleted: false,
  profile: {
    identifier: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    role: null,
  },
  siteList: [],
  selectedSite: null,
  corporationList: [],
  selectedCorporation: null,
  theme: 'light',
  selectedSection: { label: 'Operations', path: '/reservation/dashboard' },
  error: null,
};

export default function accessReducer(state = initial, action) {
  switch (action.type) {
    case Actions.ACCESS_CLEAR_STATE:
      return {
        ...initial,
      };
    case Actions.ACCESS_GET_USER_SITES_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        siteList: [],
        error: null,
      };
    case Actions.ACCESS_GET_USER_SITES_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        siteList: action.payload,
      };
    case Actions.ACCESS_GET_USER_SITES_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.UPDATE_THEME_PREFERENCE:
      return {
        ...state,
        lastActionType: action.type,
        theme: action.payload,
      };
    case Actions.PROFILE_CHECK_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        profile: {
          ...initial.profile,
        },
        profileCheckCompleted: false,
        siteList: [],
        corporationList: [],
        error: null,
      };
    case Actions.PROFILE_CHECK_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        siteList: action.payload.sites,
        selectedSite: action.payload.selectedSite,
        corporationList: action.payload.corporations,
        selectedCorporation: action.payload.selectedCorporation,
        profile: action.payload,
        profileCheckCompleted: true,
      };
    case Actions.PROFILE_CHECK_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.ACCESS_SELECT_SITE:
      return {
        ...state,
        lastActionType: action.type,
        selectedSite: action.payload,
      };
    case Actions.ACCESS_SELECT_CORP:
      return {
        ...state,
        lastActionType: action.type,
        selectedCorporation: action.payload,
      };
    case Actions.UPDATE_SELECTED_CORP_AND_SITE:
      return {
        ...state,
        lastActionType: action.type,
        selectedCorporation: action.payload.selectedCorporation,
        selectedSite: action.payload.selectedSite,
      };
    case Actions.ACCESS_SELECT_SECTION:
      return {
        ...state,
        lastActionType: action.type,
        selectedSection: action.payload,
      };
    case Actions.ACCESS_SELECTED_SITE_UPDATE_CHAT_COLLECTION_ID:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
        selectedSite: {
          ...state.selectedSite,
          chatCollectionId: action.payload,
        },
      };
    default:
      return state;
  }
}
