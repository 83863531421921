import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';

const SiteDoorActions = {
  ...actionCreator.createActions('Door', 'SITE_ROOM', '/api/private/portal/door', {
    fetchUrl: '/api/private/portal/door/search/findAllBySiteId', //override the fetch URL
  }),
  resyncDoors: (siteId) => async () => {
    API.get('PrivateAPI', `/api/private/portal/roomSelection/${siteId}/resync`, {})
      .then(() => {
        Swal.fire('Resync Requested', 'This may take several minutes. Please be patient', 'success');
      })
      .catch(() => {
        Swal.fire('Resync Failed', 'Unable to request rooms resync. Please try again later', 'error');
      });
  },
};

export default SiteDoorActions;
