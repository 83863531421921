import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as LinkedSitesActions from '../../../redux/actions/app/linkedSites/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LicenseActions from '../../../redux/actions/license/actions';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PhoneNumberInput from '../../../../components/phoneNumberInput/PhoneNumberInput';

export default function LicenseEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedItem = useSelector((state) => state.licenseReducer.selectedItem);
  const selectedItemId = selectedItem?.id;
  const lastActionType = useSelector((state) => state.licenseReducer.lastActionType);
  const error = useSelector((state) => state.licenseReducer.error);

  const [organisationName, setOrganisationName] = React.useState(selectedItem.organisationName || '');
  const [contactName, setContactName] = React.useState(selectedItem.contactName || '');
  const [phone, setPhone] = useState({
    phoneNumber: selectedItem?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [licenseType, setLicenseType] = React.useState(selectedItem.licenseType || 'TRIAL');
  const [expiryDate, setExpiryDate] = React.useState(new Date(selectedItem.expiryDate * 1000) || '');
  const [deviceFingerprint, setDeviceFingerprint] = React.useState(selectedItem.deviceFingerprint || '');
  const [licenseKey, setLicenseKey] = React.useState(selectedItem.licenseKey || '');
  const [licenseStatus, setLicenseStatus] = React.useState(selectedItem.licenseStatus || 'EXPIRED');
  const [email, setEmail] = React.useState(selectedItem.contactEmail || '');

  useEffect(() => {
    dispatch(LicenseActions.clearError());
    dispatch(LinkedSitesActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'LICENSE_ADD_SUCCESS' ||
      lastActionType === 'LICENSE_DELETE_SUCCESS' ||
      lastActionType === 'LICENSE_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      organisationName: organisationName,
      contactName: contactName,
      phone: phone.sanitizedPhoneNumber,
      licenseType: licenseType,
      expiryDate: moment(expiryDate).format('YYYY-MM-DD'),
      deviceFingerprint: deviceFingerprint,
      licenseKey: licenseKey,
      licenseStatus: licenseStatus,
      email: email,
    };

    if (selectedItem) {
      dispatch(LicenseActions.update(payload, '/api/license/private/portal/' + selectedItemId));
    } else {
      dispatch(LicenseActions.add(payload));
    }
  };

  const handleFileDownload = () => {
    dispatch(LicenseActions.downloadLicense(selectedItemId));
  };

  const isPhoneValid = phone.isValid;
  const isFormReady = organisationName && contactName && isPhoneValid && deviceFingerprint && email;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update License' : 'Create License'}
        subheader={'Each KMS Installation requires a new License Key'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        buttonLoading={lastActionType === 'LICENSE_UPDATE_IN_PROGRESS'}
        disabled={!isFormReady}
        deleteDisabled={true}
        secondaryButtonName={'Download License'}
        secondaryButtonAction={handleFileDownload}
        secondaryButtonDisabled={!selectedItemId}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to add license - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Organisation Name"
              type="text"
              variant={'outlined'}
              onChange={(event) => setOrganisationName(event.target.value)}
              value={organisationName}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              type="text"
              label="Contact Name"
              variant={'outlined'}
              onChange={(event) => setContactName(event.target.value)}
              value={contactName}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              type="text"
              label="Email Address"
              variant={'outlined'}
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="licenseType">License Type</InputLabel>
              <Select labelId="licenseType" value={licenseType} labelWidth={75} onChange={(event) => setLicenseType(event.target.value)}>
                <MenuItem value="TRIAL">TRIAL</MenuItem>
                <MenuItem value="FULL">FULL</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="licenseStatus">License Status</InputLabel>
              <Select
                labelId="licenseStatus"
                value={licenseStatus}
                labelWidth={75}
                onChange={(event) => setLicenseStatus(event.target.value)}
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="EXPIRED">EXPIRED</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DatePicker
                fullWidth
                placeholder="Start Date"
                autoOk={true}
                inputVariant={'outlined'}
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                disablePast={true}
                format="DD-MM-YYYY"
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              disabled={true}
              fullWidth
              label="Device Fingerprint"
              type="number"
              variant={'outlined'}
              onChange={(event) => setDeviceFingerprint(event.target.value)}
              value={deviceFingerprint}
              multiline
              maxRows={8}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              disabled={true}
              fullWidth
              multiline
              maxRows={20}
              label="License Key"
              type="text"
              variant={'outlined'}
              onChange={(event) => setLicenseKey(event.target.value)}
              value={licenseKey}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
