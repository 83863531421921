import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';

export const SECURITY_CARDHOLDER_PREFIX = 'SECURITY_CARDHOLDER';

const SecurityCardholderActions = {
  ...actionCreator.createActions('Security Cardholder', SECURITY_CARDHOLDER_PREFIX, '/api/private/security/cardholder'),

  findByFilter: (page, size, siteId, firstname, lastname, cardSerialNumber, active) => async (dispatch) => {
    dispatch({ type: `${SECURITY_CARDHOLDER_PREFIX}_FETCH_IN_PROGRESS` });

    /**
     * Base request URL with no frills includes the page and size of the fetch to return
     */
    const requestUrl = `/api/private/security/cardholder?page=${page || 0}&size=${
      size || 20
    }&siteId=${siteId}&firstname=${firstname}&lastname=${lastname}&cardSerialNumber=${cardSerialNumber}&active=${Boolean(
      active
    )}&sort=active,desc&sort=cardId,desc`;

    API.get('PrivateAPI', `${requestUrl}`, {})
      .then((response) => {
        dispatch({
          type: `${SECURITY_CARDHOLDER_PREFIX}_FETCH_SUCCESS`,
          payload: {
            ...response,
            page: {
              number: response.number,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: `${SECURITY_CARDHOLDER_PREFIX}_FETCH_FAILURE`,
          payload: new Error(Objects.getErrorFromResponse(error)),
        });
      });
  },
};
export default SecurityCardholderActions;
