import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

// Actions
export const USER_ACTION_IN_PROGRESS = 'USER_ACTION_IN_PROGRESS';

export const USER_GET_USERS_SUCCESS = 'USER_GET_USERS_SUCCESS';
export const USER_GET_USERS_FAILURE = 'USER_GET_USERS_FAILURE';

export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';

export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAILURE = 'USER_EDIT_FAILURE';

export const USER_SEND_VALIDATE_CODE_SUCCESS = 'USER_SEND_VALIDATE_CODE_SUCCESS';
export const USER_SEND_VALIDATE_CODE_FAILURE = 'USER_SEND_VALIDATE_CODE_FAILURE';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';

export const USER_SELECTED_ITEM = 'USER_SELECTED_ITEM';
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';

export const fetchUsers = (page, size) => async (dispatch) => {
  dispatch({ type: USER_ACTION_IN_PROGRESS });
  try {
    const url = `/api/private/portal/user?page=${page || 0}&size=${size || 10}`;
    const response = await API.get('PrivateAPI', url, {});
    dispatch({ type: USER_GET_USERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: USER_GET_USERS_FAILURE, payload: error });
  }
};

export const createUser = (user) => async (dispatch) => {
  dispatch({ type: USER_ACTION_IN_PROGRESS });
  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/user', { body: user });
    dispatch({ type: USER_CREATE_SUCCESS, payload: response });

    await Swal.fire('User Invited', `${user.emailAddress} will receive an invitation email with login details.`, 'success');
  } catch (error) {
    dispatch({ type: USER_CREATE_FAILURE, payload: error });
  }
};

export const editUser = (request) => async (dispatch) => {
  dispatch({ type: USER_ACTION_IN_PROGRESS });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/user/' + request.id, { body: request });
    dispatch({ type: USER_EDIT_SUCCESS, payload: response });
    await Swal.fire('User Updated', `${response.email} has been updated successfully`, 'success');
  } catch (error) {
    dispatch({ type: USER_EDIT_FAILURE, payload: error });
  }
};

export const deleteUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_ACTION_IN_PROGRESS });
    await API.del('PrivateAPI', '/api/private/portal/user/' + user.username, {});
    dispatch({ type: USER_DELETE_SUCCESS, payload: user });
    await Swal.fire('User Deleted', `${user.emailAddress} no longer has access to the portal`, 'success');
  } catch (error) {
    dispatch({ type: USER_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const sendValidateCode = (username) => async (dispatch) => {
  dispatch({ type: USER_ACTION_IN_PROGRESS });
  try {
    await API.post('PrivateAPI', `/api/private/portal/user/password/reset/${username}`, {});
    dispatch({ type: USER_SEND_VALIDATE_CODE_SUCCESS });
    await Swal.fire('Password Reset Requested', 'An email has been sent to the user with reset instructions', 'success');
  } catch (error) {
    dispatch({ type: USER_SEND_VALIDATE_CODE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    await Swal.fire('Password Reset Failed', 'Unable to request password reset. Please try again later.', 'error');
  }
};

export const resetUserPassword = (username, password) => async (dispatch) => {
  dispatch({ type: USER_ACTION_IN_PROGRESS });
  try {
    await API.post('PrivateAPI', `/api/private/portal/user/password/reset-password/${username}`, { body: password });
    dispatch({ type: USER_RESET_PASSWORD_SUCCESS });
    await Swal.fire('Password Reset Requested', 'The new temp password will be available to login', 'success');
  } catch (error) {
    dispatch({ type: USER_RESET_PASSWORD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    await Swal.fire('Password Reset Failed', 'Unable to request password reset. Please try again later.', 'error');
  }
};

export const findByFilter = (page, size, phoneNumber, emailAddress, lastName, corporationId) => async (dispatch) => {
  try {
    const pageParam = page ? `&page=${page}` : '&page=0';
    const sizeParam = size ? `&size=${size}` : '&size=10';
    const phoneParam = phoneNumber ? `&phoneNumber=${phoneNumber}` : '';
    const emailParam = emailAddress ? `&emailAddress=${emailAddress}` : '';
    const lastNameParam = lastName ? `&lastName=${lastName}` : '';
    const corporationIdParam = corporationId ? `&corporationId=${corporationId}` : '';
    const url = `/api/private/portal/user/search?${pageParam}${sizeParam}${phoneParam}${emailParam}${lastNameParam}${corporationIdParam}`;
    const response = await API.get('PrivateAPI', url, {});
    dispatch({ type: USER_GET_USERS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: USER_GET_USERS_FAILURE, payload: error });
  }
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: USER_SELECTED_ITEM, payload: item });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: USER_CLEAR_ERROR, payload: {} });
};
