import React, { useMemo, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import { CheckBoxSharp, CropSquareSharp, DeleteOutlined, FileCopyOutlined } from '@material-ui/icons';
import FoodModifierGroupActions from '../../../../redux/actions/inRoom/ordering/foodModifierGroup/actions';
import { getFilterField, getFilterUpdateMethods } from '../../../../../components/table/filter/filterUtils';

const columns = [
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: 70,
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <Box style={{ width: 60 }}>
          <IconButton
            size={'small'}
            color={'inherit'}
            onClick={() => dispatch(FoodModifierGroupActions.delete(value, `/api/private/portal/food/modifier/group/${value.id}`))}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(FoodModifierGroupActions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
        </Box>
      );
    },
  },
  {
    id: 'groupName',
    label: 'Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'externalId',
    label: 'External ID',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'minQty',
    label: 'Min',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'maxQty',
    label: 'Max',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <IconButton
          size={'small'}
          color={value.enabled ? 'primary' : 'inherit'}
          onClick={() =>
            dispatch(
              FoodModifierGroupActions.update(
                { ...value, enabled: !value.enabled },
                `/api/private/portal/food/modifier/group/${value.id}`,
                false,
                true
              )
            )
          }
        >
          {value.enabled ? <CheckBoxSharp /> : <CropSquareSharp />}
        </IconButton>
      );
    },
  },
];

export default function FoodModifierGroupList() {
  const dispatch = useDispatch();
  const modifierList = useSelector((state) => state.foodModifierGroupReducer.entityList);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const processing = useSelector((state) => state.foodModifierGroupReducer.processing);
  const page = useSelector((state) => state.foodModifierGroupReducer.page);
  const error = useSelector((state) => state.foodModifierGroupReducer.error);
  const [filterState, setFilterState] = useState({
    groupName: '',
    externalId: '',
  });

  const payload = useMemo(() => {
    let formattedPayload = { ...filterState };
    if (filterState['groupName']) {
      formattedPayload.groupName = filterState['groupName'] || '';
    }

    if (filterState['externalId']) {
      formattedPayload.externalId = filterState['externalId'] || '';
    }
    return formattedPayload;
  }, [filterState]);

  const getFilterFieldsForScreen = (state, setState) => {
    const filterUpdateMethods = getFilterUpdateMethods(state, setState);
    return [
      getFilterField('groupName', 'Group Name', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
      getFilterField('externalId', 'External Id', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    ];
  };

  return (
    <AutoTable
      title="Modifier Groups"
      subheader={'A modifier group is a collection of modifiers used to alter an item.'}
      handleRefreshReport={() => dispatch(FoodModifierGroupActions.fetch(0, 25, payload, { vendorId: vendorId }))}
      selectItem={(item) => dispatch(FoodModifierGroupActions.select(item))}
      processing={processing}
      handleChangePage={(ev, newPage, pageSize) =>
        dispatch(FoodModifierGroupActions.fetch(newPage, pageSize, payload, { vendorId: vendorId }))
      }
      filterPayload={payload}
      page={page}
      error={error}
      columns={columns}
      prefix="FOOD_MODIFIER_GROUP"
      createNewURL="/food/modifier/group/new"
      detailURL="/food/modifier/group/view/{id}"
      idColumn={'id'}
      data={modifierList}
      backLink={'/food/vendor'}
      filters={getFilterFieldsForScreen(filterState, setFilterState)}
    />
  );
}
