import React from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import NotificationSchemeActions from '../../../../redux/actions/scheme/notification/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined, FileCopyOutlined } from '@material-ui/icons';

// Table column rendering information.
const columns = [
  {
    id: 'duplicate',
    label: '',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (value, dispatch) {
      return (
        <>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(NotificationSchemeActions.duplicate(value))}>
            <FileCopyOutlined />
          </IconButton>
          <IconButton size={'small'} color={'inherit'} onClick={() => dispatch(NotificationSchemeActions.delete(value))}>
            <DeleteForeverOutlined />
          </IconButton>
        </>
      );
    },
  },
  {
    id: 'schemeName',
    label: 'Scheme Name',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'emailProvider',
    label: 'Email Provider',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'emailSender',
    label: 'Default Sender',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'smsProvider',
    label: 'SMS Provider',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'smsSender',
    label: 'Default SMS Sender',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'smsSenderAlias',
    label: 'SMS Sender Alias',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function NotificationSchemeList() {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.notificationSchemeReducer.entityList);
  const processing = useSelector((state) => state.notificationSchemeReducer.processing);
  const lastActionType = useSelector((state) => state.notificationSchemeReducer.lastActionType);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);
  const page = useSelector((state) => state.notificationSchemeReducer.page);
  const error = useSelector((state) => state.notificationSchemeReducer.error);

  const handleChangePage = (ev, newPage, pageSize) => {
    dispatch(NotificationSchemeActions.fetch(newPage, pageSize, { corporationId: selectedCorporation.id }));
  };

  return (
    <AutoTable
      title="Notification Schemes"
      subheader={'Delivery credentials used by properties to send emails and TXT messages'}
      handleRefreshReport={() => dispatch(NotificationSchemeActions.fetch(null, null, { corporationId: selectedCorporation.id }))}
      handleChangePage={(ev, newPage, pageSize) => handleChangePage(ev, newPage, pageSize)}
      page={page}
      selectItem={(item) => dispatch(NotificationSchemeActions.select(item))}
      processing={processing}
      error={error}
      clearError={() => dispatch(NotificationSchemeActions.clearError())}
      columns={columns}
      prefix="NOTIFICATION_SCHEME"
      newButtonName="Create"
      createNewURL="/scheme/notifications/new"
      detailURL="/scheme/notifications/view/{id}"
      idColumn={'id'}
      data={dataList}
      lastActionType={lastActionType}
      selectedCorporation={selectedCorporation}
    />
  );
}
