import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Activity Schedule', 'ACTIVITY_SCHEDULE');

const indexAt = (array, value) => {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    if (array[mid].from < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }

  return low;
};
const searchAndUpdate = (tree, id, leaf, isAdd = false) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].type + tree[i].id === id) {
      if (isAdd) {
        tree[i].rates?.unshift(leaf);
        tree[i].times?.splice(indexAt(tree[i].times, leaf.from), 0, leaf);
      } else {
        tree[i] = leaf;
      }
    } else if (tree[i].times) {
      searchAndUpdate(tree[i].times, id, leaf, isAdd);
    } else if (tree[i].rates) {
      searchAndUpdate(tree[i].rates, id, leaf, isAdd);
    }
  }

  return tree;
};

function activityScheduleReducer(state = initial, action) {
  switch (action.type) {
    case `ACTIVITY_SCHEDULE_UPDATE_CHILD`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: searchAndUpdate([...state.entityList], action.payload.type + action.payload.id, action.payload),
        selectedItem: action.payload,
      };
    }
    case `ACTIVITY_SCHEDULE_ADD_CHILD`: {
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: searchAndUpdate(
          [...state.entityList],
          action.payload.branch?.type + action.payload.branch?.id,
          action.payload.leaf,
          true
        ),
        selectedItem: action.payload.leaf,
      };
    }
    default:
      return base(state, action);
  }
}

export default activityScheduleReducer;
