import actionCreator from '../../../../support/actionCreator';
import Objects from '../../../../../../support/Objects';

const FoodMenuMappingActions = {
  ...actionCreator.createActions('Food Menu Mapping', 'FOOD_MENU_MAPPING', '/api/private/portal/food/menu/mapping'),
  toggle: (item) => async (dispatch) => {
    dispatch({ type: `FOOD_MENU_MAPPING_UPDATE_SUCCESS`, payload: item });
  },
  toggleError: (error) => async (dispatch) => {
    dispatch({ type: `FOOD_MENU_MAPPING_UPDATE_FAILURE`, payload: new Error(Objects.getErrorFromResponse(error)) });
  },
};

export default FoodMenuMappingActions;
