export const SpecialRequestAdditionalState = {
  NOT_ACKNOWLEDGED: 'NOT_ACKNOWLEDGED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  COMPLETED: 'COMPLETED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const SpecialRequestAdditionalStateOptions = [
  { value: SpecialRequestAdditionalState.NOT_ACKNOWLEDGED, label: 'Pending' },
  { value: SpecialRequestAdditionalState.ACKNOWLEDGED, label: 'Acknowledged' },
  { value: SpecialRequestAdditionalState.COMPLETED, label: 'Completed' },
  { value: SpecialRequestAdditionalState.NOT_APPLICABLE, label: 'Dismissed' },
];

export const SpecialRequestAdditionalStateLabel = {
  NOT_ACKNOWLEDGED: 'Pending',
  ACKNOWLEDGED: 'Acknowledged',
  COMPLETED: 'Completed',
  NOT_APPLICABLE: 'Dismissed',
  null: 'No Action',
};
