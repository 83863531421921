import { Button, Card, CardActions, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Delete } from '@material-ui/icons';
import React from 'react';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { getLanguageListForSelect } from '../../../../../support/LanguageList';

export default function LanguageOverrideCard({ existingList, addOverride, deleteOverride, onFieldChange }) {
  const languageList = getLanguageListForSelect();
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeaderWithControls
          header={'Language Translations'}
          subheader={'Have text displayed in the guests favourite language'}
          onClick={addOverride}
          buttonName={'Add Language'}
          disableBackButton
        />

        <CardContent>
          {existingList.length <= 0 && (
            <Alert severity="info" variant={'filled'}>
              Override the details of your package in different languages
            </Alert>
          )}

          <Grid container spacing={1}>
            {existingList.map((pd, idx) => (
              <Grid item xs={12} key={'desc-' + idx}>
                <Card variant={'outlined'}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Name"
                          name="name"
                          onChange={onFieldChange(idx)}
                          variant={'outlined'}
                          value={existingList[idx].name}
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl fullWidth variant={'outlined'}>
                          <InputLabel id="locale-select-label">Locale</InputLabel>
                          <Select
                            labelId="locale-select-label"
                            value={existingList[idx].locale}
                            name="locale"
                            labelWidth={50}
                            onChange={onFieldChange(idx)}
                            required
                          >
                            {languageList.map((l) => (
                              <MenuItem key={l.value} value={l.value}>
                                {l.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Description"
                          variant={'outlined'}
                          multiline
                          rows={3}
                          name="description"
                          onChange={onFieldChange(idx)}
                          value={existingList[idx].description}
                          required
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={'justify-content-end'}>
                    <Button size="small" variant={'outlined'} startIcon={<Delete />} onClick={() => deleteOverride(idx)}>
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
