const DiscountRule = {
  EVT_STAYS_SILVER: 'EVT_STAYS_SILVER',
  EVT_STAYS_GOLD: 'EVT_STAYS_GOLD',
  EVT_STAYS_PLATINUM: 'EVT_STAYS_PLATINUM',
  EVT_STAYS_BLACK: 'EVT_STAYS_BLACK',
};

const DiscountRuleOptions = [
  { value: DiscountRule.EVT_STAYS_SILVER, label: 'EVT Stays - Silver Tier' },
  { value: DiscountRule.EVT_STAYS_GOLD, label: 'EVT Stays - Gold Tier' },
  { value: DiscountRule.EVT_STAYS_PLATINUM, label: 'EVT Stays - Platinum Tier' },
  { value: DiscountRule.EVT_STAYS_BLACK, label: 'EVT Stays - Black Tier' },
];

export { DiscountRule, DiscountRuleOptions };
