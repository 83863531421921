// Hook
import { useCallback, useEffect, useRef, useState } from 'react';
import API from '@aws-amplify/api';
import Objects from '../Objects';

const DEFAULT_PAGE = {
  size: 25,
  totalElements: 0,
  totalPages: 0,
  number: 0,
};

function appendPageVariables(url, page, parameters) {
  let requestUrl = url;
  if (url.includes('?')) {
    requestUrl = `${url}&page=${page?.number || 0}&size=${page?.size || DEFAULT_PAGE.size}`;
  } else {
    requestUrl = `${url}?page=${page?.number || 0}&size=${page?.size || DEFAULT_PAGE.size}`;
  }

  if (parameters) {
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] !== '') {
        requestUrl += `&${key}=${parameters[key]}`;
      }
    });
  }
  return requestUrl;
}

export default function useFetch(url, parameters) {
  const cancelRequest = useRef(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const requestData = useCallback(
    async (fetchPage) => {
      cancelRequest.current = false;

      // Do nothing if the url is not given
      if (!url) return;

      setProcessing(true);
      try {
        const response = await API.get('PrivateAPI', appendPageVariables(url, fetchPage, parameters), {});
        if (cancelRequest.current) return;
        setData(response.hasOwnProperty('content') ? response.content : response);
        setPage(response.hasOwnProperty('pageable') ? parsePage(response) : fetchPage);
      } catch (error) {
        if (cancelRequest.current) return;
        setError(new Error(Objects.getErrorFromResponse(error)));
      } finally {
        setProcessing(false);
      }
    },
    [parameters, url]
  );

  const parsePage = (response) => {
    return { size: response.size, totalElements: response.totalElements, totalPages: response.totalPages, number: response.number };
  };

  // Store current value in ref
  useEffect(() => {
    requestData(DEFAULT_PAGE);
    // Use the cleanup function for avoiding a possibly state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, [requestData]); // Only re-run if value changes

  const updatePage = (nextPage, nextSize) => {
    requestData({ ...page, number: nextPage, size: nextSize });
  };

  // Return previous value (happens before update in useEffect above)
  return [data, processing, error, page, updatePage];
}
