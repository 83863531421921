import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import Alert from '@material-ui/lab/Alert';
import { Button, Card, Grid, Input } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import LicenseActions from '../../../redux/actions/license/actions';

export default function LicenseUpload() {
  const dispatch = useDispatch();
  const history = useHistory();

  const lastActionType = useSelector((state) => state.licenseReducer.lastActionType);
  const error = useSelector((state) => state.licenseReducer.error);

  const [uploadFile, setUploadFile] = React.useState('');

  useEffect(() => {
    if (lastActionType === 'LICENSE_UPLOAD_SUCCESS') {
      history.goBack();
    }
  }, [history, lastActionType]);

  const handleSubmit = () => {
    dispatch(LicenseActions.uploadFile(uploadFile));
  };

  const setFile = (e) => {
    if (!e.target.files) {
      return;
    }
    setUploadFile(e.target.files[0]);
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={'Upload License'}
        subheader={'Upload a license request file generated from the KMS'}
        buttonName={'Upload'}
        onClick={handleSubmit}
        disabled={!uploadFile}
        deleteDisabled={true}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'}>
            {`Unable to add license - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button variant={'outlined'} component={'label'}>
              <Input type={'file'} hidden={true} onChange={setFile} />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
