import Objects from './Objects';

const TimeZones = [
  {
    value: 'Etc/GMT+12',
    label: `(GMT ${Objects.getOffsetToHour('Etc/GMT+12')}) Eniwetok, Kwajalein`,
    offset: Objects.getOffset('Etc/GMT+12'),
  },
  {
    value: 'Pacific/Midway',
    label: `(GMT ${Objects.getOffsetToHour('Pacific/Midway')}) Midway Island, Samoa`,
    offset: Objects.getOffset('Pacific/Midway'),
  },
  {
    value: 'US/Hawaii',
    label: `(GMT ${Objects.getOffsetToHour('US/Hawaii')}) Hawaii`,
    offset: Objects.getOffset('US/Hawaii'),
  },
  {
    value: 'US/Alaska',
    label: `(GMT ${Objects.getOffsetToHour('US/Alaska')}) Alaska`,
    offset: Objects.getOffset('US/Alaska'),
  },
  {
    value: 'Canada/Pacific',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Pacific')}) Pacific Time (US & Canada)`,
    offset: Objects.getOffset('Canada/Pacific'),
  },
  {
    value: 'Canada/Mountain',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Mountain')}) Mountain Time (US & Canada)`,
    offset: Objects.getOffset('Canada/Mountain'),
  },
  {
    value: 'Canada/Central',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Central')}) Central Time (US & Canada), Mexico City`,
    offset: Objects.getOffset('Canada/Central'),
  },
  {
    value: 'Canada/Eastern',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Eastern')}) Eastern Time (US & Canada), Bogota, Lima`,
    offset: Objects.getOffset('Canada/Eastern'),
  },
  {
    value: 'Canada/Atlantic',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Atlantic')}) Atlantic Time (Canada), Caracas, La Paz`,
    offset: Objects.getOffset('Canada/Atlantic'),
  },
  {
    value: 'Canada/Newfoundland',
    label: `(GMT ${Objects.getOffsetToHour('Canada/Newfoundland')}) Newfoundland`,
    offset: Objects.getOffset('Canada/Newfoundland'),
  },
  {
    value: 'Brazil/East',
    label: `(GMT ${Objects.getOffsetToHour('Brazil/East')}) Brazil, Buenos Aires, Georgetown`,
    offset: Objects.getOffset('Brazil/East'),
  },
  {
    value: 'Atlantic/South_Georgia',
    label: `(GMT ${Objects.getOffsetToHour('Atlantic/South_Georgia')}) Mid-Atlantic`,
    offset: Objects.getOffset('Atlantic/South_Georgia'),
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: `(GMT ${Objects.getOffsetToHour('Atlantic/Cape_Verde')}) Azores, Cape Verde Islands`,
    offset: Objects.getOffset('Atlantic/Cape_Verde'),
  },
  {
    value: 'Europe/London',
    label: `(GMT) Western Europe Time, London, Lisbon, Casablanca`,
    offset: Objects.getOffset('Europe/London'),
  },
  {
    value: 'Europe/Brussels',
    label: `(GMT ${Objects.getOffsetToHour('Europe/Brussels')}) Brussels, Copenhagen, Madrid, Paris`,
    offset: Objects.getOffset('Europe/Brussels'),
  },
  {
    value: 'Africa/Johannesburg',
    label: `(GMT ${Objects.getOffsetToHour('Africa/Johannesburg')}) Kaliningrad, South Africa`,
    offset: Objects.getOffset('Africa/Johannesburg'),
  },
  {
    value: 'Asia/Baghdad',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Baghdad')}) Baghdad, Riyadh, St. Petersburg`,
    offset: Objects.getOffset('Asia/Baghdad'),
  },
  {
    value: 'Asia/Tehran',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Tehran')}) Tehran`,
    offset: Objects.getOffset('Asia/Tehran'),
  },
  {
    value: 'Europe/Moscow',
    label: `(GMT ${Objects.getOffsetToHour('Europe/Moscow')}) Abu Dhabi, Moscow, Baku, Tbilisi`,
    offset: Objects.getOffset('Europe/Moscow'),
  },
  {
    value: 'Asia/Kabul',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Kabul')}) Kabul`,
    offset: Objects.getOffset('Asia/Kabul'),
  },
  {
    value: 'Asia/Karachi',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Karachi')}) Ekaterinburg, Islamabad, Karachi, Tashkent`,
    offset: Objects.getOffset('Asia/Karachi'),
  },
  {
    value: 'Asia/Calcutta',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Calcutta')}) Bombay, Calcutta, Madras, New Delhi`,
    offset: Objects.getOffset('Asia/Calcutta'),
  },
  {
    value: 'Asia/Kathmandu',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Kathmandu')}) Kathmandu`,
    offset: Objects.getOffset('Asia/Kathmandu'),
  },
  {
    value: 'Asia/Dhaka',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Dhaka')}) Almaty, Dhaka`,
    offset: Objects.getOffset('Asia/Dhaka'),
  },
  {
    value: 'Asia/Bangkok',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Bangkok')}) Bangkok, Hanoi, Jakarta`,
    offset: Objects.getOffset('Asia/Bangkok'),
  },
  {
    value: 'Asia/Singapore',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Singapore')}) Beijing, Singapore, Hong Kong`,
    offset: Objects.getOffset('Asia/Singapore'),
  },
  {
    value: 'Australia/Perth',
    label: `(GMT ${Objects.getOffsetToHour('Australia/Perth')}) Australia Perth`,
    offset: Objects.getOffset('Australia/Perth'),
  },
  {
    value: 'Asia/Tokyo',
    label: `(GMT ${Objects.getOffsetToHour('Asia/Tokyo')}) Tokyo, Seoul, Osaka, Sapporo, Yakutsk`,
    offset: Objects.getOffset('Asia/Tokyo'),
  },
  {
    value: 'Australia/Adelaide',
    label: `(GMT ${Objects.getOffsetToHour('Australia/Adelaide')}) Adelaide, Darwin`,
    offset: Objects.getOffset('Australia/Adelaide'),
  },
  {
    value: 'Australia/Brisbane',
    label: `(GMT ${Objects.getOffsetToHour('Australia/Brisbane')}) Australia Brisbane`,
    offset: Objects.getOffset('Australia/Brisbane'),
  },
  {
    value: 'Australia/Melbourne',
    label: `(GMT ${Objects.getOffsetToHour('Australia/Melbourne')}) Australia Melbourne`,
    offset: Objects.getOffset('Australia/Melbourne'),
  },
  {
    value: 'Australia/Sydney',
    label: `(GMT ${Objects.getOffsetToHour('Australia/Sydney')}) Australia Sydney`,
    offset: Objects.getOffset('Australia/Sydney'),
  },
  {
    value: 'Pacific/Auckland',
    label: `(GMT ${Objects.getOffsetToHour('Pacific/Auckland')}) Auckland, Wellington, Fiji, Kamchatka`,
    offset: Objects.getOffset('Pacific/Auckland'),
  },
];

export default TimeZones;
