import actionCreator from '../../../../support/actionCreator';
import Objects from '../../../../../../support/Objects';
import Swal from 'sweetalert2';
import API from '@aws-amplify/api';
import { FoodMenuTypes } from '../../../../../../support/FoodMenuTypes';

const FoodMenuActions = {
  ...actionCreator.createActions('Food Menu', 'FOOD_MENU', '/api/private/portal/food/vendor/{vendorId}/menu'),
  duplicate: (item) => async (dispatch) => {
    Objects.notNull(item, 'item cannot be null');

    Swal.fire({
      title: 'Duplicate Menu',
      html:
        `<input id="fmt-name" class="swal2-input" value="${item.name} - Copy">` +
        '<select id="fmt-type" class="swal2-input">' +
        FoodMenuTypes.map((fmt) => `<option value="${fmt.value}">${fmt.label}</option>`) +
        '</select>  ',
      showCancelButton: true,
      preConfirm: () => {
        if (!document.getElementById('fmt-name').value || !document.getElementById('fmt-type').value) {
          Swal.showValidationMessage(`Name and Type required`);
          return false;
        }
        return { name: document.getElementById('fmt-name').value, type: document.getElementById('fmt-type').value };
      },
    }).then(async function (result) {
      if (result.isConfirmed) {
        dispatch({ type: `FOOD_MENU_ADD_IN_PROGRESS` });
        try {
          const response = await API.post(
            'PrivateAPI',
            `/api/private/portal/food/menu/${item.id}/duplicate?name=${result.value.name}&type=${result.value.type}`,
            {}
          );
          dispatch({ type: `FOOD_MENU_ADD_SUCCESS`, payload: response });
        } catch (error) {
          dispatch({
            type: `FOOD_MENU_ADD_FAILURE`,
            payload: new Error(Objects.getErrorFromResponse(error)),
          });
        }
      }
    });
  },
};

export default FoodMenuActions;
