const ReservationStatus = {
  UNCONFIRMED: 'UNCONFIRMED',
  DUE_IN: 'DUE_IN',
  CHECKED_IN: 'CHECKED_IN',
  CHECKED_OUT: 'CHECKED_OUT',
  CHECK_IN_REQUESTED: 'CHECK_IN_REQUESTED',
};

const ReservationStatusOptions = [
  { label: 'Unconfirmed', value: ReservationStatus.UNCONFIRMED },
  { label: 'Due In', value: ReservationStatus.DUE_IN },
  { label: 'Checked In', value: ReservationStatus.CHECKED_IN },
  { label: 'Checked Out', value: ReservationStatus.CHECKED_OUT },
];

export default ReservationStatus;
export { ReservationStatusOptions };
