import reducerCreator from '../../support/reducerCreator';
import {
  LICENSE_DOWNLOAD_FAILURE,
  LICENSE_DOWNLOAD_IN_PROGRESS,
  LICENSE_DOWNLOAD_SUCCESS,
  LICENSE_UPLOAD_FAILURE,
  LICENSE_UPLOAD_IN_PROGRESS,
  LICENSE_UPLOAD_SUCCESS,
} from '../../actions/license/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('License', 'LICENSE');

function licenseReducer(state = initial, action) {
  switch (action.type) {
    case LICENSE_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case LICENSE_UPLOAD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList].filter((e) => e.id !== action.payload.id),
      };
    case LICENSE_UPLOAD_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case LICENSE_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case LICENSE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [...state.entityList].filter((e) => e.id !== action.payload.id),
      };
    case LICENSE_DOWNLOAD_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default licenseReducer;
