import React from 'react';
import AutoTable from '../../../../components/table/AutoTable';
import Actions from '../../../redux/actions/app/actions';
import * as DataTypes from '../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';

// Table column rendering information.
const columns = [
  {
    id: 'name',
    label: 'App Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'code',
    label: 'App Code',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'appleStoreUrl',
    label: 'App Store URL',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'androidStoreUrl',
    label: 'Play Store URL',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'bookingEnabled',
    label: 'Booking',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
  {
    id: 'socialLogin',
    label: 'Social Login',
    align: 'center',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

export default function AppList() {
  const dispatch = useDispatch();
  const appList = useSelector((state) => state.appReducer.entityList);
  const lastActionType = useSelector((state) => state.appReducer.lastActionType);
  const page = useSelector((state) => state.appReducer.page);
  const processing = useSelector((state) => state.appReducer.processing);
  const error = useSelector((state) => state.appReducer.error);

  return (
    <AutoTable
      title="App Brands"
      subheader={'Each mobile app can have a unique brand and app store presence'}
      handleRefreshReport={() => dispatch(Actions.fetch())}
      selectItem={(item) => dispatch(Actions.select(item))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, null))}
      processing={processing}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="APP"
      buttonName="New App"
      createNewURL="/apps/new"
      detailURL="/apps/view/{id}"
      idColumn={'id'}
      data={appList}
      lastActionType={lastActionType}
    />
  );
}
