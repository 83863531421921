import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Actions from '../../../redux/actions/app/theme/actions';
import { Card, CardContent, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import toBase64 from '../../../../support/toBase64Converter';
import FileImageSelector from '../../../../components/file/FileImageSelector';
import { makeStyles } from '@material-ui/core/styles';
import initialBackgroundImage from '../../../../assets/img/sample_bg.png';
import loginImage from '../../../../assets/img/liverton-icon-white.png';
import { MenuOutlined } from '@material-ui/icons';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';

const useStyles = makeStyles(() => ({
  flexContainerMainScr: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '250px',
    height: '500px',
    padding: '5px',
  },
  addIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  flexContainerLoginScr: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    width: '250px',
    height: '500px',
    padding: '5px',
  },
  inputField: {
    borderRadius: '5px',
    border: '2px solid grey',
    paddingBottom: '27px',
    paddingLeft: '5px',
    width: '200px',
    height: '40px',
    marginTop: '10px',
  },
  loginField: {
    borderRadius: '5px',
    border: '2px solid grey',
    width: '200px',
    height: '40px',
    marginTop: '10px',
    textAlign: 'center',
  },
  appLogo: {
    width: '150px',
    maxHeight: '75px',
    objectFit: 'contain',
  },
}));

export default function AppTheme() {
  const theme = useTheme();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.appThemeReducer.error);
  const lastActionType = useSelector((state) => state.appThemeReducer.lastActionType);
  const processing = useSelector((state) => state.appThemeReducer.processing);
  const selectedItem = useSelector((state) => state.appThemeReducer.data);
  const selectedApp = useSelector((state) => state.appReducer.selectedItem);
  const selectedAppId = selectedApp?.id;

  const [primary, setPrimary] = React.useState(theme.palette.primary.main);
  const [secondary, setSecondary] = React.useState(theme.palette.secondary.main);
  const [tertiary, setTertiary] = React.useState('#7d5260');
  const [success, setSuccess] = React.useState(theme.palette.success.main);
  const [warning, setWarning] = React.useState(theme.palette.warning.main);
  const [danger, setDanger] = React.useState(theme.palette.error.main);
  const [neutral, setNeutral] = React.useState('#605d62');
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [backgroundImageInBase64, setBackgroundImageInBase64] = React.useState(null);
  const [backgroundLogo, setBackgroundLogo] = React.useState(null);
  const [backgroundLogoInBase64, setBackgroundLogoInBase64] = React.useState(null);

  useEffect(() => {
    dispatch(Actions.clearError());
    return () => {
      dispatch(Actions.clearLastAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedAppId) {
      dispatch(Actions.fetch(0, 10, { appId: selectedApp.id }));
    } else {
      history.goBack();
    }
  }, [dispatch, history, selectedApp.id, selectedAppId]);

  useEffect(() => {
    if (lastActionType === 'APP_THEME_FETCH_SUCCESS') {
      if (selectedItem) {
        setPrimary(selectedItem.primary);
        setSecondary(selectedItem.secondary);
        setTertiary(selectedItem.tertiary);
        setSuccess(selectedItem.success);
        setWarning(selectedItem.warning);
        setDanger(selectedItem.danger);
        setNeutral(selectedItem.neutral);
      }
    }
  }, [lastActionType, selectedItem]);

  useEffect(() => {
    if (lastActionType === 'APP_THEME_UPDATE_SUCCESS') {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  useEffect(() => {
    if (backgroundImage) {
      toBase64(backgroundImage).then((base64) => {
        setBackgroundImageInBase64(base64);
      });
    }
  }, [backgroundImage]);

  useEffect(() => {
    if (backgroundLogo) {
      toBase64(backgroundLogo).then((base64) => {
        setBackgroundLogoInBase64(base64);
      });
    }
  }, [backgroundLogo]);

  const handleSubmit = async () => {
    const backgroundImageToSend = await toBase64(backgroundImage);
    const backgroundLogoToSend = await toBase64(backgroundLogo);

    const payload = {
      id: selectedItem ? selectedItem.id : null,
      primary: primary,
      secondary: secondary,
      tertiary: tertiary,
      success: success,
      warning: warning,
      danger: danger,
      neutral: neutral,
      appId: selectedApp.id,
      backgroundImage: backgroundImageToSend,
      backgroundImageName: backgroundImage ? backgroundImage.name : '',
      backgroundLogo: backgroundLogoToSend,
      backgroundLogoName: backgroundLogo ? backgroundLogo.name : '',
    };

    if (selectedItem) {
      dispatch(Actions.update(payload, '/api/private/portal/appTheme/update'));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={`${selectedApp?.name} Theme`}
        subheader={'Customise the look and feel shown to users'}
        buttonName={'Update'}
        onClick={handleSubmit}
        disabled={processing}
      />

      {error && lastActionType !== 'APP_THEME_FETCH_FAILURE' && (
        <Alert severity="error" variant={'filled'}>
          {`An error occurred - ${error.message}`}
        </Alert>
      )}

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="primary"
              type="color"
              label="Primary Color"
              variant={'outlined'}
              onChange={(event) => setPrimary(event.target.value)}
              value={primary}
              error={!primary}
              helperText={primary ? 'This is your primary theme colour' : 'Please select a primary colour'}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="secondary"
              type="color"
              label="Secondary Color"
              variant={'outlined'}
              onChange={(event) => setSecondary(event.target.value)}
              value={secondary}
              error={!secondary}
              helperText={secondary ? 'This is your secondary theme colour' : 'Please select a secondary colour'}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="tertiary"
              type="color"
              label="Tertiary Color"
              variant={'outlined'}
              onChange={(event) => setTertiary(event.target.value)}
              value={tertiary}
              error={!tertiary}
              helperText={tertiary ? 'This is your tertiary theme colour' : 'Please select a tertiary colour'}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="success"
              label="Success Color"
              type="color"
              variant={'outlined'}
              onChange={(event) => setSuccess(event.target.value)}
              value={success}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="warning"
              type="color"
              label="Warning Color"
              variant={'outlined'}
              onChange={(event) => setWarning(event.target.value)}
              value={warning}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="danger"
              label="Danger Color"
              type="color"
              variant={'outlined'}
              onChange={(event) => setDanger(event.target.value)}
              value={danger}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              id="neutral"
              label="Neutral Color"
              type="color"
              variant={'outlined'}
              onChange={(event) => setNeutral(event.target.value)}
              value={neutral}
              required
            />
          </Grid>

          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'backgroundImageUrl'}
            displayName={'Background Image'}
            onSelect={(file) => setBackgroundImage(file)}
          />

          <FileImageSelector
            sourceItem={selectedItem}
            sourceItemProperty={'backgroundLogoUrl'}
            displayName={'Login Screen Logo'}
            onSelect={(file) => setBackgroundLogo(file)}
          />

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="body1">Home Screen:</Typography>

            {selectedItem ? (
              backgroundImage ? (
                <div
                  className={styles.flexContainerMainScr}
                  style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${backgroundImageInBase64})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div style={{ alignSelf: 'flex-start', color: 'white' }}>
                    <MenuOutlined />
                  </div>
                  <div style={{ color: 'white' }}>
                    <p style={{ color: primary, display: 'inline' }}> &emsp; Hi</p> There <br /> Where to next?
                  </div>
                  <div
                    style={{ alignSelf: 'flex-end', color: 'white', textAlign: 'center', background: secondary }}
                    className={styles.addIcon}
                  >
                    <div style={{ display: 'inline-block', marginTop: '4px' }}> +</div>
                  </div>
                </div>
              ) : selectedItem.backgroundImageUrl ? (
                <div
                  className={styles.flexContainerMainScr}
                  style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${selectedItem.backgroundImageUrl})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div style={{ alignSelf: 'flex-start', color: 'white' }}>
                    <MenuOutlined />
                  </div>
                  <div style={{ color: 'white' }}>
                    <p style={{ color: primary, display: 'inline' }}> &emsp; Hi</p> There <br /> Where to next?
                  </div>
                  <div
                    style={{ alignSelf: 'flex-end', color: 'white', textAlign: 'center', background: secondary }}
                    className={styles.addIcon}
                  >
                    <div style={{ display: 'inline-block', marginTop: '4px' }}> +</div>
                  </div>
                </div>
              ) : (
                <div
                  className={styles.flexContainerMainScr}
                  style={{
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${initialBackgroundImage})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div style={{ alignSelf: 'flex-start', color: 'white' }}>
                    <MenuOutlined />
                  </div>
                  <div style={{ color: 'white' }}>
                    <p style={{ color: primary, display: 'inline' }}> &emsp; Hi</p> There <br /> Where to next?
                  </div>
                  <div
                    style={{ alignSelf: 'flex-end', color: 'white', textAlign: 'center', background: secondary }}
                    className={styles.addIcon}
                  >
                    <div style={{ display: 'inline-block', marginTop: '4px' }}> +</div>
                  </div>
                </div>
              )
            ) : (
              <div
                className={styles.flexContainerMainScr}
                style={{
                  backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${initialBackgroundImage})`,
                  backgroundSize: 'cover',
                }}
              >
                <div style={{ alignSelf: 'flex-start', color: 'white' }}>
                  <MenuOutlined />
                </div>
                <div style={{ color: 'white' }}>
                  <p style={{ color: primary, display: 'inline' }}> &emsp; Hi</p> There <br /> Where to next?
                </div>
                <div
                  style={{ alignSelf: 'flex-end', color: 'white', textAlign: 'center', background: secondary }}
                  className={styles.addIcon}
                >
                  <div style={{ display: 'inline-block', marginTop: '4px' }}> +</div>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="body1">Login Screen:</Typography>
            {selectedItem ? (
              backgroundLogo ? (
                <LoginPageSample primary={primary} image={backgroundLogoInBase64} styles={styles} />
              ) : (
                <LoginPageSample primary={primary} image={selectedItem.backgroundLogoUrl || loginImage} styles={styles} />
              )
            ) : (
              <LoginPageSample primary={primary} image={loginImage} styles={styles} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function LoginPageSample({ primary, image, styles }) {
  return (
    <div className={styles.flexContainerLoginScr}>
      <div>
        <img src={image} alt="login-screen" className={styles.appLogo} />
      </div>
      <div style={{ color: 'white' }}>Welcome to SmartCheck Mobile</div>
      <div style={{ color: 'grey' }}>Login to get started</div>
      <div className={styles.inputField}>
        <p style={{ color: 'grey' }}>Email </p>
      </div>
      <div className={styles.inputField}>
        <p style={{ color: 'grey' }}>Password</p>{' '}
      </div>
      <div style={{ alignSelf: 'flex-end', color: primary, marginTop: '10px', marginRight: '20px' }}>Forgot Password?</div>
      <div className={styles.loginField}>
        <p style={{ color: 'grey', display: 'inline-block', marginBlockStart: '7px' }}>LOGIN</p>{' '}
      </div>
      <div style={{ color: 'white' }}>OR</div>
      <div className={styles.loginField} style={{ marginTop: '0px' }}>
        <p style={{ color: 'white', display: 'inline-block', marginBlockStart: '7px' }}>FACEBOOK LOGIN</p>
      </div>
      <div style={{ color: 'white', marginTop: '5px' }}>
        Don't have an account? <p style={{ color: primary, display: 'inline' }}>Sign up</p>
      </div>
    </div>
  );
}
