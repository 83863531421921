import actionCreator from '../../../support/actionCreator';
import { API } from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

export const SYSTEM_CALENDAR_EVENT_UPDATE_IN_PROGRESS = 'SYSTEM_CALENDAR_EVENT_UPDATE_IN_PROGRESS';
export const SYSTEM_CALENDAR_EVENT_UPDATE_SUCCESS = 'SYSTEM_CALENDAR_EVENT_UPDATE_SUCCESS';
export const SYSTEM_CALENDAR_EVENT_UPDATE_FAILURE = 'SYSTEM_CALENDAR_EVENT_UPDATE_FAILURE';

export const SYSTEM_CALENDAR_EVENT_FETCH_VIEW_IN_PROGRESS = 'SYSTEM_CALENDAR_EVENT_FETCH_VIEW_IN_PROGRESS';
export const SYSTEM_CALENDAR_EVENT_FETCH_VIEW_SUCCESS = 'SYSTEM_CALENDAR_EVENT_FETCH_VIEW_SUCCESS';
export const SYSTEM_CALENDAR_EVENT_FETCH_VIEW_FAILURE = 'SYSTEM_CALENDAR_EVENT_FETCH_VIEW_FAILURE';

const SystemCalendarEventActions = {
  ...actionCreator.createActions('System Calendar Event', 'SYSTEM_CALENDAR_EVENT', `/api/private/portal/system/calendar/event{path}`),
  fetch: (corpId, siteId, from, to, page, size, timezone) => async (dispatch) => {
    dispatch({ type: `SYSTEM_CALENDAR_EVENT_FETCH_IN_PROGRESS` });
    let path = corpId ? `/corp/${corpId}` : '';
    path = siteId ? path + `/site/${siteId}` : path;

    try {
      const response = await API.get(
        'PrivateAPI',
        `/api/private/portal/system/calendar/event${path}?page=${page}&size=${size}&from=${from.format('YYYY-MM-DD')}&to=${to.format(
          'YYYY-MM-DD'
        )}&timezone=${timezone}`,
        {}
      );
      dispatch({
        type: 'SYSTEM_CALENDAR_EVENT_FETCH_SUCCESS',
        payload: {
          ...response,
          page: {
            number: response.number,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: 'SYSTEM_CALENDAR_EVENT_FETCH_FAILURE',
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
  fetchCalendar: (corpId, siteId, dateRange, timezone) => async (dispatch) => {
    dispatch({ type: `SYSTEM_CALENDAR_EVENT_FETCH_VIEW_IN_PROGRESS` });
    if (dateRange) {
      let path = corpId ? `/corp/${corpId}` : '';
      path = siteId ? path + `/site/${siteId}` : path;

      const queryParams = {
        from: dateRange.start,
        to: dateRange.end,
        timezone: timezone,
      };

      try {
        const response = await API.get('PrivateAPI', `/api/private/portal/system/calendar/view${path}`, {
          queryStringParameters: { ...queryParams },
        });
        dispatch({
          type: 'SYSTEM_CALENDAR_EVENT_FETCH_VIEW_SUCCESS',
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: 'SYSTEM_CALENDAR_EVENT_FETCH_VIEW_FAILURE',
          payload: new Error(Objects.getErrorFromResponse(error)),
        });
      }
    }
  },
  fetchToday: (corpId, siteId, timezone) => async (dispatch) => {
    dispatch({ type: `SYSTEM_CALENDAR_EVENT_FETCH_TODAY_IN_PROGRESS` });
    let path = corpId ? `/corp/${corpId}` : '';
    path = siteId ? path + `/site/${siteId}` : path;

    const queryParams = {
      from: moment().startOf('day').format(DATE_FORMAT),
      to: moment().endOf('day').format(DATE_FORMAT),
      timezone: timezone,
    };

    try {
      const response = await API.get('PrivateAPI', `/api/private/portal/system/calendar/view${path}`, {
        queryStringParameters: { ...queryParams },
      });
      dispatch({
        type: 'SYSTEM_CALENDAR_EVENT_FETCH_TODAY_SUCCESS',
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: 'SYSTEM_CALENDAR_EVENT_FETCH_TODAY_FAILURE',
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};

export default SystemCalendarEventActions;
