import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationTemplateType } from './NotificationTemplateType';
import EmailNotificationTemplate from './EmailNotificationTemplate';
import { useParams } from 'react-router-dom';
import TaggedNotificationTemplate from './TaggedNotificationTemplate';
import {
  ActivityBookingNotificationTemplateTags,
  SmsNotificationTemplateTags,
  UpsellNotificationTemplateTags,
  VendorNotificationTemplateTags,
} from './NotificationTemplateTags';

export default function NotificationTemplateChoice() {
  const selectedItem = useSelector((state) => state.notificationTemplateReducer.selectedItem);
  const { templateType } = useParams();
  const selection = templateType ?? selectedItem?.type;

  if (selection === NotificationTemplateType.EMAIL) {
    return <EmailNotificationTemplate />;
  } else if (selection === NotificationTemplateType.SMS) {
    return (
      <TaggedNotificationTemplate
        type={NotificationTemplateType.SMS}
        subheader={'Configured template will be used to brand text messages sent to customers'}
        tags={SmsNotificationTemplateTags}
        hasReminder={true}
      />
    );
  } else if (selection === NotificationTemplateType.UPSELL) {
    return (
      <TaggedNotificationTemplate
        type={NotificationTemplateType.UPSELL}
        tags={UpsellNotificationTemplateTags}
        subheader={'Configured template will be used to notify the hotel of purchased upsells'}
        isEmail
      />
    );
  } else if (selection === NotificationTemplateType.VENDOR) {
    return (
      <TaggedNotificationTemplate
        type={NotificationTemplateType.VENDOR}
        tags={VendorNotificationTemplateTags}
        subheader={'Configured template will be used to notify the Vendor of booking requests'}
        isEmail
      />
    );
  } else if (selection === NotificationTemplateType.ACTIVITY_BOOKING) {
    return (
      <TaggedNotificationTemplate
        type={NotificationTemplateType.ACTIVITY_BOOKING}
        tags={ActivityBookingNotificationTemplateTags}
        subheader={'Configured template will be used to notify the Guest of booking response'}
        isEmail
      />
    );
  }

  return null;
}
