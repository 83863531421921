import * as Actions from '../../actions/system/actions';

const initial = {
  lastActionType: 'NONE',
  data: [],
  availableSystemMap: {},
  menuCodes: [],
  error: null,
  selectedItem: null,
};

export default function siteResourcesReducer(state = initial, action) {
  switch (action.type) {
    case Actions.SITE_SYSTEM_CLEAR_ERROR:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_SYSTEM_FETCH_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: action.payload,
        error: null,
      };
    case Actions.SITE_SYSTEM_FETCH_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_SYSTEM_CREATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_SYSTEM_CREATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_SYSTEM_FETCH_AVAILABLE_SUCCESS:
      return {
        ...state,
        availableSystemMap: action.payload,
        lastActionType: action.type,
      };
    case Actions.SITE_SYSTEM_FIND_POS_MENUS_FAILURE:
    case Actions.SITE_SYSTEM_FETCH_AVAILABLE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_SYSTEM_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        data: state.data.filter((item) => item.id !== action.payload.id),
        error: null,
      };
    case Actions.SITE_SYSTEM_DELETE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_SYSTEM_UPDATE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.SITE_SYSTEM_UPDATE_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.SITE_SYSTEM_SELECT:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: action.payload,
      };
    case Actions.SITE_SYSTEM_FIND_POS_MENUS_SUCCESS:
      return {
        ...state,
        menuCodes: action.payload,
        lastActionType: action.type,
      };
    default:
      return state;
  }
}
