import reducerCreator from '../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Reservation', 'HOTEL_RESERVATION');

function hotelReservationReducer(state = initial, action) {
  switch (action.type) {
    case 'HOTEL_RESERVATION_UNSELECT':
      return {
        ...state,
        selectedItem: null,
      };

    default:
      return base(state, action);
  }
}

export default hotelReservationReducer;
