import React from 'react';
import { Box } from '@material-ui/core';
import IconWidget from './IconWidget';
import './widget.scss';
import {
  AttachMoneyOutlined,
  BarChartOutlined,
  Email,
  EmailTwoTone,
  LibraryAddCheck,
  LinkSharp,
  NotificationsOff,
  QueryBuilderOutlined,
  VpnKeyOutlined,
  Category,
} from '@material-ui/icons';
import SiteFeature from '../../../../../../support/SiteFeature';
import DashboardSection from '../../../../../../support/DashboardSection';

function doesSiteHaveFeature(site, feature) {
  return site && site?.featureList?.filter((sf) => sf.code === feature).length > 0;
}

export default function SiteDashboardWidget({ widgetData, site, section }) {
  switch (section) {
    case DashboardSection.ROOM_KEY:
      return (
        <Box mb={2}>
          <Box className={'widget-container'}>
            <IconWidget
              title="Total Issued Keys"
              message={widgetData ? widgetData.issuedKeys : '0'}
              footer="Total Number Of Issued Keys For The Site"
              icon={<VpnKeyOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Total Active Keys"
              message={widgetData ? widgetData.currentActiveMobileKeys : '0'}
              footer="Currently Active Keys For The Site"
              icon={<QueryBuilderOutlined fontSize={'large'} />}
            />
          </Box>
        </Box>
      );
    case DashboardSection.BOOKING:
      return;
    case DashboardSection.FINANCIALS:
      return (
        <Box mb={2}>
          <Box className="widget-container">
            <IconWidget
              title="Total Revenue"
              message={widgetData ? widgetData.totalRevenue : '$0.00'}
              footer="Total Revenue For The Site"
              icon={<AttachMoneyOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Number of Purchases"
              message={widgetData ? widgetData.numberOfPurchases : '0'}
              footer="Total Number Of Purchases For The Site"
              icon={<BarChartOutlined fontSize={'large'} />}
            />
          </Box>
        </Box>
      );
    case DashboardSection.WEB_PORTAL:
      return (
        <Box mb={2}>
          <Box className="widget-container">
            {doesSiteHaveFeature(site, SiteFeature.CHECK_IN) && (
              <React.Fragment>
                <IconWidget
                  title="Reservations Notified (In)"
                  message={widgetData ? widgetData.checkInReservationsNotified : '0'}
                  footer="Unique Res Notified for Check-In"
                  icon={<Email fontSize={'large'} />}
                />
                <IconWidget
                  title="Completed Online Check-In"
                  message={widgetData ? widgetData.onlineCheckInCompleted : '0'}
                  footer="Completed online check-in"
                  icon={<LibraryAddCheck fontSize={'large'} />}
                />
              </React.Fragment>
            )}
            {doesSiteHaveFeature(site, SiteFeature.CHECK_OUT) && (
              <React.Fragment>
                <IconWidget
                  title="Reservations Notified (Out)"
                  message={widgetData ? widgetData.checkOutReservationsNotified : '0'}
                  footer="Unique Res Notified for Check-Out"
                  icon={<EmailTwoTone fontSize={'large'} />}
                />
                <IconWidget
                  title="Completed Online Check-Out"
                  message={widgetData ? widgetData.onlineCheckOutCompleted : '0'}
                  footer="Completed online check-out"
                  icon={<LibraryAddCheck fontSize={'large'} />}
                />
              </React.Fragment>
            )}
            {(doesSiteHaveFeature(site, SiteFeature.CHECK_OUT) || doesSiteHaveFeature(site, SiteFeature.CHECK_IN)) && (
              <React.Fragment>
                <IconWidget
                  title="Popular Link Choice"
                  message={widgetData ? widgetData.popularLinkType : 'N/A'}
                  footer="Most popular type of link the customer is clicking."
                  icon={<LinkSharp fontSize={'large'} />}
                />
                <IconWidget
                  title="Unsubscribed (Notifications)"
                  message={widgetData ? widgetData.optedOut : '0/0'}
                  footer="TOTAL unsubscribed EMAIL + SMS notifications to date"
                  icon={<NotificationsOff fontSize={'large'} />}
                />
              </React.Fragment>
            )}
          </Box>
        </Box>
      );
    case DashboardSection.FOOD_AND_BEVERAGE:
      return (
        <Box mb={2}>
          <Box className="widget-container">
            <IconWidget
              title="Total Revenue"
              message={widgetData ? widgetData.totalRevenue : '$0.00'}
              footer="Total Revenue For The Site"
              icon={<AttachMoneyOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Total Covers"
              message={widgetData ? widgetData.totalCovers : '0'}
              footer="Total Covers For The Site"
              icon={<BarChartOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Average Check"
              message={widgetData ? widgetData.averageCheckPerCover : '$0.00'}
              footer="Average Check per Cover"
              icon={<AttachMoneyOutlined fontSize={'large'} />}
            />
          </Box>
        </Box>
      );
    case DashboardSection.GUEST_SERVICE:
      return (
        <Box mb={2}>
          <Box className="widget-container">
            <IconWidget
              title="Total Requests"
              message={widgetData ? widgetData.totalRequests : '0'}
              footer="Total Requests For The Site"
              icon={<BarChartOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Total Escalations"
              message={widgetData ? widgetData.totalEscalations : '0'}
              footer="Total Escalations For The Site"
              icon={<BarChartOutlined fontSize={'large'} />}
            />
            <IconWidget
              title="Top Request Category"
              message={widgetData?.topRequestCategory.length > 0 ? widgetData.topRequestCategory.toString() : '--'}
              footer="Top Request Category For The Site"
              icon={<Category fontSize={'large'} />}
            />
            <IconWidget
              title="Top Request Subcategory"
              message={widgetData?.topRequestSubcategory.length > 0 ? widgetData.topRequestSubcategory.toString() : '--'}
              footer="Top Request Subcategory For The Site"
              icon={<Category fontSize={'large'} />}
            />
          </Box>
        </Box>
      );
    default:
      return <></>;
  }
}
