import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fade, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as AccessActions from '../../redux/actions/access/actions';
import Hidden from '@material-ui/core/Hidden';
import { LocationCity } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  outer: {
    margin: '0 0.5rem',
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  listItemText: {
    color: theme.palette.common.white,
  },
}));

export default function CorporationSelector() {
  const selectorStyles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const organisationList = useSelector((state) => state.accessReducer.corporationList);
  const siteList = useSelector((state) => state.accessReducer.siteList);
  const selectedCorporation = useSelector((state) => state.accessReducer.selectedCorporation);

  const organisationListFiltered = useMemo(() => {
    return organisationList.sort((o1, o2) => o1.name.localeCompare(o2.name));
  }, [organisationList]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getSelectedSite = (corporation) => {
    if (siteList) {
      const filteredSiteList = siteList.filter((site) => site.corporationId === corporation.id);
      if (filteredSiteList.length > 0) {
        return filteredSiteList[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleMenuItemClick = (event, corporation) => {
    dispatch(AccessActions.updateSelectedCorporationAndSite(corporation, getSelectedSite(corporation)));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonDisabled = organisationListFiltered?.length <= 1;
  return (
    <div className={selectorStyles.outer}>
      <Hidden mdUp>
        <IconButton
          aria-label="organisation-selection"
          aria-controls="organisation-selection"
          aria-haspopup="true"
          color={'inherit'}
          disabled={buttonDisabled}
          onClick={handleClickListItem}
        >
          <LocationCity />
        </IconButton>
      </Hidden>

      <Hidden smDown>
        <List component="nav" aria-label="Corporation Selector" color={'primary'}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Organisation"
            className={selectorStyles.root}
            disabled={buttonDisabled}
            onClick={handleClickListItem}
          >
            <ListItemText
              primary="Organisation"
              secondary={selectedCorporation ? selectedCorporation.name : 'No Organisation Selected'}
              secondaryTypographyProps={{ className: selectorStyles.listItemText }}
            />
          </ListItem>
        </List>
      </Hidden>

      <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} TransitionComponent={Fade}>
        {organisationListFiltered.map((c) => (
          <MenuItem
            key={`org-option-${c.id}`}
            selected={selectedCorporation && selectedCorporation.id === c.id}
            onClick={(event) => handleMenuItemClick(event, c)}
          >
            {c.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
