import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Actions from '../../../../redux/actions/notificationTemplate/actions';
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import toBase64 from '../../../../../support/toBase64Converter';
import FileImageSelector from '../../../../../components/file/FileImageSelector';

export default function TaggedNotificationTemplate({ type, tags, subheader, isEmail = false, hasReminder = false }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector((state) => state.notificationTemplateReducer.error);
  const lastActionType = useSelector((state) => state.notificationTemplateReducer.lastActionType);
  const selectedItem = useSelector((state) => state.notificationTemplateReducer.selectedItem);
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedSiteId = selectedSite?.id;
  const [name, setName] = useState(selectedItem?.name || '');
  const [message, setMessage] = useState(selectedItem?.paragraph1 || '');
  const [reminder, setReminder] = useState(selectedItem?.paragraph2 || '');
  const messageRef = useRef(message?.length || 0);
  const reminderRef = useRef(reminder?.length || 0);
  const [defaultHotelImage, setDefaultHotelImage] = useState(selectedItem?.hotelImageUrl || null);
  const [defaultTitleImage, setDefaultTitleImage] = useState(selectedItem?.titleImageUrl || null);
  const [hotelImageSelected, setHotelImageSelected] = React.useState(null);
  const [titleImageSelected, setTitleImageSelected] = React.useState(null);

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'NOTIFICATION_TEMPLATE_DELETE_SUCCESS' ||
      lastActionType === 'NOTIFICATION_TEMPLATE_ADD_SUCCESS' ||
      lastActionType === 'NOTIFICATION_TEMPLATE_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = async () => {
    const titleImageToSend = await toBase64(titleImageSelected);
    const hotelImageToSend = await toBase64(hotelImageSelected);

    let payload = {
      id: selectedItem ? selectedItem.id : null,
      type: type,
      name: name,
      siteId: selectedSiteId,
      paragraph1: message,
      paragraph2: reminder,
      enableApp: false,
      enableUpsell: false,
      locale: 'en',
    };

    if (isEmail) {
      payload = {
        ...payload,
        titleImage: titleImageToSend,
        titleImageName: titleImageSelected ? titleImageSelected.name : defaultTitleImage,
        hotelImage: hotelImageToSend,
        hotelImageName: hotelImageSelected ? hotelImageSelected.name : defaultHotelImage,
      };
    }

    if (selectedItem) {
      dispatch(Actions.update(payload, '/api/private/portal/site/template/update'));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const handleMessageLabelClick = (tag) => {
    setMessage((val) => val + `${tag.label}`);
    messageRef.current.focus();
  };

  const handleReminderLabelClick = (tag) => {
    setReminder((val) => val + `${tag.label}`);
    reminderRef.current.focus();
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Edit Notification Template' : 'New Notification Template'}
        subheader={subheader}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        backLink={'/site/templates'}
        disabled={!name && !message}
      />
      <CardContent>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12} sm={12}>
              <Alert severity="error" variant={'filled'}>
                {`Unable to edit/delete notification template - ${error.message}`}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  type="text"
                  variant={'outlined'}
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                  label="Notification Template Name"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant={'caption'}>Available Tags - Click on Tag to append to template</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {tags.map((tag) => (
                      <Box key={`${tag.value}`} component="span" mr={'.2em'}>
                        <Button
                          component={Link}
                          key={`${tag.value}`}
                          variant="outlined"
                          href="#"
                          onClick={() => handleMessageLabelClick(tag)}
                        >
                          {tag.label}
                        </Button>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  type="text"
                  variant={'outlined'}
                  onChange={(event) => setMessage(event.target.value)}
                  value={message}
                  label="Message"
                  minRows={6}
                  required
                  inputRef={messageRef}
                />
                <Typography variant={'caption'}>{messageRef?.current?.value?.length || message?.length || 0} Characters</Typography>
              </Grid>
            </Grid>
          </Grid>
          {hasReminder && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant={'h5'}>Reminder</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant={'caption'}>Available Tags - Click on Tag to append to template</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {tags.map((tag) => (
                          <Box key={`${tag.value}`} component="span" mr={'.2em'}>
                            <Button
                              component={Link}
                              key={`${tag.value}`}
                              variant="outlined"
                              href="#"
                              onClick={() => handleReminderLabelClick(tag)}
                            >
                              {tag.label}
                            </Button>
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      multiline
                      type="text"
                      variant={'outlined'}
                      onChange={(event) => setReminder(event.target.value)}
                      value={reminder}
                      label="Reminder"
                      minRows={6}
                      required
                      inputRef={reminderRef}
                    />
                    <Typography variant={'caption'}>{reminderRef?.current?.value?.length || reminder?.length || 0} Characters</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {isEmail && (
            <>
              <FileImageSelector
                sourceItem={selectedItem}
                sourceItemProperty={'titleImageUrl'}
                displayName={'Site Logo'}
                onSelect={(file) => setTitleImageSelected(file)}
                imageCleared={(value) => {
                  if (value) {
                    setTitleImageSelected(null);
                    setDefaultTitleImage(null);
                  }
                }}
              />

              <FileImageSelector
                sourceItem={selectedItem}
                sourceItemProperty={'hotelImageUrl'}
                displayName={'Site Image'}
                onSelect={(file) => setHotelImageSelected(file)}
                imageCleared={(value) => {
                  if (value) {
                    setHotelImageSelected(null);
                    setDefaultHotelImage(null);
                  }
                }}
              />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
