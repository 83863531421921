const EventPriority = {
  LOG_ON_EVENT: 'LOG_ON_EVENT',
  VERY_LOW_PRIORITY: 'VERY_LOW_PRIORITY',
  LOW_PRIORITY: 'LOW_PRIORITY',
  MEDIUM_LOW_PRIORITY: 'MEDIUM_LOW_PRIORITY',
  MEDIUM_PRIORITY: 'MEDIUM_PRIORITY',
  MEDIUM_HIGH_PRIORITY: 'MEDIUM_HIGH_PRIORITY',
  HIGH_PRIORITY: 'HIGH_PRIORITY',
  VERY_HIGH_PRIORITY: 'VERY_HIGH_PRIORITY',
  CRITICAL_PRIORITY: 'CRITICAL_PRIORITY',
};

export default EventPriority;
