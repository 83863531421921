import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import * as Actions from '../../../../redux/actions/reservationField/actions';
import FormBooleanSelect from '../../../../../components/form/FormBooleanSelect';
import FormSelect from '../../../../../components/form/FormSelect';
import ReservationFieldTypes from '../../../../../support/ReservationFieldTypes';

const initial = {
  fieldName: '',
  fieldCode: '',
  fieldType: 'STRING',
  order: 1,
  isVisible: false,
  isRequired: false,
  isUdf: true,
};

export default function ReservationFieldDialog({ open, setClose, reservationField, siteId, isNew }) {
  const dispatch = useDispatch();
  const [field, setField] = React.useState(reservationField || initial);

  const handleChange = (fieldName) => (event) => {
    setField({ ...field, [fieldName]: event.target.value });
  };

  const handleSelectionChange = (fieldName) => (value) => {
    if ((fieldName === 'isRequired' && value) || (fieldName === 'isVisible' && !value)) {
      setField({ ...field, isVisible: value, isRequired: value });
    } else {
      setField({ ...field, [fieldName]: value });
    }
  };

  function overrideAndClose() {
    if (field.siteId && field.id) {
      dispatch(Actions.updateReservationField(field.id, field));
    } else {
      dispatch(Actions.createReservationField(siteId, field));
    }

    setClose(null);
  }

  function handleClose() {
    setClose(null);
  }

  const formReady = field.fieldType && field.fieldCode && field.fieldName && field.order;

  if (field) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isNew ? 'Create Reservation Field' : field.id ? 'Update Reservation Field' : 'Override Reservation Field'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                disabled={!field.isUdf}
                variant={'outlined'}
                value={field.fieldName}
                label="Field Name"
                onChange={handleChange('fieldName')}
                required
                margin={'dense'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                disabled={!field.isUdf}
                variant={'outlined'}
                value={field.fieldCode}
                label="Field Code"
                onChange={handleChange('fieldCode')}
                required
                margin={'dense'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                label={'Field Type'}
                value={field.fieldType}
                setValue={handleSelectionChange('fieldType')}
                options={ReservationFieldTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={'outlined'}
                type="number"
                onChange={handleChange('order')}
                value={field.order}
                label="Order"
                required
                margin={'dense'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormBooleanSelect fullWidth label={'Visible'} value={field.isVisible} setValue={handleSelectionChange('isVisible')} />
            </Grid>
            <Grid item xs={12}>
              <FormBooleanSelect fullWidth label={'Required'} value={field.isRequired} setValue={handleSelectionChange('isRequired')} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" disabled={!formReady} onClick={overrideAndClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
