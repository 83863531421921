import reducerCreator from '../../../../support/reducerCreator';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Food Set Choice', 'FOOD_SET_CHOICE');

function foodSetChoiceReducer(state = initial, action) {
  switch (action.type) {
    case `FOOD_SET_CHOICE_SELECT_ALL_IN_PROGRESS`: {
      return {
        ...state,
        processing: true,
      };
    }
    case `FOOD_SET_CHOICE_SELECT_ALL_SUCCESS`: {
      return {
        ...state,
        processing: false,
        entityList: action.payload,
      };
    }
    case `FOOD_SET_CHOICE_SELECT_ALL_FAILURE`: {
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    }
    default:
      return base(state, action);
  }
}

export default foodSetChoiceReducer;
