import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CorporationActions from '../../../redux/actions/corporation/actions';
import * as AccessAccess from '../../../redux/actions/access/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FormBooleanSelect from '../../../../components/form/FormBooleanSelect';
import FormField from '../../../../components/form/FormField';

export default function CorporationEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.corporationReducer);
  const [corpName, setCorpName] = React.useState(selectedItem ? selectedItem.name : '');
  const [enabled, setEnabled] = React.useState(selectedItem ? selectedItem.enabled : true);

  useEffect(() => {
    dispatch(CorporationActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (lastActionType === 'CORPORATION_DELETE_SUCCESS' || lastActionType === 'CORPORATION_EDIT_SUCCESS') {
      history.push('/corporations');
    }
  }, [lastActionType, dispatch, history]);

  useEffect(() => {
    if (lastActionType === 'CORPORATION_ADD_SUCCESS') {
      dispatch(AccessAccess.selectCorp(selectedItem));
      history.push('/sites');
    }
  }, [history, lastActionType, dispatch, selectedItem]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: corpName,
      enabled: enabled,
    };

    if (selectedItem) {
      dispatch(CorporationActions.update(payload));
    } else {
      dispatch(CorporationActions.add(payload));
    }
  };

  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Corporation' : 'Create Corporation'}
        subheader={'A corporation is a company with one or more properties'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!corpName || !enabled || processing}
        onDelete={() => dispatch(CorporationActions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add corporation - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Corporation Name'} value={corpName} setValue={setCorpName} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
