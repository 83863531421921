import React, { useCallback, useEffect, useState } from 'react';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/securityCardholder/actions';
import * as DataTypes from '../../../../../constants/DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterField, getFilterUpdateMethods, getSelectFilterField } from '../../../../../components/table/filter/filterUtils';
import { SelectTrueFalseOptions } from '../../../../../support/FlowStates';

const columns = [
  {
    id: 'cardId',
    label: 'Card Id',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'firstname',
    label: 'Firstname',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'lastname',
    label: 'Lastname',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'cardSerialNumber',
    label: 'Card Serial Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'start',
    label: 'Start',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'end',
    label: 'End',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'created',
    label: 'Created',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'updated',
    label: 'Updated',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

function getFilterFieldsForScreen(state, setState) {
  const filterUpdateMethods = getFilterUpdateMethods(state, setState);
  return [
    getFilterField('firstname', 'Firstname', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('lastname', 'Lastname', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getFilterField('cardSerialNumber', 'Card SerialNumber', DataTypes.DATA_TYPE_STRING, filterUpdateMethods),
    getSelectFilterField('active', 'Active', SelectTrueFalseOptions, filterUpdateMethods),
  ];
}

export default function SecurityCardholderList(callback, deps) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const cardholders = useSelector((state) => state.securityCardholderReducer.entityList);
  const lastActionType = useSelector((state) => state.securityCardholderReducer.lastActionType);
  const page = useSelector((state) => state.securityCardholderReducer.page);
  const processing = useSelector((state) => state.securityCardholderReducer.processing);
  const error = useSelector((state) => state.securityCardholderReducer.error);
  const selectedSiteId = selectedSite?.id;
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    cardSerialNumber: '',
    active: true,
  });
  const filters = getFilterFieldsForScreen(state, setState);

  const handleFetchPage = useCallback(
    (newPage, pageSize) => {
      dispatch(
        Actions.findByFilter(newPage, pageSize, selectedSiteId, state.firstname, state.lastname, state.cardSerialNumber, state.active)
      );
    },
    [dispatch, selectedSiteId, state]
  );

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(Actions.findByFilter(0, 20, selectedSiteId, state.firstname, state.lastname, state.cardSerialNumber, state.active));
    }
  }, [selectedSiteId, state, dispatch]);

  return (
    <AutoTable
      title="Securtiy Cardholder"
      subheader={'Security cardholders are shown with the current status'}
      selectItem={(item) => dispatch(Actions.select(item))}
      handleChangePage={(ev, newPage, pageSize) => handleFetchPage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={columns}
      prefix="SECURITY_CARDHOLDER"
      idColumn={'id'}
      data={cardholders}
      lastActionType={lastActionType}
      filters={filters}
    />
  );
}
