import React from 'react';
import { Box, Divider, FormControlLabel, Grid, Switch } from '@material-ui/core';
import moment from 'moment/moment';
import { TimePicker } from '@material-ui/pickers';
import { DaysOfWeekLong } from '../../../../../support/DaysOfWeekShort';

export const RequestServiceTimeRenderDays = ({ days, scheduleDays, handleToggle, handleTimeUpdate, handleTimeClick }) => {
  return Object.keys(DaysOfWeekLong).map((day, idx) => {
    const currentDay = scheduleDays.find((cd) => cd.dayOfWeek === day);
    return (
      <Box display={'flex'} key={day}>
        <Grid item>
          <Grid container spacing={0} style={{ width: 180 }}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={days[day]?.selected || false} onChange={(_) => handleToggle(day)} name={day} color="primary" />}
                label={day}
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12}>
              <TimePicker
                fullWidth
                label={'Start Time'}
                inputVariant={'outlined'}
                margin={'dense'}
                disabled={!days[day]?.selected || false}
                value={currentDay?.startTime ? moment(currentDay.startTime, 'HH:mm:ss') : null}
                onChange={(time) => handleTimeUpdate(currentDay, day, time, 'start')}
                onClose={() => handleTimeClick(day, false)}
              />
              <TimePicker
                label={'End Time'}
                inputVariant={'outlined'}
                margin={'dense'}
                disabled={!days[day]?.selected || false}
                value={currentDay?.endTime ? moment(currentDay.endTime, 'HH:mm:ss') : null}
                onChange={(time) => handleTimeUpdate(currentDay, day, time, 'end')}
                onClose={() => handleTimeClick(day, false)}
              />
            </Grid>
          </Grid>
        </Grid>
        {idx < 6 && <Divider orientation="vertical" variant={'middle'} />}
      </Box>
    );
  });
};
