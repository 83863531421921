import API from '@aws-amplify/api';
import Objects from '../../../../support/Objects';
import Swal from 'sweetalert2';

export const MINIBAR_FETCH_SUCCESS = 'MINIBAR_FETCH_SUCCESS';
export const MINIBAR_FETCH_FAILURE = 'MINIBAR_FETCH_FAILURE';

export const MINIBAR_ADD_SUCCESS = 'MINIBAR_ADD_SUCCESS';
export const MINIBAR_ADD_FAILURE = 'MINIBAR_ADD_FAILURE';

export const MINIBAR_UPDATE_SUCCESS = 'MINIBAR_UPDATE_SUCCESS';
export const MINIBAR_UPDATE_FAILURE = 'MINIBAR_UPDATE_FAILURE';

export const MINIBAR_DELETE_SUCCESS = 'MINIBAR_DELETE_SUCCESS';
export const MINIBAR_DELETE_FAILURE = 'MINIBAR_DELETE_FAILURE';

export const MINIBAR_DUPLICATE_SUCCESS = 'MINIBAR_DUPLICATE_SUCCESS';
export const MINIBAR_DUPLICATE_FAILURE = 'MINIBAR_DUPLICATE_FAILURE';

export const MINIBAR_ADD_IN_PROGRESS = 'MINIBAR_ADD_IN_PROGRESS';
export const MINIBAR_PROCESSING = 'MINIBAR_PROCESSING';

export const MINIBAR_CLEAR_ERROR = 'MINIBAR_CLEAR_ERROR';
export const MINIBAR_SELECT = 'MINIBAR_SELECT';

export const fetch = (page, size, siteId) => async (dispatch) => {
  let requestUrl = `/api/private/portal/minibar/site/${siteId}?page=${page || 0}&size=${size || 10}`;

  dispatch({ type: MINIBAR_PROCESSING });
  try {
    const response = await API.get('PrivateAPI', requestUrl, {});
    dispatch({
      type: MINIBAR_FETCH_SUCCESS,
      payload: {
        ...response,
        page: {
          number: response.number,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        },
      },
    });
  } catch (error) {
    dispatch({ type: MINIBAR_FETCH_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const create = (request) => async (dispatch) => {
  dispatch({ type: MINIBAR_ADD_IN_PROGRESS });

  try {
    const response = await API.post('PrivateAPI', '/api/private/portal/minibar', { body: request });
    dispatch({ type: MINIBAR_ADD_SUCCESS, payload: response });
    Swal.fire('Minibar Item Created', 'Minibar Item has been created successfully', 'success');
  } catch (error) {
    dispatch({ type: MINIBAR_ADD_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const duplicate = (minibar) => async (dispatch) => {
  dispatch({ type: MINIBAR_PROCESSING });

  try {
    const response = await API.get('PrivateAPI', `/api/private/portal/minibar/duplicate/${minibar.id}`, {});
    dispatch({ type: MINIBAR_DUPLICATE_SUCCESS, payload: response });
    Swal.fire('Minibar Duplicated ', 'Minibar Item has been duplicated successfully', 'success');
  } catch (error) {
    dispatch({ type: MINIBAR_DUPLICATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteMinibar = (minibar) => async (dispatch) => {
  dispatch({ type: MINIBAR_PROCESSING });

  try {
    await API.del('PrivateAPI', `/api/private/portal/minibar/${minibar.id}`, {});
    dispatch({ type: MINIBAR_DELETE_SUCCESS, payload: minibar });
    Swal.fire('Minibar Deleted ', 'Minibar Item has been deleted successfully', 'success');
  } catch (error) {
    dispatch({ type: MINIBAR_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const update = (request) => async (dispatch) => {
  dispatch({ type: MINIBAR_PROCESSING });
  try {
    const response = await API.put('PrivateAPI', '/api/private/portal/minibar', { body: request });
    dispatch({ type: MINIBAR_UPDATE_SUCCESS, payload: response });
    Swal.fire('Minibar Item Updated', 'Item has been updated successfully', 'success');
  } catch (error) {
    dispatch({ type: MINIBAR_UPDATE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
  }
};

export const deleteItem = (selectedItem) => async (dispatch) => {
  dispatch({ type: MINIBAR_PROCESSING });
  Swal.fire({
    title: `Delete Minibar Item?`,
    text: 'Warning: This operation is not reversible',
    icon: 'warning',
    showCancelButton: true,
    heightAuto: false,
  }).then(async (result) => {
    if (result.value) {
      try {
        await API.del('PrivateAPI', `/api/private/portal/minibar/${selectedItem}`, {});
        dispatch({ type: MINIBAR_DELETE_SUCCESS, payload: selectedItem });
      } catch (error) {
        dispatch({ type: MINIBAR_DELETE_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
      }
    }
  });
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: MINIBAR_CLEAR_ERROR });
};

export const selectItem = (item) => async (dispatch) => {
  dispatch({ type: MINIBAR_SELECT, payload: item });
};
