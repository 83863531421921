const AvailabilityStatus = {
  PENDING: 'PENDING',
  OK: 'OK',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
  NOT_DUE_IN_OR_OUT: 'NOT_DUE_IN_OR_OUT',
  CHECK_IN_STATUS_MISMATCH: 'CHECK_IN_STATUS_MISMATCH',
  CHECK_OUT_STATUS_MISMATCH: 'CHECK_OUT_STATUS_MISMATCH',
  NO_ROOMS_AVAILABLE: 'NO_ROOMS_AVAILABLE',
  ROOM_NOT_READY: 'ROOM_NOT_READY',
  BLACKLIST: 'BLACKLIST',
  BLOCKED_BUSINESS_RULES: 'BLOCKED_BUSINESS_RULES',
};

const AvailabilityStatusOptions = [
  { label: 'Ready', value: AvailabilityStatus.OK },
  { label: 'Room Not Ready', value: AvailabilityStatus.ROOM_NOT_READY },
  { label: 'No Rooms Available', value: AvailabilityStatus.NO_ROOMS_AVAILABLE },
  { label: 'Cancelled', value: AvailabilityStatus.CANCELLED },
  { label: 'Blacklist', value: AvailabilityStatus.BLACKLIST },
  { label: 'Blocked By Business Rules', value: AvailabilityStatus.BLOCKED_BUSINESS_RULES },
];

export default AvailabilityStatus;
export { AvailabilityStatusOptions };
