import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FoodVendorScheduleActions from '../../../../redux/actions/inRoom/ordering/vendorSchedule/actions';
import CardHeaderWithControls from '../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ScheduleType, ScheduleTypes } from '../../../../../support/ScheduleTypes';
import FormSelect from '../../../../../components/form/FormSelect';
import { DaysOfWeekShort, EverydayShort } from '../../../../../support/DaysOfWeekShort';
import { SelectTrueFalseOptions } from '../../../../../support/FlowStates';
import moment from 'moment';
import MultiDatePicker from '../../../control/MultiDatePicker';
import FormMultiChipSelect from '../../../../../components/form/FormMultiChipSelect';
import Objects from '../../../../../support/Objects';

export default function FoodVendorScheduleEdit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedItem, error, lastActionType, processing } = useSelector((state) => state.foodVendorScheduleReducer);
  const vendor = useSelector((state) => state.foodVendorReducer.selectedItem);
  const vendorId = vendor?.id;
  const [type, setType] = useState(selectedItem?.schedule?.type || ScheduleType.DAYS_OF_WEEK);
  const [from, setFrom] = useState(selectedItem?.from || moment().format('HH:mm'));
  const [to, setTo] = useState(selectedItem?.to || moment().format('HH:mm'));
  const [value, setValue] = useState(selectedItem?.schedule?.value || EverydayShort);
  const [open, setOpen] = useState(!!selectedItem?.open);

  useEffect(() => {
    dispatch(FoodVendorScheduleActions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'FOOD_VENDOR_SCHEDULE_DELETE_SUCCESS' ||
      lastActionType === 'FOOD_VENDOR_SCHEDULE_UPDATE_SUCCESS' ||
      lastActionType === 'FOOD_VENDOR_SCHEDULE_ADD_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history, vendorId]);

  const handleUpdate = useCallback((type) => {
    setType(type);
    setValue([]);
  }, []);

  const handleSubmit = async () => {
    const payload = {
      ...selectedItem,
      schedule: {
        type: type,
        value: value,
      },
      from: from,
      to: to,
      open: open,
    };

    if (selectedItem) {
      dispatch(FoodVendorScheduleActions.update(payload, `/api/private/portal/food/vendor/schedule/${selectedItem.id}`, false));
    } else {
      dispatch(FoodVendorScheduleActions.add(payload, { vendorId: vendorId }));
    }
  };

  const isFormReady =
    !Objects.isNullOrEmpty(from) &&
    !Objects.isNullOrEmpty(to) &&
    !Objects.isNullOrEmpty(type) &&
    !Objects.areValuesNullOrEmpty(value) &&
    !Objects.isNullOrEmpty(open);
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Food Vendor Schedule' : 'Create Food Vendor Schedule'}
        subheader={'A Food Vendor Schedule dictates which day and time the Vendor is open/closed'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady || processing}
        deleteDisabled={!selectedItem?.id}
        onDelete={() =>
          dispatch(FoodVendorScheduleActions.delete(selectedItem, `/api/private/portal/food/vendor/schedule/${selectedItem.id}`))
        }
      />
      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add food vendor schedule - ${error.message}`}
          </Alert>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="From"
                value={moment.utc(from, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => setFrom(moment.utc(time).format('HH:mm'))}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TimePicker
                fullWidth
                label="To"
                value={moment.utc(to, 'HH:mm')}
                margin={'dense'}
                inputVariant={'outlined'}
                onChange={(time) => setTo(moment.utc(time).format('HH:mm'))}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect label={'Schedule Type'} value={type} setValue={handleUpdate} options={ScheduleTypes} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              {type === ScheduleType.DAYS_OF_WEEK && (
                <FormMultiChipSelect label={'Schedule'} value={value} setValue={setValue} options={DaysOfWeekShort} displayValue={true} />
              )}

              {type === ScheduleType.DATES && <MultiDatePicker label={'Schedule'} selected={value} setValue={setValue} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormSelect label={'Open'} value={open} setValue={setOpen} options={SelectTrueFalseOptions} />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
}
