import React from 'react';
import * as AuthenticationActions from '../../redux/actions/authentication/actions';
import { Button, Card, CardContent, CardHeader, FormControl, TextField } from '@material-ui/core';
import ContentContainer from '../../../components/container/ContentContainer';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { CheckBoxOutlined } from '@material-ui/icons';

const VerificationPasswordCode = () => {
  const [emailAddress, setEmailAddress] = React.useState('');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmed, setPasswordConfirmed] = React.useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { lastActionType, error, username } = useSelector((state) => state.authenticationReducer);

  React.useEffect(() => {
    if (lastActionType === AuthenticationActions.SUBMIT_FORGOT_PASSWORD_CODE_SUCCESS) {
      Swal.fire('Password Update Complete', 'Please login with your new password', 'success').then(() => {
        dispatch(AuthenticationActions.updateAuthenticationState('signIn'));
        history.push('/login');
      });
    }
  }, [lastActionType, history, dispatch]);

  const requestPasswordCodeValidation = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(AuthenticationActions.submitForgotPasswordCode(username || emailAddress, code, password, passwordConfirmed));
    },
    [dispatch, username, emailAddress, code, password, passwordConfirmed]
  );

  const navigateBack = () => {
    dispatch(AuthenticationActions.updateAuthenticationState('signIn'));
    history.push('/login');
  };

  return (
    <ContentContainer variant={'centered'}>
      <Card>
        <CardHeader title={'Enter Validation Code'} subheader={'Check your email, you have been sent a validation code'} />
        <CardContent>
          <form onSubmit={requestPasswordCodeValidation}>
            {error && <Alert severity={'error'}>{error.message}</Alert>}

            <FormControl fullWidth={true}>
              {username === null && (
                <TextField
                  id="emailAddress"
                  margin="dense"
                  variant="outlined"
                  label="Email Address"
                  type="text"
                  autoFocus={true}
                  required
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                />
              )}

              <TextField
                id="code"
                margin="dense"
                variant="outlined"
                label="Validation Code"
                type="text"
                autoFocus={username}
                required
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="password"
                margin="dense"
                variant="outlined"
                label="Enter Password"
                type="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <TextField
                id="passwordConfirmed"
                margin="dense"
                variant="outlined"
                label="Confirm Password"
                type="password"
                required
                value={passwordConfirmed}
                onChange={(event) => setPasswordConfirmed(event.target.value)}
              />
            </FormControl>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button type={'submit'} color={'primary'} variant={'contained'} fullWidth startIcon={<CheckBoxOutlined />}>
                Validate Code
              </Button>
            </FormControl>

            <FormControl fullWidth={true} margin={'dense'}>
              <Button fullWidth onClick={navigateBack}>
                Back
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </ContentContainer>
  );
};

export default VerificationPasswordCode;
