import * as DataTypes from '../../../../../../constants/DataTypes';
import { useSelector } from 'react-redux';
import React from 'react';
import AutoTable from '../../../../../../components/table/AutoTable';
import useFetch from '../../../../../../support/hook/useFetch';

const columns = [
  {
    id: 'originalRoomNumber',
    label: 'Original Room Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'originalRoomType',
    label: 'Original Room Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'selectedRoomNumber',
    label: 'Selected Room Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'selectedRoomType',
    label: 'Selected Room Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'upgradePrice',
    label: 'Upgrade Price',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_FUNCTION,
    render: function (values) {
      if (values.upgradePrice) {
        return parseFloat(values.upgradePrice).toFixed(2);
      } else {
        return '-';
      }
    },
  },
  {
    id: 'selectionDate',
    label: 'Selection Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE_TIME,
  },
  {
    id: 'seconds',
    label: 'Number of Seconds',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function RoomSelectionList() {
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.hotelReservationReducer.selectedItem);
  const [data, processing, error, page, updatePage] = useFetch(
    `/api/private/portal/roomSelection/reservation?siteId=${selectedSite.id}&reservationNumber=${selectedReservation.reservationNumber}`
  );

  return (
    <AutoTable
      title="Room Selection"
      subheader={'Room Selected by the guest'}
      handleChangePage={(ev, newPage, pageSize) => updatePage(newPage, pageSize)}
      processing={processing}
      page={page}
      error={error}
      columns={columns}
      prefix="RESERVATION_ROOM_SELECTION"
      buttonDisabled={true}
      detailURL="/reservation/roomSelection/view/{id}"
      data={data}
      idColumn={'id'}
      backHidden={true}
    />
  );
}
