import { combineReducers } from 'redux';
import authenticationReducer from './authentication/reducer';
import accessReducer from './access/reducer';
import accessReservationReducer from './reservation/access/reducer';
import hotelReservationReducer from './reservation/hotel/reducer';
import hotelReservationReportReducer from './reservation/report/reducer';
import userReducer from './user/reducer';
import corporationReducer from './corporation/reducer';
import platformReducer from './platform/reducer';
import siteReducer from './site/reducer';
import siteFeaturesReducer from './siteFeatures/reducer';
import siteResourcesReducer from './system/reducer';
import siteUpsellPackagesReducer from './upsell/reducer';
import siteRoomTypeReducer from './roomTypes/reducer';
import siteAmenitiesReducer from './amenity/reducer';
import notificationTemplateReducer from './notificationTemplate/reducer';
import themeReducer from './theme/reducer';
import themeTermsReducer from './theme/terms/reducer';
import themeMessagesReducer from './theme/messages/reducer';
import appUsersReducer from './appUsers/reducer';
import appReducer from './app/reducer';
import appThemeReducer from './app/theme/reducer';
import appLinkedSitesReducer from './app/linkedSites/reducer';
import dashboardReducer from './dashboard/reducer';
import siteDoorReducer from './siteDoor/reducer';
import paymentProviderReducer from './paymentProvider/reducer';
import chatReducer from './chat/reducer';
import sitePaymentTypeReducer from './sitePaymentType/reducer';
import siteRuleReducer from './rule/reducer';
import licenseReducer from './license/reducer';
import reservationFieldReducer from './reservationField/reducer';
import minibarReducer from './minibar/reducer';
import feedbackReducer from './feedback/reducer';
import specialRequestReducer from './specialRequest/reducer';
import reservationTrackingReducer from './reservationTracking/reducer';
import purchaseReducer from './purchase/reducer';
import compendiumCategoryReducer from './compendium/category/reducer';
import compendiumArticleReducer from './compendium/article/reducer';
import appUserAddressReducer from './appUserAddress/reducer';
import appUserCardTokenReducer from './appUserCardToken/reducer';
import appUserNotificationReducer from './appUserNotification/reducer';
import appUserReservationReducer from './appUserReservationCard/reducer';
import secondaryGuestReducer from './secondaryGuest/reducer';
import vendorReducer from './vendor/reducer';
import activityReducer from './activity/reducer';
import activityMappingReducer from './activity/mapping/reducer';
import activityImageReducer from './activity/image/reducer';
import activityScheduleReducer from './activity/schedule/reducer';
import activityTimeReducer from './activity/time/reducer';
import activityRateReducer from './activity/rate/reducer';
import activityBookingReducer from './activity/booking/reducer';
import notificationScheduleReducer from './site/notification/reducer';
import roomSelectionReducer from './roomSelection/reducer';
import roomFloorReducer from './roomSelection/roomFloor/reducer';
import notificationSchemeReducer from './scheme/notification/reducer';
import walletReducer from './wallet/reducer';
import reportScheduleReducer from './reservation/report/schedule/reducer';
import notificationInfographicReducer from './notificationTemplate/infographic/reducer';
import securityEventSettingReducer from './securityEventSetting/reducer';
import securityDoorReducer from './securityDoor/reducer';
import securityRoomMappingReducer from './securityRoomMapping/reducer';
import securityCardholderReducer from './securityCardholder/reducer';
import securityEventReducer from './securityEvent/reducer';
import facialRecognitionReducer from './facialRecognition/reducer';
import unsubscribedReducer from './unsubscribed/reducer';
import foodVendorReducer from './inRoom/ordering/vendor/reducer';
import foodMenuReducer from './inRoom/ordering/menu/reducer';
import foodInfoReducer from './inRoom/ordering/foodInfo/reducer';
import foodItemReducer from './inRoom/ordering/foodItem/reducer';
import foodVendorScheduleReducer from './inRoom/ordering/vendorSchedule/reducer';
import foodMenuScheduleReducer from './inRoom/ordering/menuSchedule/reducer';
import foodMenuMappingReducer from './inRoom/ordering/menuMapping/reducer';
import foodOrderReducer from './inRoom/ordering/order/reducer';
import foodOrderItemReducer from './inRoom/ordering/orderItem/reducer';
import foodModifierGroupReducer from './inRoom/ordering/foodModifierGroup/reducer';
import foodModifierGroupMappingReducer from './inRoom/ordering/foodModifierGroupMapping/reducer';
import foodModifierReducer from './inRoom/ordering/foodModifier/reducer';
import foodModifierMappingReducer from './inRoom/ordering/foodModifierMapping/reducer';
import foodOrderLocationReducer from './inRoom/ordering/orderLocation/reducer';
import specialRequestRedirectReducer from './specialRequestRedirect/reducer';
import foodTaxReducer from './inRoom/ordering/foodTax/reducer';
import foodChargeReducer from './inRoom/ordering/foodCharge/reducer';
import foodDiscountReducer from './inRoom/ordering/foodDiscount/reducer';
import foodPosPaymentTypeReducer from './inRoom/ordering/foodPosPaymentType/reducer';
import specialRequestCategoryReducer from './specialRequestCategory/reducer';
import guestOrdersReducer from './guestOrders/reducer';
import foodSetChoiceReducer from './inRoom/ordering/foodSetChoice/reducer';
import foodCorpFilterReducer from './inRoom/ordering/foodCorpFilter/reducer';
import subCategoryReducer from './specialRequestSubCategory/reducer';
import foodPriceScheduleReducer from './inRoom/ordering/priceSchedule/reducer';
import eventHookReducer from './eventHook/reducer';
import specialRequestEscalationRedirectReducer from './specialRequestEscalationRedirect/reducer';
import systemCalendarEventReducer from './system/event/reducer';

const rootReducer = combineReducers({
  authenticationReducer,
  accessReducer,
  accessReservationReducer,
  appUsersReducer,
  userReducer,
  corporationReducer,
  platformReducer,
  siteReducer,
  siteFeaturesReducer,
  siteResourcesReducer,
  siteUpsellPackagesReducer,
  siteRoomTypeReducer,
  siteAmenitiesReducer,
  notificationTemplateReducer,
  hotelReservationReducer,
  hotelReservationReportReducer,
  themeReducer,
  themeTermsReducer,
  themeMessagesReducer,
  appReducer,
  appThemeReducer,
  appLinkedSitesReducer,
  dashboardReducer,
  siteDoorReducer,
  paymentProviderReducer,
  chatReducer,
  sitePaymentTypeReducer,
  siteRuleReducer,
  licenseReducer,
  reservationFieldReducer,
  minibarReducer,
  feedbackReducer,
  specialRequestReducer,
  reservationTrackingReducer,
  purchaseReducer,
  compendiumCategoryReducer,
  compendiumArticleReducer,
  appUserAddressReducer,
  appUserCardTokenReducer,
  appUserNotificationReducer,
  appUserReservationReducer,
  secondaryGuestReducer,
  vendorReducer,
  activityReducer,
  activityMappingReducer,
  activityImageReducer,
  activityScheduleReducer,
  activityRateReducer,
  activityTimeReducer,
  activityBookingReducer,
  notificationScheduleReducer,
  roomSelectionReducer,
  roomFloorReducer,
  walletReducer,
  notificationSchemeReducer,
  reportScheduleReducer,
  notificationInfographicReducer,
  securityEventSettingReducer,
  securityDoorReducer,
  securityRoomMappingReducer,
  securityCardholderReducer,
  securityEventReducer,
  facialRecognitionReducer,
  unsubscribedReducer,
  foodVendorReducer,
  foodVendorScheduleReducer,
  foodMenuReducer,
  foodMenuScheduleReducer,
  foodInfoReducer,
  foodItemReducer,
  foodMenuMappingReducer,
  foodOrderReducer,
  foodOrderItemReducer,
  foodModifierGroupReducer,
  foodModifierGroupMappingReducer,
  foodModifierReducer,
  foodModifierMappingReducer,
  foodOrderLocationReducer,
  specialRequestRedirectReducer,
  specialRequestEscalationRedirectReducer,
  foodTaxReducer,
  foodChargeReducer,
  foodDiscountReducer,
  foodPosPaymentTypeReducer,
  specialRequestCategoryReducer,
  guestOrdersReducer,
  foodSetChoiceReducer,
  foodCorpFilterReducer,
  subCategoryReducer,
  foodPriceScheduleReducer,
  eventHookReducer,
  systemCalendarEventReducer,
});

export default rootReducer;
