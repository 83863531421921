import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../redux/actions/platform/actions';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

export default function PlatformEdit() {
  const history = useHistory();
  const dispatch = useDispatch();

  const lastActionType = useSelector((state) => state.platformReducer.lastActionType);
  const selectedItem = useSelector((state) => state.platformReducer.selectedItem);
  const error = useSelector((state) => state.platformReducer.error);

  const [platformName, setPlatformName] = React.useState(selectedItem ? selectedItem.name : '');
  const [platformType, setPlatformType] = React.useState(selectedItem ? selectedItem.type : '');
  const [baseUrl, setBaseUrl] = React.useState(selectedItem ? selectedItem.baseUrl : '');
  const [platformRegionCode, setPlatformRegionCode] = React.useState(selectedItem ? selectedItem.regionCode : '');
  const [bucketName, setBucketName] = React.useState(selectedItem ? selectedItem.bucketName : '');
  const [uiUrl, setUiUrl] = React.useState(selectedItem ? selectedItem.uiUrl : '');

  useEffect(() => {
    dispatch(Actions.clearError());
  }, [dispatch]);

  useEffect(() => {
    if (
      lastActionType === 'PLATFORM_DELETE_SUCCESS' ||
      lastActionType === 'PLATFORM_ADD_SUCCESS' ||
      lastActionType === 'PLATFORM_UPDATE_SUCCESS'
    ) {
      history.goBack();
    }
  }, [lastActionType, dispatch, history]);

  const handleSubmit = () => {
    const payload = {
      ...selectedItem,
      name: platformName,
      type: platformType,
      baseUrl: baseUrl,
      regionCode: platformRegionCode,
      bucketName: bucketName,
      uiUrl,
    };

    if (selectedItem) {
      dispatch(Actions.update(payload));
    } else {
      dispatch(Actions.add(payload));
    }
  };

  const isFormReadyForSubmit = platformType && baseUrl && platformRegionCode && bucketName && platformName;
  return (
    <Card>
      <CardHeaderWithControls
        header={selectedItem ? 'Update Platform' : 'Create Platform'}
        subheader={'Cloud platform and account details for different environments'}
        buttonName={selectedItem ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReadyForSubmit}
        deleteDisabled={false}
        onDelete={() => dispatch(Actions.delete(selectedItem))}
      />

      <CardContent>
        {error && (
          <Alert
            severity="error"
            action={
              <Link component="button" onClick={() => dispatch(Actions.clearError())}>
                <ClearOutlined />
              </Link>
            }
          >
            {'Unable to update platform - ' + error.message}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="platformName"
              type="text"
              variant={'outlined'}
              onChange={(event) => setPlatformName(event.target.value)}
              value={platformName}
              label="Platform Name"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Platform Type</InputLabel>
              <Select
                labelId="type-select-label"
                value={platformType}
                variant={'outlined'}
                onChange={(event) => setPlatformType(event.target.value)}
                required
              >
                <MenuItem value="ON_PREMISE" selected={platformType === 'ON_PREMISE'}>
                  On Premise
                </MenuItem>
                <MenuItem value="AMAZON_WEB_SERVICES" selected={platformType === 'AMAZON_WEB_SERVICES'}>
                  Amazon Web Services
                </MenuItem>
                <MenuItem value="GOOGLE_CLOUD_PLATFORM" selected={platformType === 'GOOGLE_CLOUD_PLATFORM'}>
                  Google Cloud Platform
                </MenuItem>
                <MenuItem value="AZURE" selected={platformType === 'AZURE'}>
                  Azure
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="baseUrl"
              type="text"
              variant={'outlined'}
              onChange={(event) => setBaseUrl(event.target.value)}
              value={baseUrl}
              label="Base URL"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="regionCode"
              type="text"
              variant={'outlined'}
              onChange={(event) => setPlatformRegionCode(event.target.value)}
              value={platformRegionCode}
              label="Platform Region Code"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="bucketName"
              type="text"
              variant={'outlined'}
              onChange={(event) => setBucketName(event.target.value)}
              value={bucketName}
              label="S3 Bucket Name"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              id="uiUrl"
              type="text"
              variant={'outlined'}
              onChange={(event) => setUiUrl(event.target.value)}
              value={uiUrl}
              label="URL of the UI"
              required
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
