const TimeRange = {
  HOUR: { icon: '1h', value: 'HOUR', display: '1 Hour' },
  DAY: { icon: '1d', value: 'DAY', display: '1 Day' },
  WEEK: { icon: '1w', value: 'WEEK', display: '1 Week' },
  MONTH: { icon: '1m', value: 'MONTH', display: '1 Month' },
  QUARTER: { icon: '3m', value: 'QUARTER', display: '3 Months' },
  HALF_YEAR: { icon: '6m', value: 'HALF_YEAR', display: '6 Months' },
  YEAR: { icon: '1y', value: 'YEAR', display: '1 Year' },
  CUSTOM: { icon: 'Custom', value: 'CUSTOM', display: 'Custom' },
};

export default TimeRange;
