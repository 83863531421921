import actionCreator from '../../support/actionCreator';
import { API } from '@aws-amplify/api';
import Swal from 'sweetalert2';
import Objects from '../../../../support/Objects';
import SpecialRequestState from '../../../../support/SpecialRequestState';

export const SPECIAL_REQUEST_NOTIFICATION_IN_PROGRESS = 'SPECIAL_REQUEST_NOTIFICATION_IN_PROGRESS';
export const SPECIAL_REQUEST_NOTIFICATION_SUCCESS = 'SPECIAL_REQUEST_NOTIFICATION_SUCCESS';
export const SPECIAL_REQUEST_NOTIFICATION_FAILURE = 'SPECIAL_REQUEST_NOTIFICATION_FAILURE';
export const SPECIAL_REQUEST_UNSELECT = 'SPECIAL_REQUEST_UNSELECT';
export const SPECIAL_REQUEST_EXPORT_IN_PROGRESS = 'SPECIAL_REQUEST_EXPORT_IN_PROGRESS';
export const SPECIAL_REQUEST_EXPORT_SUCCESS = 'SPECIAL_REQUEST_EXPORT_SUCCESS';
export const SPECIAL_REQUEST_EXPORT_FAILURE = 'SPECIAL_REQUEST_EXPORT_FAILURE';

const SpecialRequestActions = {
  ...actionCreator.createActions('SpecialRequest', 'SPECIAL_REQUEST', '/api/private/special'),

  updateNote: (specialRequest) => async (dispatch) => {
    dispatch({ type: SPECIAL_REQUEST_NOTIFICATION_IN_PROGRESS });

    try {
      const response = await API.put('PrivateAPI', '/api/private/special/note/update', { body: specialRequest });
      dispatch({ type: SPECIAL_REQUEST_NOTIFICATION_SUCCESS, payload: response });
      if (SpecialRequestState.NOT_APPLICABLE === specialRequest.state) {
        Swal.fire('Note Saved', 'Note has been marked as Not Applicable', 'success');
      } else {
        Swal.fire('Note Saved', 'Note and guest notified', 'success');
      }
    } catch (error) {
      dispatch({ type: SPECIAL_REQUEST_NOTIFICATION_FAILURE, payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  fetchItems: (page, size, siteId, reservationNumber) => async (dispatch) => {
    try {
      const response = await API.get(
        'PrivateAPI',
        `/api/private/special/reservation?page=${page}&size=${size}&siteId=${siteId}&reservationNumber=${reservationNumber}`,
        {}
      );
      dispatch({
        type: 'SPECIAL_REQUEST_FETCH_SUCCESS',
        payload: {
          ...response,
          page: {
            number: response.number,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
          },
        },
      });
    } catch (error) {
      dispatch({ type: 'SPECIAL_REQUEST_FETCH_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  unselectRequests: () => async (dispatch) => {
    dispatch({ type: SPECIAL_REQUEST_UNSELECT });
  },
  exportAll: (siteId, params) => async (dispatch) => {
    await dispatch({ type: SPECIAL_REQUEST_EXPORT_IN_PROGRESS });
    try {
      const requestUrl = Objects.appendParamsUrl(params, `/api/private/special/site/${siteId}/export`);
      const response = await API.get('PrivateAPI', requestUrl, {});
      await dispatch({ type: SPECIAL_REQUEST_EXPORT_SUCCESS });
      Objects.saveAndDownloadContent(`${response.fileName}`, `data:application/csv;name=;base64,${response.file}`);
    } catch (error) {
      console.warn('Failed to export food order: ', error);
      dispatch({
        type: SPECIAL_REQUEST_EXPORT_FAILURE,
        payload: new Error(Objects.getErrorFromResponse(error)),
      });
    }
  },
};
export default SpecialRequestActions;
