import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import UserRoleHelper from '../support/UserRoleHelper';
import PropTypes from 'prop-types';

const SecureRoute = ({ role, component: Component, ...rest }) => {
  const profile = useSelector((state) => state.accessReducer.profile);
  return <Route {...rest} render={(props) => (UserRoleHelper.hasRole(profile, role) ? <Component {...props} /> : <Redirect to={'/'} />)} />;
};

SecureRoute.propTypes = {
  role: PropTypes.string.isRequired,
};

export default React.memo(SecureRoute);
