import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../../redux/actions/reservation/access/actions';
import CannedRegex from '../../../../../../support/CannedRegex';
import AccessControlGuestType from '../../../../../../support/AccessControlGuestType';
import { AddBox } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import API from '@aws-amplify/api';
import PhoneNumberInput from '../../../../../../components/phoneNumberInput/PhoneNumberInput';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { getCountryCodeFromInternationalPhoneNumber } from '../../../../../../components/phoneNumberInput/PhoneNumberUtils';

export default function GuestDialog({ isOpen, onSubmit, onClose, guest, parentGuest }) {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedReservation = useSelector((state) => state.accessReservationReducer.selectedItem);

  const [firstName, setFirstName] = React.useState(guest ? guest.firstName : '');
  const [lastName, setLastName] = React.useState(guest ? guest.lastName : '');
  const [email, setEmail] = React.useState(guest ? guest.email : '');
  const [phone, setPhone] = useState({
    phoneNumber: guest?.phone,
    sanitizedPhoneNumber: '',
    isValid: false,
  });
  const [guestType, setGuestType] = React.useState(guest ? guest.guestType : AccessControlGuestType.PRIMARY);
  const [appUserId, setAppUserId] = React.useState(guest ? guest.appUserId : null);
  const [candidateList, setCandidateList] = React.useState([]);

  React.useEffect(() => {
    if (guest && isOpen) {
      setFirstName(guest?.firstName || '');
      setLastName(guest?.lastName || '');
      setGuestType(guest?.guestType || AccessControlGuestType.PRIMARY);
      setEmail(guest?.email || '');
      setPhone({
        phoneNumber: guest?.phone,
        sanitizedPhoneNumber: '',
        isValid: false,
      });
      setAppUserId(guest?.appUserId || null);
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setGuestType(AccessControlGuestType.PRIMARY);
      setAppUserId(null);
    }
  }, [guest, isOpen]);

  const handleAddNewGuest = React.useCallback(() => {
    let reference = selectedReservation?.reference || guest?.reference;
    let parentId = guest?.parentId || selectedReservation?.id; //id of the parent reservation

    if (!reference) {
      reference = Math.random().toString(36).substr(2, 7);
    }

    const payload = {
      ...guest,
      reference: reference,
      firstName: firstName,
      lastName: lastName,
      guestType: guestType,
      email: email,
      phone: phone.sanitizedPhoneNumber,
      appUserId: appUserId,
      parentId: parentId,
    };

    if (onSubmit) {
      onSubmit(payload);
    } else {
      if (payload.id) {
        dispatch(Actions.editGuest(payload));
      } else {
        dispatch(Actions.addGuest(payload));
      }

      onClose();
    }
  }, [
    selectedReservation?.reference,
    selectedReservation?.id,
    guest,
    firstName,
    lastName,
    guestType,
    email,
    phone,
    onSubmit,
    onClose,
    appUserId,
    dispatch,
  ]);

  const handleDialogClose = () => {
    onClose();
  };

  function getEmailAddressHelperMessage(isEmailUnique, isEmailValid) {
    if (isEmailValid) {
      if (isEmailUnique) {
        return '';
      } else {
        return '** Email address is already used by another guest';
      }
    }

    return CannedRegex.getEmailMessage(true);
  }

  const pullAutoComplete = (event) => {
    if (event) {
      // setSearchCriteria(event.target.value);
      setFirstName(event.target.value);

      const payload = {
        criteria: event.target.value,
        siteId: selectedSite?.id,
      };

      console.log('sending search: ', payload);
      API.post('PrivateAPI', '/api/private/portal/access/reservation/guest/search', { body: payload })
        .then((response) => {
          setCandidateList(response);
        })
        .catch(() => {
          setCandidateList([]);
        });
    }
  };

  const isPhoneValid = phone.isValid;
  const isEmailValid = CannedRegex.isEmailValid(email);
  const isEmailUnique = isEmailValid && isGuestEmailUnique(email, guest, selectedReservation?.guestList);
  const isGuestFormValid = firstName && lastName && isPhoneValid && isEmailValid && isEmailUnique;
  const defaultRegion = getCountryCodeFromInternationalPhoneNumber(phone?.sanitizedPhoneNumber, selectedSite?.countryCode);
  return (
    <Dialog open={isOpen} onClose={handleDialogClose} aria-labelledby="add-guest-title">
      <DialogTitle id="add-guest-title">{guest ? 'Update Guest' : 'Add Guest'}</DialogTitle>
      <DialogContent>
        <DialogContentText>To add a guest to this reservation please enter their details below and click "Add".</DialogContentText>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            {guest && (
              <TextField
                autoFocus
                label="First Name"
                variant={'outlined'}
                type="text"
                onChange={(event) => setFirstName(event.target.value)}
                value={firstName}
                error={!firstName}
                placeholder="Enter Guest First Name"
                fullWidth
              />
            )}

            {!guest && (
              <Autocomplete
                id="combo-box-demo"
                options={candidateList}
                freeSolo={true}
                clearOnEscape={true}
                onInputChange={(event) => pullAutoComplete(event)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setPhone({
                      phoneNumber: parsePhoneNumberFromString(newValue.phone).nationalNumber,
                      sanitizedPhoneNumber: newValue.phone,
                      isValid: true,
                      autoComplete: true,
                    });
                    setEmail(newValue.email);
                    setFirstName(newValue.firstName);
                    setLastName(newValue.lastName);
                    setAppUserId(newValue.appUserId);
                  } else {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setAppUserId(null);
                  }
                }}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                renderInput={(params) => <TextField {...params} label="First Name" error={!firstName} variant="outlined" />}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant={'outlined'}
              type="text"
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              error={!lastName}
              placeholder="Enter Guest Last Name"
              fullWidth
              label="Last Name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant={'outlined'}
              type="email"
              disabled={guest?.id}
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              placeholder={CannedRegex.getEmailPlaceholder()}
              error={!isEmailValid && !isEmailUnique}
              helperText={getEmailAddressHelperMessage(isEmailUnique, isEmailValid)}
              fullWidth
              label="Email"
            />
          </Grid>

          {parentGuest && (
            <Grid item xs={12}>
              <TextField
                variant={'outlined'}
                placeholder={CannedRegex.getPhonePlaceholder()}
                label="Parent Guest Email"
                disabled
                fullWidth
                value={parentGuest.email}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <PhoneNumberInput phone={phone} setPhone={setPhone} isRequired={true} defaultRegion={defaultRegion} />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id="issue-behavior">Guest Type</InputLabel>
              <Select value={guestType} labelId={'guest-type-label'} labelWidth={80} onChange={(event) => setGuestType(event.target.value)}>
                <MenuItem value={AccessControlGuestType.PRIMARY} selected={guestType === AccessControlGuestType.PRIMARY}>
                  Primary
                </MenuItem>
                <MenuItem value={AccessControlGuestType.SECONDARY} selected={guestType === AccessControlGuestType.SECONDARY}>
                  Secondary
                </MenuItem>
                <MenuItem value={AccessControlGuestType.TEMPORARY} selected={guestType === AccessControlGuestType.TEMPORARY}>
                  Temporary
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/*<TextField*/}
        {/*  autoFocus*/}
        {/*  variant={'outlined'}*/}
        {/*  margin="dense"*/}
        {/*  type="text"*/}
        {/*  onChange={(event) => setFirstName(event.target.value)}*/}
        {/*  value={firstName}*/}
        {/*  placeholder="Enter Guest First Name"*/}
        {/*  fullWidth*/}
        {/*  label="First Name"*/}
        {/*/>*/}
      </DialogContent>
      <DialogActions className={'mr-2 mb-1'}>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddNewGuest} color="primary" disabled={!isGuestFormValid} variant={'contained'} startIcon={<AddBox />}>
          {guest ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function isGuestEmailUnique(email, guest, guests) {
  //if this is guest which has been added then it's unique
  if (guest?.id) {
    return true;
  }

  if (guests) {
    return !guests.find((g) => g.emailAddress === email);
  } else {
    return true;
  }
}
