const DashboardSection = {
  BOOKING: 'BOOKING',
  ROOM_KEY: 'ROOM KEY',
  WEB_PORTAL: 'WEB PORTAL',
  FINANCIALS: 'FINANCIALS',
  FOOD_AND_BEVERAGE: 'FOOD_AND_BEVERAGE',
  GUEST_SERVICE: 'GUEST_SERVICE',
};

export default DashboardSection;
