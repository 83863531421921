import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../redux/actions/system/actions';
import CardHeaderWithControls from '../../../../../../components/card/CardHeaderWithControls';
import { Card, CardContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Objects from '../../../../../../support/Objects';
import FormSelect from '../../../../../../components/form/FormSelect';
import FormBooleanSelect from '../../../../../../components/form/FormBooleanSelect';
import FormField from '../../../../../../components/form/FormField';

const SECURITY_REVOKE_MODE = [
  { value: 'DELETE', label: 'Delete' },
  { value: 'CANCEL', label: 'Cancel' },
];

const SECURITY_FETCH_ROOM_TYPE = [
  { value: 'ALL', label: 'All' },
  { value: 'DOOR_ONLY', label: 'Door Only' },
  { value: 'COMMON_AREA_ONLY', label: 'Common Area Only' },
];

export default function EditGallagherSecuritySystem() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const selectedItem = useSelector((state) => state.siteResourcesReducer.selectedItem);
  const error = useSelector((state) => state.siteResourcesReducer.error);
  const configuration = selectedItem.systemConfiguration ? JSON.parse(selectedItem.systemConfiguration) : {};

  const [name, setName] = React.useState(selectedItem?.name || '');
  const [enabled, setEnabled] = React.useState(selectedItem?.enabled || false);

  const [server, setServer] = React.useState(configuration?.server || 'https://192.168.50.239');
  const [port, setPort] = React.useState(configuration?.port || '8904');
  const [apiKey, setApiKey] = React.useState(configuration?.apiKey || '4738-78E9-8D40-F266-7B74-C3FD-CD3C-FA60');
  const [revokeMode, setRevokeMode] = React.useState(configuration?.revokeMode || 'DELETE');
  const [fetchRoomType, setFetchRoomType] = React.useState(configuration?.fetchRoomType || 'ALL');
  const [physicalCardType, setPhysicalCardType] = React.useState(configuration?.physicalCardType || 'Vingcard');
  const [division, setDivision] = React.useState(configuration?.division || 'Root Division');
  const [restWorkstation, setRestWorkstation] = React.useState(configuration?.restWorkstation || 'Liverton REST Client');
  const [personalDataField, setPersonalDataField] = React.useState(configuration?.personalDataField || 'Visionline User Id');
  const [debug, setDebug] = React.useState(configuration?.debug || false);

  const handleSubmit = React.useCallback(() => {
    const payload = {
      ...selectedItem,
      name: name,
      enabled: enabled,
      systemCategory: selectedItem.systemCategory,
      systemType: selectedItem.systemType,
      siteId: selectedItem ? selectedItem.siteId : selectedSite.id,
      systemConfiguration: JSON.stringify({
        debug: debug,
        server: Objects.sanitizeAndTrimString(server),
        port,
        apiKey,
        revokeMode,
        fetchRoomType,
        physicalCardType,
        division,
        restWorkstation,
        personalDataField,
      }),
    };

    if (selectedItem.id) {
      dispatch(Actions.updateSiteSystem(payload));
    } else {
      dispatch(Actions.createSiteSystem(payload));
    }
  }, [
    selectedItem,
    name,
    enabled,
    selectedSite.id,
    debug,
    server,
    port,
    apiKey,
    revokeMode,
    fetchRoomType,
    physicalCardType,
    division,
    restWorkstation,
    personalDataField,
    dispatch,
  ]);

  const isFormReady = name && server && port && apiKey && physicalCardType && division && restWorkstation && personalDataField;
  return (
    <Card>
      <CardHeaderWithControls
        header={'Gallagher Security System'}
        subheader={'Gallagher security system to synchronise between two lock systems'}
        buttonName={selectedItem.id ? 'Update' : 'Create'}
        onClick={handleSubmit}
        disabled={!isFormReady}
        onDelete={() => dispatch(Actions.deleteSiteSystem(selectedItem))}
        deleteDisabled={!selectedItem.id}
      />

      <CardContent>
        {error && (
          <Alert severity="error" variant={'filled'} className={'mb-2'}>
            {`Unable to add system - ${error.message}`}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Friendly Name'} value={name} setValue={setName} type={'text'} error={!name} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Server URL'} value={server} setValue={setServer} type={'text'} error={!server} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Server port'} value={port} setValue={setPort} type={'text'} error={!port} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'API Key'} value={apiKey} setValue={setApiKey} type={'password'} error={!apiKey} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Revoke Mode'} value={revokeMode} setValue={setRevokeMode} options={SECURITY_REVOKE_MODE} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormSelect label={'Room Type to Fetch'} value={fetchRoomType} setValue={setFetchRoomType} options={SECURITY_FETCH_ROOM_TYPE} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Physical Card Type Name'}
              value={physicalCardType}
              setValue={setPhysicalCardType}
              type={'text'}
              error={!physicalCardType}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField label={'Division Name'} value={division} setValue={setDivision} type={'text'} error={!division} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'REST API Workstation Name'}
              value={restWorkstation}
              setValue={setRestWorkstation}
              type={'text'}
              error={!restWorkstation}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              label={'Personal Data Field Name'}
              value={personalDataField}
              setValue={setPersonalDataField}
              type={'text'}
              error={!personalDataField}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Debug'} value={debug} setValue={setDebug} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormBooleanSelect label={'Enabled'} value={enabled} setValue={setEnabled} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
