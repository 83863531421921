import reducerCreator from '../../support/reducerCreator';
import { SECURITY_ROOMS_DELETE_SUCCESS } from '../../actions/securityDoor/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Security Room', 'SECURITY_ROOM');

function securityDoorReducer(state = initial, action) {
  switch (action.type) {
    case SECURITY_ROOMS_DELETE_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        entityList: [],
      };
    default:
      return base(state, action);
  }
}

export default securityDoorReducer;
