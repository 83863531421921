import reducerCreator from '../../../support/reducerCreator';
import * as Actions from '../../../actions/reservation/access/actions';

const initial = {
  ...reducerCreator.getInitialState(),
};

const base = reducerCreator.createReducer('Access Reservation', 'ACCESS_RESERVATION');

function accessReservationReducer(state = initial, action) {
  switch (action.type) {
    case Actions.ACCESS_RESERVATION_ADD_GUEST_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        error: null,
      };
    case Actions.ACCESS_RESERVATION_ADD_GUEST_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        selectedItem: {
          ...state.selectedItem,
          guestList: [...state.selectedItem.guestList, action.payload],
        },
      };
    case Actions.ACCESS_RESERVATION_ADD_GUEST_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        error: action.payload,
      };
    case Actions.ACCESS_RESERVATION_EDIT_GUEST_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case Actions.ACCESS_RESERVATION_EDIT_GUEST_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        selectedItem: {
          ...state.selectedItem,
          guestList: state.selectedItem.guestList.map((g) => (g.id === action.payload.id ? action.payload : g)),
        },
      };
    case Actions.ACCESS_RESERVATION_EDIT_GUEST_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    case Actions.ACCESS_RESERVATION_DELETE_GUEST_IN_PROGRESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: true,
        error: null,
      };
    case Actions.ACCESS_RESERVATION_DELETE_GUEST_SUCCESS:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        selectedItem: {
          ...state.selectedItem,
          guestList: state.selectedItem.guestList.filter((g) => g.id !== action.payload.id).filter(g => g.parentGuestId !== action.payload.id),
        },
      };
    case Actions.ACCESS_RESERVATION_DELETE_GUEST_FAILURE:
      return {
        ...state,
        lastActionType: action.type,
        processing: false,
        error: action.payload,
      };
    default:
      return base(state, action);
  }
}

export default accessReservationReducer;
