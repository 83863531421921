import Currency from './Currency';

export default class CartUtil {
  /**
   * [modifiers,choices,subModifiers] come in two varieties. Locally in the redux it will be item.[property].items = []
   * however from the server it is item.[property] = []
   * @param {[]} value Either modifiers,choices,subModifiers from the cart or server
   * @return {FoodOrderSetItemInfo[]|FoodOrderModifierInfo[]}
   */
  static flatMapCartProperty(value = []) {
    if (value?.length === 0) {
      return [];
    }

    const items = [];

    value.forEach((c) => {
      if (c.items) {
        c.items.forEach((i) => items.push(i));
      } else {
        items.push(c);
      }
    });

    return items;
  }

  /**
   *
   * @param {FoodOrderItemInfo} item details
   * @param {string} currency Currency code
   * @return {Currency} Total amount for the given item
   */
  static getCartItemTotal(item, currency) {
    if (!item) {
      return Currency.ZERO(currency);
    }

    const modifiersTotal =
      CartUtil.flatMapCartProperty(item.modifiers)
        .map((m) => m.price)
        .reduce((m1, m2) => m1 + m2, 0) || 0;
    const choicesTotal =
      CartUtil.flatMapCartProperty(item.choices)
        .map((c) => c.price)
        .reduce((c1, c2) => c1 + c2, 0) || 0;
    const subModsTotal =
      CartUtil.flatMapCartProperty(item.subModifiers)
        .map((sm) => sm.price)
        .reduce((sm1, sm2) => sm1 + sm2, 0) || 0;

    return new Currency(item.price || 0, currency)
      .plus(modifiersTotal)
      .plus(choicesTotal)
      .plus(subModsTotal)
      .times(item.quantity)
      .toFixed(2);
  }

  /**
   *
   * @param {FoodOrderItemInfo} item details
   * @param {string} currency Currency code
   * @return {Currency} Total amount for the given item
   */
  static getCartDiscountedItemTotal(item, currency) {
    if (!item) {
      return Currency.ZERO(currency);
    }

    const modifiersTotal =
      CartUtil.flatMapCartProperty(item.modifiers)
        .map((m) => m.discountedPrice)
        .reduce((m1, m2) => m1 + m2, 0) || 0;
    const choicesTotal =
      CartUtil.flatMapCartProperty(item.choices)
        .map((c) => c.discountedPrice)
        .reduce((c1, c2) => c1 + c2, 0) || 0;
    const subModsTotal =
      CartUtil.flatMapCartProperty(item.subModifiers)
        .map((sm) => sm.discountedPrice)
        .reduce((sm1, sm2) => sm1 + sm2, 0) || 0;

    return new Currency(item.discountedPrice || 0, currency)
      .plus(modifiersTotal)
      .plus(choicesTotal)
      .plus(subModsTotal)
      .times(item.quantity)
      .toFixed(2);
  }
}
