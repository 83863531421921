import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';
import ResetPasswordType from '../../../../constants/ResetPasswordType';
import CannedRegex from '../../../../support/CannedRegex';
import FormField from '../../../../components/form/FormField';

export default function PasswordResetModal(props) {
  const { handleClose, handleResetPassword, isOpen } = props;

  const [resetPasswordType] = React.useState(ResetPasswordType.UPDATE_TEMP_PASSWORD.value);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const isPasswordInvalid = !CannedRegex.isPasswordValid(newPassword);
  const isPasswordMismatch = newPassword && newPassword !== confirmPassword;
  const isFormReady = newPassword && confirmPassword && !isPasswordInvalid && !isPasswordMismatch;

  return (
    <Dialog open={isOpen} onClose={() => handleClose()}>
      <DialogTitle id="alert-dialog-title">Reset Password</DialogTitle>
      <Divider />

      <DialogContent>
        <FormField
          label={'New Temp Password'}
          type={'password'}
          value={newPassword}
          setValue={setNewPassword}
          error={isPasswordInvalid}
          helperText={CannedRegex.getPasswordMessage(isPasswordInvalid)}
        />
        <FormField
          label={'Confirm Temp Password'}
          type={'password'}
          value={confirmPassword}
          setValue={setConfirmPassword}
          error={isPasswordInvalid || isPasswordMismatch}
          helperText={isPasswordMismatch ? '** Password mismatch double check passwords' : ''}
        />
        <DialogContentText id="alert-dialog-description">
          This will make the current app user's password invalid and the updated temporary password will be available to reset their
          password.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary" autoFocus>
          NO
        </Button>
        <Button
          onClick={() => handleResetPassword(resetPasswordType, newPassword)}
          disabled={!isFormReady}
          color="primary"
          variant={'contained'}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}
