import React, { useState } from 'react';
import * as DataTypes from '../../../../../constants/DataTypes';
import AutoTable from '../../../../../components/table/AutoTable';
import Actions from '../../../../redux/actions/siteDoor/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Sync } from '@material-ui/icons';

// Table column rendering information.
const columns = [
  {
    id: 'doorName',
    label: 'Room Name',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DRILL_DOWN_LINK,
  },
  {
    id: 'doorId',
    label: 'Door ID',
    minWidth: 80,
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'doorType',
    label: 'Door Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'doorGroup',
    label: 'Door Group',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'floor',
    label: 'Floor',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'displayRoomNumber',
    label: 'Display Room Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'roomType',
    label: 'Room Type',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'nextBookingDate',
    label: 'Next Booking Date',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_DATE,
  },
  {
    id: 'authorisationNumber',
    label: 'Authorisation Number',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'visible',
    label: 'Visible',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_BOOLEAN,
  },
];

const roomStatusColumn = [
  {
    id: 'foStatus',
    label: 'Front Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
  {
    id: 'hkStatus',
    label: 'House Keeping Status',
    align: 'left',
    dataType: DataTypes.DATA_TYPE_STRING,
  },
];

export default function SiteRoomList() {
  const dispatch = useDispatch();
  const selectedSite = useSelector((state) => state.accessReducer.selectedSite);
  const doorList = useSelector((state) => state.siteDoorReducer.entityList);
  const lastActionType = useSelector((state) => state.siteDoorReducer.lastActionType);
  const processing = useSelector((state) => state.siteDoorReducer.processing);
  const page = useSelector((state) => state.siteDoorReducer.page);
  const error = useSelector((state) => state.siteDoorReducer.error);
  const parameters = { siteId: selectedSite?.id, sort: 'doorName,asc' };

  const [updatedColumn, setUpdatedColumn] = useState(columns);

  React.useEffect(() => {
    dispatch(Actions.select(null));
  }, [dispatch]);

  React.useEffect(() => {
    if (doorList.some((d) => d.foStatus && d.hkStatus)) {
      setUpdatedColumn([...columns, ...roomStatusColumn]);
    }
  }, [doorList]);

  return (
    <AutoTable
      title="Room List"
      subheader={'Rooms available at property via the PMS/Lock System'}
      handleRefreshReport={() => dispatch(Actions.fetch(0, 50, parameters))}
      handleChangePage={(ev, newPage, pageSize) => dispatch(Actions.fetch(newPage, pageSize, parameters))}
      processing={processing}
      page={page}
      selectItem={(item) => dispatch(Actions.select(item))}
      error={error}
      clearError={() => dispatch(Actions.clearError())}
      columns={updatedColumn}
      prefix="SITE_ROOM"
      detailURL="/site/rooms/view/{id}"
      idColumn={'id'}
      data={doorList}
      secondaryButtonAction={Actions.resyncDoors(selectedSite.id)}
      secondaryButtonName={'Resync Rooms'}
      secondaryButtonIcon={<Sync />}
      lastActionType={lastActionType}
    />
  );
}
