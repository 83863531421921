import actionCreator from '../../support/actionCreator';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';

export const SECURITY_ROOMS_DELETE_SUCCESS = 'SECURITY_ROOMS_DELETE_SUCCESS';

const SecurityDoorActions = {
  ...actionCreator.createActions('Security Room', 'SECURITY_ROOM', '/api/private/security/door'),

  resyncDoors: (siteId) => async (dispatch) => {
    API.get('PrivateAPI', `/api/private/security/door/${siteId}/resync`, {})
      .then(() => {
        Swal.fire('Resync Requested', 'This may take several minutes depending on the agent door scheduler. Please be patient', 'success');
        dispatch({ type: SECURITY_ROOMS_DELETE_SUCCESS });
      })
      .catch(() => {
        Swal.fire('Resync Failed', 'Unable to request rooms resync. Please try again later', 'error');
      });
  },
};
export default SecurityDoorActions;
