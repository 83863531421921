import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '@aws-amplify/api';
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Card, Grid } from '@material-ui/core';
import CardHeaderWithControls from '../../../../components/card/CardHeaderWithControls';
import CardContent from '@material-ui/core/CardContent';
import { AddOutlined } from '@material-ui/icons';
import ThemeMessageDialog from './components/ThemeMessageDialog';

export default function ThemeMessages() {
  const dispatch = useDispatch();
  const selectedTheme = useSelector((state) => state.themeReducer.selectedItem);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [customisations, setCustomisations] = useState([]);
  const languageList = selectedTheme?.languageList;

  useEffect(() => {
    if (selectedTheme) {
      API.get('PrivateAPI', `/api/private/portal/theme/${selectedTheme.id}/messages`, {})
        .then((response) => {
          setMessages(response);
        })
        .catch((error) => {
          Swal.fire('Fetch Failed', `Unable to get theme messages (${error.message})`, 'error');
        });
    }
  }, [dispatch, selectedTheme]);

  const requestMessageUpdate = useCallback(() => {
    API.post('PrivateAPI', `/api/private/portal/theme/${selectedTheme.id}/messages`, { body: customisations })
      .then((response) => {
        setMessages(response);
        setCustomisations([]); // clear customisations
      })
      .catch((error) => {
        Swal.fire('Update Failed', `Unable to update messages (${error.message})`, 'error');
      });
  }, [customisations, selectedTheme.id]);

  const addThemeMessages = useCallback(
    (created = []) => {
      setCustomisations([...customisations, ...created]);
      setMessages([...messages, ...created]);
    },
    [customisations, messages]
  );

  const handleCellEditCommit = React.useCallback(
    (params) => {
      const { id, field, value } = params;

      if (value !== '') {
        setCustomisations([
          ...customisations,
          {
            messageKey: id,
            messageValue: value,
            languageCode: field,
            overridden: true,
            removal: false,
          },
        ]);
      } else {
        const override = messages.find((c) => c.messageKey === id && c.languageCode === field && c.overridden);

        // if there's an existing override track it for deletion
        if (override) {
          setCustomisations([
            ...customisations,
            {
              messageKey: id,
              messageValue: value,
              languageCode: field,
              overridden: false,
              removal: true,
            },
          ]);
        }
      }
    },
    [customisations, messages]
  );

  const tableRows = useMemo(() => {
    const map = {};

    messages
      .filter((m) => m.overridden)
      .forEach((m) => {
        populateMessageKey(map, m);
      });

    messages
      .filter((m) => !m.overridden)
      .forEach((m) => {
        populateMessageKey(map, m);
      });

    return Object.values(map);
  }, [messages]);

  const tableColumns = useMemo(() => {
    const preparedColumns = [
      {
        field: 'messageKey',
        headerName: 'Message Key',
        minWidth: 300,
        flex: 1,
        editable: false,
        sortable: true,
        filterable: true,
      },
    ];

    languageList?.forEach((l) => {
      preparedColumns.push({
        field: l.code, // column property value
        headerName: `${l.name} (${l.displayName})`, // column display name
        minWidth: 300,
        flex: 1,
        editable: true,
        sortable: false,
        filterable: l.code === 'en',
        cellClassName: (params) => {
          const row = params.row;
          const updated = row && row[params.field];
          return updated ? 'text-primary' : 'text-muted';
        },
        // valueFormatter: (params) => {
        //   return params.value || params.row?.fallbacks[params.field] || '';
        // },
        valueGetter: (params) => {
          return params.value || params.row?.fallbacks[params.field] || '';
        },
      });
    });

    preparedColumns.push({
      field: 'customised',
      headerName: 'Customised',
      minWidth: 300,
      sortable: true,
      flex: 1,
      type: 'boolean',
    });

    return preparedColumns;
  }, [languageList]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeaderWithControls
            header={'Wording Customisations'}
            subheader={'Customise the wording visible in SmartCheck product suite'}
            onClick={requestMessageUpdate}
            secondaryButtonName={'Add New'}
            secondaryButtonIcon={<AddOutlined />}
            secondaryButtonAction={() => setShowAddDialog(true)}
            disabled={customisations.length <= 0}
          />
          <CardContent>
            <DataGrid
              rows={tableRows}
              columns={tableColumns}
              pageSize={25}
              rowsPerPageOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              autoHeight={true}
              onCellEditCommit={handleCellEditCommit}
            />
          </CardContent>
        </Card>
      </Grid>

      <ThemeMessageDialog open={showAddDialog} setOpen={setShowAddDialog} addThemeMessages={addThemeMessages} languageList={languageList} />
    </Grid>
  );
}

function populateMessageKey(map, entry) {
  const isOverride = entry.overridden;

  let existing = map[entry.messageKey];
  if (!existing) {
    map[entry.messageKey] = {
      id: entry.messageKey,
      messageKey: entry.messageKey,
      fallbacks: {},
    };

    existing = map[entry.messageKey];
  }

  const languageValue = existing[entry.languageCode];

  // language value is only set for customised values
  if (languageValue) {
    // populate the fallback value
    if (!existing.fallbacks[entry.languageCode]) {
      existing.fallbacks[entry.languageCode] = entry.messageValue;
    }
  } else {
    // populate our primary key
    if (isOverride) {
      existing[entry.languageCode] = entry.messageValue;
    } else {
      existing.fallbacks[entry.languageCode] = entry.messageValue;
    }
  }

  const customised = existing['customised'];
  if (!customised) {
    existing['customised'] = isOverride;
  }
}
