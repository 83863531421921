import actionCreator from '../../support/actionCreator';

export const NotificationScheduleActions = {
  ...actionCreator.createActions(
    'Special Request Category',
    'SPECIAL_REQUEST_CATEGORY',
    '/api/private/specialRequestCategory/site/{siteId}/schedule'
  ),
  selectAndNavigate: (item, history) => async (dispatch) => {
    await dispatch({ type: 'SPECIAL_REQUEST_CATEGORY_SELECT', payload: item });
    history.push(`/site/specialRequestCategory/view/${item.id}`);
  },
};

export default NotificationScheduleActions;
