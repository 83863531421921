import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FileImageSelector from '../../../../components/file/FileImageSelector';
import FormField from '../../../../components/form/FormField';
import { Grid } from '@material-ui/core';

export default function AppleWallet({ setValue }) {
  const selectedItem = useSelector((state) => state.walletReducer.selectedItem);

  const [androidWalletKey, setAndroidWalletKey] = React.useState(selectedItem?.androidWalletKey || '');
  const [androidClassId, setAndroidClassId] = React.useState(selectedItem?.androidClassId || 'smartcheck_wallet');
  const [androidIssuerId, setAndroidIssuerId] = React.useState(selectedItem?.androidIssuerId || '3388000000022211031');

  useEffect(() => {
    setValue({
      androidIssuerId,
      androidClassId,
      androidWalletKey,
    });
  }, [setValue, androidIssuerId, androidClassId, androidWalletKey]);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormField label={'Issue ID'} value={androidIssuerId} setValue={setAndroidIssuerId} placeholder="Enter Android Issuer ID" />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormField label={'Class ID'} value={androidClassId} setValue={setAndroidClassId} placeholder="Enter Android Class ID" />
      </Grid>

      <FileImageSelector
        sourceItem={selectedItem}
        sourceItemProperty={'androidWalletKey'}
        displayName={'Android Wallet Key(.json)'}
        onSelect={(file) => setAndroidWalletKey(file)}
        accept={'.json'}
      />
    </>
  );
}
