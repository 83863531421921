import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Grid, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FoodItemActions from '../../../../redux/actions/inRoom/ordering/foodItem/actions';
import FormField from '../../../../../components/form/FormField';
import { DeleteOutlined } from '@material-ui/icons';

export default function FoodSetItem({ vendorId, item, choiceIndex, index, setItem, deleteItem, setChildError }) {
  const dispatch = useDispatch();
  const modifierGroupList = useSelector((state) => state.foodModifierGroupReducer.entityList || []);
  const [searchResults, setSearchResults] = useState([]);
  const [foodItemId, setFoodItemId] = useState(item?.foodItemId);
  const [linkedFoodItem, setLinkedFoodItem] = useState(null);
  const [price, setPrice] = useState(item?.itemPrice);
  const [description, setDescription] = useState(linkedFoodItem?.description || '');
  const available = !!item?.available;
  const [modifierGroups, setModifierGroups] = useState(
    item?.modifierGroups?.map((mg) => ({
      label: mg.groupName + (mg.externalId ? ' - ' + mg.externalId : ''),
      value: mg.id,
    })) || []
  );

  useEffect(() => {
    setItem(index)({
      vendorId: vendorId,
      id: item?.id,
      foodItemId: foodItemId,
      itemName: linkedFoodItem?.label,
      itemPrice: price,
      index: choiceIndex,
      available: available,
    });

    if (!foodItemId || !price) {
      setChildError(true);
    } else {
      setChildError(false);
    }
  }, [foodItemId, price, linkedFoodItem, choiceIndex, index, item?.id, setChildError, setItem, vendorId, modifierGroupList, available]);

  useEffect(() => {
    const fetch = async () => {
      if (foodItemId) {
        const foodItemOption = await dispatch(FoodItemActions.findById(foodItemId));
        setLinkedFoodItem({
          value: foodItemOption?.id,
          label: foodItemOption?.name,
          price: foodItemOption?.price,
        });
        setDescription(foodItemOption?.description);
        if (!modifierGroups.length) {
          setModifierGroups([
            ...modifierGroupList
              ?.filter((mg) => foodItemOption?.modifierGroups?.includes(mg.id))
              .map((mg) => ({
                label: mg.groupName + (mg.externalId ? ' - ' + mg.externalId : ''),
                value: mg.id,
              })),
          ]);
        }
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, foodItemId]);

  const modifierGroupOptions = useMemo(() => {
    return modifierGroupList.map((mg) => ({
      label: mg.groupName + (mg.externalId ? ' - ' + mg.externalId : ''),
      value: mg.id,
    }));
  }, [modifierGroupList]);

  const handleSearch = async (event, criteria) => {
    if (criteria) {
      const result = await dispatch(FoodItemActions.search(vendorId, criteria));
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <Grid container>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'center',
            }}
          >
            <IconButton size={'small'} color={'inherit'} onClick={() => deleteItem(index)}>
              <DeleteOutlined />
            </IconButton>
            <Autocomplete
              fullWidth
              freeSolo
              options={searchResults}
              getOptionSelected={(option, selected) => option?.value === selected?.value}
              getOptionLabel={(option) => option?.label || ''}
              onInputChange={handleSearch}
              onChange={(event, value) => setFoodItemId(value?.value)}
              value={linkedFoodItem}
              renderInput={(params) => (
                <TextField
                  autoFocus={!foodItemId}
                  required
                  label={'Food Item'}
                  placeholder={'Start typing to search...'}
                  margin={'dense'}
                  variant="outlined"
                  InputProps={{
                    type: 'search',
                  }}
                  error={!foodItemId}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <FormField
            label={'Price'}
            required
            value={price}
            setValue={setPrice}
            type={'number'}
            inputProps={{ step: '0.01' }}
            error={!price}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormField label={'Description'} disabled value={description} setValue={setDescription} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Autocomplete
            multiple
            fullWidth
            name={'Modifier Groups'}
            placeholder={'Modifier Groups'}
            options={modifierGroupOptions}
            disabled
            getOptionSelected={(option, selected) => option.value === selected?.value}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                required={false}
                {...params}
                label={'Modifier Groups - *Modify on Item Level'}
                margin={'dense'}
                variant="outlined"
              />
            )}
            value={modifierGroups}
            renderTags={(values, getTagProps) =>
              values.map((option, index) => {
                return <Chip {...getTagProps({ index })} size={'small'} label={option.label || 'N/A'} />;
              })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
