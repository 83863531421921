import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fade, Hidden, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import * as AccessActions from '../../redux/actions/access/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserRoleHelper from '../../../support/UserRoleHelper';
import UserRole from '../../../support/UserRole';
import { ApartmentOutlined } from '@material-ui/icons';

const FeatureSections = [
  {
    id: 'operations',
    label: 'Operations',
    path: '/reservation/dashboard',
    children: [],
    icon: 'fad fa-fw fa-bed',
    role: UserRole.ROLE_SITE_USER,
  },
  {
    id: 'configuration',
    label: 'Configuration',
    path: '/dashboard',
    children: [],
    icon: 'fad fa-fw fa-tv',
    role: UserRole.ROLE_SITE_ADMIN,
  },
];

const useStyles = makeStyles((theme) => ({
  outer: {
    margin: '0 0.5rem',
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  listItemText: {
    color: 'rgba(255,255,255, 0.70)',
  },
}));

export default function SectionSelector() {
  const history = useHistory();
  const selectedSection = useSelector((state) => state.accessReducer.selectedSection);
  const profile = useSelector((state) => state.accessReducer.profile);

  const selectorStyles = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuItemClick = (event, section) => {
    dispatch(AccessActions.selectSection(section));
    setAnchorEl(null);
    history.push(section.path);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredSections = FeatureSections.filter((s) => UserRoleHelper.hasRole(profile, s.role));
  const isSelectorDisabled = filteredSections.length <= 1;
  return (
    <div className={selectorStyles.outer}>
      <Hidden mdUp>
        <IconButton
          aria-label="section-selection"
          aria-controls="section-selection"
          aria-haspopup="true"
          color={'inherit'}
          disabled={isSelectorDisabled}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <ApartmentOutlined />
        </IconButton>
      </Hidden>

      <Hidden smDown>
        <List component="nav" aria-label="Section Selection" color={'primary'}>
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Current Section"
            className={selectorStyles.root}
            disabled={isSelectorDisabled}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <ListItemText
              primary="Current Section"
              secondary={selectedSection ? selectedSection.label : 'No Section Selected'}
              secondaryTypographyProps={{ className: selectorStyles.listItemText }}
            />
          </ListItem>
        </List>
      </Hidden>

      <Menu id="lock-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} TransitionComponent={Fade}>
        {filteredSections.map((data, index) => {
          return (
            <MenuItem
              selected={selectedSection && selectedSection.label === data.label}
              onClick={(event) => handleMenuItemClick(event, data)}
              key={'section_' + index}
            >
              {data.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
