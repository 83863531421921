const PackageValidDaysTarget = {
  ARRIVAL_ONLY: 'ARRIVAL_ONLY',
  DEPARTURE_ONLY: 'DEPARTURE_ONLY',
  BETWEEN_ARRIVAL_DEPARTURE: 'BETWEEN_ARRIVAL_DEPARTURE',
};

const PackageValidDaysTargetOptions = [
  { label: 'Arrival Date Only', value: PackageValidDaysTarget.ARRIVAL_ONLY },
  { label: 'Departure Date Only', value: PackageValidDaysTarget.DEPARTURE_ONLY },
  { label: 'Between Arrival and Departure', value: PackageValidDaysTarget.BETWEEN_ARRIVAL_DEPARTURE },
];

export { PackageValidDaysTargetOptions };
export default PackageValidDaysTarget;
