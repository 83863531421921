import actionCreator from '../../../support/actionCreator';
import API from '@aws-amplify/api';
import Objects from '../../../../../support/Objects';
import Swal from 'sweetalert2';

export const ActivityScheduleActions = {
  ...actionCreator.createActions('Activity Schedule', 'ACTIVITY_SCHEDULE', '/api/private/portal/activity/{path}'),
  disable: (value) => async (dispatch) => {
    const payload = { ...value, enabled: !value.enabled };

    dispatch({ type: `ACTIVITY_SCHEDULE_UPDATE_IN_PROGRESS` });
    try {
      await API.patch('PrivateAPI', `/api/private/portal/activity/schedule/${payload.id}?enabled=${payload.enabled}`, {});
      await dispatch({ type: 'ACTIVITY_SCHEDULE_UPDATE_SUCCESS', payload: payload });
      await Swal.fire(`Activity Schedule Updated`, 'Your changes have been saved successfully', 'success');
    } catch (error) {
      dispatch({ type: 'ACTIVITY_SCHEDULE_UPDATE_FAILURE', payload: new Error(Objects.getErrorFromResponse(error)) });
    }
  },
  updateChild: (leaf) => async (dispatch) => {
    await dispatch({ type: 'ACTIVITY_SCHEDULE_UPDATE_CHILD', payload: leaf });
    await Swal.fire(`Activity Schedule Updated`, 'Your changes have been saved successfully', 'success');
  },
};

export default ActivityScheduleActions;
